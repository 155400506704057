// #region UTL

/** A class which represents the utl.AirportCodes table. */
export class utlAirportCode {
	AirportID: number;
	AirportCode: string;
	City: string;
	StateProvinceCode: string;
	CountryCode: string;
	IsActive: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
	CSC: string;
}
/** A class which represents the utl.CountryCodes table. */
export class utlCountryCode {
	CountryID: number;
	CountryCodeA3: string;
	CountryCodeA2: string;
	CountryName: string;
	CountryNumber: number;
	AutoHoldFlag: boolean;
	FedExZone: string;
	DHLZone: string;
	RemoveZeroFromPostalCode: boolean;
	CountryCodeToStateCode: boolean;
	IsActive: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the utl.CurrencyList view. */
export class utlCurrencyList {
	currency: string;
}
/** A class which represents the utl.EmailTypes table. */
export class utlEmailType {
	EmailTypeID: any;
	EmailTypeName: string;
	IsActive: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the utl.ExchangeRates table. */
export class utlExchangeRate {
	ExchangeRateID: number;
	FromCurrencyCode: string;
	ToCurrencyCode: string;
	ExchangeDate: Date;
	ExchangeRate: number;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the utl.MediaTypes table. */
export class utlMediaType {
	MediaTypeID: any;
	MediaTypeName: string;
	MediaTypeBase: string;
	IsActive: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the utl.NmfcNumbers table. */
export class utlNmfcNumber {
	NmfcNumberID: number;
	NmfcNumber: string;
	ItemClass: number;
	Item: string;
	ReferencePage: number;
	ItemDescription: string;
	IsActive: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
	FBNmfcID: number;
}
/** A class which represents the utl.PhoneTypes table. */
export class utlPhoneType {
	PhoneTypeID: any;
	PhoneTypeName: string;
	IsActive: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the utl.PlaceTypes table. */
export class utlPlaceType {
	PlaceTypeID: any;
	PlaceType: string;
	IsActive: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the utl.StateProvinceCodes table. */
export class utlStateProvinceCode {
	StateProvinceID: number;
	StateProvinceCode: string;
	StateProvinceName: string;
	CountryCodeA3: string;
	IsActive: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
export class utlPlace {
	id: number;
	City: string;
	StateProvinceCode: string;
	CSC: string;
	CountryCode: string;
	CreatedBy: number;
	CreatedDate: number;
	UpdatedBy: number;
	UpdatedDate: Date;
}
//#endregion UTL

export class reportParam {
	name: string;
	value: string;
}

export class reportRequest {
	ReportName: string;
	paramList: reportParam[];
	format: string;
}

export class DimFactorUpdate {
	DimFactor: number;
	pieces: any[];
}

export class NoteDocTaskCount {
	NoteCount: number;
	DocCount: number;
	TaskCount: number;
	ClientNoteCount: number;
	NewDocCount: number;
}

export class QuoteForwardingStatus {
	QuoteStatusStep: number;
	ForwardingStatusStep: number;
}
