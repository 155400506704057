import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Accounts3Party, CarrierConfigDetails, P3PLFulfillmentClients, cfgAdditionalSerivceOptionsDb, cfgAdditionalServicesDb, cfgCarrierAccountsDb, cfgCarriersDb, cfgPaymentTypeDb, cfgRateDivisionTypesDb, cfgRateTypeDb, cfgRatingEngineServicesDb, cfgRatingEngineSitesDb, cfgRatingEnginesDb, cfgShipServAddServOptsDb, cfgShipViasDb, cfgShippingServiceDb } from 'app/_models/SEND.model';

@Injectable()
export class cfgRateTypesService {
  api = 'cfgRateTypes'

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<cfgRateTypeDb[]>(this.apiURL + this.api);

  }
  get(id: number) {
    return this.http.get<cfgRateTypeDb>(this.apiURL + this.api + '/' + id);
  }

  create(RateTypeDb: cfgRateTypeDb) {
    return this.http.post(this.apiURL + this.api, RateTypeDb);
  }

  update(RateTypeDb: cfgRateTypeDb) {
    return this.http
      .put(this.apiURL + this.api + '/' + RateTypeDb.RateTypeID, RateTypeDb);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + '/' + id);
  }
}

@Injectable()
export class cfgPaymentTypesService {
  api = 'cfgPaymentTypes'

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<cfgPaymentTypeDb[]>(this.apiURL + this.api);

  }
  get(id: number) {
    return this.http.get<cfgPaymentTypeDb>(this.apiURL + this.api + '/' + id);
  }

  create(PaymentTypeDb: cfgPaymentTypeDb) {
    return this.http.post(this.apiURL + this.api, PaymentTypeDb);
  }

  update(PaymentTypeDb: cfgPaymentTypeDb) {
    return this.http
      .put(this.apiURL + this.api + '/' + PaymentTypeDb.PaymentTypeID, PaymentTypeDb);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + '/' + id);
  }
}

@Injectable()
export class cfgAccounts3dPartyService {
  api = 'cfgAccounts3Party'

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<Accounts3Party[]>(this.apiURL + this.api);
  }

  get(id: number) {
    return this.http.get<Accounts3Party>(this.apiURL + this.api + '/' + id);
  }

  create(Accounts3PartyItem: Accounts3Party) {
    return this.http.post(this.apiURL + this.api, Accounts3PartyItem);
  }

  update(Accounts3PartyItem: Accounts3Party) {
    console.log(Accounts3PartyItem.Account3PartyID)
    return this.http.put(this.apiURL + this.api + '/' + Accounts3PartyItem.Account3PartyID, Accounts3PartyItem);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + '/' + id);
  }
}

@Injectable()
export class cfgCarriersService {
  api = 'cfgCarriers/'

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<cfgCarriersDb[]>(this.apiURL + this.api);
  }

  getCarrierConfigDetails(carrierID: number) {
    return this.http.get<CarrierConfigDetails[]>(this.apiURL + this.api + 'cfgDetails/' + carrierID)
  }

  create(carrierItem: cfgCarriersDb) {
    return this.http.post(this.apiURL + this.api, carrierItem);
  }

  update(carrierItem: cfgCarriersDb) {
    return this.http.put(this.apiURL + this.api + '/' + carrierItem.CarrierID, carrierItem);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }
}

@Injectable()
export class cfgAdditionalServicesService {
  api = 'cfgAdditionalServices/'

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<cfgAdditionalServicesDb[]>(this.apiURL + this.api + 'getAll')
  }

  getForCarrier(carrierID: number) {
    return this.http.get<cfgAdditionalServicesDb[]>(this.apiURL + this.api + 'carrier/' + carrierID)
  }

  get(additionalServiceID: number) {
    return this.http.get<cfgAdditionalServicesDb>(this.apiURL + this.api + additionalServiceID)
  }

  create(addServiceItem: cfgAdditionalServicesDb) {
    return this.http.post(this.apiURL + this.api, addServiceItem);
  }

  update(addServiceItem: cfgAdditionalServicesDb) {
    return this.http.put(this.apiURL + this.api + '/' + addServiceItem.AdditionalServiceID, addServiceItem);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }
}

@Injectable()
export class cfgAdditionalServiceOptionsService {
  api = 'cfgAdditionalServiceOptions/'

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<cfgAdditionalSerivceOptionsDb[]>(this.apiURL + this.api + 'getAll')
  }

  getForAddServ(additionalServiceID: number) {
    return this.http.get<cfgAdditionalSerivceOptionsDb[]>(this.apiURL + this.api + 'addServ/' + additionalServiceID)
  }

  getByID(additionalServiceOptionID: number) {
    return this.http.get<cfgAdditionalSerivceOptionsDb>(this.apiURL + this.api + additionalServiceOptionID)
  }

  create(addServiceOptItem: cfgAdditionalSerivceOptionsDb) {
    return this.http.post(this.apiURL + this.api, addServiceOptItem);
  }

  update(addServiceOptItem: cfgAdditionalSerivceOptionsDb) {
    return this.http.put(this.apiURL + this.api + '/' + addServiceOptItem.AdditionalServiceID, addServiceOptItem);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }
}

@Injectable()
export class cfgCarrierAccountsService {
  api = 'cfgCarrierAccounts/'

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<cfgCarrierAccountsDb[]>(this.apiURL + this.api)
  }

  getForCarrier(carrierID: number) {
    return this.http.get<cfgCarrierAccountsDb[]>(this.apiURL + this.api + 'forCarrier/' + carrierID)
  }

  create(carrierAccount: cfgCarrierAccountsDb) {
    return this.http.post(this.apiURL + this.api, carrierAccount);
  }

  update(carrierAccount: cfgCarrierAccountsDb) {
    return this.http.put(this.apiURL + this.api + '/' + carrierAccount.CarrierAccountID, carrierAccount);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }
}

@Injectable()
export class cfgShippingServiceService {
  api = 'cfgSendItShippingServicesDb/'

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<cfgShippingServiceDb[]>(this.apiURL + this.api + 'getAll')
  }

  getByID(shippingServiceID: number) {
    return this.http.get<cfgShippingServiceDb>(this.apiURL + this.api + shippingServiceID)
  }

  getForCarrier(carrierID: number) {
    return this.http.get<cfgShippingServiceDb[]>(this.apiURL + this.api + 'forCarrier/' + carrierID)
  }

  create(shippingService: cfgShippingServiceDb) {
    return this.http.post(this.apiURL + this.api, shippingService);
  }

  update(shippingService: cfgShippingServiceDb) {
    return this.http.put(this.apiURL + this.api + '/' + shippingService.ShippingServiceID, shippingService);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }
}

@Injectable()
export class cfgRateDivisionTypesService {
  api = 'cfgRateDivisionTypes/'

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<cfgRateDivisionTypesDb[]>(this.apiURL + this.api)
  }

  getByID() {
    return this.http.get<cfgRateDivisionTypesDb>(this.apiURL + this.api)
  }

  create(rdt: cfgRateDivisionTypesDb) {
    return this.http.post(this.apiURL + this.api, rdt);
  }

  update(rdt: cfgRateDivisionTypesDb) {
    return this.http.put(this.apiURL + this.api + '/' + rdt.RateDivisionTypeID, rdt);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }
}

@Injectable()
export class cfgShipViaService {
  api = "cfgShipVias/"

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getForShippingService(shippingServiceID: number) {
    return this.http.get<cfgShipViasDb[]>(this.apiURL + this.api + 'shippingService/' + shippingServiceID)
  }

  create(shipVia: cfgShipViasDb) {
    return this.http.post(this.apiURL + this.api, shipVia);
  }

  update(shipVia: cfgShipViasDb) {
    return this.http.put(this.apiURL + this.api + '/' + shipVia.ShipViaID, shipVia);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }
}

@Injectable()
export class cfgRatingEngineService {
  api = 'cfgRatingEngines/'

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<cfgRatingEnginesDb[]>(this.apiURL + this.api + 'getAll')
  }

  create(ratingEngine: cfgRatingEnginesDb) {
    return this.http.post(this.apiURL + this.api, ratingEngine);
  }

  update(ratingEngine: cfgRatingEnginesDb) {
    return this.http.put(this.apiURL + this.api + '/' + ratingEngine.RatingEngineID, ratingEngine);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }
}

@Injectable()
export class cfgRatingEngineSitesService {
  api = 'cfgRatingEngineSites/'

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getForRatingEngine(ratingEngineID: number) {
    return this.http.get<cfgRatingEngineSitesDb[]>(this.apiURL + this.api + 'forRatingEngine/' + ratingEngineID)
  }

  create(site: cfgRatingEngineSitesDb) {
    return this.http.post(this.apiURL + this.api, site);
  }

  update(site: cfgRatingEngineSitesDb) {
    return this.http.put(this.apiURL + this.api + '/' + site.RatingEngineSiteID, site);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }
}

@Injectable()
export class cfgRatingEngineServiceService {
  api = 'cfgRatingEngineServices/'

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<cfgRatingEngineServicesDb[]>(this.apiURL + this.api + 'getAll')
  }

  getForShippingService(id: number) {
    return this.http.get<cfgRatingEngineServicesDb[]>(this.apiURL + this.api + 'forShippingService/' + id)
  }

  getForRatingEngine(id: number) {
    return this.http.get<cfgRatingEngineServicesDb[]>(this.apiURL + this.api + 'forRatingEngine/' + id)
  }

  create(ratingEngineService: cfgRatingEngineServicesDb) {
    return this.http.post(this.apiURL + this.api, ratingEngineService);
  }

  update(ratingEngineService: cfgRatingEngineServicesDb) {
    return this.http.put(this.apiURL + this.api + '/' + ratingEngineService.RatingEngineServiceID, ratingEngineService);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

}

@Injectable()
export class cfgShippingServiceAdditionalServiceOptionsService {
  api = 'shipServ-addServOpts/'

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getForShippingService(id: number) {
    return this.http.get<cfgShipServAddServOptsDb[]>(this.apiURL + this.api + 'forShippingService/' + id)
  }

  create(item: cfgShipServAddServOptsDb) {
    return this.http.post(this.apiURL + this.api, item);
  }

  update(item: cfgShipServAddServOptsDb) {
    return this.http.put(this.apiURL + this.api + item.ShippingServiceAdditionalServiceOptionID, item);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }
}

@Injectable()
export class p3plFulfillmentClientService {
  api = 'orgClient/'

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getP3PLClients() {
    return this.http.get<P3PLFulfillmentClients[]>(this.apiURL + this.api + 'p3plClients')

  }
}