<div style="display: flex; justify-content: center;">
    <!-- <div style="padding:20px; width: 20%;"></div> -->
    <div style="width: 10%; padding:10px;" >
        <button style="padding: 10px; margin:10px 10px 20px 0px; width: 100%;" pButton [label]="buttonString" (click)="toggleTeam()" *ngIf="isAdmin"></button>
    </div>
    <div style="width: 90%; display:flex; justify-content:center;">
        <div style="width:100%; padding: 10px;">
            <p-panel [header]="headerString">

                <p-table #dt [value]="actions" sortMode="multiple" [showJumpToPageDropdown]="true" [showPageLinks]="false" [multiSortMeta]="multiSortMetaDefault"
                    (onFilter)="doFilter($event)" (onSort)="onSort($event)" (onPage)="onPage($event)">
                    <!-- Table Header Template -->
                    <ng-template pTemplate="header">
                        <!-- Header Row -->
                        <tr>
                            <th style="width:50px;"></th>
                            <th pSortableColumn="PersonName" [ngStyle]="{ 'width':'25%', 'text-align': 'left' }" *ngIf="localFilter.ShowAllActors">
                                Person Name
                                <span>
                                    <p-sortIcon field="PersonName"></p-sortIcon>
                                </span>
                            </th>
                            <th pSortableColumn="Title" [ngStyle]="{ 'width':'25%', 'text-align': 'left' }">
                                Document Title
                                <span>
                                    <p-sortIcon field="Title"></p-sortIcon>
                                </span>
                            </th>
                            <th pSortableColumn="VersionNumber" [ngStyle]="{ 'width':'15%', 'text-align': 'left' }">
                                Version Number
                                <span>
                                    <p-sortIcon field="VersionNumber"></p-sortIcon>
                                </span>
                            </th>
                            <th pSortableColumn="RoleName" [ngStyle]="{ 'text-align': 'left' }">
                                Role
                                <span>
                                    <p-sortIcon field="RoleName"></p-sortIcon>
                                </span>
                            </th>
                            <th pSortableColumn="ActionDueDate" [ngStyle]="{ 'width':'20%', 'text-align': 'left' }">
                                Due Date
                                <span>
                                    <p-sortIcon field="ActionDueDate"></p-sortIcon>
                                </span>
                            </th>
                            <th pSortableColumn="ActionCompletedDate" [ngStyle]="{ 'width':'20%', 'text-align': 'left' }" *ngIf="localFilter.ShowCompleted">
                                Completed Date
                                <span>
                                    <p-sortIcon field="ActionCompletedDate"></p-sortIcon>
                                </span>
                            </th>
                        </tr>
                        <!-- Filters Row -->
                        <tr>
                            <th class="col-icon-delete">
                                <div class="col-icon-delete">
                                    <i class="fa fa-ban" (click)="clearLocalFilter(dt)"></i>
                                </div>
                            </th>
                            <th *ngIf="localFilter.ShowAllActors">
                                <p-dropdown [options]="teamMembers" [(ngModel)]="localFilter.PersonName" (onClear)="dt.filter(localFilter.PersonName, null, null)"
                                    [showClear]="true" placeholder="Team Member" appendTo="body" [editable]="true"
                                    (onChange)="dt.filter(localFilter.PersonName, 'PersonName', 'contains')"></p-dropdown>
                            </th>
                            <th>
                                <p-dropdown [options]="documents" [(ngModel)]="localFilter.Title" (onClear)="dt.filter(localFilter.Title, null, null)"
                                    [showClear]="true" placeholder="Document Title" appendTo="body" [editable]="true"
                                    (onChange)="dt.filter(localFilter.Title, 'Title', 'contains')"></p-dropdown>
                            </th>
                            <th>
                                <p-dropdown [options]="versions" [(ngModel)]="localFilter.VersionNumber" (onClear)="dt.filter(localFilter.VersionNumber, null, null)"
                                    [showClear]="true" placeholder="Version Number" appendTo="body" [editable]="true"
                                    (onChange)="dt.filter(localFilter.VersionNumber, 'VersionNumber', 'contains')"></p-dropdown>
                            </th>
                            <th>
                                <p-dropdown [options]="roles" [(ngModel)]="localFilter.RoleName" (onClear)="dt.filter(localFilter.RoleName, null, null)"
                                    [showClear]="true" placeholder="Roles" appendTo="body" [editable]="true"
                                    (onChange)="dt.filter(localFilter.RoleName, 'RoleName', 'contains')"></p-dropdown>
                            </th>
                            <th>
                                <p-calendar [(ngModel)]='localFilter.ActionDueDate'
                                    (onSelect)="dt.filter(localFilter.ActionDueDate, 'ActionDueDate', 'dateRangeFilter')"
                                    (onClearClick)="dt.filter('', 'ActionDueDate','contains')" [showButtonBar]="true"
                                    [readonlyInput]="true" [monthNavigator]="true" [yearNavigator]="true"
                                    [inputStyle]="{'width': '100%'}" appendTo="body" yearRange="2000:2040" selectionMode="range">
                                </p-calendar>
                            </th>
                            <th *ngIf="localFilter.ShowCompleted">
                                <p-calendar [(ngModel)]='localFilter.ActionCompletedDate'
                                    (onSelect)="dt.filter(localFilter.ActionCompletedDate, 'ActionCompletedDate', 'dateRangeFilter')"
                                    (onClearClick)="dt.filter('', 'ActionCompletedDate','contains')" [showButtonBar]="true"
                                    [readonlyInput]="true" [monthNavigator]="true" [yearNavigator]="true"
                                    [inputStyle]="{'width': '100%'}" appendTo="body" yearRange="2000:2040" selectionMode="range">
                                </p-calendar>
                            </th>
                        </tr>
                    </ng-template>
                    <!-- Table Body Template -->
                    <ng-template pTemplate="body" let-rowData let-action>
                        <!-- Data Rows -->
                        <tr [pSelectableRow]="rowData">
                            <td style="text-align: center;">
                                <button pButton icon="pi pi-book" (click)="viewAction(action)" pTooltip="View Action"></button>
                            </td>
                            <td *ngIf="localFilter.ShowAllActors">
                                <p>{{ action.PersonName }}</p>
                            </td>
                            <td>
                                <p>{{ action.Title }}</p>
                            </td>
                            <td>
                                {{ action.VersionNumber }}
                            </td>
                            <td>
                                {{ action.RoleName }}
                            </td>
                            <td>
                                {{ action.ActionDueDate | date: 'mediumDate' }}
                            </td>
                            <td *ngIf="localFilter.ShowCompleted">
                                {{ action.ActionCompletedDate | date: 'mediumDate' }}
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Table Footer Template -->
                    <ng-template pTemplate="summary">
                        <div style="display:flex; justify-content:space-evenly">
                            <p-button label="Refresh" icon="pi pi-refresh" (click)="getData()"></p-button>
                            <p>Action Count: {{dt.totalRecords }} </p>
                            <p-button [label]="completeString" (click)="includeCompleted()"></p-button>
                        </div>
                    </ng-template>
                </p-table>
            </p-panel>
        </div>
    </div>
</div>
