import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { orgEntity, orgEntitySearchResult, orgEntityVw } from '../_models/ORG.model';
import { HttpParams } from '@angular/common/http';
import { EntityType } from '../_models/enums.model';


@Injectable()
export class EntityListService {

    api = 'orgEntity/';

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    getAll() {
        return this.http.get<orgEntity[]>(this.apiURL + this.api);

    }
    getByID(id: number) {
        return this.http.get<orgEntity>(this.apiURL + this.api + id);

    }
    create(entity: orgEntity) {
        return this.http.post(this.apiURL + this.api, entity);
    }

    update(entity: orgEntity) {
        return this.http
            .put(this.apiURL + this.api + entity.EntityID, entity);
    }

    delete(id: number) {
        return this.http.delete(this.apiURL + this.api + id);
    }

    // searchBillTos(frag: string) {
    //     return this.http.get<orgEntity[]>(this.apiURL + this.api + 'search/' + EntityType.BillTo, { params: { frag: frag } });
    // }

    // searchEntityShipCons(frag: string, entityID: number, shipconsOnly: boolean) {
    //     if (shipconsOnly) {
    //         return this.http.get<orgEntity[]>(this.apiURL + this.api + 'shipconsSearch/' + entityID, { params: { frag: frag } });
    //     } else {
    //         return this.http.get<orgEntity[]>(this.apiURL + this.api + 'search/' + EntityType.AllEntities, { params: { frag: frag } });
    //     }
    // }

    getEntitiesByType(entityType: EntityType, associatedEntityID: number = -1) {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        let params = new HttpParams();
        params = params.append('entityType', entityType.toString());
        params = params.append('associatedEntityID', associatedEntityID.toString());
        return this.http.get<orgEntity[]>(this.apiURL + this.api + 'ByType', { params });
    }

    getEntityRoles(entityID: number) {
        return this.http.get<string[]>(this.apiURL + this.api + 'roles/' + entityID);
    }

    getShipConsForClient(entityID: number, CSC: string) {
        const CSCParam = { params: new HttpParams().set('CSC', CSC) };
        return this.http.get<orgEntity[]>(this.apiURL + this.api + entityID + '/shipcons', { params: { CSC: CSC } }); //  CSCParam);
    }

    updateTypeValue(id: number, type: EntityType, value: boolean) {
        return this.http.put(this.apiURL + this.api + id + '/IsType/' + type + '/' + value, null);

    }
}

@Injectable()
export class EntityVwService {

    api = 'orgEntityVw/';

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    getByID(id: number) {
        return this.http.get<orgEntityVw>(this.apiURL + this.api + id);
    }
}

@Injectable()
export class EntitySearchService {

    api = 'orgEntity/';

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    searchEntities(entityType: EntityType, frag: string, associatedEntityID: number = -1) {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        let params = new HttpParams();
        params = params.append('frag', frag);
        params = params.append('entityType', entityType.toString());
        params = params.append('associatedEntityID', associatedEntityID.toString());
        return this.http.get<orgEntitySearchResult[]>(this.apiURL + this.api + 'search', { headers: headers, params: params });
    }

}
