import { Injectable } from '@angular/core';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {


  breadcrumbItems: MenuItem[] = [];

  home: MenuItem;

  constructor(
    private router: Router
  ) {

    this.home = { routerLink: ['/'], icon: 'pi pi-home' };

  }

  add(item: MenuItem) {
    let id = 'a';
    if (this.breadcrumbItems.length > 0) {
      const char: string = this.breadcrumbItems[this.breadcrumbItems.length - 1].id;
      let code: number = char.charCodeAt(0);
      code++;
      id = String.fromCharCode(code);
    }
    item.id = id;
    this.breadcrumbItems.push(item);
  }

  clear() {
    this.breadcrumbItems = [];
  }

  navTo(item: MenuItem) {
    this.breadcrumbItems.filter(x => x.id < item.id);
    // this.router.navigate(item.routerLink, item.queryParams);
   }

}
