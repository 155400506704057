import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

// // import { server } from '../_models/index';
import { ordBillingLogVw, ordBillingPortalVw } from '../_models/DBTables.model';

@Injectable()
export class BillingService {

  api = 'ordBillingLogVw/';

  constructor(
    private http: HttpClient,
    @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<ordBillingLogVw[]>(this.apiURL + this.api);
  }
}

@Injectable()
export class BillingPortalVwService {

  api = 'ordBillingPortalVw/';

  constructor(
    private http: HttpClient,
    @Inject('apiURL') private apiURL) { }

  // getAllForClient(ClientID: number) {
  //   var params = new HttpParams();
  //   params = params.append('ClientID', ClientID);
  //   return this.http.get<ordBillingPortalVw[]>(this.apiURL + this.api, {params: params});
  // }

  getAllFilteredForClient(clientID: number, invoiceStatuses: string[], forwardingStatuses: string[]) {
    var params = new HttpParams();
    params = params.append('clientID', clientID);
    params = params.append('InvoiceStatuses', invoiceStatuses.join(','));
    params = params.append('forwardingStatuses', forwardingStatuses.join(','));

    return this.http.get<ordBillingPortalVw[]>(this.apiURL + this.api, {params: params}); // so get all
  }

}


