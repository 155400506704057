import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { docAssignmentService, docDocLinkService, docDocumentService, docHistoryService, docQuizService, docVersionService } from 'app/_data/pro-docs.service';
import { UpsertRet } from 'app/_models/API.model';
import { docActorDb, docDocLinkDb, docDocumentDb, docDocumentSearch, docHistoryDb, docQuizDb, docUploadedDoc, docVersion, docVersionDb } from 'app/_models/DOC.model';
import { DocVersionStatusTypes, LanguageTypes } from 'app/_models/enums.model';
import { LabelForEnumPipe } from 'app/_pipes/label-for-enum.pipe';
import { AlertService, AuthService } from 'app/_services';
import { LookupsService } from 'app/_services/lookups.service';
import { PersistenceService } from 'app/_services/persistence.service';
import { UtilityService } from 'app/_services/utility.service';
import { saveAs } from 'file-saver-es';
import { ConfirmationService, MenuItem, SelectItem } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { Table } from 'primeng/table';
import { DocumentVersionHistoryComponent } from '../document-version-history/document-version-history.component';

@Component({
  selector: 'arc-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {

  @ViewChild(DocumentVersionHistoryComponent) historyComponent: DocumentVersionHistoryComponent;

  versionID = 1;
  version: docVersion;
  selectedVersion: docVersionDb;
  document: docDocumentDb;
  mayEdit = false;

  noteCount = 0;

  actors: SelectItem[] = [];
  languages: SelectItem[] = [];
  links: docDocLinkDb[] = [];
  selectedDocumentLink: docDocumentDb;
  filteredDocuments: docDocumentSearch[];
  versions: docVersionDb[] = [];

  addDocLink: boolean = false;

  @ViewChild('PDFUpload', { static: false }) pdfUpload: FileUpload;
  @ViewChild('SourceUpload', { static: false }) sourceUpload: FileUpload;

  showConfirmLanguageDialog = false;
  confirmMessage: string;
  confirmLabel: string;
  langMenuItems: MenuItem[];
  versionMenuItems: MenuItem[];
  editVersionNumber: boolean = false;
  ownerID: number = null;

  documentHistory: docHistoryDb[] = [];
  isAuthor: boolean = false;

  statusTypes = DocVersionStatusTypes;

  historyCount: number = null;

  // ** Error Tooltips
  publishErrorTooltip: string = null;

  reviseErrorTooltip: string = null;
  newLanguageErrorTooltip: string = null;
  editQuizErrorTooltip: string = null;
  // ** --------------

  multiSortMetaDefault = [{ field: 'ActionDate', order: 1 }]; // , {field: 'PlaceHolder', order: 1}, {field: 'PlaceHolder', order: 1}];

  //#region FilterNames
  storedDTFilters = 'DocHistoryDTFilters';
  storedInputFilters = 'DocHistoryInputFilters';
  //#endregion

  localFilter = {
    VersionNumber: null,
    PersonName: null,
    Action: null,
    ActionDate: null,
    Note: null,
    MultiSortMeta: this.multiSortMetaDefault,
    rowsPerPage: +this.auth.currentUser.opts.PaginatorRows,
    currentRow: 0
  };
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private docService: docDocumentService,
    private versionService: docVersionService,
    private alert: AlertService,
    private auth: AuthService,
    private lookups: LookupsService,
    private confirm: ConfirmationService,
    private utility: UtilityService,
    private historyService: docHistoryService,
    private persist: PersistenceService,
    private docLinkService: docDocLinkService,
    private quizService: docQuizService,
    private labelForEnum: LabelForEnumPipe,
    private assignmentService: docAssignmentService
  ) { }

  ngOnInit(): void {
    this.versionID = this.route.snapshot.params['versionID'];
    this.loadVersion();

  }

  loadVersion() {
    this.versionService.getByID(this.versionID)
      .subscribe((data: docVersion) => {
        this.publishErrorTooltip = '';
        this.version = data;
        this.version.LanguageName = this.labelForEnum.transform(this.version.LanguageCode, LanguageTypes);
        this.version.ExpirationDate = new Date(this.version.ExpirationDate);
        this.getDocument();
        this.getLanguageMenu();
        this.getActorList();
        this.getVersionMenu();
        this.getDocumentLinks();
        this.canPublish();
        this.historyComponent.getVersionHistory();
        this.ownerID = this.version.OwnerID;
      });
  }

  setDetails() {
    this.ownerID = this.version.OwnerID;
  }

  getDocument(): void {
    this.docService.getByID(this.version.DocumentID)
      .subscribe((data: docDocumentDb) => {
        this.document = data;
        console.log('got document', this.document);
        this.getVersions();
      });
  }

  getLanguageMenu() {
    if (!this.canAddNewLanguage()) {
      return;
    }
    this.lookups.languages
      .subscribe((ret: SelectItem[]) => {
        this.languages = ret;
        this.langMenuItems = [];
        ret.forEach((si: SelectItem) => {
          this.langMenuItems.push({ label: si.label, command: () => { this.confirmNewLanguage(si) } })
        });
        console.log('lanMenuItems', this.langMenuItems);
      });
  }

  getVersionMenu() {
    this.versionMenuItems = [];
    this.versionMenuItems.push({ label: 'Major Revision: ' + this.newVersionLabel(1), command: () => this.confirmRevise(this.version, 1) });
    this.versionMenuItems.push({ label: 'Minor Revision: ' + this.newVersionLabel(2), command: () => this.confirmRevise(this.version, 2) });
    this.versionMenuItems.push({ label: 'Patch Revision: ' + this.newVersionLabel(3), command: () => this.confirmRevise(this.version, 3) });

  }
 
  newVersionLabel(level: number): string {
    switch (level) {
      case 1: return `${this.version.MajorNum + 1}.0.0`;
      case 2: return `${this.version.MajorNum}.${this.version.MinorNum + 1}.0`;
      case 3: return `${this.version.MajorNum}.${this.version.MinorNum}.${this.version.PatchNum + 1}`;
      default: return this.version.VersionNumber;
    }
  }

  getVersions() {
    this.versionService.getForDocument(this.document.DocumentID).subscribe({
      next: (versions: docVersionDb[]) => {
        this.versions = versions.filter(a => a.StatusID !== this.statusTypes.Archived);
        console.log(this.versions)
      }
    });
  }

  changeVersion() {
    this.versionID = this.version.VersionID;
    this.loadVersion();
  }


  getActorList() {
    this.lookups.proDocActors
      .subscribe((ret: SelectItem[]) => {
        this.actors = ret;
        console.log('got actors', this.actors);
      });
  }

  doSave() {
    this.mayEdit = false;
    this.version.OwnerID = this.ownerID;
    this.versionService.update(this.version)
      .subscribe((ret: UpsertRet) => {
        this.alert.success('Saved!');
        this.editVersionNumber = false;
        this.loadVersion();
      },
        (error) => this.alert.error(error)
      )
  }

  goQuiz(): void {
    if (!this.canEditQuiz()) {
      this.alert.error(this.editQuizErrorTooltip);
      return;
    }
    this.router.navigate(['/prodocs/quiz', this.versionID, 'edit']);
  }

  viewQuiz(): void {
    this.router.navigate(['/prodocs/quiz', this.versionID, 'display']);
  }

  confirmPublish(item: docVersionDb) {
    if (!this.canPublish()) {
      this.alert.error(this.publishErrorTooltip);
      return;
    }
    this.confirm.confirm({
      message: 'Ready to publish ' + item.Description + '?',
      header: 'Confirm',
      icon: 'fa fa-question-circle',
      accept: () => { this.goPublish(item.VersionID); }
    });
  }
  goPublish(vid: number): void {
    this.versionService.publish(vid)
      .subscribe((ret: UpsertRet) => {
        this.alert.info('Published');
        this.loadVersion();
      },
        (error) => this.alert.error(error)
      );
  }


  confirmRevise(item: docVersionDb, level: number) {
    if (!this.canRevise()) {
      this.alert.error(this.reviseErrorTooltip);
      return;
    }
    this.confirm.confirm({
      message: 'Create ' + this.newVersionLabel(level) + ' version of ' + item.Description + '?',
      header: 'Confirm',
      icon: 'fa fa-question-circle',
      accept: () => { this.goRevise(item.VersionID, level); }
    });
  }

  goRevise(versionID: number, level: number): void {
    this.versionService.revise(versionID, level)
      .subscribe((ret: UpsertRet) => {
        if (ret.returnID > 0) {
          this.alert.success('New version created!');
          this.versionID = ret.returnID;
          this.loadVersion();
          this.router.navigate(['/prodocs/version', ret.returnID]);
        }
      },
        (error) => this.alert.error(error)
      );
  }



  confirmNewLanguage(lang: SelectItem) {
    this.confirm.confirm({
      message: 'Make a new ' + lang.label + ' version of this version?',
      header: 'Confirm',
      icon: 'fa fa-question-circle',
      accept: () => { this.goNewLanguage(lang.value); }
    });
  }

  goNewLanguage(languageCode: string): void {
    console.log('create new language', this.versionID, languageCode);
    this.versionService.newLanguage(this.versionID, languageCode)
      .subscribe((ret: UpsertRet) => {
        if (ret.returnID > 0) {
          this.alert.success('New version created for new language!');
          this.versionID = ret.returnID;
          this.loadVersion();
          this.router.navigate(['/prodocs/version', ret.returnID]);

        }
      },
        (error) => this.alert.error(error)
      );
  }

  uploadFile(type: string, event) {
    console.log('upload PDF', event);
    let upload: File;
    for (const file of event.files) {
      const doc1: docUploadedDoc = new docUploadedDoc();
      doc1.FileType = type;
      doc1.FileName = file.name;
      doc1.DocumentID = this.version.DocumentID;
      doc1.VersionID = this.version.VersionID;

      doc1.FileOther = type === 'PDF' ? this.version.SourceFile : this.version.PublishedFile;


      upload = file;
      this.docService.upload(upload, doc1)
        .subscribe((data: any) => {
          console.log('after upload, got back ', data);
          this.alert.success('File uploaded.');

          if (type === 'PDF') {
            this.pdfUpload.clear();
            this.version.PublishedFile = doc1.FileName;
          } else if (type === 'SRC') {
            this.sourceUpload.clear();
            this.version.SourceFile = doc1.FileName;
          }

          console.log('update version', this.version);
          this.versionService.update(this.version)
            .subscribe((ret: UpsertRet) => {
              this.version = ret.returnItem;
              this.historyComponent.getVersionHistory();
            },
              (error) => this.alert.error(error)
            );
        },
          error => this.alert.error('Error uploading: ' + error)
        );
    }
  }

  allowDownload(type: string) {
    switch (type) {
      case 'PDF':
        return (this.version && this.version.PublishedFile && this.version.PublishedFile.length > 0);
      case 'SOURCE':
        return (this.version && this.version.SourceFile && this.version.SourceFile.length > 0);
      default:
        return false;
    }
  }

  setNoteCount(cnt: number) {
    this.noteCount = cnt;
  }
  userIsOwner(): boolean {
    return this.auth.currentUser.UserID == this.version?.OwnerID;
  }

  getFileName(type): string {
    switch (type) {
      case 'PDF':
        return this.version.PublishedFile;
      case 'SOURCE':
        return this.version.SourceFile;
    }

  }

  showFile(type: string) {
    var fileName = this.getFileName(type);
    const suffix = fileName.split('.').pop();
    var contentType = this.utility.contentTypeForSuffix(suffix);

    this.docService.getForVersion(this.versionID, type)
      .subscribe(
        (data: Blob) => {
          const theFile = new Blob([data], { type: contentType });
          const url = window.URL.createObjectURL(theFile);
          window.open(url, 'NewWindow', 'resizable,scrollbar,status,toolbar');
          this.historyComponent.getVersionHistory();
        },
        (error) => {
          console.log('error!', error);
          this.alert.error(error);
        });
  }

  downloadFile(type: string) {
    var fileName = this.getFileName(type);
    this.docService.getForVersion(this.versionID, type)
      .subscribe({
        next: (data) => {
          saveAs(data, fileName);
          this.historyComponent.getVersionHistory();
        }, error: (err) => {
          this.alert, console.error(err);

        }
      })
  }

  onSort(event) {
    this.localFilter.MultiSortMeta = event.multisortmeta;
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  onPage(event) {
    this.localFilter.rowsPerPage = event.rows;
    this.localFilter.currentRow = event.first;
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  doFilter(event) {
    // and store the filters for later recall
    this.persist.set(this.storedDTFilters, event.filters);
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  clearLocalFilter(dt: Table) {
    this.localFilter.VersionNumber = null;
    this.localFilter.PersonName = null;
    this.localFilter.Action = null;
    this.localFilter.ActionDate = null;
    this.localFilter.Note = null;
    this.localFilter.MultiSortMeta = this.multiSortMetaDefault;
    this.localFilter.rowsPerPage = +this.auth.currentUser.opts.PaginatorRows;
    this.localFilter.currentRow = 0;

    dt.clear();
    dt.filters = {};

    this.persist.set(this.storedDTFilters, null);
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  isOwnerOrAdmin(): boolean {
    if (this.auth.userHasRight('actProDocsAdmin') || this.version.OwnerID === +this.auth.currentUser.UserID) {
      return true;
    } else { return false; }
  }

  isPublished(): boolean {
    return this.version.StatusID === DocVersionStatusTypes.Published;
  }

  findAuthor(actors: docActorDb[]) {
    actors.forEach(a => {
      if (a.PersonID === +this.auth.currentUser.UserID) {
        if (a.RoleName === 'Author') {
          this.isAuthor = true;
        }
      }
    });
  }

  // pageRuleSetup() {
  //   this.canRevise();
  //   this.canPublish();
  // }

  canEditQuiz(): boolean {
    if (this.version?.StatusID === this.statusTypes.Archived) {
      this.editQuizErrorTooltip = 'Version Archived';
      return false;
    }
    if (this.isAuthor) {
      return true;
    }
    if (!this.isOwnerOrAdmin()) {
      this.editQuizErrorTooltip = 'Access to this function is limited to version owners, authors, and admins';
      return false;
    }
    return true;
  }

  canRevise(): boolean {
    if (!this.isOwnerOrAdmin()) {
      this.reviseErrorTooltip = 'Access to this function is limited to version owners and admins';
      return false;
    }
    return true;
  }

  canAddNewLanguage(): boolean {
    if (!this.isOwnerOrAdmin()) {
      this.newLanguageErrorTooltip = 'Access to this function is limited to version owners and admins';
      return false;
    }
    return true;
  }

  canPublish(): boolean {
    if (!this.version) return false;
    if (!this.isOwnerOrAdmin()) {
      this.publishErrorTooltip = 'Access to this function is limited to version owners and admins';
      return false;
    } else if (!this.version?.PublishedFile) {
      this.publishErrorTooltip = 'No published file specified.';
      return false;
    } else if (this.version?.StatusID === this.statusTypes.Published) {
      this.publishErrorTooltip = 'Version is already published';
      return false;
    } else if (this.version?.StatusID !== this.statusTypes.Approved) {
      this.publishErrorTooltip = 'Version must be approved before publication';
      return false;
    }
    return true;
  }


  // addNewLink() {
  //   this.addDocLink = true;
  // }

  getDocumentLinks() {
    this.docLinkService.getForVersion(this.version.VersionID).subscribe({
      next: (data: docDocLinkDb[]) => {
        this.links = data;
      }
    })
  }

  filterDocument(event) {
    const parm = (event.query.length > 0 ? event.query : '*');
    this.docService.search(parm)
      .subscribe(
        data => {
          this.filteredDocuments = this.prepFilteredDocs(data);
        },
        error => {
          this.alert.error(error);
        });
  }

  prepFilteredDocs(data: docDocumentSearch[]): docDocumentSearch[] {
    const ret = data.filter((d: docDocumentSearch) => d.DocumentID != this.version.DocumentID); // 10/25/2022 RJS filter out the current document;
    ret.forEach(a => a.DisplayName = a.DocumentID + ': ' + a.DocumentTitle);
    return ret;
  }

  selectDocument(document: docDocumentSearch) {
    if ((this.links.find(a => a.DocumentID === document.DocumentID) !== undefined)) {
      this.selectedDocumentLink = null;
      return;
    }

    let load = new docDocLinkDb();
    load.VersionID = this.version.VersionID;
    load.DocumentID = document.DocumentID;
    load.DocumentTitle = document.DocumentTitle;
    this.docLinkService.create(load).subscribe({
      next: (data) => {
        console.log(data);
        this.loadVersion();
        this.selectedDocumentLink = null;
      }
    })
  }

  deleteLink(link: docDocLinkDb) {
    this.docLinkService.delete(link.DocLinkID).subscribe({
      next: (data) => {
        this.loadVersion();
      }
    })
  }

  dropdownClick() {
    this.alert.info('dropdown clicked');
  }

  toggleQuiz() {
    if (!this.canEditQuiz()) {
      this.alert.error(this.editQuizErrorTooltip);
      return;
    }
    this.quizService.toggleActiveQuiz(this.version.QuizID, !this.version.IsActive).subscribe({
      next: (data: UpsertRet) => {
        console.log(data);
        this.alert.success('Quiz Toggled')
        this.loadVersion();
        this.historyComponent.getVersionHistory();
      }
    });
  }

  deleteQuiz() {
    if (!this.canEditQuiz()) {
      this.alert.error(this.editQuizErrorTooltip);
      return;
    }
    this.quizService.delete(this.version.QuizID).subscribe({
      next: () => {
        this.alert.success('Quiz Removed');
        this.loadVersion();
      }
    })
  }

  createQuiz() {
    if (!this.canEditQuiz()) {
      this.alert.error(this.editQuizErrorTooltip);
      return;
    }

    this.confirm.confirm({
      message: 'Create Quiz for ' + this.version.DocumentTitle + '?',
      header: 'Confirm',
      icon: 'fa fa-question-circle',
      accept: () => {
        let newQuiz = new docQuizDb();
        newQuiz.VersionID = this.version.VersionID;
        newQuiz.RequiredScore = 1.0;
        newQuiz.IsActive = true;
        newQuiz.Description = `New Quiz for ${this.version.DocumentTitle} version ${this.version.VersionNumber}`

        this.quizService.create(newQuiz).subscribe({
          next: (data) => {
            console.log(data)
            this.goQuiz();
            this.historyComponent.getVersionHistory();
          }
        });
      }
    });


  }

  goToDocument() {
    this.router.navigateByUrl('prodocs/document/' + this.version.DocumentID);
  }

  editVersionDetails() {
    // if (this.isPublished()) { this.mayEdit = false } else { this.mayEdit = this.isOwnerOrAdmin(); }
    this.mayEdit = true;
    this.setDetails();
  }

  cancelDetailEdit() {
    this.mayEdit = false;
    this.loadVersion();
  }

  setHistoryCount(count) {
    this.historyCount = count;
  }

  getSourceTooltip(): string {
    if (this.version?.SourceFile) {
      return 'Replace'
    } else {
      return 'Upload'
    }

  }
  getPDFTooltip(): string {
    if (this.version?.PublishedFile) {
      return 'Replace'
    } else {
      return 'Upload'
    }
  }
}

