import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { docDashboardService } from 'app/_data/pro-docs.service';
import { docAssignmentEx, docDashboardTeamAssignmentsDocuments, docDashboardTeamAssignmentsEmployee } from 'app/_models/DOC.model';
import { GoTimeEmployeeTabs } from 'app/_models/enums.model';
import { AuthService } from 'app/_services';
import { PersistenceService } from 'app/_services/persistence.service';
import { SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';

@Component({
  selector: 'prodocs-dashboard-team-table',
  templateUrl: './prodocs-dashboard-team-table.component.html',
  styleUrls: ['./prodocs-dashboard-team-table.component.scss']
})

// **********************
// ****** GLK 2022 ******
// **********************

export class ProdocsDashboardTeamTableComponent implements OnInit {

  // ** TOGGLES ** //
  viewByEmployee: boolean = true;
  flipType: string = 'Documents'
  showFullTeam: boolean = false;
  doneGettingData: boolean = false;

  // ** DATA ** //
  @Input() rawData: docAssignmentEx[] = [];
  employeeItems: docDashboardTeamAssignmentsEmployee[] = [];
  documentItems: docDashboardTeamAssignmentsDocuments[] = [];
  teamMembers: string[] = [];
  documents: string[] = [];
  warehouses: string[] = [];
  fullTeamString: string = 'Show';
  managerID: number = null;

  // ** DATA TABLE FILTERING ** //
  @ViewChild('dt', { static: true }) mydt: Table;

  multiSortMetaDefault = [{ field: 'SoftDueDate', order: 1 }];
  //#region FilterNames
  storedDTFilters = 'ProdocsTeamDashboardDTFilters';
  storedInputFilters = 'ProDocsTeamDashboardFilters';

  localFilter = {
    PersonName: null,
    DocumentTitle: null,
    SoftDueDate: null,
    HardDueDate: null,
    ShowFullTeam: false,
    Warehouse: null,
    ManagerID: null,
    MultiSortMeta: this.multiSortMetaDefault,
    rowsPerPage: +this.auth.currentUser.opts.PaginatorRows,
    currentRow: 0,
  };

  constructor(
    private dashboardService: docDashboardService,
    private persist: PersistenceService,
    private auth: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.managerID = +this.auth.currentUser.UserID;
    this.setLocalFilters();
    // this.getData(this.managerID);
  }

  getData(managerID: number) {
    console.log(managerID)
    this.managerID = managerID;
    this.localFilter.ManagerID = this.managerID;
    this.dashboardService.getAssignmentsForTeam(managerID, this.showFullTeam).subscribe({
      next: (data: docAssignmentEx[]) => {
        console.log('%cTEAM ASSIGNMENT LOG', 'color: orange; font-size: 15px');
        this.rawData = data;      // Store this later for flipping without having to go back to server.

        this.rawData.forEach(a => {
          a.AssignedDate = a.AssignedDate ? new Date(a.AssignedDate) : null;
          a.SoftDueDate = a.SoftDueDate ? new Date(a.SoftDueDate) : null;
          a.HardDueDate = a.HardDueDate ? new Date(a.HardDueDate) : null;
        })

        this.getTeamMembers(data);
        this.getDocumentTitles(data);
        this.getWarehouses(data);

        this.rawData.forEach(a => {
          a.DaysTillSoft = this.daysTill(a.SoftDueDate);
          a.DaysTillHard = this.daysTill(a.HardDueDate);
        })
        console.log(this.rawData);

        setTimeout(() => this.setTableParms(), 200);

      }
    });
  }

  setTableParms() {
    this.mydt.alwaysShowPaginator = true;
    this.mydt.rows = this.localFilter.rowsPerPage; //  +this.authService.currentUser.opts.PaginatorRows;
    this.mydt.first = this.localFilter.currentRow;
  }

  daysTill(dueDate: Date): number {
    let ret = Math.round((new Date(dueDate.toString()).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
    if (ret < 0)
      ret = 0;
    return ret;
  }

  getTeamMembers(assignments: docAssignmentEx[]) {
    this.teamMembers = [... new Set(assignments.map(member => member.PersonName))].sort((a, b) => a > b ? 1 : -1);
  }

  getDocumentTitles(assignments: docAssignmentEx[]) {
    this.documents = [... new Set(assignments.map(member => member.DocumentTitle))].sort((a, b) => a > b ? 1 : -1);
  }

  getWarehouses(assignments: docAssignmentEx[]) {
    this.warehouses = [... new Set(assignments.map(member => member.Warehouse))].sort((a, b) => a > b ? 1 : -1);
  }

  formatDataForDocument(data: docAssignmentEx[]) {
    this.documentItems = [];
    let docIDs = [...new Set(data.map(doc => doc.DocumentID))];
    console.log(docIDs);

    docIDs.forEach(doc => {
      let temp = new docDashboardTeamAssignmentsDocuments();
      temp.Employees = [];
      temp.DocumentID = doc;

      data.forEach(a => {
        if (a.DocumentID === doc) {
          temp.DocumentTitle = a.DocumentTitle;
          temp.Employees.push(a);
        }
      });
      this.documentItems.push(temp);
    });
    console.log(this.documentItems)
  }

  getTextColor(days: number): string {
    if (days >= 5) { return '#069206'; }
    else if (days < 5 && days > 0) { return 'orange'; }
    else { return 'red'; }
  }

  toggleShowFull() {
    this.showFullTeam = !this.showFullTeam;
    console.log('%c HELLO THERE', 'color:orange')
    this.getData(this.managerID);
  }

  viewAssignments(personID: number) {
    this.router.navigateByUrl('time/employee/' + personID, {
      state: { 'selectedTab': GoTimeEmployeeTabs.Assignments, 'forDate': new Date() }
    });
  }

  toggleTeam(showFull: boolean) {
    console.log('in toggleTeam', showFull);
    console.log('managerID', this.managerID)
    if (this.showFullTeam !== showFull) {
      this.showFullTeam = showFull;
      this.localFilter.ShowFullTeam = this.showFullTeam;
      this.getData(this.managerID);
    }
  }

  // ***************************
  // TABLE SORTING AND FILTERING
  // ***************************
  setLocalFilters() {
    this.mydt.rows = +this.auth.currentUser.opts.PaginatorRows;
    this.mydt.rowsPerPageOptions = this.auth.currentUser.opts.PaginatorRowOptions;
    this.mydt.paginator = true;
    this.mydt.alwaysShowPaginator = false;

    const temp = this.persist.get(this.storedInputFilters);
    if (temp) {
      this.localFilter = temp;
      this.mydt.first = this.localFilter.currentRow;

      // Multisort Meta
      if (this.localFilter.MultiSortMeta) {
        this.mydt.multiSortMeta = this.localFilter.MultiSortMeta;
      } else {
        this.mydt.multiSortMeta = this.multiSortMetaDefault;
      }

      // Show Full Team
      if (this.localFilter.ShowFullTeam) {
        this.showFullTeam = this.localFilter.ShowFullTeam;
      } else {
        this.showFullTeam = false;
      }

      // ManagerID
      if (this.localFilter.ManagerID) {
        this.managerID = this.localFilter.ManagerID;
      } else {
        this.managerID = +this.auth.currentUser.UserID;
      }
      // Soft Due Date
      if (temp.SoftDueDate) {
        if (temp.SoftDueDate[0]) {
          temp.SoftDueDate[0] = new Date(temp.SoftDueDate[0]);
        }
        if (temp.SoftDueDate[1]) {
          temp.SoftDueDate[1] = new Date(temp.SoftDueDate[1]);
        }
      }

      // Hard Due Date
      if (temp.HardDueDate) {
        if (temp.HardDueDate[0]) {
          temp.HardDueDate[0] = new Date(temp.HardDueDate[0]);
        }
        if (temp.HardDueDate[1]) {
          temp.HardDueDate[1] = new Date(temp.HardDueDate[1]);
        }
      }
    } else {
      this.showFullTeam = false;
      this.managerID = +this.auth.currentUser.UserID;
    }

    const tmp = this.persist.get(this.storedDTFilters);
    if (tmp) {
      this.mydt.filters = tmp;
    }
  }

  doFilter(event) {
    this.persist.set(this.storedDTFilters, event.filters);
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  onSort(event) {
    this.localFilter.MultiSortMeta = event.multisortmeta;
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  onPage(event) {
    this.localFilter.rowsPerPage = event.rows;
    this.localFilter.currentRow = event.first;
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  clearLocalFilter(dt: Table) {

    this.localFilter.PersonName = null;
    this.localFilter.DocumentTitle = null;
    this.localFilter.SoftDueDate = null;
    this.localFilter.HardDueDate = null;
    this.localFilter.ShowFullTeam = null;
    this.localFilter.Warehouse = null;
    this.localFilter.ManagerID = null;


    this.localFilter.MultiSortMeta = this.multiSortMetaDefault;
    this.localFilter.rowsPerPage = +this.auth.currentUser.opts.PaginatorRows;
    this.localFilter.currentRow = 0;

    dt.clear();
    dt.filters = {};

    this.persist.set(this.storedDTFilters, null);
    this.persist.set(this.storedInputFilters, this.localFilter);

    this.getData(this.managerID);
  }
}
