import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import { secGroup } from '../_models/SEC.model';

import { HttpClient } from '@angular/common/http';

@Injectable()
export class GroupsService {

  api = 'secGroup/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<secGroup[]>(this.apiURL + this.api);

  }
  create(group: secGroup) {
    return this.http.post(this.apiURL + this.api, group);
  }

  update(group: secGroup) {
    return this.http
      .put(this.apiURL + this.api + group.GroupID, group);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getForEntityUser(id: number) {
    return this.http.get<secGroup[]>(this.apiURL + this.api + 'entityUser/' + id );
  }

  getAvailableForEntityUser(id: number) {
    return this.http.get<secGroup[]>(this.apiURL + this.api + 'entityUser/' + id + '/available');
  }

  putForEntityUser(id: number, groups: secGroup[]) {
    return this.http.put(this.apiURL + this.api + 'entityUser/' + id, groups);
  }

  getAvailableForRight(id: number) {
    return this.http.get<secGroup[]>(this.apiURL + this.api + 'right/' + id + '/available');
  }

  getAssignedForRight(id: number) {
    return this.http.get<secGroup[]>(this.apiURL + this.api + 'right/' + id + '/assigned');
  }

  putAssignedForRight(id: number, groups: secGroup[]) {
    return this.http.put(this.apiURL + this.api + 'right/' + id, groups);
  }
  
  getAssignedGroupsForReport(ReportID: number) {
    return this.http.get<secGroup[]>(this.apiURL + this.api + 'report/' + ReportID + '/assigned');
  }

  getUnAssignedGroupsForReport(ReportID: number) {
    return this.http.get<secGroup[]>(this.apiURL + this.api + 'report/' + ReportID + '/available');
  }

  // pubAssignedForReport(id: number, groups: secGroup[]) {
  //   return this.http.put(this.apiURL + this.api + 'report/' + id, groups);
  // }

}

