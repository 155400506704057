import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { timeClientJobService, timeEmployeeService } from 'app/_data/time.service';
import { BillType, ClientRule, cfgTrackTypes } from 'app/_models/enums.model';
import { timeAssignedFunction, timeClientFunction, timeClientFunctionDb, timeClientJob, timeEmployeeSnapshot, timeFunctionDb } from 'app/_models/TIME.model';
import { AlertService, AuthService } from 'app/_services';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'arc-select-function',
  templateUrl: './select-function.component.html',
  styleUrls: ['./select-function.component.scss']
})
export class SelectFunctionComponent implements OnInit, OnDestroy {

  Functions: timeAssignedFunction[] = [];
  FrequentFunctions: timeAssignedFunction[] = [];
  selectedFunction: timeAssignedFunction = null;

  Clients: timeClientFunction[] = [];
  selectedClient: timeClientFunction = null;

  ClientRule: ClientRule;

  selectJob: boolean = false;
  jobs: timeClientJob[] = [];
  selectedJob: timeClientJob = null;

  selectOrderID: boolean = false;

  Billing: SelectItem[];

  @Input() currentFunction?: timeAssignedFunction;
  @Output() setJob = new EventEmitter<bundle>();
  @Output() setTimeTrack = new EventEmitter<bundle>();
  private _subscription: Subscription;
  userID: number;
  employee: timeEmployeeSnapshot;
  packageToSend: bundle = new bundle();


  complete: boolean = false;
  mayBill: boolean = false;
  mustBill: boolean = false;
  isBilling: boolean = false;
  showBilling: boolean = false;

  viewAdminNote: boolean = false;
  adminNotes: string[] = [];

  holdOrderStatus: boolean = false;

  constructor(
    private auth: AuthService,
    private employeeService: timeEmployeeService,
    private jobService: timeClientJobService,
    private alert: AlertService
  ) { }

  ngOnInit(): void {
    this.Billing = [
      { label: 'Is Billable', value: true },
      { label: 'Not Billable', value: false }
    ];
    this.userID = this.auth.currentUser.UserID;
    if (this.currentFunction) {
      if (this.currentFunction.ClientRule >= ClientRule.AllowClient) {
        this.selectFunction(this.currentFunction);
      }
    }

    this._subscription = this.employeeService.signedInEmployee$.subscribe(
      (value) => {
        if (value) {
          this.employee = value;
          this.Functions = value.AssignedFunctions;
          this.FrequentFunctions = value.FrequentFunctions;

          if (value.Notes.length) {
            this.viewAdminNote = true;
            this.adminNotes = value.Notes;
          }
        } else {
          this.Functions = [];
          this.FrequentFunctions = [];
          this.Clients = []
        }
      },
      (error) => this.alert.error(error)
    );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  /**
   * Select a Function
   * @param {timeAssignedFunction} func The function that them employee would like to clock into
   */
  selectFunction(func: timeAssignedFunction) {
    if (func.IsBlocked) {
      return;
    }
    this.selectedFunction = func;
    this.selectedClient = null;
    this.selectedJob = null;
    this.isBilling = null;
    this.showBilling = false;
    this.selectJob = false;
    this.selectOrderID = false;

    if (func.ClientRule === 0) {
      this.complete = true;
      this.selectedClient = null;
      this.Clients = [];
      this.selectedFunction = func;
      this.finishSelection();
    }
    if (func.ClientRule >= ClientRule.AllowClient) {
      this.Clients = func.Clients;
    }
  }

  /**
   * Select a Client
   * @param {timeClientFunction} client The client that employee would like
   */
  selectClient(client: timeClientFunction) {
    if (!client) return; // 2/15/2023 RJS -- to avoid creating TS withouth TRK
    this.selectedClient = client;
    this.selectedJob = null;
    this.isBilling = null;
    this.showBilling = false;
    this.selectJob = false;
    this.selectOrderID = false;

    this.complete = true;
    this.selectedClient = client;
    if (this.selectedClient !== null) {
      this.mayBill = client.BillType >= BillType.MayBill;
      this.mustBill = client.BillType >= BillType.MustBill;

      if (client.TrackTypeID === cfgTrackTypes.ClientReference) {               // Selecting a Client Supplied Reference Number
        this.jobService.getJobsForClientFunction(client.ClientFunctionID, this.userID).subscribe(
          (val) => {
            if (val) {
              this.selectJob = true;
              // this.isBilling = false;
              this.jobs = val;
            }
          },
          (error) => this.alert.error(error)
        );
      } else if (client.TrackTypeID === cfgTrackTypes.Order) {                  // Entering OrderID and transfer to TimeTrack
        this.selectOrderID = true;
        if (this.mustBill === true) { this.isBilling = true; this.beginTimeTrack() }
        else if (this.mustBill === false && this.mayBill === true) { this.holdOrderStatus = true; this.showBilling = true; }
        else { this.isBilling = false; this.beginTimeTrack(); }
      } else {
        console.log('else', this.mustBill, this.mayBill);                                              // Else Select Billing and Continue
        if (this.mustBill === true) { this.isBilling = true; this.finishSelection() }
        else if (this.mustBill === false && this.mayBill === true) { this.showBilling = true; }
        else { this.isBilling = false; this.finishSelection(); }
      }
    } else { this.isBilling = false; this.finishSelection() }
  }

  selectBillable(val: boolean) {
    this.isBilling = val;
    // this.selectedJob = null;
    this.selectJob = false;
    this.selectOrderID = false;
    if (this.holdOrderStatus)
      this.beginTimeTrack();
    else
      this.finishSelection();
  }

  assignJob(val: timeClientJob) {
    this.selectedJob = val;
    this.isBilling = null;
    if (this.mustBill === true) { this.isBilling = true; this.finishSelection() }
    else if (this.mustBill === false && this.mayBill === true) { this.showBilling = true; }
    else { this.isBilling = false; this.finishSelection(); }
  }

  /**
   * Compile all the necessary data and send it off to parent container for database creation.
   */
  finishSelection() {
    this.packageToSend.func = this.selectedFunction;
    this.packageToSend.clnt = this.selectedClient;
    this.packageToSend.clientJobReferenceNote = this.selectedJob?.ClientJobReference;
    this.packageToSend.billClient = this.isBilling;
    console.log('e;mitting', this.packageToSend);
    this.setJob.emit(this.packageToSend);
  }

  /** Set Function, Client, Job, and Billing flags to null */
  goBack() {
    this.selectedFunction = null;
    this.selectedClient = null;
    this.selectedJob = null;
    this.selectJob = false;
    this.showBilling = false;
    this.holdOrderStatus = false;
  }

  /** If the Client-Function relationship requires the use of TimeTrack, 
   * send off to the TimeTrack MOD */
  beginTimeTrack() {
    this.packageToSend.func = this.selectedFunction;
    this.packageToSend.clnt = this.selectedClient;
    this.packageToSend.billClient = this.isBilling;
    console.log('Selected Function For TimeTrack', this.packageToSend);
    this.setTimeTrack.emit(this.packageToSend);
  }
}

export class bundle {
  func: timeAssignedFunction;
  clnt: timeClientFunction;
  clientJobReferenceNote?: string;
  billClient: boolean;
}