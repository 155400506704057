<div style="display:flex;">
    <div style="width:20%; padding: 10px;">
        <arc-team-selector [forManagerID]="managerID" [showFullTeam]="showFullTeam" (onSelectManager)="getData($event)"
        (onToggleTeam)="toggleTeam($event)"></arc-team-selector>
    </div>
    <div style="width: 80%; display: flex; justify-content: center;">
        <div style="width: 100%; padding: 10px;">
            <p-panel header="Employee Assignment Status">
                <p-table #dt [value]="rawData" sortMode="multiple" [showJumpToPageDropdown]="true" [showPageLinks]="false" [multiSortMeta]="multiSortMetaDefault"
                    (onFilter)="doFilter($event)" (onSort)="onSort($event)" (onPage)="onPage($event)">
                    <!-- Table Header Template -->
                    <ng-template pTemplate="header">
                        <!-- Header Row -->
                        <tr>
                            <th style="width:50px;"></th>
                            <th pSortableColumn="PersonName" [ngStyle]="{ 'width':'20%', 'text-align': 'left' }">
                                Person Name
                                <span>
                                    <p-sortIcon field="PersonName"></p-sortIcon>
                                </span>
                            </th>
                            <th pSortableColumn="DocumentTitle" [ngStyle]="{ 'width':'25%', 'text-align': 'left' }">
                                Document Title
                                <span>
                                    <p-sortIcon field="DocumentTitle"></p-sortIcon>
                                </span>
                            </th>
                            <th pSortableColumn="SoftDueDate" [ngStyle]="{ 'width':'20%', 'text-align': 'left' }">
                                Soft Due Date
                                <span>
                                    <p-sortIcon field="SoftDueDate"></p-sortIcon>
                                </span>
                            </th>
                            <th pSortableColumn="HardDueDate" [ngStyle]="{ 'width':'20%', 'text-align': 'left' }">
                                Hard Due Date
                                <span>
                                    <p-sortIcon field="HardDueDate"></p-sortIcon>
                                </span>
                            </th>
                            <th pSortableColumn="Warehouse" [ngStyle]="{ 'text-align': 'left' }">
                                Warehouse
                                <span>
                                    <p-sortIcon field="Warehouse"></p-sortIcon>
                                </span>
                            </th>
                        </tr>
                        <!-- Filters Row -->
                        <tr>
                            <th class="col-icon-delete">
                                <div class="col-icon-delete">
                                    <i class="fa fa-ban" (click)="clearLocalFilter(dt)"></i>
                                </div>
                            </th>
                            <th>
                                <p-dropdown [options]="teamMembers" [(ngModel)]="localFilter.PersonName" (onClear)="dt.filter(localFilter.PersonName, null, null)"
                                    [showClear]="true" placeholder="Team Member" appendTo="body" [editable]="true"
                                    (onChange)="dt.filter(localFilter.PersonName, 'PersonName', 'contains')"></p-dropdown>
                            </th>
                            <th>
                                <p-dropdown [options]="documents" [(ngModel)]="localFilter.DocumentTitle" (onClear)="dt.filter(localFilter.DocumentTitle, null, null)"
                                    [showClear]="true" placeholder="Document Title" appendTo="body" [editable]="true"
                                    (onChange)="dt.filter(localFilter.DocumentTitle, 'DocumentTitle', 'contains')"></p-dropdown>
                            </th>
                            <th>
                                <p-calendar [(ngModel)]='localFilter.SoftDueDate'
                                    (onSelect)="dt.filter(localFilter.SoftDueDate, 'SoftDueDate', 'dateRangeFilter')"
                                    (onClearClick)="dt.filter('', 'SoftDueDate','contains')" [showButtonBar]="true"
                                    [readonlyInput]="true" [monthNavigator]="true" [yearNavigator]="true"
                                    [inputStyle]="{'width': '100%'}" appendTo="body" yearRange="2000:2030" selectionMode="range">
                                </p-calendar>
                            </th>
                            <th>
                                <p-calendar [(ngModel)]='localFilter.HardDueDate'
                                    (onSelect)="dt.filter(localFilter.HardDueDate, 'HardDueDate', 'dateRangeFilter')"
                                    (onClearClick)="dt.filter('', 'HardDueDate','contains')" [showButtonBar]="true"
                                    [readonlyInput]="true" [monthNavigator]="true" [yearNavigator]="true"
                                    [inputStyle]="{'width': '100%'}" appendTo="body" yearRange="2000:2030" selectionMode="range">
                                </p-calendar>
                            </th>
                            <th>
                                <p-dropdown [options]="warehouses" [(ngModel)]="localFilter.Warehouse" (onClear)="dt.filter(localFilter.Warehouse, null, null)"
                                    [showClear]="true" placeholder="Warehouse" appendTo="body" [editable]="true"
                                    (onChange)="dt.filter(localFilter.Warehouse, 'Warehouse', 'contains')"></p-dropdown>
                            </th>
                        </tr>
                    </ng-template>
                    <!-- Table Body Template -->
                    <ng-template pTemplate="body" let-rowData let-assignment>
                        <!-- Data Rows -->
                        <tr [pSelectableRow]="rowData" style="font-size: .65vw">
                            <td style="text-align: center;">
                                <p-button icon="pi pi-book" (onClick)="viewAssignments(assignment.PersonID)" pTooltip="View Assignment"></p-button>
                            </td>
                            <td>
                                <p>{{ assignment.PersonName }}</p>
                            </td>
                            <td>
                                <p>{{ assignment.DocumentTitle }}</p>
                            </td>
                            <td>
                                <div style="display:flex; justify-content:space-evenly;">
                                    <p *ngIf="assignment.DaysTillSoft > 0"> {{ assignment.SoftDueDate | date: 'mediumDate' }} </p> <p *ngIf="assignment.DaysTillSoft > 0" class="dateIndicator" [style.background-color]="getTextColor(assignment.DaysTillSoft)"> {{ assignment.DaysTillSoft }} Day(s)</p>
                                    <p *ngIf="assignment.DaysTillSoft === 0"> {{ assignment.SoftDueDate | date: 'mediumDate' }} </p> <p *ngIf="assignment.DaysTillSoft === 0" class="dateIndicator" [style.background-color]="getTextColor(assignment.DaysTillSoft)"> OVERDUE</p>
                                </div>
                            </td>
                            <td>
                                <div style="display:flex; justify-content:space-evenly;">
                                    <p *ngIf="assignment.DaysTillHard > 0"> {{ assignment.HardDueDate | date: 'mediumDate' }} </p> <p *ngIf="assignment.DaysTillHard > 0" class="dateIndicator" [style.background-color]="getTextColor(assignment.DaysTillSoft)"> {{ assignment.DaysTillHard }} Day(s)</p>
                                    <p *ngIf="assignment.DaysTillHard === 0"> {{ assignment.HardDueDate | date: 'mediumDate' }} </p> <p *ngIf="assignment.DaysTillHard === 0" class="dateIndicator" [style.background-color]="getTextColor(assignment.DaysTillHard)"> OVERDUE</p>
                                </div>
                            </td>
                            <td>
                                <p>{{ assignment.Warehouse }}</p>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Table Footer Template -->
                    <ng-template pTemplate="summary">
                        <div style="display:flex; justify-content:space-between">
                            <p>Assignment Count: {{ dt.totalRecords }} </p>
                            <p-button label="Refresh" icon="pi pi-refresh" (click)="getData()"></p-button>
                        </div>
                    </ng-template>
                </p-table>
        
            </p-panel>
        </div>
    </div>
</div>