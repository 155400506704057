<div style="background-color: whitesmoke; width: 50%; height: 50%; border-radius: 10px; display: flex; 
            flex-direction: column; justify-content: center; padding: 25px; margin: 0; overflow: hidden;" *ngIf="changePass">
    <div>
        <div>
            <h2> {{ selectedValidation.ValidationMessages }} </h2>
        </div>
        <div class="p-grid" style="align-items: center;">
            <div class="p-col-2">
                <label for="OldPassword">Current Password</label>
            </div>
            <div class="p-col-6">
                <input pInputText type="password" id="OldPassword" name="OldPassword"
                        placeholder="Enter Current Password" #OldPassword="ngModel"
                    [(ngModel)]="oldPass" required (blur)="checkPassword()" />
            </div>
            <div class="p-col-4">
                <p *ngIf="currentPassErr" style="color: red;">Password does not match current record.</p>
            </div>
        </div>
        <div class="p-grid" style="align-items: center;">
            <div class="p-col-2">
                <label for="UserPassword">New Password</label>
            </div>
            <div class="p-col-6">
                <input pInputText type="password" id="UserPassword" name="UserPassword" #UserPassword="ngModel"
                    [(ngModel)]="newPass" required placeholder="Enter New Password"
                    pattern="^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,20}$" />
                        <!-- suggested by Cilian:   ^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])(?=.*[^\w\s]?).{8,20}$ 
                            However, the bigger problem is doing the match using something other than the 'pattern' setting (below) -->

            </div>
            <div class="p-col-4">
                <!-- <arc-show-errors [control]="UserPassword">Not a valid password.</arc-show-errors> -->
            </div>
        </div>
        <div class="p-grid" style="align-items: center;">
            <div class="p-col-2">
                <label for="ConfirmPassword">Confirm Password</label>
            </div>
            <div class="p-col-6">
                <input pInputText type="password" id="ConfirmPassword" name="ConfirmPassword"
                    #ConfirmPassword="ngModel" [(ngModel)]="confirmNewPass" required
                    placeholder="Confirm New Password"
                    pattern="{{ newPass }}" />
            </div>
            <div class="p-col-4">
                <!-- <arc-show-errors [control]="ConfirmPassword">New Passwords do not match.</arc-show-errors> -->
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-2"><p style="font-weight: bold;">Password must contain: </p></div>
            <div class="p-col-6"><p style="color: red">One Uppercase Letter, One Lowercase Letter, One Number</p></div>     
        </div>
        <p-button pRipple label="Confirm" styleClass="p-button-lg" icon="pi pi-save" [ngStyle]="{'float':'right'}" (click)="changePassword()"></p-button>
    </div>
</div>
<!-- ~ ------------------------------- ~ -->
<br>
<!-- ~ ------------------------------- ~ -->
<div style="background-color: whitesmoke; width: 50%; height: 50%; border-radius: 10px; display: flex; 
            flex-direction: column; justify-content: center; padding: 25px; margin: 0; overflow: hidden;" *ngIf="changePIN">
    <div>
        <div>
            <h2> {{ selectedValidation.ValidationMessages }} </h2>
        </div>
        <div class="p-grid" style="align-items: center;">
            <div class="p-col-2">
                <label for="OldPIN">Current PIN</label>
            </div>
            <div class="p-col-6">
                <input pInputText type="password" id="OldPIN" name="OldPIN"
                        placeholder="Enter Current PIN" #OldPIN="ngModel"
                    [(ngModel)]="oldPIN" required (blur)="checkPIN()" />
            </div>
            <div class="p-col-4">
                <p *ngIf="currentPINErr" style="color: red;">PIN does not match current record.</p>
            </div>
        </div>
        <div class="p-grid" style="align-items: center;">
            <div class="p-col-2">
                <label for="NewPIN">New PIN</label>
            </div>
            <div class="p-col-6">
                <input pInputText type="password" id="NewPIN" name="NewPIN" #NewPIN="ngModel"
                    [(ngModel)]="newPIN" required placeholder="Enter New PIN"
                    pattern="^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,20}$" />
            </div>
            <div class="p-col-4">
                <!-- <arc-show-errors [control]="NewPIN">Not a valid PIN.</arc-show-errors> -->
            </div>
        </div>
        <div class="p-grid" style="align-items: center;">
            <div class="p-col-2">
                <label for="ConfirmPIN">Confirm PIN</label>
            </div>
            <div class="p-col-6">
                <input pInputText type="password" id="ConfirmPIN" name="ConfirmPIN"
                    #ConfirmPIN="ngModel" [(ngModel)]="confirmNewPIN" required
                    placeholder="Confirm New PIN"
                    pattern="{{ newPIN }}" />
            </div>
            <div class="p-col-4">
                <arc-show-errors [control]="ConfirmPIN">New PINs do not match.</arc-show-errors>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-2"><p style="font-weight: bold;">PIN must contain: </p></div>
            <div class="p-col-6"><p style="color: red">Between 4 and 8 numbers.</p></div>     
        </div>
        <p-button pRipple label="Confirm" styleClass="p-button-lg" icon="pi pi-save" [ngStyle]="{'float':'right'}" (click)="changeUserPIN()" [disabled]="newPIN !== confirmNewPIN" ></p-button>
    </div>
</div>
