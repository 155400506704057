import { Injectable, Inject } from '@angular/core';
 import { chgCarrierRateEx } from '../_models/TableCustom.model';
 import { chgCarrierRate } from '../_models/CHG.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CarrierRatesService {


  api = 'chgCarrierRate/';
  apiEx = 'chgCarrierRatesEx/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<chgCarrierRate[]>(this.apiURL + this.api);

  }
  create(carrierRate: chgCarrierRate) {
    return this.http.post(this.apiURL + this.api, carrierRate);
  }

  update(carrierRate: chgCarrierRate) {
    return this.http
      .put(this.apiURL + this.api + carrierRate.CarrierRateID, carrierRate);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getAllEx() {
    return this.http.get<chgCarrierRateEx[]>(this.apiURL + this.apiEx);
  }

}

