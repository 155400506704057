import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
    cfgBillTypeDb,
    cfgClientRulesDb,
    cfgDepartmentDb,
    cfgEmployeeTypeDb,
    cfgIssueTypeDb,
    cfgPayrollType,
    cfgScheduleTypeDb,
    cfgTerminationReasonDb,
    cfgTrackTypeDb,
    cfgWorkGroupDb,
    cfgWorkStatusDb,
    cfgFunctionTypeDb
} from 'app/_models/CFG.model';

@Injectable()
export class cfgBillTypesService {
    api = 'cfgBillType';

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    getAll() {
        return this.http.get<cfgBillTypeDb[]>(this.apiURL + this.api);

    }
    get(id: number) {
        return this.http.get<cfgBillTypeDb>(this.apiURL + this.api + id);
    }

    create(BillTypeDb: cfgBillTypeDb) {
        return this.http.post(this.apiURL + this.api, BillTypeDb);
    }

    update(BillTypeDb: cfgBillTypeDb) {
        return this.http
            .put(this.apiURL + this.api + BillTypeDb.BillTypeID, BillTypeDb);
    }

    delete(id: number) {
        return this.http.delete(this.apiURL + this.api + id);
    }


}

@Injectable()
export class cfgClientRulesService {
    api = 'cfgClientRule/';

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    getAll() {
        return this.http.get<cfgClientRulesDb[]>(this.apiURL + this.api);

    }
    get(id: number) {
        return this.http.get<cfgClientRulesDb>(this.apiURL + this.api + id);
    }

    create(ClientRuleDb: cfgClientRulesDb) {
        return this.http.post(this.apiURL + this.api, ClientRuleDb);
    }

    update(ClientRuleDb: cfgClientRulesDb) {
        return this.http
            .put(this.apiURL + this.api + ClientRuleDb.ClientRuleID, ClientRuleDb);
    }

    delete(id: number) {
        return this.http.delete(this.apiURL + this.api + id);
    }
}

@Injectable()
export class cfgDepartmentsService {
    api = 'cfgDepartment/';

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    getAll() {
        return this.http.get<cfgDepartmentDb[]>(this.apiURL + this.api);

    }
    get(id: number) {
        return this.http.get<cfgDepartmentDb>(this.apiURL + this.api + id);
    }

    create(DepartmentDb: cfgDepartmentDb) {
        return this.http.post(this.apiURL + this.api, DepartmentDb);
    }

    update(DepartmentDb: cfgDepartmentDb) {
        return this.http
            .put(this.apiURL + this.api + DepartmentDb.DepartmentID, DepartmentDb);
    }

    delete(id: number) {
        return this.http.delete(this.apiURL + this.api + id);
    }
}

@Injectable()
export class cfgEmployeeTypesService {
    api = 'cfgEmployeeType/';

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    getAll() {
        return this.http.get<cfgEmployeeTypeDb[]>(this.apiURL + this.api);

    }
    get(id: number) {
        return this.http.get<cfgEmployeeTypeDb>(this.apiURL + this.api + id);
    }

    create(EmployeeTypeDb: cfgEmployeeTypeDb) {
        return this.http.post(this.apiURL + this.api, EmployeeTypeDb);
    }

    update(EmployeeTypeDb: cfgEmployeeTypeDb) {
        return this.http
            .put(this.apiURL + this.api + EmployeeTypeDb.EmployeeTypeID, EmployeeTypeDb);
    }

    delete(id: number) {
        return this.http.delete(this.apiURL + this.api + id);
    }
}

@Injectable()
export class cfgFunctionTypesService {
    api = 'cfgFunctionType/';

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    getAll() {
        return this.http.get<cfgFunctionTypeDb[]>(this.apiURL + this.api);

    }
    get(id: number) {
        return this.http.get<cfgFunctionTypeDb>(this.apiURL + this.api + id);
    }

    create(FunctionTypeDb: cfgFunctionTypeDb) {
        return this.http.post(this.apiURL + this.api, FunctionTypeDb);
    }

    update(FunctionTypeDb: cfgFunctionTypeDb) {
        return this.http
            .put(this.apiURL + this.api + FunctionTypeDb.FunctionTypeID, FunctionTypeDb);
    }

    delete(id: number) {
        return this.http.delete(this.apiURL + this.api + id);
    }
}

@Injectable()
export class cfgIssueTypesService {
    api = 'cfgIssueType/';

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    getAll() {
        return this.http.get<cfgIssueTypeDb[]>(this.apiURL + this.api);

    }
    get(id: number) {
        return this.http.get<cfgIssueTypeDb>(this.apiURL + this.api + id);
    }

    create(IssueTypeDb: cfgIssueTypeDb) {
        return this.http.post(this.apiURL + this.api, IssueTypeDb);
    }

    update(IssueTypeDb: cfgIssueTypeDb) {
        return this.http
            .put(this.apiURL + this.api + IssueTypeDb.IssueTypeID, IssueTypeDb);
    }

    delete(id: number) {
        return this.http.delete(this.apiURL + this.api + id);
    }
}

@Injectable()
export class cfgPayrollTypesService {
    api = 'cfgPayrollType/';

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    getAll() {
        return this.http.get<cfgPayrollType[]>(this.apiURL + this.api);

    }
    get(id: number) {
        return this.http.get<cfgPayrollType>(this.apiURL + this.api + id);
    }

    create(PayrollTypeDb: cfgPayrollType) {
        return this.http.post(this.apiURL + this.api, PayrollTypeDb);
    }

    update(PayrollTypeDb: cfgPayrollType) {
        return this.http
            .put(this.apiURL + this.api + PayrollTypeDb.PayrollTypeID, PayrollTypeDb);
    }

    delete(id: number) {
        return this.http.delete(this.apiURL + this.api + id);
    }
}


@Injectable()
export class cfgTerminationReasonsService {
    api = 'cfgTerminationReason/';

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    getAll() {
        return this.http.get<cfgTerminationReasonDb[]>(this.apiURL + this.api);

    }
    get(id: number) {
        return this.http.get<cfgTerminationReasonDb>(this.apiURL + this.api + id);
    }

    create(TerminationReasonDb: cfgTerminationReasonDb) {
        return this.http.post(this.apiURL + this.api, TerminationReasonDb);
    }

    update(TerminationReasonDb: cfgTerminationReasonDb) {
        return this.http
            .put(this.apiURL + this.api + TerminationReasonDb.TerminationReasonID, TerminationReasonDb);
    }

    delete(id: number) {
        return this.http.delete(this.apiURL + this.api + id);
    }
}

@Injectable()
export class cfgScheduleTypesService {
    api = 'cfgScheduleType/';

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    getAll() {
        return this.http.get<cfgScheduleTypeDb[]>(this.apiURL + this.api);

    }
    get(id: number) {
        return this.http.get<cfgScheduleTypeDb>(this.apiURL + this.api + id);
    }

    create(ScheduleTypeDb: cfgScheduleTypeDb) {
        return this.http.post(this.apiURL + this.api, ScheduleTypeDb);
    }

    update(ScheduleTypeDb: cfgScheduleTypeDb) {
        return this.http
            .put(this.apiURL + this.api + ScheduleTypeDb.ScheduleTypeID, ScheduleTypeDb);
    }

    delete(id: number) {
        return this.http.delete(this.apiURL + this.api + id);
    }
}

@Injectable()
export class cfgTrackTypesService {
    api = 'cfgTrackType/';

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    getAll() {
        return this.http.get<cfgTrackTypeDb[]>(this.apiURL + this.api);

    }
    get(id: number) {
        return this.http.get<cfgTrackTypeDb>(this.apiURL + this.api + id);
    }

    create(TrackTypeDb: cfgTrackTypeDb) {
        return this.http.post(this.apiURL + this.api, TrackTypeDb);
    }

    update(TrackTypeDb: cfgTrackTypeDb) {
        return this.http
            .put(this.apiURL + this.api + TrackTypeDb.TrackTypeID, TrackTypeDb);
    }

    delete(id: number) {
        return this.http.delete(this.apiURL + this.api + id);
    }
}

@Injectable()
export class cfgWorkStatusesService {
    api = 'cfgWorkStatus/';

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    getAll() {
        return this.http.get<cfgWorkStatusDb[]>(this.apiURL + this.api);

    }
    get(id: number) {
        return this.http.get<cfgWorkStatusDb>(this.apiURL + this.api + id);
    }

    create(WorkStatusDb: cfgWorkStatusDb) {
        return this.http.post(this.apiURL + this.api, WorkStatusDb);
    }

    update(WorkStatusDb: cfgWorkStatusDb) {
        return this.http
            .put(this.apiURL + this.api + WorkStatusDb.WorkStatusID, WorkStatusDb);
    }

    delete(id: number) {
        return this.http.delete(this.apiURL + this.api + id);
    }
}

@Injectable()
export class cfgWorkGroupsService {
    api = 'cfgWorkGroup/';

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    getAll() {
        return this.http.get<cfgWorkGroupDb[]>(this.apiURL + this.api);

    }
    get(id: number) {
        return this.http.get<cfgWorkGroupDb>(this.apiURL + this.api + id);
    }

    create(WorkGroupDb: cfgWorkGroupDb) {
        return this.http.post(this.apiURL + this.api, WorkGroupDb);
    }

    update(WorkGroupDb: cfgWorkGroupDb) {
        return this.http
            .put(this.apiURL + this.api + WorkGroupDb.WorkGroupID, WorkGroupDb);
    }

    delete(id: number) {
        return this.http.delete(this.apiURL + this.api + id);
    }
}

