import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { docDocumentService, docQuizExService, docVersionService } from 'app/_data/pro-docs.service';
import { UpsertRet } from 'app/_models/API.model';
import { docDocumentDb, docQuizEx, docQuizQuestionEx, docQuizResponseDb, docVersionDb } from 'app/_models/DOC.model';
import { IDType } from 'app/_models/enums.model';
import { AlertService } from 'app/_services';
import { ConfirmationService } from 'primeng/api';
import { TreeNode } from 'primeng/api/treenode';
import { TreeTable } from 'primeng/treetable';

@Component({
  selector: 'arc-quiz-edit',
  templateUrl: './quiz-edit.component.html',
  styleUrls: ['./quiz-edit.component.scss']
})
export class QuizEditComponent implements OnInit, AfterViewInit {

  @Input() quizID = 0;
  @Input() versionID = 0;

  @Output() view = new EventEmitter();

  @ViewChild('tt') tt: TreeTable;

  documentID = 0;
  document: docDocumentDb;
  version: docVersionDb;
  quiz: docQuizEx;
  showEditor = false;
  newQID = 0;
  newRID = 0;

  selectedQuestion: any;
  requiredScore = 100;


  quizData: TreeNode[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private quizService: docQuizExService,
    private versionService: docVersionService,
    private docService: docDocumentService,
    private cd: ChangeDetectorRef,
    private alert: AlertService,
    private confirm: ConfirmationService
  ) { }

  ngOnInit(): void {
    console.log('got quizID', this.quizID, ', versionID', this.versionID);
    this.quizService.getByVersionID(this.versionID)
      .subscribe((data: docQuizEx) => {
        this.quiz = data;
        console.log('got this as a quiz', this.quiz);
        if (this.quiz) {
          this.quizID = this.quiz.QuizID;
        } else {
          this.quiz = this.initQuiz();
        }
        this.buildQuizData();
      });

    this.versionService.getByID(this.versionID)
      .subscribe((data: docVersionDb) => {
        this.version = data;
        this.getDocument();
      });
  }

  initQuiz(): docQuizEx {
    const newQuiz: docQuizEx = {
      Questions: [],
      QuizID: IDType.NewRec,
      VersionID: this.versionID,
      Description: 'Quiz',
      RequiredScore: 1.0,
      IsActive: false,
      CreatedBy: 0,
      CreatedDate: new Date(1899, 1, 1),
      UpdatedBy: 0,
      UpdatedDate: new Date(1899, 1, 1)
    };

    this.quizID = IDType.NewRec;

    this.requiredScore = 100;

    return newQuiz;
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  getDocument() {
    this.docService.getByID(this.version.DocumentID)
      .subscribe((data: docDocumentDb) => {
        this.document = data;
      });
  }

  buildQuizData() {
    this.showEditor = false;
    this.quizData = [];
    if (this.quiz) {
      // this.quiz.Questions.sort((a, b) => a.Sequence > b.Sequence ? 1 : 0);
      this.quiz.Questions.forEach((q) => {
        const newQNode: TreeNode = {};
        newQNode.data = {
          'keyid': q.QuestionID,
          'questionid': q.QuestionID,
          'responseid': null,
          'toggle': 'Question',
          'sequence': q.Sequence,
          'text': q.Text,
          'notes': q.Notes,
          'response': false,
          'isCorrect': false,
          'createdBy': q.CreatedBy,
          'createdDate': q.CreatedDate,
          'updatedBy': q.UpdatedBy,
          'updatedDate': q.UpdatedDate
        };
        newQNode.expanded = true;
        newQNode.children = [];
        q.Responses.sort((a, b) => a.Sequence > b.Sequence ? 1 : 0);
        // newQNode.parent = null;
        q.Responses.forEach((r) => {
          const newRNode: TreeNode = {};
          newRNode.data = {
            'keyid': (q.QuestionID * 100) + r.ResponseID,
            'questionid': q.QuestionID,
            'responseid': r.ResponseID,
            'toggle': 'Response',
            'sequence': r.Sequence,
            'text': r.Text,
            'notes': r.Notes,
            'response': true,
            'isCorrect': r.IsCorrect,
            'createdBy': r.CreatedBy,
            'createdDate': r.CreatedDate,
            'updatedBy': r.UpdatedBy,
            'updatedDate': r.UpdatedDate
          };
          newQNode.children.push(newRNode);
          newRNode.parent = newQNode;
        });
        this.quizData.push(newQNode);
      });
    }
    this.requiredScore = this.quiz.RequiredScore * 100;

    console.log('leaving build', this.quizData);
    this.showEditor = true;
  }

  onEditComplete(q: any) {
    console.log('onEditComplete', q);
    if ((q.data.response) && (q.field === 'sequence')) {
      console.log('we will re-sort!');
      // this.buildQuizData();
    }
  }

  onChangeToggle(rowData: any) {
    // this fires when the correct/incorrect toggle button is pressed; need to set/unset correct values
    // here we first the the question that matches the question corresponding to the response (in rowData)
    const chk = this.tt._value.find((qrow: any) => qrow.data.questionid === rowData.questionid);
    // then, set the 'isCorrect' value on that particular response
    chk.children.forEach((rrow: any) => rrow.data.isCorrect = (rrow.data.keyid === rowData.keyid) && (rowData.isCorrect));
  }

  addResponse(rd: any, ev: any) {
    console.log('add response', rd, ev);
    // find the question
    const chk = this.tt._value.find((qrow: any) => qrow.data.questionid === rd.questionid);
    console.log('found chk', chk);
    // add a response
    const newRNode: TreeNode = {};
    newRNode.data = {
      'keyid': --this.newRID,
      'questionid': chk.data.questionid,
      'responseid': this.newRID,
      'toggle': 'Response',
      'sequence': 0,
      'text': '',
      'notes': '',
      'response': true,
      'isCorrect': false,
      'createdBy': 0,
      'createdDate': null,
      'updatedBy': 0,
      'updatedDate': null
    };
    newRNode.parent = chk;
    chk.children.push(newRNode);
    chk.expanded = true;
    this.quizData = [...this.quizData];
  }

  addQuestion(ev: any) {
    console.log('add question', ev);
    const newQNode: TreeNode = {};
    newQNode.data = {
      'keyid': --this.newQID,
      'questionid': this.newQID,
      'responseid': null,
      'toggle': 'Question',
      'sequence': 0,
      'text': '',
      'notes': '',
      'response': false,
      'isCorrect': false,
      'createdBy': 0,
      'createdDate': null,
      'updatedBy': 0,
      'updatedDate': null
    };
    newQNode.children = [];
    this.quizData.push(newQNode);
    this.quizData = [...this.quizData];
  }

  saveQuiz() {
    let newQuiz: docQuizEx;
    if (this.quiz) {
      newQuiz = { ...this.quiz };
      newQuiz.Questions = [];
    } else {
      newQuiz = {
        Questions: [],
        QuizID: IDType.NewRec,
        VersionID: this.versionID,
        Description: '',
        RequiredScore: 1.0,
        IsActive: false,
        CreatedBy: 0,
        CreatedDate: new Date(1899, 1, 1),
        UpdatedBy: 0,
        UpdatedDate: new Date(1899, 1, 1)
      };
    }

    this.tt._value.forEach((qItem) => {
      console.log('qItem', qItem);
      const newQuestion: docQuizQuestionEx = {
        Responses: [],
        QuestionID: qItem.data.questionid,
        QuizID: this.quizID,
        Sequence: qItem.data.sequence,
        Text: qItem.data.text,
        Notes: qItem.data.notes,
        CreatedBy: qItem.data.createdBy,
        CreatedDate: qItem.data.createdDate ?? new Date(1899, 1, 1),
        UpdatedBy: qItem.data.updatedBy,
        UpdatedDate: qItem.data.updatedDate ?? new Date(1899, 1, 1)
      };
      qItem.children.forEach((rItem) => {
        const newResponse: docQuizResponseDb = {
          ResponseID: rItem.data.responseid,
          QuestionID: qItem.data.questionid,
          Sequence: rItem.data.sequence,
          Text: rItem.data.text,
          IsCorrect: rItem.data.isCorrect,
          Notes: rItem.data.notes,
          CreatedBy: rItem.data.createdBy,
          CreatedDate: rItem.data.createdDate ?? new Date(1899, 1, 1),
          UpdatedBy: rItem.data.updatedBy,
          UpdatedDate: rItem.data.updatedDate ?? new Date(1899, 1, 1)
        };
        newQuestion.Responses.push(newResponse);
      });
      newQuiz.Questions.push(newQuestion);
    });

    if (!this.validateQuiz(newQuiz)) {
      this.alert.error('All questions must have one and only 1 response marked as Correct');
      return;
    }

    console.log('saving quiz', newQuiz);

    if (newQuiz.QuizID > 0) {
      this.quizService.update(newQuiz)
        .subscribe((ret: UpsertRet) => {
          if (ret.returnID > 0) {
            this.alert.success('Saved');
            this.router.navigate(['/prodocs/version', newQuiz.VersionID]);
            console.log('ret', ret);
          } else {
            this.alert.error(ret.displayMessage);
          }
        },
          (error) => this.alert.error(error));
    } else {
      this.quizService.create(newQuiz)
        .subscribe((ret: UpsertRet) => {
          if (ret.returnID > 0) {
            this.alert.success('Saved');
            console.log('ret', ret);
            this.quiz = ret.returnItem;
            this.quizID = this.quiz.QuizID;
            this.router.navigate(['/prodocs/version', newQuiz.VersionID]);
          } else {
            this.alert.error(ret.displayMessage);
          }
        },
          (error) => this.alert.error(error));
    }
  }

  validateQuiz(vQuiz: docQuizEx): boolean {
    let isValid = true;
    vQuiz.Questions.forEach((q) => {
      if (q.Responses.filter((r) => r.IsCorrect).length !== 1) {
        isValid = false;
      }
    });

    return isValid;
  }

  onConfirmDelete(ev: any) {
    const msg = 'Delete ' + (ev.response ? 'response ' : 'question ') + '"' + ev.text + '"?';
    this.confirm.confirm({
      message: msg,
      header: 'Confirm',
      icon: 'fa fa-question-circle',
      accept: () => { this.doDelete(ev); }
    });
  }


  doDelete(ev: any) {
    if (!ev.response) {// then it is a question
      // filter out the deleted question
      this.quizData = this.quizData.filter((d) => d.data.keyid !== ev.keyid);
    } else { // else it is a response, so first find the question
      const chk = this.quizData.find((q) => q.data.questionid === ev.questionid);
      // then filter out the deleted response
      chk.children = chk.children.filter((r) => r.data.keyid !== ev.keyid);
      this.quizData = [...this.quizData];
    }
  }

  viewQuiz() {
    this.saveQuiz();
    this.view.emit();
  }

  RequiredScoreChanged() {
    this.quiz.RequiredScore = this.requiredScore / 100;
  }
}



