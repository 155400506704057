<div class="cardContainer" *ngIf="data">
    <div class="title"> <h3>Document Assignment Status</h3> </div>
    <div class="header">
        <div class="showFullTeam" *ngIf="displayType === displayTypes.Team">
            <p-checkbox [(ngModel)]="showFullTeam" inputId="showFullTeam2" binary="true" (onChange)="getData()"></p-checkbox>
            <label for="showFullTeam2">Full Team</label>
        </div>
        <div class="warehouse" style="width: 100%;" *ngIf="displayType === displayTypes.Admin">
            <p-dropdown [options]="warehouses" [(ngModel)]="selectedWarehouse" (onChange)="getDataForWarehouse()"></p-dropdown>
        </div>
    </div>
    <div class="body">
        <p-chart type="pie" [data]="formattedChartData" height="35vh"></p-chart>
    </div>
    <div class="footer">
        <p>Total Employees: {{totalEmployees}}</p>
    </div>
</div>