import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { secUser, secUserValidations } from 'app/_models/SEC.model';
import { AlertService, AuthService } from 'app/_services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'arc-validations-home',
  templateUrl: './validations-home.component.html',
  styleUrls: ['./validations-home.component.scss']
})
export class ValidationsHomeComponent implements OnInit, OnDestroy {

  currentUser: any;

  GoTime: string = 'GoTime';


  subUser: Subscription;
  subValidations: Subscription;
  validations: secUserValidations[] = null;
  selectedValidation: secUserValidations;
  validating = true;

  // Validation Types:
  /*
    Validation Types:
      valClockIn
      valChangePassword
      valChangePIN
      valManagerApprovals
      valEmployeeApprovals
      valSOPsToRead
      valNotes
  */

  changePass: boolean = false;
  clockIn: boolean = false;
  changePIN: boolean = false;
  employeeStop: boolean = false;
  readSOPs: boolean = false;

  changePass_tab: boolean = false;
  clockIn_tab: boolean = false;
  changePIN_tab: boolean = false;
  employeeStop_tab: boolean = false;
  readSOPs_tab: boolean = false;
  employeeNotes_tab: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private alert: AlertService
  ) { }

  ngOnInit(): void {

    if (!this.authService.getAuthentication()) {
      this.router.navigate(['/']);
    };

    this.subUser = this.authService.currentUserItem$.subscribe(
      user => {
        this.currentUser = user;
      },
      (error) => this.alert.error(error)
    );

    this.subValidations = this.authService.userValidations$.subscribe(
      (data: secUserValidations[]) => {
        if (data) {
          this.changePass = false;
          this.clockIn = false;
          this.changePIN = false;
          this.employeeStop = false;
          this.readSOPs = false;

          this.changePass_tab = false;
          this.clockIn_tab = false;
          this.changePIN_tab = false;
          this.employeeStop_tab = false;
          this.employeeNotes_tab = false;
          this.readSOPs_tab = false;

          this.validations = data;

          if (this.validations.length > 0) {
            this.selectedValidation = this.validations[0];
            switch (this.selectedValidation.ValidationType) {
              case 'valChangePassword': this.changePass = true; break;
              case 'valClockIn': this.clockIn = true; break;
              case 'valChangePIN': this.changePIN = true; break;
              case 'valSOPsToRead': this.readSOPs = true; break;
              case 'valApprovalsByManager':
              case 'valNoAssignedFunctions':
              case 'valNoAssignedSchedule': this.employeeStop = true; break;
              case 'valNotes': this.employeeStop = true; break;

            }

            this.validations.forEach((e: secUserValidations) => {
              if (e.ValidationType === 'valChangePassword')
                this.changePass_tab = true;
              if (e.ValidationType === 'valClockIn')
                this.clockIn_tab = true;
              if (e.ValidationType === 'valChangePIN')
                this.changePIN_tab = true;
              if (e.ValidationType === 'valApprovalsByManager')
                this.employeeStop_tab = true;
              if (e.ValidationType === 'valNoAssignedFunctions')
                this.employeeStop_tab = true;
              if (e.ValidationType === 'valNoAssignedSchedule')
                this.employeeStop_tab = true;
              if (e.ValidationType === 'valNotes')
                this.employeeNotes_tab = true;
              if (e.ValidationType === 'valSOPsToRead')
                this.readSOPs_tab = true;
            });
            this.validating = false;
          }
          else {
            this.router.navigate(['/']);
          }
        }
      },
      (error) => this.alert.error(error)
    );
  }

  ngOnDestroy(): void {
    this.subUser.unsubscribe();
    this.subValidations.unsubscribe();
  }

  closeGoTime() {
    this.runValidations();
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  clockInSuccess() {
    console.log('Made it into ClockSuccess');
    this.runValidations();
  }

  pinSuccess() {
    console.log('Made it into PINSuccess')
    this.runValidations();
  }

  passSuccess() {
    console.log('Made it into PassSuccess')
    this.runValidations();
  }

  runValidations() {
    console.log('running validations in authService');
    this.authService.checkValidations();
  }

  confirmValidated(): boolean {
    return this.authService.userValidationState === 1;
  }

  kioskFinished() {
    this.clockIn = false;
    this.runValidations();
  }

  acknowledge() {
    this.employeeStop = false;
    this.authService.acknowledgeValidations();
  }
}
