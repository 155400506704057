import { Injectable, Inject } from '@angular/core';
import { cfgShippingService } from '../_models/CFG.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ShippingServicesService {

  api = 'cfgShippingService/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<cfgShippingService[]>(this.apiURL + this.api);

  }

  getForLevelCode(code: string) {
    return this.http.get(this.apiURL + this.api + 'ForLevel/' + code);
  }

  create(shippingService: cfgShippingService) {
    return this.http.post(this.apiURL + this.api, shippingService);
  }

  update(shippingService: cfgShippingService) {
    return this.http
      .put(this.apiURL + this.api + shippingService.ShippingServiceID, shippingService);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

}

