import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { orgPersonSearchResult } from '../_models/ORG.model';

@Injectable({
  providedIn: 'root'
})
export class PersonSearchService {

  api = 'orgPersonSearch/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  searchPersons(lastNameFrag: string, firstNameFrag: string, entityID: number = -1) {
    // const headers = new HttpHeaders();
    // headers.append('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('lastName', lastNameFrag);
    params = params.append('firstName', firstNameFrag);
    params = params.append('entityID', entityID.toString());
    return this.http.get<orgPersonSearchResult[]>(this.apiURL + this.api + 'search', { params });
  }

}
