import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { ContextMenuModule } from 'primeng/contextmenu';

@Injectable({
  providedIn: 'root'
})
export class HardwareConnectorService {

  constructor
    (
      private http: HttpClient
    ) {
    this.getHardware();
  }

  private ScaleURL = 'http://localhost:3257/devices/v2/Weight/';
  private PrintURL = 'http://localhost:3257/devices/v2/print/print';
  private EnabledUrl = 'http://localhost:3257/v2/enabled';
  private scaleID: number;
  private scaleFound = false;
  private labelPrinterFound = false;
  private batchLabelPrinterFound = false;
  private pagePrinterFound = false;

  private scaleName = "SendItScale";
  private labelPrinterName = "SendItLabel";
  private batchLabelPrinterName = "SendItBatch";
  private pagePrinterName = "SendItPage";
  private workstationHeaderValue = "X-Proship-Hostname";

  private defaultWorkstationID = 'unknown';
  public workstationID = this.defaultWorkstationID;

  // 10/17/2023 RJS Added code to retrieve the dev.headers from response (to get the workstation ID)
  private getHardware() {
    //const headers = new HttpHeaders();
    //headers.append('Content-Type', 'application/json');
    this.http.get(this.EnabledUrl, { observe: "response" })
      .subscribe({
        next: (response: any) => {
          var scale = response.body.find(d => d.friendlyName == this.scaleName);
          if (scale) {
            this.scaleID = scale.deviceId;
            this.ScaleURL = this.ScaleURL + this.scaleID.toString();
            this.scaleFound = true;
            // console.log('scaleID', this.scaleID);
          }
          var labelPrinter = response.body.find(d => d.friendlyName == this.labelPrinterName);
          this.labelPrinterFound = labelPrinter ? true : false;
          // console.log('label printer found', this.labelPrinterFound);

          var batchLabelPrinter = response.body.find(d => d.friendlyName == this.batchLabelPrinterName);
          this.batchLabelPrinterFound = batchLabelPrinter ? true : false;
          // console.log('Batch label printer found', this.batchLabelPrinterFound);

          var pagePrinter = response.body.find(d => d.friendlyName == this.pagePrinterName);
          this.pagePrinterFound = pagePrinter ? true : false;
          // console.log('Page printer found', this.pagePrinterFound);

          const wsNode = response.headers.get(this.workstationHeaderValue);
          if (wsNode) {
            this.workstationID = wsNode;
            // console.log('WorkStationID', this.workstationID);
          } else {
            console.log('no WSNode, using default', this.defaultWorkstationID);
            this.workstationID = this.defaultWorkstationID;
          }
        },
        error: (err) => {
          // did not connect -- no hardware connector found at that URL
          console.log(err);
          this.scaleFound = false;
          this.workstationID = this.defaultWorkstationID;
          this.batchLabelPrinterFound = false;
          this.labelPrinterFound = false;
        }
      });
  }


  public getWorkstationID() {
    if (this.workstationID) {
      return this.workstationID
    } else {
      this.getHardware()
      return null;
    }

  }

  public GetWeight() {
    if (this.scaleFound) {
      return this.http.get(this.ScaleURL);
    } else {
      this.getHardware()
      return of(null);
    }
  }

  public PrintLabel(pslr: string, payload: string) {
    if (!this.labelPrinterFound && !this.batchLabelPrinterFound) {
      console.log('No label printer found');
      return of(null);
    }
    // console.log('print to label printer', payload);
    const body = {
      "deviceId": "",
      "printerName": this.labelPrinterFound ? this.labelPrinterName : this.batchLabelPrinterName,
      "data": payload,
      "jobType": "RAW"
    }
    return this.http.post(this.PrintURL, body)
  }

  public PrintBatchLabel(pslr: string, payload: string) {
    if (!this.labelPrinterFound && !this.batchLabelPrinterFound) {
      console.log('No label printer found');
      return of(null);
    }
    const body = {
      "deviceId": "",
      "printerName": this.batchLabelPrinterFound ? this.batchLabelPrinterName : this.labelPrinterName,
      "data": payload,
      "jobType": "RAW"
    }
    return this.http.post(this.PrintURL, body)
  }

  public PrintPage(pslr: string, payload: string) {
    if (!this.pagePrinterFound) {
      console.log('no page printer found');
      return of(null);
    }
    const body = {
      "deviceId": "",
      "printerName": this.pagePrinterName,
      "data": payload,
      "jobType": "RAW"
    }

    return this.http.post(this.PrintURL, body);
  }
}
