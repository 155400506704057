<div class="p-grid ">
    <div class="p-col-10 p-lg-offset-1" *ngIf="selectedReport">
        <div class="p-col-12">
            <h2>Report {{selectedReport.ReportID}}: {{ selectedReport.Name }}</h2>
            <textarea pInputTextarea [rows]="6" autoResize="autoResize" readonly
                style="font-size:larger; padding: 25px; max-width: 100%;"
                [(ngModel)]="selectedReport.Description"></textarea>
        </div>
        <div class="p-col-12">
            <form #ReportDisplayForm="ngForm">
                <p-panel header="Report Parameters">
                    <div *ngFor="let p of selectedReport.Parameters; let i = index;" [ngSwitch]="p.ParameterTypeID"
                        class="p-grid p-field">

                        <div class="p-col-2" style="display:flex; align-items: center;">
                            <label for="'p.SSRSName'">{{ p.Name }}</label>
                        </div>
                        <div class="p-col-4" style="vertical-align: middle;">
                            <div *ngSwitchCase="ReportParameterType.TextBox">
                                <input pInputText [name]="p.SSRSName" [id]="p.SSRSName" [(ngModel)]="p.ParameterValue"
                                    [required]="!p.AllowNull || null" #RefVariableTB="ngModel"
                                    (input)="checkClearText(p)" (blur)="doCheckCascade(p.ParameterID, p.DisplayOrder)"
                                    [disabled]="p.ParameterDisabled">
                                <arc-show-errors [control]="RefVariableTB"></arc-show-errors>
                            </div>
                            <div *ngSwitchCase="ReportParameterType.CheckBox">
                                <p-checkbox [name]="p.SSRSName" [id]="p.SSRSName" binary="true"
                                    [(ngModel)]="p.ParameterValue" #RefVariableCB="ngModel"
                                    (onChange)="doCheckCascade(p.ParameterID, p.DisplayOrder)">
                                </p-checkbox>
                                <arc-show-errors [control]="RefVariableCB"></arc-show-errors>
                            </div>
                            <div *ngSwitchCase="ReportParameterType.TriCheckBox">
                                <p-triStateCheckbox [name]="p.SSRSName" [id]="p.SSRSName" [(ngModel)]="p.ParameterValue"
                                    #RefVariableCB="ngModel" (onChange)="doCheckCascade(p.ParameterID, p.DisplayOrder)">
                                </p-triStateCheckbox>
                                {{ p.ParameterValue ? p.ParameterValue : p.ParameterValue === null ? 'N/A' : 'false' }}
                                <arc-show-errors [control]="RefVariableCB"></arc-show-errors>
                            </div>
                            <div *ngSwitchCase="ReportParameterType.DatePicker">
                                <p-calendar [name]="p.SSRSName" [id]="p.SSRSName" [(ngModel)]="p.ParameterValue"
                                    [showButtonBar]="true" [readonlyInput]="false" [monthNavigator]="true"
                                    [yearNavigator]="true" [inputStyle]="{'width': '100%'}" appendTo="body"
                                    yearRange="2000:2030" selectionMode="single" [required]="!p.AllowNull || null"
                                    #RefVariableDP="ngModel" (onSelect)="doCheckCascade(p.ParameterID, p.DisplayOrder)">
                                </p-calendar>
                                <arc-show-errors [control]="RefVariableDP"></arc-show-errors>
                            </div>
                            <div *ngSwitchCase="ReportParameterType.DateTimePicker">
                                <p-calendar [name]="p.SSRSName" [id]="p.SSRSName" [(ngModel)]="p.ParameterValue"
                                    [showButtonBar]="true" showTime="true" hourFormat="12" [readonlyInput]="false"
                                    [monthNavigator]="true" [yearNavigator]="true" [inputStyle]="{'width': '100%'}"
                                    appendTo="body" yearRange="2000:2030" selectionMode="single"
                                    [required]="!p.AllowNull || null" #RefVariableDP="ngModel"
                                    (onClose)="doCheckCascade(p.ParameterID, p.DisplayOrder)">
                                </p-calendar>
                                <arc-show-errors [control]="RefVariableDTP"></arc-show-errors>
                            </div>
                            <div *ngSwitchCase="ReportParameterType.DateRangePicker">
                                <p-calendar [name]="p.SSRSName" [id]="p.SSRSName" [(ngModel)]="p.ParameterValue"
                                    [showButtonBar]="true" [readonlyInput]="false" [monthNavigator]="true"
                                    [yearNavigator]="true" [inputStyle]="{'width': '100%'}" appendTo="body"
                                    yearRange="2000:2030" selectionMode="range" [required]="!p.AllowNull || null"
                                    #RefVariableDRP="ngModel" (onSelect)="doCheckCascade(p.ParameterID, p.DisplayOrder)"
                                    arcValidDateRange>
                                </p-calendar>
                                <div *ngIf="dateRangeError" style="color:red;">
                                    * Not a valid date range.
                                </div>
                                <arc-show-errors [control]="RefVariableDRP"></arc-show-errors>
                            </div>
                            <div *ngSwitchCase="ReportParameterType.DropDownList">
                                <p-dropdown *ngIf="!p.ParameterDisplayValue; else clientName" [name]="p.SSRSName" [id]="p.SSRSName"
                                    [options]="p.Options.SelectOptions" [(ngModel)]="p.ParameterValue"
                                    [style]="{'width':'80%', 'vertical-align': 'bottom'}" [required]="!p.AllowNull || null"
                                    #RefVariableDDL="ngModel" (onChange)="doCheckCascade(p.ParameterID, p.DisplayOrder)"
                                    [disabled]="p.ParameterDisabled">
                                </p-dropdown>
                                <ng-template #clientName>
                                    <h4 *ngIf="p.ParameterDisplayValue">{{ p.ParameterDisplayValue }}</h4>
                                </ng-template>
                                <arc-show-errors [control]="RefVariableDDL"></arc-show-errors>
                            </div>
                            <div *ngSwitchCase="ReportParameterType.RadioButtonList">
                                <span *ngFor="let o of p.Options.SelectOptions; let x = index;"
                                    style="padding-right: 10px;">
                                    <input type="radio" [id]="p.SSRSName + x" [name]="p.SSRSName + x" [value]="o.value"
                                        [(ngModel)]="p.ParameterValue" style="width: 5%"
                                        (change)="doCheckCascade(p.ParameterID, p.DisplayOrder)"
                                        [disabled]="p.ParameterDisabled">
                                    <label [for]="p.SSRSName + x">{{ o.label }}</label>
                                </span>
                            </div>
                        </div>
                        <div class="p-col-6">
                            <span *ngIf="!p.ParameterDisplayValue">{{ p.Description }}</span>
                        </div>
                    </div>
                    <div class="p-grid p-field">
                        <!-- <div> -->
                        <div class="p-col-2" style="display:flex; align-items: center;">
                            <label>Output Type</label>
                        </div>
                        <div class="p-col-10">
                            <p-selectButton [options]="outputOptions" [(ngModel)]="selectedOutput" optionLabel="name"
                                id="outputOptions" name="outputOptions">
                                <ng-template let-item>
                                    <div style="padding: .5em 1em">
                                        <img style="vertical-align: middle; margin-right: .5em" height="20px"
                                            src="../../../assets/images/{{item.logo}}" />
                                        <!-- ssets/showcase/images/demo/flag/{{item.value.flag}}"/> -->
                                        <span>{{item.name}}</span>
                                    </div>
                                </ng-template>
                            </p-selectButton>
                        </div>
                    </div>
                </p-panel>
            </form>
            <div>
                <hr>
                <p-button icon="pi pi-eye" label="Create Report" [disabled]="!ReportDisplayForm.valid"
                    (onClick)="doCreateReport()"></p-button>
                <div style="float:right">
                    <p-button icon="pi pi-ban" label="Cancel" (onClick)="doCancelDisplay()"></p-button>
                </div>
            </div>
        </div>
    </div>
</div>