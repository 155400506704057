import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { timeEmployeeSnapshot, timeAssignedFunction } from 'app/_models/TIME.model';
import { AlertService, AuthService } from 'app/_services';
import { timeEmployeeService } from 'app/_data/time.service';
import { secCurrentUser, secGoTime } from 'app/_models/SEC.model';
import { bundle } from '../select-function/select-function.component';

@Component({
  selector: 'arc-time-home',
  templateUrl: './time-home.component.html',
  styleUrls: ['./time-home.component.scss']
})
export class TimeHomeComponent implements OnInit, OnDestroy {

  @Input() pageTitle: string;
  signedInEmployee: timeEmployeeSnapshot
  employeeID: number;
  _subscription;
  showMe: boolean = false;
  currentFunction: timeAssignedFunction;

  statusData: secGoTime;
  currentUser: secCurrentUser;

  @Input() isValidated = true;

  @Output() out = new EventEmitter<secGoTime>();
  @Output() timeTrack = new EventEmitter<timeEmployeeSnapshot>();

  constructor(
    public authService: AuthService,
    private employeeService: timeEmployeeService,
    private alert: AlertService
  ) { }

  ngOnInit(): void {
    this.employeeID = this.authService.currentUser.UserID;
    console.log('time-home init');
    this._subscription = this.employeeService.getSnapshot(this.employeeID).subscribe(
      (value) => {
        if (value) {
          this.signedInEmployee = value;
          this.employeeService.updateEmployeeSnapshot(value);
          // this.authService.gatherGoTimeInfo(value.Employee.EmployeeID).subscribe(
          //   (val) => {
          //     this.statusData = val;
          //     this.pageTitle = 'GoTime';
          //   },
          //   (error) => this.alert.error(error)
          // );
          this.signedInEmployee.AssignedFunctions.forEach(func => {
            if (func?.FunctionID === this.signedInEmployee?.CurrentTimeSegment?.FunctionID) {
              this.currentFunction = func;
            }
          });

          console.log('Home Snapshot of Employee: ', this.signedInEmployee);
        } else {
          this.signedInEmployee = null;
        }
      },
      (error) => this.alert.error(error)
    );
    // this.statusData = this.authService.currentUser.GoTimeInfo;
    this.currentUser = this.authService.currentUser;
  }

  ngOnDestroy(): void {
    this.signedInEmployee = null;
    this._subscription.unsubscribe();
    this.pageTitle = 'GoTime';
  }

  changeTitle(page: string) {
    this.pageTitle = page;
  }

  reloadStatusBar(value: timeEmployeeSnapshot) {
    this.authService.gatherGoTimeInfo(value.Employee.EmployeeID).subscribe(
      (val) => {
        this.statusData = val;
        this.pageTitle = 'GoTime';
        this.currentFunction = value.AssignedFunctions.find((f) => f.FunctionID === value.CurrentTimeSegment?.Function.FunctionID);
        this.out.emit(this.statusData);
      },
      (error) => this.alert.error(error)
    );
  }

  goToTimeTrack(pack: timeEmployeeSnapshot) {
    console.log('Time Home', pack);
    this.timeTrack.emit(pack);
  }

}
