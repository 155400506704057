<div>
    <span [innerHTML]="message"></span>
</div>
<div>
    <p><p-dropdown [options]="languages" [(ngModel)]="retValueText" placeholder="Select new language" [showClear]="false" appendTo="body"></p-dropdown></p>
</div>
<hr/>
<div style="float:right">
    <button type="button" pButton icon="fa fa-check" label="Yes" (click)="doAccept()">
    </button>
    &nbsp;
    <button type="button" pButton icon="fa fa-times" label="No" (click)="doReject()">
    </button>
</div>
