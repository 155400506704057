import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import { ordQuotesFreightVw, ordQuoteSummaryVw } from '../_models/DBTables.model';
import { HttpClient } from '@angular/common/http';
import { ordQuoteStatusItem } from 'app/_models/ORD.model';

@Injectable()
export class QuotesService {

  api = 'ordQuotesFreightVw/';

  constructor(
    private http: HttpClient,
    @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<ordQuotesFreightVw[]>(this.apiURL + this.api);
  }

  getOrderForQuoteID(QuoteID: number) {
    return this.http.get<number>(this.apiURL + this.api + 'OrderID4QuoteID/' + QuoteID);
  }

  getAllFiltered(currentStatuses: string[], triggerStatuses: string[]) {

    const exceptStatuses: string[] = [];
    // are any of my trigger statuses in the currently selected status list?
    // if NOT, add them to the except list
    // if they ARE, we do NOT want to except them
    triggerStatuses.forEach(s => {
      if (currentStatuses.indexOf(s) < 0) {
        exceptStatuses.push(s);
      }
    });
    // and we only except anything if there's something to except
    if (exceptStatuses.length === 0) {
      // if (currentStatuses.indexOf(triggerStatus) >= 0) { // it's found
      return this.http.get<ordQuotesFreightVw[]>(this.apiURL + this.api); // so get all
    } else {  // else, not found,
      return this.http.get<ordQuotesFreightVw[]>(this.apiURL + this.api + '/except/' + exceptStatuses.join(',')); // so get all EXCEPT the trigger status
    }
  }

  getForClient(ClientID: number) {
    console.log('in getForClient', ClientID);
    return this.http.get<ordQuotesFreightVw[]>(this.apiURL + this.api + 'client/' + ClientID);
  }

}

@Injectable()
export class QuoteSummaryVwService {

  api = 'ordQuoteSummaryVw/';

  constructor(
    private http: HttpClient,
    @Inject('apiURL') private apiURL) { }

  getForOrder(OrderID: number) {
    console.log('in getForOrder', OrderID);
    return this.http.get<ordQuoteSummaryVw[]>(this.apiURL + this.api + 'order/' + OrderID);
  }

}

@Injectable()
export class QuoteStatusService {

  api = 'ordQuoteStatus/';

  constructor(
    private http: HttpClient,
    @Inject('apiURL') private apiURL) { }

  fetchQuoteListByStatus(forClientID: number, forList: ordQuoteStatusItem[]) {
    return this.http.post(this.apiURL + this.api + 'fetchList/' + forClientID, forList);
  }
}

