import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class InvoicingService {

  api = 'chgInvoice/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getByID(invoiceID: number) {
    return this.http.get(this.apiURL + this.api + invoiceID);
  }

  getByOrder(orderID: number) {
    return this.http.get(this.apiURL + this.api + 'order/' + orderID);
  }
  generate(orderID: number) {
    return this.http.post(this.apiURL + this.api + 'generate/' + orderID, null);
  }

  delete(invoiceID: number) {
    return this.http.delete(this.apiURL + this.api + invoiceID);
  }

  deleteByOrder(orderID: number) {
    return this.http.delete(this.apiURL + this.api + 'order/' + orderID);
  }

  getPortalSummary(orderID: number) {
    return this.http.get(this.apiURL + this.api + 'portalSummary/' + orderID);
  }
}
