import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SelectItem } from 'primeng/api';

@Injectable()
export class P3PLService {
    api = 'P3PL/';
    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    getClients() {
        return this.http.get<SelectItem[]>(this.apiURL + this.api + 'clients');
    }

    getVASClients() {
        return this.http.get<SelectItem[]>(this.apiURL + this.api + 'vasclients');
    }

    getCarriers() {
        return this.http.get<SelectItem[]>(this.apiURL + this.api + 'carriers');
    }
}