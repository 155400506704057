import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { timeEmployeeService } from 'app/_data/time.service';
import { timeEmployeeSnapshot } from 'app/_models/TIME.model';
import { AlertService, AuthService } from 'app/_services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'arc-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  employee: timeEmployeeSnapshot;
  _subscription: Subscription;
  userID: number;
  forDate: Date;
  displayDate: string;
  @Output() out = new EventEmitter<timeEmployeeSnapshot>();

  @Input() pageTitle: string;

  public _reload = true;

  constructor(
    private authService: AuthService,
    private employeeService: timeEmployeeService,
    private alert: AlertService
  ) { }

  ngOnInit(): void {
    this.userID = this.authService.currentUser.UserID;
    // this._subscription = this.employeeService.getSnapshot(this.userID).subscribe((value) => {
    //   this.employee = value;
    // });

    this._subscription = this.employeeService.signedInEmployee$.subscribe(
      (value) => {
        this.employee = value;
      },
      (error) => this.alert.error(error)
    )
    this.forDate = new Date();
  }

}
