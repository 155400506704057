import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ArcSharedServicesModule } from './_shared/arc-shared-services.module';
import { ArcSharedWidgetsModule } from './_shared/arc-shared-widgets.module';

import { AppRoutingModule } from './app-routing.module';

import { TokenInterceptor } from './_helpers/token.interceptor';
import { AuthService, AlertService, MenuService, DraggedService } from './_services/index';
import { PersistenceService } from './_services/persistence.service';

import { environment } from '../environments/environment';
import { AuthGuard } from './_guards/auth.guard';
// import { Md5 } from 'ts-md5/dist/md5';

// import components

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AlertComponent } from './_helpers/alert/alert.component';
import { FreightDashboardComponent } from './freight-dashboard/freight-dashboard.component';
import { ReportDisplayComponent } from './reports/report-display/report-display.component';

import { TimeHomeComponent } from './go-time/time-home/time-home.component';
import { ClockInComponent } from './go-time/clock-in/clock-in.component';
import { SelectFunctionComponent } from './go-time/select-function/select-function.component';
import { ChangeFunctionComponent } from './go-time/change-function/change-function.component';
import { BeginBreakComponent } from './go-time/begin-break/begin-break.component';
import { EndBreakComponent } from './go-time/end-break/end-break.component';
import { ClockOutComponent } from './go-time/clock-out/clock-out.component';
import { SelectBreakTypeComponent } from './go-time/select-break-type/select-break-type.component';
import { TimeSummaryComponent } from './go-time/time-summary/time-summary.component';
import { IssuePopupComponent } from './go-time/issue-popup/issue-popup.component';
import { DashboardComponent } from './go-time/dashboard/dashboard.component';
import { TimeTrackComponent } from './go-time/time-track/time-track.component';
import { ValidationsHomeComponent } from './validations/validations-home/validations-home.component';
import { ChangePassPinComponent } from './validations/change-pass-pin/change-pass-pin.component';
import { SopComponent } from './validations/sop/sop.component';
import { EmployeeStopComponent } from './validations/employee-stop/employee-stop.component';

// import services
import { ActionsService } from './_data/actions.service';
import { ActivityTypesService } from './_data/activity-types.service';
import { AddressService } from './_data/address.service';
import { AffiliatesService } from './_data/affiliates.service';
import { AirportCodesService } from './_data/airport-codes.service';
import { AssociationService } from './_data/association.service';
import { AssociationTypesService } from './_data/association-types.service';
import {
  AudActionService,
  AudBackBillService,
  AudCarrierService,
  AudChargeService,
  AudCompareService,
  AudDashboardService,
  AudFedExCodeService,
  AudImportBatchService,
  AudImportPackageService,
  AudInvoiceSummaryService,
  AuditActionService,
  AuditCompareService,
  AuditResidentialService,
  AuditWeightsDimsService,
  AudNotesService,
  AudPackageService,
  AudPackageSummaryService,
  AudPendingService,
  AudPending2Service,
  AudRerateService,
  AudServiceMapService,
  AudShipSearchService,
  AudSurchargeCarrierService,
  AudSurchargeInternalService,
  AudSurchargeTypeService,
  AudVASService,
  AuedExpectedChargesService,
  P3PLShipmentPackageService,
  ReRateRequestedServicesService,
  WLPackageService,
  WLShipmentService
} from './_data/auditing.service';
import { BillingPortalVwService, BillingService } from './_data/billing.service';
import { CarrierAreasService } from './_data/carrier-areas.service';
import { CarrierRatesService } from './_data/carrier-rates.service';
import { ClientsService, ClientsGoTimeService } from './_data/clients.service';
import { ConfirmationService } from 'primeng/api';
import { CountryCodesService } from './_data/country-codes.service';
import { CurrencyExchangeService } from './_data/currency-exchange.service';
import { DefaultSubjectTosService } from './_data/default-subject-tos.service';
import { EmailsService, EmailExService, EmailVwService, PEMEmailService } from './_data/emails.service';
import { EmailTypesService } from './_data/email-types.service';
import { EntityEditService } from './_data/entity-edit.service';
import { EntityListService, EntitySearchService, EntityVwService } from './_data/entity-list.service';
import { EntityPersonService } from './_data/entity-person.service';
import { FreightBillingService } from './_data/freight-billing.service';
import { FreightPayableService } from './_data/freight-payable.service';
import { FuelSurchargesService } from './_data/fuel-surcharges.service';
import { GroupsService } from './_data/groups.service';
import { GroupTypesService } from './_data/group-types.service';
import { HandlingChargesService } from './_data/handling-charges.service';
import { HardwareConnectorService } from './_services/hardware-connector.service';
import { InternationalTypesService } from './_data/international-types.service';
import { InvoicingService } from './_data/invoicing.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LabelForEnumPipe } from './_pipes/label-for-enum.pipe';
import { LabelForValuePipe } from './_pipes/label-for-value.pipe';
import { LinkedPersonService } from './_data/persons.service';
import { LookupsService } from './_services/lookups.service';
import { MediaService, MediaExService, MediaVwService, PEMMediaService } from './_data/media.service';
import { MediaTypesService } from './_data/media-types.service';
import { MessageService } from 'primeng/api';
import { MinsToTimePipe } from './_pipes/mins-to-time.pipe';
import { MinusToParensPipe } from './_pipes/minus-to-parens.pipe';
import { NmfcNumbersService } from './_data/nmfc-numbers.service';
import { NoteTypesService } from './_data/note-types.service';
import { OrderBaseService } from './_data/orders-freight.service';
import { OrderCalculatorService } from './_data/order-calculator.service';
import { OrderHoldsService } from './_data/order-holds.service';
import { OrderIssuesService } from './_data/order-issues.service';
import { OrderNmfcService } from './_data/order-nmfc.service';
import { OrdersFreightExService } from './_data/orders-freight.service';
import { OrdersFreightPayableService } from './_data/orders-freight-payable.service';
import { OrdersFreightService, OrdersFreightLegService, OrdersFreightHandlingService } from './_data/orders-freight.service';
import { OrderTaskService } from './_data/order-task.service';
import { OrganizationsListService } from './_data/organizations-list.service';
import { P3PLService } from './_data/p3pl.service';
import { PayableTypesService } from './_data/payable-types.service';
import { PaymentTermsService } from './_data/payment-terms.service';
import { PaymentTypesService } from './_data/payment-types.service';
import { PersonSearchService } from './_data/person-search.service';
import { PersonsService } from './_data/persons.service';
import { PersonVwService } from './_data/persons.service';
import { PhonesService, PhoneVwService, PEMPhoneService } from './_data/phones.service';
import { PhoneTypesService } from './_data/phone-types.service';
import { PieceTypeService } from './_data/piece-types.service';
import { PlacesService, PlaceVwService, PlaceExService } from './_data/places.service';
import { PlaceTypesService } from './_data/place-types.service';
import { QuotesService, QuoteStatusService, QuoteSummaryVwService } from './_data/quotes.service';
import { ReportingService } from './_data/reporting.service';
import { ReportsService, ReportCategoryService, ReportParameterTypeService, ReportOptionsService, ReportViewHistoryService, ReportInfoService } from './_data/reports.service';
import { RightsService } from './_data/rights.service';
import { RightTypesService } from './_data/right-types.service';
import { ServiceLevelsService } from './_data/service-levels.service';
import { ServiceProcessesService } from './_data/service-processes.service';
import { ServiceUnitsService } from './_data/service-units.service';
import { ShipmentTypesService } from './_data/shipment-types.service';
import { ShippingServicesService } from './_data/shipping-services.service';
import { StateProvinceCodesService } from './_data/state-province-codes.service';
import { StatusesService } from './_data/statuses.service';
import { SystemOptionsService } from './_data/system-options.service';
import { TaskTypesService } from './_data/task-types.service';
import { TrackingCategoryService } from './_data/tracking-category.service';
import { TrackingPortalVwService, TrackingService } from './_data/tracking.service';
import { TrackingVwService } from './_data/tracking.service';
import { UsersService } from './_data/users.service';
import { UtilityService } from './_services/utility.service';
import { utlPlaceService } from './_data/places.service';
import { VendorFeesService } from './_data/vendor-fees.service';
import { VendorsService } from './_data/vendors.service';
import { WarehouseAlertsService } from './_data/warehouse-alerts.service';
import { WarehouseDashboardService } from './_data/warehouse-dashboard.service';
import { WarehouseFreightSelectService } from './_data/warehouse-freight-select.service';
import { WarehousesService } from './_data/warehouses.service';
import {
  timeActionService,
  timeClientFunctionService,
  timeClientJobService,
  timeClientWarehouseService,
  timeDashboardService,
  timeEmployeeAccessService,
  timeEmployeeFunctionService,
  timeEmployeeService,
  timeFunctionGroupFunctionService,
  timeFunctionGroupService,
  timeFunctionService,
  timeIssueService,
  timeJobService,
  timePayrollBatchService,
  timePayrollBatchDetailService,
  timeScheduleService,
  timeScheduleTemplateService,
  timeSettingService,
  timeTimeSegmentService,
  timeTrackSegmentService,
  timeWarehousePayrollSettingsService
} from './_data/time.service';
import {
  cfgBillTypesService,
  cfgClientRulesService,
  cfgDepartmentsService,
  cfgEmployeeTypesService,
  cfgIssueTypesService,
  cfgPayrollTypesService,
  cfgScheduleTypesService,
  cfgTerminationReasonsService,
  cfgTrackTypesService,
  cfgWorkStatusesService,
  cfgWorkGroupsService,
  cfgFunctionTypesService
} from './_data/time-cfg.service';

import { ChartModule } from 'primeng/chart';
import { SplitButtonModule } from 'primeng/splitbutton'
import { AssetDashboardService, astActionScopeService, astActionService, astActivityService, astAssetActionService, astAssetService, astAssetStateService, cfgAssetStatusService, cfgAssetTypeService } from './_data/asset.service';
import { RefNotesService } from './_data/ref-notes.service';
import { RefDocumentService } from './_data/ref-document.service';
import { docActorService, docAssignmentService, docCategoryService, docDashboardService, docDocumentService, docQuizExService, docQuizInstanceService, docQuizQuestionService, docQuizResponseService, docQuizService, docRoleService, docStatusService, docTriggerService, docVersionService, docLanguageService, docHistoryService } from './_data/pro-docs.service';
import { ProdocsModule } from './prodocs/prodocs.module';
import { AutoFocusDirective } from './_directives/auto-focus.directive';
import { SendItBatchService, SendItPackageLabelService, SendItService, SendItPackageService, SendItDashboardService } from './_data/sendit.service';
import { ShipmentHistory } from './_models/SEND.model';
import { cfgAccounts3dPartyService, cfgAdditionalServiceOptionsService, cfgAdditionalServicesService, cfgCarrierAccountsService, cfgCarriersService, cfgPaymentTypesService, cfgRateDivisionTypesService, cfgRateTypesService, cfgRatingEngineService, cfgRatingEngineServiceService, cfgRatingEngineSitesService, cfgShippingServiceAdditionalServiceOptionsService, cfgShippingServiceService, cfgShipViaService, p3plFulfillmentClientService } from './_data/sendit-cfg.service';
import { StepsModule } from 'primeng/steps';
import { MenuItem } from 'primeng/api';
@NgModule({
  declarations: [
    AlertComponent,
    AppComponent,
    BeginBreakComponent,
    ChangeFunctionComponent,
    ChangePassPinComponent,
    ClockInComponent,
    ClockOutComponent,
    DashboardComponent,
    EmployeeStopComponent,
    EndBreakComponent,
    FreightDashboardComponent,
    HeaderComponent,
    HomeComponent,
    IssuePopupComponent,
    LoginComponent,
    SelectBreakTypeComponent,
    SelectFunctionComponent,
    SopComponent,
    TimeHomeComponent,
    TimeSummaryComponent,
    TimeTrackComponent,
    ValidationsHomeComponent,
    AutoFocusDirective,
  ],
  imports: [
    AppRoutingModule,
    ArcSharedServicesModule,
    ArcSharedWidgetsModule,
    BrowserAnimationsModule,
    BrowserModule,
    ChartModule,
    FormsModule,
    SplitButtonModule,
    HttpClientModule,
    ProdocsModule,
    StepsModule
  ],
  providers: [
    // Md5,
    cfgAssetStatusService,
    cfgAssetTypeService,
    astAssetActionService,
    astAssetStateService,
    astActionService,
    astActionScopeService,
    astActivityService,
    astAssetService,
    AssetDashboardService,
    ActionsService,
    ActivityTypesService,
    AddressService,
    AffiliatesService,
    AirportCodesService,
    AlertService,
    AssociationService,
    AssociationTypesService,
    AudBackBillService,
    AudCarrierService,
    AudChargeService,
    AudDashboardService,
    AudFedExCodeService,
    AudImportBatchService,
    AudImportPackageService,
    AudInvoiceSummaryService,
    AuditActionService,
    AuditCompareService,
    ReRateRequestedServicesService,
    AuditResidentialService,
    AuditWeightsDimsService,
    AudNotesService,
    AudPackageService,
    AudPackageSummaryService,
    AudPendingService,
    AudPending2Service,
    AudServiceMapService,
    AudShipSearchService,
    AudSurchargeCarrierService,
    AudSurchargeInternalService,
    AudCompareService,
    AuedExpectedChargesService,
    AudSurchargeTypeService,
    AudVASService,
    AudActionService,
    AudRerateService,
    AuthService,
    BillingService,
    BillingPortalVwService,
    CarrierAreasService,
    CarrierRatesService,
    cfgBillTypesService,
    cfgClientRulesService,
    cfgDepartmentsService,
    cfgEmployeeTypesService,
    cfgFunctionTypesService,
    cfgIssueTypesService,
    cfgPayrollTypesService,
    cfgScheduleTypesService,
    cfgTerminationReasonsService,
    cfgTrackTypesService,
    cfgWorkStatusesService,
    cfgWorkGroupsService,
    cfgRateTypesService,
    cfgCarriersService,
    cfgAdditionalServicesService,
    cfgAdditionalServiceOptionsService,
    cfgShippingServiceAdditionalServiceOptionsService,
    p3plFulfillmentClientService,
    cfgCarrierAccountsService,
    cfgShippingServiceService,
    cfgRateDivisionTypesService,
    cfgShipViaService,
    cfgRatingEngineService,
    cfgRatingEngineSitesService,
    cfgRatingEngineServiceService,
    cfgPaymentTypesService,
    cfgAccounts3dPartyService,
    ClientsGoTimeService,
    ClientsService,
    ConfirmationService,
    CountryCodesService,
    CurrencyExchangeService,
    DefaultSubjectTosService,
    docActorService,
    docAssignmentService,
    docCategoryService,
    docDashboardService,
    docDocumentService,
    docHistoryService,
    docStatusService,
    docVersionService,
    docQuizService,
    docQuizExService,
    docQuizQuestionService,
    docQuizResponseService,
    docQuizInstanceService,
    docRoleService,
    docTriggerService,
    docLanguageService,
    DraggedService,
    EmailExService,
    EmailsService,
    EmailTypesService,
    EmailVwService,
    EntityEditService,
    EntityListService,
    EntityPersonService,
    EntitySearchService,
    EntityVwService,
    FreightBillingService,
    FreightPayableService,
    FuelSurchargesService,
    GroupsService,
    GroupTypesService,
    HandlingChargesService,
    HardwareConnectorService,
    InternationalTypesService,
    InvoicingService,
    JwtHelperService,
    LabelForEnumPipe,
    LabelForValuePipe,
    LinkedPersonService,
    LookupsService,
    MediaExService,
    MediaService,
    MediaTypesService,
    MediaVwService,
    MenuService,
    MessageService,
    MinsToTimePipe,
    MinusToParensPipe,
    NmfcNumbersService,
    NoteTypesService,
    OrderBaseService,
    OrderCalculatorService,
    OrderHoldsService,
    OrderIssuesService,
    OrderNmfcService,
    OrdersFreightExService,
    OrdersFreightHandlingService,
    OrdersFreightLegService,
    OrdersFreightPayableService,
    OrdersFreightService,
    OrderTaskService,
    OrganizationsListService,
    P3PLService,
    P3PLShipmentPackageService,
    PayableTypesService,
    PaymentTermsService,
    PaymentTypesService,
    PEMEmailService,
    PEMMediaService,
    PEMPhoneService,
    PersistenceService,
    PersonSearchService,
    PersonsService,
    PersonVwService,
    PhonesService,
    PhoneTypesService,
    PhoneVwService,
    PieceTypeService,
    PlaceExService,
    PlacesService,
    PlaceTypesService,
    PlaceVwService,
    QuotesService,
    QuoteStatusService,
    QuoteSummaryVwService,
    RefNotesService,
    RefDocumentService,
    ReportCategoryService,
    ReportDisplayComponent,
    ReportInfoService,
    ReportingService,
    ReportOptionsService,
    ReportParameterTypeService,
    ReportsService,
    ReportViewHistoryService,
    RightsService,
    RightTypesService,
    SendItService,
    SendItBatchService,
    SendItPackageLabelService,
    SendItPackageService,
    SendItDashboardService,
    ServiceLevelsService,
    ServiceProcessesService,
    ServiceUnitsService,
    ShipmentTypesService,
    ShippingServicesService,
    StateProvinceCodesService,
    StatusesService,
    SystemOptionsService,
    TaskTypesService,
    timeActionService,
    timeClientFunctionService,
    timeClientJobService,
    timeClientWarehouseService,
    timeDashboardService,
    timeEmployeeAccessService,
    timeEmployeeFunctionService,
    timeEmployeeService,
    timeFunctionGroupFunctionService,
    timeFunctionGroupService,
    timeFunctionService,
    timeIssueService,
    timeJobService,
    timePayrollBatchService,
    timePayrollBatchDetailService,
    timeWarehousePayrollSettingsService,
    timeScheduleService,
    timeScheduleTemplateService,
    timeSettingService,
    timeTimeSegmentService,
    timeTrackSegmentService,
    TrackingCategoryService,
    TrackingService,
    TrackingVwService,
    TrackingPortalVwService,
    UsersService,
    UtilityService,
    utlPlaceService,
    VendorFeesService,
    VendorsService,
    VendorsService,
    WarehouseAlertsService,
    WarehouseDashboardService,
    WarehouseFreightSelectService,
    WarehousesService,
    WLPackageService,
    WLShipmentService,
    AuthGuard,
    {
      provide: 'apiURL',
      useValue: environment.apiURL
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
