import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { Alert, AlertType } from '../_models/index';
import { MessageService } from 'primeng/api'; //primeng/messageservice';

@Injectable()
export class AlertService {
    private subject = new Subject<Alert>();
    // private keepAfterRouteChange = false;

    INFOTIMEOUT = 6000;
    ERRTIMEOUT = 0; // never hide

    // constructor(private router: Router) {
    //     // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    //     router.events.subscribe(event => {
    //         if (event instanceof NavigationStart) {
    //             if (this.keepAfterRouteChange) {
    //                 // only keep for a single route change
    //                 this.keepAfterRouteChange = false;
    //             } else {
    //                 // clear alert messages
    //                 this.clear();
    //             }
    //         }
    //     });
    // }

    constructor(
        private toaster: MessageService
    ) {}

    getAlert(): Observable<any> {
        return this.subject.asObservable();
    }

    success(message: string, key = 'tr', timeout = this.INFOTIMEOUT) { // , keepAfterRouteChange = false) {
        this.toaster.add( { severity: 'success', summary: 'Success!', detail: message, life: timeout, key: key });
//        this.alert(AlertType.Success, message, timeout, keepAfterRouteChange);
    }

    error(message: string, key = 'tr', timeout = this.ERRTIMEOUT) { // , keepAfterRouteChange = false) {
        this.toaster.add( { severity: 'error', summary: 'Error!', detail: message, sticky: true, key: key });
        // this.alert(AlertType.Error, message, timeout, keepAfterRouteChange);
    }

    info(message: string, key = 'tr', timeout = this.INFOTIMEOUT) { // , keepAfterRouteChange = false) {
        this.toaster.add( { severity: 'info', summary: 'Info', detail: message, life: timeout, key: key});
//        this.alert(AlertType.Info, message, timeout, keepAfterRouteChange);
    }

    warn(message: string, key = 'tr', timeout = this.INFOTIMEOUT) { // , keepAfterRouteChange = false) {
        this.toaster.add( { severity: 'warn', summary: 'Warning!', detail: message, life: timeout, key: key});
//        this.alert(AlertType.Warning, message, timeout, keepAfterRouteChange);
    }

    // alert(type: AlertType, message: string, timeout: number, keepAfterRouteChange = false) {
    //     this.keepAfterRouteChange = keepAfterRouteChange;
    //     this.subject.next(<Alert>{ type: type, message: message, timeout: timeout });
    // }

    clear() {
        // clear alerts
        this.toaster.clear();
        // this.subject.next();
    }
}
