import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import { secPasswordUpdate, secUser, secUserValidations } from '../_models/SEC.model';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { secEntityUserEx } from '../_models/TableCustom.model';

@Injectable()
export class UsersService {

  api = 'secUser/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<secUser[]>(this.apiURL + this.api);
  }

  get(id: number) {
    return this.http.get<secUser>(this.apiURL + this.api + id);
  }
  
  getEntityUserEx(PersonID: number, EntityID: number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('PersonID', PersonID.toString());
    params = params.append('EntityID', EntityID.toString());
    return this.http.get<secEntityUserEx>(this.apiURL + this.api + 'EntityUserEx', { headers: headers, params : params });
  }

  getAllEx() {
    return this.http.get<secEntityUserEx[]>(this.apiURL + this.api + 'Ex');
  }

  create(item: secUser) {
    return this.http.post(this.apiURL + this.api, item);
  }

  update(item: secUser) {
    return this.http
      .put(this.apiURL + this.api + item.UserID, item);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getForwarderInfo(id: number) {
    return this.http.get<any>(this.apiURL + this.api + 'forwarder/' + id);
  }

  getUserTags() {
    return this.http.get<string[]>(this.apiURL + this.api + 'tags');
  }

  getAllForwarders() {
    return this.http.get<secEntityUserEx[]>(this.apiURL + this.api + 'forwarders');
  }

  getAllWarehousers() {
    return this.http.get<secEntityUserEx[]>(this.apiURL + this.api + 'warehousers');
  }

  getProDocActors() {
    return this.http.get<secEntityUserEx[]>(this.apiURL + this.api + 'prodocactors');
  }
  
  getUserNamesAndInitials() {
    return this.http.get<any>(this.apiURL + this.api + 'userNamesInits');
  }

  getForGroup(id: number) {
    return this.http.get<secEntityUserEx[]>(this.apiURL + this.api + 'group/' + id);
  }

  getAvailableForGroup(id: number) {
    return this.http.get<secEntityUserEx[]>(this.apiURL + this.api + 'group/' + id + '/available');
  }

  putForGroup(id: number, users: secEntityUserEx[]) {
    return this.http.put(this.apiURL + this.api + 'group/' + id, users);
  }

  checkPassword(id: number, pass: secPasswordUpdate) {
    return this.http.post(this.apiURL + this.api + 'password/' + id, pass);
  }

  changePassword(id: number, pass: secPasswordUpdate) {
    return this.http.put(this.apiURL + this.api + 'password/' + id, pass);
  }

  getUserValidations(id: number) {
    return this.http.get<secUserValidations[]>(this.apiURL + this.api + 'validations/' + id);
  }
}
