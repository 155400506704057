<p-panel [showHeader]="false">
    <div style="display: flex; justify-content: space-between; align-items: center; padding-bottom: 5px;">
        <span style="font-size:larger; font-weight: bold;">Select Manager</span>
        <button pButton icon="pi pi-user" label="Me" (click)="goHome()"></button>
    </div>

    <p-dropdown [options]="colleagues" [filter]="true" [showClear]="false" [resetFilterOnHide]="true"
        [(ngModel)]="forManagerID" id="selectManagers" name="selectManagers" [showClear]="false"
        (onChange)="selectManager()">
    </p-dropdown>
    <div style="margin-top: 10px;">
        <p-checkbox name="showFullTeam" label="Show Full Team" [(ngModel)]="showFullTeam" binary="true"
            (onChange)="toggleTeam()"></p-checkbox>
        <div style="float:right">
            Mgr: {{ forManagerID}}
        </div>
    </div>
</p-panel>
