<!-- Viewing Time In Chronological Order -->
<div *ngIf="pageTitle === 'Dashboard'" style="display: flex; justify-content: center;">
	<p-calendar [(ngModel)]="currentDate" (onSelect)="jumpDay()"
				[showButtonBar]="true" [monthNavigator]="true" [yearNavigator]="true" [showButtonBar]="true"
				[style]="{'width': '100%'}" [inputStyle]="{'width': '100%', 'text-align' : 'center'}" appendTo="body" yearRange="2000:2030"></p-calendar>
</div>
<div style="display: flex; flex-direction: column;" *ngIf="displayMode === 'detail'">
	<p-table [value]="activity" id="tblDetail" #tblDetail (onSort)="detailOnSort($event)">
		<ng-template pTemplate="caption" *ngIf="pageTitle === 'Dashboard'">
			<div style="float:left; padding-left:10px;">
				<button pButton label="Show Summary" (click)="showSummary()"></button>
			</div>
			Activity for {{ displayEmployee }} on {{displayDate | date }}
			<div style="float:right; padding-right:10px;" *ngIf="pageTitle === 'Clock Out' && employee.ClockStatus === 'IN'">
				<button pButton [label]="'Approve Time and Clock Out'" (click)="finish()"></button>
			</div>
			<div style="float:right; padding-right:10px;" *ngIf="pageTitle === 'Clock Out' && employee.ClockStatus === 'ADM'">
				<button pButton [label]="'Approve Time and Clock In'" (click)="finish()"></button>
			</div>
			<div style="float: right; padding-right: 10px;" *ngIf="pageTitle === 'Dashboard'">
				<button pButton type="button" icon="pi pi-angle-double-left" iconPos="left" label="Prev Day" (click)="prevDay()"></button>
				<!-- <div style="width: 5px;"></div> -->
				<button pButton type="button" icon="pi pi-angle-double-right" iconPos="right" label="Next Day" (click)="nextDay()"></button>
			</div>
		</ng-template>
		<ng-template pTemplate="caption" *ngIf="pageTitle === 'Clock Out'">
			<div style="float:left; padding-left:10px;">
				<button pButton label="Show Summary" (click)="showSummary()"></button>
			</div>
			Unapproved Activity for {{ displayEmployee }}
			<div style="float:right; padding-right:10px;" *ngIf="pageTitle === 'Clock Out' && employee.ClockStatus === 'IN'">
				<button pButton [label]="'Approve Time and Clock Out'" (click)="finish()"></button>
			</div>
			<div style="float:right; padding-right:10px;" *ngIf="pageTitle === 'Clock Out' && employee.ClockStatus === 'ADM'">
				<button pButton [label]="'Approve Time and Clock In'" (click)="finish()"></button>
			</div>
			<div style="float: right; padding-right: 10px;" *ngIf="pageTitle === 'Dashboard'">
				<button pButton type="button" icon="pi pi-angle-double-left" iconPos="left" label="Prev Day" (click)="prevDay()"></button>
				<!-- <div style="width: 5px;"></div> -->
				<button pButton type="button" icon="pi pi-angle-double-right" iconPos="right" label="Next Day" (click)="nextDay()"></button>
			</div>
		</ng-template>
		<ng-template pTemplate="header">
			<tr>
				<th width="3%" style="text-align: center;">
					Detail
				</th>
				<th style="text-align: center;" pSortableColumn="Function.FunctionCode" width="4%">
					Code 
					<span>
						<p-sortIcon field="Function.FunctionCode"></p-sortIcon>
					</span>
				</th>
				<th pSortableColumn="Function.FunctionName" width="10%">
					Function
					<span>
						<p-sortIcon field="Function.FunctionName"></p-sortIcon>
					</span>
				</th>
				<th style="text-align: center;" pSortableColumn="Start" width="6%">
					Start
					<span>
						<p-sortIcon field="Start"></p-sortIcon>
					</span>
				</th>
				<th style="text-align: center;" pSortableColumn="Stop" width="6%">
					Stop
					<span>
						<p-sortIcon field="Stop"></p-sortIcon>
					</span>
				</th>
				<th style="text-align: center;" pSortableColumn="Duration" width="4%">
					Dur
					<span>
						<p-sortIcon field="Duration"></p-sortIcon>
					</span>
				</th>
				<th style="text-align: center;" pSortableColumn="PaidDuration" width="4%">
					Paid
					<span>
						<p-sortIcon field="PaidDuration"></p-sortIcon>
					</span>
				</th>
				<th style="text-align: center;" width="3%">
					Issues
				</th>
				<th style="text-align: center;" width="3%">Emp</th>
				<th style="text-align: center;" *ngIf="pageTitle === 'Dashboard'" width="3%">Mgr</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-seg>
			<tr>
				<td style="text-align: center;">
					<button pButton icon="pi pi-align-justify" (click)="showTracks(seg)"></button>
				</td>
				<td style="text-align: center;">
					{{ seg.Function.FunctionCode}}
				</td>
				<td>
					{{ seg.Function.FunctionName }}
				</td>
				<td style="text-align: center;">
					<span  pTooltip="{{seg.Start | date: 'MM-dd-yyyy'}}">{{ seg.Start | date: 'h:mm a'}}</span>
				</td>
				<td style="text-align: center;" *ngIf="seg.Stop">
					<span  pTooltip="{{seg.Stop | date: 'MM-dd-yyyy'}}">{{ seg.Stop | date: 'h:mm a'}}</span>
				</td>
				<td style="text-align: center;" *ngIf="!seg.Stop">
					Open
				</td>
				<td style="text-align: center;">
					{{ seg.DisplayDuration }}
					<!-- {{ durationText(seg.Duration) }} -->
				</td>
				<td style="text-align: center;">
					{{ durationText(seg.PaidDuration) }}
				</td>
				<td style="text-align: center;">
					<button pButton [label]="(getNumberOfIssues(seg) < 1) ? ' + ' : getNumberOfIssues(seg)"
						(click)="logIssue(seg)" [style.backgroundColor]="getIssueColor(seg)" [style.color]="getIssueColor(seg) === 'transparent' ? 'black' : 'white'" [style.border]="none">
					</button>
				</td>
				<td *ngIf="pageTitle === 'Clock Out'" style="text-align: center;">
					<i class="pi pi-check" *ngIf="!seg.Disputed"></i>
				</td>
				<td *ngIf="pageTitle === 'Dashboard'" style="text-align: center;" pTooltip="No need to approve each segment. Approve them all at clock out!">
					<!-- <p-checkbox [(ngModel)]="seg.EmployeeApprovedDate" [binary]="true" [disabled]="true"></p-checkbox> -->
					<i class="pi pi-check" *ngIf="seg.EmployeeApprovedDate"></i>
				</td>
				<td *ngIf="pageTitle === 'Dashboard'" style="text-align: center;">
					<!-- <p-checkbox [(ngModel)]="seg.ManagerApprovedDate" [binary]="true" [disabled]="true"></p-checkbox> -->
					<i class="pi pi-check" *ngIf="seg.ManagerApprovedDate"></i>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="summary">
			<div *ngIf="!errorNoData" class="p-d-flex p-ai-center p-jc-between">
				<p>Shift Total {{ displaySum }}</p>		
				<p>Week Total {{ displayWeekSum }}</p>		
			</div>
			<div *ngIf="!errorNoData && pageTitle === 'Dashboard'">
				<p *ngIf="employeeScheduleStart">Schedule: {{employeeScheduleStart}} - {{employeeScheduleStop}} </p>
				<p *ngIf="!employeeScheduleStart">Schedule: <span style="color: red;">None</span> </p>
			</div>
			<h3 *ngIf="errorNoData" style="text-align: center;"> {{ errorNoDataString }} {{currentDate | date: 'medium'}} </h3>
		</ng-template>
	</p-table>

</div>

<!-- ------------------------------- -->
<!-- Viewing Time Sorted By Function -->
<div style="display: flex; flex-direction: column;" *ngIf="displayMode === 'summary'">
	<p-table>
		<ng-template pTemplate="caption" *ngIf="pageTitle === 'Dashboard'">
			<div style="float:left; padding-left:10px;">
				<button pButton label="Show Detail" (click)="showDetail()"></button>
			</div>
			Activity for {{ displayEmployee }} on {{displayDate | date }}
			<div style="float:right; padding-right:10px;" *ngIf="pageTitle === 'Clock Out' && employee.ClockStatus === 'IN'">
				<button pButton [label]="'Approve Time and Clock Out'" (click)="finish()"></button>
			</div>
			<div style="float:right; padding-right:10px;" *ngIf="pageTitle === 'Clock Out' && employee.ClockStatus === 'ADM'">
				<button pButton [label]="'Approve Time and Clock In'" (click)="finish()"></button>
			</div>
			<div style="float: right; padding-right: 10px;" *ngIf="pageTitle === 'Dashboard'">
				<button pButton type="button" icon="pi pi-angle-double-left" iconPos="left" label="Prev Day" (click)="prevDay()"></button>
				<!-- <div style="width: 5px;"></div> -->
				<button pButton type="button" icon="pi pi-angle-double-right" iconPos="right" label="Next Day" (click)="nextDay()"></button>
			</div>
		</ng-template>
		<ng-template pTemplate="caption" *ngIf="pageTitle === 'Clock Out'">
			<div style="float:left; padding-left:10px;">
				<button pButton label="Show Detail" (click)="showDetail()"></button>
			</div>
			Unapproved Activity for {{ displayEmployee }}
			<div style="float:right; padding-right:10px;" *ngIf="pageTitle === 'Clock Out' && employee.ClockStatus === 'IN'">
				<button pButton [label]="'Approve Time and Clock Out'" (click)="finish()"></button>
			</div>
			<div style="float:right; padding-right:10px;" *ngIf="pageTitle === 'Clock Out' && employee.ClockStatus === 'ADM'">
				<button pButton [label]="'Approve Time and Clock In'" (click)="finish()"></button>
			</div>
			<div style="float: right; padding-right: 10px;" *ngIf="pageTitle === 'Dashboard'">
				<button pButton type="button" icon="pi pi-angle-double-left" iconPos="left" label="Prev Day" (click)="prevDay()"></button>
				<!-- <div style="width: 5px;"></div> -->
				<button pButton type="button" icon="pi pi-angle-double-right" iconPos="right" label="Next Day" (click)="nextDay()"></button>
			</div>
		</ng-template>
	</p-table>
	<p-accordion [multiple]="true">
		<p-accordionTab *ngFor="let sum of sumActivity">
			<ng-template pTemplate="header"> {{ sum.Function.FunctionName }} </ng-template>
			<p-table [value]="sum.TimeSegments">
				<ng-template pTemplate="header">
					<tr>
						<th width="6%" style="text-align: center;">Detail</th>
						<th style="text-align: center;">Start</th>
						<th style="text-align: center;">Stop</th>
						<th width="15%">Duration</th>
						<th style="text-align: center;" width="7%"># of Issues</th>
						<th style="text-align: center;" width="7%">Emp</th>
						<th *ngIf="pageTitle === 'Dashboard'" style="text-align: center;" width="7%">Mgr</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-item>
					<tr>
						<td style="text-align: center;">
							<button pButton icon="pi pi-align-justify" (click)="showTracks(item)"></button>
						</td>
						<td style="text-align: center;">
							<span  pTooltip="{{item.Start | date: 'MM-dd-yyyy'}}">{{ item.Start | date: 'h:mm a'}}</span>
						</td>
						<td style="text-align: center;" *ngIf="item.Stop">
							<span  pTooltip="{{item.Stop | date: 'MM-dd-yyyy'}}">{{ item.Stop | date: 'h:mm a'}}</span>
						</td>
						<td *ngIf="!item.Stop">
							Open
						</td>
						<td>
							{{ item.DisplayDuration }}
						</td>
						<td style="text-align: center;">
							<button pButton [label]="getNumberOfIssues(item) < 1 ? ' + ' : getNumberOfIssues(item)"
								(click)="logIssue(item)" [style.backgroundColor]="getIssueColor(item)" [style.color]="getIssueColor(item) === 'transparent' ? 'black' : 'white'" [style.border]="none">
							</button>
						</td>
						<td *ngIf="pageTitle === 'Clock Out'" style="width: 7%">
							<i class="pi pi-check" *ngIf="!item.Disputed"></i>
						</td>
						<td *ngIf="pageTitle === 'Dashboard'" style="text-align: center;" pTooltip="No need to approve each segment. Approve them all at clock out!">
							<!-- <p-checkbox [(ngModel)]="seg.EmployeeApprovedDate" [binary]="true" [disabled]="true"></p-checkbox> -->
							<i class="pi pi-check" *ngIf="item.EmployeeApprovedDate"></i>
						</td>
						<td *ngIf="pageTitle === 'Dashboard'" style="text-align: center;">
							<!-- <p-checkbox [(ngModel)]="seg.ManagerApprovedDate" [binary]="true" [disabled]="true"></p-checkbox> -->
							<i class="pi pi-check" *ngIf="item.ManagerApprovedDate"></i>
						</td>
					</tr>
				</ng-template>
			</p-table>
		</p-accordionTab>
	</p-accordion>
	<p-table>
		<ng-template pTemplate="summary">
			<div *ngIf="!errorNoData">
				<p>Shift Total {{ displaySum }}</p>
				<p>Week Total {{ displayWeekSum }}</p>
			</div>
			<div *ngIf="!errorNoData && pageTitle === 'Dashboard'">
				<p *ngIf="employeeScheduleStart">Schedule: {{employeeScheduleStart}} - {{employeeScheduleStop}} </p>
				<p *ngIf="!employeeScheduleStart">Schedule: <span style="color: red;">None</span> </p>
			</div>
			<h3 *ngIf="errorNoData" style="text-align: center;"> {{ errorNoDataString }} {{currentDate | date: 'medium'}} </h3>
		</ng-template>
	</p-table>
</div>
<!-- ------------------------------- -->

<!-- ------------------------------- -->
<!-- Viewing Track Segments -->
<p-dialog header=" {{trackFunctionName}} " [(visible)]="viewingTracks" [style]="{width: '52vw'}" styleclass="track-dialog" *ngIf="viewingTracks" appendTo="body">
	<div style="width: 50vw; display: flex; justify-content: center;">
		<p-table [value]="trackSegs.TrackSegments">
			<ng-template pTemplate="header">
				<tr>
					<th><h3>Start</h3></th>
					<th><h3>Stop</h3></th>
					<th><h3>Duration</h3></th>
					<th><h3>Client</h3></th>
					<th><h3>Order</h3></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-item let-index="rowIndex">
				<tr style="border: 1px solid white; line-height: 10px;">
					<td style="text-align: center;">
						<h3 style="color: green;">{{item.Start | date:'h:mm a'}}</h3>
					</td>
					<td style="text-align: center;">
						<h3 *ngIf="!item.Stop">Current</h3>
						<h3 style="color: green;">{{item.Stop | date:'h:mm a'}}</h3>
					</td>
					<td style="text-align: center;">
						<h3 *ngIf="!item.Stop">Current</h3>
						<h3 *ngIf="item.Stop">{{item.Duration}} min</h3>
					</td>
					<td style="text-align: center;">
						<h3> {{item.ClientName}} </h3>
					</td>	
					<td style="text-align: center;">
						<h2 *ngIf="item.TrackID"> {{item.TrackID}} </h2>
						<h2 *ngIf="item.TrackReference"> {{item.TrackReference}} </h2>
					</td>
				</tr>
			</ng-template>
		</p-table>
		<br>
		<div class="addIssueToTrackBtn">
			<div class="btn" (click)="logIssue(trackSegs)">
				<!-- <h2>Issues {{getNumberOfIssues(trackSegs)}} </h2> -->
			</div>
		</div>
	</div>
</p-dialog>
<!-- ------------------------------- -->


<!-- Adding and Viewing Issues -->
<p-dialog header="Log Issue" [(visible)]="loggingIssue" [style]="{width: '35vw'}" styleclass="issue-dialog" *ngIf="loggingIssue" appendTo="body">
	<arc-issue-popup [deliveredIssuePackage]=sentIssuesArray [ApprovalPayrollFlag]=ApprovalPayrollFlag (hasChanged)="hasChanged($event)"></arc-issue-popup>
</p-dialog>
<!-- ------------------------------- -->
