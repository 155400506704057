<div style="width: 100%;">
    <!-- <p-button *ngIf="!editing" label="New Note" icon="fa fa-plus" (onClick)="onNewNote()"></p-button> -->
    <div *ngIf="!editingExisting && allowEdit" style="width: 100%;">
      <!-- *ngIf="editing"> -->
      <textarea pInputTextArea #editNoteField id="editNoteField" [(ngModel)]='editNoteText' autoResize="autoResize"
        (blur)="onSaveNote()" (keyup.enter)="onSaveNote()" (keyup.esc)="onCancelNote()" placeholder="Type new note here"></textarea>
      <br>
      <!-- <p-button label="Save" icon="fa fa-save" (onClick)="onSaveNote()"></p-button>
      <p-button label="Cancel" icon="fa fa-times" (onClick)="onCancelNote()"></p-button> -->
    </div>
  
    <!-- <div *ngIf="itemType !== OrdLinkType.Order"> -->
      <div style="height: 15vh;">
      <p-scrollPanel *ngIf="notes.length > 0" [style]="{width: '100%', height: '100%' }">
        <div *ngFor="let n of notes" style="border-bottom:black 1px solid;">
          <div style="width: 100%; display:flex;">
            <div style="width: 50%;">
              <span style="font-weight: bold; font-size: smaller">
                {{ n.PersonName }}
              </span><br />
            </div>
            <div style="width: 50%; text-align: right; padding-right:15px;">
              <span style="font-weight:bold; font-size:smaller;">{{ n.CreatedDate | date:dateFormat }}</span>
            </div>
          </div>
  
          <div *ngIf="(curNoteID !== n.NoteID)">
            {{ n.Note }}
            <!-- Per Shawn (Wrike https://www.wrike.com/open.htm?id=1052764134) remove Edit/Delete functionality completely from ProDocs -->
            <!-- <a *ngIf="allowEdit && (n.CreatedBy === +auth.currentUser.UserID)" (click)="onEditNote(n.NoteID)">[Edit]</a>
            <a *ngIf="allowEdit && (n.CreatedBy === +auth.currentUser.UserID)" (click)="onConfirmDelete(n)">[Delete]</a> -->
          </div>
  
          <div *ngIf="curNoteID === n.NoteID">
            <textarea pInputTextArea [(ngModel)]='editNoteText' autoResize="autoResize"></textarea>
            <br>
            <p-button label="Save" icon="fa fa-save" (onClick)="onUpdateNote()"></p-button>
            <p-button label="Cancel" icon="fa fa-times" (onClick)="onCancelNote()"></p-button>
          </div>
  
        </div>
      </p-scrollPanel>
      <span *ngIf="notes.length === 0" >No notes found</span>
    </div>
  </div>