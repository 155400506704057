import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import { utlPhoneType } from '../_models/UTL.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PhoneTypesService {


  api = 'utlPhoneType/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  get(id: number) {
    return this.http.get<utlPhoneType>(this.apiURL + this.api + id);
  }

  getAll() {
    return this.http.get<utlPhoneType[]>(this.apiURL + this.api);
  }

  create(phoneType: utlPhoneType) {
    return this.http.post(this.apiURL + this.api, phoneType);
  }

  update(phoneType: utlPhoneType) {
    return this.http
      .put(this.apiURL + this.api + phoneType.PhoneTypeID, phoneType);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

}
