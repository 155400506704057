import config from "../../auth_config.json";

const {
  domain,
  clientId,
  authorizationParams: { audience },
  apiUri,
  errorPath,
} = config as {
  domain: string;
  clientId: string;
  authorizationParams: {
    audience?: string;
  };
  apiUri: string;
  errorPath: string;
};

export const environment = {
  production: false,
  display: "Stord",
  headerColor: "#FE9900",
  apiURL: "https://api.360.staging.stord.com/api/",
  tokenURL: "https://api.360.staging.stord.com/token",
  clientID: "099153c2625149bc8ecb3e85e03f0022a",
  SSRSServer: "http://ReportServer?/ArcturusReports/",
  SSRSCmds:
    "&rs:Command=Render&rs:ClearSession=true&rc:Parameters=false&rc:Toolbar=false",
  SSRSOutput: "PDF",
  ShipOrderURL: "https://www.p3pl.com/Popups",
  AssetTrackerURL: "https://assets.propack.com",

  auth: {
    domain,
    clientId,
    authorizationParams: {
      ...(audience && audience !== "YOUR_API_IDENTIFIER" ? { audience } : null),
      redirect_uri: window.location.origin,
    },
    errorPath,
  },
  httpInterceptor: {
    allowedList: [`${apiUri}/*`],
  },

  SendItScaleInterval: 1000,
};
