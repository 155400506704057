<div class="captureContainer" *ngIf="employee?.ClockStatus === 'IN' && punchRecorded === false" style="text-align: center;">
	<div class="message">
		<h1>You are already clocked in.</h1>
	</div>
</div>
<div class="captureContainer" *ngIf="employee?.ClockStatus === 'BRK'" style="text-align: center;">
	<div class="message">
		<h1>You are currently on break.</h1>
	</div>
</div>

<div class="captureContainer" *ngIf="employee?.ClockStatus === 'ADM' && !viewingTime" style="text-align: center;">
	<div class="message">
		<h1>You have time that requires approval.</h1>
		<h2>Please view and approve your time before clocking in.</h2>
		<h1>Last Punch: {{employee.PreviousTimeSegment?.Start | date:'medium'}} </h1>
		<div>
			<button pButton label="View Time" (click)="viewingTime = true"></button>
		</div>
	</div>
</div>

<arc-time-summary *ngIf="viewingTime" [pageTitle]="'Clock Out'" (admOut)="toggleViewTime($event)"></arc-time-summary>


<arc-select-function *ngIf="!deliveredFunction && employee?.ClockStatus === 'OUT'" (setJob)="collectPackage($event)" (setTimeTrack)="beginTimeTrack($event)"></arc-select-function>

<div class="container" *ngIf="deliveredFunction && !punchRecorded && timeTrack === false">
	<div style="padding: 5px;"></div>
	<div class="backBtn">
		<button pButton style="width: 75%" label="Back" (click)="goBack()"></button>
	</div>
	<div class="exitBtnContainer">
		<h1>Now Clocking Into: {{deliveredFunction.FunctionName}}</h1>
		<h1 *ngIf="deliveredClient">For: {{deliveredClient.ClientName}} </h1>
		<h1 *ngIf="deliveredClientReferenceNote">CSRN: {{ deliveredClientReferenceNote }} </h1>

		<!-- <h1 *ngIf="isBilling">Is Billing</h1> -->

		<div class="exitBtn" (click)="finish()" style="background-color: rgb(26, 124, 209);">
			<p>CONFIRM</p>
		</div>
		<br>
		<div class="logIssueBtn" (click)="createIssue()" *ngIf="addedIssue === false" style="background-color: rgb(243, 168, 59);">
			<p>Log Issue</p>
		</div>
		<h1 *ngIf="addedIssue === true" style="color: green">Issue Logged</h1>
	</div>

	<p-dialog header="Log Issue" [(visible)]="creatingIssue" [style]="{width: '50vw', height: '500px'}" styleclass="issue-dialog" *ngIf="creatingIssue" appendTo="body">
		<arc-issue-popup [deliveredIssuePackage]=sentIssueArray [ApprovalPayrollFlag]=ApprovalPayrollFlag (hasChanged)="hasChanged($event)"></arc-issue-popup>
	</p-dialog>
</div>

<div class="container" *ngIf="deliveredFunction && !punchRecorded && timeTrack === true">
	<div style="padding: 5px;"></div>
	<!-- <div class="backBtn" (click)="goBack()" style="background-color: ;">
		Back
	</div> -->
	<div class="backBtn">
		<button pButton style="width: 75px" label="Back" (click)="goBack()"></button>
	</div>
	<div class="exitBtnContainer">
		<h1>Now Clocking Into: {{deliveredFunction.FunctionName}}</h1>
		<h1 *ngIf="deliveredClient">For: {{deliveredClient.ClientName}} </h1>
		<!-- <h1 *ngIf="isBilling">Is Billing</h1> -->

		<div class="exitBtn" (click)="confirmTimeTrack()" style="background-color: rgb(26, 124, 209);">
			<p>Begin Time Track w/ OrderID</p>
		</div>
		<br>
	</div>

	<p-dialog header="Log Issue" [(visible)]="creatingIssue" [style]="{width: '50vw', height: '500px'}" styleclass="issue-dialog" *ngIf="creatingIssue" appendTo="body">
		<arc-issue-popup [deliveredIssuePackage]=sentIssueArray [ApprovalPayrollFlag]=ApprovalPayrollFlag  (hasChanged)="hasChanged($event)"></arc-issue-popup>
	</p-dialog>
</div>