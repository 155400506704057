import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UserSettings, UserEntity } from '../../_models';
import { UserSettingsService } from '../../_data/user-settings.service';
import { AlertService } from '../../_services';
import { BehaviorSubject, Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { WarehousesService } from '../../_data/warehouses.service';
import { orgPersonSearchResult } from '../../_models/ORG.model';
import { IDType } from '../../_models/enums.model';

@Component({
  selector: 'arc-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit, OnDestroy {

  @ViewChild('UserSettingsForm', { static: true }) myForm: NgForm;

  @Output() editComplete = new EventEmitter<number>();
  @Output() navigate = new EventEmitter<string>();

  subUserID: Subscription;
  subUser: Subscription;

  private _userID = new BehaviorSubject<number>(null);
  private _user = new BehaviorSubject<UserSettings>(null);

  // selectDialogVisible = false;
  newItem = false;

  @Input() InSec: false;
  @Input() ByUser: boolean;

  // possible input: UserID, from the
  @Input()
  set userID(value: number) {
    this._userID.next(value);
  }

  get userID() {
    return this._userID.getValue();
  }

  @Input()
  set user(value: UserSettings) {
    this._user.next(value);
  }

  get user() {
    return this._user.getValue();
  }

  editUser: UserSettings = new UserSettings();

  warehouses: SelectItem[] = [];
  entityList: UserEntity[] = [];
  selectedEntity: UserEntity;

  IDType = IDType;

  constructor(
    private users: UserSettingsService,

    private warehouseService: WarehousesService,
    private alert: AlertService
  ) { }

  ngOnInit() {
    // Bring in Warehouse Services
    this.warehouseService.getAll()
      .subscribe(data => {
        this.warehouses = data
          .filter(p => p.IsActive === true)
          .sort((a, b) => a.WarehouseName < b.WarehouseName ? -1 : a.WarehouseName > b.WarehouseName ? 1 : 0)
          .map(p => ({ label: p.WarehouseName.toUpperCase(), value: p.WarehouseID }));
        // this.warehouses.unshift({ label: 'ALL', value: null })
      },
        error => {
          this.alert.error(error);
          // this.loading = false;
        });

    this.subUser = this._user.subscribe(
      () => {
        if (this.user) {
          this.editUser = { ...this.user };
        }
      },
      (error) => this.alert.error(error)
    );

    this.subUserID = this._userID.subscribe(() => {
      // this.myForm.reset();
      if (!this.user) {
        if (this.userID !== IDType.NewRec) {
          this.users.get(this.userID)
            .subscribe((data) => {
              this.editUser = data;
              this.editUser.ByUser = this.ByUser;
            },
              error => this.alert.error(error)
            );
        } else {
          this.editUser = new UserSettings();
        }
      }
    });

  }

  ngOnDestroy() {
    this.subUserID.unsubscribe();
    this.subUser.unsubscribe();
  }

  onHide() {
    //  this.editUser = new UserSettings();
    this.myForm.reset();
  }

  doSave(action: string = null) {
    if (this.editUser.UserID === IDType.NewRec) {
      console.log('creating new user', this.editUser);
      this.users.create(this.editUser)
        .subscribe(
          (data: any) => {
            this.editUser = data.returnItem;
            this.alert.success('User created.');
            // this.myForm.form.markAsPristine();
            this.editComplete.emit(this.editUser.UserID);
            if (action) {
              this.navigate.emit(action);
            }
          },
          error => this.alert.error(error)
        );
    } else {
      console.log('updating user', this.editUser);
      this.users.update(this.editUser)
        .subscribe(
          (data: any) => {
            this.alert.success('User updated.');
            this.editUser = data.returnItem;
            // this.myForm.form.markAsPristine();
            this.editComplete.emit(this.editUser.UserID);
            if (action) {
              this.navigate.emit(action);
            }

          },
          error => {
            console.log(error);
            this.alert.error(error);
          }
        );
    }
  }

  toggleNewPassword() {
    this.editUser.NewPassword = !this.editUser.NewPassword;

    this.editUser.Password = null;
    this.editUser.ConfirmPassword = null;
  }

  doNext(action: string) {
    this.doSave(action);
  }

  doCancel() {
    this.editComplete.emit(null);
  }

  onPersonSearchComplete(item: orgPersonSearchResult) {
    console.log('you selected', item);
  }

  markAsDefault(entity: UserEntity) {
    this.editUser.Entities.forEach(e => e.IsDefaultForUser = e.EntityID === entity.EntityID);
  }

  toggleActive(entity: UserEntity) {
    const idx = this.editUser.Entities.findIndex(e => e.EntityID === entity.EntityID);
    console.log('found idx', idx);
    if (idx >= 0) {
      this.editUser.Entities[idx].IsActive = !this.editUser.Entities[idx].IsActive;
      console.log('set to ', this.editUser.Entities[idx].IsActive);
    }
  }

  isPropack(): boolean {
    return this.editUser.Entities.findIndex((e: UserEntity) => (e.EntityID === 0) && (e.IsActive)) >= 0 ;
  }

  onEntitySearch(event) {
    this.selectedEntity = null;
    this.users.getAvailableEntities(this.userID, event.query)
      .subscribe(
        (data: UserEntity[]) => {
          this.entityList = data;
        },
        (error) => this.alert.error(error)
      );
  }

  onEntitySelect(item: UserEntity) {
    this.selectedEntity = item;
  }

  onEntityConnect() {

    // fill in the blanks
    this.selectedEntity.EntityUserID = IDType.NewRec;
    this.selectedEntity.IsDefaultForUser = false;
    this.selectedEntity.IsActive = true;
    this.selectedEntity.Title = null;
    // add it to the list -- will be saved/handled when the user is saved/created
    this.editUser.Entities.push(this.selectedEntity);
    this.selectedEntity = null;
    this.entityList = [];
  }

}
