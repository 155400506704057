import { Component, OnInit } from '@angular/core';
import { AssignmentCompletionDistributionTypes } from 'app/_models/enums.model';
import { AuthService } from 'app/_services';

@Component({
  selector: 'prodocs-actors-home-dashboard',
  templateUrl: './prodocs-actors-home-dashboard.component.html',
  styleUrls: ['./prodocs-actors-home-dashboard.component.scss']
})
export class ProdocsActorsHomeDashboardComponent implements OnInit {

  // ** TOGGLES ** // 
  showStats: boolean = false;

  // ** MISC ** \\
  displayTypes = AssignmentCompletionDistributionTypes

  constructor(
    private auth: AuthService
  ) { }

  ngOnInit(): void { }

  hasRight(right: string): boolean {
    return this.auth.userHasRight(right);
  }

  handleTabChange(e) {
    if (e.index === 1) {
      this.showStats = true;
    }
  }

}
