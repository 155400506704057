import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import { cfgAssociationType } from '../_models/CFG.model';

import { HttpClient } from '@angular/common/http';


@Injectable()
export class AssociationTypesService {


  api = 'cfgAssociationType/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<cfgAssociationType[]>(this.apiURL + this.api);

  }
  create(associationType: cfgAssociationType) {
    return this.http.post(this.apiURL + this.api, associationType);
  }

  update(associationType: cfgAssociationType) {
    return this.http
      .put(this.apiURL + this.api + associationType.AssociationTypeID, associationType);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

}
