import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import { HttpClient } from '@angular/common/http';
import { orgEntityHeader, orgEntitySearchResult, orgAddress, orgEntity } from '../_models/ORG.model';
import { EntityType } from '../_models/enums.model';

@Injectable()
export class EntityEditService {

  api: string;

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getEntity(entityID: number) {
    this.api = 'orgEntity/edit/';
    return this.http.get<any>(this.apiURL + this.api + entityID);

  }

  // saveEntity(entity: orgEntityHeader) {
  //   this.api = 'orgEntity/header';
  //   return this.http.put<orgEntityHeader>(this.apiURL + this.api, entity);
  // }

  saveEntityName(entityID: number, entityName: string) {
    this.api = 'orgEntity/edit/';
    const config = { headers: { 'Content-Type': 'application/json' } };
    const payload = { 'entityID': entityID, 'entityName': entityName };

    return this.http.put(this.apiURL + this.api + 'name', payload, config);
  }

  saveEntityQuick(editAddress: orgAddress, entityType: EntityType, associatedEntityID: number) {
    this.api = 'orgEntity/saveQuick/';
    return this.http.post(this.apiURL + this.api + entityType + '/' + associatedEntityID, editAddress);
  }

  designateEntity(entityID: number, entityType: EntityType) {
    this.api = 'orgEntity/designate/';
    return this.http.put(this.apiURL + this.api + entityID + '/' + entityType, null);
  }

  designateAssociateEntity(entityID: number, associatedEntityID: number, entityType: EntityType) {
    this.api = 'orgEntity/designateAssociate/';
    return this.http.put(this.apiURL + this.api + entityID + '/' + associatedEntityID + '/' + entityType, null);
  }

  associateEntity(entityID: number, associatedEntityID: number, entityType: EntityType) {
    this.api = 'orgEntity/associate/';
    return this.http.put(this.apiURL + this.api + entityID + '/' + associatedEntityID + '/' + entityType, null);
  }
}

