import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';

import { AuthGuard } from './_guards/auth.guard';
import { ValidationsHomeComponent } from './validations/validations-home/validations-home.component';

export const appRoutes: Routes = [
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  { path: 'portal', loadChildren: () => import('./portal/portal.module').then(m => m.PortalModule) },
  { path: 'billing', loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule) },
  { path: 'customer-service', loadChildren: () => import('./customer-service/customer-service.module').then(m => m.CustomerServiceModule) },
  { path: 'login', component: LoginComponent },
  { path: 'organizations', loadChildren: () => import('./organizations/organizations.module').then(m => m.OrganizationsModule) },
  { path: 'security', loadChildren: () => import('./security/security.module').then(m => m.SecurityModule) },
  { path: 'manifesting', loadChildren: () => import('./manifesting/manifesting.module').then(m=> m.ManifestingModule) },
  { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule) },
  { path: 'shipping', loadChildren: () => import('./shipping/shipping.module').then(m => m.ShippingModule) },
  { path: 'auditing', loadChildren: () => import('./auditing/auditing.module').then(m => m.AuditingModule) },
  { path: 'time', loadChildren: () => import('./time/time.module').then(m => m.TimeModule) },
  { path: 'assets', loadChildren: () => import('./assets/asset.module').then(m => m.AssetModule) },
  { path: 'prodocs', loadChildren: () => import('./prodocs/prodocs.module').then(m => m.ProdocsModule) },
  { path: 'warehouse-services', loadChildren: () => import('./warehouse-services/warehouse-services.module').then(m => m.WarehouseServicesModule)},
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'validations', component: ValidationsHomeComponent},
  { path: '**', redirectTo: ''}
];


@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes
// { enableTracing: true } // debugging purposes only
, {})
  ],
  declarations: [],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
