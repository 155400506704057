import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class WarehouseDashboardService {


  api = 'ordWarehouseDashboard/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  get(warehouseCode: string, dashboardType: string) {
    return this.http.get<any>(this.apiURL + this.api + warehouseCode + '/' + dashboardType);

  }
}
