import { Injectable, Inject } from '@angular/core';
import { refNote } from '../_models/REF.model';
import { HttpClient } from '@angular/common/http';
import { OrgItemType } from '../_models/enums.model';


@Injectable({
  providedIn: 'root'
})
export class RefNotesService {

  api = 'refNote/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<refNote[]>(this.apiURL + this.api);

  }
  create(item: refNote) {
    return this.http.post(this.apiURL + this.api, item);
  }

  update(item: refNote) {
    return this.http
      .put(this.apiURL + this.api + item.NoteID, item);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getForItem(itemType: OrgItemType, itemID: number) {
    return this.http.get<refNote[]>(this.apiURL + this.api + itemType + '/' + itemID);
  }

}
