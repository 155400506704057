<div style="width: 100%; display: flex; justify-content: center; padding:10px;">
    Select the person to whom the document should be re-assigned, specify the Soft Due Date and Hard Due Date below,
    then press 'Reassign'<div class=""></div>
</div>
<div style="width: 100%; display: flex; justify-content: center; padding:10px;">
    <div class="p-inputgroup">
        <span class="p-inputgroup-addon">Soft Due Date:</span>
        <p-calendar [(ngModel)]="SoftDue" [minDate]="minDate"></p-calendar>
    </div>
    <div class="p-inputgroup">
        <span class="p-inputgroup-addon">Hard Due Date:</span>
        <p-calendar [(ngModel)]="HardDue" [minDate]="minDate"></p-calendar>
    </div>
    <div class="p-inputgroup">
        <button pButton label="Reassign" (click)="doConfirmReassign()"></button>
    </div>
</div>
<div>
    <p-table #dt [value]="candidates" selectionMode="multiple" [(selection)]="selectedPersons" sortMode="multiple" [multiSortMeta]="localFilter.MultiSortMeta" styleClass="mt-5"
        dataKey="PersonID" [metaKeySelection]="true" [loading]="isLoading" (onFilter)="doFilter($event)" (onSort)="onSort($event)"
        (onPage)="onPage($event)" [tableStyle]="{'min-width': '50rem'}">
        <ng-template pTemplate="header">
            <tr [pSelectableRow]="candidate">
                <th style="width:3em"></th>
                <th pSortableColumn="PersonName" [ngStyle]="{'width':'15%', 'text-align':'left'}">
                    Person
                    <span>
                        <p-sortIcon field="PersonName"></p-sortIcon>
                    </span>
                </th>
                <th pSortableColumn="DepartmentName" [ngStyle]="{'width':'20%', 'text-align':'left'}">
                    Department
                    <span>
                        <p-sortIcon field="DepartmentName"></p-sortIcon>
                    </span>
                </th>
                <th pSortableColumn="WarehouseName" [ngStyle]="{'width':'10%', 'text-align':'left'}">
                    Warehouse
                    <span>
                        <p-sortIcon field="WarehouseName"></p-sortIcon>
                    </span>
                </th>
                <th pSortableColumn="LanguageCode" [ngStyle]="{'width':'10%', 'text-align':'left'}">
                    Language
                    <span>
                        <p-sortIcon field="LanguageCode"></p-sortIcon>
                    </span>
                </th>
                <th pSortableColumn="Function" [ngStyle]="{'width':'15%', 'text-align':'left'}">
                    Function
                    <span>
                        <p-sortIcon field="Function"></p-sortIcon>
                    </span>
                </th>
                <th pSortableColumn="LastUsed" [ngStyle]="{'width':'12%', 'text-align':'left'}">
                    Last Used
                    <span>
                        <p-sortIcon field="LastUsed"></p-sortIcon>
                    </span>
                </th>
                <th pSortableColumn="DaysSinceLastUse" [ngStyle]="{'width':'10%', 'text-align':'left'}">
                    Days ago
                    <span>
                        <p-sortIcon field="DaysSinceLastUse"></p-sortIcon>
                    </span>
                </th>
                <th pSortableColumn="AssignmentStatus" [ngStyle]="{'width':'15%', 'text-align':'left'}">
                    Assign Status
                    <span>
                        <p-sortIcon field="AssignmentStatus"></p-sortIcon>
                    </span>
                </th>
                <th pSortableColumn="LastAssignment" [ngStyle]="{'width':'12%', 'text-align':'left'}">
                    Last Assigned
                    <span>
                        <p-sortIcon field="LastAssignment"></p-sortIcon>
                    </span>
                </th>
            </tr>
            <!--Column Filters-->
            <tr>
                <th style="width:3em; text-align: center;" class="col-icon-delete" (click)="clearLocalFilter(dt)">
                    <div>
                        <i class="fa fa-ban"></i>
                    </div>
                </th>
                <th>
                    <input pInputText status="text" id="PersonNameFilter" [(ngModel)]="localFilter.PersonName"
                        (input)="mydt.filter(localFilter.PersonName, 'PersonName', 'contains')">
                </th>
                <th>
                    <input pInputText status="text" id="DDepartmentNameFilter" [(ngModel)]="localFilter.DepartmentName"
                        (input)="mydt.filter(localFilter.DepartmentName, 'DepartmentName', 'contains')">
                </th>
                <th style="width:3em">
                    <input pInputText status="text" id="WarehouseNameFilter" [(ngModel)]="localFilter.WarehouseName"
                        (input)="mydt.filter(localFilter.WarehouseName, 'WarehouseName', 'contains')">
                </th>
                <th style="width:3em">
                    <p-multiSelect [options]="languages" defaultLabel="All" [(ngModel)]="localFilter.LanguageCode"
                        (onChange)="mydt.filter(localFilter.LanguageCode, 'LanguageCode', 'in')" appendTo="body"
                        optionValue="value" placeholder="Language" [style]="{'width':'100%'}">
                    </p-multiSelect>
                    <!-- <input pInputText status="text" id="LanguageCodeFilter" [(ngModel)]="localFilter.LanguageCode"
                        (input)="mydt.filter(localFilter.LanguageCode, 'LanguageCode', 'contains')"> -->
                </th>
                <th style="width:3em">
                    <input pInputText status="text" id="FunctionFilter" [(ngModel)]="localFilter.Function"
                        (input)="mydt.filter(localFilter.Function, 'Function', 'contains')">
                </th>
                <th style="width:3em">
                    <p-calendar [(ngModel)]="localFilter.LastUsed"
                        (onSelect)="dt.filter(localFilter.LastUsed, 'LastUsed', 'dateRangeFilter')"
                        (onClearClick)="dt.filter('', 'LastUsed','contains')" [showButtonBar]="true"
                        [readonlyInput]="true" [monthNavigator]="true" [yearNavigator]="true"
                        [inputStyle]="{'width': '100%'}" appendTo="body" yearRange="2000:2030" selectionMode="range">
                    </p-calendar>
                </th>
                <th style="width:3em">
                    <p-inputNumber [(ngModel)]="localFilter.DaysAgo" [useGrouping]="false"
                        (onInput)="dt.filter($event.value, 'DaysSinceLastUse','lte')"></p-inputNumber>
                </th>
                <th>
                    <p-multiSelect [options]="statuses" defaultLabel="All" [(ngModel)]="localFilter.AssignmentStatus"
                        (onChange)="mydt.filter(localFilter.AssignmentStatus, 'AssignmentStatus', 'in')" appendTo="body"
                        optionValue="value" placeholder="Statuses" [style]="{'width':'100%'}">
                    </p-multiSelect>
                </th>
                <th style="width:3em">
                    <p-calendar [(ngModel)]="localFilter.LastAssigned"
                        (onSelect)="dt.filter(localFilter.LastAssigned, 'LastAssignment', 'dateRangeFilter')"
                        (onClearClick)="dt.filter('', 'LastAssignment','contains')" [showButtonBar]="true"
                        [readonlyInput]="true" [monthNavigator]="true" [yearNavigator]="true"
                        [inputStyle]="{'width': '100%'}" appendTo="body" yearRange="2000:2030" selectionMode="range">
                    </p-calendar>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-candidate let-rowIndex="rowIndex">
            <tr [pSelectableRow]="candidate" [pSelectableRowIndex]="rowIndex"
                [disabled]="candidate.AssignmentStatus === 'Open'">
                <td>
                    <!-- <p-tableCheckbox [value]="candidate" [disabled]="candidate.assignmentStatus === 'Open'"></p-tableCheckbox> -->
                </td>
                <td>{{candidate.PersonName}}</td>
                <td>{{candidate.DepartmentName}}</td>
                <td>{{candidate.WarehouseName}}</td>
                <td>{{candidate.LanguageCode | labelForValue: languages}}</td>  
                <td>{{candidate.Function}}</td>
                <!-- <td>{{candidate.LastUsed}}</td> -->
                <td>{{(candidate.LastUsed) ? (candidate.LastUsed | date:'mediumDate') : ""}}</td>
                <td style="text-align:right">{{candidate.DaysSinceLastUse}}</td>
                <!-- <td style="text-align:right">{{(candidate.DaysSinceLastUse !== 0) ? candidate.DaysSinceLastUse : ""}}</td> -->
                <td>{{candidate.AssignmentStatus}}</td>
                <td>{{candidate.LastAssignment | date:'MM/dd/yyyy'}}</td>
            </tr>
        </ng-template>

        <ng-template pTemplate="summary" style="height: 48px">
            Count: {{ dt.totalRecords | number}}

        </ng-template>

        <ng-template pTemplate="emptymessage">
            <span>None found.</span>
        </ng-template>
    </p-table>
</div>
