import { IDType, DimFactors } from './enums.model';

import {
	orgEntity,
	orgPlace,
	orgPerson,
	orgEntityVw,
} from './ORG.model';

import { cfgWarehouse } from './CFG.model';

import { FeeType } from './enums.model';
import { chgInvoice } from './CHG.model';
import { utlByNames } from './utlByNames.model';

export class ordAddress {
	AddressID: number;
	AddressHash: any[];
	ContactName: string;
	EntityName: string;
	PlaceName: string;
	Address1: string;
	Address2: string;
	City: string;
	PostalCode: string;
	StateProvinceCode: string;
	CountryCode: string;
	AirportCode: string;
	TollFree: string;
	Phone: string;
	Fax: string;
	Mobile: string;
	EMail: string;
	Website: string;
	OpenHours: string;
	Comments: string;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}

export class ordNote {
	NoteID: number;
	NoteTypeID: any;
	OrderID: number;
	ItemID: number;
	ItemType: number;
	InvoiceID: number;
	FreightLegID: number;
	FreightPayableID: number;
	FreightFeeID: number;
	FreightHandlingID: number;
	TaskID: number;
	TrackingID: number;
	WarehouseAlertID: number;
	QuoteID: number;
	WONumber: number;
	Note: string;
	ClientVisible: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
	LinkType: string;
	LinkName: string;
	PersonName: string;
	UserInitials: string;
	IsRead: boolean;

	constructor() {
		this.NoteID = IDType.NewRec;
		this.NoteTypeID = 0;
		this.Note = '';
		this.ClientVisible = false;
		this.IsRead = false;
	}
}

export class ordDocument {
	DocumentID: number;
	DocumentTypeID: number;
	OrderID: number;
	InvoiceID: number;
	FreightLegID: number;
	FreightPayableID: number;
	FreightFeeID: number;
	FreightHandlingID: number;
	TaskID: number;
	TrackingID: number;
	WarehouseAlertID: number;
	QuoteID: number;
	WONumber: number;
	DocumentName: string;
	DocumentPath: string;
	DocumentDescription: string;
	ContentType: string;
	ClientVisible: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
	IsNew: boolean;

	constructor() {
		this.DocumentID = IDType.NewRec;
		this.DocumentTypeID = 0;
		this.ClientVisible = false;
		this.IsNew = true;
	}
}

export class ordDocumentEx extends ordDocument {
	PersonName: string;
	UserInitials: string;
	LinkType: string;
	LinkName: string;
}

export class ordTracking {
	TrackingID: number;
	OrderID: number;
	FreightLegID: number;
	Status: string;
	Terminal: string;
	DateTracked: Date;
	ContactPerson: string;
	TrackingTypeID: number;
	TrackingCategoryID: number;
	TrackingCategory: string;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
	FBMasterID: number;
	FBTrackID: number;
	PersonName: string;
	TrackingType: string;
	StatusColor: string;
}


export class ordActivity {
	ActivityID: number;
	ActivityTypeID: number;
	OrderID: number;
	FreightLegID: number;
	FreightPayableID: number;
	FreightFeeID: number;
	FreightHandlingID: number;
	TaskID: number;
	InvoiceID: number;
	TrackingID: number;
	WarehouseAlertID: number;
	ActivityDescription: string;
	CreatedBy: number;
	CreatedDate: Date;
	OldValue: string;
	NewValue: string;
	CreatedByPerson: string;
	ActivityType: string;
}

export class ordNmfc {
	NmfcID: number;
	OrderID: number;
	DataVersion: string;
	NmfcNumberID: number;
	Item: string;
	NmfcNumber: string;
	ItemClass: number;
	ReferencePage: number;
	ItemDescription: string;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}

export class ordPiece {
	PieceID: number;
	OrderID: number;
	DataVersion: string;
	WarehouseAlertID: number;
	FreightLegID: number;
	PalletNumber: number;
	BoxNumber: number;
	PieceTypeID: any;
	PieceDescription: string;
	PieceLength: number;
	PieceWidth: number;
	PieceHeight: number;
	PieceWeight: number;
	Quantity: number;
	ManualTotalWeight: number;
	DimWeight: number;
	ItemCount: number;
	PackedByUserID: number;
	PackTimeStart: Date;
	PackTimeFinish: Date;
	WarehouseCheck: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}

export class ordPieceEx extends ordPiece {
	TotalWeight: number;
	PieceTypeName: string;
}

export class ordSubjectTo {
	SubjectToID: number;
	OrderID: number;
	SubjectTo: string;
	CreatedBy: number;
	CreatedDate: Date;
}

export class ordTask {
	TaskID: number;
	OrderID: number;
	OrdLinkType: number;
	TaskTypeID: number;
	TaskText: string;
	WarehouseID: number;
	WarehouseAlertID: number;
	FreightLegID: number;
	FreightPayableID: number;
	WONumber: number;
	QuoteID: number;
	InvoiceID: number;
	WOQT: string;
	DateDue?: Date;
	DateCompleted: Date;
	CompletedBy: number;
	IsDefault: boolean;
	AssignedToUserID: number;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
	FBMasterID: number;
	FBTaskType: number;
	DateCompletedOffset: number;
	DateDueOffset: number;
	CompletedByPersonName: string;
	CompletedByInitials: string;
	AssigneePersonName: string;
	AssigneeInitials: string;
	CreatedByPersonName: string;
	CreatedByInitials: string;
	LinkType: string;
	LinkName: string;
	NoteCount: number;
	internalLink: string;
	internalParams: string;
	linkText: string;
	TaskStatus: string;
}


export class ordFreightCalculator extends utlByNames {
	FreightCalculatorID: number;
	OrderID: number;
	DataVersion: string;
	Revision: number;
	IsLocked: boolean;
	SpecifiedMarkupPercent: number;
	SpecifiedMarkupMargin: number;
	SpecifiedTotalMarkupMargin: number;
	SpecifiedShipmentRate: number;
	SpecifiedBillableRate: number;
	SpecifiedGrossMargin: number;
	CarrierCost: number;
	AccessorialCharges: number;
	CustomCharges: number;
	ShipmentCost: number;
	MarkupPercent: number;
	MarkupMargin: number;
	CustomMarkUpMargin: number;
	TotalMarkUpMargin: number;
	ShipmentRate: number;
	HandlingFees: number;
	ShipmentHandlingRate: number;
	BrokerageCost: number;
	BrokerageRate: number;
	InsuranceCost: number;
	InsuranceRate: number;
	OtherCost: number;
	OtherRate: number;
	BillRate: number;
	COGS: number;
	COGSMarkupPercent: number;
	GrossMargin: number;
	Commission: number;
	// TotalCost: number;
	NetMargin: number;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
	FBRateCalculatorID: number;

	constructor() {
		super();
		this.MarkupPercent = 0;
	}

	get name(): string {
		return 'Rev.' + this.Revision.toString();
	}
}

export class ordFreightCalculatorEx extends ordFreightCalculator {
	CreatedByName: string;
	CreatedByInitials: string;
	UpdatedByName: string;
	UpdatedByInitials: string;
	InvoiceNumber: string;
}

export class ordFreightFee {
	FreightFeeID: number;
	FreightPayableID: number;
	FeeTypeID: number;
	OnInvoiceID: number;
	InputCurrencyCode: string;
	InputExchangeRate: number;
	InputBaseAmount: number;
	InputAmount: number;
	MarkupAmount: number;
	MarkupPercent: number;
	CommissionPercent: number;
	FuelSurchargePercent: number;
	CustomBillableAmount: number;
	ShowNameOnInvoice: boolean;
	ShowBillableOnInvoice: boolean;
	InvoicedAmount: number;
	InvoicedApprovedBy: number;
	InvoicedApprovedDate: Date;
	BilledCurrencyCode: string;
	BilledExchangeRate: number;
	BilledBaseAmount: number;
	BilledAmount: number;
	BilledEnteredBy: number;
	BilledEnteredDate: Date;
	BilledReferenceNumber: string;
	FeeStatus: string;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
	FBSubPayablesID: number;
	FBSubChargesLegID: number;

	constructor(BaseCurrency: string) {
		this.InputCurrencyCode = BaseCurrency;
		this.InputExchangeRate = null;
		// this.InputBaseAmount = 0;
		// this.InputAmount = 0;
		// this.MarkupAmount = 0;
		// this.MarkupPercent = 0;
		// this.CommissionPercent = 0;
		// this.FuelSurchargePercent = 0;
		// this.BillableAmount = 0;
		// this.InvoicedAmount = 0;
		// this.InvoicedApprovedBy = 0;
		this.BilledExchangeRate = null;
		this.BilledCurrencyCode = BaseCurrency;
		// this.BilledBaseAmount = 0;
		// this.BilledAmount = 0;
		// this.BilledEnteredBy = 0;
	}
}

export class ordFreightFeeEx extends ordFreightFee {
	// Notes: ordNote[];
	NoteCount: number;
	FeeTypeName: string;
	FreightLegID: number;
	InvoicedApprovedByInitials: string;
	InvoicedApprovedByName: string;
	BilledEnteredByInitials: string;
	BilledEnteredByName: string;
	CreatedByInitials: string;
	CreatedByName: string;
	UpdatedByInitials: string;
	UpdatedByName: string;
}

export class ordFreightPayable {
	FreightPayableID: number;
	OrderID: number;
	DataVersion: string;
	FreightLegID: number;
	IsPayable: boolean;
	ChargeDate: Date;
	PayableTypeID: any;
	PayableEntityID: number;
	PayableEntityPlaceID: number;
	PayableAddressID: number;
	ApprovedBy: number;
	ApprovedDate: Date;
	ClearedBy: number;
	ClearedDate: Date;
	ReferenceNumber: string;
	PayableInvoiceNumber: string;
	ToChargeID: number;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
	FBSubPayablesID: number;
	FBSubChargesLegID: number;
}

export class ordFreightPayableEx extends ordFreightPayable {
	PayableTypeName: string;
	PayableAddress: ordAddress;
	PayableEntity: orgEntity;
	PayablePlace: orgPlace;
	BaseFee: ordFreightFeeEx;
	AdditionalFees: ordFreightFeeEx[];
	HoldNotes: ordNote[];
	TaskCount: number;
	TaskCompletedCount: number;
	AutoBilling: boolean;
	// include some accumulator slots here for doing calculator, etc.
	AdditionalFeeInputAmount: number;
	AdditionalFeeInvoicedAmount: number;
	AdditionalFeeBilledAmount: number;
	AdditionalFeeCustomAmount: number;
	AdditionalFeeCustomMargin: number;

	allChargesApproved(): boolean {
		let ret = true;
		ret = ret && this.BaseFee.InvoicedApprovedDate !== null;
		this.AdditionalFees.forEach(fee => {
			ret = ret && (fee.InvoicedApprovedDate !== null);
		});
		return ret;
	}

	allTasksComplete(): boolean {
		return (this.TaskCount === this.TaskCompletedCount);
	}
}

// export class ordFreightPayableSummary extends ordFreightPayable {
// 	PayableTypeName: string;
// 	PayableEntityName: string;
// 	BaseFee: number;
// 	AdditionalFees: number;
// 	FeeList: ordApproveBilling[];
// 	TaskCount: number;
// 	TaskCompletedCount: number;
// }

export class ordFreightLeg {
	FreightLegID: number;
	OrderID: number;
	DataVersion: string;
	FreightLegSequence: any;
	DestinationCity: string;
	DestinationStateProvinceCode: string;
	DestinationCountryCode: string;
	LegApproved: boolean;
	ScheduledPickUpDate: Date;
	ActualPickUpDate: Date;
	DestinationEntityID: number;
	DestinationPlaceID: number;
	DestinationAddressID: number;
	ScheduledDeliveryDate: Date;
	ActualDeliveryDate: Date;
	AlertEmailedDate: Date;
	TrackingStatusStep: number;
	PODName: string;
	PODDate: Date;
	ParsPaps: string;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
	FBSubPayablesID: number;
	DestinationCSC: string;
}

export class ordFreightLegEx extends ordFreightLeg {
	OriginCSC: string;
	FreightLegCount: number;
	OriginEntityID: number;
	OriginEntity: orgEntityVw;
	OriginPlaceID: number;
	OriginPlace: orgPlace;
	OriginAddressID: number;
	OriginAddress: ordAddress;
	DestinationAddress: ordAddress;
	DestinationEntity: orgEntityVw;
	DestinationPlace: orgPlace;
	Carrier: ordFreightPayableEx;
	Broker: ordFreightPayableEx;
	CrossDock: ordFreightPayableEx;
	HoldNotes: ordNote[];
	Pieces: ordPieceEx[];
	Tasks: ordTask[];
	Documents: ordDocument[];
	Tracking: ordTracking[];

	// include some accumulator slots here for doing calculator, etc.
	AdditionalFeeInputAmount: number;
	AdditionalFeeInvoicedAmount: number;
	AdditionalFeeBilledAmount: number;
	AdditionalFeeCustomAmount: number;
	AdditionalFeeCustomMargin: number;

	// constructor() {
	// 	super();
	// }
}

export class ordFreightHandling {
	FreightHandlingID: number;
	OrderID: number;
	DataVersion: string;
	ServiceCode: number;
	InputCurrencyCode: string;
	InputCharge: number;
	Charge: number;
	PerSkid: boolean;
	TotalCharge: number;
	Comment: string;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
	FBSubChargesWOID: number;
}

export class ordFreightHandlingEx extends ordFreightHandling {
	ServiceName: string;
}

export class ordOrderFreightParms {
	FreightCalculators: ordFreightCalculatorEx[];
	FreightPieces: ordPieceEx[];
	FreightHandling: ordFreightHandlingEx[];
	FreightLegs: ordFreightLegEx[];
	FreightInsurance: ordFreightPayableEx;
	FreightCommission: ordFreightPayableEx;
	FreightOther: ordFreightPayableEx;
	Nmfcs: ordNmfc[];
	Version: string;
	CalcVersion: string;
	CalculatorIsLocked: boolean;

}

export class ordOrderFreight {
	OrderID: number;
	QuoteID: number;
	WONumber: number;
	BillToAddressID: number;
	ForwarderUserID: number;
	SupporterUserID: number;
	TrackerUserID: number;
	BillingUserID: number;
	OriginCity: string;
	OriginStateProvinceCode: string;
	OriginPostalCode: string;
	OriginCountryCode: string;
	OriginCSC: string;
	DestinationCity: string;
	DestinationStateProvinceCode: string;
	DestinationPostalCode: string;
	DestinationCountryCode: string;
	DestinationCSC: string;
	InboundCarrierEntityID: number;
	OutboundCarrierEntityID: number;
	AirportCode: string;
	PODName: string;
	SupportBilling: boolean;
	PaymentTermsID: any;
	PaymentTypeID: any;
	WaybillNumber: string;
	PARSNumber: string;
	PONumber: string;
	QuoteStatusStep: number;
	ForwardingStatusStep: number;
	BilledStatusStep: number;
	InvoiceStatusStep: number;
	OKtoInvoice: boolean;
	QuotedDate: Date;
	ReleasedDate: Date;
	CompletedDate: Date;
	SetupDate: Date;
	ArchivedDate: Date;
	ShipmentTypeID: any;
	DescriptionOfShipment: string;
	Priority: string;
	InternationalTypeID: any;
	BillOfLading: string;
	DimFactor: DimFactors;
	SetupComplete: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}

export class ordOrderFreightEx extends ordOrderFreight {
	Order: ordOrderEx;
	BaseWarehouse: cfgWarehouse;
	BillToAddress: ordAddress;
	BillToContact: orgPerson;
	// Notes: ordNote[];
	SubjectTos: ordSubjectTo[];
	Invoices: chgInvoice[];
	NMFCs: ordNmfc[];
	QT: ordOrderFreightParms;
	WO: ordOrderFreightParms;
	WarehouseAlerts: ordWarehouseAlertEx[];
	ForwarderName: string;
	QuoteStatusColor: string;
	ForwardingStatusColor: string;
	BilledStatusColor: string;
	InvoiceStatusColor: string;
}

export class ordOrder {
	OrderID: number;
	WarehouseID: any;
	BaseCurrency: string;
	BillToEntityID: number;
	BillToPlaceID: number;
	BillToContactID: number;
	InputSource: string;
	ReferenceNumber: string;
	CrossReferenceID: number;
	ReferenceAdditional: string;
	PreferredShippingServiceLevel: string;
	PreferredShippingServiceCode: string;
	PreferredPaymentTypeID: any;
	PaymentAccountNumber: string;
	ShipperEntityID: number;
	ShipperPlaceID: number;
	ShipperAddressID: number;
	ConsigneeEntityID: number;
	ConsigneePlaceID: number;
	ConsigneeAddressID: number;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
	FBMasterID: number;
	P3PLOrderID: number;
	DimFactor: number;
}

export class ordOrderEx extends ordOrder {
	BillToEntity: orgEntity;
	ShipperEntity: orgEntityVw;
	ShipperPlace: orgPlace;
	ShipperAddress: ordAddress;

	ConsigneeEntity: orgEntityVw;
	ConsigneePlace: orgPlace;
	ConsigneeAddress: ordAddress;
}

export class ordWarehouseAlert {
	WarehouseAlertID: number;
	OrderID: number;
	WarehouseID: number;
	InboundFreightLegID: number;
	InboundWarehouseID: number;
	OutboundFreightLegID: number;
	OutboundWarehouseID: number;
	WarehouseAlertStatusStep: number;
	AlertType: string;
}

export class ordWarehouseAlertEx extends ordWarehouseAlert {
	InboundLeg: ordFreightLegEx;
	OutboundLeg: ordFreightLegEx;
	// Notes: ordNote[];
	Tasks: ordTask[];
	Pieces: ordPieceEx[];
}

// export class ordApproveBilling {
// 	FreightFeeID: number;
// 	FreightPayableID: number;
// 	FreightLegID; number;
// 	FeeTypeName: string;
// 	InputCurrencyCode: string;
// 	InputExchangeRate: number;
// 	InputBaseAmount: number;
// 	InputAmount: number;
// 	InvoicedAmount: number;
// 	InvoicedApprovedBy: number;
// 	InvoicedApprovedByName: string;
// 	InvoicedApprovedByInitials: string;
// 	InvoicedApprovedDate: Date;
// 	NoteCount: number;
// 	BilledCurrencyCode: string;
// 	BilledExchangeRate: number;
// 	BilledBaseAmount: number;
// 	BilledAmount: number;
// 	BilledEnteredBy: number;
// 	BilledEnteredByName: string;
// 	BilledEnteredByInitials: string;
// 	BilledEnteredDate: Date;
// 	BilledReferenceNumber: string;
// }

export class taskFilter {
	AssignedTo: number[];
	CompletedBy: number[];
	Completed: number;
	OverDue: number;

	/**
	 *
	 */
	constructor() {
		this.AssignedTo = [];
		this.CompletedBy = [];
		this.Completed = 0;
		this.OverDue = 0;
		console.log('construct taskFilter', this);
	}
}

export class ordQuoteStatusItem {
	QuoteStatusStep: number;
	QuoteList: number[];

	constructor(step: number) {
		this.QuoteStatusStep = step;
		this.QuoteList = [];
	}
}