import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { refDocument, refDocumentEx } from '../_models/REF.model';
import { OrdLinkType, RefLinkType } from '../_models/enums.model';

@Injectable({
  providedIn: 'root'
})
export class RefDocumentService {

  api = 'refDocument/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<refDocument[]>(this.apiURL + this.api);

  }

  get(id: number) {
    return this.http.get<refDocument>(this.apiURL + this.api + id);
  }

  create(item: refDocument) {
    return this.http.post(this.apiURL + this.api, item);
  }

  update(item: refDocument) {
    return this.http.put(this.apiURL + this.api + item.DocumentID, item);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getContent(id: number) {
    return this.http.get(this.apiURL + this.api + 'content/' + id, { responseType: 'blob' });
  }

  getForEmployee(employeeID: number) {
    return this.http.get<refDocumentEx[]>(this.apiURL + this.api + 'forEmployee/' + employeeID);
  }

  getForAsset(assetID: number) {
    return this.http.get<refDocumentEx[]>(this.apiURL + this.api + 'forAsset/' + assetID);
  }

  getForItem(LinkType: RefLinkType, itemID: number) {
    return this.http.get<refDocumentEx[]>(this.apiURL + this.api + 'forItem/' + itemID + '/' + LinkType);
  }

  upload(file: File, doc: refDocument) {

    const fData: FormData = new FormData();

    fData.append(file.name, file, file.name);
    fData.append('refDocument', JSON.stringify(doc));
    return this.http.post(this.apiURL + this.api, fData);
  }
}
