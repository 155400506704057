<div>
    <div class="p-grid" *ngFor="let item of items">
        <div class="p-col-4">
            {{ item.PrinterTypeDescription }}
        </div>
        <div class="p-col-8">
            <p-dropdown [options]="printersByType(item.PrinterTypeID)" [(ngModel)]="item.PrinterID" appendTo="body"
                [autoDisplayFirst]="false">
            </p-dropdown>
        </div>
    </div>
</div>
<div>
    <hr>
    <div style="float: left;">
        <p-button type="submit" (onClick)="doSave()" icon="fa fa-check-square-o" label="Save"></p-button>
    </div>
    <div [ngStyle]="{'float':'right'}">
        <p-button type="reset" (onClick)="doCancel()" icon="fa fa-ban" label="Cancel">
        </p-button>
    </div>
</div>