<div>
    <form id="UserSettingsForm" #UserSettingsForm="ngForm">
        <div class="p-grid">
            <div class="p-col-2">
                <label for="FirstName">First Name</label>
            </div>
            <div class="p-col-6">
                <input pInputText type="text" id="FirstName" name="FirstName" #FirstName="ngModel" autofocus
                    [(ngModel)]="editUser.FirstName" required />
            </div>
            <div class="p-col-4">
                <arc-show-errors [control]="FirstName"></arc-show-errors>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-2">
                <label for="LastName">Last Name</label>
            </div>
            <div class="p-col-6">
                <input pInputText type="text" id="LastName" name="LastName" #LastName="ngModel"
                    [(ngModel)]="editUser.LastName" required />
            </div>
            <div class="p-col-4">
                <arc-show-errors [control]="LastName"></arc-show-errors>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-2">
                <label for="UserLogin">Login</label>
            </div>
            <div class="p-col-6">
                <input pInputText type="email" id="UserLogin" name="UserLogin" #UserLogin="ngModel"
                    [(ngModel)]="editUser.Login" required pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,63}$" />
            </div>
            <div class="p-col-4">
                <arc-show-errors [control]="UserLogin">Must be a valid email address.</arc-show-errors>
            </div>
        </div>
        <div class="p-grid" *ngIf="isPropack()">
            <div class="p-col-2">
                <label for="DefaultWarehouseID">Default Warehouse</label>
            </div>
            <div class="p-col-6">
                <select class="arc-select" id="DefaultWarehouseID" name="DefaultWarehouseID"
                    #DefaultWarehouseID="ngModel" [(ngModel)]="editUser.DefaultWarehouseID" required>
                    <option *ngFor="let warehouse of warehouses" value="{{warehouse.value}}">{{warehouse.label}}
                    </option>
                </select>
            </div>
            <div class="p-col-4">
                <arc-show-errors [control]="DefaultWarehouseID"></arc-show-errors>
            </div>
        </div>
        <div class="p-grid" *ngIf="InSec && isPropack()">
            <div class="p-col-2">
                <label for="HomeWarehouseID">Home Warehouse</label>
            </div>
            <div class="p-col-6">
                <select class="arc-select" id="HomeWarehouseID" name="HomeWarehouseID"
                    #HomeWarehouseID="ngModel" [(ngModel)]="editUser.HomeWarehouseID" required>
                    <option *ngFor="let warehouse of warehouses" value="{{warehouse.value}}">{{warehouse.label}}
                    </option>
                </select>
            </div>
            <div class="p-col-4">
                <arc-show-errors [control]="HomeWarehouseID"></arc-show-errors>
            </div>
        </div>
        <div class="p-grid"  *ngIf="isPropack()">
            <div class="p-col-2">
                <label for="Initials">Initials</label>
            </div>
            <div class="p-col-6">
                <input pInputText type="text" id="Initials" name="Initials" #Initials="ngModel"
                    [(ngModel)]="editUser.Initials" required maxlength="6" />
            </div>
            <div class="p-col-4">
                <arc-show-errors [control]="Initials"></arc-show-errors>
            </div>
        </div>
        <div class="p-grid" *ngIf="isPropack()">
            <div class="p-col-2">
                <label for="Tag">Tag</label>
            </div>
            <div class="p-col-6">
                <input pInputText type="text" id="Tag" name="Tag" #Tag="ngModel" [(ngModel)]="editUser.Tag" required
                    maxlength="20" />
            </div>
            <div class="p-col-4">
                <arc-show-errors [control]="Tag"></arc-show-errors>
            </div>
        </div>
        <div class="p-grid" *ngIf="newItem === false">
            <div class="p-col-2">
                <label for="IsActive">Active</label>
            </div>
            <div class="p-col-6">
                <p-checkbox binary="true" id="IsActive" name="IsActive" #IsActive="ngModel"
                    [(ngModel)]="editUser.IsActive">
                </p-checkbox>
            </div>
            <div class="p-col-4">
                <arc-show-errors [control]="IsActive"></arc-show-errors>
            </div>
        </div>

        <div class="p-grid" *ngIf="editUser.UserID !== IDType.NewRec">
            <div class="p-col-2"></div>
            <div class="p-col-10">
                <p-button (onClick)="toggleNewPassword()"
                    [label]="editUser.NewPassword ? 'Cancel Password change' : 'Change Password'">
                </p-button>
            </div>
        </div>

        <div *ngIf="editUser.NewPassword || (editUser.UserID === IDType.NewRec)">


            <div class="p-grid" *ngIf="editUser.ByUser">
                <div class="p-col-2">
                    <label for="OldPassword">Old Password</label>
                </div>
                <div class="p-col-6">
                    <input pInputText type="password" id="OldPassword" name="OldPassword" #OldPassword="ngModel"
                        [(ngModel)]="editUser.OldPassword" required />
                </div>
                <div class="p-col-4">
                    <arc-show-errors [control]="OldPassword"></arc-show-errors>
                </div>
            </div>

            
            <div class="p-grid">
                <div class="p-col-2">
                    <label for="UserPassword">New Password</label>
                </div>
                <div class="p-col-6">
                    <input pInputText type="password" id="UserPassword" name="UserPassword" #UserPassword="ngModel"
                        [(ngModel)]="editUser.Password" required
                        pattern="^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,20}$" />
                                 <!-- suggested by Cilian:   ^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])(?=.*[^\w\s]?).{8,20}$ 
                                        However, the bigger problem is doing the match using something other than the 'pattern' setting (below) -->
                </div>
                <div class="p-col-4">
                    <arc-show-errors [control]="UserPassword">Not a valid password.</arc-show-errors>
                </div>
            </div>
            <div class="p-grid">
                <div class="p-col-2">
                    <label for="ConfirmPassword">Confirm Password</label>
                </div>
                <div class="p-col-6">
                    <input pInputText type="password" id="ConfirmPassword" name="ConfirmPassword"
                        #ConfirmPassword="ngModel" [(ngModel)]="editUser.ConfirmPassword" required
                        pattern="{{ UserPassword.value }}" />
                </div>
                <div class="p-col-4">
                    <arc-show-errors [control]="ConfirmPassword">Password and ConfirmPassword do not match.
                    </arc-show-errors>
                </div>
            </div>
        </div>
        <div class="p-grid" *ngIf="InSec">
            <div class="p-col-2">

            </div>
            <div class="p-col-10">
                <p-fieldset legend="Associated Entities" [toggleable]="false">
                    <div *ngFor="let e of editUser.Entities" class="p-grid">
                        <div class="p-col-6">{{ e.EntityName }}</div>
                        <div class="p-col-3">
                            <button pButton type="button" (click)="markAsDefault(e)"
                                [label]="e.IsDefaultForUser === true ? 'Default' : 'Set default'"
                                [disabled]="e.IsDefaultForUser ? true : false">
                            </button>
                        </div>
                        <div class="p-col-3">
                            <button pButton type="button" (click)="toggleActive(e)"
                                [label]="e.IsActive ? 'Active' : 'NOT Active'">
                            </button>
                            <!-- <p-toggleButton [(ngModel)]="e.IsActive" id="'e.EntityID'" name="'e.EntityID'" 
                                    onLabel="Active" offLabel="NOT Active">
                                </p-toggleButton> -->
                        </div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-9">
                            <p-autoComplete name="getEntity" id="getEntity" (completeMethod)="onEntitySearch($event)"
                                [suggestions]="entityList" [field]="'EntityName'" (onSelect)="onEntitySelect($event)"
                                appendTo="body">
                            </p-autoComplete>
                        </div>
                        <div class="p-col-3">
                            <button pButton [disabled]="!selectedEntity" label="Connect"
                                (click)="onEntityConnect()"></button>
                        </div>
                    </div>
                </p-fieldset>
            </div>
        </div>
    </form>
    <p-footer>
        <!-- footer when NOT in security page -->
        <div *ngIf="!InSec">
            <div style="float: left;">
                <p-button type="submit" (onClick)="doSave()" icon="fa fa-check-square-o" label="Save"
                    [disabled]="!UserSettingsForm.valid"></p-button>
            </div>
            <div [ngStyle]="{'float':'right'}">
                <p-button type="reset" (onClick)="doCancel()" icon="fa fa-ban" label="Cancel">
                </p-button>
            </div>
        </div>

        <!-- footer when IN security page -->
        <div *ngIf="InSec">
            <div style="text-align: center">
                <p-button type="submit" (onClick)="doSave()" icon="fa fa-check-square-o" label="Save"
                    [disabled]="!UserSettingsForm.valid"></p-button>
                <p-button type="reset" (onClick)="doCancel()" icon="fa fa-ban"
                    [label]="UserSettingsForm.pristine ? 'Exit' : 'Cancel'"> </p-button>
            </div>
            <hr>
            <arc-show-stamp [item]="editUser" *ngIf="editUser.UserID > 0"></arc-show-stamp>
        </div>
    </p-footer>
    <!-- <div>
        {{editUser | json }}
    </div> -->
</div>

<!-- 3/11/22 RJS This is never used here, so removing
    <p-dialog header="Select Person" [(visible)]="selectDialogVisible" [modal]="true" showEffect="fade" [modal]="true"
    [style]="{'width':'1200px'}" [closable]="false">
    <arc-person-search (searchComplete)="onPersonSearchComplete($event)"></arc-person-search>
</p-dialog> -->