import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Params } from '@angular/router';

// // import { server } from '../_models/index';
import { ordAddress } from '../_models/ORD.model';


@Injectable()
export class AddressService {


  affiliateType = '';
  // affiliateTypeSubscription: Subscription;

  api = 'ordAddress/';
  constructor(
    private http: HttpClient, @Inject('apiURL') private apiURL) {
  }

  getByID(addressID: number) {
    return this.http.get<ordAddress>(this.apiURL + this.api + addressID);
  }

  register(address: ordAddress) {
    return this.http.post<ordAddress>(this.apiURL + this.api + 'register', address);
  }
  saveTerminal(address: ordAddress, forEntityID: number) {
    return this.http.post<ordAddress>(this.apiURL + this.api + 'terminal/' + forEntityID, address);
  }

  generateFor(entityID: number, placeID: number, personID: number) {
    return this.http.get<ordAddress>(this.apiURL + this.api + 'generate/' + entityID + '/' + placeID + '/' + personID);
  }
}
