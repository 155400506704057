import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import { utlMediaType } from '../_models/UTL.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MediaTypesService {


  api = 'utlMediaType/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<utlMediaType[]>(this.apiURL + this.api);

  }
  create(mediaType: utlMediaType) {
    return this.http.post(this.apiURL + this.api, mediaType);
  }

  update(mediaType: utlMediaType) {
    return this.http
      .put(this.apiURL + this.api + mediaType.MediaTypeID, mediaType);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

}
