<div class="head">
    <h1>Admin Dashboard</h1>
</div>

<p-tabView (onChange)="handleTabChange($event)" [ngStyle]="{'margin':'10px'}">
    <p-tabPanel header="Versions">
        <arc-prodocs-dashboard-versions-list></arc-prodocs-dashboard-versions-list>
    </p-tabPanel>
    <p-tabPanel header="Stats">
        <div *ngIf="showStats" class="ProDocsDashboardContainer">
            <div class="Card" *ngIf="hasRight('actProDocsActor')">
                <assignment-completion-distribution [displayType]="displayTypes.Admin"></assignment-completion-distribution>
            </div>
            <div class="Card">
                <document-assignment-completion [displayType]="displayTypes.Admin"></document-assignment-completion>
            </div>
        </div>
    </p-tabPanel>
</p-tabView>