import { Component, Input, OnInit, OnDestroy, ViewChild, EventEmitter, Output } from '@angular/core';
import { timeActionService, timeEmployeeService, timeFunctionService, timeTimeSegmentService } from 'app/_data/time.service';
import { timeTimeSegment, timeFunctionSummary, timeFunctionDb, timeEmployee, timeTrackSegment, teamMember, timeTimeSegmentDb, timeAssignedFunction, timeEmployeeSnapshot, timeClockAction, timeIssueDb, timeDaySnapshot } from 'app/_models/TIME.model';
import { AlertService, AuthService } from 'app/_services';
import { SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { BehaviorSubject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { addDays } from 'date-fns';
import { ClientRule } from 'app/_models/enums.model';
import { NotifierService } from 'app/_sharedData/notifier.service';

@Component({
  selector: 'arc-time-summary',
  templateUrl: './time-summary.component.html',
  styleUrls: ['./time-summary.component.scss']
})

export class TimeSummaryComponent implements OnInit, OnDestroy {

  currentSeg: timeTimeSegment = null;
  editSeg: timeTimeSegmentDb = null;
  editMode = false;

  @ViewChild('tblDetail') tblDetail: Table;

  private _currentDate = new BehaviorSubject<Date>(null);
  @Input()
  set currentDate(value: Date) {
    this._currentDate.next(value);
  }
  get currentDate() {
    return this._currentDate.getValue();
  }

  private _currentEmployee = new BehaviorSubject<teamMember>(null);
  @Input()
  set currentEmployee(value: teamMember) {
    this._currentEmployee.next(value);
  }
  get currentEmployee() {
    return this._currentEmployee.getValue();
  }

  @Input() pageTitle: string;
  @Output() out = new EventEmitter<timeEmployeeSnapshot>();
  @Output() admOut = new EventEmitter<timeEmployeeSnapshot>();

  codeDisplayed = '';
  segDisplayed = -1;
  displayDate: Date;
  displayEmployee: string;
  displayMode: string;

  ClientRule: ClientRule;

  issueID = 0;

  showIssueDialog = false;
  showSegmentDialog = false;

  activity: timeTimeSegment[] = [];
  sumActivity: timeFunctionSummary[];

  assignedFuncs: SelectItem[] = [];
  assignedFuncsWithClient: SelectItem[] = [];

  queryData = false;
  queryFuncs = false;

  employee: timeEmployeeSnapshot;
  _subscription: Subscription;
  viewingTracks: boolean = false;

  loggingIssue: boolean = false;
  sentIssuesArray: timeIssueDb[];
  sentTimeSegmentID: number;
  changed: boolean = false;

  trackSegs: timeTimeSegment;
  trackFunctionName: string;
  userID: number;
  // ApprovalPayrollFlag: boolean = false;

  errorNoData: boolean = false;
  errorNoDataReturnString: string = '';

  minuteSum: number = 0;
  hours: number = 0;
  minutes: number = 0;
  displaySum: string = '';
  displayWeekSum: string = '';

  employeeScheduleStart: string = '';
  employeeScheduleStop: string = '';
  compareTodayDate: Date;
  isBusy: boolean = false;

  constructor(
    private segService: timeTimeSegmentService,
    private authService: AuthService,
    private actionService: timeActionService,
    private funcs: timeFunctionService,
    private employeeService: timeEmployeeService,
    private alert: AlertService,
    private notifier: NotifierService
  ) { }

  ngOnInit(): void {
    this.userID = this.authService.currentUser.UserID;

    this._subscription = this.employeeService.signedInEmployee$.subscribe(
      (value) => {
        this.employee = value;
        this.displayEmployee = this.employee.Employee.FirstName + ' ' + this.employee.Employee.LastName;
        this.displayWeekSum = this.convertMinToHours(this.employee.WeekMinutes);

        if (this.pageTitle === 'Dashboard')
          this.getData(this.currentDate, this.employee);
        else if (this.pageTitle === 'Clock Out')
          this.getDataForClockOut(this.employee);

      },
      (error) => this.alert.error(error)
    );

    this.showDetail();
  }

  ngOnDestroy(): void { }

  /**
   * Get time data for employee for specific date
   * @param forDate The day that they data was created on
   * @param forEmployee The employee that created the data
   */
  getData(forDate: Date, forEmployee: timeEmployeeSnapshot) {
    this.displayDate = this.currentDate;
    this.compareTodayDate = this.currentDate;
    this.errorNoData = false;
    this.actionService.getSnapshotofDayInOrder(forEmployee.Employee.EmployeeID, forDate)
      .pipe(finalize(() => this.queryData = false))
      .subscribe(
        (data: timeDaySnapshot) => {
          if (!data) {
            this.errorNoData = true;
            this.activity = [];
            this.errorNoDataReturnString = `No Data Found for `;
            this.displaySum = this.getSum(this.activity);
            // this.displaySum = this.activity.map(a => a.PaidDuration).reduce((prev, next) => prev + next);
            console.log(this.errorNoData);
          }
          this.activity = data.Activity.sort((a, b) => (a.Start < b.Start ? -1 : 1));
          console.log('got activity', this.activity);
          this.activity.forEach((a) => {
            a.IsMgrApproved = a.ManagerApprovedID != null;
            a.IsEmpApproved = a.EmployeeApprovedID != null;
            a.StartDate = new Date(a.Start);
            if (a.Stop !== null) {
              a.StopDate = new Date(a.Stop);
            }
            a.TrackSegments.forEach((t) => {
              t.IsApproved = t.ApprovedBy != null;
            });
            a.DisplayTracks = false;

            a.DisplayDuration = this.convertMinToHours(a.Duration);
            if (this.compareDate(a.CreatedDate, this.currentDate))
              a.FromDifferentDay = true;
            else if (this.compareDate(a.Start, a.Stop))
              a.FromDifferentDay = true;
            else a
            a.FromDifferentDay = false;

          });

          this.employeeScheduleStart = this.timeConvert(data.Schedule?.StartTime.substring(0, data.Schedule?.StartTime.length - 3));
          this.employeeScheduleStop = this.timeConvert(data.Schedule?.StopTime.substring(0, data.Schedule?.StopTime.length - 3));
          this.displayWeekSum = this.convertMinToHours(data.WeekMinutes);


          if (this.employeeScheduleStart === '12:00 AM' && this.employeeScheduleStop === '12:00 AM') {
            this.employeeScheduleStart = '';
            this.employeeScheduleStop = '';
          }

          this.sumActivity = this.transform(this.activity);
          this.displaySum = this.getSum(this.activity);

          // console.log('Activity for Employee Dashboard: ', this.activity);
        },
        err => {
          this.errorNoData = true;
          this.activity = [];
          this.errorNoDataReturnString = `No Data Found for `;
          this.displaySum = this.getSum(this.activity);
          console.log(this.errorNoData);
        });
  }

  /**
   * Gather clockout data for employee.
   * @param forEmployee The employee snapshot that will be used to get data.
   */
  getDataForClockOut(forEmployee: timeEmployeeSnapshot) {
    this.displayDate = this.currentDate;
    this.errorNoData = false;
    if (!this.queryData) {
      this.queryData = true;

      this.actionService.getActivityForClockOut(forEmployee.Employee.EmployeeID)
        .pipe(finalize(() => this.queryData = false))
        .subscribe(
          (data: timeTimeSegment[]) => {
            this.activity = data.sort((a, b) => (a.Start < b.Start ? -1 : 1));
            this.activity.forEach((a: timeTimeSegment) => {
              a.IsMgrApproved = a.ManagerApprovedID != null;
              a.IsEmpApproved = a.EmployeeApprovedID != null;
              a.StartDate = new Date(a.Start);
              if (a.Stop !== null) {
                a.StopDate = new Date(a.Stop);
              }
              a.TrackSegments.forEach((t) => {
                t.IsApproved = t.ApprovedBy != null;
              });
              a.DisplayTracks = false;
              a.DisplayDuration = this.convertMinToHours(a.PaidDuration);
              if (a.Stop !== null) {
                if (this.compareDate(a.CreatedDate, a.StopDate))
                  a.FromDifferentDay = true;
                else
                  a.FromDifferentDay = false;
              } else if (this.compareDate(a.CreatedDate, this.currentDate))
                a.FromDifferentDay = true;
              else
                a.FromDifferentDay = false;

              a.Disputed = a.Issues.some(i => {
                if (i.IsDisputeTime && !i.ResolvedBy) {
                  return true;
                } else {
                  return false;
                }
              });

            },
              (error) => this.alert.error(error)
            );

            this.sumActivity = this.transform(this.activity);
            this.displaySum = this.getSum(this.activity);
            console.log('Activity for Employee ClockOut: ', this.activity);

          }, (error) => {
            this.errorNoData = true;
            this.activity = [];
            this.errorNoDataReturnString = `No Data Found for ${this.displayDate}`;
            this.displaySum = this.getSum(this.activity);
            console.log(this.errorNoData);

          });
    }
  }

  /**
   * Convert Date string to formatted string
   * @param time Date in string format to be re-formatted
   * @returns A string formatted in 'hh:mm a'
   */
  timeConvert(time) {
    // Check correct time format and split into components

    if (time === undefined)
      return '';
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  nextDay() {
    this.getData(addDays(this.currentDate, 1), this.employee);
    this.currentDate = addDays(this.currentDate, 1);
    this.displayDate = this.currentDate;
  }

  prevDay() {
    this.getData(addDays(this.currentDate, -1), this.employee);
    this.currentDate = addDays(this.currentDate, -1);
    this.displayDate = this.currentDate;
  }

  jumpDay() {
    this.minutes = 0;
    this.hours = 0;
    this.getData(this.currentDate, this.employee);
  }

  /**
   * Get functions that employee is configured to use.
   * @param forEmployee Employee we are getting functions for.
   */
  getFunctions(forEmployee: teamMember) {
    if (!this.queryFuncs) {
      this.queryFuncs = true;
      this.funcs.getAssignedForEmployee(forEmployee.EmployeeID)
        .pipe(finalize(() => this.queryFuncs = false))
        .subscribe(
          (data: timeAssignedFunction[]) => {
            this.assignedFuncs = data
              .map((f) => ({ label: f.FunctionName, value: +f.FunctionID }));
            this.assignedFuncsWithClient = data
              .filter((f) => f.ClientRule >= ClientRule.AllowClient)
              .map((f) => ({ label: f.FunctionName, value: +f.FunctionID }));
            console.log('got functions', this.assignedFuncs);
          },
          (error) => this.alert.error(error)
        );
    }
  }

  /**
   * Turn number of seconds into Hours : Minutes
   * @param secs Time in seconds
   * @returns String formatted into Hours : Minutes
   */
  durationText(secs: number): string {
    return ('00' + Math.floor(secs / 60)).slice(-2) + ':' + ('00' + (secs % 60)).slice(-2);
  }

  showSummary() {
    this.displayMode = 'summary';
  }
  showDetail() {
    this.displayMode = 'detail';
  }

  /**
   * Take a list of time segements and transform them to be objects sorted by function
   * @param activity timeTimeSegment[ ] - List of time segments to transform 
   * @returns A timeFunctionSummary[ ] - Same time segments but sorted by function.
   */
  transform(activity: timeTimeSegment[]): timeFunctionSummary[] {
    let funcs: timeFunctionDb[] = [];
    activity.forEach((a) => {
      if (funcs.findIndex(f => f.FunctionID === a.FunctionID) < 0) {
        funcs.push(a.Function);
      }
    });
    // put together by creating timeFunctionSummary records
    let out: timeFunctionSummary[] = [];
    funcs.forEach((f) => {
      const segs = activity.filter(a => a.FunctionID === f.FunctionID);
      const tmp = {
        Function: f,
        TimeSegments: segs,
        Duration: segs.map(a => a.Duration).reduce((prev, next) => prev + next),
        DisplaySegs: false
      }
      out.push(tmp);
    });
    return out.sort((a, b) => a.Function.FunctionCode > b.Function.FunctionCode ? 1 : -1);
  }

  displayMe(code: string) {
    return this.codeDisplayed === code;
  }
  toggleLabel(code: string) {
    //return this.codeDisplayed == code ? 'Hide Detail' : 'Show Detail';
    return '';
  }

  toggleIcon(code: string) {
    return this.codeDisplayed == code ? 'pi pi-angle-double-right' : 'pi pi-angle-double-down';
  }

  toggleDisplay(code: string) {
    if (this.codeDisplayed === code) {
      this.codeDisplayed = '';
    } else {
      this.codeDisplayed = code;
    }
    console.log('displayed: ', this.codeDisplayed);
  }


  toggleSegLabel(id: number, cnt: number) {
    return cnt.toString();
    return (this.segDisplayed == id ? 'Hide ' + cnt + ' Track' : 'Show ' + cnt + ' Track') + (cnt > 1 ? 's' : '');
  }

  toggleSegIcon(id: number, cnt: number) {
    return this.segDisplayed === id ? 'pi pi-angle-double-right' : 'pi pi-angle-double-down';
  }

  toggleSegDisplay(id: number) {
    if (this.segDisplayed === id) {
      this.segDisplayed = -1;
    } else {
      this.segDisplayed = id;
    }
    console.log('Seg displayed: ', this.segDisplayed);
  }

  displaySeg(id: number) {
    return this.segDisplayed === id;
  }

  showSegIssues(seg: timeTimeSegment) {
    this.currentSeg = seg;
    this.showIssueDialog = true;
  }

  showTrkIssues(trk: timeTrackSegment) {
    console.log('showTrkIssues', trk);
  }

  closeIssueDialog() {
    this.showIssueDialog = false;
    this.getData(this.currentDate, this.employee);
  }

  showTracks(seg: timeTimeSegment) {
    if (seg.TrackSegments.length !== 0) {
      this.trackSegs = seg;
      this.trackFunctionName = seg.Function.FunctionName;
      this.viewingTracks = true;
    } else {
      this.alert.warn('No detail for this segment', 'tc', 5000); // , true);
    }
  }

  /**
   * Compare two dates to find out if one took place earlier than the other
   * @param date1 First Date to compare
   * @param date2 Second Date to compare to first
   * @returns Boolean on whether date1 took place sooner than date2
   */
  compareDate(date1, date2): boolean {
    // With Date object we can compare dates them using the >, <, <= or >=.
    // The ==, !=, ===, and !== operators require to use date.getTime(),
    // so we need to create a new instance of Date with 'new Date()'
    let d1 = new Date(date1);
    let d2 = new Date(date2);

    // Check if the dates are equal
    let same = d1.getDate() === d2.getDate();
    if (same) return false;

    // Check if the first is greater than second
    else if (d1 < d2) return true;

    else return false;
  }

  finish() {
    if (this.isBusy)
      return;

    if (this.employee.ClockStatus === 'ADM')
      var goToClockIn = true;

    this.isBusy = true;
    this.notifier.loadingOn();
    this.activity.forEach((value) => {
      if (!value.Disputed) {
        if (value.EmployeeApprovedDate === null) {
          value.EmployeeApprovedID = this.employee.Employee.EmployeeID;
        }
      } else {
        value.EmployeeApprovedDate = null;
        value.EmployeeApprovedID = null;
      }
    });
    console.log('now, activity = ', this.activity);
    let action: timeClockAction = {
      EmployeeID: this.employee.Employee.EmployeeID,
      TimeStamp: new Date().toLocaleTimeString(),
      Action: 'OUT',
      FunctionID: null,
      ClientID: null,
      IsBillable: null,
      Approval: this.activity,
      IsAutoGenerated: false,
      StopType: 0
    }
    console.log('ACTION APPROVAL: ', action.Approval)
    this.actionService.postAction(action)
      .pipe(finalize(() => {
        this.notifier.loadingOff();
        this.isBusy = false;
      }))
      .subscribe((data: timeEmployeeSnapshot) => {
        if (data.ActionSuccess === true) {
          // this.authService.employeeSignOut();
          console.log('DATA: ', data);
          this.employeeService.updateEmployeeSnapshot(data);
          if (goToClockIn)
            this.admOut.emit(data);
          else
            this.out.emit(data);

        } else { this.alert.error(data.ActionStatus); }
      }, err => { console.log(err); });
  }

  // SETTING ISSUES
  logIssue(item: timeTimeSegment) {
    this.sentIssuesArray = item.Issues;
    console.log('SENT ISSUES ARRAY BEFORE SENDING', this.sentIssuesArray);
    this.sentTimeSegmentID = item.TimeSegmentID;
    this.loggingIssue = true;
    // if (item.SentToPayrollDate || item.ManagerApprovedDate) {
    //   this.ApprovalPayrollFlag = true;
    // }
  }

  hasChanged(result: boolean) {
    this.loggingIssue = false
    if (result === true) {
      this.changed = true;
      if (this.pageTitle === 'Dashboard') {
        this.update();
      } else {
        console.log('%c BreakPoint', 'color: orange; font-size: 15px;')
        console.log(this.activity)
        this.activity.forEach(a => {
          a.Disputed = a.Issues.some(i => {
            if (i.IsDisputeTime && !i.ResolvedBy) {
              return true;
            } else {
              return false;
            }
          });
        });
      }
    }
  }

  getNumberOfIssues(segment: timeTimeSegment): number {
    return segment.Issues.length;
  }

  getIssueColor(arr: timeTimeSegment): string {
    let tempBool: boolean;
    tempBool = arr.Issues.every(val => val.ResolvedDate)
    if (tempBool === true && arr.Issues.length > 0) {
      return 'green'
    } else if (arr.Issues.some(val => val.ResolvedDate) && tempBool === false && arr.Issues.length > 0) {
      return 'orange'
    } else if (arr.Issues.length > 0 && tempBool === false) {
      return 'red';
    } else { return 'transparent' }
  }

  hasIssues(segment: timeTimeSegment): boolean {
    return segment.Issues !== null ? true : false;
  }

  getSum(hrs: timeTimeSegment[]): string {
    let minuteSum = 0;

    // 5/17/2022 RJS Removed the following as PaidDuration now accounts for NULL ServerStop
    
    // // Skip code if list is empty
    // if (hrs.length === 0)
    //   return this.convertMinToHours(0);

    // // Grab the open segment and add on the Duartion. 
    // // Paid Duration is zero on open segments
    // // (Don't get "paid" till you close segement)
    // if (hrs[hrs.length - 1].Stop === null) {

    //   if (hrs[hrs.length - 1].Function.IsBreak)
    //     minuteSum = hrs[hrs.length - 1].PaidDuration;
    //   else
    //     minuteSum = hrs[hrs.length - 1].Duration;
    // }

    // Sum up the rest of the paid time
    hrs.forEach(element => {
      minuteSum += element.PaidDuration;
    });

    // First convert that minute sum to ** hours ** minutes
    // Then Return that string.
    return this.convertMinToHours(minuteSum);
  }

  convertMinToHours(mins: number): string {
    let timeString = '';
    let minutes = 0;
    let hours = 0;

    let tempHours = mins / 60;
    hours = Math.floor(tempHours);
    minutes = (tempHours - hours) * 60;
    minutes = Math.round(minutes);

    // timeString = `${hours} hours ${minutes} min`
    if (hours === 0 && minutes === 0) {
      timeString = ' < 1 min';
    } else if (hours === 0) {
      timeString = minutes + ' min';
    } else if (hours === 1) {
      timeString = hours + ' hour ' + minutes + ' min';
    } else { timeString = hours + ' hours ' + minutes + ' min'; }

    return timeString;
  }

  update() {
    if (this.isBusy)
      return;
    this.isBusy = true;
    this.notifier.loadingOn();
    this.sentIssuesArray.forEach((val) => { val.TimeSegmentID = this.sentTimeSegmentID });
    console.log('SENT ISSUES ARRAY AFTER CHANGING', this.sentIssuesArray)
    let action: timeClockAction = {
      EmployeeID: this.employee.Employee.EmployeeID,
      Action: 'ISSUE',
      TimeSegmentID: this.sentTimeSegmentID,
      Issues: this.sentIssuesArray,
      TimeStamp: null,
      FunctionID: null,
      ClientID: null,
      IsBillable: null,
      Approval: null,
      IsAutoGenerated: false,
      StopType: 0
    }

    this.actionService.postAction(action)
      .pipe(finalize(() => {
        this.notifier.loadingOff();
        this.isBusy = false;
      }))
      .subscribe(
        (data: timeEmployeeSnapshot) => {
          if (data.ActionSuccess === true) {
            this.employee = data;
            this.displayEmployee = this.employee.Employee.FirstName + ' ' + this.employee.Employee.LastName;
            this.employeeService.updateEmployeeSnapshot(data);
            this.activity = this.employee.Activity;
            this.activity = this.activity.sort((a, b) => (a.Start < b.Start ? -1 : 1));
            this.activity.forEach((a) => {
              a.IsMgrApproved = a.ManagerApprovedID != null;
              a.IsEmpApproved = a.EmployeeApprovedID != null;
              a.StartDate = new Date(a.Start);
              if (a.Stop !== null) {
                a.StopDate = new Date(a.Stop);
              }
              a.TrackSegments.forEach((t) => {
                t.IsApproved = t.ApprovedBy != null;
              });
              a.DisplayTracks = false;

              a.Disputed = a.Issues.some(i => i.IsDisputeTime === true);
            });
            this.sumActivity = this.transform(this.activity);
            this.getSum(this.activity);
          }
        },
        (error) => this.alert.error(error)
      )
  }

}