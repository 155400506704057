import { DatePipe, Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssignmentCompletionDistributionComponent } from 'app/prodocs/dashboards/assignment-completion-distribution/assignment-completion-distribution.component';
import { docAssignmentService, docCategoryService, docDocumentFunctionService, docDocumentService, docSearchTextService } from 'app/_data/pro-docs.service';
import { timeFunctionService } from 'app/_data/time.service';
import { UpsertRet } from 'app/_models/API.model';
import { docCategoryDb, docDocumentDb, docSearchText } from 'app/_models/DOC.model';
import { DocItemType, DocVersionStatusTypes, IDType } from 'app/_models/enums.model';
import { docDocumentFunction, timeFunctionDb } from 'app/_models/TIME.model';
import { AlertService, AuthService } from 'app/_services';
import { ConfirmationService, MenuItem, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { DocumentVersionHistoryComponent } from '../../document-version-history/document-version-history.component';
import { AssignmentsComponent } from '../../assignments/assignments.component';

@Component({
  selector: 'arc-document-edit',
  templateUrl: './document-edit.component.html',
  styleUrls: ['./document-edit.component.scss']
})
export class DocumentEditComponent implements OnInit {

  @ViewChild('dt', { static: true }) mydt: Table;
  @ViewChild(DocumentVersionHistoryComponent) historyComponent: DocumentVersionHistoryComponent;
  @ViewChild(AssignmentsComponent) assignmentComponent: AssignmentsComponent;
  // For auto focus....
  @ViewChild('input', { static: false })
  set input(element: ElementRef<HTMLInputElement>) {
    if (element) {
      element.nativeElement.focus();
    }
  }

  // ** DATA ** \\
  selectedTab: number;
  createdDate: string;
  updatedDate: string;
  versionCount: number = 0;
  triggerCount: number = 0;
  assignmentCount: number = 0;
  noteCount: number = 0;
  historyCount: number = 0;
  document: docDocumentDb;
  documentID = 888;
  searchTxt: docSearchText = null;
  searchTextString: string = '';
  DocItemType: DocItemType;
  statusList: SelectItem[] = [];
  categories: docCategoryDb[] = [];
  selectedFunctionLink: timeFunctionDb = null;
  filteredFunctions: timeFunctionDb[] = [];
  functions: docDocumentFunction[] = [];

  reassignErrorTooltip: string = null;
  reassignMenuItems: MenuItem[];
  reassignDocumentID = 0;
  showReassignDialog = false;

  majorNum: number = 1;
  minorNum: number = 0;
  patch: number = 0;

  hasPublished = false;

  statusTypes = DocVersionStatusTypes;

  // ** TOGGLES ** \\
  addDocText: boolean = false;
  hasSearchText: boolean = false;
  editHotStart: boolean = false;

  //  documentHistory: docHistoryDb[] = [];

  editing: boolean = false;

  constructor(
    private docFunctionService: docDocumentFunctionService,
    private functionService: timeFunctionService,
    private searchableTxt: docSearchTextService,
    private categoryService: docCategoryService,
    private docService: docDocumentService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private alert: AlertService,
    private authService: AuthService,
    private location: Location,
    private confirmationService: ConfirmationService,
    private confirm: ConfirmationService,
    private assignmentService: docAssignmentService,
    private auth: AuthService
  ) {
    if (this.router.getCurrentNavigation()
      && this.router.getCurrentNavigation().extras
      && this.router.getCurrentNavigation().extras.state) {
      this.selectedTab = this.router.getCurrentNavigation().extras.state.selectedTab;
    }
  }

  ngOnInit(): void {
    this.documentID = this.route.snapshot.params['documentID'];

    this.categoryService.getAll()
      .subscribe((dta: docCategoryDb[]) =>
        this.categories = dta
      );

    this.getDocument();
    // this.getDocumentHistory();

    this.canReassign();
    this.getReassignMenu();
  }

  getDocument() {
    if (this.documentID == IDType.NewRec) {
      this.editing = true;
      this.document = this.initDocument();
      this.docService.getNextReferenceNumber()
        .subscribe(n => {
          this.document.ReferenceNumber = n;
        });
    } else {
      this.docService.getByID(this.documentID)
        .subscribe((data: docDocumentDb) => {
          this.document = data;
          this.getDisplayDates();
          this.getFunctionLinks();
          this.getSearchableText();
        });
    }
  }

  getReassignMenu() {
    this.reassignMenuItems = [];
    this.reassignMenuItems.push({ label: 'Auto Reassign', command: () => this.confirmAutoReassign(this.document) });
    this.reassignMenuItems.push({ label: 'Selective Reassign', command: () => this.goSelectiveReassign(this.document) });

  }

  getDisplayDates() {
    this.createdDate = this.datePipe.transform(this.document.CreatedDate, 'yyyy-MM-dd HH:mm');
    this.updatedDate = this.datePipe.transform(this.document.UpdatedDate, 'yyyy-MM-dd HH:mm');
  }

  initDocument(): docDocumentDb {

    const newDoc: docDocumentDb = new docDocumentDb();
    newDoc.Title = 'New Document';
    newDoc.CategoryID = 1;
    newDoc.Description = '';
    newDoc.CreatedDate = new Date(1899, 1, 1); // set this to before 1/1/1900 
    return newDoc;

  }

  saveDocument() {

    if (this.document.DocumentID > 0) {
      this.docService.update(this.document)
        .subscribe((ret: UpsertRet) => {
          this.document = ret.returnItem;
          this.getDisplayDates();
          this.alert.success('Document info updated.');
          this.historyComponent.getDocumentHistory();
          this.editing = false;
        },
          (error) => this.alert.error(error)
        );
    } else {
      // This is where hot start version number would go
      if (this.editHotStart) {
        this.document.SetVersion = this.majorNum.toString() + '.' + this.minorNum.toString() + '.' + this.patch.toString()
      }
      this.docService.create(this.document)
        .subscribe((ret: UpsertRet) => {
          this.document = ret.returnItem;
          this.editing = false;
          this.getDisplayDates();
          this.alert.success('Document created.');
          this.documentID = this.document.DocumentID;
          // rewrite the location to the a path to the new document so the back button will work correctly
          this.location.replaceState(this.location.path().replace('-2', this.documentID.toString()));
          this.getDocument();
          this.historyComponent.getDocumentHistory();

        },
          (error) => this.alert.error(error)
        );
    }
  }

  cancelEdit() {
    this.editing = false;
    this.addDocText = false;
    if (this.documentID < 0) {
      this.router.navigateByUrl('prodocs/documents')
    } else {
      this.getDocument();
    }
  }

  editDocumentDetails() {
    this.editing = true;
    setTimeout(() => {
      const ctl = document.getElementById('title');
      if (ctl) ctl.focus();
    }, 100);
  }

  editSearchText() {
    this.addDocText = true;
  }

  cancelSearchText() {
    this.addDocText = false;
    this.getSearchableText();
  }

  getSearchableText() {
    this.searchableTxt.getByID(this.documentID).subscribe({
      next: (data: docSearchText) => {
        if (data) {
          this.searchTxt = data;
          this.searchTextString = data.RawText;
          this.hasSearchText = true;
        } else {
          this.searchTxt = null;
        }
      }, error: () => {
        this.searchTxt = null;
      }
    });
  }

  saveSearchText() {
    if (this.hasSearchText) {
      this.searchTxt.RawText = this.searchTextString;
      this.searchableTxt.update(this.searchTxt).subscribe({
        next: () => {
          this.alert.success('Searchable Text Saved');
          this.addDocText = false;
        }, error: (err) => this.alert.error(err)
      });
    } else {
      let temp = new docSearchText();
      temp.RawText = this.searchTextString;
      temp.DocumentID = +this.documentID;
      this.searchableTxt.create(temp).subscribe({
        next: () => {
          this.alert.success('Saved Searchable Text');
          this.addDocText = false;
        }, error: (err) => this.alert.error(err)
      })
    }
  }

  mayEdit(): boolean {
    return this.authService.userHasRight("actProDocsAdmin");
  }

  toggleEditHotStart() {
    this.editHotStart = !this.editHotStart;
  }

  setVersionCount(ret: any) {
    this.versionCount = ret.count;
    this.hasPublished = ret.hasPublished;
  }

  setTriggerCount(count: number) {
    this.triggerCount = count;
  }

  setAssignmentCount(count: number) {
    this.assignmentCount = count;
    this.historyComponent.getDocumentHistory();
  }

  setNoteCount(count: number) {
    this.noteCount = count;
  }

  setHistoryCount(count: number) {
    this.historyCount = count;
  }

  changeCategory(selected: any) {
    // changed category, so reset the InLibrary setting on the document to the default for that category; it can be changed by the user.
    const selCat = this.categories.find((c) => c.CategoryID === selected.value);
    if (selCat) {
      this.document.InLibrary = selCat.InLibraryDefault;
    }
  }

  toDocumentList() {
    this.router.navigateByUrl('prodocs/documents');
  }

  getFunctionLinks() {
    this.docFunctionService.getForDocument(this.documentID).subscribe({
      next: (data: docDocumentFunction[]) => {
        this.functions = data;
      }
    })
  }

  filterFunctions(event) {
    const parm = (event.query.length > 0 ? event.query : '*');
    this.functionService.getBySearchText(parm).subscribe({
      next: (data: timeFunctionDb[]) => {
        this.filteredFunctions = data;
      }
    });
  }

  selectFunction(func: timeFunctionDb) {
    if ((this.functions.find(a => a.FunctionID === func.FunctionID) !== undefined)) {
      this.selectedFunctionLink = null;
      return;
    }

    let load = new docDocumentFunction();
    load.DocumentID = this.documentID;
    load.DocumentTitle = this.document.Title;
    load.FunctionID = func.FunctionID;
    load.FunctionCode = func.FunctionCode.toString();
    load.FunctionName = func.FunctionName;

    this.docFunctionService.create(load).subscribe({
      next: (data) => {
        this.getDocument();
        this.selectedFunctionLink = null;
      }
    })
  }

  deleteLink(link: docDocumentFunction) {
    this.docFunctionService.delete(link.DocumentFunctionID).subscribe({
      next: (data) => {
        this.getDocument();
      }
    })
  }

  confirmDelete() {
    // note that toggleArchive just toggles the IsArchived flag on the record
    this.confirmationService.confirm({
      header: '** Delete Document **',
      message: 'You wish to delete this document?<br/>Warning: Deleting is permanent!',
      accept: () => {
        this.docService.delete(this.documentID).subscribe({
          next: () => {
            this.alert.success('Document deleted!');
            this.toDocumentList();
          }, error: (err) => {
            this.alert.error('Error deleting document');
          }
        });
      },
      reject: (type) => {
        this.alert.info('Deletion cancelled');
      }
    });

  }

  canReassign(): boolean {
    this.reassignErrorTooltip = '';
    if (this.document && this.document.IsArchived) {
      this.reassignErrorTooltip = 'Document is archived';
      return false;
    } else if (!this.auth.userHasRight('actProDocsAdmin')) {
      this.reassignErrorTooltip = 'Access to this function is limited to admins';
      return false;
    } else if (!this.hasPublished) {
      this.reassignErrorTooltip = 'No published version(s) found';
      return false;
    }

    this.reassignErrorTooltip = '';
    return true;
  }


  confirmAutoReassign(item: docDocumentDb) {
    if (!this.canReassign()) {
      this.alert.error(this.reassignErrorTooltip);
      return;
    }
    this.confirm.confirm({
      message: 'Initiate Auto-reassign of ' + item.Title + '?',
      header: 'Confirm',
      icon: 'fa fa-question-circle',
      accept: () => { this.goAutoReassign(item.DocumentID); }
    });

  }

  goAutoReassign(documentID: number) {

    this.assignmentService.flagAssignmentOverride(documentID)
      .subscribe((ret) => {
        this.alert.info("Reassign initiated");
      },
      (error) => this.alert.error(error)
      );
  }

  goSelectiveReassign(item: docDocumentDb) {
    this.reassignDocumentID = item.DocumentID;
    this.showReassignDialog = true;
  }

  confirmReassign(item: docDocumentDb) {
    if (!this.canReassign()) {
      this.alert.error(this.reassignErrorTooltip);
      return;
    }
    this.confirm.confirm({
      message: 'Ready to Reassign ' + item.Title + '?',
      header: 'Confirm',
      icon: 'fa fa-question-circle',
      accept: () => { this.goReassign(item.DocumentID); }
    });
  }

  goReassign(did: number): void {
    this.assignmentService.flagAssignmentOverride(did)
      .subscribe((ret: boolean) => {
        this.alert.info('Reassignment ');
        this.getDocument();
      },
        (error) => this.alert.error(error)
      );
  }

  doUpdateAssignments(cnt:number) {
    this.assignmentComponent.reload();
  }

}

