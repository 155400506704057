import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { docActorService, docRoleService } from 'app/_data/pro-docs.service';
import { UpsertRet } from 'app/_models/API.model';
import { docActorDb } from 'app/_models/DOC.model';
import { DocActorRoleTypes, DocVersionStatusTypes, IDType } from 'app/_models/enums.model';
import { timeEmployee } from 'app/_models/TIME.model';
import { AlertService } from 'app/_services';
import { LookupsService } from 'app/_services/lookups.service';
import { SelectItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'arc-actor-edit',
  templateUrl: './actor-edit.component.html',
  styleUrls: ['./actor-edit.component.scss']
})
export class ActorEditComponent implements OnInit {

  private _actorID = new BehaviorSubject<number>(null);
  @Input() versionStatus: number = null;

  @Input()
  set actorID(value: number) {

    this._actorID.next(value);
  }
  get actorID() {
    return this._actorID.getValue();
  }

  @Output() editComplete = new EventEmitter<number>();

  actor: docActorDb;
  actorList: timeEmployee[] = [];
  roleList: SelectItem[] = [];

  newItem = false;
  roleTypes = DocActorRoleTypes;
  statusTypes = DocVersionStatusTypes;

  @Input() versionID = 0;

  constructor(
    private actorService: docActorService,
    private alert: AlertService,
    private lookups: LookupsService,
    private roles: docRoleService
  ) { }

  ngOnInit(): void {
    this._actorID.subscribe(
      () => {
        this.loadActor();
      },
      (error) => this.alert.error(error)
    );

    this.getActors();
    this.getRoles();
  }

  loadActor() {
    if (this.actorID && this.actorID > 0) {
      console.log('getting actorid', this.actorID)
      this.actorService.getByID(this.actorID)
        .subscribe((a: docActorDb) => {
          this.actor = a;
          if (this.actor.ActionDueDate) {
            this.actor.ActionDueDate = new Date(this.actor.ActionDueDate);
          }
          console.log('got actor', this.actor);
          this.newItem = false;
        },
          (error) => this.alert.error(error)
        );
    } else if (this.actorID < 0) {
      this.actor = {
        ActorID: IDType.NewRec,
        PersonID: -1,
        VersionID: +this.versionID,
        RoleID: 1,
        Notes: '',
        ActionAssignedDate: new Date(),
        ActionAssignedBy: null,
        ActionDueDate: null,
        ActionCompletedDate: null,
        ActionCompletedBy: null,
        CreatedBy: 0,
        CreatedDate: new Date(1899, 1, 1),
        UpdatedBy: 0,
        UpdatedDate: new Date(1899, 1, 1),
      }
      this.newItem = true;
    }
  }

  getActors(): void {
    this.lookups.proDocActors
      .subscribe((a: timeEmployee[]) => {
        this.actorList = a;
        console.log('got actors List', this.actorList);
      });
  }

  getRoles(): void {
    this.roles.getAll().subscribe(data => {
      this.roleList = data
        .map(x => ({ label: x.RoleName, value: +x.RoleID }))
        .sort((a, b) => a.label > b.label ? 1 : -1);

      if (this.versionStatus === this.statusTypes.Published) {
        this.roleList = this.roleList.filter(a => a.value === this.roleTypes.QA_Reviewer);
      }
      console.log('got roles', this.roleList);
    },
      (error) => this.alert.error(error)
    );
  }

  save() {
    if (this.newItem) {
      this.actor.ActorID = IDType.NewRec;
      this.actorService.create(this.actor)
        .subscribe((ret: UpsertRet) => {
          this.actor = ret.returnItem;
          this.alert.success("Saved");
          this.editComplete.emit(this.actor.ActorID);
        },
          (error) => this.alert.error(error)
        );
    } else {
      this.actorService.update(this.actor)
        .subscribe((ret: UpsertRet) => {
          this.actor = ret.returnItem;
          this.alert.success("Updated");
          this.editComplete.emit(this.actor.ActorID);
        },
          (error) => this.alert.error(error)
        );
    }
  }

  toggleDialog() {
    this.editComplete.emit(-1);
  }
}
