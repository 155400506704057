import { Injectable, Inject } from '@angular/core';
import { orgCarrierFuelSurcharge } from '../_models/ORG.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FuelSurchargesService {


  api = 'orgCarrierFuelSurcharge/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<orgCarrierFuelSurcharge[]>(this.apiURL + this.api);

  }
  create(carrierFuelSurcharge: orgCarrierFuelSurcharge) {
    return this.http.post(this.apiURL + this.api, carrierFuelSurcharge);
  }

  update(carrierFuelSurcharge: orgCarrierFuelSurcharge) {
    return this.http
      .put(this.apiURL + this.api + carrierFuelSurcharge.CarrierFuelSurchargeID, carrierFuelSurcharge);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

}
