<div>
    <form #ActorEditForm="ngForm">
        <div *ngIf="actor">
            <!-- Form Items -->
            <div class="p-grid p-field">
                <div class="p-col-2">
                    <label for="Person">Name</label>
                </div>
                <div class="p-col-6">
                    <p-dropdown [options]="actorList" [(ngModel)]="actor.PersonID" placeholder="Select Person"
                        optionLabel="label" optionValue="value" id="Person" name="Person" #Person="ngModel">
                    </p-dropdown>
                </div>
                <div class="p-col-4">
                    <arc-show-errors [control]="Person"></arc-show-errors>
                </div>
            </div>
            <div class="p-grid p-field">
                <div class="p-col-2">
                    <label for="Role">Role</label>
                </div>
                <div class="p-col-6">
                    <p-dropdown [options]="roleList" [(ngModel)]="actor.RoleID" placeholder="Select Role"
                        optionLabel="label" optionValue="value" id="Role" name="Role" #Role="ngModel"></p-dropdown>
                </div>
                <div class="p-col-4">
                    <arc-show-errors [control]="Role"></arc-show-errors>
                </div>
            </div>

            <div class="p-grid p-field">
                <div class="p-col-2">
                    <label for="ActionDueDate">Due</label>
                </div>
                <div class="p-col-6">
                    <p-calendar [(ngModel)]="actor.ActionDueDate" appendTo="body" id="ActionDueDate"
                        name="ActionDueDate" #ActionDueDate="ngModel" showButtonBar="true"></p-calendar>
                </div>
                <div class="p-col-4">
                    <arc-show-errors [control]="ActionDueDate"></arc-show-errors>
                </div>
            </div>

            <div class="p-grid p-field">
                <div class="p-col-2">
                    <label for="Note">Note</label>
                </div>
                <div class="p-col-6">
                    <textarea rows="4" cols="50" pInputTextarea [(ngModel)]="actor.Notes" id="Note" name="Note"
                        #Note="ngModel"></textarea>
                </div>
                <div class="p-col-4">
                    <arc-show-errors [control]="Note"></arc-show-errors>
                </div>
            </div>

        </div>
    </form>
    <!-- Form Footer -->
    <hr>
    <div>
        <arc-show-stamp [item]="actor" *ngIf="newItem === false"></arc-show-stamp>
        <div style="float:right">
            <p-button type="submit" (onClick)="save()" icon="fa fa-check-square-o" label="Save"
                [disabled]="!ActorEditForm.valid"></p-button>
            <p-button type="reset" (onClick)="toggleDialog()" icon="fa fa-ban" label="Cancel" tabindex="1">
            </p-button>
        </div>
    </div>

</div>