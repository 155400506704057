<p-table #dt [value]="documentHistory" sortMode="single" (onFilter)="doFilter($event)" (onSort)="onSort($event)"
    (onPage)="onPage($event)" [paginator]="true" [alwaysShowPaginator]="false" [rows]="30">
    <!--Table Header Template-->
    <ng-template pTemplate="header">
        <!--Column Headers-->
        <tr>
            <th></th>
            <th pSortableColumn="VersionNumber" [ngStyle]="{ 'width': '10%', 'text-align': 'left' }" *ngIf="!versionID">
                Version#
                <span>
                    <p-sortIcon field="VersionNumber"></p-sortIcon>
                </span>
            </th>
            <th pSortableColumn="PersonName" [ngStyle]="{ 'width': '15%', 'text-align': 'left' }">
                Person
                <span>
                    <p-sortIcon field="PersonName"></p-sortIcon>
                </span>
            </th>
            <th pSortableColumn="ActionDate" [ngStyle]="{ 'width': '15%', 'text-align': 'left' }">
                Date
                <span>
                    <p-sortIcon field="ActionDate"></p-sortIcon>
                </span>
            </th>
            <th pSortableColumn="Action" [ngStyle]="{ 'width': '15%', 'text-align': 'left' }">
                Action
                <span>
                    <p-sortIcon field="Action"></p-sortIcon>
                </span>
            </th>
            <th [ngStyle]="{  'text-align': 'left' }">
                Note
            </th>
            <th style="width:3em"></th>
        </tr>

        <!--Column Filters-->
        <tr>
            <th style="width:3em; text-align: center;" class="col-icon-delete">
                <div>
                    <i class="fa fa-ban" (click)="clearLocalFilter(dt)"></i>
                </div>
            </th>
            <th *ngIf="!versionID">
                <input pInputText status="text" id="VersionNumber" [(ngModel)]="localFilter.VersionNumber"
                    (input)="dt.filter(localFilter.VersionNumber, 'VersionNumber', 'contains')">
            </th>
            <th>
                <input pInputText status="text" id="PersonName" [(ngModel)]="localFilter.PersonName"
                    (input)="dt.filter(localFilter.PersonName, 'PersonName', 'contains')">
            </th>
            <th>
                <input pInputText status="text" id="ActionDate" [(ngModel)]="localFilter.ActionDate"
                    (input)="dt.filter(localFilter.ActionDate, 'ActionDate', 'contains')">
            </th>
            <th>
                <input pInputText status="text" id="Action" [(ngModel)]="localFilter.Action"
                    (input)="dt.filter(localFilter.Action, 'Action', 'contains')">
            </th>
            <th>
                <input pInputText status="text" id="Note" [(ngModel)]="localFilter.Note"
                    (input)="dt.filter(localFilter.Note, 'Note', 'contains')">
            </th>
            <th></th>
        </tr>
    </ng-template>

    <!--Table Body Template-->
    <ng-template pTemplate="body" let-rowData let-item>
        <!--Column Rows-->
        <tr [pSelectableRow]="rowData" style="height:30px;">
            <td></td>
            <td *ngIf="!versionID">
                {{ item.VersionNumber }}
            </td>
            <td>
                {{ item.PersonName }}
            </td>
            <td>
                {{ item.ActionDate | date: 'MMM d, YYYY hh:mm a'}}
            </td>
            <td>
                {{ item.Action }}
            </td>
            <td>
                {{ item.Note }}
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="summary">
        <p>Count: {{dt.totalRecords}} </p>
    </ng-template>
</p-table>