import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { timeEmployeeService } from 'app/_data/time.service';
import { teamMember } from 'app/_models/TIME.model';
import { AlertService, AuthService } from 'app/_services';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'arc-team-selector',
  templateUrl: './team-selector.component.html',
  styleUrls: ['./team-selector.component.scss']
})
export class TeamSelectorComponent implements OnInit {

  @Input() forManagerID: number;
  @Input() showFullTeam: boolean = false;

  @Output() onSelectManager = new EventEmitter<number>(true);
  @Output() onToggleTeam = new EventEmitter<boolean>(true);

  colleagues: SelectItem[] = [];

  constructor(
    private employeeService: timeEmployeeService,
    private auth: AuthService,
    private alert: AlertService
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.employeeService.getColleagues(this.forManagerID)
      .subscribe(
        (data: teamMember[]) => {
          this.colleagues = this.buildHierarchicalList(this.forManagerID, data);
          this.colleagues.forEach((c) => c.value = +c.value)
        },
        (error) => this.alert.error(error)
      );
  }

  buildHierarchicalList(topID: number, data: teamMember[]): SelectItem[] {
    const mgrs = data.filter((e) => (e.TeamSize > 0 || Math.abs(e.EmployeeID) === topID));
    // const boss = mgrs.find((e) => Math.abs(e.EmployeeID) === topID);
    const boss = mgrs.find((e) => e.EmployeeID === topID);
    const bossID = boss?.ReportsToEmployeeID;

    // build the hierarchical structure
    const hier: teamMember[] = [];
    hier.push(... this.hierAddLevel(bossID, 0, mgrs));

    // now build the flattened list
    const ret: SelectItem[] = [];
    ret.push(... this.listAddLevel(hier));


    return ret;
  }

  hierAddLevel(topID: number, level: number, team: teamMember[]): teamMember[] {
    const list = team.filter((m) => +m.ReportsToEmployeeID === +topID && +m.EmployeeID !== +topID);
    if (list.length === 0) {
      return list;
    }

    list.sort((a, b) => (a.EmployeeName < b.EmployeeName ? -1 : 1));

    const arrows = '-- -- -- -- --';
    list.forEach((m) => {
      m.EmployeeName = arrows.substr(0, level * 3) + m.EmployeeName;
      m.Subs = [];
      const subs = this.hierAddLevel(m.EmployeeID, level + 1, team);
      if (subs) {
        m.Subs.push(...subs);
      }
    });
    return list;
  }

  listAddLevel(hier: teamMember[]): SelectItem[] {
    const ret: SelectItem[] = [];

    hier.forEach((m) => {
      ret.push({ label: m.EmployeeName, value: m.EmployeeID.toString() });
      if (m.Subs.length > 0) {
        ret.push(...this.listAddLevel(m.Subs));
      }
    });
    return ret;
  }

  goHome() {
    console.log('%c BEFORE', 'color:coral', this.forManagerID)
    this.forManagerID = +this.auth.currentUser.UserID;
    console.log('%c AFTER', 'color:coral', this.forManagerID)
    this.getData();
    this.selectManager();
  }

  selectManager() {
    this.onSelectManager.emit(this.forManagerID);
  }

  toggleTeam() {
    this.onToggleTeam.emit(this.showFullTeam);
  }
}
