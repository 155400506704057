import { IDType } from './enums.model';

/** A class which represents the doc.Actors table. */
export class docActorDb {
    ActorID: number;
    PersonID: number;
    PersonName?: number;
    VersionID: number;
    VersionNumber?: string;
    RoleID: number;
    RoleName?: string;
    Notes: string;
    ActionAssignedDate?: Date;
    ActionAssignedBy?: number;
    ActionDueDate?: Date;
    ActionCompletedDate?: Date;
    ActionCompletedBy?: number;
    CreatedBy: number;
    CreatedDate: Date;
    UpdatedBy: number;
    UpdatedDate: Date;

    //UI USE ONLY
    CanComplete?: boolean;
}
/** A class which represents the doc.Assignments table. */
export class docAssignmentDb {
    AssignmentID: number;
    FromTriggerID: number;
    FromTriggerName: string;
    PersonID: number;
    PersonName: string;
    DocumentID: number;
    DocumentTitle: string;
    VersionID?: number;
    AssignedBy: number;
    AssignedByName: string;
    AssignedDate: Date;
    AssignmentReason: string;
    SoftDueDate?: Date;
    HardDueDate?: Date;
    CompletedDate?: Date;
    CancelledDate?: Date;
    CancelledBy?: number;
    ReminderDays?: number;
    ReassignOverride: boolean;
    CreatedBy: number;
    CreatedDate: Date;
    UpdatedBy: number;
    UpdatedDate: Date;
}
/** A class which represents the doc.Categories table. */
export class docCategoryDb {
    CategoryID: number;
    CategoryName: string;
    InLibraryDefault: boolean;
    IsActive: boolean;
    CreatedBy: number;
    CreatedDate: Date;
    UpdatedBy: number;
    UpdatedDate: Date;
}
/** A class which represents the doc.Languages table. */
export class docLanguageDb {
    LanguageCode: string;
    LanguageName: string;
}

/** A class which represents the doc.Documents table. */
export class docDocumentDb {
    DocumentID: number;
    CategoryID?: number;
    CategoryName?: string;
    Title: string;
    Description: string;
    InLibrary: boolean;
    IsDeleted: boolean;
    IsArchived: boolean;
    CreatedBy: number;
    CreatedByName: string;
    CreatedDate: Date;
    UpdatedBy: number;
    UpdatedByName: string;
    UpdatedDate: Date;
    SetVersion?: string;
    ReferenceNumber: number;
}

export class docDocumentEx extends docDocumentDb {
    VersionCount: number;
    AssignmentCount: number;
    TotalAssignmentCount: number;
    TriggerCount: number;
    NotesCount: number;
    PublishedVersionCount: number;
    PublishedFileCount: number;
    SourceFileCount: number;
    HasSearchText: boolean;
}
/** A class which represents the doc.Versions table. */
export class docVersionDb {
    VersionID: number;
    DocumentID: number;
    DocumentTitle: string;
    MajorNum: number;
    MinorNum: number;
    PatchNum: number;
    VersionNumber: string;
    Description: string;
    ChangeNotes: string;
    LanguageCode: string;
    OwnerID: number;
    OwnerName: string;
    StatusID: number;
    StatusName: string;
    IsLive: boolean;
    PublicationDate?: Date;
    ExpirationDate?: Date;
    SourceFile: string;
    PublishedFile: string;
    LockedDate: Date;
    CreatedBy: number;
    CreatedDate: Date;
    UpdatedBy: number;
    UpdatedDate: Date;

    // UI USE ONLY
    LanguageName?: string;
    DaysTilExpiration?: number;
}

export class docVersionDbEx extends docVersionDb {
    CategoryID: number;
    CategoryName: string;
}

export class docVersion extends docVersionDb {
    QuizID: number;
    IsActive: boolean;
    QuizQuestionCount: number;
    QuizLastEditDate: Date;
    QuizLastEditBy: number;
    QuizLastEditByName: string;
}

export class docUploadedDoc {
    VersionID: number;
    DocumentID: number;
    FileName: string;
    FileType: string;
    FileOther: string;
}

/** A class which represents the doc.Files table. */
export class docFileDb {
    FileID: number;
    VersionID: number;
    FileName: string;
    FileType: string;
    LanguageCode: string;
    VersionNumber: string;
    LockedBy?: number;
    LockedDate?: Date;
    CreatedBy: number;
    CreatedDate: Date;
    UpdatedBy: number;
    UpdatedDate: Date;
}
/** A class which represents the doc.History table. */
export class docHistoryDb {
    HistoryID: number;
    DocumentID: number;
    VersionID?: number;
    VersionNumber: string;
    PersonID: number;
    Action: string;
    ActionDate: Date;
    Note: string;
}
/** A class which represents the doc.Notes table. */
export class docNoteDb {
    NoteID: number;
    DocumentID: number;
    VersionID?: number;
    PersonID: number;
    Note: string;
    CreatedBy: number;
    CreatedDate: Date;
    UpdatedBy: number;
    UpdatedDate: Date;
}

/** A class which represents the doc.NoteThreadRecipients table. */
export class docNoteThreadRecipientDb {
    NoteThreadRecipientID: number;
    NoteThreadID: number;
    RecipientID: number;
}
/** A class which represents the doc.NoteThreads table. */
export class docNoteThreadDb {
    NoteThreadID: number;
    NoteTypeID: number;
    ItemID: number;
}
/** A class which represents the doc.QuizInstances table. */
export class docQuizInstanceDb {
    InstanceID: number;
    QuizID: number;
    AssignmentID: number;
    PersonID: number;
    WarehouseID: number;
    InstanceStartDate?: Date;
    InstanceEndDate?: Date;
    ResultPercent?: number;
    Detail: string;
}
/** A class which represents the doc.QuizQuestions table. */
export class docQuizQuestionDb {
    QuestionID: number;
    QuizID: number;
    Sequence: number;
    Text: string;
    Notes: string;
    CreatedBy: number;
    CreatedDate: Date;
    UpdatedBy: number;
    UpdatedDate: Date;
}
/** A class which represents the doc.QuizResponses table. */
export class docQuizResponseDb {
    ResponseID: number;
    QuestionID: number;
    Sequence: number;
    Text: string;
    IsCorrect: boolean;
    IsSelected?: boolean;
    Notes: string;
    CreatedBy: number;
    CreatedDate: Date;
    UpdatedBy: number;
    UpdatedDate: Date;
}
/** A class which represents the doc.Quizzes table. */
export class docQuizDb {
    QuizID: number;
    VersionID: number;
    Description: string;
    RequiredScore: number;
    IsActive: boolean;
    CreatedBy: number;
    CreatedDate: Date;
    UpdatedBy: number;
    UpdatedDate: Date;
}
/** A class which represents the doc.Roles table. */
export class docRoleDb {
    RoleID: number;
    RoleName: string;
    IsActive: boolean;
    CreatedBy: number;
    CreatedDate: Date;
    UpdatedBy: number;
    UpdatedDate: Date;
}
/** A class which represents the doc.Statuses table. */
export class docStatusDb {
    StatusID: number;
    StatusName: string;
    IsActive: boolean;
    CreatedBy: number;
    CreatedDate: Date;
    UpdatedBy: number;
    UpdatedDate: Date;
}
/** A class which represents the doc.Triggers table. */
export class docTriggerDb {
    TriggerID: number;
    TriggerTypeID: number;
    TriggerTypeName?: string;
    TriggerName: string;
    DocumentID: number;
    DocumentTitle: string;
    UsageMinutes?: number;
    UsageWindow?: number;
    AssignFrequencyDays?: number;
    SoftDueInDays?: number;
    HardDueInDays?: number;
    IsActive: boolean;
    CreatedBy: number;
    CreatedByName: string;
    CreatedDate: Date;
    UpdatedBy: number;
    UpdatedByName: string;
    UpdatedDate: Date;
}

export class docTriggerEx extends docTriggerDb {
    Functions?: number[];
    Clients?: number[];
    Persons?: number[];
    Departments?: number[];
    Warehouses?: number[];
}

export class docQuizEx extends docQuizDb {
    Questions: docQuizQuestionEx[];
}
export class docQuizQuestionEx extends docQuizQuestionDb {
    Responses: docQuizResponseDb[];

    // UI USE ONLY
    Correct?: boolean;
}
export class docAssignmentEx extends docAssignmentDb {
    PastReads: number;
    Warehouse: string;
    HasQuiz: boolean;
    // UI USE ONLY
    IsSelected?: boolean;
    FirstDue?: number;
    DaysTillSoft?: number;
    DaysTillHard?: number;
}

export class docQuizInstanceEx extends docQuizInstanceDb {
    Questions: docQuizQuestionEx[];

    // UI USE ONLY
    DisplayPercentage?: number;
}
export class docDocumentItem {
    DocumentID: number;
    CategoryID: number;
    CategoryName: string;
    Title: string;
    Description: string;
    Status: string;
    CreatedBy: number;
    CreatedByName: string;
    CreatedDate: Date;
    UpdatedBy: number;
    UpdatedByName: string;
    UpdatedDate: Date;
}

export class docSearchText {
    DocumentID: number;
    RawText: string;
    CreatedBy: number;
    CreatedByName: string;
    CreatedDate: Date;
    UpdatedBy: number;
    UpdatedByName: string;
    UpdatedDate: Date;
}

// ------------------------------
// DASHBOARD MODELS
// ------------------------------
export class docDashboardActionsItem {
    PersonID: number;
    PersonName: string;
    DocumentID: number;
    Title: string;
    Description: string;
    VersionID: number;
    VersionNumber: string;
    LanguageCode: string;
    RoleID: number;
    RoleName: string;
    CategoryID: number;
    CategoryName: string;
    ActionDueDate?: Date;
    ActionCompletedDate?: Date;
}

export class docDashboardTeamAssignmentsEmployee {
    EmployeeID: number;
    PersonName: string;
    PendingAssignmentsCount: number;
    FirstSoftDue: Date;
    FirstHardDue: Date;
    Warehouse: string;
    FirstSoftCount?: number;
    FirstHardCount?: number;
}
export class docDashboardTeamAssignmentsDocuments {
    DocumentID: number;
    DocumentTitle: string;
    Employees: docAssignmentEx[];
}

export class docAssignmentCompletionDistribution {
    PastDueCount: number;
    DoneRightCount: number;
    Total: number;
}

export class docAssignmentStatus {
    DocType: string;
    Employees: number;
}

export class docNote {
    NoteID: number;
    DocumentID: number;
    VersionID?: number;
    PersonName: string;
    Note: string;
    CreatedBy: number;
    CreatedDate: Date;
    UpdatedBy: number;
    UpdatedDate: Date;

    constructor() {
        this.NoteID = IDType.NewRec;
        this.Note = '';
    }
}

export class docDocLinkDb {
    DocLinkID: number;
    VersionID: number;
    DocumentID: number;
    DocumentTitle: string;  // <- read only
    CreatedBy: number;
    CreatedDate: Date;
    UpdatedBy: number;
    UpdatedDate: Date;
}

export class docDocumentSearch {
    DocumentID: number;
    DocumentTitle: string;
    CategoryName: string;

    // UI USE ONLY
    DisplayName: string;
}

export class docReassignCandidate
{
    PersonID: number;
    PersonName:string;
    DepartmentName: string;
    WarehouseName: string;
    LanguageCode: string;
    Function?: string;
    LastUsed?: Date;
    DaysSinceLastUse?: number;
    LastAssignment: Date;
    AssignmentStatus: string
}

export class docReassignGroup {
    DocumentID: number;
    Persons: docReassignCandidate[];
    SoftDueDate: Date;
    HardDueDate: Date;
}