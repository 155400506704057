import { Injectable, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PersistenceService implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  set(key: string, value: any) {
    if (value) {
      sessionStorage.setItem(key, JSON.stringify(value));
    } else {
      sessionStorage.setItem(key, null);
    }
  }

  clear(key: string) {
    return new Promise<boolean>((resolve, reject) => {
      sessionStorage.removeItem(key);
      resolve(true);
    })
  }

  get(key: string): any {
    const tmp = sessionStorage.getItem(key);
    if (tmp === undefined) {
      return '';
    } else if (tmp) {
      return JSON.parse(tmp);
    } else {
      return '';
    }
  }

}
