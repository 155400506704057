import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import { ordNmfc } from '../_models/ORD.model';
import { HttpClient } from '@angular/common/http';
import { IDType } from '../_models/enums.model';

@Injectable()
export class OrderNmfcService {
    api = 'ordNmfc/';

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }


    save(item: ordNmfc) {
        if (item.NmfcID === IDType.NewRec) {
            return this.http.post(this.apiURL + this.api, item);
        } else {
            return this.http.put(this.apiURL + this.api + item.NmfcID, item);
        }
    }

    delete(item: ordNmfc) {
        return this.http.delete(this.apiURL + this.api + item.NmfcID);
    }

    getForOrder(orderID: number, displayType: string) {
        return this.http.get(this.apiURL + this.api + 'order/' + orderID + '/' + displayType);
    }
}
