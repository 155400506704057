import { Inject, Injectable } from '@angular/core';
import { cfgTrackingCategory } from '../_models/CFG.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TrackingCategoryService {
	api = 'cfgTrackingCategory/';

	constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

	get(id: number) {
		return this.http.get<cfgTrackingCategory>(this.apiURL + this.api + id);
	}
	
	getAll() {
		return this.http.get<cfgTrackingCategory[]>(this.apiURL + this.api);
	}

	create(trackingCategory: cfgTrackingCategory) {
		return this.http.post(this.apiURL + this.api, trackingCategory);
	}

	update(trackingCategory: cfgTrackingCategory) {
		return this.http.put(this.apiURL + this.api + trackingCategory.TrackingCategoryID, trackingCategory);
	}

	delete(id: number) {
		return this.http.delete(this.apiURL + this.api + id);
	}
}
