import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';

import { cfgPaymentType } from '../_models/CFG.model';

import { HttpClient } from '@angular/common/http';

@Injectable()
export class PaymentTypesService {


  api = 'cfgPaymentType/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<cfgPaymentType[]>(this.apiURL + this.api);

  }
  create(paymentType: cfgPaymentType) {
    return this.http.post(this.apiURL + this.api, paymentType);
  }

  update(paymentType: cfgPaymentType) {
    return this.http
      .put(this.apiURL + this.api + paymentType.PaymentTypeID, paymentType);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }


}
