import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DocNotesService } from '../../_data/pro-docs.service';
import { AlertService, AuthService } from '../../_services/index';
import { BehaviorSubject } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { docNoteDb } from 'app/_models/DOC.model';
import { DocItemType } from 'app/_models/enums.model';

@Component({
  selector: 'arc-docNotes',
  templateUrl: './doc-notes.component.html',
  styleUrls: ['./doc-notes.component.scss']
})
export class DocNotesComponent implements OnInit {

  private _versionID = new BehaviorSubject<number>(null);
  private _documentID = new BehaviorSubject<number>(null);
  @Input()
  set versionID(value: number) {
    this._versionID.next(value);
  }
  get versionID() {
    return this._versionID.getValue();
  }
  @Input()
  set documentID(value: number) {
    this._documentID.next(value);
  }
  get documentID() {
    return this._documentID.getValue();
  }

  editNoteText: string;
  editing = false;
  curNoteID = -1;
  curNote: docNoteDb;
  editingExisting = false;

  dateFormat: string;

  notes: docNoteDb[] = [];

  oldItemType = -1;
  oldItemID = -1;

  @Input() allowEdit = true;

  @Output() loaded = new EventEmitter<number>(true);

  @ViewChild('editNoteField', { static: true }) editNoteField: any;

  // @Input() documentID;
  // @Input() versionID;

  constructor(
    private noteService: DocNotesService,
    private alert: AlertService,
    private auth: AuthService,
    private confirmer: ConfirmationService
  ) { }

  ngOnInit() {
    this.dateFormat = this.auth.currentUser.opts.dateFormat;
    this._documentID.subscribe({
      next: () => {
        // this.loadNotes();
        this._versionID.subscribe({
          next: () => {
            this.loadNotes();
          }
        });
      }
    });
    // this.loadNotes();
  }

  loadNotes() {
    console.log('load notes?');
    if ((this.documentID !== null)) { //  && (this.noteType !== null)) {
      this.notes = [];
      this.noteService.getNotes(this.documentID, this.versionID)
        .subscribe(data => {
          this.notes = data.sort((a, b) => a.CreatedDate < b.CreatedDate ? 1 : -1);
          this.loaded.emit(this.notes.length);
        },
          error => {
            this.alert.error(error);
          });
    }
  }

  onSaveNote() {
    if (this.editNoteText.length === 0) { return; }
    const saveNote = new docNoteDb();
    saveNote.DocumentID = this.documentID;
    saveNote.VersionID = this.versionID;
    saveNote.Note = this.editNoteText;
    this.noteService.create(saveNote).subscribe(
      ret => {
        this.loadNotes();
      },
      error => {
        this.alert.error(error);
      });
    this.editing = false;
    this.editNoteText = '';
    this.editingExisting = false;
    this.setFocus('editNoteField');
  }

  onEditNote(id) {
    this.curNoteID = id;
    this.curNote = this.notes.find(n => n.NoteID === id);
    this.editingExisting = true;
    this.editNoteText = this.curNote.Note;
  }

  onConfirmDelete(n: docNoteDb) {
    this.confirmer.confirm({
      message: 'Delete note from ' + n.CreatedDate.toString() + '?',
      header: 'Confirm',
      icon: 'fa fa-question-circle',
      accept: () => { this.deleteNote(n.NoteID); }
    });
  }

  deleteNote(id: number) {
    this.noteService.delete(id)
      .subscribe(x => {
        this.alert.info('Note deleted');
        this.loadNotes();
      },
        error => {
          this.alert.error(error);
        });
    this.setFocus('editNoteField');

  }

  onUpdateNote() {
    this.curNote.Note = this.editNoteText;
    this.noteService.update(this.curNote)
      .subscribe(ret => {
        this.alert.info('Updated');
        this.loadNotes();
      },
        error => {
          this.alert.error(error);
        });
    this.curNoteID = -1;
    this.editNoteText = '';
    this.setFocus('editNoteField');
    this.editingExisting = false;
  }

  onCancelNote() {
    this.editingExisting = false;
    this.curNoteID = -1;
    this.editNoteText = '';
    this.setFocus('editNoteField');
  }

  setFocus(controlID) {
    if (this.editNoteField) {
      // this.editNoteField.setFocus();
      setTimeout(() => { this.editNoteField.nativeElement.focus(); }, 10);
    }
  }

}
