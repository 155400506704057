import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UserSettings, UserEntity } from '../_models';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  api = 'secUserSettings/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  get(id: number) {
    return this.http.get<UserSettings>(this.apiURL + this.api + id);
  }

  getAll(activeOnly: boolean) {
    let params = new HttpParams();
    params = params.append('activeOnly', activeOnly.toString());
    return this.http.get<UserSettings[]>(this.apiURL + this.api, {params: params });
  }

  create(item: UserSettings) {
    return this.http.post<UserSettings>(this.apiURL + this.api, item);
  }

  update(item: UserSettings) {
    return this.http.put<UserSettings>(this.apiURL + this.api + item.UserID, item);
  }

  getEntitiesForUser(id: number) {
    return this.http.get<UserEntity[]>(this.apiURL + this.api + 'entities/' + id);
  }

  getAvailableEntities(userID: number, frag: string) {
    return this.http.get<UserEntity[]>(this.apiURL + this.api + 'availableEntities/' + userID + '/' + frag);
  }

  // delete(id: number) {
  //   return this.http.delete(this.apiURL + this.api + id);
  // }

}
