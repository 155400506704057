import { ThisReceiver } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { docAssignmentService } from 'app/_data/pro-docs.service';
import { docAssignmentDb, docAssignmentEx, docDocumentDb } from 'app/_models/DOC.model';
import { IDType } from 'app/_models/enums.model';
import { timeEmployee } from 'app/_models/TIME.model';
import { AlertService, AuthService } from 'app/_services';
import { PersistenceService } from 'app/_services/persistence.service';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';

@Component({
  selector: 'arc-assignments',
  templateUrl: './assignments.component.html',
  styleUrls: ['./assignments.component.scss']
})
export class AssignmentsComponent implements OnInit {

  @ViewChild('dt', { static: true }) mydt: Table;

  @Input() documentID: number = -1;
  @Input() personID: number = -1;
  @Output() count = new EventEmitter<number>();

  document: docDocumentDb;
  person: timeEmployee;

  itemName = 'Assignments';

  showAssignDialog = false;
  includeCompleted = false;
  reassignToggle: boolean = false;

  showReasonDialog = false;

  items: docAssignmentEx[] = [];
  selectedItem: docAssignmentEx;
  editAssignmentID = 0;
  editPersonID = 0;
  editDocumentID = 0;
  reason = '';
  assignees: SelectItem[] = [];
  warehouses: SelectItem[] = [];

  //#region FilterNames
  storedDTFilters = 'AssignmentsDTFilters';
  storedInputFilters = 'AssignmentsInputFilters';
  //#endregion

  // sorting
  multiSortMetaDefault = [{ field: 'DocumentTitle', order: 1 }, { field: 'PersonName', order: 1 }]; // , {field: 'PlaceHolder', order: 1}, {field: 'PlaceHolder', order: 1}];

  localFilter = {
    DocumentTitle: null,
    PersonName: null,
    Warehouse: null,
    VersionID: null,
    AssignedDate: null,
    SoftDueDate: null,
    HardDueDate: null,
    IncludeCompleted: null,
    MultiSortMeta: this.multiSortMetaDefault,
    rowsPerPage: +this.authService.currentUser.opts.PaginatorRows,
    currentRow: 0
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private assignService: docAssignmentService,
    private authService: AuthService,
    private persist: PersistenceService,
    private confirmationService: ConfirmationService,
    private alert: AlertService
  ) { }

  ngOnInit(): void {
    // this.documentID = this.route.snapshot.params['documentID'];

    this.mydt.rowsPerPageOptions = this.authService.currentUser.opts.PaginatorRowOptions;

    const tmp2 = this.persist.get(this.storedInputFilters);
    console.log('tmp2', tmp2);
    if (tmp2) {

      if (tmp2.AssignedDate) {
        if (tmp2.AssignedDate[0]) {
          tmp2.AssignedDate[0] = new Date(tmp2.AssignedDate[0]);
        }
        if (tmp2.AssignedDate[1]) {
          tmp2.AssignedDate[1] = new Date(tmp2.AssignedDate[1]);
        }
      }
      if (tmp2.SoftDueDate) {
        if (tmp2.SoftDueDate[0]) {
          tmp2.SoftDueDate[0] = new Date(tmp2.SoftDueDate[0]);
        }
        if (tmp2.SoftDueDate[1]) {
          tmp2.SoftDueDate[1] = new Date(tmp2.SoftDueDate[1]);
        }
      }
      if (tmp2.HardDueDate) {
        if (tmp2.HardDueDate[0]) {
          tmp2.HardDueDate[0] = new Date(tmp2.HardDueDate[0]);
        }
        if (tmp2.HardDueDate[1]) {
          tmp2.HardDueDate[1] = new Date(tmp2.HardDueDate[1]);
        }
      }

      this.localFilter = tmp2;
      if (this.localFilter.IncludeCompleted) {
        this.includeCompleted = this.localFilter.IncludeCompleted;
      } else {
        this.includeCompleted = false;
      }
    }

    //#region Set Default Filters
    const tmp = this.persist.get(this.storedDTFilters);
    if (tmp) {
      this.mydt.filters = tmp;
    }

    this.loadAll();
  }

  loadAll() {
    // 9/10/2022 RJS Need to make this more intelligent
    // if we're in Person mode, only load assignments for this person
    // if we're in document mode, only load assignments for this document
    // if we're in generic mode, load ALL documents


    console.log('loading all');
    console.log('assignments component', this.documentID, this.personID);
    if (this.personID > 0) {
      this.assignService.getForAssignee(this.personID, this.includeCompleted)
        .subscribe((da: docAssignmentEx[]) => {
          this.items = da;
          this.items.forEach(a => {
            a.AssignedDate = a.AssignedDate ? new Date(a.AssignedDate) : null;
            a.SoftDueDate = a.SoftDueDate ? new Date(a.SoftDueDate) : null;
            a.HardDueDate = a.HardDueDate ? new Date(a.HardDueDate) : null;
          })
          this.getAssignees(this.items);
          this.getWarehouses(this.items);
          this.count.emit(this.items.length);
          console.log('got person assignments', this.items);
        });
    } else if (this.documentID > 0) {
      this.assignService.getForDocument(this.documentID, this.includeCompleted)
        .subscribe((da: docAssignmentEx[]) => {
          this.items = da;
          this.items.forEach(a => {
            a.AssignedDate = a.AssignedDate ? new Date(a.AssignedDate) : null;
            a.SoftDueDate = a.SoftDueDate ? new Date(a.SoftDueDate) : null;
            a.HardDueDate = a.HardDueDate ? new Date(a.HardDueDate) : null;
          })
          this.getAssignees(this.items);
          this.getWarehouses(this.items);
          this.count.emit(this.items.length);
          console.log('got assignments', this.items);
        });
    } else {
      this.assignService.getAll(this.includeCompleted)
        .subscribe((da: docAssignmentEx[]) => {
          this.items = da;
          this.items.forEach(a => {
            a.AssignedDate = a.AssignedDate ? new Date(a.AssignedDate) : null;
            a.SoftDueDate = a.SoftDueDate ? new Date(a.SoftDueDate) : null;
            a.HardDueDate = a.HardDueDate ? new Date(a.HardDueDate) : null;
          })
          this.getAssignees(this.items);
          this.getWarehouses(this.items);
          console.log(this.assignees)
          this.count.emit(this.items.length);
          console.log('got ALL assignmets', this.items);
        })
    }
    setTimeout(() => this.setTableParms(), 200);

  }

  public reload() {
    this.loadAll();
  }
  
  getAssignees(assignments: docAssignmentEx[]) {
    const tmp = [... new Set(assignments.map(owner => owner.AssignedByName))].sort((a, b) => a > b ? 1 : -1);
    this.assignees = tmp.map(asgn => ({ label: asgn, value: asgn }));
  }

  getWarehouses(assignments: docAssignmentEx[]) {
    const tmp = [... new Set(assignments.map(warehouse => warehouse.Warehouse))].sort((a, b) => a > b ? 1 : -1);
    this.warehouses = tmp.map(whse => ({ label: whse, value: whse }));
  }

  setTableParms() {
    this.mydt.rows = this.localFilter.rowsPerPage; //  +this.authService.currentUser.opts.PaginatorRows;
    this.mydt.first = this.localFilter.currentRow;
  }

  //#region UI Methods

  checkItem(item: any) {
    item.isSelected = !item.isSelected;
  }

  addNew() {
    this.router.navigate(['/prodocs/assignment-edit', IDType.NewRec]);
  }

  toggleIncludeCompleted() {
    this.includeCompleted = !this.includeCompleted;
    this.localFilter.IncludeCompleted = this.includeCompleted;
    this.persist.set(this.storedInputFilters, this.localFilter);
    this.loadAll();
  }

  goToDocument(id: number) {
    this.router.navigateByUrl('prodocs/document/' + id);
  }

  //#endregion

  //#region Internal Methods

  // editItem(item: docDocumentDb) {
  //   console.log('routing ', item.DocumentID);
  //   this.router.navigate(['/prodocs/assignment', item.DocumentID]);
  // }

  onSort(event) {
    this.localFilter.MultiSortMeta = event.multisortmeta;
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  onPage(event) {
    this.localFilter.rowsPerPage = event.rows;
    this.localFilter.currentRow = event.first;
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  doFilter(event) {
    // and store the filters for later recall
    this.persist.set(this.storedDTFilters, event.filters);
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  clearLocalFilter(dt: Table) {
    this.localFilter.DocumentTitle = null;
    this.localFilter.PersonName = null;
    this.localFilter.Warehouse = null;
    this.localFilter.VersionID = null;
    this.localFilter.AssignedDate = null;
    this.localFilter.SoftDueDate = null;
    this.localFilter.HardDueDate = null;
    this.localFilter.MultiSortMeta = this.multiSortMetaDefault;
    this.localFilter.rowsPerPage = +this.authService.currentUser.opts.PaginatorRows;
    this.localFilter.currentRow = 0;

    dt.clear();
    dt.filters = {};

    this.persist.set(this.storedDTFilters, null);
    this.persist.set(this.storedInputFilters, this.localFilter);

    this.loadAll();

  }

  reassign(item: docAssignmentEx) {
    console.log('adding new assignment: doc, person', this.documentID, this.personID);
    this.editAssignmentID = IDType.NewRec;
    this.editPersonID = item.PersonID;
    this.editDocumentID = item.DocumentID;
    this.reassignToggle = true;
    this.showAssignDialog = true;
  }

  editAssignment(item: docAssignmentEx) {
    this.editAssignmentID = item.AssignmentID;
    this.editPersonID = item.PersonID;
    this.editDocumentID = item.DocumentID;
    this.showAssignDialog = true;
  }

  addAssignment() {
    console.log('adding new assignment: doc, person', this.documentID, this.personID);
    this.editAssignmentID = IDType.NewRec;
    this.editDocumentID = (this.documentID > 0) ? this.documentID : 0;
    this.editPersonID = (this.personID > 0) ? this.personID : 0;
    this.showAssignDialog = true;
  }

  editComplete() {
    this.showAssignDialog = false;
    this.reassignToggle = false;
    console.log('assigment complete, load all');
    this.loadAll();
  }

  confirmDelete(asgn: docAssignmentEx) {
    this.confirmationService.confirm({
      message: 'Remove assignment of <em><b>' + asgn.DocumentTitle + '</b></em> to <b>' + asgn.PersonName + '</b>?',
      header: 'Confirm',
      icon: 'fa fa-question-circle',
      accept: () => { this.doDelete(asgn.AssignmentID); }
    });
  }

  doDelete(asgnID: number) {
    this.assignService.delete(asgnID)
      .subscribe(
        (ret) => {
          this.alert.success('Deleted!');
          this.loadAll();
        },
        error => this.alert.error(error)
      );
  }

  showReason(asgn: docAssignmentEx) {
    this.selectedItem = asgn;
    console.log('selectedItem', this.selectedItem);
    this.showReasonDialog = true;
  }

  mayEditAssignments(): boolean {
    return this.authService.userHasRight('actProDocsAssignmentEdit');
  }

  mayAddAssignments(): boolean {
    return this.authService.userHasRight('actProDocsAssignmentAdd');
  }

  //#endregion

}
