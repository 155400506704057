import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

// // import { server } from '../_models/index';
import { ordTrackingPortalVw, ordTrackingFreightVw } from '../_models/DBTables.model';
import { ordTracking } from '../_models/ORD.model';
import { OrdLinkType, TrackingType, TrackingStatus } from '../_models/enums.model';
import { ordFreightLegEx } from '../_models/ORD.model';
import { AlertService } from 'app/_services';

@Injectable()
export class TrackingVwService {

  api = 'ordTrackingFreightVw/';

  constructor(
    private http: HttpClient,
    @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<ordTrackingFreightVw[]>(this.apiURL + this.api);
  }

  getAllFiltered(currentStatuses: string[], triggerStatuses: string[]) {
    const exceptStatuses: string[] = [];
    // are any of my trigger statuses in the currently selected status list?
    // if NOT, add them to the except list
    // if they ARE, we do NOT want to except them
    triggerStatuses.forEach(s => {
      if (currentStatuses.indexOf(s) < 0) {
        exceptStatuses.push(s);
      }
    });

    if (exceptStatuses.length === 0) { // it's found
      return this.http.get<ordTrackingFreightVw[]>(this.apiURL + this.api); // so get all
    } else {  // else, not found,
      return this.http.get<ordTrackingFreightVw[]>(this.apiURL + this.api + 'except/' + exceptStatuses.join(',')); // so get all EXCEPT the trigger status
    }
  }

}

@Injectable()
export class TrackingPortalVwService {

  api = 'ordTrackingPortalVw/';

  constructor(
    private http: HttpClient,
    @Inject('apiURL') private apiURL) { }

  getAllForClient(ClientID: number) {
    var params = new HttpParams();
    params = params.append('ClientID', ClientID);
    return this.http.get<ordTrackingPortalVw[]>(this.apiURL + this.api, {params: params});
  }

  getAllFilteredForClient(clientID: number, forwardingStatuses: string[]) {
    var params = new HttpParams();
    params = params.append('clientID', clientID.toString());
    params = params.append('forwardingStatuses', forwardingStatuses.join(','));
    return this.http.get<ordTrackingPortalVw[]>(this.apiURL + this.api, {params: params}); // so get all
  }

}

@Injectable()
export class TrackingService {

  api = 'ordTracking/';

  constructor(
    private http: HttpClient,
    private alert: AlertService,
    @Inject('apiURL') private apiURL) { }

  generateForOrder(id: number) {
    return this.http.post<boolean>(this.apiURL + this.api + 'order/' + id, null);
  }

  getNew() {
    return this.http.get<ordTracking>(this.apiURL + this.api + 'new');
  }

  create(item: ordTracking) {
    return this.http.post(this.apiURL + this.api, item);
  }

  update(item: ordTracking) {
    return this.http.put(this.apiURL + this.api + item.TrackingID, item);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  clearAuto(FreightLegID: number, Type: TrackingType) {
    return this.http.delete(this.apiURL + this.api + Type + '/' + FreightLegID);

  }
  getForItem(itemType: OrdLinkType, itemID: number) {
    return this.http.get<ordTracking[]>(this.apiURL + this.api + itemType + '/' + itemID);
  }

  // ----------------------------------------------
  public saveTracking(direction: string, leg: ordFreightLegEx, newStatus: TrackingStatus, type: TrackingType) {
    const track = new ordTracking();
    track.FreightLegID = leg.FreightLegID;
    track.OrderID = leg.OrderID;
    track.DateTracked = new Date();
    track.TrackingTypeID = type;

    if (direction === 'inbound') {
      track.Status = 'Inbound status set to ' + TrackingStatus[newStatus];
    } else if (direction === 'outbound') {
      track.Status = 'Outbound status set to ' + TrackingStatus[newStatus];
    }
    this.create(track)
      .subscribe(
        data => {
          // this.alerts.success('Created tracking record');
        },
        (error) => this.alert.error(error)
      );
  }

  clearTracking(FreightLegID: number, Type: TrackingType) {
    this.clearAuto(FreightLegID, Type)
      .subscribe(x => {
        // this.alerts.info('auto tracking cleared'));
      },
        (error) => this.alert.error(error)
      );
  }

}
