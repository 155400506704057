import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import { cfgOrderIssue } from '../_models/CFG.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class OrderIssuesService {
  api = 'cfgOrderIssue/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<cfgOrderIssue[]>(this.apiURL + this.api);

  }
  create(OrderIssue: cfgOrderIssue) {
    return this.http.post(this.apiURL + this.api, OrderIssue);
  }

  update(OrderIssue: cfgOrderIssue) {
    return this.http
      .put(this.apiURL + this.api + OrderIssue.OrderIssueID, OrderIssue);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }
}
