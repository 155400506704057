import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService, AlertService } from './../_services/index';

@Injectable()
export class AuthGuard  {

    constructor(private router: Router, private auth: AuthService, private alert: AlertService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.auth.isAuthenticated) {
            // not logged in so redirect to login page with the return url
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }

        if (state.url === '/') { return true; } // always allow to home page

        /// if not Validation
        /// RJS 1/28/22 Not Needed
        // if (this.auth.userValidationState <= 0) {
        //     this.router.navigate(['/']);
        //     return false;
        // }

        const needRight = route.data.needRight;

        if (!needRight) {
            return true;
        } else if (this.auth.userHasRight(needRight)) {
            return true;
        } else {
            this.alert.error('You are not permitted to view that page.', 'tc', 5000); // , true);
            this.router.navigate(['/']);
            return false;
        }
    }
}
