import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Subscription } from 'rxjs';

// // import { server } from '../_models/index';
import { ClientsGoTimeSearch, orgClientsFreightVw, orgClientsGoTime } from '../_models/ORG.model';


@Injectable()
export class ClientsService {

  clientType = '';
  clientTypeSubscription: Subscription;

  api = 'orgClient/';
  constructor(
    private http: HttpClient, @Inject('apiURL') private apiURL) {
  }

  getAll(clientType: string) {
    if (clientType === 'freight') {
      this.api = 'orgClient/';
      return this.http.get<orgClientsFreightVw[]>(this.apiURL + this.api + clientType);
    } else if (clientType === 'fulfillment') {
      this.api = 'orgClient/';
      return this.http.get<orgClientsFreightVw[]>(this.apiURL + this.api + clientType);
    }

  }

  getAllPaged(clientType: string, sortField: string, startindex: number, rows: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'sortBy': sortField
      })
    };

    if (clientType === 'freight') {
      this.api = 'orgClient/';
      return this.http.get<orgClientsFreightVw[]>(this.apiURL + this.api + clientType + '/' + startindex + '/' + rows, httpOptions);
    } else if (clientType === 'fulfillment') {
      this.api = 'orgClient/';
      return this.http.get<orgClientsFreightVw[]>(this.apiURL + this.api + clientType + '/' + startindex + '/' + rows, httpOptions);
    }

  }

  getByID(clientID: number) {
    this.api = 'orgClientsFreightVw/';
    return this.http.get<orgClientsFreightVw>(this.apiURL + this.api + clientID);
  }

  update(client: orgClientsFreightVw) {
    this.api = 'orgClientsFreightVw/';
    return this.http.put<orgClientsFreightVw>(this.apiURL + this.api + client.EntityID, client);
  }
}

@Injectable()
export class ClientsGoTimeService {
  api = 'orgClientsGoTime/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<orgClientsGoTime[]>(this.apiURL + this.api);

  }
  get(id: number) {
    return this.http.get<orgClientsGoTime>(this.apiURL + this.api + id);
  }

  search(frag: string) {
    return this.http.get<ClientsGoTimeSearch[]>(this.apiURL + this.api + 'search/' + frag);
  }

  create(shortName: string) {
    return this.http.post(this.apiURL + this.api + shortName, null);
  }
}


