import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { timeEmployeeService } from 'app/_data/time.service';
import { timeAssignedFunction, timeEmployeeSnapshot, timeFunctionDb } from 'app/_models/TIME.model';
import { AlertService, AuthService } from 'app/_services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'arc-select-break-type',
  templateUrl: './select-break-type.component.html',
  styleUrls: ['./select-break-type.component.scss']
})
export class SelectBreakTypeComponent implements OnInit {

  Breaks: timeAssignedFunction[] = [];
  counter: number;
  selectedBreak: timeFunctionDb = null;
  complete: boolean = false;
  userID: number;
  employee: timeEmployeeSnapshot = null

  @Output() setJob = new EventEmitter<timeFunctionDb>();
  private _subscription: Subscription;


  constructor(
    private authService: AuthService,
    private employeeService: timeEmployeeService,
    private alert: AlertService
  ) { }

  ngOnInit(): void {
    this.userID = this.authService.currentUser.UserID;

    this._subscription = this.employeeService.signedInEmployee$.subscribe(
      (value) => {
        if (value) {
          this.employee = value;
          this.Breaks = value.AssignedBreaks;
        } else {
          this.Breaks = [];
        }
      },
      (error) => this.alert.error(error)
    );
  }

  selectBreak(brk: timeFunctionDb) {
    this.selectedBreak = brk
    this.complete = true;
    this.finishSelection(brk);
  }

  finishSelection(brk: timeFunctionDb) {
    this.setJob.emit(brk);
  }

}
