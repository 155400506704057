import { Component, Input, OnInit } from '@angular/core';
import { docDashboardService } from 'app/_data/pro-docs.service';
import { docAssignmentCompletionDistribution } from 'app/_models/DOC.model';
import { AssignmentCompletionDistributionTypes, DateRange } from 'app/_models/enums.model';
import { AuthService } from 'app/_services';
import { PersistenceService } from 'app/_services/persistence.service';
import { UtilityService } from 'app/_services/utility.service';

// ******************
// **** GLK 2022 ****
// ******************

@Component({
  selector: 'assignment-completion-distribution',
  templateUrl: './assignment-completion-distribution.component.html',
  styleUrls: ['./assignment-completion-distribution.component.scss']
})
export class AssignmentCompletionDistributionComponent implements OnInit {


  // ** TOGGLES & PARAMS ** \\
  @Input() displayType: string;
  showFullTeam: boolean = false;
  currentUserID: number = null;
  fromDate: Date = null;
  dateOptionDefault = DateRange.Last180Days;

  // ** DATA ** \\
  data: docAssignmentCompletionDistribution = null;
  dateRangeOptions = this.utility.dateRangeOptions();
  formatedChartData: any;

  // ** MISC & STORAGE ** \\
  localStorageName = 'AssignmentCompletionDistributionStorage';
  displayTypes = AssignmentCompletionDistributionTypes;

  localStorage = {
    dateOption: DateRange.Last180Days,
    dateRange: this.utility.dateRangeDate(this.dateOptionDefault)
  }

  constructor(
    private dashboardService: docDashboardService,
    private persist: PersistenceService,
    private utility: UtilityService,
    private auth: AuthService
  ) { }

  ngOnInit(): void { this.setUp(); }

  // ** Initialization **
  // #region Initialization

  setUp() {
    this.currentUserID = this.auth.currentUser.UserID;
    this.getData();
  }

  getData() {
    if (this.displayType === this.displayTypes.Team) {
      this.getDataForTeam();
    } else if (this.displayType === this.displayTypes.Admin) {
      this.getAllData();
    }
  }

  // ! **********************
  // ! MAKE SURE TO CHANGE THE USERID TO currentUserID BEFORE DEPLOYEMENT
  // ! **********************
  getDataForTeam() {
    this.dashboardService.getCompletionDistributionForTeam(this.currentUserID, this.showFullTeam, this.localStorage.dateRange[0]).subscribe({
      next: (data: docAssignmentCompletionDistribution) => {
        console.log('%c Assignment Completion Distribution For Team', 'color: orange', data)
        this.data = data;
        this.formatData(data);
      }
    });
  }

  getAllData() {
    this.dashboardService.getCompletionDistribution(this.localStorage.dateRange[0]).subscribe({
      next: (data: docAssignmentCompletionDistribution) => {
        console.log('%c Assignment Completion Distribution For ProPack', 'color: orange', data)
        this.data = data;
        this.formatData(data);
      }
    })
  }

  //#endregion Initialization

  // ** Data Manipulation **
  // #region Data Manipulation

  formatData(data: docAssignmentCompletionDistribution) {
    this.formatedChartData = {
      labels: ['% Completed On Time', ' % Completed Late'],
      datasets: [
        {
          data: [Math.round(data.DoneRightCount / data.Total * 100), Math.round(data.PastDueCount / data.Total * 100)],
          backgroundColor: [
            '#71c061',
            '#ff7171'
          ],
          hoverBackgroundColor: [
            '#71c061b8',
            '#ff7171a5'
          ]
        }
      ],
    }
  }

  setDateRange() {
    const newRange = this.utility.dateRangeDate(this.localStorage.dateOption);
    this.localStorage.dateRange = newRange;
    this.changeDateRange();
  }

  changeDateRange() {
    this.persist.set(this.localStorageName, this.localStorage);
    this.getData();
  }

  // #endregion

  // ** Toggling **
  // #region Toggling

  toggleShowFullTeam() {
    this.showFullTeam = !this.showFullTeam;

    this.getData();
  }

  // #endregion Toggling

  // Check Rights
  hasRight(right: string): boolean {
    return this.auth.userHasRight(right);
  }

}
