import { NgModule } from '@angular/core';
import { ActorEditComponent } from './actors/actor-edit/actor-edit.component';
import { ActorsComponent } from './actors/actors.component';
import { ArcSharedServicesModule } from '../_shared/arc-shared-services.module';
import { ArcSharedWidgetsModule } from '../_shared/arc-shared-widgets.module';
import { AssignmentEditComponent } from './assignments/assignment-edit/assignment-edit.component';
import { AssignmentsComponent } from './assignments/assignments.component';
import { CommonModule, DatePipe } from '@angular/common';
import { ConfirmNewLanguageDialogComponent } from './confirm-new-language-dialog/confirm-new-language-dialog.component';
import { DocNotesComponent } from './doc-notes/doc-notes.component';
import { DocumentEditComponent } from './documents/document-edit/document-edit.component';
import { DocumentsComponent } from './documents/documents.component';
import { ProdocReaderDocReviewComponent } from './prodocs-reader/prodoc-reader-doc-review/prodoc-reader-doc-review.component';
import { ProdocReaderHomeComponent } from './prodocs-reader/prodoc-reader-home/prodoc-reader-home.component';
import { ProdocReaderQuizComponent } from './prodocs-reader/prodoc-reader-quiz/prodoc-reader-quiz.component';
import { ProdocsDashboardEmployeeActionsComponent } from './dashboards/Actors/prodocs-actors-home-dashboard/prodocs-dashboard-employee-actions/prodocs-dashboard-employee-actions.component';
import { ProDocsRoutingModule } from './prodocs.routing-module';
import { QuizComponent } from './quiz/quiz.component';
import { QuizEditComponent } from './quiz/quiz-edit/quiz-edit.component';
import { TriggerEditComponent } from './triggers/trigger-edit/trigger-edit.component';
import { TriggersComponent } from './triggers/triggers.component';
import { VersionComponent } from './version/version.component';
import { VersionsComponent } from './versions/versions.component';
import { ProdocsDashboardVersionsListComponent } from './dashboards/Admin/prodocs-admin-home-dashboard/prodocs-dashboard-versions-list/prodocs-dashboard-versions-list.component';
import { ProdocsDashboardTeamTableComponent } from './dashboards/Team/prodocs-team-home-dashboard/prodocs-dashboard-team-table/prodocs-dashboard-team-table.component';
import { DocumentVersionHistoryComponent } from './document-version-history/document-version-history.component';
import { DocumentLibraryComponent } from './document-library/document-library.component';
import { AssignmentCompletionDistributionComponent } from './dashboards/assignment-completion-distribution/assignment-completion-distribution.component';
import { ProdocsAdminHomeDashboardComponent } from './dashboards/Admin/prodocs-admin-home-dashboard/prodocs-admin-home-dashboard.component';
import { ChartModule } from 'primeng/chart';
import { ProdocsTeamHomeDashboardComponent } from './dashboards/Team/prodocs-team-home-dashboard/prodocs-team-home-dashboard.component';
import { ProdocsActorsHomeDashboardComponent } from './dashboards/Actors/prodocs-actors-home-dashboard/prodocs-actors-home-dashboard.component';
import { DocumentAssignmentCompletionComponent } from './dashboards/document-assignment-completion/document-assignment-completion.component';
import { ProdocsDashboardTeamSummaryComponent } from './dashboards/Team/prodocs-team-home-dashboard/prodocs-dashboard-team-summary/prodocs-dashboard-team-summary.component';
import { ReassignSelectComponent } from './assignments/reassign-select/reassign-select.component';

@NgModule({
  declarations: [
    ActorEditComponent,
    ActorsComponent,
    AssignmentEditComponent,
    AssignmentsComponent,
    DocumentEditComponent,
    DocumentsComponent,
    ProdocReaderDocReviewComponent,
    ProdocReaderHomeComponent,
    ProdocReaderQuizComponent,
    QuizComponent,
    QuizEditComponent,
    TriggerEditComponent,
    TriggersComponent,
    VersionComponent,
    VersionsComponent,
    ProdocsDashboardEmployeeActionsComponent,
    ConfirmNewLanguageDialogComponent,
    DocNotesComponent,
    ProdocsDashboardVersionsListComponent,
    ProdocsDashboardTeamTableComponent,
    DocumentVersionHistoryComponent,
    DocumentLibraryComponent,
    AssignmentCompletionDistributionComponent,
    ProdocsAdminHomeDashboardComponent,
    ProdocsTeamHomeDashboardComponent,
    ProdocsActorsHomeDashboardComponent,
    DocumentAssignmentCompletionComponent,
    ProdocsDashboardTeamSummaryComponent,
    ReassignSelectComponent,
  ],
  imports: [
    CommonModule,
    ProDocsRoutingModule,
    ArcSharedServicesModule,
    ArcSharedWidgetsModule,
    ChartModule,
  ],
  exports: [
    ProdocReaderHomeComponent,
    ProdocReaderDocReviewComponent,
    ProdocReaderQuizComponent,
    AssignmentsComponent
  ],
  providers: [
    DatePipe
  ]
})
export class ProdocsModule { }
