import { Component, OnInit, Input } from '@angular/core';
import { PersonsService } from '../../_data/persons.service';
// import { server } from '../../_models/index';
import { orgPerson } from '../../_models/ORG.model';
import { OrgItemType } from '../../_models/enums.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'arc-show-stamp',
  templateUrl: './show-stamp.component.html',
  styleUrls: ['./show-stamp.component.scss']
})
export class ShowStampComponent implements OnInit {

  private _item = new BehaviorSubject<any>(0);

  @Input()
  set item(value) {
    this._item.next(value);
    this.loadPersons();
  }


  get item() {
    return this._item.getValue();
  }

  offset: number;
  offsetString: string;
  personList: orgPerson[] = [];
  creator: orgPerson;
  updater: orgPerson;
  displayMe = true;

  constructor(
    private persons: PersonsService
  ) { }

  ngOnInit() {
    this.offset = new Date().getTimezoneOffset();
    this.offsetString = this.convertOffset(this.offset);
  }

  setOffset(offset: number) {
    this.offset = offset;
    this.offsetString = this.convertOffset(this.offset);
  }

  loadPersons() {
    if ((this.item) && (this.item.CreatedBy != null)) {
      this.persons.getByID(this.item.CreatedBy).subscribe(data => this.creator = data);
      this.persons.getByID(this.item.UpdatedBy).subscribe(data => this.updater = data);
      }

  }

  convertOffset(t: number): string {
    const isNeg = t < 0;
    t = Math.abs(t);
    const hour = '0' + (t / 60);
    const min = '0' + (t % 60);
    const res = isNeg ? '' : '-' + hour.slice(-2) + min.slice(-2);

    return res;

  }

}
