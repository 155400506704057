import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import { cfgSystemOption } from '../_models/CFG.model';

import { HttpClient } from '@angular/common/http';

@Injectable()
export class SystemOptionsService {
  api = 'cfgSystemOption/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<cfgSystemOption[]>(this.apiURL + this.api);

  }
  create(SystemOption: cfgSystemOption) {
    return this.http.post(this.apiURL + this.api, SystemOption);
  }

  update(SystemOption: cfgSystemOption) {
    return this.http
      .put(this.apiURL + this.api + SystemOption.OptionsID, SystemOption);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }
}
