import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';

import { finalize } from 'rxjs/operators';

import { Table } from 'primeng/table';
import { SelectItem } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';

import { AlertService, AuthService } from '../../_services/index';
import { PersistenceService } from '../../_services/persistence.service';
import { NotifierService } from '../../_sharedData/notifier.service';
import { IDType, TriggerType } from 'app/_models/enums.model';
import { docDocumentDb, docTriggerDb } from 'app/_models/DOC.model';
import { docDocumentService, docTriggerService } from 'app/_data/pro-docs.service';

@Component({
  selector: 'arc-triggers',
  templateUrl: './triggers.component.html',
  styleUrls: ['./triggers.component.scss']
})
export class TriggersComponent implements OnInit {

  //#region Declarations

  @ViewChild('dt', { static: true }) mydt: Table;

  @Input() documentInID = 0;
  @Output() count = new EventEmitter<number>();

  // sorting
  multiSortMetaDefault = [{ field: 'DocumentTitle', order: 1 }]; // , {field: 'PlaceHolder', order: 1}, {field: 'PlaceHolder', order: 1}];

  clearMessage = '';
  inputLabel = '';

  // warehouses: SelectItem<string>[] = [];
  triggerTypes: SelectItem<any>[] = [];
  creators: SelectItem[] = [];

  TriggerTypeList = TriggerType;

  //#region FilterNames
  storedDTFilters = 'TriggerDTFilters';
  storedInputFilters = 'TriggerInputFilters';
  //#endregion

  localFilter = {
    TriggerTypeID: null,
    TriggerName: null,
    DocumentTitle: null,
    CreatedByName: null,
    CreatedDate: null,
    IsActive: true,
    MultiSortMeta: this.multiSortMetaDefault,
    rowsPerPage: +this.authService.currentUser.opts.PaginatorRows,
    currentRow: 0
  };

  documentID = 0;
  forDocument: docDocumentDb = null;
  itemName = 'Triggers';
  items: docTriggerDb[];
  selectedItem: docTriggerDb = null;


  //#endregion

  //#region Life Cycle

  constructor(
    private itemsService: docTriggerService,
    private docService: docDocumentService,
    private authService: AuthService,
    private alert: AlertService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private route: ActivatedRoute,
    private persist: PersistenceService,
    private notifier: NotifierService
  ) { }

  ngOnInit() {
    this.mydt.rowsPerPageOptions = this.authService.currentUser.opts.PaginatorRowOptions;
    this.documentID = this.route.snapshot.params['documentID'];

    if (this.documentInID) {
      this.documentID = this.documentInID;
    }

    if (this.documentID > 0) {
      this.docService.getByID(this.documentID)
        .subscribe((doc: docDocumentDb) => {
          this.forDocument = doc;
          this.itemName = 'Triggers';
        });
    } else {
      // we're getting ALL triggers!
      this.documentID = -1;
      this.itemName = "ALL Triggers";
    }

    const tmp2 = this.persist.get(this.storedInputFilters);
    console.log('tmp2', tmp2);
    if (tmp2) {
      if (tmp2.CreatedDate) {
        if (tmp2.CreatedDate[0]) {
          tmp2.CreatedDate[0] = new Date(tmp2.CreatedDate[0]);
        }
        if (tmp2.CreatedDate[1]) {
          tmp2.CreatedDate[1] = new Date(tmp2.CreatedDate[1]);
        }
      }
      this.localFilter = tmp2;
    }

    //#region Set Default Filters
    const tmp = this.persist.get(this.storedDTFilters);
    if (tmp) {
      this.mydt.filters = tmp;
    }

    // derive the triggerTypes list from the TriggerType enum
    const TTKeys = Object.keys(TriggerType).filter((v) => !isNaN(Number(v)));
    TTKeys.forEach((key, index) => {
      this.triggerTypes.push({ value: +key, label: TriggerType[key] });
    });

    this.loadAll();
  }


  //#endregion

  //#region Data Services

  loadAll() {
    this.notifier.loadingOn();
    this.itemsService.getAll(this.documentID)
      .pipe(finalize(() => this.notifier.loadingOff()))
      .subscribe(
        data => {
          this.items = data;
          this.items.forEach(a => {
            a.CreatedDate = a.CreatedDate ? new Date(a.CreatedDate) : null;
          });

          this.getCreators(this.items);
          this.count.emit(this.items.length);
          console.log('got items', this.items);

          setTimeout(() => this.setTableParms(), 200);

        },
        error => {
          this.alert.error(error);
        });
  }

  setTableParms() {
    this.mydt.rows = this.localFilter.rowsPerPage; //  +this.authService.currentUser.opts.PaginatorRows;
    this.mydt.first = this.localFilter.currentRow;
  }

  //#endregion

  //#region UI Methods

  checkItem(item: any) {
    item.isSelected = !item.isSelected;
  }

  addNew() {
    this.router.navigate(['prodocs/trigger-edit', this.documentID, IDType.NewRec]);
  }

  //#endregion

  //#region Internal Methods

  getCreators(triggers: docTriggerDb[]) {
    const tmp = [... new Set(triggers.map(creator => creator.CreatedByName))].sort((a, b) => a > b ? 1 : -1);
    this.creators = tmp.filter(crt => crt !== null).map(crt => ({label: crt, value: crt }));
  }

  goToDocument(id: number) {
    this.router.navigateByUrl('prodocs/document/' + id);
  }

  editItem(item: docTriggerDb) {
    console.log('routing ', item.DocumentID);
    this.router.navigate(['prodocs/trigger-edit', item.DocumentID, item.TriggerID]);
  }

  onSort(event) {
    this.localFilter.MultiSortMeta = event.multisortmeta;
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  onPage(event) {
    this.localFilter.rowsPerPage = event.rows;
    this.localFilter.currentRow = event.first;
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  doFilter(event) {
    // and store the filters for later recall
    this.persist.set(this.storedDTFilters, event.filters);
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  clearLocalFilter(dt) {
    this.localFilter.TriggerTypeID = null;
    this.localFilter.TriggerName = null;
    this.localFilter.DocumentTitle = null;
    this.localFilter.CreatedByName = null;
    this.localFilter.CreatedDate = null;
    this.localFilter.IsActive = true;
    this.localFilter.MultiSortMeta = this.multiSortMetaDefault;
    this.localFilter.rowsPerPage = +this.authService.currentUser.opts.PaginatorRows;
    this.localFilter.currentRow = 0;

    this.mydt.clear();
    this.mydt.filters = {};

    this.loadAll();
    this.mydt.filter(null, null, null);

    this.persist.set(this.storedDTFilters, null);
    this.persist.set(this.storedInputFilters, this.localFilter);

  }


  confirmDelete(item: docTriggerDb) {
    this.confirmationService.confirm({
      message: 'Delete "' + item.TriggerName + '"?',
      header: 'Confirm',
      icon: 'fa fa-question-circle',
      accept: () => { this.delete(item.TriggerID); }
    });
  }

  delete(id: number) {
    this.itemsService.delete(id)
      .subscribe(() => {
        this.alert.success('Deleted');
        this.loadAll();
      },
        (error) => this.alert.error(error));
  }

  //#endregion

  mayEditTriggers(): boolean {
    return this.authService.userHasRight("actProDocsTriggerEdit") && (this.documentID > 0);
  }
}

