import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { docDocumentService, docQuizExService, docQuizInstanceService, docVersionService } from 'app/_data/pro-docs.service';
import { docDocumentDb, docQuizEx, docQuizInstanceEx, docQuizQuestionEx, docVersionDb } from 'app/_models/DOC.model';
import { DocVersionStatusTypes, LanguageTypes } from 'app/_models/enums.model';
import { LabelForEnumPipe } from 'app/_pipes/label-for-enum.pipe';

@Component({
  selector: 'arc-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {

  documentID = 0;
  document: docDocumentDb;
  versionID = 0;
  version: docVersionDb;
  quizID = 0;
  quiz: docQuizEx;
  viewableQuiz: docQuizInstanceEx = null;
  statusTypes = DocVersionStatusTypes;
  lang: string = null;

  mode = 'edit';

  // ** Displaying Quiz ** //
  currentQuestion: docQuizQuestionEx = null;
  questionNumber: number = 0;

  constructor(
    private quizInstanceService: docQuizInstanceService,
    private versionService: docVersionService,
    private docService: docDocumentService,
    private labelForEnum: LabelForEnumPipe,
    private quizService: docQuizExService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.versionID = this.route.snapshot.params['versionID'];
    this.mode = this.route.snapshot.params['mode'];

    this.getVersion();

    console.log(this.mode);
    console.log('got versionID', this.versionID)

    if (this.mode === "display") {
      this.quizService.getByVersionID(this.versionID)
        .subscribe((data: docQuizEx) => {
          this.quiz = data;

          this.currentQuestion = data?.Questions[0];
          console.log(data);
        });
    }
  }

  getVersion() {
    this.versionService.getByID(this.versionID).subscribe({
      next: (data: docVersionDb) => {
        this.version = data;
        this.version.LanguageName = this.labelForEnum.transform(data.LanguageCode, LanguageTypes)
      }
    })
  }

  getDocument() {
    this.docService.getByID(this.version.DocumentID)
      .subscribe((data: docDocumentDb) => {
        this.document = data;
      });
  }

  previousQuestion() {
    if (this.questionNumber === 0)
      return;
    this.questionNumber--;
    this.currentQuestion = this.quiz.Questions[this.questionNumber];
  }

  nextQuestion() {
    if (this.questionNumber === this.quiz?.Questions.length - 1)
      return;
    this.questionNumber++;
    this.currentQuestion = this.quiz.Questions[this.questionNumber];
  }

  editQuiz() {
    this.mode = 'edit';
    this.currentQuestion = null;
    this.questionNumber = 0;
  }

  viewQuiz() {
    this.mode = 'display';
    this.quizService.getByVersionID(this.versionID)
      .subscribe((data: docQuizEx) => {
        this.quiz = data;

        this.currentQuestion = data.Questions[0];
        console.log(data);
      });
  }
}
