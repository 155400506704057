<div class="container">
    <div class="abandonBtn" (click)="continue()" *ngIf="!reviewing">
        <h3>Abandon</h3>
    </div>
    <p-panel>
        <ng-template pTemplate="header" *ngIf="selectedAssignment">
            <div class="quizHeader">
                <div><h2>{{ selectedAssignment?.DocumentTitle }}</h2></div>
                <div><p> Question: {{ questionNumber + 1 }} of {{ quiz?.Questions.length }} </p></div>
            </div>
        </ng-template>
        <div class="quizBody" *ngIf="selectedAssignment">
            <!-- ** FULL QUIZ BODY ** -->
            <div class="question">
                <h2> {{ currentQuestion?.Text }} </h2>
            </div>
            <div class="answers">
                <div class="option" *ngFor="let r of currentQuestion?.Responses" (click)="selectAnswer(r)">
                    <span class="dot" [ngClass]="{ dot_SELECTED: r.IsSelected }"></span>
                    <h3> {{ r.Text }} </h3>
                </div>
            </div>

            <!-- ** SUBMIT ** -->
            <div class="results" *ngIf="questionNumber === quiz?.Questions.length && !reviewing">
                <div class="primaryBtn" (click)="submit()">
                    <h1>Submit Quiz</h1>
                </div>
            </div>

            <!-- ** RESULTS ** -->
            <div class="results" *ngIf="reviewing">
                <div class="score">
                    <div class="scoreTile_PASSED" [ngClass]="{ scoreTile_FAILED: !passed }" >
                        <h1 *ngIf="passed">You Passed!</h1>
                        <h1 *ngIf="!passed">You did not meet the required score.</h1>
                        <h1>Score: {{ quiz?.DisplayPercentage }}% </h1>
                    </div>
                </div>
                <div class="answerContainer">
                    <div class="answers" *ngFor="let a of quiz?.Questions">
                        <div class="answer">
                            <div>
                                <h3> Question: <span style="color: grey">{{ a.Text }}</span> </h3>
                            </div>            
                            <div *ngFor="let r of a.Responses">
                                <h3 *ngIf="r.IsSelected">Your Answer: <span style="color: grey;">{{ r.Text }}</span> </h3>
                            </div> 
                        </div>
                        <div style="margin: 15px;">
                            <span class="RightDot" [ngClass]="{ WrongDot: !a.Correct }"></span>
                            <!-- <img src="../../../assets/images/RightAnswer.svg" alt="Right Answer Mark" *ngIf="a.Correct">
                            <img src="../../../assets/images/WrongAnswer.svg" alt="Wrong Answer Mark" *ngIf="!a.Correct"> -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- ******************* -->
        </div>
        <ng-template pTemplate="footer">
            <div class="footer" [ngClass]="{ footerReviewing: reviewing }" >
                <div class="primaryBtn" (click)="previousQuestion()" [ngClass]="{primaryBtn_DISSABLE: questionNumber === 0}" [hidden]="reviewing">
                    <h3>Back</h3>
                </div>
                <div class="primaryBtn" (click)="nextquestion()" [ngClass]="{primaryBtn_DISSABLE: questionNumber >= quiz?.Questions.length }" [hidden]="reviewing">
                    <h3>Next</h3>
                </div>

                <div class="primaryBtn" (click)="continue()" [hidden]="!reviewing" style="float: right;">
                    <h1>Continue</h1>
                </div>
            </div>
        </ng-template>
    </p-panel>
</div>