
import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import { cfgPieceType } from '../_models/CFG.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PieceTypeService {
  api = 'cfgPieceType/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<cfgPieceType[]>(this.apiURL + this.api);

  }
  get(id: number) {
    return this.http.get<cfgPieceType>(this.apiURL + this.api + id);
  }

  create(PieceType: cfgPieceType ) {
    return this.http.post(this.apiURL + this.api, PieceType );
  }

  update(PieceType: cfgPieceType) {
    return this.http
      .put(this.apiURL + this.api + PieceType.PieceTypeID, PieceType);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }
}
