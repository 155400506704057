import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FilterService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { secUserValidations } from './_models/SEC.model';
import { AuthService, AlertService } from './_services/index';

@Component({
  selector: 'arc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'ProPack360';
  isAuthenticated = false;
  subValidations: Subscription;
  isValidated = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private alert: AlertService,
    private filterService: FilterService,
  ) { }

  ngOnInit() {
    // 1/28/22 RJS THis is all needed!
    this.authService.getAuthentication();
    this.subValidations = this.authService.userValidations$.subscribe(
      (d: secUserValidations[]) => {
        if (d) {
          this.isValidated = (d.length === 0);
          if (!this.isValidated) {
            this.router.navigate(['validations']);
          }
        }
      },
      (error) => this.alert.error(error)
    );

    this.registerFilterService();
  }

  registerFilterService() {
    this.filterService.register('dateRangeFilter', (value, filters): boolean => {
      let startDate;
      let endDate;
      if (filters[0]) { // get the from/start value
        filters[0] = new Date(filters[0]);
        startDate = filters[0].getTime();
      }
      // the to/end value might not be set
      if (filters[1]) {
        filters[1] = new Date(filters[1]);
        endDate = filters[1].getTime() + 86399999;
      } else {
        endDate = startDate + 86399999;
      }
      // compare it to the actual values
      if (value) {
        value.setHours(0, 0, 0, 0);
        return value.getTime() >= startDate && value.getTime() <= endDate;
      } else {
        return false;
      }
    });
  }

  userValidationState(): number {
    return this.authService.userValidationState;
  }


  checkIsAuthenticated(): boolean {
    return this.authService.isAuthenticated;
  }

}
