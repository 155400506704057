import { Injectable, Inject, OnInit, OnDestroy } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

import { environment } from '../../environments/environment';
import { MenuService } from './menu.service';
import { AlertService } from './alert.service';
import { RightsService } from '../_data/rights.service';
import { secCurrentUser, secGoTime, secUserValidations } from '../_models/SEC.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { Md5 } from 'ts-md5';
import { MD5 } from 'crypto-es/lib/md5.js';
import { timeEmployeeService } from 'app/_data/time.service';
import { UsersService } from 'app/_data/users.service';


@Injectable()
export class AuthService implements OnInit, OnDestroy {

	myToken: any;
	// tokenExpired = true;
	tokenTimer: any;
	private _currentUserSource = new BehaviorSubject<any>(null);
	currentUserItem$ = this._currentUserSource.asObservable();

	private _userValidationSource = new BehaviorSubject<secUserValidations[]>(null);
	userValidations$ = this._userValidationSource.asObservable();

	jwt = new JwtHelperService();

	public currentUser: secCurrentUser;

	userValidationState = 0;  // 0 = Unchecked, -1 = Not valid, 1 = Validated
	userValidations: secUserValidations[] = [];

	euidKey = 'euid';
	rchkKey = 'rchk';
	rightsKey = 'rts';
	userTokenKey = 'userToken';

	gotAuthentication = false;

	constructor(
		private http: HttpClient,
		//private md5: MD5,
		private menuService: MenuService,
		private alert: AlertService,
		private rights: RightsService,
		private userService: UsersService,
//		private cdRef: ChangeDetectorRef,
		private timeService: timeEmployeeService,
		@Inject('apiURL') private apiURL) { }

	// #region Lifecycle functions
	ngOnInit() {
		// check once now
	}

	ngOnDestroy() {
		this.currentUserItem$ = null;
	}

	// #endregion

	// #region Internal Functions

	public getToken(): string {
		return localStorage.getItem(this.userTokenKey);
	}

	public getAuth(): string {
		if (!this.myToken) {
			this.myToken = this.getToken();
		}
		if (this.myToken) {
			return JSON.parse(this.myToken).access_token;
		} else {
			return null;
		}
	}

	public removeToken(): void {
		localStorage.removeItem(this.userTokenKey);
		localStorage.removeItem(this.rchkKey);
		localStorage.removeItem(this.euidKey);
		localStorage.removeItem(this.rightsKey);
	}


	public parseJwt(token: string): any {
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace('-', '+').replace('_', '/');
		return JSON.parse(window.atob(base64));
	}

	optionsFix(myOpts): any[] {
		// const myOpts = JSON.parse(this.currentUser.Options);

		// convert the paginator options string to a numeric array
		const rowOpts = myOpts.PaginatorRowOptions.split(',');
		for (let i = 0; i < rowOpts.length; i++) { rowOpts[i] = +rowOpts[i]; }
		myOpts.PaginatorRowOptions = rowOpts; // reassign to the object

		return myOpts;
	}

	// #endregion

	//#region Externally used functions (global)

	login(username: string, password: string) {
		// make sure all is logged out
		this.logout();

		// now do login with username and password
		const body = 'username=' + username + '&password=' + encodeURIComponent(password) + '&grant_type=password&client_id=' + environment.clientID;
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/x-www-form-urlencoded'
			})
		};
		console.log('tokenurl', environment.tokenURL, body, httpOptions);
		return this.http.post(environment.tokenURL, body, httpOptions)
			.pipe(
				map(
					(response: any) => {
						// login successful if there's a jwt token in the response
						if (response && response.access_token) {
							// grab response and put it into string format (already is?)
							this.myToken = JSON.stringify(response);
							// store user details and jwt token in local storage to keep user logged in between page refreshes
							localStorage.setItem(this.userTokenKey, this.myToken);
							// populate
							this.getAuthentication();
						} else {
							console.log('go here');
						}
					}
				)
			);
	}

	// renewToken(entityUserID: number) {
	//   this.http.get(this.apiURL + 'secLo' entityUserID)
	// }

	getAuthentication(): boolean {
		if (this.gotAuthentication) return true;

		console.log('in getAuthentication');

		if (this.isAuthenticated) {
			this.currentUser = this.parseJwt(this.myToken);

			this.checkValidations();

			this.currentUser.rts = JSON.parse(localStorage.getItem(this.rightsKey));
			this.currentUser.DefaultWarehouse = +this.currentUser.DefaultWarehouse;

			this.currentUser.opts = this.optionsFix(JSON.parse(this.currentUser.Options));
			this.currentUser.Options = [];

			this.currentUser.ents = JSON.parse(this.currentUser.Entities);
			this.currentUser.Entities = '';

			const entityUserID = +localStorage.getItem(this.euidKey);


			this.getEntityAndRightsAndMenu(entityUserID);

			// if (this.currentUser.curEntity.EntityID === 0)// an employee of ProPack
			// {
			// 	console.log('current user', this.currentUser);
			// 	this.gatherGoTimeInfo(this.currentUser.UserID);
			// }

			this.gotAuthentication = true;
			return true;

		} else {
			console.log('not authenticated, cannot get authentication');
			return false;
		}
	}

	checkValidations() {
		this.userService.getUserValidations(this.currentUser.UserID)
			.subscribe((data: secUserValidations[]) => {
				this.userValidations = data;
				this.userValidationState = (this.userValidations.length === 0) ? 1 : -1;
				this._userValidationSource.next(this.userValidations);
				console.log('got validations; state:', this.userValidationState, ',  vals:', this.userValidations);
			},
				(error) => {
					this.alert.error('Error getting validations: ' + error);
				});
	}

	acknowledgeValidations() {
		this.userValidations = [];
		this.userValidationState = 1;
		this._userValidationSource.next(this.userValidations);
	}

	gatherGoTimeInfo(id: number): Observable<secGoTime> {
		return this.timeService.getGoTimeInfo(id);
	}

	setEntityUser(euid: number) {
		console.log('setEntityUser', euid);
		this.getEntityAndRightsAndMenu(euid);
	}

	getEntityAndRightsAndMenu(euid: number) {
		console.log('getEntityAndRightsAndMenu', euid);
		if (euid) {
			// get the most recent entityUserID
			this.currentUser.curEntity = this.currentUser.ents.find(o => o.EntityUserID === euid);
		} else {
			// get the default from the 'ents' list and set the entityUserID accordingly
			this.currentUser.curEntity = this.currentUser.ents.find(o => o.isCurrent === true);
			// 4/30/2020 RJS If, due to programmer error ( :(> ) there is no default/isCurrent entity, grab the first one in the list.
			if ((!this.currentUser.curEntity) && (this.currentUser.ents.length > 0)) {
				this.currentUser.curEntity = this.currentUser.ents[0];
			}
			euid = this.currentUser.curEntity.EntityUserID;
			// localStorage.setItem(this.euidKey, euid.toString());
		}
		localStorage.setItem(this.euidKey, euid.toString()); // RJS moved this here from above 4/30/2020 on 7/21/23	

		this.setNewEntityUser(euid)
			.subscribe(
				data => {
					console.log('getNewEntityUser');
					this.currentUser.rts = data;
					localStorage.setItem(this.rightsKey, JSON.stringify(this.currentUser.rts));

					//const rchk = Md5.hashStr(JSON.stringify(this.currentUser.rts)) as string;
					const rchk = MD5(JSON.stringify(this.currentUser.rts)).toString();
					localStorage.setItem(this.rchkKey, rchk);

					this.menuService.get(this.currentUser.rts)
						.subscribe(
							(ret) => {
								this.currentUser.MenuItems = ret;
								this._currentUserSource.next(this.currentUser);
								//this.cdRef.detectChanges();
							},
							(error) => {
								console.log(error);
								this.alert.error(error);
							});
				},
				(error) => this.alert.error(error)
			);
	}

	public get isAuthenticated(): boolean {
		if (!this.myToken) {
			this.myToken = this.getToken();
		}
		if (this.myToken && !this.jwt.isTokenExpired(this.myToken)) {
			return true;
		}
		return false;
	}

	logout(): void {
		this.removeToken();
		this.myToken = null;
		this.currentUser = null;
		this.userValidationState = 0;
		this.userValidations = [];
		this._userValidationSource.next(this.userValidations);
		this.gotAuthentication = false;

		// this.tokenExpired = true;
		// this._currentUserSource.next(null);
	}

	userHasRight(right: string): boolean {
		if (this.currentUser.rts) {
			return this.currentUser.rts.includes(right); // -1 = not found, >0 = found
		} else {
			return false;
		}
	}

	setNewEntityUser(id: number) {
		return this.http.put<any>(this.apiURL + 'secEntityUser/SetNew', id);
	}



}
