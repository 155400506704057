import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { rptReport, rptReportCategory, rptReportEx, rptReportParameterTypeDb, rptReportParameterOptionDb, rptReportParameterOptionEx, rptReportCategoryEx, rptReportViewHistoryDb } from '../_models/RPT.model';

@Injectable()
export class ReportsService {
  api = 'rptReport/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<rptReportEx[]>(this.apiURL + this.api);
  }
  get(itemID: number) {
    return this.http.get<rptReport>(this.apiURL + this.api + itemID);
  }

  getForEntityUser(entityUserID: number) {
    return this.http.get<rptReportEx[]>(this.apiURL + this.api + 'user/' + entityUserID);
  }

  getExtended(itemID: number) {
    return this.http.get<rptReportEx>(this.apiURL + this.api + 'extended/' + itemID);
  }

  getExpanded(itemID: number) {
    return this.http.get<rptReportEx>(this.apiURL + this.api + 'expanded/' + itemID);
  }

  getForCategory(selectedCategory: number, entityUserID: number) {
    return this.http.get<rptReportEx[]>(this.apiURL + this.api + 'category/' + selectedCategory + '/user/' + entityUserID);
  }

  search(frag: string, category: number, entityUserID: number = -1) {
    let params = new HttpParams();
    params = params.append('frag', frag);
    params = params.append('categoryID', category.toString());
    params = params.append('entityUserID', entityUserID.toString());
    return this.http.get<rptReportEx[]>(this.apiURL + this.api + 'search', { params });
  }

  update(item: rptReportEx) {
    return this.http.put(this.apiURL + this.api + item.ReportID, item);
  }

  create(item: rptReportEx) {
    return this.http.post(this.apiURL + this.api, item);
  }

  delete(itemID: number) {
    return this.http.delete(this.apiURL + this.api + itemID);
  }

}

@Injectable()
export class ReportCategoryService {

  api = 'rptReportCategory/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<rptReportCategoryEx[]>(this.apiURL + this.api);
  }

  getForType(type: number, entityUserID: number = -1) {
    return this.http.get<rptReportCategoryEx[]>(this.apiURL + this.api + 'type/' + type + '/user/' + entityUserID);
  }

  get(itemID: number) {
    return this.http.get<rptReportCategory>(this.apiURL + this.api + itemID);
  }

  update(item: rptReportCategory) {
    return this.http.put(this.apiURL + this.api + item.CategoryID, item);
  }

  create(item: rptReportCategory) {
    return this.http.post(this.apiURL + this.api, item);
  }

  delete(itemID: number) {
    return this.http.delete(this.apiURL + this.api + itemID);
  }

}

@Injectable()
export class ReportParameterTypeService {

  api = 'rptReportParameterType/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<rptReportParameterTypeDb[]>(this.apiURL + this.api);
  }

  get(itemID: number) {
    return this.http.get<rptReportParameterTypeDb>(this.apiURL + this.api + itemID);
  }

  update(item: rptReportParameterTypeDb) {
    return this.http.put(this.apiURL + this.api + item.ParameterTypeID, item);
  }

  create(item: rptReportParameterTypeDb) {
    return this.http.post(this.apiURL + this.api, item);
  }

  delete(itemID: number) {
    return this.http.delete(this.apiURL + this.api + itemID);
  }
}

@Injectable()
export class ReportOptionsService {

  api = 'rptReportParameterOption/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<rptReportParameterOptionEx[]>(this.apiURL + this.api);
  }

  get(itemID: number) {
    return this.http.get<rptReportParameterOptionEx>(this.apiURL + this.api + itemID);
  }

  getWithParms(optionID: number, parms: string[], includeNA: boolean) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    let params = new HttpParams();
    params = params.append('parmValues', parms.join('|'));
    params = params.append('includeNA', includeNA ? 'true' : 'false');
    return this.http.get(this.apiURL + this.api + 'withParms/' + optionID, { headers: headers, params: params } )
  }

  update(item: rptReportParameterOptionDb) {
    return this.http.put(this.apiURL + this.api + item.OptionsID, item);
  }

  create(item: rptReportParameterOptionDb) {
    return this.http.post(this.apiURL + this.api, item);
  }

  delete(itemID: number) {
    return this.http.delete(this.apiURL + this.api + itemID);
  }

}

@Injectable()
export class ReportViewHistoryService {

  api = 'rptReportViewHistory/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<rptReportViewHistoryDb[]>(this.apiURL + this.api);
  }

  get(itemID: number) {
    return this.http.get<rptReportViewHistoryDb>(this.apiURL + this.api + itemID);
  }

  update(item: rptReportViewHistoryDb) {
    return this.http.put(this.apiURL + this.api + item.ViewHistoryID, item);
  }

  create(item: rptReportViewHistoryDb) {
    return this.http.post(this.apiURL + this.api, item);
  }

  delete(itemID: number) {
    return this.http.delete(this.apiURL + this.api + itemID);
  }
}

@Injectable()
export class ReportInfoService {

  api = 'rptReportInfo/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getOptionUsage(optionID: number) {
    return this.http.get(this.apiURL + this.api + 'option/' + optionID);
  }

  getCategoryUsage(categoryID: number) {
    return this.http.get(this.apiURL + this.api + 'category/' + categoryID);
  }

  getReportExecution(reportID: number) {
    return this.http.get(this.apiURL + this.api + 'report/' + reportID);
  }
}
