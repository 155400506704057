import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { docDashboardService } from 'app/_data/pro-docs.service';
import { docAssignmentEx, docDashboardTeamAssignmentsDocuments, docDashboardTeamAssignmentsEmployee } from 'app/_models/DOC.model';
import { GoTimeEmployeeTabs } from 'app/_models/enums.model';
import { AuthService } from 'app/_services';
import { PersistenceService } from 'app/_services/persistence.service';
import { Table } from 'primeng/table';

@Component({
  selector: 'prodocs-dashboard-team-summary',
  templateUrl: './prodocs-dashboard-team-summary.component.html',
  styleUrls: ['./prodocs-dashboard-team-summary.component.scss']
})
export class ProdocsDashboardTeamSummaryComponent implements OnInit {

  // ** TOGGLES ** //
  viewByEmployee: boolean = true;
  flipType: string = 'Documents'
  showFullTeam: boolean = false;
  doneGettingData: boolean = false;

  // ** DATA ** //
  @Input() rawData: docAssignmentEx[] = [];
  employeeItems: docAssignmentEx[] = [];
  filteredData: docDashboardTeamAssignmentsEmployee[] = [];
  documentItems: docDashboardTeamAssignmentsDocuments[] = [];
  teamMembers: string[] = [];
  documents: string[] = [];
  warehouses: string[] = [];
  fullTeamString: string = 'Show';
  managerID: number = null;
  GoTimeEmployeeTabs = GoTimeEmployeeTabs;

  // ** DATA TABLE FILTERING ** //
  @ViewChild('dt', { static: true }) mydt: Table;

  multiSortMetaDefault = [{ field: 'FirstSoftDue', order: 1 }];
  //#region FilterNames
  storedDTFilters = 'ProdocsTeamSummaryDashboardDTFilters';
  storedInputFilters = 'ProDocsTeamSummaryDashboardFilters';

  localFilter = {
    PersonName: null,
    PendingAssignmentCount: null,
    FirstSoftDue: null,
    FirstHardDue: null,
    ShowFullTeam: null,
    Warehouse: null,
    ManagerID: null,
    MultiSortMeta: this.multiSortMetaDefault,
    rowsPerPage: +this.auth.currentUser.opts.PaginatorRows,
    currentRow: 0,
  };

  constructor(
    private dashboardService: docDashboardService,
    private persist: PersistenceService,
    private auth: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.managerID = +this.auth.currentUser.UserID;
    this.setLocalFilters();
    // this.formatDataForEmployee(this.rawData);
    this.getData(this.managerID);
    console.log('%c RAW DATA', 'color:orange;', this.rawData)
  }

  getData(managerID: number) {
    console.log(managerID)
    this.managerID = managerID;
    this.localFilter.ManagerID = this.managerID;
    this.dashboardService.getAssignmentsForTeam(managerID, this.showFullTeam).subscribe({
      next: (data: docAssignmentEx[]) => {
        console.log('%cTEAM ASSIGNMENT LOG', 'color: orange; font-size: 15px');
        this.rawData = data;      // Store this later for flipping without having to go back to server.

        this.rawData.forEach(a => {
          a.AssignedDate = a.AssignedDate ? new Date(a.AssignedDate) : null;
          a.SoftDueDate = a.SoftDueDate ? new Date(a.SoftDueDate) : null;
          a.HardDueDate = a.HardDueDate ? new Date(a.HardDueDate) : null;
        })

        this.getTeamMembers(data);
        this.getDocumentTitles(data);
        this.getWarehouses(data);

        this.rawData.forEach(a => {
          a.DaysTillSoft = this.daysTill(a.SoftDueDate);
          a.DaysTillHard = this.daysTill(a.HardDueDate);
        })
        console.log(this.rawData);
        this.formatDataForEmployee(this.rawData);
      }
    });
  }

  getTeamMembers(assignments: docAssignmentEx[]) {
    this.teamMembers = [... new Set(assignments.map(member => member.PersonName))].sort((a, b) => a > b ? 1 : -1);
  }

  getDocumentTitles(assignments: docAssignmentEx[]) {
    this.documents = [... new Set(assignments.map(member => member.DocumentTitle))].sort((a, b) => a > b ? 1 : -1);
  }

  getWarehouses(assignments: docAssignmentEx[]) {
    this.warehouses = [... new Set(assignments.map(member => member.Warehouse))].sort((a, b) => a > b ? 1 : -1);
  }

  daysTill(dueDate: Date): number {
    let ret = Math.round((new Date(dueDate.toString()).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
    if (ret < 0)
      ret = 0;
    return ret;
  }

  toggleTeam(showFull: boolean) {
    console.log('in toggleTeam', showFull);
    console.log('managerID', this.managerID)
    if (this.showFullTeam !== showFull) {
      this.showFullTeam = showFull;
      this.localFilter.ShowFullTeam = this.showFullTeam;
      this.getData(this.managerID);
    }
  }

  formatDataForEmployee(data: docAssignmentEx[]) {
    this.filteredData = [];
    this.employeeItems = data
    let empIDs = [...new Set(data.map(emp => emp.PersonID))].sort((a, b) => a > b ? 1 : -1);

    empIDs.forEach(emp => {
      console.log(emp);
      let temp = new docDashboardTeamAssignmentsEmployee();
      let playData = this.employeeItems.filter(a => a.PersonID === emp).sort((a, b) => a.SoftDueDate > b.SoftDueDate ? 1 : -1);
      let upcoming = playData[0];
      temp.EmployeeID = emp;
      temp.PersonName = upcoming.PersonName
      temp.FirstSoftDue = new Date(upcoming.SoftDueDate);
      temp.FirstHardDue = new Date(upcoming.HardDueDate);
      temp.PendingAssignmentsCount = playData.filter(a => a.PersonID === emp).length;
      temp.Warehouse = upcoming.Warehouse;
      temp.FirstSoftCount = upcoming.DaysTillSoft
      temp.FirstHardCount = upcoming.DaysTillHard;

      console.log('%c TEMP EMP:', 'color:pink', temp)
      this.filteredData.push(temp);
      // this.employeeItems.shift();
      playData = [];
      upcoming = null;
      temp = null;
    });
    console.log('%c Summary Data', 'color: coral', this.filteredData)

  }

  getTextColor(days: number): string {
    if (days >= 5) { return '#069206'; }
    else if (days < 5 && days > 0) { return 'orange'; }
    else { return 'red'; }
  }

  viewAssignments(personID: number) {
    this.router.navigateByUrl('time/employee/' + personID, {
      state: { 'selectedTab': GoTimeEmployeeTabs.Assignments, 'forDate': new Date() }
    });
  }

  // ***************************
  // TABLE SORTING AND FILTERING
  // ***************************
  setLocalFilters() {
    this.mydt.rows = +this.auth.currentUser.opts.PaginatorRows;
    this.mydt.rowsPerPageOptions = this.auth.currentUser.opts.PaginatorRowOptions;
    this.mydt.paginator = true;
    this.mydt.alwaysShowPaginator = false;

    const temp = this.persist.get(this.storedInputFilters);
    if (temp) {
      this.localFilter = temp;
      this.mydt.first = this.localFilter.currentRow;

      // Multisort Meta
      if (this.localFilter.MultiSortMeta) {
        this.mydt.multiSortMeta = this.localFilter.MultiSortMeta;
      } else {
        this.mydt.multiSortMeta = this.multiSortMetaDefault;
      }

      // Show Full Team
      if (this.localFilter.ShowFullTeam) {
        this.showFullTeam = this.localFilter.ShowFullTeam;
      } else {
        this.showFullTeam = false;
      }

      // ManagerID
      if (this.localFilter.ManagerID) {
        this.managerID = this.localFilter.ManagerID;
      } else {
        this.managerID = +this.auth.currentUser.UserID;
      }
      // Soft Due Date
      if (temp.FirstSoftDue) {
        if (temp.FirstSoftDue[0]) {
          temp.SoftDueDate[0] = new Date(temp.FirstSoftDue[0]);
        }
        if (temp.FirstSoftDue[1]) {
          temp.FirstSoftDue[1] = new Date(temp.FirstSoftDue[1]);
        }
      }

      // Hard Due Date
      if (temp.FirstHardDue) {
        if (temp.FirstHardDue[0]) {
          temp.FirstHardDue[0] = new Date(temp.FirstHardDue[0]);
        }
        if (temp.FirstHardDue[1]) {
          temp.FirstHardDue[1] = new Date(temp.FirstHardDue[1]);
        }
      }
    } else {
      this.showFullTeam = false;
      this.managerID = +this.auth.currentUser.UserID;
    }

    const tmp = this.persist.get(this.storedDTFilters);
    if (tmp) {
      this.mydt.filters = tmp;
    }
  }

  doFilter(event) {
    this.persist.set(this.storedDTFilters, event.filters);
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  onSort(event) {
    this.localFilter.MultiSortMeta = event.multisortmeta;
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  onPage(event) {
    this.localFilter.rowsPerPage = event.rows;
    this.localFilter.currentRow = event.first;
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  clearLocalFilter(dt: Table) {

    this.localFilter.PersonName = null;
    this.localFilter.PendingAssignmentCount = null;
    this.localFilter.FirstSoftDue = null;
    this.localFilter.FirstHardDue = null;
    this.localFilter.ShowFullTeam = null;
    this.localFilter.Warehouse = null;
    this.localFilter.ManagerID = null;

    this.localFilter.MultiSortMeta = this.multiSortMetaDefault;
    this.localFilter.rowsPerPage = +this.auth.currentUser.opts.PaginatorRows;
    this.localFilter.currentRow = 0;

    dt.clear();
    dt.filters = {};

    this.persist.set(this.storedDTFilters, null);
    this.persist.set(this.storedInputFilters, this.localFilter);

    this.getData(this.managerID);
  }
}
