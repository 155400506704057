import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import { utlEmailType } from '../_models/UTL.model';
import { HttpClient } from '@angular/common/http';
import { SelectItem } from 'primeng/api/selectitem';
import { Observable } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';

@Injectable()
export class EmailTypesService {


  api = 'utlEmailType/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<utlEmailType[]>(this.apiURL + this.api);

  }
  create(emailType: utlEmailType) {
    return this.http.post(this.apiURL + this.api, emailType);
  }

  update(emailType: utlEmailType) {
    return this.http
      .put(this.apiURL + this.api + emailType.EmailTypeID, emailType);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getSelectItemList(): Observable<SelectItem[]> {
    return this.http.get(this.apiURL + this.api).pipe(
      mergeMap(this.toSelectItem));
  }

  private toSelectItem(x): Observable<SelectItem[]> {
    x.map(d => ({ value: d.EmailTypeID, label: d.EmailTypeName }));
    return x;
  }

}
