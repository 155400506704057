<div class="container" *ngIf="!takingQuiz">
    <!-- ************************* -->
    <!-- DOCUMENT NAVIGATOR -->
    
    <div class="closedNav" *ngIf="!navOpen">
        <p-panel>
            <!-- <ng-template pTemplate="header">
            </ng-template> -->
            <ng-template pTemplate="header">
                <p-button icon="pi pi-bars" (click)="toggleNav()"></p-button>
            </ng-template>
        </p-panel>
    </div>

    <div class="navigator" *ngIf="navOpen">
        <p-panel>
            <ng-template pTemplate="header">
                <p-button icon="pi pi-bars" (click)="toggleNav()"></p-button>
                <h3 style="margin-left: 25px; width: 100%;" *ngIf="!showCompletedDocuments"> Pending Assignments </h3>
                <h3 style="margin-left: 25px; width: 100%;" *ngIf="showCompletedDocuments"> Completed Assignments </h3>
                <p-button icon="pi pi-history" pTooltip="Show Completed Documents" (click)="toggleCompleted()"></p-button>
            </ng-template>
            <p-listbox [options]="assignments" [(ngModel)]="selectedAssignment" optionLabel="name"
                [listStyle]="{'height':'60vh'}" [style]="{'width':'25rem'}" (onChange)="selectAssignment()">
                <ng-template let-assignment pTemplate="item" >
                    <div class="listItemTemplate" *ngIf="!showCompletedDocuments">
                        <h3>{{ assignment.DocumentTitle }}</h3>

                        <div style="display:flex; justify-content:space-between; width: 22.5rem;">
                            <div style="width: 50%;">
                                <p *ngIf="assignment.SoftDueDate"><span style="font-weight: bold;">Due Date:</span> {{ assignment.SoftDueDate | date: 'mediumDate' }} </p>
                            </div>
                            <div style="width: 50%; text-align:right;">
                                <span *ngIf="assignment?.FirstDue > 0" [style.color]="getTextColor(assignment?.FirstDue)" >{{ assignment?.FirstDue }} day(s)</span>
                                <span *ngIf="assignment?.FirstDue === 0" [style.color]="getTextColor(assignment?.FirstDue)" >OVERDUE</span>
                            </div>
                        </div>
                        <!-- <p *ngIf="assignment.HardDueDate"><span style="font-weight: bold;">Hard Due Date:</span> {{ assignment.HardDueDate | date: 'mediumDate' }} </p> -->
                        <p *ngIf="assignment.CompletedDate"><span style="font-weight: bold;">Completed On:</span> {{ assignment.CompletedDate | date: 'mediumDate' }} </p>
                    </div>
                    <div class="listItemTemplate" *ngIf="showCompletedDocuments">
                        <h3>{{ assignment.DocumentTitle }}</h3>

                        <p *ngIf="assignment.CompletedDate"><span style="font-weight: bold;">Completed On:</span> {{ assignment.CompletedDate | date: 'mediumDate' }} </p>
                    </div>
                </ng-template>

        </p-listbox>
        </p-panel>
    </div>
    <!-- ************************* -->

    <!-- ************************* -->
    <!-- DOCUMENT VIEWER-->

    <div class="document">
        <p-panel>
            <ng-template pTemplate="header">
                <div class="documentHeader" *ngIf="assignments.length > 0">
                    <h3> {{ selectedAssignment?.DocumentTitle }} </h3>
                    <p *ngIf="selectedAssignment?.SoftDueDate"><span style="font-weight: bold;">Due Date:</span> {{ selectedAssignment?.SoftDueDate | date: 'longDate'}} </p>
                    <p *ngIf="selectedAssignment?.CompletedDate"><span style="font-weight: bold;">Completed Date:</span> {{ selectedAssignment?.CompletedDate | date: 'longDate'}} </p>
                    <button pButton icon="pi pi-link" label="Document Links" [ngClass]="'p-button-primary'" style="width:20%;" [disabled]="links?.length <= 0" (click)="linkMenu.toggle($event)"></button>

                </div>
                <div class="documentHeader" style="justify-content: center;" *ngIf="assignments.length === 0">
                    <h3>No Pending Assignments</h3>
                </div>
            </ng-template>
            <div class="documentBody" *ngIf="selectedAssignment && gotURL" >
                <embed [src]="cleanURL" width="100%" height="100%" style="overflow-y: auto;" type="application/pdf">
            </div>
            <ng-template pTemplate="footer">
                <div class="footer">
                    <button pButton (click)="takeQuiz()" *ngIf="selectedAssignment && !showCompletedDocuments && selectedAssignment.HasQuiz" >Take Quiz</button>
                    <button pButton (click)="markRead()" *ngIf="selectedAssignment && !selectedAssignment.HasQuiz" >{{showCompletedDocuments ? 'Unmark Read' : 'Mark Read'}}</button>
                </div>
            </ng-template>
        </p-panel>
    </div>
    <!-- ************************* -->
</div>

<div class="container" *ngIf="takingQuiz" style="width: 100%;">
        
    <!-- ************************* -->
    <!-- QUIZ VIEWER -->
    <arc-prodoc-reader-quiz [selectedAssignment]="selectedAssignment" (reload)="finishQuiz()" ></arc-prodoc-reader-quiz>

    <!-- ************************* -->
</div>


<p-dialog header="Linked Document" [(visible)]="displayLinkDocToggle" *ngIf="displayLinkDocToggle" [modal]="true" [style]="{'width':'90vw', 'height':'90vh'}">
    <embed [src]="linkedURL" width="100%" height="100%" style="overflow-y: auto;" type="application/pdf">
</p-dialog>

<p-menu #linkMenu [model]="linkMenuItems" [popup]="true"></p-menu>