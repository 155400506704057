import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

// // import { server } from '../_models/index';

@Injectable()
export class FreightBillingService {
    api = 'FreightBilling/';
    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    // getByID(ID: number) {
    //     return this.http.get(this.apiURL + this.api + '/' + ID);
    // }

    getAllBilling() {
        return this.http.get(this.apiURL + this.api + 'Billing/');
    }

    getAllBillingFiltered(currentStatuses: string[], triggerStatus: string) {
        if (currentStatuses.indexOf(triggerStatus) >= 0) { // it's found
            return this.http.get(this.apiURL + this.api + 'Billing'); // so get all
        } else {  // else, not found,
            return this.http.get(this.apiURL + this.api + 'Billing/except/' + triggerStatus); // so get all EXCEPT the trigger status
        }
    }

    getBillingSummary(invoiceStatuses: number[], forwardingStatuses: number[]) {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        let myParams = new HttpParams();
        myParams = myParams.append('invoiceStatuses', invoiceStatuses.join(','));
        myParams = myParams.append('forwardingStatuses', forwardingStatuses.join(','));
        return this.http.get(this.apiURL + this.api + 'Summary', { headers: headers, params: myParams} );
      }

    getAllCreditHolds() {
        return this.http.get(this.apiURL + this.api + 'CreditHolds');
    }

    getBillingPortalView(invoiceStatuses: number[], forwardingStatuses: number[]) {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        let myParams = new HttpParams();
        myParams = myParams.append('invoiceStatuses', invoiceStatuses.join(','));
        myParams = myParams.append('forwardingStatuses', forwardingStatuses.join(','));
        return this.http.get(this.apiURL + this.api + 'Portal', { headers: headers, params: myParams} );
      }
}

