<div style="width: 100%;">
    <form #AssignmentEditForm="ngForm">
        <div *ngIf="assignment">
            <!-- Form Items -->
            <div class="p-grid p-field">
                <div class="p-col-2">
                    <label for="Person">Name</label>
                </div>
                <div class="p-col-6">
                    <span *ngIf="assignmentID > 0 || personID > 0">{{person?.FirstName }} {{ person?.LastName }}</span>
                    <p-dropdown *ngIf="assignmentID < 0 && personID <= 0" [options]="employeeList" [(ngModel)]="assignment.PersonID"
                        placeholder="Select Person" optionLabel="LastName" optionValue="EmployeeID" id="Person" [showClear]="true" [filter]="true"
                        name="Person" #Person="ngModel" [disabled]="personID > 0 || reaassign === true">
                    </p-dropdown>
                </div>
                <div class="p-col-4">
                    <arc-show-errors [control]="Person"></arc-show-errors>
                </div>
            </div>
            <!----------------------------------------------------------------------------------->
            <div class="p-grid p-field">
                <div class="p-col-2">
                    <label for="Document">Document</label>
                </div>
                <div class="p-col-6">
                    <span *ngIf="assignmentID > 0 || documentID > 0">{{ document?.Title }}</span>
                    <p-dropdown *ngIf="assignmentID < 0 && documentID <= 0" [options]="documentList" [(ngModel)]="assignment.DocumentID"
                        placeholder="Select Document" optionLabel="Title" optionValue="DocumentID" id="Document" [showClear]="true" [filter]="true"
                        name="Document" #Document="ngModel">
                    </p-dropdown>
                </div>
                <div class="p-col-4">
                    <arc-show-errors [control]="Document"></arc-show-errors>
                </div>
            </div>
            <!----------------------------------------------------------------------------------->
            <div class="p-grid p-field">
                <div class="p-col-2">
                    <label for="SoftDue">Soft Due</label>
                </div>
                <div class="p-col-6">
                    <p-calendar [(ngModel)]="assignment.SoftDueDate" appendTo="body" id="SoftDueDate" name="SoftDueDate"
                        #SoftDueDate="ngModel"></p-calendar>
                </div>
                <div class="p-col-4">
                    <arc-show-errors [control]="SoftDueDate"></arc-show-errors>
                </div>
            </div>
            <!----------------------------------------------------------------------------------->
            <div class="p-grid p-field">
                <div class="p-col-2">
                    <label for="HardDue">Hard Due</label>
                </div>
                <div class="p-col-6">
                    <p-calendar [(ngModel)]="assignment.HardDueDate" appendTo="body" id="HardDueDate" name="HardDueDate"
                        #HardDueDate="ngModel"></p-calendar>
                </div>
                <div class="p-col-4">
                    <arc-show-errors [control]="HardDueDate"></arc-show-errors>
                </div>
            </div>
            <!----------------------------------------------------------------------------------->
        </div>
    </form>
    <!-- Form Footer -->
    <hr>
    <div>
        <div style="display:flex; justify-content: space-between; padding: 10px;">
            <p-button type="reset" (onClick)="toggleDialog()" icon="fa fa-ban" label="Cancel" tabindex="1"></p-button>
            <p-button *ngIf="reassign === false" type="submit" (onClick)="saveAssignment()" icon="fa fa-check-square-o" label="Save" [disabled]="!AssignmentEditForm.valid"></p-button>
            <p-button *ngIf="reassign === true" type="submit" (onClick)="reassignAssignment()" icon="fa fa-check-square-o" label="Reassign" [disabled]="!AssignmentEditForm.valid"></p-button>
        </div>
        <arc-show-stamp [item]="assignment" *ngIf="newItem === false"></arc-show-stamp>
    </div>

</div>