import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import { utlCountryCode } from '../_models/UTL.model';

import { HttpClient } from '@angular/common/http';

@Injectable()
export class CountryCodesService {

  api = 'utlCountryCode/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<utlCountryCode[]>(this.apiURL + this.api);

  }
  create(countryCode: utlCountryCode) {
    return this.http.post(this.apiURL + this.api, countryCode);
  }

  update(countryCode: utlCountryCode) {
    return this.http
      .put(this.apiURL + this.api + countryCode.CountryID, countryCode);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

}

