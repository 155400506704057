<div *ngIf="employee?.ClockStatus === 'OUT'">
	<h1>You are not clocked in</h1>
</div>

<div *ngIf="(employee?.ClockStatus === 'IN' || employee?.ClockStatus === 'ADM') && !freezeClockOut">
	<arc-time-summary [currentDate]="forDate" [currentEmployee]="employee" [pageTitle]=pageTitle (out)="reload($event)"></arc-time-summary>
</div>

<div class="container" *ngIf="freezeClockOut && (employee.ClockStatus === 'IN')">
	<h1>Please Check In All Assigned Assets Before Clock Out.</h1>
</div>