import { ThisReceiver } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { docVersionService } from 'app/_data/pro-docs.service';
import { docVersionDb } from 'app/_models/DOC.model';
import { DocVersionStatusTypes, IDType, LanguageTypes } from 'app/_models/enums.model';
import { LabelForEnumPipe } from 'app/_pipes/label-for-enum.pipe';
import { AlertService, AuthService } from 'app/_services';
import { LookupsService } from 'app/_services/lookups.service';
import { PersistenceService } from 'app/_services/persistence.service';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'arc-versions',
  templateUrl: './versions.component.html',
  styleUrls: ['./versions.component.scss']
})
export class VersionsComponent implements OnInit {

  private _documentID = new BehaviorSubject<number>(0);

  @Input()
  set documentID(value: number) {
    this._documentID.next(value);
  }

  get documentID() {
    return this._documentID.getValue();
  }

  @Output() count = new EventEmitter<any>();

  itemName = 'Versions';
  items: docVersionDb[] = [];

  DocVersionStatusTypes: DocVersionStatusTypes;

  statusList: SelectItem[] = [];

  showArchived = false;

  storedInputFilters = 'ProDocsVersionsFilters';

  localFilter = {
    ShowArchived: null,
  }


  constructor(
    private docVersions: docVersionService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private alert: AlertService,
    private lookups: LookupsService,
    private labelForEnum: LabelForEnumPipe,
    private auth: AuthService,
    private persist: PersistenceService
  ) { }

  ngOnInit(): void {

    const temp = this.persist.get(this.storedInputFilters);
    if (temp) {
      this.localFilter = temp;
      if (this.localFilter.ShowArchived) {
        this.showArchived = this.localFilter.ShowArchived;
      } else {
        this.showArchived = false;
      }
    }

    this.loadStatuses();

    this._documentID.subscribe(() => {
      this.getVersions();
    });
  }

  loadStatuses() {
    this.lookups.documentStatuses.subscribe((data: SelectItem[]) => this.statusList = data);
  }

  getVersions(): void {
    let hasPublished = false;
    this.docVersions.getForDocument(this.documentID)
      .subscribe((data: docVersionDb[]) => {
        this.items = data.filter((d) => this.showArchived || d.StatusID !== DocVersionStatusTypes.Archived);
        this.items.forEach(a => {
          a.LanguageName = this.labelForEnum.transform(a.LanguageCode, LanguageTypes);
          hasPublished = hasPublished || a.IsLive;
        });
        this.count.emit({count: this.items.length, hasPublished: hasPublished });
        console.log('got versions', data);
      });
  }

  goVersion(item: docVersionDb): void {
    this.router.navigate(['/prodocs/version', item.VersionID]);
  }

  newVersion(): void {
    this.router.navigate(['/prodocs/version', IDType.NewRec]);
  }

  confirmDelete(item: docVersionDb) {
    console.log('in confirm Delete');
    if (!this.isOwnerOrAdmin(item.OwnerID)) {
      this.alert.error('Must be the version owner or ProDocs admin to delete this version.');
    } else if (item.StatusName === 'Published') {
      this.alert.error('May not delete a published document.');
    } else {
      this.confirmationService.confirm({
        message: 'Delete "' + item.Description + '"?',
        header: 'Confirm',
        icon: 'fa fa-question-circle',
        accept: () => { this.delete(item.VersionID); }
      });
    }
  }

  delete(id: number) {
    this.docVersions.delete(id)
      .subscribe(() => {
        this.alert.success('Deleted');
        this.getVersions();
      },
        (error) => this.alert.error(error));
  }

  toggleArchived() {
    this.showArchived = !this.showArchived;
    this.localFilter.ShowArchived = this.showArchived;
    this.persist.set(this.storedInputFilters, this.localFilter);
    this.getVersions();
  }

  isOwnerOrAdmin(ownerID: number): boolean {
    if ((ownerID == this.auth.currentUser.UserID) || this.auth.userHasRight('actProDocsAdmin')) {
      return true;
    }
    else {
      return false;
    }

  }
}
