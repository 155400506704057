<div class="lists">
    <p-listbox [options]="Functions" [(ngModel)]="selectedFunction" optionLabel="FunctionName" [checkbox]="true"
        [multiple]="false" [listStyle]="{'height':'450px'}" [style]="{'width':'20rem'}" [metaKeySelection]="false"
        (onClick)="selectFunction(selectedFunction)">
        <p-header>
            FUNCTIONS
        </p-header>
        <ng-template let-function pTemplate="item">
            <div [className]="function.IsBlocked ? 'function-item-blocked' : 'function-item'">
                <div class="func"> <p style="margin: 0;padding:0;">{{function.FunctionCode}}: {{function.FunctionName}}</p> </div>
                <div class="image"><img *ngIf="function.IsBlocked" src="../../../assets/images/lock.svg"></div>
            </div>
        </ng-template>
    </p-listbox>

    <div class="list" *ngIf="Clients.length > 0 && selectedFunction">
        <p-listbox [options]="Clients" [(ngModel)]="selectedClient" optionLabel="ClientName" [checkbox]="true"
            [multiple]="false" [listStyle]="{'height':'450px'}" [style]="{'width':'20rem'}" [metaKeySelection]="false"
            (onClick)="selectClient(selectedClient)">
            <p-header>
                CLIENTS
            </p-header>
            <ng-template let-client pTemplate="item">
                <div class="client-item">
                    <div>{{client.ClientName}}</div>
                </div>
            </ng-template>
        </p-listbox>
    </div>

    <div class="list">
        <p-listbox *ngIf="selectJob && selectedClient && selectedFunction" [options]="jobs" [(ngModel)]="selectedJob"
            optionLabel="ClientFunctionID" [checkbox]="true" [multiple]="false" [liststyle]="{'height':'450px'}"
            [style]="{'width':'20rem'}" [metaKeySelection]="false" (onClick)="assignJob(selectedJob)">
            <p-header>
                CLIENT SUPPLIED REF #
            </p-header>
            <ng-template let-type pTemplate="item">
                <div class="job-item">
                    <div> {{ type.ClientJobReference }} </div>
                </div>
            </ng-template>
        </p-listbox>
    </div>

    <div class="list">
        <p-listbox *ngIf="showBilling && selectedClient" [options]="Billing" [(ngModel)]="isBilling" optionLabel="label"
            [checkbox]="true" [multiple]="false" [listStyle]="{'height':'450px'}" [style]="{'width':'20rem'}"
            [metaKeySelection]="false" (onClick)="selectBillable(isBilling.value)">
            <p-header>
                BILLABLE
            </p-header>
            <ng-template let-billable pTemplate="item">
                <div class="billable-item">
                    <div> {{billable.label}} </div>
                </div>
            </ng-template>
        </p-listbox>
    </div>

</div>

<!-- <div class="list">
    <p-listbox *ngIf="showBilling && selectedClient" [options]="Billing" [(ngModel)]="isBilling" optionLabel="label"
        [checkbox]="true" [multiple]="false" [listStyle]="{'height':'450px'}" [style]="{'width':'20rem'}"
        [metaKeySelection]="false" (onClick)="selectBillable(isBilling.value)">
        <p-header>
            BILLABLE
        </p-header>
        <ng-template let-billable pTemplate="item">
            <div class="billable-item">
                <div> {{billable.label}} </div>
            </div>
        </ng-template>
    </p-listbox>
</div> -->

<p-dialog header="Notes" [(visible)]="viewAdminNote" [style]="{width: '50vw'}" *ngIf="viewAdminNote" appendTo="body">
    <div style="display: flex; justify-content: center;">
        <ol style="font-size: 45px; width: 48vw;">
            <li *ngFor="let note of adminNotes">
                <h1 style="text-align: center; font-size: 45px;"> {{ note }} </h1>
            </li>
        </ol>
    </div>
</p-dialog>