    <!-- show versions -->
            <p-table #dt [value]="items" sortMode="multiple" [paginator]="true" [alwaysShowPaginator]="false"
                [rows]="10">
                <!-- [multiSortMeta]="localFilter.MultiSortMeta"
                (onFilter)="doFilter($event)" (onSort)="onSort($event)" (onPage)="onPage($event)"  -->
                <ng-template pTemplate="caption">
                    {{ itemName }}
                </ng-template>

                <!--Table Header Template-->
                <ng-template pTemplate="header">
                    <!--Column Headers-->
                    <tr>
                        <th style="width:3em"></th>
                        <th pSortableColumn="CreatedDate" [ngStyle]="{ 'width': '10%', 'text-align': 'left' }">
                            Created Date
                            <span>
                                <p-sortIcon field="CreatedDate"></p-sortIcon>
                            </span>
                        </th>
                        <th pSortableColumn="OwnerName" [ngStyle]="{ 'width': '10%', 'text-align': 'left' }">
                            Owner
                            <span>
                                <p-sortIcon field="OwnerName"></p-sortIcon>
                            </span>
                        </th>

                        <th pSortableColumn="Description" [ngStyle]="{ 'width': '35%', 'text-align': 'left' }">
                            Description
                            <span>
                                <p-sortIcon field="Description"></p-sortIcon>
                            </span>
                        </th>
                        <th pSortableColumn="LanguageCode" [ngStyle]="{ 'width': '10%', 'text-align': 'left' }">
                            Language
                            <span>
                                <p-sortIcon field="LanguageCode"></p-sortIcon>
                            </span>
                        </th>
                        <th pSortableColumn="Number" [ngStyle]="{ 'width': '7%', 'text-align': 'left' }">
                            Version #
                            <span>
                                <p-sortIcon field="Number"></p-sortIcon>
                            </span>
                        </th>
                        <th pSortableColumn="PublicationDate" [ngStyle]="{ 'width': '10%', 'text-align': 'left' }">
                            Publication Date
                            <span>
                                <p-sortIcon field="PublicationDate"></p-sortIcon>
                            </span>
                        </th>
                        <th pSortableColumn="ExpirationDate" [ngStyle]="{ 'width': '10%', 'text-align': 'left' }">
                            Review Due Date
                            <span>
                                <p-sortIcon field="ExpirationDate"></p-sortIcon>
                            </span>
                        </th>
                        <th pSortableColumn="StatusID" [ngStyle]="{ 'width': '15%', 'text-align': 'left' }">
                            Status
                            <span>
                                <p-sortIcon field="StatusID"></p-sortIcon>
                            </span>
                        </th>
                        <th style="width:3em"></th>
                    </tr>
                </ng-template>

                <!--Table Body Template-->
                <ng-template pTemplate="body" let-rowData let-item>
                    <!--Column Rows-->
                    <tr [pSelectableRow]="rowData">
                        <td>
                            <p-button icon="fa fa-edit" (onClick)="goVersion(rowData)"></p-button>
                        </td>
                        <td>
                            {{ item.CreatedDate | date: 'mediumDate' }}
                        </td>
                        <td>
                            {{ item.OwnerName }}
                        </td>
                        <td>
                            {{ item.Description }}
                        </td>
                        <td>
                            {{ item.LanguageName }}
                        </td>
                        <td>
                            {{ item.MajorNum }}.{{ item.MinorNum }}.{{ item.PatchNum }}
                        </td>
                        <td>
                            {{ item.PublicationDate | date: 'mediumDate' }}
                        </td>
                        <td>
                            {{ item.ExpirationDate | date: 'mediumDate' }}
                        </td>
                        <td>
                            {{ item.StatusID | labelForValue: statusList }}
                        </td>
                        <td>
                            <p-button icon="fa fa-trash-o" (onClick)="confirmDelete(rowData)"></p-button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    <div style="display:flex; justify-content:space-between;">
                        <p>Total: {{dt.totalRecords}} </p>
                        <p-button icon="pi pi-ban" label="Hide Archived" [hidden]="!showArchived" (click)="toggleArchived()"></p-button>
                        <p-button icon="pi pi-star" label="Show Archived" [hidden]="showArchived" (click)="toggleArchived()"></p-button>
                    </div>
                </ng-template>

                <!--Table Footer Template-->
                <!-- <ng-template pTemplate="summary" style="height: 48px">
                <span style="float:left; width: 150px;">
                    <p-button icon="fa fa-plus" [ngStyle]="{'float':'left'}" (onClick)="addItem()" label="Add"></p-button>
                </span>
                Count: {{ dt.totalRecords | number}}
            </ng-template> -->
            </p-table>

