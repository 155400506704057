import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { docActorService } from 'app/_data/pro-docs.service';
import { docActorDb } from 'app/_models/DOC.model';
import { DocActorRoleTypes, DocVersionStatusTypes } from 'app/_models/enums.model';
import { AlertService, AuthService } from 'app/_services';
import { ConfirmationService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'arc-actors',
  templateUrl: './actors.component.html',
  styleUrls: ['./actors.component.scss']
})
export class ActorsComponent implements OnInit {

  private _versionID = new BehaviorSubject<number>(null);
  private _ownerID = new BehaviorSubject<number>(null);
  @Input()
  set versionID(value: number) {
    this._versionID.next(value);
  }
  get versionID() {
    return this._versionID.getValue();
  }
  @Input()
  set ownerID(value: number) {
    this._ownerID.next(value);
  }
  get ownerID() {
    return this._ownerID.getValue();
  }

  @Output() reload = new EventEmitter();
  @Output() sendActors = new EventEmitter<docActorDb[]>();
  @Input() personID = 0;
  @Input() versionStatus = 0;
  includeCompleted: boolean = true;
  showEditDialog = false;

  selectedActorID = 0;
  amActor: docActorDb = null;
  userID: number = null;
  canCompleteToggle: boolean = false;

  actors: docActorDb[] = [];

  canUseTooltip: string = null;
  versionStatusTypes = DocVersionStatusTypes;
  roleTypes = DocActorRoleTypes;

  constructor(
    private actorService: docActorService,
    private confirmationService: ConfirmationService,
    private alert: AlertService,
    private auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.userID = +this.auth.currentUser.UserID;
    this._versionID.subscribe(() => {
      this.isArchivedOrPublished();
      this.getActors();
    })
  }

  getActors(): void {
    this.actorService.getFiltered(this.versionID, this.personID, this.includeCompleted)
      .subscribe((data: docActorDb[]) => {
        this.actors = data;
        this.actors.forEach(a => {
          if (a.PersonID === this.userID) {
            this.amActor = a;
          }
          a.CanComplete = this.canComplete(a);
        });
        this.sendActors.emit(this.actors);
        console.log('Got Actors for Actor Comp', this.actors);
      });
  }

  editItem(item: docActorDb) {
    if (this.isArchivedOrPublished() && item.RoleID !== this.roleTypes.QA_Reviewer) {
      this.alert.error(this.canUseTooltip);
      return;
    }
    this.selectedActorID = item.ActorID;
    this.showEditDialog = true;
    console.log('editing', item);
  }

  confirmDelete(item: docActorDb) {
    if (this.isArchivedOrPublished() && item.RoleID !== this.roleTypes.QA_Reviewer) {
      this.alert.error(this.canUseTooltip);
      return;
    }
    this.confirmationService.confirm({
      message: 'Delete "' + item.PersonName + ':' + item.RoleName + '?',
      header: 'Confirm',
      icon: 'fa fa-question-circle',
      accept: () => { this.delete(item.ActorID); }
    });
  }

  delete(id: number) {
    this.actorService.delete(id)
      .subscribe(() => {
        this.alert.success('Deleted');
        this.getActors();
        this.reload.emit();
      },
        (error) => this.alert.error(error));
  }

  addNew() {
    if (this.versionStatus === this.versionStatusTypes.Archived) {
      this.canUseTooltip = 'Version Archived';
      this.alert.error(this.canUseTooltip);
      return;
    }
    this.selectedActorID = -1;
    this.showEditDialog = true;
  }

  toggleIncludeCompleted() {
    this.includeCompleted = !this.includeCompleted;
    this.getActors();
  }

  editComplete(event: number) {
    console.log('got back ', event);
    this.showEditDialog = false;
    this.getActors();
    this.reload.emit();
  }

  toggleComplete(actor: docActorDb) {
    if (this.isArchivedOrPublished() && actor.RoleID !== this.roleTypes.QA_Reviewer) {
      this.alert.error(this.canUseTooltip);
      return;
    }
    this.actorService.markComplete(actor.ActorID, actor.ActionCompletedDate ? false : true).subscribe({
      next: () => {
        this.getActors();
        this.reload.emit();
      }, error: (err) => {
        console.log(err);
        this.alert.error('Error Marking Complete.')
      }
    });
  }

  canComplete(action: docActorDb): boolean {
    if (this.isOwnerOrAdmin()) {
      return true;
    } else if (action.PersonID === this.userID) {
      return true;
    } else { return false; }
  }

  isOwnerOrAdmin(): boolean {
    if (this.auth.userHasRight('actProDocsAdmin') || this.ownerID === this.userID) {
      return true;
    } else { return false; }
  }

  isArchivedOrPublished(): boolean {
    this.canUseTooltip = null;
    if (this.versionStatus === this.versionStatusTypes.Published) {
      this.canUseTooltip = 'Version Published'
      return true;
    }
    if (this.versionStatus === this.versionStatusTypes.Archived) {
      this.canUseTooltip = 'Version Archived'
      return true;
    } else {
      return false;
    }
  }
}
