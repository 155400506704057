  import { Injectable, Inject } from '@angular/core';
  // // import { server } from '../_models/index';
  import { secGroupType } from '../_models/SEC.model';
  import { HttpClient } from '@angular/common/http';

  @Injectable()
  export class GroupTypesService {

    api = 'secGroupType/';

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    getAll() {
      return this.http.get<secGroupType[]>(this.apiURL + this.api);

    }
    create(item: secGroupType) {
      return this.http.post(this.apiURL + this.api, item);
    }

    update(item: secGroupType) {
      return this.http
        .put(this.apiURL + this.api + item.GroupTypeID, item);
    }

    delete(id: number) {
      return this.http.delete(this.apiURL + this.api + id);
    }

  }


