import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { docAssignmentService, docDocumentService } from 'app/_data/pro-docs.service';
import { timeEmployeeService } from 'app/_data/time.service';
import { UpsertRet } from 'app/_models/API.model';
import { docAssignmentDb, docDocumentDb } from 'app/_models/DOC.model';
import { IDType } from 'app/_models/enums.model';
import { timeEmployee } from 'app/_models/TIME.model';
import { AlertService, AuthService } from 'app/_services';
import { NotifierService } from 'app/_sharedData/notifier.service';
import { addDays } from 'date-fns';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'arc-assignment-edit',
  templateUrl: './assignment-edit.component.html',
  styleUrls: ['./assignment-edit.component.scss']
})
export class AssignmentEditComponent implements OnInit {

  @Input() assignmentID = -1;
  @Input() documentID = -1;
  @Input() personID = -1;

  @Input() reassign?: boolean = false;

  @Output() editComplete = new EventEmitter<number>();

  assignment: docAssignmentDb
  newItem = false;

  employeeList: timeEmployee[] = [];
  documentList: docDocumentDb[] = [];

  person: timeEmployee;
  document: docDocumentDb;

  busy = false;

  constructor(
    private assignService: docAssignmentService,
    private empService: timeEmployeeService,
    private docService: docDocumentService,
    private auth: AuthService,
    private alert: AlertService,
    private notifier: NotifierService
  ) { }

  ngOnInit(): void {

    console.log('assignmentEdit', this.assignmentID, this.documentID, this.personID);

    // if the documentID > 0, then were assigning a new person to read a document
    //  so get the list of employees
    // then, get employees and documents to pick from; otherwise, we already know
    this.empService.getAllEx(true)
      .subscribe((ret: timeEmployee[]) => {
        this.employeeList = ret
          .sort((a, b) => a.FirstName > b.FirstName ? 1 : a.FirstName < b.FirstName ? -1 : a.LastName < b.LastName ? -1 : 1)
        this.employeeList.forEach((e) => e.LastName = e.FirstName + ' ' + e.LastName);

      });
    this.docService.getAllPublished()
      .subscribe((ret: docDocumentDb[]) => {
        this.documentList = ret.sort((a, b) => a.Title > b.Title ? 1 : -1);
        console.log('got documents', this.documentList);
        // if (this.documentID > 0) this.document = this.documentList.find((d) => d.DocumentID == this.documentID);
      });

    if (this.personID > 0) this.loadPerson(this.personID);

    if (this.documentID > 0) this.loadDocument(this.documentID);


    // get appropriate 
    if (this.assignmentID > 0) {
      this.loadAssignment();
    } else {
      this.initAssignment();
    }
  }

  loadAssignment() {
    this.assignService.getByID(this.assignmentID)
      .subscribe((ret: docAssignmentDb) => {
        this.assignment = ret;
        if (this.assignment.SoftDueDate) this.assignment.SoftDueDate = new Date(this.assignment.SoftDueDate);
        if (this.assignment.HardDueDate) this.assignment.HardDueDate = new Date(this.assignment.HardDueDate);
        if (this.assignment.CompletedDate) this.assignment.CompletedDate = new Date(this.assignment.CompletedDate);
        if (this.assignment.CancelledDate) this.assignment.CancelledDate = new Date(this.assignment.CancelledDate);
        console.log('got assignment', this.assignment);

        // this.loadPerson(this.assignment.PersonID);
        // this.loadDocument(this.assignment.DocumentID);
      });
  }

  loadPerson(personID: number) {
    this.empService.getEx(personID)
      .subscribe((data: timeEmployee) => this.person = data);
  }

  loadDocument(documentID: number) {
    this.docService.getByID(documentID)
      .subscribe((data: docDocumentDb) => this.document = data);
  }

  initAssignment() {
    this.assignment = new docAssignmentDb();
    if (this.documentID > 0) this.assignment.DocumentID = this.documentID;
    if (this.personID > 0) this.assignment.PersonID = this.personID;

    this.assignment.AssignedDate = new Date();
    this.assignment.AssignedBy = this.auth.currentUser.UserID;

    this.assignment.HardDueDate = addDays(new Date(), 10);
    this.assignment.SoftDueDate = addDays(new Date(), 5);

    this.assignment.AssignmentID = IDType.NewRec;

    this.newItem = true;
  }

  validateAssignment(): boolean {
    let isValid = true;

    if ((this.assignment.HardDueDate) && (this.assignment.SoftDueDate)) {
      if (this.assignment.SoftDueDate > this.assignment.HardDueDate) {
        this.alert.warn("Hard Due Date must be on or after Soft Due Date.")
        isValid = false;
      }
    }
    return isValid;

  }
  saveAssignment() {
    if (this.busy) return;
    if (this.validateAssignment()) {
      if (this.assignment.AssignmentID > 0) {
        this.busy = true;
        this.assignService.update(this.assignment)
          .pipe(finalize(() => {
            this.busy = false;
          })
          )
          .subscribe((ret: UpsertRet) => {
            this.assignment = ret.returnItem;
            this.alert.success('Assignment updated.');
            this.editComplete.emit(this.assignment.AssignmentID);
          },
            (error) => this.alert.error(error)
          );
      } else {
        this.busy = true;
        this.assignService.create(this.assignment)
          .pipe(finalize(() => {
            this.busy = false;
          })
          )
          .subscribe((ret: UpsertRet) => {
            this.assignment = ret.returnItem;
            this.alert.success('Assignment created.');
            this.assignmentID = this.assignment.AssignmentID;
            this.editComplete.emit(this.assignment.AssignmentID);
          },
            (error) => this.alert.error(error)
          );
      }
    }
  }

  toggleDialog() {
    this.editComplete.emit(-1);
  }

  reassignAssignment() {
    if (this.busy) return;
    this.notifier.loadingOn();
    this.busy = true;
    this.assignService.reassign(this.assignment)
      .pipe(finalize(() => {
        this.busy = false;
        this.notifier.loadingOff();
      }),
      )
      .subscribe((ret: UpsertRet) => {
        this.assignment = ret.returnItem;
        this.alert.success('Document reassigned.');
        this.assignmentID = this.assignment.AssignmentID;
        this.editComplete.emit(this.assignment.AssignmentID);
      },
        (error) => this.alert.error(error)
      );

  }
}
