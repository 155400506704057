import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import { utlStateProvinceCode } from '../_models/UTL.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class StateProvinceCodesService {

  api = 'utlStateProvinceCode/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<utlStateProvinceCode[]>(this.apiURL + this.api);

  }

  getForCountry(countryCode: string) {
    return this.http.get<utlStateProvinceCode[]>(this.apiURL + this.api + 'GetForCountry/' + countryCode);
  }

  create(stateProvinceCode: utlStateProvinceCode) {
    return this.http.post(this.apiURL + this.api, stateProvinceCode);
  }

  update(stateProvinceCode: utlStateProvinceCode) {
    return this.http
      .put(this.apiURL + this.api + stateProvinceCode.StateProvinceID, stateProvinceCode);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

}
