import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

// import { server } from '../_models';
import { orgPerson, orgPersonVw } from '../_models/ORG.model';

import { OrgItemType } from '../_models/enums.model';
import { orgLinkedPerson } from '../_models/TableCustom.model';

@Injectable()
export class PersonsService {
  api = 'orgPerson/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAllForItem(itemType: OrgItemType, itemID: number) {
    return this.http.get<orgPerson[]>(this.apiURL + this.api + itemType + '/' + itemID);
  }

  getByID(itemID: number) {
    return this.http.get<orgPerson>(this.apiURL + this.api + itemID);
  }

  getAll() {
    return this.http.get<orgPerson[]>(this.apiURL + this.api);
  }

  update(item: orgPerson) {
    return this.http.put(this.apiURL + this.api + item.PersonID, item);
  }

  create(item: orgPerson) {
    return this.http.post(this.apiURL + this.api, item);
  }

  delete(itemID: number) {
    return this.http.delete(this.apiURL + this.api + itemID);
  }
}

@Injectable()
export class PersonExService {
  api = 'orgPersonEx/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

}

@Injectable()
export class LinkedPersonService {
  api = 'orgLinkedPerson/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) {}

  getAllForItem(itemType: OrgItemType, linkItemID: number) {
    return this.http.get<orgLinkedPerson[]>(this.apiURL + this.api + itemType + '/' + linkItemID);
  }

  delete(linkType: OrgItemType, linkID: number) {
    return this.http.delete(this.apiURL + this.api + linkType + '/' + linkID);
  }

  save(item: orgLinkedPerson) {
    console.log('going to save person', item);
    return this.http.post(this.apiURL + this.api, item);
  }

  getByLinkID(linkType: OrgItemType, linkID: number) {
    console.log('getbylinkid', linkType, linkID);
    return this.http.get<orgLinkedPerson>(this.apiURL + this.api + 'person/' + linkType + '/' + linkID);
  }

  createLink(personID: number, itemType: OrgItemType, itemID: number) {
    return this.http.post(this.apiURL + this.api + 'link/' + personID + '/' + itemType + '/' + itemID, null );
  }

}

@Injectable()
export class PersonVwService {
  api = 'orgPersonVw/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) {}

  getAllForItem(itemType: OrgItemType, itemID: number) {
    return this.http.get<orgPersonVw[]>(this.apiURL + this.api + itemType + '/' + itemID);
  }

  getAll() {
    return this.http.get<orgPersonVw[]>(this.apiURL + this.api);
  }

  getByID(itemID: number) {
    return this.http.get<orgPersonVw>(this.apiURL + this.api + itemID);
  }

  delete(itemID: number) {
    return this.http.delete(this.apiURL + this.api + itemID);
  }

}
