import { Pipe, PipeTransform } from '@angular/core';
import { startCase } from 'lodash-es';

@Pipe({
  name: 'labelForEnum'
})
export class LabelForEnumPipe implements PipeTransform {

  transform(value: any, enumList: any): any {
    if (!enumList) {
      return value;
    }
    if (value !== 0 && !value) {
      return null;
    }

    if (typeof (value) === 'string') {
      // the following is slow, but it works, and is the only way (I've found so far) that works
      let ret = '';
      for (const key in enumList) {
        if (value === enumList[key]) {
          ret = key;
          break;
        }
      }
      return startCase(ret);
    } else {
      return startCase(enumList[value]);
    }
  }

}
