import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { IDType } from '../_models/enums.model';
import { map } from 'rxjs/operators';

// // import { server } from '../_models/index';
import { ordOrderFreightVw } from '../_models/DBTables.model';
import { ordFreightLegEx, ordOrderFreightEx, ordFreightHandlingEx, ordOrderEx } from '../_models/ORD.model';
import { BilledStatus } from '../_models/enums.model';
import { UpsertRet } from '../_models/API.model';
import { NoteDocTaskCount } from '../_models/UTL.model';

@Injectable()
export class OrdersFreightService {

  api = 'ordOrderFreightVw/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll(OrderIDList: string, StatusList: string[]) {
    let paramList = new HttpParams();
    if (OrderIDList) {
      paramList = paramList.append('idList', OrderIDList);
    }
    if (StatusList) {
      const sList = StatusList.join(',');
      paramList = paramList.append('statusList', sList);
    }
    return this.http.get<ordOrderFreightVw[]>(this.apiURL + this.api + 'filtered', { params: paramList });

  }

  getAllFiltered(currentStatuses: string[], triggerStatuses: string[]) {
    const exceptStatuses: string[] = [];
    // are any of my trigger statuses in the currently selected status list?
    // if NOT, add them to the except list
    // if they ARE, we do NOT want to except them
    triggerStatuses.forEach(s => {
      if (currentStatuses.indexOf(s) < 0) {
        exceptStatuses.push(s);
      }
    });

    if (exceptStatuses.length === 0) { // it's found
      return this.http.get<ordOrderFreightVw[]>(this.apiURL + this.api); // so get all
    } else {  // else, not found,
      return this.http.get<ordOrderFreightVw[]>(this.apiURL + this.api + 'except/' + exceptStatuses.join(',')); // so get all EXCEPT the trigger status
    }
  }

  getForClient(clientID: number) {
    return this.http.get<ordOrderFreightVw[]>(this.apiURL + this.api + 'client/' + clientID);
  }
}

@Injectable()
export class OrdersFreightLegService {
  api = 'ordFreightLeg/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getNew(displayType: string) {
    return this.http.get<ordFreightLegEx>(this.apiURL + this.api + 'new/' + displayType);
  }

  getLeg(legID: number) {
    return this.http.get<ordFreightLegEx>(this.apiURL + this.api + legID);
  }

  save(item: ordFreightLegEx) {
    return this.http.put<ordFreightLegEx>(this.apiURL + this.api, item);
  }

  delete(legID: number) {
    return this.http.delete(this.apiURL + this.api + legID);
  }
  getForOrder(orderID: number, displayType: string) {
    return this.http.get<ordFreightLegEx[]>(this.apiURL + this.api + 'forOrder/' + orderID + '/' + displayType);
  }
}

@Injectable()
export class OrdersFreightExService {

  api = 'ordOrderFreightEx/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getOrder(orderID: number, type: string = 'WO') {
    // return this.http.get<ordOrderFreightEx>(this.apiURL + this.api + orderID).pipe(
    //   map(data => ({ ...new ordOrderFreightEx(), ...data }))
    // );
    if (orderID === IDType.NewRec) {
      return this.http.get<ordOrderFreightEx>(this.apiURL + this.api + 'new/' + type);
    } else {
      return this.http.get<ordOrderFreightEx>(this.apiURL + this.api + orderID);
    }
  }

  getOrderForWorkOrder(WONumber: number) {
    return this.http.get<number>(this.apiURL + this.api + 'OrderID4WONumber/' + WONumber);
  }

  saveOrder(item: ordOrderFreightEx) {
    return this.http.put<UpsertRet>(this.apiURL + this.api, item);
  }

  getSummaryStatsForOrder(orderID: number) {
    return this.http.get(this.apiURL + this.api + 'summaryStats/' + orderID);
  }

  getTaskNoteDocCounts(orderID: number) {
    return this.http.get<NoteDocTaskCount>(this.apiURL + this.api + 'taskNoteDocCounts/' + orderID);
  }

  // setBillingStatus(orderID: number, newStatus: BillingStatus) {
  //   return this.http.put(this.apiURL + this.api + 'billingStatus/' + orderID + '/' + newStatus, null);
  // }
}

@Injectable()
export class OrderBaseService {

  api = 'ordOrderEx/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getOrder(orderID: number) {
    return this.http.get<ordOrderEx>(this.apiURL + this.api + orderID);
  }

  saveOrder(item: ordOrderEx) {
    console.log('in saveOrder', item);
    return this.http.put<UpsertRet>(this.apiURL + this.api, item);
  }
}

@Injectable()
export class OrdersFreightHandlingService {
  api = 'ordFreightHandlingEx/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getNew(type: string) {
    return this.http.get<ordFreightHandlingEx>(this.apiURL + this.api + 'new/' + type);
  }

  get(handlingID: number) {
    return this.http.get<ordFreightHandlingEx>(this.apiURL + this.api + handlingID);
  }

  getForOrder(orderID: number, dataVersion: string) {
    return this.http.get<ordFreightHandlingEx>(this.apiURL + this.api + 'order/' + orderID + '/' + dataVersion);
  }

  update(item: ordFreightHandlingEx) {
    return this.http.put<ordFreightHandlingEx>(this.apiURL + this.api, item);
  }

  delete(handlingID: number) {
    return this.http.delete<ordFreightHandlingEx>(this.apiURL + 'ordFreightHandling/' + handlingID);
  }

}

// @Injectable()
// export class OrdersFreightWONumberService {
//   api = 'ordWONumber';

//   constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

//   getNext() {
//     return this.http.get<number>(this.apiURL + this.api);
//   }
// }

// @Injectable()
// export class OrdersFreightQuoteNumberService {
//   api = 'ordWONumber';

//   constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

//   getNext() {
//     return this.http.get<number>(this.apiURL + this.api);
//   }
// }
