import { Injectable, OnInit, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NoteDocTaskCount } from '../_models/UTL.model';
import { OrdersFreightExService } from '../_data/orders-freight.service';
import { r3JitTypeSourceSpan } from '@angular/compiler';
import { AlertService } from 'app/_services';

@Injectable({
  providedIn: 'root'
})


export class NotifierService implements OnInit {

  //  4/16/2021 RJS Now handling multiple requests for spinner in such a way that ending the first doesn't end the second
  //  We keep a count of requests; increment when spinner requested, decrement when done. When it's ZERO, turn off.
  loadingCount = 0;
//   loading = false;

  noteDocTaskCount: NoteDocTaskCount = null;

  private noteDocTaskNotifier = new BehaviorSubject<NoteDocTaskCount>(this.noteDocTaskCount);
  NoteDocTaskNotifier$: Observable<NoteDocTaskCount> = this.noteDocTaskNotifier.asObservable();

  // KEEP THIS!!
  public refreshNotes = new BehaviorSubject<boolean>(false);
  //

  private spinnerNotifier = new BehaviorSubject<boolean>(false); // this.loading);
  SpinnerNotifier$: Observable<boolean> = this.spinnerNotifier.asObservable();

  constructor(
    private ordService: OrdersFreightExService,
    private alert: AlertService
    // private cdRef: ChangeDetectorRef

  ) { }

  ngOnInit() {
    // this.cdRef.detectChanges();

    this.noteDocTaskCount = {
      DocCount: 0,
      NoteCount: 0,
      TaskCount: 0,
      ClientNoteCount: 0,
      NewDocCount: 0
    };
  }

  refreshTaskNoteDocCount(orderID: number) {

    this.ordService.getTaskNoteDocCounts(orderID)
      .subscribe(
        (data: NoteDocTaskCount) => {
          console.log('got notifier notetaskdocccount', data);
        this.noteDocTaskCount = data;
        this.noteDocTaskNotifier.next(this.noteDocTaskCount);
        },
        (error) => this.alert.error(error)
      );
  }

  loadingOn() {
    setTimeout(() => {
      this.loadingCount++;
      if (this.loadingCount > 0) {
        // this.loading = true;
        // console.log('loading is ON');
        this.spinnerNotifier.next(true); // this.loading);
      }
    });
  }
  loadingOff() {
    setTimeout(() => {
      this.loadingCount--;
      if (this.loadingCount <= 0) {
        // this.loading = false;
        // console.log('loading is OFF');
        this.spinnerNotifier.next(false); // this.loading);
      }
    });
  }
}


