import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { secUserValidations } from 'app/_models/SEC.model';

@Component({
  selector: 'arc-employee-stop',
  templateUrl: './employee-stop.component.html',
  styleUrls: ['./employee-stop.component.scss']
})
export class EmployeeStopComponent implements OnInit {

  @Input() selectedValidation: secUserValidations;
  @Output() acknowledgeNotes = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  acknowledge() {
    this.acknowledgeNotes.emit();
  }

}
