import { SelectItem } from 'primeng/api';
import { secGroup } from './SEC.model';

export class rptReport {
    ReportID: number;
    SSRSName: string;
    Name: string;
    Description: string;
    CategoryID: number;
    ClientCategoryID: number;
    VisibleToClient: boolean;
    IsActive: boolean;
    CreatedBy: number;
    CreatedDate: Date;
    UpdatedBy: number;
    UpdatedDate: Date;
}

export class rptReportEx extends rptReport {
    CreatedByName: string;
    ExecCount: number;
    Parameters: rptReportParameterEx[];
    Groups: secGroup[];
}

/// <summary>
/// A class which represents the rpt.ReportCategory table.
/// </summary>
export class rptReportCategory {
    CategoryID: number;
    Name: string;
    Description: string;
    DisplayOrder: number;
    CategoryTypeID: number;
    IsActive: boolean;
    CreatedBy: number;
    CreatedDate: Date;
    UpdatedBy: number;
    UpdatedDate: Date;
}

export class rptReportCategoryEx extends rptReportCategory {
    UseCount: number;
}

/// <summary>
/// A class which represents the rpt.ReportParameter table.
/// </summary>
export class rptReportParameterDb {
    ParameterID: number;
    ReportID: number;
    SSRSName: string;
    Name: string;
    Description: string;
    ParameterTypeID: number;
    ParameterSourceID: number;
    IsClientID: boolean;
    AllowNull: boolean;
    DoNotPass: boolean;
    ClientOmit: boolean;
    OptionsID: number;
    DependentOnParameterIDs: string;
    DisplayOrder: number;
    CreatedBy: number;
    CreatedDate: Date;
    UpdatedBy: number;
    UpdatedDate: Date;
}

export class rptReportParameterEx extends rptReportParameterDb {
    Options: rptReportParameterOptionEx;
    ParameterValue: any;
    ParameterDisplayValue: string;
    Dependents: rptReportParameterEx[]; // for use in execution
}

/// <summary>
/// A class which represents the rpt.ReportParameterDependentOn table.
/// </summary>
export class rptReportParameterDependentOnDb {
    ParameterDependentOnID: number;
    ParameterID: number;
    DependentOnParameterID: number;
    DependentOnOrder: number;
    CreatedBy: number;
    CreatedDate: Date;
    UpdatedBy: number;
    UpdatedDate: Date;
}

/// <summary>
/// A class which represents the rpt.ReportParameterOptions table.
/// </summary>
export class rptReportParameterOptionDb {
    OptionsID: number;
    OptionsName: string;
    OptionsDescription: string;
    SQLCode: string;
    OptionsValues: string;
    CreatedBy: number;
    CreatedDate: Date;
    UpdatedBy: number;
    UpdatedDate: Date;
}

export class rptReportParameterOptionEx extends rptReportParameterOptionDb {
    UseCount: number;
    SelectOptions: SelectItem[];
}

/// <summary>
/// A class which represents the rpt.ReportParameterType table.
/// </summary>
export class rptReportParameterTypeDb {
    ParameterTypeID: number;
    Name: string;
    Description: string;
    CreatedBy: number;
    CreatedDate: Date;
    UpdatedBy: number;
    UpdatedDate: Date;
}

/// <summary>
/// A class which represents the rpt.ReportViewHistory table.
/// </summary>
export class rptReportViewHistoryDb {
    ViewHistoryID: number;
    ReportID: number;
    UserID: number;
    DateViewed: Date;
    Parameters: string;
    ReportName: string;
    OutputFormat: string;
}
