import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { reportParam, reportRequest } from '../_models/UTL.model';


@Injectable()
export class ReportingService {
  api = 'Reporting/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getReportToFormat(reportName: string, params: reportParam[], format: string) {
    const req = new reportRequest();
    req.ReportName = reportName;
    req.paramList = params;
    req.format = format;

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // let hparams = new HttpParams();
    // hparams = hparams.append('reportRequest', JSON.stringify(req));
    return this.http.post(this.apiURL + this.api, req, { responseType: 'blob' });

  }

}

