import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import {cfgPayableType } from '../_models/CFG.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PayableTypesService {

  api = 'cfgPayableType/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<cfgPayableType[]>(this.apiURL + this.api);

  }
  create(payableType: cfgPayableType) {
    return this.http.post(this.apiURL + this.api, payableType);
  }

  update(payableType: cfgPayableType) {
    return this.http
      .put(this.apiURL + this.api + payableType.PayableTypeID, payableType);
  }

  delete (id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

}
