import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import { cfgNoteType } from '../_models/CFG.model';

import { HttpClient } from '@angular/common/http';

@Injectable()
export class NoteTypesService {


  api = 'cfgNoteType/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<cfgNoteType[]>(this.apiURL + this.api);

  }
  create(noteType: cfgNoteType) {
    return this.http.post(this.apiURL + this.api, noteType);
  }

  update(noteType: cfgNoteType) {
    return this.http
      .put(this.apiURL + this.api + noteType.NoteTypeID, noteType);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

}

