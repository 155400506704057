import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import { cfgPaymentTerm } from '../_models/CFG.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PaymentTermsService {



  api = 'cfgPaymentTerm/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<cfgPaymentTerm[]>(this.apiURL + this.api);

  }
  create(paymentTerm: cfgPaymentTerm) {
    return this.http.post(this.apiURL + this.api, paymentTerm);
  }

  update(paymentTerm: cfgPaymentTerm) {
    return this.http
      .put(this.apiURL + this.api + paymentTerm.PaymentTermsID, paymentTerm);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

}
