import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { docCategoryService, docDocumentService } from 'app/_data/pro-docs.service';
import { docCategoryDb, docDocumentEx } from 'app/_models/DOC.model';
import { AlertService, AuthService } from 'app/_services';
import { PersistenceService } from 'app/_services/persistence.service';
import { Table } from 'primeng/table';

@Component({
  selector: 'document-library',
  templateUrl: './document-library.component.html',
  styleUrls: ['./document-library.component.scss']
})
export class DocumentLibraryComponent implements OnInit {

  // ** TOGGLES ** \\
  notFound: boolean = false;
  viewingDoc: boolean = false;
  gotURL: boolean = false;

  // ** DATA ** \\
  searchText: string = '';
  documents: docDocumentEx[] = [];
  filteredDocs: docDocumentEx[] = [];
  cats: docCategoryDb[] = [];
  selectedCategories: docCategoryDb[] = [];
  cleanURL: SafeResourceUrl = null;

  // ** MISC ** \\
  @ViewChild('docSearch', { static: false })
  set docSearch(element: ElementRef<HTMLInputElement>) {
    if (element) {
      element.nativeElement.focus();
    }
  }

  // ** DATA TABLE ** \\
  // for table mode
  @ViewChild('dt', { static: true }) mydt: Table;

  // sorting
  multiSortMetaDefault = [{ field: 'DocumentName', order: 1 }]; // , {field: 'PlaceHolder', order: 1}, {field: 'PlaceHolder', order: 1}];

  //#region FilterNames
  storedDTFilters = 'DocListDTFilters';
  storedInputFilters = 'DocListInputFilters';
  //#endregion

  localFilter = {
    Title: null,
    Description: null,
    MultiSortMeta: this.multiSortMetaDefault,
    rowsPerPage: +this.authService.currentUser.opts.PaginatorRows,
    currentRow: 0
  };

  constructor(
    private docService: docDocumentService,
    private categories: docCategoryService,
    private persist: PersistenceService,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    private alert: AlertService,
  ) { }

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories() {
    this.categories.getAll().subscribe({
      next: (data: docCategoryDb[]) => {
        this.cats = data;
        console.log(this.cats)
      }
    });
  }

  searchForDocuments() {
    var el = document.getElementById('search');
    el.classList.remove('error');
    this.docService.searchLibrary(this.searchText).subscribe({
      next: (data: docDocumentEx[]) => {
        this.searchText = '';
        this.selectedCategories = [];
        console.log('DOC-LIBRARY DOCS:', data);
        this.documents = data.sort((a, b) => a.Title < b.Title ? -1 : 1);
        this.filteredDocs = data.sort((a, b) => a.Title < b.Title ? -1 : 1);
        if (data.length === 0) {
          // this.notFound = true;
          el.classList.add('error');
          document.getElementById('searchText').focus();

          this.alert.warn('No Documents Found Matching Search')


        } else {
          this.notFound = false;
        }
      }, error: (err) => {
        console.log(err);
        this.alert.warn(err);
        this.notFound = true;
      }
    })
  }

  viewDocument(doc: docDocumentEx) {
    this.docService.getForDocument(doc.DocumentID)
      .subscribe({
        next: (data: Blob) => {
          this.viewingDoc = true;
          this.gotURL = false;
          const theFile = new Blob([data], { type: 'application/pdf' });
          const rawURL = window.URL.createObjectURL(theFile);

          this.cleanURL = this.sanitizer.bypassSecurityTrustResourceUrl(rawURL);
          this.gotURL = true;
        },
        error: (error) => {
          console.log('Error Gathering Document Content: ' + error);
          this.alert.warn(error);
        }
      });
  }

  filterSearch() {
    if (this.selectedCategories.length === 0) {
      this.filteredDocs = this.documents;
      console.log(this.filteredDocs)
      return;
    }
    this.filteredDocs = this.documents.filter(d => {
      return this.selectedCategories.some(c => c.CategoryID === d.CategoryID)
    })
    console.log(this.filteredDocs)
  }

  goBack() {
    this.viewingDoc = false;
    this.gotURL = false;
    this.searchText = '';
    this.documents = [];
  }

  backToList() {
    this.viewingDoc = false;
    this.gotURL = false;
  }

  onSort(event) {
    this.localFilter.MultiSortMeta = event.multisortmeta;
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  onPage(event) {
    this.localFilter.rowsPerPage = event.rows;
    this.localFilter.currentRow = event.first;
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  doFilter(event) {
    // and store the filters for later recall
    this.persist.set(this.storedDTFilters, event.filters);
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  // clearLocalFilter(dt: Table) {
  //   this.localFilter.Title = null;
  //   this.localFilter.Description = null;
  //   this.localFilter.MultiSortMeta = this.multiSortMetaDefault;
  //   this.localFilter.rowsPerPage = +this.authService.currentUser.opts.PaginatorRows;
  //   this.localFilter.currentRow = 0;

  //   dt.clear();
  //   dt.filters = {};

  //   this.persist.set(this.storedDTFilters, null);
  //   this.persist.set(this.storedInputFilters, this.localFilter);

  //   // this.loadAll();

  // }
}
