<div style="display: flex; justify-content: center; margin-top: 10px;">
    <div style="background-color: #fbfbfb; text-align: center; margin-bottom: 10px; padding: 15px; width: 70%; border-radius: 10px;">
        <div><h1>Quiz</h1></div>
        <div style="display:flex; justify-content:space-evenly; text-align:center; margin-bottom:5px;">
            <div style="width: 33%;">
                <p style="font-size: 20px; margin: 0;"> {{version?.DocumentTitle}} </p>
            </div>
            <div style="width: 33%;">
                <p style="font-size: 20px; margin: 0;"> Version {{version?.VersionNumber}} </p>
            </div>
            <div style="width: 33%;">
                <p style="font-size: 20px; margin: 0;"> {{version?.LanguageName }} </p>
            </div>
        </div>
        <div *ngIf="mode === 'display'">
            <button pButton style="width: 10%;" label="Edit Quiz" icon="pi pi-arrow-left" (click)="editQuiz()" *ngIf="version?.StatusID !== statusTypes.Archived"></button>
        </div>
        <div *ngIf="mode === 'edit'">
            <button pButton style="width: 10%;" icon="pi pi-arrow-right" iconPos="right" label="View Quiz" (click)="viewQuiz()" ></button>
        </div>
    </div>
</div>


<div *ngIf="mode === 'edit'">
    <arc-quiz-edit [quizID]="quizID" [versionID]="versionID" (view)="viewQuiz()" ></arc-quiz-edit>
</div>

<div *ngIf="mode === 'display'" class="display">
    <p-panel class="panel">
        <ng-template pTemplate="header">
            <div class="header">
                <div><h2>Employee View</h2></div>
                <div><p> Question: {{ questionNumber + 1 }} of {{ quiz?.Questions.length }} </p></div>
            </div>
        </ng-template>
        <ng-template pTemplate="body">
            <div class="quizBody">
                <!-- ** FULL QUIZ BODY ** -->
                <div class="question">
                    <h2> {{ currentQuestion?.Text }} </h2>
                </div>
                <div class="answers">
                    <div class="option" *ngFor="let r of currentQuestion?.Responses">
                        <span class="dot" [ngClass]="{ dot_SELECTED: r.IsCorrect }"></span>
                        <h3> {{ r.Text }} </h3>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <div class="footer">
                <div class="primaryBtn" (click)="previousQuestion()" [ngClass]="{primaryBtn_DISSABLE: questionNumber === 0}" [hidden]="reviewing">
                    <h3>Back</h3>
                </div>
                <div class="primaryBtn" (click)="nextQuestion()" [ngClass]="{primaryBtn_DISSABLE: questionNumber === quiz?.Questions.length - 1 }" [hidden]="reviewing">
                    <h3>Next</h3>
                </div>
            </div>
        </ng-template>
    </p-panel>
</div>
