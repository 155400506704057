import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Params } from '@angular/router';

// // import { server } from '../_models/index';
import { orgAffiliatesVw, orgAffiliateEdit } from '../_models/ORG.model';
import { ordAddress } from '../_models/ORD.model';


@Injectable()
export class AffiliatesService {


  affiliateType = '';
  // affiliateTypeSubscription: Subscription;

  api = 'orgAffiliate/';
  constructor(
    private http: HttpClient, @Inject('apiURL') private apiURL) {
  }

  getByID(affiliateID: number) {
    return this.http.get<orgAffiliateEdit>(this.apiURL + this.api + 'edit/' + affiliateID);
  }

  getForClient(affiliateType: string, clientID: number) {
    return this.http.get<orgAffiliateEdit[]>(this.apiURL + this.api + affiliateType + '/associated/' + clientID);
  }

  getAffiliateForClient(id: number, affiliateType: string, clientID: number) {
    return this.http.get<orgAffiliateEdit>(this.apiURL + this.api + affiliateType + '/' + id + '/associated/' + clientID);
  }

  getAll(affiliateType: string) {
      return this.http.get<orgAffiliateEdit[]>(this.apiURL + this.api + affiliateType);
  }

  searchShipCons(frag: string) {
    return this.http.get<orgAffiliatesVw[]>(this.apiURL + this.api + 'shipconsearch', { params: { frag: frag }});
  }

  update(item: orgAffiliateEdit) {
    // this.api = 'orgAffiliateEdit/';
    return this.http.put(this.apiURL + this.api + item.EntityID, item);
  }

  addShipCon(forEntityID: number, item: ordAddress) {
    // this.api = 'orgAffiliateEdit/shipcon/';
    return this.http.put(this.apiURL + this.api + 'shipcon/' + forEntityID, item);
  }
  editShipCon(ShipConID: number, forEntityID: number, item: ordAddress) {
    // this.api = 'orgAffiliateEdit/shipconEdit/';
    return this.http.put(this.apiURL + this.api + 'shipconEdit/' + ShipConID + '/' + forEntityID, item);
  }
}

