import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { docAssignmentService, docDocLinkService, docDocumentService } from 'app/_data/pro-docs.service';
import { docAssignmentEx, docDocLinkDb } from 'app/_models/DOC.model';
import { AlertService, AuthService } from 'app/_services';
import { MenuItem, SelectItem } from 'primeng/api';

// ********************
// **** GLK - 2022 ****
// ********************

@Component({
  selector: 'arc-prodoc-reader-doc-review',
  templateUrl: './prodoc-reader-doc-review.component.html',
  styleUrls: ['./prodoc-reader-doc-review.component.scss']
})
export class ProdocReaderDocReviewComponent implements OnInit {

  // ** Toggles ** //
  takingQuiz: boolean = false;
  navOpen: boolean = true;
  gotURL: boolean = false;
  showCompletedDocuments: boolean = false;
  displayLinkDocToggle: boolean = false;

  // ** Data ** // 
  assignments: docAssignmentEx[] = [];
  selectedAssignment: docAssignmentEx = null;
  cleanURL: SafeResourceUrl = null;
  links: docDocLinkDb[] = [];
  linkMenuItems: MenuItem[] = [];
  linkedURL: SafeResourceUrl = null;

  // ** Constants ** //
  userID: number = null;

  constructor(
    private assignmentService: docAssignmentService,
    private docService: docDocumentService,
    private linkService: docDocLinkService,
    public sanitizer: DomSanitizer,
    private alert: AlertService,
    private auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.userID = this.auth.currentUser.UserID;
    this.getAssignments();
  }

  getAssignments() {
    this.assignments = [];
    this.selectedAssignment = null;
    this.assignmentService.getForAssignee(this.userID, this.showCompletedDocuments)
      .subscribe({
        next: (data: docAssignmentEx[]) => {
          if (this.showCompletedDocuments) {
            this.assignments = data.filter(a => a.CompletedDate).sort((a, b) => a.CompletedDate > b.CompletedDate ? -1 : 1);
          } else {
            this.assignments = data.sort((a, b) => a.SoftDueDate > b.SoftDueDate ? 1 : -1);
          }
          console.log('****************')
          console.log(this.assignments);
          if (this.assignments.length > 0) {
            this.selectedAssignment = this.assignments[0];

            this.assignments.forEach(a => {
              a.FirstDue = Math.ceil((new Date(a.SoftDueDate).getTime() - new Date().getTime()) / (1000 * 3600 * 24));
              if (a.FirstDue < 0) { a.FirstDue = 0; }
            })

            // ! This is where you need to display the 
            // !   document assosiated with the assignment
            // ! --------------------
            this.displayDocument();
            this.getDocLinks();

            // ! --------------------
          } else {
            this.selectedAssignment = null;
          }
        }, error: (err) => {
          console.log(err);
        }
      });
  }

  displayDocument() {
    this.gotURL = false;
    this.docService.getForAssignment(this.selectedAssignment.AssignmentID)
      .subscribe({
        next: (data: Blob) => {
          const theFile = new Blob([data], { type: 'application/pdf' });
          const rawURL = window.URL.createObjectURL(theFile);

          this.cleanURL = this.sanitizer.bypassSecurityTrustResourceUrl(rawURL);
          this.gotURL = true;
        },
        error: (error) => console.log('error', error)
      });
  }

  selectAssignment() {

    // ! This is where you need to display the 
    // !   document assosiated with the assignment
    // ! --------------------
    this.displayDocument();
    this.getDocLinks();

    // ! --------------------
  }

  getDocLinks() {
    this.linkService.getForVersion(this.selectedAssignment.VersionID).subscribe({
      next: (data: docDocLinkDb[]) => {
        this.links = data;
        this.linkMenuItems = [];
        data.forEach((link: docDocLinkDb) => {
          this.linkMenuItems.push({ label: link.DocumentTitle, command: () => { this.displayLinkedDoc(link) } })
        });
        console.log(this.linkMenuItems)
      }, error: (err) => {
        console.log(err);
      }
    });
  }

  displayLinkedDoc(link: docDocLinkDb) {
    this.displayLinkDocToggle = true;
    console.log(link)
    //    this.docService.getForDocument(link.DocumentID)
    this.linkService.getLinkedDoc(link.DocumentID, this.selectedAssignment.AssignmentID)
      .subscribe({
        next: (data: Blob) => {
          const theFile = new Blob([data], { type: 'application/pdf' });
          const rawURL = window.URL.createObjectURL(theFile);

          this.linkedURL = this.sanitizer.bypassSecurityTrustResourceUrl(rawURL);
          console.log(this.linkedURL)
        },
        error: (error) => console.log('error', error)
      });
  }

  toggleNav() {
    this.navOpen = !this.navOpen;
  }

  takeQuiz() {
    this.takingQuiz = true;
  }

  finishQuiz() {
    this.takingQuiz = false;
    this.getAssignments();
  }

  toggleCompleted() {
    this.showCompletedDocuments = !this.showCompletedDocuments;
    this.getAssignments();
  }

  markRead() {
    this.assignmentService.markRead(this.selectedAssignment.AssignmentID).subscribe({
      next: (UpsertRet) => {
        this.getAssignments();
      }, error: (err) => {
        console.log(err);
        this.alert.error('Error Marking Assignment');
      }
    });
  }

  getTextColor(days: number): string {
    if (days >= 5) { return 'green'; }
    else if (days < 5 && days > 0) { return 'orange'; }
    else if (days <= 0) { return 'red'; }
  }

}
