import { Component, Input, OnInit } from '@angular/core';
import { docDashboardService } from 'app/_data/pro-docs.service';
import { docAssignmentStatus } from 'app/_models/DOC.model';
import { AssignmentCompletionDistributionTypes } from 'app/_models/enums.model';
import { AlertService, AuthService } from 'app/_services';
import { LookupsService } from 'app/_services/lookups.service';
import { UtilityService } from 'app/_services/utility.service';
import { SelectItem } from 'primeng/api';
import { AssignmentCompletionDistributionComponent } from '../assignment-completion-distribution/assignment-completion-distribution.component';


// ******************
// **** GLK 2022 ****
// ******************

@Component({
  selector: 'document-assignment-completion',
  templateUrl: './document-assignment-completion.component.html',
  styleUrls: ['./document-assignment-completion.component.scss']
})
export class DocumentAssignmentCompletionComponent implements OnInit {

  // ** TOGGLES & PARAMS ** \\
  @Input() displayType: string;
  showFullTeam: boolean = false;
  currentUserID: number = null;

  // ** DATA ** \\
  data: docAssignmentStatus[] = [];
  warehouses: SelectItem[] = [];
  selectedWarehouse: number = null;
  totalEmployees: number = 0;
  formattedChartData: any;

  // ** MISC & STORAGE ** \\
  displayTypes = AssignmentCompletionDistributionTypes;

  constructor(
    private dashboardService: docDashboardService,
    private utility: UtilityService,
    private lookups: LookupsService,
    private alert: AlertService,
    private auth: AuthService,
  ) { }

  ngOnInit(): void { this.setup() }

  // ** Initialization ** 
  // #region Initialization

  setup() {
    this.currentUserID = this.auth.currentUser.UserID;
    this.getData();
  }

  getData() {
    if (this.displayType === this.displayTypes.Team)
      this.getDataForTeam();
    else
      this.getDataForWarehouse();
  }

  // ! **********************
  // ! MAKE SURE TO CHANGE THE USERID TO currentUserID BEFORE DEPLOYEMENT
  // ! **********************
  getDataForTeam() {
    this.dashboardService.getDocumentAssignmentStatus(this.currentUserID, this.showFullTeam).subscribe({
      next: (data: docAssignmentStatus[]) => {
        console.log('%c Assignment Status Distribution For Team', 'color: orange', data)
        this.data = data;
        this.formatData(data);
      }, error: (err) => {
        console.log(err);
        this.alert.error('Error Gathering Data For Document Assignment Status: ' + err);
      }
    });
  }

  getDataForWarehouse() {
    this.getWarehouses();
    this.dashboardService.getDocumentAssignmentStatus(-1, false, this.selectedWarehouse).subscribe({
      next: (data: docAssignmentStatus[]) => {
        console.log('%c Assignment Status Distribution For Warehouse', 'color: orange', data)
        this.data = data;
        this.formatData(data);
      }, error: (err) => {
        console.log(err);
        this.alert.error('Error Gathering Data For Document Assignment Status: ' + err);
      }
    });

  }

  getWarehouses() {
    this.lookups.warehouses.subscribe((data: SelectItem[]) => {
      this.warehouses = data.filter((w) => w.disabled === false);
      this.warehouses.unshift({ label: 'All Warehouses', value: -1 });
    });
  }

  // #endregion

  // ** Data Manipulation **
  // #region Data Manipulation

  formatData(data: docAssignmentStatus[]) {
    this.totalEmployees = 0;
    let labels = [];
    let dataPoints = [];
    let colors = [];
    data.forEach(a => {
      labels.push(a.DocType);
      dataPoints.push(a.Employees);
      colors.push(this.getRandomColor());
      this.totalEmployees += a.Employees;
    })

    this.formattedChartData = {
      labels: labels,
      datasets: [{ data: dataPoints, backgroundColor: colors }]
    }
  }

  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  // #endregion

  // ** Toggling ** 
  // #region Toggling

  // #endregion
}
