<div style="display:flex;">
    <div style="width:20%; padding: 10px;">
        <arc-team-selector [forManagerID]="managerID" [showFullTeam]="showFullTeam" (onSelectManager)="getData($event)"
        (onToggleTeam)="toggleTeam($event)"></arc-team-selector>
    </div>
    <div style="width: 80%; display:flex; justify-content:center;">
        <div style="width: 100%; padding: 10px;">
            <p-panel header="Employee Assignment Summary">
                <p-table #dt [value]="filteredData" sortMode="multiple" [showJumpToPageDropdown]="true" [showPageLinks]="false" [multiSortMeta]="multiSortMetaDefault"
                    (onFilter)="doFilter($event)" (onSort)="onSort($event)" (onPage)="onPage($event)">
                    <!-- Table Header Template -->
                    <ng-template pTemplate="header">
                        <!-- Header Row -->
                        <tr>
                            <th style="width:50px;"></th>
                            <th pSortableColumn="PersonName" [ngStyle]="{ 'width':'20%', 'text-align': 'left' }">
                                Person Name
                                <span>
                                    <p-sortIcon field="PersonName"></p-sortIcon>
                                </span>
                            </th>
                            <th pSortableColumn="PendingAssignmentCount" [ngStyle]="{ 'width':'20%', 'text-align': 'left' }">
                                Pending Assignment Count
                                <span>
                                    <p-sortIcon field="PendingAssignmentCount"></p-sortIcon>
                                </span>
                            </th>
                            <th pSortableColumn="FirstSoftDue" [ngStyle]="{ 'width':'20%', 'text-align': 'left' }">
                                First Soft Due Date
                                <span>
                                    <p-sortIcon field="FirstSoftDue"></p-sortIcon>
                                </span>
                            </th>
                            <th pSortableColumn="FirstHardDue" [ngStyle]="{ 'width':'20%', 'text-align': 'left' }">
                                First Hard Due Date
                                <span>
                                    <p-sortIcon field="FirstHardDue"></p-sortIcon>
                                </span>
                            </th>
                            <th pSortableColumn="Warehouse" [ngStyle]="{ 'text-align': 'left' }">
                                Warehouse
                                <span>
                                    <p-sortIcon field="Warehouse"></p-sortIcon>
                                </span>
                            </th>
                        </tr>
                        <!-- Filters Row -->
                        <tr>
                            <th class="col-icon-delete">
                                <div class="col-icon-delete">
                                    <i class="fa fa-ban" (click)="clearLocalFilter(dt)"></i>
                                </div>
                            </th>
                            <th>
                                <p-dropdown [options]="teamMembers" [(ngModel)]="localFilter.PersonName" (onClear)="dt.filter(localFilter.PersonName, null, null)"
                                    [showClear]="true" placeholder="Team Member" appendTo="body" [editable]="true"
                                    (onChange)="dt.filter(localFilter.PersonName, 'PersonName', 'contains')"></p-dropdown>
                            </th>
                            <th></th>
                            <th>
                                <p-calendar [(ngModel)]='localFilter.FirstSoftDue'
                                    (onSelect)="dt.filter(localFilter.FirstSoftDue, 'FirstSoftDue', 'dateRangeFilter')"
                                    (onClearClick)="dt.filter('', 'FirstSoftDue','contains')" [showButtonBar]="true"
                                    [readonlyInput]="true" [monthNavigator]="true" [yearNavigator]="true"
                                    [inputStyle]="{'width': '100%'}" appendTo="body" yearRange="2000:2030" selectionMode="range">
                                </p-calendar>
                            </th>
                            <th>
                                <p-calendar [(ngModel)]='localFilter.FirstHardDue'
                                    (onSelect)="dt.filter(localFilter.FirstHardDue, 'FirstHardDue', 'dateRangeFilter')"
                                    (onClearClick)="dt.filter('', 'FirstHardDue','contains')" [showButtonBar]="true"
                                    [readonlyInput]="true" [monthNavigator]="true" [yearNavigator]="true"
                                    [inputStyle]="{'width': '100%'}" appendTo="body" yearRange="2000:2030" selectionMode="range">
                                </p-calendar>
                            </th>
                            <th>
                                <p-dropdown [options]="warehouses" [(ngModel)]="localFilter.Warehouse" (onClear)="dt.filter(localFilter.Warehouse, null, null)"
                                    [showClear]="true" placeholder="Warehouse" appendTo="body" [editable]="true"
                                    (onChange)="dt.filter(localFilter.Warehouse, 'Warehouse', 'contains')"></p-dropdown>
                            </th>
                        </tr>
                    </ng-template>
                    <!-- Table Body Template -->
                    <ng-template pTemplate="body" let-rowData let-item>
                        <!-- Data Rows -->
                        <tr [pSelectableRow]="rowData" style="font-size: .65vw">
                            <td style="text-align: center;">
                                <p-button icon="pi pi-book" (onClick)="viewAssignments(item.PersonID)" pTooltip="View Assignment"></p-button>
                            </td>
                            <td>
                                <p>{{ item.PersonName }}</p>
                            </td>
                            <td>
                                <p>{{ item.PendingAssignmentsCount }}</p>
                            </td>
                            <td>
                                <div style="display:flex; justify-content:space-evenly;">
                                    <p *ngIf="item.FirstSoftCount > 0"> {{ item.FirstSoftDue | date: 'mediumDate' }} </p> <p *ngIf="item.FirstSoftCount > 0" class="dateIndicator" [style.background-color]="getTextColor(item.FirstSoftCount)"> {{ item.FirstSoftCount }} Day(s)</p>
                                    <p *ngIf="item.FirstSoftCount === 0"> {{ item.FirstSoftDue | date: 'mediumDate' }} </p> <p *ngIf="item.FirstSoftCount === 0" class="dateIndicator" [style.background-color]="getTextColor(item.FirstSoftCount)"> OVERDUE</p>
                                </div>
                            </td>
                            <td>
                                <div style="display:flex; justify-content:space-evenly;">
                                    <p *ngIf="item.FirstHardCount > 0"> {{ item.FirstHardDue | date: 'mediumDate' }} </p> <p *ngIf="item.FirstHardCount > 0" class="dateIndicator" [style.background-color]="getTextColor(item.DaysTillSoft)"> {{ item.FirstHardCount }} Day(s)</p>
                                    <p *ngIf="item.FirstHardCount === 0"> {{ item.FirstHardDue | date: 'mediumDate' }} </p> <p *ngIf="item.FirstHardCount === 0" class="dateIndicator" [style.background-color]="getTextColor(item.FirstHardCount)"> OVERDUE</p>
                                </div>
                            </td>
                            <td>
                                <p>{{ item.Warehouse }}</p>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Table Footer Template -->
                    <ng-template pTemplate="summary">
                        <div style="display:flex; justify-content:space-between">
                            <p>Assignment Count: {{ dt.totalRecords }} </p>
                            <p-button label="Refresh" icon="pi pi-refresh" (click)="getData()"></p-button>
                        </div>
                    </ng-template>
                </p-table>
            </p-panel>
        </div>
    </div>
</div>