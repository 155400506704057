import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { docQuizInstanceService } from 'app/_data/pro-docs.service';
import { docAssignmentEx, docQuizInstanceEx, docQuizQuestionEx, docQuizResponseDb } from 'app/_models/DOC.model';
import { AlertService, AuthService } from 'app/_services';

// ********************
// **** GLK - 2022 ****
// ********************

@Component({
  selector: 'arc-prodoc-reader-quiz',
  templateUrl: './prodoc-reader-quiz.component.html',
  styleUrls: ['./prodoc-reader-quiz.component.scss']
})
export class ProdocReaderQuizComponent implements OnInit {

  // ** Toggles ** //
  reviewing: boolean = false;
  passed: boolean = null;

  // ** Data ** //
  quiz: docQuizInstanceEx = null;
  @Input() selectedAssignment?: docAssignmentEx;
  @Output() reload = new EventEmitter();
  currentQuestion: docQuizQuestionEx = null;

  // ** Constants ** //
  questionNumber: number = 0;
  userID: number = null;

  constructor(
    private quizService: docQuizInstanceService,
    private authService: AuthService,
    private alert: AlertService,
  ) { }

  ngOnInit(): void {
    this.getQuizForAssignment();
  }

  getQuizForAssignment() {
    this.quizService.getQuizForAssignment(this.selectedAssignment.AssignmentID)
      .subscribe({
        next: (data: docQuizInstanceEx) => {
          this.quiz = data;
          this.currentQuestion = data.Questions[0];
          console.log(data);
        }, error: (err) => {
          console.log(err);
        }
      });
  }

  selectAnswer(response: docQuizResponseDb) {
    this.currentQuestion.Responses.forEach(r => {
      r.IsSelected = false;
      if (r.ResponseID === response.ResponseID) {
        r.IsSelected = true;
      }
    });
  }

  previousQuestion() {
    if (this.questionNumber === 0 || this.reviewing)
      return;
    this.questionNumber--;
    this.currentQuestion = this.quiz.Questions[this.questionNumber];
  }

  nextquestion() {
    if (this.questionNumber >= this.quiz?.Questions.length)
      return;
    this.questionNumber++;
    this.currentQuestion = this.quiz.Questions[this.questionNumber];
  }

  submit() {
    console.log(this.quiz.Questions);
    this.quizService.postQuiz(this.quiz).subscribe({
      next: (data) => {
        this.reviewing = true;
        this.quiz = data.returnItem;
        this.quiz.DisplayPercentage = data.returnItem.ResultPercent * 100;
        if (data.returnItem.RequiredScore > data.returnItem.ResultPercent)
          this.passed = false; // USER DID NOT PASS THE QUIZ
        else
          this.passed = true; // USER PASSED THE QUIZ

        this.quiz.Questions.forEach(a => {
          a.Responses.forEach(b => {
            if (b.IsCorrect === true && b.IsSelected === true)
              a.Correct = true;
          });
        });
        console.log(this.quiz);
      }
    });
  }

  continue() {
    this.reload.emit();
  }

}
