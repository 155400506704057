import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { timeActionService, timeEmployeeService } from 'app/_data/time.service';
import { timeClockAction, timeEmployeeSnapshot, timeFunctionDb, timeIssueDb } from 'app/_models/TIME.model';
import { AlertService, AuthService } from 'app/_services';
import { NotifierService } from 'app/_sharedData/notifier.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
	selector: 'arc-begin-break',
	templateUrl: './begin-break.component.html',
	styleUrls: ['./begin-break.component.scss']
})
export class BeginBreakComponent implements OnInit, OnDestroy {

	deliveredPackage: timeFunctionDb = null;
	employee: timeEmployeeSnapshot;
	counter: number;
	finished: boolean = false;
	punchRecorded: boolean = false;
	now: Date;
	creatingIssue: boolean = false;
	sentIssueArray: timeIssueDb[];
	addedIssue: boolean = false;
	private _subscription: Subscription;
	userID: number;

	isBusy: boolean = false;

	@Output() out = new EventEmitter<timeEmployeeSnapshot>();

	constructor(
		private authService: AuthService,
		private employeeService: timeEmployeeService,
		private actionService: timeActionService,
		private alert: AlertService,
		private notifier: NotifierService
	) { }

	ngOnInit(): void {
		this.userID = this.authService.currentUser.UserID
		this._subscription = this.employeeService.getSnapshot(this.userID).subscribe(
			(value) => {
				if (value) { this.employee = value; } else { this.employee = null; }
			},
			(error) => this.alert.error(error)
		);

	}

	ngOnDestroy(): void {
		this._subscription.unsubscribe();
	}


	collectPackage(pack: timeFunctionDb) {
		this.deliveredPackage = pack;
	}

	hasChanged(result: boolean) {
		this.creatingIssue = false;
		if (result === true) { this.addedIssue = true; }
		console.log('Sent Issues Array RETURN', this.sentIssueArray);
	}

	createIssue() {
		this.creatingIssue = true;
		this.sentIssueArray = [];
	}

	finish() {
		if (this.isBusy)
			return;
		this.isBusy = true;
		this.notifier.loadingOn();
		let action: timeClockAction = {
			EmployeeID: this.employee.Employee.EmployeeID,
			TimeStamp: new Date().toLocaleString(),
			Action: 'BBRK',
			FunctionID: this.deliveredPackage.FunctionID,
			ClientID: null,
			IsBillable: null,
			Approval: null,
			Issues: this.sentIssueArray,
			IsAutoGenerated: false,
			StopType: 0
		}
		console.log('SENDING ACTION', action)

		this.actionService.postAction(action)
			.pipe(finalize(() => {
				this.notifier.loadingOff();
				this.isBusy = false;
			}))
			.subscribe((data: timeEmployeeSnapshot) => {
				if (data.ActionSuccess === true) {
					// this.authService.employeeSignOut();
					this.punchRecorded = true;
					this.employeeService.updateEmployeeSnapshot(data);
					this.out.emit(data);
				} else { this.alert.error(data.ActionStatus); }
			}, err => { this.alert.error(err); console.log(err); });
	}

	goBack() {
		this.deliveredPackage = null;
		this.sentIssueArray = [];
		this.addedIssue = false;
	}

}
