import { Component, OnInit, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import { timeActionService, timeEmployeeService } from 'app/_data/time.service';
import { cfgIssueTypesService } from 'app/_data/time-cfg.service';
import { timeEmployeeSnapshot, timeIssueDb, timeIssueSummary } from 'app/_models/TIME.model';
import { cfgIssueTypeDb } from 'app/_models/CFG.model';
import { AlertService, AuthService } from 'app/_services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'arc-issue-popup',
  templateUrl: './issue-popup.component.html',
  styleUrls: ['./issue-popup.component.scss']
})
export class IssuePopupComponent implements OnInit, OnDestroy {

  @Input() deliveredIssuePackage: timeIssueDb[];
  @Input() ApprovalPayrollFlag: boolean;
  @Output() reload = new EventEmitter<timeIssueDb>();
  @Output() hasChanged = new EventEmitter<boolean>();

  employee: timeEmployeeSnapshot;
  _subscription: Subscription;
  userID: number;

  currentIssue: timeIssueDb = new timeIssueDb();

  selectedIssueType: cfgIssueTypeDb = null;
  note: string = '';

  tempArray: timeIssueDb[] = [];

  issueTypes: cfgIssueTypeDb[] = [];
  visableTypes: cfgIssueTypeDb[] = [];
  viewingIssues: boolean;
  addingIssues: boolean;
  viewingManagerNotes: boolean;
  issueBeingViewed: timeIssueSummary;

  constructor(
    private actionService: timeActionService,
    private employeeService: timeEmployeeService,
    private alert: AlertService,
    private issueTypeService: cfgIssueTypesService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {

    this.userID = this.authService.currentUser.UserID;

    this._subscription = this.employeeService.signedInEmployee$.subscribe(
      (value) => {
        if (value) {
          this.employee = value;
          if (this.deliveredIssuePackage.length > 0) {
            this.viewingIssues = true;
            this.viewingManagerNotes = false;
            this.addingIssues = false;
          }
          else {
            this.viewingIssues = false;
            this.viewingManagerNotes = false;
            this.addingIssues = true;
            this.addIssue();
          }
        }
      },
      (error) => this.alert.error(error)
    );

    this.issueTypeService.getAll().subscribe(
      (value) => {
        this.issueTypes = value;
        this.visableTypes = this.issueTypes.filter((val) => val.IsAutoAssigned === false);
        console.table(this.visableTypes);
      },
      (error) => this.alert.error(error)
    );
  }

  ngOnDestroy(): void { }


  setIssueType(issueType: cfgIssueTypeDb) {
    this.selectedIssueType = issueType;
    this.currentIssue.IssueTypeID = issueType.IssueTypeID;
    this.currentIssue.IsDisputeTime = issueType.IsDisputeTime;
  }

  setIssue() {
    console.log('CURRENT ISSUE', this.currentIssue);
    // Checking to make sure that there is a issue type selcted
    if (this.selectedIssueType === null) {
      this.alert.warn("Please select an Issue Type");
      return;
    }

    if (this.note === '' || this.note === ' ') {
      this.alert.warn('Please add an issue note.');
      return;
    }
    // FOR CREATING A NEW ISSUE WE USE -2
    if (this.currentIssue.IssueID === -2) {
      this.currentIssue.IssueNote = this.note;
      this.deliveredIssuePackage.push(this.currentIssue);
      console.log('IN THE HEAT OF IT ***********', this.deliveredIssuePackage);
      this.hasChanged.emit(true);
    }
    // FOR EDITING AN EXISTING ISSUE 
    else {
      this.currentIssue.IssueNote = this.note;
      this.currentIssue.IssueTypeID = this.selectedIssueType.IssueTypeID;
      this.hasChanged.emit(true);
    }
  }

  getIssueName(id: number): string {
    let retVal = '';
    this.issueTypes.forEach((val) => {
      if (val.IssueTypeID === id) {
        retVal = val.IssueTypeName;
      }
    });
    return retVal;
  }

  addIssue() {
    console.log(this.selectedIssueType);
    console.log(this.note);
    this.viewingIssues = false;
    this.viewingManagerNotes = false;
    this.addingIssues = true;
    // this.currentIssue = new timeIssueDb();
    this.currentIssue.IssueNote = this.note;
    this.currentIssue.IssueTypeID = this.selectedIssueType?.IssueTypeID;
    this.currentIssue.CreatedBy = this.employee.Employee.EmployeeID;
    this.currentIssue.IssueID = -2;
  }

  edit(issue: timeIssueDb) {
    this.viewingIssues = false;
    this.viewingManagerNotes = false;
    this.addingIssues = true;

    if (issue.IsAutoGenerated) {
      this.viewingIssues = true;
      return;
    }

    this.currentIssue = issue;
    this.selectedIssueType = this.issueTypes.find((val) => {
      if (val.IssueTypeID === this.currentIssue.IssueTypeID) { return val } else { return null }
    });
    this.note = this.currentIssue.IssueNote;
  }

  canShow(id: number): boolean {
    let retVal = true;
    this.issueTypes.forEach((val) => {
      if (val.IssueTypeID === id) {
        if (val.IsAutoAssigned) { retVal = false; }
      }
    });
    return retVal;
  }

  viewManagerNotes(issue: timeIssueSummary) {
    this.viewingManagerNotes = true;
    this.viewingIssues = false;
    this.addingIssues = false;
    this.issueBeingViewed = issue;
    console.log('Viewing Manager Notes', issue);
  }

  finishViewingManagerNotes() {
    this.viewingManagerNotes = false;
    this.viewingIssues = true;
    this.addingIssues = false;
    this.issueBeingViewed = null;

  }
}
