import { Injectable } from '@angular/core';
import { ordPieceEx } from '../_models/ORD.model';
import { DateRange, DimFactors } from '../_models/enums.model';
import { environment } from '../../environments/environment';
import { ReportingService } from '../_data/reporting.service';
import { AlertService } from './alert.service';
import { NotifierService } from '../_sharedData/notifier.service';
import { lastDayOfMonth, lastDayOfWeek, lastDayOfYear, startOfMonth, startOfWeek, startOfYear, subDays, subMonths, subYears } from 'date-fns';
import { SelectItem } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(
    private alert: AlertService,
    private reporting: ReportingService,
    private notifier: NotifierService
  ) { }

  env = environment;

  calcDimWeight(piece: ordPieceEx, dimFactor: DimFactors): number {
    // working off of the following (from old FreightBook):
    // Length * Width * Height / 194 * Qty AS Dim194,
    // Length * Width * Height / 166 * Qty AS Dim166,
    // Length * Width * Height / 160 * Qty AS Dim160,
    // Length * Width * Height / 139 * Qty AS Dim139,
    // Length * Width * Height / 173 * Qty AS Dim173,
    // Length * Width * Height / 250 * Qty AS Dim250,
    // Length * Width * Height / 1728 * 10 * Qty AS Dim1728,
    // Length * Width * Height / 1728 * Qty AS CubicFeet,
    // CASE WHEN (Length * Width * Height) / 1728 <= 0 THEN 0 ELSE Weight / (Length * Width * Height / 1728) * Qty END AS LbsPerCF

    let dimWeight = 0;
    if ((piece.Quantity) && (piece.PieceHeight) && (piece.PieceLength) && (piece.PieceWidth)) {
      switch (dimFactor) {
        case DimFactors.Dim1728:
          dimWeight = Math.ceil((piece.Quantity * piece.PieceHeight * piece.PieceWidth * piece.PieceLength) / dimFactor * 10);
          break;
        case DimFactors.Dim139:
        case DimFactors.Dim160:
        case DimFactors.Dim166:
        case DimFactors.Dim173:
        case DimFactors.Dim194:
        case DimFactors.Dim250:
          dimWeight = Math.ceil((piece.Quantity * piece.PieceHeight * piece.PieceWidth * piece.PieceLength) / dimFactor);
          break;
        case DimFactors.CubicFeet:
          dimWeight = Math.ceil(piece.PieceLength * piece.PieceWidth * piece.PieceHeight / 1728 * piece.Quantity);
          break;
        case DimFactors.LbsPerCF: // lbs per Cubic Foot
          // let this one round to 1 decimal place
          if (((piece.PieceLength * piece.PieceWidth * piece.PieceHeight) / 1728) <= 0) {
            dimWeight = 0;
          } else {
            dimWeight = piece.PieceWeight / (piece.PieceLength * piece.PieceWidth * piece.PieceHeight / 1728) * piece.Quantity;
          }
          break;
        default:
          console.log('no DimFactor match');
      }
    }
    return dimWeight;
  }

  // ParseCSC
  // 20191120 RJS Handle CSC's without a StateProvince code -- you can tell because the province is preceded by ', ', the country by '  '

  public ParseCSC(inCSC: string): CSC {
    const newCSC = new CSC();
    // force into a string array but standardizing delimiters
    let workCSC = inCSC.replace(', ', '|'); // pipe between city and state/country
    const pipePos = workCSC.indexOf('|');
    const spacePos = workCSC.indexOf(' ', pipePos);
    if (spacePos >= 0) {
      workCSC = workCSC.substr(0, spacePos) + '|' + workCSC.substr(spacePos + 1).trim();
    }
    const aCSC = workCSC.split('|');
    // city is always the first
    newCSC.City = aCSC[0];
    // if there's two more, we have state and country
    if (aCSC.length > 2) {
      newCSC.StateProvinceCode = aCSC[1];
      if (newCSC.StateProvinceCode === '') { newCSC.StateProvinceCode = null; }
      newCSC.CountryCode = aCSC[2];
    } else { // we only have country
      newCSC.StateProvinceCode = null;
      newCSC.CountryCode = aCSC[1];
    }
    // const commaPos = inCSC.indexOf(',');
    // newCSC.City = inCSC.substr(0, commaPos).trim(); //  parts[0].substring(0, parts[0].length - 1);   // remove trailing comma
    // inCSC = inCSC.substr(commaPos).trim().toUpperCase();
    // const spacePos = inCSC.indexOf(' ');
    // newCSC.StateProvinceCode = inCSC.substr(0, spacePos).toUpperCase(); //  parts[1].trim();                 // remove any extraneous spaces
    // newCSC.CountryCode = inCSC.substr(spacePos).trim().toUpperCase(); //  parts[2].trim();                       // remove any extraneous spaces
    newCSC.CSC = newCSC.City + ', ' + (newCSC.StateProvinceCode ? newCSC.StateProvinceCode + ' ' : ' ') + newCSC.CountryCode;

    return newCSC;
  }

  // public BuildAddress(ent: orgEntity, pl: orgPlace): ordAddress {
  //   const newAddr = new ordAddress();

  //   newAddr.EntityName = ent.EntityName;
  //   newAddr.ContactName = '';
  //   newAddr.PlaceName = pl.PlaceName;
  //   newAddr.Address1 = pl.Address1;
  //   newAddr.Address2 = pl.Address2;
  //   newAddr.City = pl.City;
  //   newAddr.PostalCode = pl.PostalCode;
  //   newAddr.StateProvinceCode = pl.StateProvinceCode;
  //   newAddr.CountryCode = pl.CountryCode;
  //   newAddr.AirportCode = pl.AirportCode;
  //   newAddr.TollFree = '';
  //   newAddr.Phone = '';
  //   newAddr.Fax = '';
  //   newAddr.Mobile = '';
  //   newAddr.EMail = '';
  //   newAddr.OpenHours = pl.OpenHours;
  //   newAddr.Comments = '';

  //   return newAddr;
  // }

  extensionForFormat(format: string): string {
    switch (format.toUpperCase()) {
      case 'PDF':
        return 'pdf';
        break;
      case 'EXCEL':
        return 'xls';
        break;
      case 'CSV':
        return 'csv';
        break;
      case 'CSVASCII':
        return 'csv';
        break;
      case 'XML':
        return 'xml';
        break;
      case 'WORD':
        return 'doc';
        break;

    }
  }

  contentTypeForFormat(format: string): string {
    switch (format.toUpperCase()) {
      case 'PDF':
        return 'application/pdf';
        break;
      case 'EXCEL':
        return 'application/vnd.ms-excel';
        break;
      case 'CSV':
        return 'text/csv';
        break;
      case 'XML':
        return 'application/xml';
        break;
      case 'WORD':
        return 'application/msword';
        break;
    }
  }

  contentTypeForSuffix(suffix: string): string {
    switch (suffix.toUpperCase()) {
      case 'PDF':
        return 'application/pdf';
        break;
      case 'XLS':
      case 'XLSX':
        return 'application/vnd.ms-excel';
        break;
      case 'DOC':
      case 'DOCX':
        return 'application/msword';
        break;
      case 'CSV':
        return 'text/csv';
        break;
      case 'TXT':
        return 'text/text';
        break;
    }
  }

  // a helpful tool for getting data range names translated to an array of dates
  // uses date-fns library
  dateRangeDate(dr: DateRange): Date[] {

    const date = new Date();
    var fromDate: Date;
    var toDate: Date;

    switch (dr) {
      case DateRange.Custom:
        return null;
      //fromDate = null;
      //toDate = null;
      //break;
      case DateRange.WeekToDate:
        fromDate = startOfWeek(date);
        toDate = date;
        break;
      case DateRange.LastWeek:
        fromDate = startOfWeek(subDays(date, 7));
        toDate = lastDayOfWeek(subDays(date, 7));
        break;
      case DateRange.MonthToDate:
        fromDate = startOfMonth(date);
        toDate = date;
        break;
      case DateRange.LastMonth:
        fromDate = startOfMonth(subMonths(date, 1));
        toDate = lastDayOfMonth(subMonths(date, 1));
        break;
      case DateRange.YearToDate:
        fromDate = startOfYear(date);
        toDate = date;
        break;
      case DateRange.LastYear:
        fromDate = startOfYear(subYears(date, 1));
        toDate = lastDayOfYear(subYears(date, 1));
        break;
      case DateRange.Last7Days:
        fromDate = subDays(date, 7);
        toDate = date;
        break;
      case DateRange.Last30Days:
        fromDate = subDays(date, 30);
        toDate = date;
        break;
      case DateRange.Last60Days:
        fromDate = subDays(date, 60);
        toDate = date;
        break;
      case DateRange.Last90Days:
        fromDate = subDays(date, 90);
        toDate = date;
        break;
      case DateRange.Last120Days:
        fromDate = subDays(date, 120);
        toDate = date;
        break;
      case DateRange.Last180Days:
        fromDate = subDays(date, 180);
        toDate = date;
        break;
      case DateRange.Last12Months:
        fromDate = subMonths(date, 12);
        toDate = date;
        break;
      case DateRange.Last24Months:
        fromDate = subMonths(date, 24);
        toDate = date;
        break;
      case DateRange.All:
        fromDate = new Date('1/1/2000');
        toDate = date;
        break;
      default:
        fromDate = subDays(date, 180);
        toDate = date;
    }

    const range: Date[] = [fromDate, toDate];
    return range;
  }

  dateRangeOptions(): SelectItem[] {
    const options = [
      { label: 'Custom', value: DateRange.Custom },
      { label: 'Week to Date', value: DateRange.WeekToDate },
      { label: 'Last Week', value: DateRange.LastWeek },
      { label: 'Month to Date', value: DateRange.MonthToDate },
      { label: 'Last Month', value: DateRange.LastMonth },
      { label: 'Year to Date', value: DateRange.YearToDate },
      { label: 'Last Year', value: DateRange.LastYear },
      { label: 'Last 7 Days', value: DateRange.Last7Days },
      { label: 'Last 30 Days', value: DateRange.Last30Days },
      { label: 'Last 60 Days', value: DateRange.Last60Days },
      { label: 'Last 90 Days', value: DateRange.Last90Days },
      { label: 'Last 120 Days', value: DateRange.Last120Days },
      { label: 'Last 180 Days', value: DateRange.Last180Days },
      { label: 'Last 12 Months', value: DateRange.Last12Months },
      { label: 'Last 24 Months', value: DateRange.Last24Months },
      { label: 'All (slow!)', value: DateRange.All }
    ];
    return options;
  }
}

export class CSC {
  City: string;
  StateProvinceCode: string;
  CountryCode: string;
  CSC: string;
}


