import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { docDashboardService } from 'app/_data/pro-docs.service';
import { docVersionDb, docVersionDbEx } from 'app/_models/DOC.model';
import { DocVersionStatusTypes, LanguageTypes } from 'app/_models/enums.model';
import { LabelForEnumPipe } from 'app/_pipes/label-for-enum.pipe';
import { AlertService, AuthService } from 'app/_services';
import { LookupsService } from 'app/_services/lookups.service';
import { PersistenceService } from 'app/_services/persistence.service';
import { SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';

// **********************
// ****** GLK 2022 ******
// **********************

@Component({
  selector: 'arc-prodocs-dashboard-versions-list',
  templateUrl: './prodocs-dashboard-versions-list.component.html',
  styleUrls: ['./prodocs-dashboard-versions-list.component.scss']
})
export class ProdocsDashboardVersionsListComponent implements OnInit {

  // ** TOGGLES ** //
  includeArchived: boolean = false;
  archivedString: string = 'Include';

  // ** DATA ** //
  versionsData: docVersionDbEx[] = [];
  statuses: SelectItem[] = [];
  owners: SelectItem[] = [];
  languages: SelectItem[] = [];
  categories: SelectItem[] = [];

  // ** DATA TABLE FILTERING ** //
  @ViewChild('dt', { static: true }) mydt: Table;

  multiSortMetaDefault = [{ field: 'DocumentTitle', order: 1 }];
  //#region FilterNames
  storedDTFilters = 'VersionsDashboardDTFilters';
  storedInputFilters = 'VersionsDashboardFilters';
  //#endregion

  localFilter = {
    DocumentTitle: null,
    VersionNumber: null,
    LanguageName: null,
    CategoryName: null,
    CreatedDate: null,
    PublicationDate: null,
    ExpirationDate: null,
    StatusID: null,
    OwnerName: null,
    MultiSortMeta: this.multiSortMetaDefault,
    rowsPerPage: +this.auth.currentUser.opts.PaginatorRows,
    currentRow: 0,
  };

  // Timer Interval
  // interval;
  // timerDuration: number = 300000;

  constructor(
    private dashboardService: docDashboardService,
    private labelForEnum: LabelForEnumPipe,
    private persist: PersistenceService,
    private lookups: LookupsService,
    private alert: AlertService,
    private auth: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {

    this.mydt.rowsPerPageOptions = this.auth.currentUser.opts.PaginatorRowOptions;

    this.mydt.paginator = true;
    this.mydt.alwaysShowPaginator = true;

    const tmp2 = this.persist.get(this.storedInputFilters);
    if (tmp2) {
      if (tmp2.CreatedDate) {
        if (tmp2.CreatedDate[0]) {
          tmp2.CreatedDate[0] = new Date(tmp2.CreatedDate[0]);
        }
        if (tmp2.CreatedDate[1]) {
          tmp2.CreatedDate[1] = new Date(tmp2.CreatedDate[1]);
        }
      }
      if (tmp2.PublicationDate) {
        if (tmp2.PublicationDate[0]) {
          tmp2.PublicationDate[0] = new Date(tmp2.PublicationDate[0]);
        }
        if (tmp2.PublicationDate[1]) {
          tmp2.PublicationDate[1] = new Date(tmp2.PublicationDate[1]);
        }
      }
      if (tmp2.ExpirationDate) {
        if (tmp2.ExpirationDate[0]) {
          tmp2.ExpirationDate[0] = new Date(tmp2.ExpirationDate[0]);
        }
        if (tmp2.ExpirationDate[1]) {
          tmp2.ExpirationDate[1] = new Date(tmp2.ExpirationDate[1]);
        }
      }
      this.localFilter = tmp2;
    }

    const tmp = this.persist.get(this.storedDTFilters);
    if (tmp) {
      this.mydt.filters = tmp;
    }

    this.getStatuses();
    this.getVersionsData();
  }

  getVersionsData() {
    this.dashboardService.getVersionsList(this.includeArchived).subscribe({
      next: (data: docVersionDbEx[]) => {
        this.versionsData = data;

        this.versionsData.forEach(a => {
          a.LanguageName = this.labelForEnum.transform(a.LanguageCode, LanguageTypes);
          a.CreatedDate = a.CreatedDate ? new Date(a.CreatedDate) : null;
          a.PublicationDate = a.PublicationDate ? new Date(a.PublicationDate) : null;
          a.ExpirationDate = a.ExpirationDate ? new Date(a.ExpirationDate) : null;

          a.DaysTilExpiration = this.getDaysTill(a);
        });

        this.getOwners(this.versionsData);
        this.getLanguages(this.versionsData);
        this.getCategories(this.versionsData);

        setTimeout(() => this.setTableParms(), 200);

        console.log('%c Version Data', 'color:coral')
        console.log(this.versionsData);
      }, error: (err) => {
        this.alert.error('Error Gettings Versions List');
        console.log(err);
      }
    })
  }

  setTableParms() {
    this.mydt.rows = this.localFilter.rowsPerPage; //  +this.authService.currentUser.opts.PaginatorRows;
    this.mydt.first = this.localFilter.currentRow;
  }

  getStatuses() {
    this.lookups.documentStatuses.subscribe({
      next: (data: SelectItem[]) => {
        if (this.includeArchived)

          this.statuses = data.sort((a, b) => a.value > b.value ? 1 : -1);
        else
          this.statuses = data.filter(a => a.value !== DocVersionStatusTypes.Archived).sort((a, b) => a.value > b.value ? 1 : -1);
      }
    });
  }

  getCategories(versions: docVersionDbEx[]) {
    const tmp = [... new Set(versions.map(category => category.CategoryName))].sort((a, b) => a > b ? 1 : -1);
    this.categories = tmp.map(cat => ({ label: cat, value: cat }));
  }

  getOwners(versions: docVersionDbEx[]) {
    const tmp = [... new Set(versions.map(owner => owner.OwnerName))].sort((a, b) => a > b ? 1 : -1);
    this.owners = tmp.map(own => ({ label: own, value: own }));
  }

  getLanguages(versions: docVersionDbEx[]) {
    const tmp = [... new Set(versions.map(languages => languages.LanguageName))].sort((a, b) => a > b ? 1 : -1);
    this.languages = tmp.map(lang => ({ label: lang, value: lang }));
  }

  viewVersion(versionID: number) {
    this.router.navigate(['/prodocs/version', versionID]);
  }


  getDaysTill(version: docVersionDbEx): number {
    let ret = Math.abs(new Date(version.ExpirationDate).getTime() - new Date().getTime());
    ret = ret / (1000 * 3600 * 24);
    if (ret < 0) { ret = 0; }
    return ret;
  }

  getTextColor(days: number): string {
    if (days >= 60) { return 'green'; }
    else if (days < 60 && days > 30) { return 'orange'; }
    else if (days <= 30) { return 'red'; }
  }

  // ***************************
  // TABLE SORTING AND FILTERING
  // ***************************
  doFilter(event) {
    this.persist.set(this.storedDTFilters, event.filters);
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  onSort(event) {
    this.localFilter.MultiSortMeta = event.multisortmeta;
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  onPage(event) {
    this.localFilter.rowsPerPage = event.rows;
    this.localFilter.currentRow = event.first;
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  clearLocalFilter(dt: Table) {

    this.localFilter.DocumentTitle = null;
    this.localFilter.VersionNumber = null;

    this.localFilter.LanguageName = null;
    this.localFilter.CategoryName = null;
    this.localFilter.CreatedDate = null;
    this.localFilter.PublicationDate = null;
    this.localFilter.ExpirationDate = null;
    this.localFilter.StatusID = null;
    this.localFilter.OwnerName = null;

    this.localFilter.MultiSortMeta = this.multiSortMetaDefault;
    this.localFilter.rowsPerPage = +this.auth.currentUser.opts.PaginatorRows;
    this.localFilter.currentRow = 0;

    dt.clear();
    dt.filters = {};

    this.persist.set(this.storedDTFilters, null);
    this.persist.set(this.storedInputFilters, this.localFilter);

    this.getVersionsData();
  }

  toggleArchived() {
    this.includeArchived = !this.includeArchived;
    if (this.includeArchived)
      this.archivedString = 'Exclude';
    else
      this.archivedString = 'Include'
    this.getStatuses();
    this.getVersionsData();
  }

}
