export const menuData = {
  // ---------------- these first items are specifically for ProPack Portal (clients) ---------------- 
  items: [
    // {
    //   label: 'Home',
    //   needsRight: 'rtmPortalHome',
    //   routerLink: 'portal/cphome',
    //   routerLinkActiveOptions: '{exact:false;}',
    // },
    {
      label: 'Freight',
      needsRight: 'rtmPortalHome',
      routerLinkActiveOptions: '{exact:false;}',
      items: [
        {
          label: 'Quotes',
          needsRight: 'rtmPortalQuotes',
          routerLink: 'portal/cpquotes',
          routerLinkActiveOptions: '{exact:false;}'
        },
        {
          label: 'Work Orders',
          needsRight: 'rtmPortalWorkOrders',
          routerLink: 'portal/cpworkOrders',
          routerLinkActiveOptions: '{exact:false;}',
        },
        {
          label: 'Tracking',
          needsRight: 'rtmPortalTrackings',
          routerLink: 'portal/cptrackings',
          routerLinkActiveOptions: '{exact:false;}',
        },
        {
          label: 'Billing',
          needsRight: 'rtmPortalBillings',
          routerLink: 'portal/cpbillings',
          routerLinkActiveOptions: '{exact:false;}',
        },
        {
          label: 'Dashboard',
          needsRight: 'rtmPortalDashboard',
          routerLink: 'portal/cpdashboard',
          routerLinkActiveOptions: '{exact:false;}',
        }
      ]
    },
    // ----------------  end ProPack Portal (clients) items ---------------- 
    {
      label: 'Organizations',
      needsRight: 'rtmOrganizations',
      routerLinkActiveOptions: '{exact:false;}',
      items: [
        {
          label: 'Clients',
          needsRight: 'rtmOrgClients',
          routerLinkActiveOptions: '{exact:false;}',
          items: [
            {
              label: 'Freight Bill Tos',
              routerLink: 'organizations/clients/freight',
              needsRight: 'rtmOrgCliBillTos',
              routerLinkActiveOptions: '{exact:false;}'
            }
          ]
        },
        {
          label: 'Vendors',
          needsRight: 'rtmOrgVendors',
          routerLinkActiveOptions: '{exact:false;}',
          items: [
            {
              label: 'Carriers',
              routerLink: 'organizations/vendors/carriers',
              needsRight: 'rtmOrgVenCarriers',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Brokers',
              routerLink: 'organizations/vendors/brokers',
              needsRight: 'rtmOrgVenBrokers',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Insurers',
              routerLink: 'organizations/vendors/insurers',
              needsRight: 'rtmOrgVenInsurers',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Sales Reps',
              routerLink: 'organizations/vendors/sales-reps',
              needsRight: 'rtmOrgVenSalesReps',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Cross Docks',
              routerLink: 'organizations/vendors/cross-docks',
              needsRight: 'rtmOrgVenCrossDocks',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Others',
              routerLink: 'organizations/vendors/others',
              needsRight: 'rtmOrgVenOthers',
              routerLinkActiveOptions: '{exact:false;}'
            }
          ]
        },
        {
          label: 'Affiliates',
          needsRight: 'rtmOrgAffiliates',
          routerLinkActiveOptions: '{exact:false;}',
          items: [
            {
              label: 'Ship/Cons',
              routerLink: 'organizations/affiliates/ship-cons',
              needsRight: 'rtmOrgAflShipCons',
              routerLinkActiveOptions: '{exact:false;}'
            }
          ]
        },
        {
          label: 'All Organizations',
          routerLink: 'organizations/organizations',
          needsRight: 'rtmOrgOrgsAll',
          routerLinkActiveOptions: '{exact:false;}'
        }
      ]
    },
    {
      label: 'Customer Service',
      needsRight: 'rtmCustomerService',
      routerLinkActiveOptions: '{exact:false;}',
      items: [
        {
          label: 'Contacts',
          needsRight: 'rtmCsvContacts',
          routerLinkActiveOptions: '{exact:false;}',
          items: [
            {
              label: 'People',
              routerLink: 'customer-service/person-lookup',
              needsRight: 'rtmCsvConPeople',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Places',
              routerLink: 'customer-service/place-lookup',
              needsRight: 'rtmCsvConPlaces',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Phones',
              routerLink: 'customer-service/phone-lookup',
              needsRight: 'rtmCsvConPhones',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Email',
              routerLink: 'customer-service/email-lookup',
              needsRight: 'rtmCsvConEmail',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Media',
              routerLink: 'customer-service/media-lookup',
              needsRight: 'rtmCsvConMedia',
              routerLinkActiveOptions: '{exact:false;}'
            }
          ]
        }
      ]
    },
    {
      label: 'Warehouse Services',
      needsRight: 'rtmWarehouse',
      routerLinkActiveOptions: '{exact:false;}',
      items: [
        {
          label: 'Warehouse Dashboard',
          routerLink: 'warehouse-services/warehouse-dashboard',
          needsRight: 'rtmWarWarehouseDashboard',
          routerLinkActiveOptions: '{exact:false;}'
        }
      ]
    },
    {
      label: 'Inventory Control',
      needsRight: 'rtmInventoryControl',
      routerLinkActiveOptions: '{exact:false;}'
    },
    {
      label: 'Order Management',
      needsRight: 'rtmOrderManagement',
      routerLinkActiveOptions: '{exact:false;}'
    },
    {
      label: 'Shipping',
      needsRight: 'rtmShipping',
      routerLinkActiveOptions: '{exact:false;}',
      items: [
        {
          label: 'Quoting',
          needsRight: 'rtmShpQuoting',
          routerLinkActiveOptions: '{exact:false;}',
          items: [
            {
              label: 'Quotes',
              routerLink: 'shipping/quotes',
              needsRight: 'rtmShpQuoQuotes',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'New Quote',
              routerLink: 'shipping/order-freight-edit/QT/-2',
              needsRight: 'actCreateQuote',
              routerLinkActiveOptions: '{exact:false;}'
            }
          ]
        },
        {
          label: 'Forwarding',
          needsRight: 'rtmShpForwarding', routerLinkActiveOptions: '{exact:false;}',
          items: [
            {
              label: 'Work Orders',
              routerLink: 'shipping/orders-freight',
              needsRight: 'rtmShpFwdWorkOrders',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'New Work Order',
              routerLink: 'shipping/order-freight-edit/WO/-2',
              needsRight: 'actCreateWorkOrder',
              routerLinkActiveOptions: '{exact:false;}'
            }
          ]
        },
        {
          label: 'Tracking',
          needsRight: 'rtmShpTracking',
          routerLinkActiveOptions: '{exact:false;}',
          items: [
            {
              label: 'Tracked Work Orders',
              routerLink: 'shipping/tracking',
              needsRight: 'rtmShpTrkTrackedWOs',
              routerLinkActiveOptions: '{exact:false;}'
            }
          ]
        }
      ]
    },
    {
      label: 'Billing',
      needsRight: 'rtmBilling',
      routerLinkActiveOptions: '{exact:false;}',
      items: [
        {
          label: 'Freight Billing',
          routerLink: 'billing/freight-billing',
          needsRight: 'rtmBilFreightBilling',
          routerLinkActiveOptions: '{exact:false;}'
        },
        {
          label: 'Freight Payables',
          routerLink: 'billing/freight-payables',
          needsRight: 'rtmBilFreightPayables',
          routerLinkActiveOptions: '{exact:false;}'
        },
        {
          label: 'Credit Holds',
          routerLink: 'billing/credit-holds',
          needsRight: 'rtmBilCreditHolds',
          routerLinkActiveOptions: '{exact:false;}'
        }
      ]
    },
    {
      label: 'Reports',
      needsRight: 'rtmReports',
      routerLinkActiveOptions: '{exact:false;}',
      items: [
        {
          label: 'Run Report',
          needsRight: 'rtmReportDisplay',
          routerLink: 'reports/report-display-list',
          routerLinkActiveOptions: '{exact:false;}'
        },
        {
          label: 'Report Settings',
          needsRight: 'rtmReportEdit',
          routerLinkActiveOptions: '{exact:false;}',
          items: [
            {
              label: 'Edit Reports',
              needsRight: 'rtmReportEdit',
              routerLink: 'reports/report-edit',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Edit Report Categories',
              needsRight: 'rtmReportEdit',
              routerLink: 'reports/report-categories',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Edit Report Options',
              needsRight: 'rtmReportEdit',
              routerLink: 'reports/report-option-list',
              routerLinkActiveOptions: '{exact:false;}'
            }
          ]
        }
      ]
    },

    {
      label: 'SendIt',
      needsRight: 'rtmSendIt',
      routerLinkActiveOptions: '{exact:false;}',
      items: [
        {
          label: 'Search',
          routerLink: 'manifesting/view-shipment',
          needsRight: 'rtmSendItViewShipment',
          routerLinkActiveOptions: '{exact:false;}',
          icon: 'pi pi-search'
        },
        { separator: true },
        {
          label: 'Orders',
          routerLink: 'manifesting/order',
          needsRight: 'rtmSendItOrder',
          routerLinkActiveOptions: '{exact:false;}'
        },
        {
          label: 'Dashboard',
          routerLink: 'manifesting/dashboard',
          needsRight: 'rtmSendItDashboard',
          routerLinkActiveOptions: '{exact:false;}',
        },
        {
          label: 'Batch',
          routerLink: 'manifesting/batch',
          needsRight: 'rtmSendItBatch',
          routerLinkActiveOptions: '{exact:false;}'
        },
        {
          label: 'Manual Shipment',
          routerLink: 'manifesting/manual',
          needsRight: 'rtmSendItManual',
          routerLinkActiveOptions: '{exact:false;}'
        },
        {
          label: 'Active Shipments',
          routerLink: 'manifesting/shipmenthistory',
          needsRight: 'rtmSendItShipmentHistory',
          routerLinkActiveOptions: '{exact:false;}'
        },
        {
          label: 'Close Manifest',
          routerLink: 'manifesting/closemanifest',
          needsRight: 'rtmSendItCloseManifest',
          routerLinkActiveOptions: '{exact:false;}'
        },
        { separator: true },
        {
          label: 'Accounts 3rd Party',
          routerLink: 'admin/sendit-accounts3party',
          needsRight: 'rtmSendIt3Party',
          routerLinkActiveOption: '{exact:false;}'
        }
      ]
    },

    {
      label: 'ProAudit',
      needsRight: 'rtmAuditing',
      routerLinkActiveOptions: '{exact:false;}',
      items: [
        {
          label: 'Search',
          needsRight: 'rtmAuditReview',
          routerLink: 'auditing/search-audit',
          routerLinkActiveOptions: '{exact:false;}',
          icon: 'pi pi-search'
        },
        { separator: true },
        {
          label: 'Actionable',
          needsRight: 'rtmAuditImport',
          routerLinkActiveOptions: '{exact:false;}',
          items: [
            {
              label: 'Dashboard',
              needsRight: 'rtmAuditImport',
              routerLink: 'auditing/action-dashboard',
              routerLinkActiveOptions: '{exact:false;}',
            },
            { separator: true },
            {
              label: 'All Data',
              needsRight: 'rtmAuditImport',
              routerLink: 'auditing/compare',
              routerLinkActiveOptions: '{exact:false;}',
              icon: 'pi pi-inbox'
            },
            {
              label: 'In Dispute',
              needsRight: 'rtmAuditImport',
              routerLink: 'auditing/dispute',
              routerLinkActiveOptions: '{exact:false;}',
              icon: 'pi pi-exclamation-triangle',
            },
          ]
        },
        // {
        //   label: 'Dashboard',
        //   needsRight: 'rtmAuditDashboard',
        //   // routerLink: 'auditing/dashboard',
        //   routerLinkActiveOptions: '{exact:false;}',
        //   items: [
        //     {
        //       label: 'Summary',
        //       needsRight: 'rtmAuditDashboard',
        //       routerLink: 'auditing/dashboard',
        //       routerLinkActiveOptions: '{exact:false;}',
        //     },
        //     {
        //       label: 'Residential',
        //       needsRight: 'rtmAuditDashboard',
        //       routerLink: 'auditing/review-residential',
        //       routerLinkActiveOptions: '{exact:false;}'
        //     }
        //   ]

        // },
        {
          label: 'Review',
          needsRight: 'rtmAuditReview',
          routerLinkActiveOptions: '{exact:false;}',
          items: [
            {
              label: 'Summary',
              needsRight: 'rtmAuditDashboard',
              routerLink: 'auditing/dashboard',
              routerLinkActiveOptions: '{exact:false;}',
            },
            {
              label: 'Residential',
              needsRight: 'rtmAuditDashboard',
              routerLink: 'auditing/review-residential',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Weights & Dims',
              needsRight: 'rtmAuditReview',
              routerLink: 'auditing/review-weights-dims',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Pending',
              needsRight: 'rtmAuditDashboard',
              routerLink: 'auditing/audit-pending2',
              routerLinkActiveOptions: '{exact:false;}'
            }
          ]
        },
        {
          label: 'Utilities',
          needsRight: 'rtmAuditBilling',
          routerLink: 'auditing/billing',
          routerLinkActiveOptions: '{exact:false;}',
          items: [
            {
              label: 'Charge Descriptions',
              needsRight: 'rtmAuditReview',
              routerLink: 'admin/audit-carrier-surcharges', // auditing/review-charges',
              routerLinkActiveOptions: '{exact:false;}'
            },

          ]
        },
        {
          label: 'Import',
          needsRight: 'rtmAuditImport',
          routerLink: 'auditing/import',
          routerLinkActiveOptions: '{exact:false;}'
        },
        { separator: true },
        {
          label: 'Archived Screens',
          needsRight: 'rtmAuditReview',
          icon: 'pi pi-moon',
          routerLinkActiveOptions: '{exact:false;}',
          items: [
            {
              label: 'Invoices',
              needsRight: 'rtmAuditReview',
              routerLink: 'auditing/invoice-audit',
              routerLinkActiveOptions: '{exact:false;}',
            },
            {
              label: 'BackBill',
              needsRight: 'rtmAuditBilling',
              routerLink: 'auditing/billing-back-bill',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Exceptions',
              needsRight: 'rtmAuditBilling',
              routerLink: 'auditing/billing-VAS',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Review Pending',
              needsRight: 'rtmAuditDashboard',
              routerLink: 'auditing/audit-pending',
              routerLinkActiveOptions: '{exact:false;}'
            }
          ]
        },
      ]
    },
    {
      label: 'GoTime',
      needsRight: 'rtmTime',
      routerLinkActiveOptions: '{exact: false;}',
      items: [
        {
          label: 'Dashboard',
          routerLink: 'time/dashboard',
          needsRight: 'rtmTimeDashboard',
          routerLinkActiveOptions: '{exact:false}'
        },
        {
          label: 'My Team',
          needsRight: 'rtmTimeEmployees',
          routerLinkActiveOptions: '{exact:false;}',
          items: [
            {
              label: 'Employees',
              routerLink: 'time/employees',
              needsRight: 'rtmTimeEmployees',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Schedules',
              routerLink: 'time/schedules',
              needsRight: 'rtmTimeSchedules',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Review Time',
              routerLink: 'time/review-time',
              needsRight: 'rtmTimeReview',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Issues',
              routerLink: 'time/issues',
              needsRight: 'rtmTimeIssues',
              routerLinkActiveOptions: '{exact:false;}'
            }
          ]
        },
        { separator: true },
        {
          label: 'Client Functions',
          routerLink: 'time/clients',
          needsRight: 'rtmTimeClients',
          routerLinkActiveOptions: '{exact:false;}'
        },

        {
          label: 'Functions',
          routerLink: 'time/functions',
          needsRight: 'rtmTimeFunctions',
          routerLinkActiveOptions: '{exact:false;}'
        },
        {
          label: 'Client Jobs',
          routerLink: 'time/client-jobs',
          needsRight: 'rtmTimeClientReference',
          routerLinkActiveOptions: '{exact:false;}'
        },
        // {
        //   label: 'Accounting',
        //   needsRight: 'rtmTimeAccounting',
        //   routerLinkActiveOptions: '{exact:false;}',
        //   items: [
        //     {
        //       label: 'Export Billing',
        //       routerLink: 'time/export-billing',
        //       needsRight: 'rtmTimeAccountingExport',
        //       routerLinkActiveOptions: '{exact:false;}'
        //     }
        //   ]
        // },
        {
          label: 'HR',
          needsRight: 'rtmTimeHR',
          routerLinkActiveOptions: '{exact:false;}',
          items: [
            {
              label: 'Employees',
              routerLink: 'time/hr-employees',
              needsRight: 'rtmTimeHR',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Payroll',
              routerLink: 'time/export-payroll',
              needsRight: 'rtmTimeExportPayroll',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Payroll Types',
              routerLink: 'time/hr-payroll-types',
              needsRight: 'rtmTimeHR',
              routerLinkActiveOptions: '{exact:false;}'
            }
          ]
        }
      ]
    },
    {
      label: 'ProDocs',
      needsRight: 'rtmProDocs',
      routerLinkActiveOptions: '{exact:false;}',
      items: [
        {
          label: 'Library',
          needsRight: 'rtmProDocsLibrary',
          routerLink: 'prodocs/library',
          routerLinkActiveOptions: '{exact:false}'
        },
        {
          label: 'Dashboard',
          needsRight: 'rtmProDocsDashboard',
          routerLinkActiveOptions: '{exact:false;}',
          items: [
            {
              label: 'Admin Dashboard',
              routerLink: 'prodocs/admin-dashboard',
              needsRight: 'actProDocsAdmin',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Team Dashboard',
              routerLink: 'prodocs/team-dashboard',
              needsRight: 'rtmProDocsDashboardTeam',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Actor Dashboard',
              routerLink: 'prodocs/actor-dashboard',
              needsRight: 'rtmProDocsDashboardActor',
              routerLinkActiveOptions: '{exact:false;}'
            },
          ]
        },
        { separator: true },
        {
          label: 'Documents',
          routerLink: 'prodocs/documents',
          needsRight: 'rtmProDocsDocuments',
          routerLinkActiveOptions: '{exact:false;}'
        },
        {
          label: 'Assignments',
          routerLink: 'prodocs/assignments',
          needsRight: 'rtmProDocsAssignments',
          routerLinkActiveOptions: '{exact:false;}'
        },
        {
          label: 'Triggers',
          routerLink: 'prodocs/triggers',
          needsRight: 'rtmProDocsTriggers',
          routerLinkActiveOptions: '{exact:false;}'
        }
      ]
    },

    {
      label: 'Asset Tracker',
      needsRight: 'rtmAssets',
      routerLinkActiveOptions: '{exact: false;}',
      items: [
        {
          label: 'Dashboard',
          routerLink: 'assets/dashboard',
          needsRight: 'rtmAssetsDashboard',
          routerLinkActiveOptions: '{exact:false}'
        },
        {
          label: 'Asset Tracker',
          routerLink: 'assets/assets',
          needsRight: 'rtmAssetsAssets',
          routerLinkActiveOptions: '{exact:false}'
        }
      ]
    },
    {
      label: 'Admin',
      needsRight: 'rtmAdmin',
      routerLinkActiveOptions: '{exact:false;}',
      items: [
        {
          label: 'Billing Admin',
          needsRight: 'rtmAdmBilling',
          routerLinkActiveOptions: '{exact:false;}',
          items: [
            {
              label: 'Vendor Fees',
              routerLink: 'admin/vendor-fees',
              needsRight: 'rtmAdmBilVendorFees',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Handling Charges',
              routerLink: 'admin/handling-charges',
              needsRight: 'rtmAdmBilHandlingCharges',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Payment Terms',
              routerLink: 'admin/payment-terms',
              needsRight: 'rtmAdmBilPaymentTerms',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Payment Types',
              routerLink: 'admin/payment-types',
              needsRight: 'rtmAdmBilPaymentTypes',
              routerLinkActiveOptions: '{exact:false;}'
            }
          ]
        },
        {
          label: 'Shipping Admin',
          needsRight: 'rtmAdmShippingAdmin',
          routerLinkActiveOptions: '{exact:false;}',
          items: [
            {
              label: 'Shipping Services',
              routerLink: 'admin/shipping-services',
              needsRight: 'rtmAdmShpShippingServices',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Service Levels',
              routerLink: 'admin/service-levels',
              needsRight: 'rtmAdmShpServiceLevels',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Fuel Surcharges',
              routerLink: 'admin/fuel-surcharges',
              needsRight: 'rtmAdmShpFuelSurcharges',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'State/Province Codes',
              routerLink: 'admin/state-province-codes',
              needsRight: 'rtmAdmShpStateProvCodes',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Country Codes',
              routerLink: 'admin/country-codes',
              needsRight: 'rtmAdmShpCountryCodes',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Airport Codes',
              routerLink: 'admin/airport-codes',
              needsRight: 'rtmAdmShpAirportCodes',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Cities',
              routerLink: 'admin/cities',
              needsRight: 'rtmAdmShpCities',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'NMFC Numbers',
              routerLink: 'admin/nmfc-numbers',
              needsRight: 'rtmAdmShpNMFCNumbers',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Tracking Categories',
              routerLink: 'admin/tracking-categories',
              needsRight: 'rtmAdmShpTrackingCategories',
              routerLinkActiveOptions: '{exact:false;}'
            }
          ]
        },
        {
          label: 'Rating Admin',
          needsRight: 'rtmAdmRating',
          routerLinkActiveOptions: '{exact:false;}',
          items: [
            {
              label: 'Carrier Rates',
              routerLink: 'admin/carrier-rates',
              needsRight: 'rtmAdmRatCarrierRates',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Carrier Areas',
              routerLink: 'admin/carrier-areas',
              needsRight: 'rtmAdmRatCarrierAreas',
              routerLinkActiveOptions: '{exact:false;}'
            }
          ]
        },
        {
          label: 'SendIt Admin',
          needsRight: 'rtmAdmSendIt',
          routerLinkActiveOptions: '{exact:false;}',
          items: [

            {
              label: 'Configuration Home',
              routerLink: 'manifesting/config',
              needsRight: 'rtmAdmSendIt',
              routerLinkActiveOption: '{exact:false;}',
            },
            {
              label: 'Rate Types',
              routerLink: 'manifesting/config/rate-types',
              needsRight: 'rtmAdmSendIt',
              routerLinkActiveOption: '{exact:false;}'
            },
            {
              label: 'Payment Types',
              routerLink: 'admin/sendit-payment-types',
              needsRight: 'rtmAdmSendIt',
              routerLinkActiveOption: '{exact:false;}'
            },
            {
              label: 'Rate Division Types',
              routerLink: 'manifesting/config/rate-division-types',
              needsRight: 'rtmAdmSendIt',
              routerLinkActiveOption: '{exact:false;}'
            },
            {
              label: 'Rating Engines',
              routerLink: 'manifesting/config/rating-engines',
              needsRight: 'rtmAdmSendIt',
              routerLinkActiveOption: '{exact:false;}',
              items: [
                {
                  label: 'Rating Engine Services',
                  routerLink: 'manifesting/config/rating-engine-services',
                  needsRight: 'rtmAdmSendIt',
                  routerLinkActiveOption: '{exact:false;}'
                },
              ]
            },
          ]
        },
        {
          label: 'Asset Admin',
          needsRight: 'rtmAdmAssets',
          items: [
            {
              label: 'Asset Types',
              routerLink: 'admin/asset-types',
              needsRight: 'rtmAdmAssets',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Asset Statuses',
              routerLink: 'admin/asset-statuses',
              needsRight: 'rtmAdmAssets',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Asset Actions',
              routerLink: 'admin/asset-actions',
              needsRight: 'rtmAdmAssets',
              routerLinkActiveOptions: '{exact:false;}'
            }
          ]
        },
        {
          label: 'GoTime Admin',
          needsRight: 'rtmAdmTime',
          items: [
            {
              label: 'Departments',
              routerLink: 'admin/time-departments',
              needsRight: 'rtmAdmTime',
              routerLinkActiveOptions: '{exact:false;}'
            },
            // {
            //   label: 'Function Groups',
            //   routerLink: 'admin/time-function-groups',
            //   needsRight: 'rtmAdmTime',
            //   routerLinkActiveOptions: '{exact:false;}'
            // },
            {
              label: 'Functions',
              routerLink: 'admin/time-functions',
              needsRight: 'rtmAdmTime',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Function Types',
              routerLink: 'admin/time-function-types',
              needsRight: 'rtmAdmTime',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Issue Types',
              routerLink: 'admin/time-issue-types',
              needsRight: 'rtmAdmTime',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Schedule Types',
              routerLink: 'admin/time-schedule-types',
              needsRight: 'rtmAdmTime',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Track Types',
              routerLink: 'admin/time-track-types',
              needsRight: 'rtmAdmTime',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Work Groups',
              routerLink: 'admin/time-work-groups',
              needsRight: 'rtmAdmTime',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Settings',
              routerLink: 'admin/go-time-settings',
              needsRight: 'rtmAdmTime',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Warehouse Payroll Settings',
              routerLink: 'admin/time-warehouse-payroll-settings',
              needsRight: 'rtmAdmTime',
              routerLinkActiveOptions: '{exact:false}'
            }
          ]
        },
        {
          label: 'ProDocs Admin',
          needsRight: 'rtmAdmProDocs',
          items: [
            {
              label: 'Categories',
              routerLink: 'admin/prodocs-categories',
              needsRight: 'rtmAdmProDocs',
              routerLinkActiveOptions: '{exact:false}'
            },
            {
              label: 'Statuses',
              routerLink: 'admin/prodocs-statuses',
              needsRight: 'rtmAdmProDocs',
              routerLinkActiveOptions: '{exact:false}'
            },
            {
              label: 'Roles',
              routerLink: 'admin/prodocs-roles',
              needsRight: 'rtmAdmProDocs',
              routerLinkActiveOptions: '{exact:false}'
            },
          ]
        },
        {
          label: 'Auditing Admin',
          needsRight: 'rtmAdmAuditing',
          items: [
            {
              label: 'Carriers',
              routerLink: 'admin/audit-carriers',
              needsRight: 'rtmAdmAuditing',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Carrier Surcharges',
              routerLink: 'admin/audit-carrier-surcharges',
              needsRight: 'rtmAdmAuditing',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'ProPack Surcharges',
              routerLink: 'admin/audit-propack-surcharges',
              needsRight: 'rtmAdmAuditing',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Surcharge Types',
              routerLink: 'admin/audit-surcharge-types',
              needsRight: 'rtmAdmAuditing',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Service Mappings',
              routerLink: 'admin/audit-service-mappings',
              needsRight: 'rtmAdmAuditing',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'FedEx Codes',
              routerLink: 'admin/audit-fedex-codes',
              needsRight: 'rtmAdmAuditing',
              routerLinkActiveOptions: '{exact:false;}'
            }
          ]
        },
        {
          label: 'Utilities',
          needsRight: 'rtmAdmUtilities',
          routerLinkActiveOptions: '{exact:false;}',
          items: [
            {
              label: 'Piece Types',
              routerLink: 'admin/piece-types',
              needsRight: 'rtmAdmUtlPieceTypes',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Place Types',
              routerLink: 'admin/place-types',
              needsRight: 'rtmAdmUtlPlaceTypes',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Default Subject Tos',
              routerLink: 'admin/default-subject-tos',
              needsRight: 'rtmAdmUtlSubjectTos',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Currency Exchange',
              routerLink: 'admin/currency-exchange',
              needsRight: 'rtmAdmUtlCurExchange',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Email Types',
              routerLink: 'admin/email-types',
              needsRight: 'rtmAdmUtlEmailTypes',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Activity Types',
              routerLink: 'admin/activity-types',
              needsRight: 'rtmAdmUtlActivityTypes',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'International Types',
              routerLink: 'admin/international-types',
              needsRight: 'rtmAdmUtlIntlTypes',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Phone Types',
              routerLink: 'admin/phone-types',
              needsRight: 'rtmAdmUtlPhoneTypes',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Media Types',
              routerLink: 'admin/media-types',
              needsRight: 'rtmAdmUtlMediaTypes',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Note Types',
              routerLink: 'admin/note-types',
              needsRight: 'rtmAdmUtlNoteTypes',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Order Holds',
              routerLink: 'admin/order-holds',
              needsRight: 'rtmAdmUtlOrderHolds',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Order Issues',
              routerLink: 'admin/order-issues',
              needsRight: 'rtmAdmUtlOrderIssues',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Payable Types',
              routerLink: 'admin/payable-types',
              needsRight: 'rtmAdmUtlPayableTypes',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Shipment Types',
              routerLink: 'admin/shipment-types',
              needsRight: 'rtmAdmUtlShipmentTypes',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Task Types',
              routerLink: 'admin/task-types',
              needsRight: 'rtmAdmUtlTaskTypes',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Tracking Categories',
              routerLink: 'admin/tracking-categories',
              needsRight: 'rtmAdmUtlTrackingCategories',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Warehouses',
              routerLink: 'admin/warehouses',
              needsRight: 'rtmAdmUtlWarehouses',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Statuses',
              routerLink: 'admin/statuses',
              needsRight: 'rtmAdmUtlStatuses',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Association Types',
              routerLink: 'admin/association-types',
              needsRight: 'rtmAdmUtlAssocTypes',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Service Processes',
              routerLink: 'admin/service-processes',
              needsRight: 'rtmAdmUtlServiceProcesses',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Service Units',
              routerLink: 'admin/service-units',
              needsRight: 'rtmAdmUtlServiceUnits',
              routerLinkActiveOptions: '{exact:false;}'
            }
          ]
        },
        {
          label: 'Security',
          needsRight: 'rtmAdmSecurity',
          routerLinkActiveOptions: '{exact:false;}',
          items: [
            {
              label: 'Group Types',
              routerLink: 'security/group-types',
              needsRight: 'rtmAdmSecGroupTypes',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Users',
              routerLink: 'security/users',
              needsRight: 'rtmAdmSecUsers',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Groups',
              routerLink: 'security/groups',
              needsRight: 'rtmAdmSecGroups',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Rights',
              routerLink: 'security/rights',
              needsRight: 'rtmAdmSecRights',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Edit Personnel',
              routerLink: 'organizations/company-edit',
              needsRight: 'rtmAdmSecPersonnel',
              routerLinkActiveOptions: '{exact:false;}'
            }
          ]
        },
        {
          label: 'PrintManager Admin',
          needsRight: 'rtmAdmPrintManager',
          routerLinkActiveOptions: '{exact:false;}',
          items: [
            {
              label: 'Printer Types',
              routerLink: 'admin/print-manager-printer-types',
              needsRight: 'rtmAdmPrintManager',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Printers',
              routerLink: 'admin/print-manager-printers',
              needsRight: 'rtmAdmPrintManager',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Reports',
              routerLink: 'admin/print-manager-reports',
              needsRight: 'rtmAdmPrintManager',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Printer Assignments',
              routerLink: 'admin/printer-users',
              needsRight: 'rtmAdmPrintManager',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Print Queue',
              routerLink: 'admin/print-manager-queue',
              needsRight: 'rtmAdmPrintManager',
              routerLinkActiveOptions: '{exact:false;}'
            },
            {
              label: 'Add Printers',
              routerLink: 'admin/add-printers',
              needsRight: 'rtmAdmPrintManager',
              routerLinkActiveOptions: '{exact:false;}'
            }
          ]
        },
        {
          label: 'System Options',
          routerLink: 'admin/system-options',
          needsRight: 'rtmAdmSystemOptions',
          routerLinkActiveOptions: '{exact:false;}'
        }
      ]
    }
  ]
};
