import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minsToTime'
})
export class MinsToTimePipe implements PipeTransform {

  transform(value: number): string {

    if (!value) {
      return '00:00';
    }

    const hrs = ('00' + Math.floor(value / 60));
    const mins = ('00' + (value % 60));
    return hrs.substr(hrs.length - 2, 2) + ':' + mins.substr(mins.length - 2, 2);
  }

}
