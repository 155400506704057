import { MenuItem } from 'primeng/api/menuitem';

// #region SEC
/** A class which represents the sec.EntityUserGroups table. */
export class secEntityUserGroup {
	GroupID: number;
	EntityUserID: number;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the sec.EntityUsers stuff table. */
export class secEntityUser {
	EntityUserID: number;
	EntityID: number;
	UserID: number;
	IsDefaultForUser: boolean;
	PersonID: number;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the sec.GroupRights table. */
export class secGroupRight {
	GroupID: number;
	RightID: number;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the sec.Groups table. */
export class secGroup {
	GroupID: number;
	GroupName: string;
	GroupTypeID: any;
	IsActive: boolean;
	IsRestricted: boolean;
	IsClientAssignable: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
	P3PLGroupID: number;
}
/** A class which represents the sec.GroupTypes table. */
export class secGroupType {
	GroupTypeID: any;
	GroupType: string;
	IsActive: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
	P3PLGroupTypeID: number;
}
/** A class which represents the sec.Rights table. */
export class secRight {
	RightID: number;
	RightName: string;
	RightDescription: string;
	RightTypeID: number;
	IsActive: boolean;
	IsClientAssignable: boolean;
	ReportID: number;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the sec.UserRightAdditions table. */
export class secUserRightAddition {
	EntityUserID: number;
	RightID: number;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the sec.UserRightExceptions table. */
export class secUserRightException {
	EntityUserID: number;
	RightID: number;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the sec.UserRightsVw view. */
export class secUserRightsVw {
	EntityUserID: number;
	RightName: string;
}
/** A class which represents the sec.UserRouteAdditions table. */
export class secUserRouteAddition {
	EntityUserID: number;
	RouteID: number;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the sec.UserRouteExceptions table. */
export class secUserRouteException {
	EntityUserID: number;
	RouteID: number;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the sec.Users table. */
export class secUser {
	UserID: number;
	UserLogin: string;
	UserPassword: string;
	UserPasswordEnc: string;
	DefaultWarehouseID: number;
	HomeWarehouseID: number;
	ActiveWarehouseID: number;
	Initials: string;
	IsActive: boolean;
	OldUserID: number;
	PersonID: number;
	Tag: string;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}

/** A class which represents the sec.UserWarehouseAccess table. */
export class secUserWarehouseAccess {
	UserWarehouseAccessID: number;
	EntityUserID: number;
	ClientWarehouseID: number;
	UserWarehouseAccessLevelID: any;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the sec.UserWarehouseAccessLevels table. */
export class secUserWarehouseAccessLevel {
	UserWarehouseAccessLevelID: any;
	AccessLevelDescription: string;
}

export interface arcMenuItem extends MenuItem {
	needsRight?: string;
}

export class secCurrentUser {
	opts: any;
	ents: any;
	pers: any;
	rts: string[];
	curEntity: any;
	Entities: any;
	Options: any;
	UserID: number;
	Initials: string;
	PersonName: string;
	DefaultWarehouse: number;
	GoTimeInfo: secGoTime;
	MenuItems: arcMenuItem[];
}

export class secGoTime {
	EmployeeID: number;
	FirstName: string;
	LastName: string;
	ReportsToEmployeeName: string;
	ClockStatus: string;
	FunctionID: number;
	FunctionCode: number;
	FunctionName: string;
	ClientID: number;
	ClientShortName: string;
	CFTrackTypeID?: number;
	TrackTypeID?: number;
	TrackID?: number;
	TrackReference: string;
	SegmentStartTime: Date;
	ClockInTime: Date;
	DefaultWarehouseID: number;
	HomeWarehouseID: number;
	// FOR UI USE ONLY
	FunctionCodeName?: string;
	WeekTotal: number;
}
export class secRightType {
	RightTypeID: number;
	RightTypeCode: string;
	RightTypeDescription: string;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}

export class secPasswordUpdate {
	OldPassword: string;
	NewPassword: string;
}

export class secUserValidations {
	UserID: number;
	ValidationType: string;
	ValidationMessages: string[];
	ShowStop: boolean;
}

//#endregion SEC
