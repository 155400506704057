import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

// import { server } from '../_models';
import { orgEmail, orgEmailVw } from '../_models/ORG.model';

import { OrgItemType } from '../_models/enums.model';
import { PEMEmail } from '../_models/PEMLink.model';

@Injectable()
export class EmailsService {
  api = 'orgEmail/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  get(itemID: number) {
    return this.http.get<orgEmail>(this.apiURL + this.api + itemID);
  }

  update(item: orgEmail) {
    return this.http.put(this.apiURL + this.api + item.EmailID, item);
  }

  create(item: orgEmail) {
    return this.http.post(this.apiURL + this.api, item);
  }

  delete(itemID: number) {
    return this.http.delete(this.apiURL + this.api + itemID);
  }
}

@Injectable()
export class EmailExService {
  api = 'orgEmailEx/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll(itemType: OrgItemType, itemID: number, andLinked: boolean) {
    const plusLinked =  { params: new HttpParams().set('linked', andLinked ? 'true' : 'false') };
    return this.http.get<orgEmailVw[]>(this.apiURL + this.api + itemType + '/' + itemID, plusLinked);
  }

  get(itemID: number) {
    return this.http.get<orgEmailVw>(this.apiURL + this.api + itemID);
  }

  update(item: orgEmailVw) {
    return this.http.put(this.apiURL + this.api + item.EmailID, item );
  }

  create(item: orgEmailVw) {
    return this.http.post(this.apiURL + this.api, item);
  }

  delete(itemID: number) {
    return this.http.delete(this.apiURL + this.api + itemID);
  }
}

@Injectable()
export class PEMEmailService {
  api = 'PEMEmail/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAllForItem(itemType: OrgItemType, itemID: number) {
    return this.http.get<PEMEmail[]>(this.apiURL + this.api + itemType + '/' + itemID);
  }

  get(itemID: number) {
  return this.http.get<PEMEmail>(this.apiURL + this.api + itemID);
  }

  save(item: PEMEmail) {
    return this.http.post<PEMEmail>(this.apiURL + this.api, item);
  }

  delete(itemID: number) {
    return this.http.delete(this.apiURL + this.api + itemID);
  }

  link(EmailID: number, itemID: number, itemType: OrgItemType) {
    let itm;
    switch (itemType) {
      case OrgItemType.Entity:
        itm = { 'EmailID': EmailID, 'EntityID': itemID };
        break;
      case OrgItemType.Place:
        itm = { 'EmailID': EmailID, 'PlaceID': itemID };
        break;
      case OrgItemType.Person:
        itm = { 'EmailID': EmailID, 'PersonID': itemID };
        break;
    }
    console.log('link email', itm);
    return this.http.put(this.apiURL + this.api, itm);
  }
}

@Injectable()
export class EmailVwService {
  api = 'orgEmailVw/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<orgEmailVw[]>(this.apiURL + this.api);
  }

  get(itemID: number) {
    return this.http.get<orgEmailVw>(this.apiURL + this.api + itemID);
  }

  delete(itemID: number) {
    return this.http.delete(this.apiURL + 'orgEmail/' + itemID);
  }
}
