import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import { orgOrganizationsListVw } from '../_models/ORG.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class OrganizationsListService {

  api = 'orgOrganizationsListVw/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<orgOrganizationsListVw[]>(this.apiURL + this.api);

  }


}
