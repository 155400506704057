import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minusToParens'
})
export class MinusToParensPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value === null || value === undefined) {
      return value;
    }
    return value.charAt(0) === '-' ?
      '(' + value.substring(1, value.length) + ')' :
      value;
  }
}
