import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Subscription } from 'rxjs';
import { orgVendorEdit, orgEntity } from '../_models/ORG.model';
import { SelectItem } from 'primeng/api';

@Injectable()
export class VendorsService {

  vendorType = '';
  vendorTypeSubscription: Subscription;

  api = 'orgVendor/';

  constructor(
    private http: HttpClient, @Inject('apiURL') private apiURL) {
  }

  getAll(vendorType: string) {
    // get all vendors of a particular vendor type
    this.api = 'orgVendor/';
    return this.http.get<orgVendorEdit[]>(this.apiURL + this.api + vendorType);
  }

  getByID(id: number) {
    this.api = 'orgVendor/';
    return this.http.get<orgVendorEdit>(this.apiURL + this.api + 'edit/' + id);
  }

  getForAssociatedID(vendorType: string, clientID: number) {
    this.api = 'orgVendor/';
    return this.http.get<orgVendorEdit[]>(this.apiURL + this.api + vendorType + '/associated/' + clientID);
  }

  // GetVendorByIDForAssociateByType(id: number, vendorType: string, associatedID: number) {
  //   this.api = 'orgVendor/';
  //   return this.http.get<orgVendorEdit[]>(this.apiURL + this.api + vendorType + '/' + id + '/associated/' + associatedID);
  // }

  update(vendor: orgVendorEdit) {
    this.api = 'orgVendorEdit/';
    return this.http.put<orgVendorEdit>(this.apiURL + this.api + vendor.EntityID, vendor);
  }

  // search(vendorType: string, frag: string) {
  //   this.api = 'orgVendor/';
  //   return this.http.get<orgEntity[]>(this.apiURL + this.api + vendorType + '/search', { params: { frag: frag }});
  // }

  getCountriesForVendor(id: number) {
    this.api = 'orgVendorEdit/';
    return this.http.get<SelectItem[]>(this.apiURL + this.api + 'countries/' + id);
  }

  getAvailableCountriesForVendor(id: number) {
    this.api = 'orgVendorEdit/';
    return this.http.get<SelectItem[]>(this.apiURL + this.api + 'countries/' + id + '/available');
  }

  putCountriesForVendor(id: number, countries: SelectItem[]) {
    this.api = 'orgVendorEdit/';
    return this.http.put(this.apiURL + this.api + 'countries/' + id, countries);
  }
}
