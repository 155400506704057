import { Injectable, Inject, SkipSelf } from '@angular/core';
// // import { server } from '../_models/index';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CheckAddress, DashboardAction, HistoryPackageSummary, HistoryShipmentSummary, ManifestReportReturn, SIBatch, SIBatchPackage, SIBatchPackageSummary, SIOrderFault, SIPackage, SIPackageLabel, SISendItPackaging, SIShipment, SendItDashboardResult, ShipViaPull, cfgPaymentTypeDb } from 'app/_models/SEND.model';
import { PackageService, SIFault, ShippingServiceCode, SIPackageSummary, ShipmentHistory, ManifestHistory, VoidPackage } from 'app/_models/SEND.model';
import { AddressCheckType } from 'app/_models/enums.model';

@Injectable()
export class SendItService {
  api = 'sendIt/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  pullP3PLOrder(OrderID: number) {
    return this.http.get<SIShipment>(this.apiURL + this.api + 'P3PL/' + OrderID);
  }

  pullArcturusOrder(OrderID: number) {
    return this.http.get<SIShipment>(this.apiURL + this.api + 'arc/' + OrderID);
  }

  getShipViaForOrder(OrderID: number) {
    return this.http.get<ShipViaPull>(this.apiURL + this.api + 'shipVia/' + OrderID);
  }

  getShipViaForParms(ShippingServiceCode: string, ClientID: number, WarehouseID: number) {
    return this.http.get<ShipViaPull>(this.apiURL + this.api + 'shipVia/' + ShippingServiceCode + '/' + ClientID + '/' + WarehouseID);
  }

  getPackageServices(PackageID: number, ClientID: number) {
    return this.http.get<PackageService[]>(this.apiURL + this.api + 'services/' + PackageID + '/' + ClientID);
  }

  checkAddress(addr: CheckAddress) {
    return this.http.put<AddressCheckType>(this.apiURL + this.api + 'checkAddress', addr);
  }

  getShippingServiceCodes(WarehouseID: number) {
    return this.http.get<ShippingServiceCode[]>(this.apiURL + this.api + 'shippingServices/' + WarehouseID);
  }

  validateOrder(ord: SIShipment) {
    return this.http.put<SIFault>(this.apiURL + this.api + 'validate', ord);
  }

  changeResidential(curOrd: SIShipment, ResiOn: boolean) {
    return this.http.put<SIShipment>(this.apiURL + this.api + 'changeResidential/' + ResiOn, curOrd);
  }

  changeSaturdayDelivery(curOrd: SIShipment, SatDelOn: boolean) {
    return this.http.put<SIShipment>(this.apiURL + this.api + 'changeSaturdayDelivery/' + SatDelOn, curOrd);
  }

  shipOrder(ord: SIShipment) {
    return this.http.post<SIShipment>(this.apiURL + this.api, ord);
  }

  /**
   * Service call for staging package within order (used with MultiRateType of 'SHIPMENT')
   * @param ord Order for shipment to be staged in
   * @param packageNumber Which package is being staged. (Package Number -NOT- PackageID)
   * @returns Shipment model with updated values
   */
  stagePackage(ord: SIShipment, packageNumber: number) {
    return this.http.post<SIShipment>(this.apiURL + this.api + 'stage/' + packageNumber, ord)
  }

  /**
   * Service call for shipping all staged packages( used with MultiRateType of 'SHIPMENT')
   * @param ord The order to ship when all packages have been validated and staged
   * @returns Updated shipment record.
   */
  shipStaged(ord: SIShipment) {
    return this.http.post<SIShipment>(this.apiURL + this.api + 'shipMultiLot', ord)
  }

  /**
   * Unstaging a package and resetting it's status to INPR from STAG
   * @param pkg Package to be unstaged 
   * @returns The updated package record
   */
  unstagePackage(pkg: SIPackage) {
    return this.http.put<SIPackage>(this.apiURL + this.api + 'unstage', pkg)
  }

  reShipOrder(orderID: number, orderBatchID: number) {
    return this.http.post<SIShipment>(this.apiURL + this.api + 'reShipOrder/' + orderID + '/' + orderBatchID, null);
  }

  getHistorySummary(workstationID: string, Quantity: number = 1000) {
    return this.http.get<SIPackageSummary[]>(this.apiURL + this.api + 'historysummary/' + workstationID + '/' + Quantity);
  }

  getUserShipmentCount(UserID: number) {
    return this.http.get<number>(this.apiURL + this.api + 'shipmentHistoryCount/' + UserID)
  }

  getPackageShipmentHistory(UserID: any, Quantity: number) {
    return this.http.get<ShipmentHistory[]>(this.apiURL + this.api + 'packagehistorysummary/' + UserID + '/' + Quantity)
  }

  getShipmentHistory(IsAdmin: boolean) {
    return this.http.get<HistoryShipmentSummary[]>(this.apiURL + this.api + 'shipmenthistorysummary/' + IsAdmin)
  }

  getShipmentPackageHistory(ShipmentID: number, ManifestID: number) {
    return this.http.get<HistoryPackageSummary[]>(this.apiURL + this.api + 'shipmentpackagehistory/' + ShipmentID + '/' + ManifestID);
  }

  viewEntireShipmentHistory(SearchType: number, SearchValue: string) {
    return this.http.get<SIShipment[]>(this.apiURL + this.api + 'viewEntireShipmentHistory/' + SearchType + '/' + SearchValue)
  }

  getManifestHistory(Quantity: number, IsAdmin: boolean) {
    return this.http.get<ManifestHistory[]>(this.apiURL + this.api + 'manifesthistorysummary/' + Quantity + '/' + IsAdmin)
  }

  getManifestPackages(ManifestID: number) {
    return this.http.get<SIPackage[]>(this.apiURL + this.api + 'manifestpackages/' + ManifestID);
  }

  shipBatchPackages(batchID: number) {
    // set timeout?
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Keep-Alive
    var headers = new HttpHeaders();
    headers = headers.append('Connection', 'Keep-Alive');
    headers = headers.append('Keep-Alive', 'timeout=600000');
    return this.http.post<SIOrderFault[]>(this.apiURL + this.api + 'shipBatchPackages/' + batchID.toString(), null, { headers: headers });
  }

  voidPackage(voidPackageGUID: any) {
    return this.http.delete(this.apiURL + this.api + 'voidpackage/' + voidPackageGUID)
  }

  voidShipment(workstation: string, shipmentID: number, fromBatch: boolean = false) {
    return this.http.delete(this.apiURL + this.api + 'voidshipment/' + workstation + '/' + shipmentID + '/' + fromBatch)
  }

  voidBatch(workstation: string, batchID: number) {
    return this.http.delete(this.apiURL + this.api + 'voidbatch/' + workstation + '/' + batchID)
  }

  completeShipment(shp: SIShipment) {
    console.log('call completeShipment', shp.ShipmentID);
    return this.http.put<SIShipment>(this.apiURL + this.api + 'complete/' + shp.ShipmentID, shp)
  }

  endSegment() {
    return this.http.put<boolean>(this.apiURL + this.api + 'endSegment', null);
  }

  closeManifest(workstation: string, manifestID: number, RatingEngineManifestID: string, IsAdmin: boolean) {
    return this.http.get<ManifestHistory[]>(this.apiURL + this.api + 'closeManifest/' + workstation + '/' + manifestID + '/' + IsAdmin);
  }

  getManifestReport(workstation: string, manifestID: number, RatingEngineID: number) {
    console.log('call getManifestReport', workstation, manifestID, RatingEngineID);
    return this.http.get<ManifestReportReturn>(this.apiURL + this.api + 'getManifestReport/' + workstation + '/' + manifestID + '/' + RatingEngineID);
  }
}

@Injectable()
export class SendItBatchService {
  api = 'SendItBatch/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAllBatches() {
    return this.http.get<SIBatch[]>(this.apiURL + this.api);
  }

  getBatchByID(BatchID: number) {
    return this.http.get<SIBatch>(this.apiURL + this.api + BatchID);
  }

  getBatchesByWarehouse(WarehouseID: number) {
    return this.http.get<SIBatch[]>(this.apiURL + this.api + 'warehouse/' + WarehouseID);
  }

  getBatchesByWarehouseAndClient(WarehouseID: number, ClientID: number) {
    return this.http.get<SIBatch>(this.apiURL + this.api + 'warehouse/' + WarehouseID + '/client/' + ClientID);
  }

  getPackagesByBatch(OrderBatchID: number) {
    return this.http.get<SIBatchPackage[]>(this.apiURL + this.api + 'batchPackages/' + OrderBatchID);
  }

  getPackageSummariesByBatch(OrderBatchID: number) {
    return this.http.get<SIBatchPackageSummary[]>(this.apiURL + this.api + 'batchSummary/' + OrderBatchID);
  }

  getBatchFaults(OrderBatchID: number) {
    return this.http.get<SIOrderFault[]>(this.apiURL + this.api + 'batchFaults/' + OrderBatchID);
  }

  removeFromBatch(OrderID: number, BatchID: number) {
    return this.http.delete(this.apiURL + this.api + 'removeFromBatch/' + OrderID + '/' + BatchID);
  }

  completeBatch(batchID: number) {
    return this.http.put<boolean>(this.apiURL + this.api + 'completeBatch/' + batchID.toString(), null);
  }


}

@Injectable()
export class SendItPackageLabelService {
  api = 'PackageLabel/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getByBatch(OrderBatchID: number) {
    return this.http.get<SIPackageLabel[]>(this.apiURL + this.api + 'batch/' + OrderBatchID);
  }

  getDividerForBatch(OrderBatchID: number) {
    return this.http.get<string>(this.apiURL + this.api + 'divider/' + OrderBatchID);
  }

  update(lbl: SIPackageLabel) {
    return this.http.put<SIPackageLabel>(this.apiURL + this.api + lbl.PackageLabelID, lbl);
  }

  updatePrintCount(labelID: number) {
    return this.http.put<number>(this.apiURL + this.api + 'printCount/' + labelID, null);
  }

  getByOrderBatchPackageID(PackageID: number) {
    console.log(this.apiURL + this.api + 'orderBatchPackage/' + PackageID);
    return this.http.get<SIPackageLabel[]>(this.apiURL + this.api + 'orderBatchPackage/' + PackageID);
  }

  getByPackageID(PackageID: number) {
    return this.http.get<SIPackageLabel[]>(this.apiURL + this.api + 'package/' + PackageID);
  }

  getPDFLabelByPackageID(PackageID: number, idx: number) {
    return this.http.get(this.apiURL + this.api + 'package/pdf/' + PackageID + '/' + idx, { responseType: 'blob' });
  }
  getPNGLabelByPackageID(PackageID: number, idx: number) {
    return this.http.get(this.apiURL + this.api + 'package/png/' + PackageID + '/' + idx, { responseType: 'blob' });
  }
}

@Injectable()
export class SendItPaymentTypeService {
  api = 'cfgPaymentTypes/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<cfgPaymentTypeDb[]>(this.apiURL + this.api);
  }
}


@Injectable()
export class SendItPackageService {
  api = 'SendItPackage/'

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  validateCartonCode(cartonCode: string) {
    return this.http.get<SISendItPackaging>(this.apiURL + this.api + 'ValidateCartonCode/' + cartonCode)
  }

}

@Injectable()
export class SendItDashboardService {
  api = 'sendItDashboard/'

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getDashboardData(action: DashboardAction) { return this.http.put<SendItDashboardResult>(this.apiURL + this.api + 'gimmie/', action) }
}