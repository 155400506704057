<div class="p-grid">
    <div class="p-col-2 p-offset-5">
        <!-- <img src="assets\images\Propack_Brand_FNL_RGB_Tagline 3.jpg" alt=""> -->
        <!-- <div style="text-align:center"><h3>{{ env.display }}</h3></div> -->
        <h2 *ngIf="env.display">Please Login ({{env.display }})</h2>
        <h2 *ngIf="!env.display">Please Login</h2>
        <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
            <div [ngClass]="{ 'has-error': f.submitted && !username.valid }" style="padding-bottom: 20px;">
                <label for="username">User Name</label>
                <input pInputText type="text" name="username" id="username" [(ngModel)]="model.username"
                    #username="ngModel" required autofocus />
                <div *ngIf="f.submitted && !username.valid" class="help-block">User Name is required</div>
            </div>
            <div [ngClass]="{ 'has-error': f.submitted && !password.valid }" style="padding-bottom: 20px;">
                <label for="password">Password</label>
                <p-password name="password" id="password" [(ngModel)]="model.password"
                    #password="ngModel" required [feedback]="false" [toggleMask]="true" [style]="{'display':'block'}"> </p-password>
                <div *ngIf="f.submitted && !password.valid" class="help-block">Password is required</div>
            </div>
            <div class="form-group">
                <p-button label="Login" type="submit" [disabled]="f.invalid"></p-button>
            </div>
        </form>
    </div>
</div>