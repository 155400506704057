import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { docAssignmentService } from 'app/_data/pro-docs.service';
import { docReassignCandidate, docReassignGroup } from 'app/_models/DOC.model';
import { AlertService, AuthService } from 'app/_services';
import { LookupsService } from 'app/_services/lookups.service';
import { PersistenceService } from 'app/_services/persistence.service';
import { NotifierService } from 'app/_sharedData/notifier.service';
import { addDays } from 'date-fns';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'arc-reassign-select',
  templateUrl: './reassign-select.component.html',
  styleUrls: ['./reassign-select.component.scss']
})
export class ReassignSelectComponent implements OnInit {

  @Input() documentID: number;
  @Input() documentTitle: string;

  @Output() assignmentsMade = new EventEmitter<number>();

  candidates: docReassignCandidate[] = [];
  selectedPersons: docReassignCandidate[] = [];
  reassignGroup: docReassignGroup;

  isLoading = false;

  SoftDue: Date;
  HardDue: Date;
  minDate: Date;

  languages: SelectItem[] = [];
  statuses: SelectItem[] = [];

  // ** DATA TABLE ** \\
  // for table mode
  @ViewChild('dt', { static: true }) mydt: Table;

  // sorting
  multiSortMetaDefault = [{ field: 'PersonName', order: 1 }, { field: 'AssignmentStatus', order: 1 } ];

  //#region FilterNames
  storedDTFilters = 'ReassignDocDTFilters';
  storedInputFilters = 'ReassignDocInputFilters';
  //#endregion

  localFilter = {
    PersonName: null,
    DepartmentName: null,
    WarehouseName: null,
    LanguageCode: null,
    Function: null,
    LastUsed: null,
    DaysAgo: null,
    AssignmentStatus: ['Cancelled', 'Completed'],
    LastAssigned: null,
    MultiSortMeta: this.multiSortMetaDefault,
    rowsPerPage: +this.authService.currentUser.opts.PaginatorRows,
    currentRow: 0
  };

  constructor(
    private assignmentService: docAssignmentService,
    private authService: AuthService,
    private persist: PersistenceService,
    private lookups: LookupsService,
    private alert: AlertService,
    private confirm: ConfirmationService,
    private notifier: NotifierService
  ) { }

  ngOnInit(): void {


    this.SoftDue = addDays(new Date, 5);
    this.HardDue = addDays(new Date, 10);
    this.minDate = new Date;

    this.statuses = [{ label: 'Open', value: 'Open' }, { label: 'Cancelled', value: 'Cancelled' }, { label: 'Completed', value: 'Completed' }];

    this.lookups.languages
      .subscribe((data: SelectItem[]) => {
        this.languages = data.sort((a, b) => a.value > b.value ? 1 : -1);
        console.log('got languages', this.languages);
      });
    this.mydt.rows = +this.authService.currentUser.opts.PaginatorRows;
    this.mydt.rowsPerPageOptions = this.authService.currentUser.opts.PaginatorRowOptions;
    this.mydt.paginator = true;
    this.mydt.alwaysShowPaginator = true;

    const tmp2 = this.persist.get(this.storedInputFilters);
    if (tmp2) {
      this.localFilter = tmp2;
      this.mydt.first = this.localFilter.currentRow;

      if (this.localFilter.MultiSortMeta) {
        this.mydt.multiSortMeta = this.localFilter.MultiSortMeta;
      } else {
        this.mydt.multiSortMeta = this.multiSortMetaDefault;
      }
    }

    if (this.localFilter.LastUsed) {
      if (this.localFilter.LastUsed[0]) {
        this.localFilter.LastUsed[0] = new Date(this.localFilter.LastUsed[0]);
      }
      if (this.localFilter.LastUsed[1]) {
        this.localFilter.LastUsed[1] = new Date(this.localFilter.LastUsed[1]);
      }
    }

    if (this.localFilter.LastAssigned) {
      if (this.localFilter.LastAssigned[0]) {
        this.localFilter.LastAssigned[0] = new Date(this.localFilter.LastAssigned[0]);
      }
      if (this.localFilter.LastAssigned[1]) {
        this.localFilter.LastAssigned[1] = new Date(this.localFilter.LastAssigned[1]);
      }
    }

    const tmp = this.persist.get(this.storedDTFilters);
    if (tmp) {
      this.mydt.filters = tmp;
    }

    this.loadData();

    this.mydt.filter(null, null, null);

  }

  loadData() {
    if (this.documentID) {
      this.isLoading = true;
      this.assignmentService.getReassignList(this.documentID)
        .pipe(finalize(() => this.isLoading = false))
        .subscribe((data: docReassignCandidate[]) => {
          data.forEach(i => {
            if (i.LastUsed) i.LastUsed = new Date(i.LastUsed);
            if (i.LastAssignment) i.LastAssignment = new Date(i.LastAssignment);
          });

          this.candidates = data;
          console.log('candidates', this.candidates);
        });
    }
  }

  doConfirmReassign() {
    if (this.selectedPersons.length === 0) {
      this.alert.warn('You must select at least one person from the list.');
      return;
    }

    if (this.HardDue <= this.SoftDue) {
      this.alert.warn("Hard Due Date must be greater than or equal to Soft Due Date.");
      return;
    }
    this.confirm.confirm({
      message: 'Reassigning ' + this.documentTitle + ' to ' + this.selectedPersons.length + ' persons.',
      header: 'Confirm',
      icon: 'fa fa-question-circle',
      accept: () => { this.doReassign() }
    });
  }
    
  doReassign() {
    this.reassignGroup = new docReassignGroup();
    this.reassignGroup.Persons = this.selectedPersons;
    this.reassignGroup.HardDueDate = this.HardDue;
    this.reassignGroup.SoftDueDate = this.SoftDue;
    this.reassignGroup.DocumentID = this.documentID;

    console.log('reassigning group', this.reassignGroup);
    
    this.assignmentService.reassignGroup(this.reassignGroup)
      .subscribe((cnt) => {
        this.alert.success(cnt + ' persons reassigned.');
        this.loadData();
        this.assignmentsMade.emit(cnt);
      })
  }

  onSort(event) {
    this.localFilter.MultiSortMeta = event.multisortmeta;
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  onPage(event) {
    this.localFilter.rowsPerPage = event.rows;
    this.localFilter.currentRow = event.first;
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  doFilter(event) {
    // and store the filters for later recall
    this.persist.set(this.storedDTFilters, event.filters);
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  clearLocalFilter(dt: Table) {
    this.localFilter.PersonName = null;
    this.localFilter.DepartmentName = null;
    this.localFilter.WarehouseName = null;
    this.localFilter.LanguageCode = null;
    this.localFilter.Function = null;
    this.localFilter.LastUsed = null;
    this.localFilter.DaysAgo = null;
    this.localFilter.AssignmentStatus = ['Cancelled', 'Completed'];
    this.localFilter.LastAssigned = null;
    this.localFilter.MultiSortMeta = this.multiSortMetaDefault;
    this.localFilter.rowsPerPage = +this.authService.currentUser.opts.PaginatorRows;
    this.localFilter.currentRow = 0;

    dt.clear();
    dt.filters = {};

    this.persist.set(this.storedDTFilters, null);
    this.persist.set(this.storedInputFilters, this.localFilter);
    this.mydt.multiSortMeta = this.localFilter.MultiSortMeta;
  }

}
