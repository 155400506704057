import { Location } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientsGoTimeService } from 'app/_data/clients.service';
import { docDocumentService, docTriggerService, docVersionService } from 'app/_data/pro-docs.service';
import { cfgDepartmentsService } from 'app/_data/time-cfg.service';
import { timeEmployeeService, timeFunctionService } from 'app/_data/time.service';
import { WarehousesService } from 'app/_data/warehouses.service';
import { UpsertRet } from 'app/_models/API.model';
import { cfgDepartmentDb, cfgWarehouse } from 'app/_models/CFG.model';
import { docDocumentDb, docTriggerEx } from 'app/_models/DOC.model';
import { DocumentTabs, TriggerType } from 'app/_models/enums.model';
import { orgClientsGoTime } from 'app/_models/ORG.model';
import { timeEmployee, timeFunctionDb } from 'app/_models/TIME.model';
import { AlertService } from 'app/_services';

@Component({
  selector: 'arc-trigger-edit',
  templateUrl: './trigger-edit.component.html',
  styleUrls: ['./trigger-edit.component.scss']
})
export class TriggerEditComponent implements OnInit {

  @Output() editComplete = new EventEmitter<number>();

  triggerTypes = [
    { label: 'Function', value: 1 },
    { label: 'Person', value: 2 },
    { label: 'Department', value: 3 },
    { label: 'Warehouse', value: 4 }
  ];

  documentID = 0;
  triggerID = 0;
  trigger: docTriggerEx;
  document: docDocumentDb;

  isError = false;

  WhValue = 'WhAny';
  ClValue = 'ClAny';
  WhOptions = [
    { label: 'any', value: 'WhAny' },
    { label: 'these', value: 'WhList' }
  ];

  TriggerType = TriggerType;

  functionList: timeFunctionDb[] = [];
  functionSuggs: timeFunctionDb[] = [];
  functionsSelected: timeFunctionDb[] = [];

  clientList: orgClientsGoTime[] = [];
  clientSuggs: orgClientsGoTime[];
  clientsSelected: orgClientsGoTime[] = [];

  warehouseList: cfgWarehouse[] = [];
  warehouseSuggs: cfgWarehouse[] = [];
  warehousesSelected: cfgWarehouse[] = [];

  personList: timeEmployee[] = [];
  personSuggs: timeEmployee[] = [];
  personsSelected: timeEmployee[] = [];

  departmentList: cfgDepartmentDb[] = [];
  departmentSuggs: cfgDepartmentDb[] = [];
  departmentsSelected: cfgDepartmentDb[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private docService: docDocumentService,
    private versionService: docVersionService,
    private location: Location,
    private triggerService: docTriggerService,
    private funcService: timeFunctionService,
    private clientService: ClientsGoTimeService,
    private warehouseService: WarehousesService,
    private personService: timeEmployeeService,
    private deptService: cfgDepartmentsService,
    private alert: AlertService
  ) { }

  ngOnInit(): void {
    this.documentID = this.route.snapshot.params['documentID'];
    this.triggerID = this.route.snapshot.params['triggerID'];
    console.log('ids', this.documentID, this.triggerID);

    this.setUpTrigger();
  }

  setUpTrigger() {
    if (this.triggerID > 0) {
      this.triggerService.getByIDEx(this.triggerID)
        .subscribe((t: docTriggerEx) => {
          this.trigger = t;
          console.log('got trigger', t);
          this.selectTriggerType();
        });
    } else {
      this.trigger = {
        TriggerID: this.triggerID,
        TriggerTypeID: 1,
        TriggerName: '',
        DocumentID: this.documentID,
        DocumentTitle: '',
        IsActive: true,
        CreatedBy: 0,
        CreatedByName: '',
        CreatedDate: new Date(1899, 1, 1),
        UpdatedBy: 0,
        UpdatedByName: '',
        UpdatedDate: new Date(),
        Clients: [],
        Functions: [],
        Departments: [],
        Persons: [],
        Warehouses: []
      };
      this.selectTriggerType();
    }

    this.docService.getByID(this.documentID)
      .subscribe((ret: docDocumentDb) => {
        this.document = ret;
        console.log('got document', this.document);
      });
  }

  selectTriggerType() {
    switch (this.trigger.TriggerTypeID) {
      case TriggerType.Function:
        this.loadFunctionList();
        this.loadClientList();
        this.loadWarehouseList();
        break;
      case TriggerType.Person:
        this.loadPersonList();
        break;
      case TriggerType.Department:
        this.loadDepartmentList();
        break;
      case TriggerType.Warehouse:
        this.loadWarehouseList();
        break;
    }

    this.initializeOpts();
  }

  initializeOpts() {
    if (this.trigger.Clients.length > 0) {
      this.ClValue = 'ClList';
    } else {
      this.ClValue = 'ClAny';
    }

    if (this.trigger.Warehouses.length > 0) {
      this.WhValue = 'WhList';
    } else {
      this.WhValue = 'WhAny';
    }

  }

  loadFunctionList() {
    this.funcService.getAllActive()
      .subscribe((data: timeFunctionDb[]) => {
        this.functionList = data.sort((a, b) => a.FunctionCode > b.FunctionCode ? 1 : -1);
        this.functionList.forEach((f) => f.FunctionName = f.FunctionCode.toString() + ':' + f.FunctionName);
        console.log('got functions', this.functionList);
        this.functionsSelected = this.functionList.filter((f) => this.trigger.Functions.findIndex((i) => f.FunctionID == i) >= 0);
      });
  }

  loadClientList() {
    this.clientService.getAll()
      .subscribe((data: orgClientsGoTime[]) => {
        this.clientList = data.sort((a, b) => a.ShortName > b.ShortName ? 1 : -1);
        const orgAll = new orgClientsGoTime();
        orgAll.EntityID = -1;
        orgAll.ShortName = 'Any';
        this.clientList.unshift(orgAll);
        console.log('got clients', this.clientList);
        // build out clientSelected list
        this.clientsSelected = this.clientList.filter((c) => this.trigger.Clients.findIndex((i) => c.EntityID == i) >= 0);
      });
  }

  loadWarehouseList() {
    this.warehouseService.getAll()
      .subscribe((data: cfgWarehouse[]) => {
        this.warehouseList = data.filter((w) => w.IsActive).sort((a, b) => a.WarehouseCode > b.WarehouseCode ? 1 : -1);
        console.log('got warehouses', this.warehouseList);
        this.warehousesSelected = this.warehouseList.filter((w) => this.trigger.Warehouses.findIndex((i) => w.WarehouseID == i) >= 0);
        console.log('warehouses selected', this.warehousesSelected);
      });
  }

  loadPersonList() {
    this.personService.getAllEx(true)
      .subscribe((data: timeEmployee[]) => {
        this.personList = data.sort((a, b) => a.LastName > b.LastName ? 1 : a.LastName < b.LastName ? -1 : a.FirstName > b.FirstName ? 1 : -1);
        this.personList.forEach((p) => p.LastName = p.FirstName + ' ' + p.LastName);
        console.log('got person list', this.personList);
        this.personsSelected = this.personList.filter((p) => this.trigger.Persons.findIndex((i) => p.EmployeeID == i) >= 0);
      });
  }

  loadDepartmentList() {
    this.deptService.getAll()
      .subscribe((data: cfgDepartmentDb[]) => {
        this.departmentList = data.filter((d) => d.IsActive);
        console.log('got departments', this.departmentList);
        this.departmentsSelected = this.departmentList.filter((d) => this.trigger.Departments.findIndex((i) => d.DepartmentID == i) >= 0);
      });
  }

  searchClient(event) {
    console.log('in searchClient', event);
    const suggs = this.clientList.filter((c: orgClientsGoTime) => (c.ShortName.toUpperCase().includes(event.query.toUpperCase()) || event.query === '*'));
    console.log('returning', suggs);
    this.clientSuggs = suggs;
  }

  searchFunction(event) {
    console.log('in searchFunction', event);
    const suggs = this.functionList.filter((c: timeFunctionDb) => (c.FunctionName.toUpperCase().includes(event.query.toUpperCase()) || event.query === '*'));
    console.log('returning', suggs);
    this.functionSuggs = suggs;
  }

  searchDepartment(event) {
    console.log('in searchDepartment', event);
    const suggs = this.departmentList.filter((c: cfgDepartmentDb) => (c.DepartmentName.toUpperCase().includes(event.query.toUpperCase()) || event.query === '*'));
    console.log('returning', suggs);
    this.departmentSuggs = suggs;
  }

  searchPerson(event) {
    console.log('in searchPerson', event);
    const suggs = this.personList.filter((c: timeEmployee) => (c.LastName.toUpperCase().includes(event.query.toUpperCase()) || event.query === '*'));
    console.log('returning', suggs);
    this.personSuggs = suggs;
  }

  searchWarehouse(event) {
    console.log('in searchWarehouse', event);
    const suggs = this.warehouseList.filter((c: cfgWarehouse) => (c.WarehouseCode.toUpperCase().startsWith(event.query.toUpperCase()) || event.query === '*'));
    console.log('returning', suggs);
    this.warehouseSuggs = suggs;
  }

  cancelEdit() {
    this.setUpTrigger();
    this.router.navigateByUrl('prodocs/document/' + this.documentID, {
      state: { 'selectedTab': DocumentTabs.Triggers }
    })
    // this.editComplete.emit(-1);
  }

  saveTrigger() {
    // fix up client list (if any)
    console.log('saving trigger', this.trigger);

    if (this.ClValue === 'ClAny') {
      this.trigger.Clients = [];
    } else {
      this.trigger.Clients = this.clientsSelected.map((c) => c.EntityID);
    }
    if (this.WhValue === 'WhAny') {
      this.trigger.Warehouses = [];
    } else {
      this.trigger.Warehouses = this.warehousesSelected.map((c) => c.WarehouseID);
    }
    this.trigger.Functions = this.functionsSelected.map((f) => f.FunctionID);
    this.trigger.Persons = this.personsSelected.map((p) => p.EmployeeID);
    this.trigger.Departments = this.departmentsSelected.map((d) => d.DepartmentID);

    // ----------------------------------------------------------------------------
    // validate
    // ----------------------------------------------------------------------------
    this.isError = false;

    // make sure numeric values are actually numeric
    this.trigger.UsageMinutes = +this.trigger.UsageMinutes;
    this.trigger.UsageWindow = +this.trigger.UsageWindow;
    this.trigger.HardDueInDays = +this.trigger.HardDueInDays;
    this.trigger.SoftDueInDays = +this.trigger.SoftDueInDays;
    this.trigger.AssignFrequencyDays = + this.trigger.AssignFrequencyDays;

    if (this.trigger.TriggerName.length == 0) {
      this.alert.warn("Trigger name is required.");
      this.isError = true;
    }
    if (this.trigger.TriggerTypeID === this.TriggerType.Function) {
      // must have something in functions
      if (this.trigger.Functions.length === 0) {
        this.alert.warn('You must select at least one function.');
        this.isError = true;
      }
      if (this.trigger.UsageMinutes === 0) {
        this.alert.warn('Please specify Usage Days.')
        this.isError = true;
      }
      if (this.trigger.UsageWindow === 0) {
        this.alert.warn('Please specify Usage Window.')
        this.isError = true;
      }
    }

    if (this.trigger.TriggerTypeID === this.TriggerType.Person) {
      // must have something in functions
      if (this.trigger.Persons.length === 0) {
        this.alert.warn('You must select at least one Person.');
        this.isError = true;
      }
    }

    if (this.trigger.TriggerTypeID === this.TriggerType.Department) {
      // must have something in functions
      if (this.trigger.Departments.length === 0) {
        this.alert.warn('You must select at least one Department.');
        this.isError = true;
      }
    }

    if (this.trigger.AssignFrequencyDays === 0) {
      this.alert.warn('Please specify Frequency Days')
      this.isError = true;
    }

    if (this.trigger.SoftDueInDays === 0) {
      this.alert.warn('Please specify Soft Due Days')
      this.isError = true;
    }

    if (this.trigger.HardDueInDays === 0) {
      this.alert.warn('Please specify Hard Due Days')
      this.isError = true;
    }

    if (this.trigger.HardDueInDays < this.trigger.SoftDueInDays) {
      this.alert.warn('Soft Due Days must be less than or equal to Hard Due Days.')
      this.isError = true;
    }

    // ----------------------------------------------------------------------------
    if (!this.isError) {
      console.log('save trigger', this.triggerID, this.trigger);
      if (this.triggerID > 0) {
        this.triggerService.updateEx(this.trigger)
          .subscribe((ret: UpsertRet) => {
            this.alert.success("Trigger Saved!");
            this.trigger = ret.returnItem;
            console.log('after update, got ', ret.returnItem);
            this.triggerID = ret.returnItem.triggerID;
            this.router.navigateByUrl('prodocs/document/' + this.trigger.DocumentID, {
              state: { 'selectedTab': DocumentTabs.Triggers }
            });
          },
            (error) => this.alert.error(error)
          );
      } else {
        this.triggerService.createEx(this.trigger)
          .subscribe((ret: UpsertRet) => {
            this.alert.success("Trigger Created!");
            console.log('after create, got ', ret.returnItem);
            this.trigger = ret.returnItem;
            this.triggerID = ret.returnItem.TriggerID;
            this.router.navigateByUrl('prodocs/document/' + this.trigger.DocumentID, {
              state: { 'selectedTab': DocumentTabs.Triggers }
            });
          },
            (error) => this.alert.error(error)
          );
      }
    }
  }


}
