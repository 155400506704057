import { SelectItem } from 'primeng/api';
import { assetState } from './asset.model';

export class timeClientFunctionDb {
	ClientFunctionID: number;
	ClientID: number;
	FunctionID: number;
	BillType: number;
	BillTypeName: string;
	BillingCode: string;
	TrackTypeID: number;
	UsageCount: number;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}

export class timeClientFunctionShort {
	ClientID: number;
	ClientShortName: string;
	BillType: number;
	TrackTypeID: number;
}

/** A export class which represents the time.ClientJobs table. */
export class timeClientJobDb {
	ClientJobID: number;
	ClientFunctionID: number;
	ClientJobReference: string;
	Note: string;
	WarehouseID?: number;
	ExpirationDate?: Date;
	IsActive: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}

export class timeClientWarehouseDb {
	ClientWarehouseID: number;
	ClientID: number;
	WarehouseID: number;
	WarehouseCode: string;
	WarehouseName: string;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A export class which represents the time.EmployeeFunctions table. */
export class timeEmployeeFunctionDb {
	EmployeeFunctionID: number;
	EmployeeID: number;
	FunctionID: number;
	IsDefault: boolean;
	ExpirationDate: Date;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}

export class timeEmployeeFunctionAssignment {
	EmployeeFunctionID: number;
	EmployeeID: number;
	EmployeeName: string;
	FunctionID: number;
	FunctionCode: string;
	FunctionName: string;
	ExpirationDate: Date;
	IsDefault: boolean;
	// For UI use only
	IsSelected: boolean;
}
/** A export class which represents the time.Employees table. */
export class timeEmployeeDb {
	EmployeeID: number;
	EmployeeNumber: string;
	EmployeePIN: string;
	BadgeCode: string;
	LanguageCode: string;
	PayrollTypeID: number;
	PayrollTypeName: string;
	PayrollXRef: string;
	WorkStatus: number;
	WorkGroupID: number;
	RequireSchedule: number;
	EmployeeTypeID: number;
	DepartmentID: number;
	HireDate?: Date;
	LeaveDate?: Date;
	ReportsToEmployeeID?: number;
	MustChangePIN: boolean;
	LastChangedPIN?: Date;
	EmployeePINEnc: string;
	NewEmployeePIN: boolean;
	AssignDocException: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
export class timeFunctionDb {
	FunctionID: number;
	FunctionName: string;
	FunctionCode: number;
	ClientRule: number;
	PaidAllowance?: number;
	MaxPerDay?: number;
	MinInterval?: number;
	IsBreak: boolean;
	IsActive: boolean;
	IsBillable: boolean;
	IsClientAssignable: boolean;
	IsEmployeeAssignable: boolean;
	ValidateMatch: boolean;
	BillingCode: string;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
	// For UI Use Only
	FunctionCodeName: string;
}

/** A class representing the time.FunctionGroups table. */
export class timeFunctionGroupDb {
	FunctionGroupID: number;
	FunctionGroupName: string;
	FunctionGroupType: string;
	FunctionGroupTypeName: string;
	IsActive: boolean;
}

export class timeFunctionGroup extends timeFunctionGroupDb {
	FunctionCount: number;
}

export class timeFunctionGroupFunctionDb {
	FunctionGroupFunctionID: number;
	FunctionGroupID: number;
	FunctionID: number;
}
export class timeFunctionGroupFunction extends timeFunctionGroupFunctionDb {
	FunctionName: string;
	FunctionGroupName: string;
	FunctionCode: string;
	FunctionDisplay: string;
	IsSelected: boolean;
}

/** A export class which represents the time.Issues table. */
export class timeIssueDb {
	IssueID: number;
	TimeSegmentID?: number;
	TrackSegmentID?: number;
	IssueTypeID?: number;
	IssueNote: string;
	IsAutoGenerated: boolean;
	IsDisputeTime: boolean;
	ResolvedBy?: number;
	ResolvedDate?: Date;
	ResolvedNote: string;
	PointsAccrued?: number;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}

/** A derived class that extends timeIssueDb */
export class timeIssue extends timeIssueDb {
	TimeSegment: timeTimeSegmentDb;
	TrackSegment: timeTrackSegmentDb;
	Function: timeFunctionDb;
	Schedule: timeScheduleDb;
	EmployeeID: number;
	EmployeeName: string;
	ResolvedByName: string;
	DefaultPoints: number;
}

export class timeIssueSummary extends timeIssueDb {
	TimeSegmentStart: string;  // this will be local (warehouse) time
	TimeSegmentStartDate: Date; // ui use
	TimeSegmentStop?: string; // this will be local (warehouse) time
	TimeSegmentStopDate?: Date;
	IssueTypeName: string;
	FunctionName: string;
	EmployeeName: string;
	SupervisorName: string;
	ResolvedByName: string;
	CreatedByName: string;
}

/** A export class which represents the time.Schedules table. */
export class timeScheduleDb {
	ScheduleID: number;
	EmployeeID: number;
	SupervisorID: number;
	ScheduleDate: Date;
	ScheduleTypeID: number;
	StartTime: string; // this should be local (warehouse) time
	StopTime?: string; // this should be local (warehouse) time
	ScheduleHours?: number;
	PointsAdjustedBy?: number;
	PointsAdjustedNote: string;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A export class which represents the time.ScheduleTemplates table. */
export class timeScheduleTemplateDb {
	ScheduleTemplateID: number;
	ScheduleTemplateName: string;
	ScheduleTemplateDescription: string;
	ScheduleTypeID: number;
	ScheduleStartTime: string;
	ScheduleStopTime?: string;
	ScheduleHours?: number;
	OwnerID?: number;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A export class which represents the time.TimeSegments table. */
export class timeTimeSegmentDb {
	TimeSegmentID: number;
	EmployeeID: number;
	FunctionID: number;
	WarehouseID?: number;
	DepartmentID?: number;
	EmployeeTypeID?: number;
	PayrollTypeID?: number;
	ServerTimeOffset: number;
	ServerStart: string;
	ServerStop?: string;
	Start: string;
	StartDate: Date;
	Stop?: string;
	StopDate: Date;
	StopType?: number;
	TimeEdited: boolean;
	Duration?: number;
	PaidAllowance?: number;
	PaidDuration?: number;
	EmployeeApprovedID?: number;
	EmployeeApprovedDate?: Date;
	EmployeeApprovedDateLocal?: Date;
	ManagerApprovedID?: number;
	ManagerApprovedDate?: Date;
	ManagerApprovedDateLocal?: Date;
	PayrollBatchID?: number;
	// SentToPayrollDate?: Date;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
	AddHist: boolean;
}
/** A export class which represents the time.TrackSegments table. */
export class timeTrackSegmentDb {
	TrackSegmentID: number;
	TimeSegmentID: number;
	ClientID: number;
	TrackTypeID?: any;
	TrackID?: number;
	TrackReference?: string;
	ServerStart: string;
	ServerStop?: string;
	Start: string;
	StartDate: Date;
	Stop?: string;
	StopDate: Date;
	StopType: number;
	TimeEdited: boolean;
	Duration?: number;
	DurationFractional?: number;
	IsBillable: boolean;
	IsAutoGenerated: boolean;
	ChargeID?: number;
	Note: string;
	ApprovedBy?: number;
	ApprovedDate?: Date;
	ApprovedDateLocal?: Date;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
	AddHist: boolean;
}

export class timeSetting {
	SettingID: number;
	SettingCode: string;
	SettingDescription: string;
	SettingNumValue: number;
	SettingTextValue: string;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}

export class timeNewTimeSegment {
	EmployeeID: number;
	WarehouseID: number;
	FunctionID: number;
	Start: Date; // will come in as 'local' (warehouse) date/time
	StartText: string;
	Stop: Date; // will come in as 'local' (warehouse) date/time
	StopText: string;
	ClientID: number;
	IsBillable: boolean;
	TrackTypeID: number;
	TrackID: number;
	TrackReference: string;
	IsAutoGenerated: boolean;
	CreatedBy: number;
}

export class timeDaySnapshot {
	EmployeeID: number;
	ForDate: Date;
	Schedule: timeScheduleDb;
	WeekMinutes: number;
	Activity: timeTimeSegment[];
}

export class timeEmployeeAccessDb {
	EmployeeAccessID: number;
	AccessByID: number;
	AccessToID: number;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}

export class timeEmployeePINUpdate {
	OldPIN: string;
	NewPIN: string;
}

export class timeEmployeeTermination {
	TerminationReasonID: number;
	TerminationDate: Date;
	EmployeeID: number;
}

export class timePayrollBatch {
	PayrollBatchID: number;
	PayrollBatchName: string;
	FromDate: string;
	ToDate: string;
	CountryCode: string;
	EmployeeTypeGroup: string;
	PayrollTypeGroup: string;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}

export class timePayrollBatchDetail {
	PayrollBatchDetailID: number;
	PayrollBatchID: number;
	EmployeeID: number;
	PayrollXRef: string;
	PayrollTypeID: number;
	EmployeeTypeID: number;
	WorkDate: Date;
	WarehouseID: number;
	RegularMinutes: number;
	WeekOvertimeMinutes: number;
	DayOvertime1Minutes: number;
	DayOvertime2Minutes: number;
}
export class timePayrollParm {
	StartDate: string;
	EndDate: string;
	CountryCode: string;
	EmployeeTypeGroup: string;
	PayrollTypeGroup: string;
	ValidateOnly: boolean;
	EmployeeIDList: number[];
}

export class timePayrollValidationError {
	TimeSegmentID: number;
	EmployeeID: number;
	EmployeeNumber: number;
	PayrollXRef: string;
	EmployeeName: string;
	ReportsToEmployeeName: string;
	Start: string; // this should be returned as local (warehouse) date/time
	Stop: string; // this should be returned as local (warehouse) date/time
	ValidateMessage: string;
}

export class timePayrollBatchContents {
	PayrollBatchID: number;
	PayrollTypeID: number;
	PayrollTypeName: string;
	EmployeeCount: number;
	DayCount: number;
}

export class timePayrollUnprocessedItems {
	PayrollTypeGroup: string;
	EmployeeTypeGroup: string;
	CountryCode: string;
	EmployeeCount: number;
	DayCount: number;
	EarliestDate: Date;
}

export class timeUnapprovedSummary {
	EmployeeID: number;
	EmployeeName: string;
	OnDate: Date;
	UnapprovedSegmentCount: number;
	UnresolvedIssueCount: number;
}

export class timeTeamWeekTotals {
	EmployeeID: number;
	WeekTotal: number;
}

export class timeWarehousePayrollSettings {
	WarehouseID: number;
	WarehouseCode: string;
	DailyRegularMinutes: number;
	DailyRegularMinutes2: number;
	WeeklyRegularMinutes: number;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedByName: string;
	UpdatedDate: Date;
}

export class docDocumentFunction {
	DocumentFunctionID: number;
	DocumentID: number;
	FunctionID: number;
	DocumentTitle: string;
	FunctionName: string;
	FunctionCode: string;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}

/** ***************************************************************************
 * Extended Models**
 * *************************************************************************** */
export class timeFunction extends timeFunctionDb {
	GroupCount: number;
}
export class timeClientFunction extends timeClientFunctionDb {
	ClientName: string;
	Function: timeFunction;
}
export class timeClientFunctionAssignment {
	ClientFunctionID: number;
	ClientID: number;
	ClientShortName: string;
	FunctionID: number;
	FunctionCode: number;
	FunctionName: string;
	MayBill: boolean;
	MustBill: boolean;
	BillType: number;
	BillingCode: string;
	FunctionBillingCode: string;
	TrackTypeID: number;
	TrackTypeName: string;
	ClientRule: number;
	ClientRuleName: string;
	UsageCount: number;
	IsSelected: boolean;
}

export class timeClientWarehouseAssignment {
	ClientWarehouseID: number;
	ClientID: number;
	WarehouseID: number;
	WarehouseCode: string;
	WarehouseName: string;
}

export class timeEmployee extends timeEmployeeDb {
	FirstName: string;
	LastName: string;
	DepartmentName: string;
	ReportsToEmployeeName: string;
	WarehouseID: number;
	WarehouseCode: string;
	HomeWarehouseID: number;
	HomeWarehouseCode: string;
	ArcturusLogin: string;
	P3PLLogin: string;
	TeamSize: number;
	MaxSchedule: Date;
	PersonName: string;
	ReportsToMe?: number;
	WorkGroupName: string;
}
export class timeEmployeeFunction extends timeEmployeeFunctionDb {
	Employee: timeEmployee;
	Function: timeFunction;
}
export class timeAssignedFunction extends timeFunctionDb {
	EmployeeID: number;
	UsageCount: number;
	IsDefault: boolean;
	IsBlocked: boolean;
	ExpirationDate?: Date;
	Clients: timeClientFunction[];
}
export class timeClientJob extends timeClientJobDb {
	FunctionID: number;
	FunctionCode: number;
	FunctionName: string;
	ClientShortName: string;
	ClientID: number;
	WarehouseCode: string;
	WarehouseName: string;
	CreatedByName: string;
}
export class timeSchedule extends timeScheduleDb {
	Employee: timeEmployee;
	EmployeeName: string;
	DepartmentName: string;
	ReportsToID: number;
	ReportsToName: string;
	ScheduleTypeName: string;
	WeekNumber: number;
	Weekend: boolean;
	DOW: number;
	isSelected: boolean;
	isAssigned: boolean;
}
export class timeScheduleTemplate extends timeScheduleTemplateDb {
	ScheduleTypeName: string;
}
export class timeTimeSegment extends timeTimeSegmentDb {
	Employee: timeEmployee;
	Function: timeFunction;
	LocationName: string;
	EmployeeApprovedName: string;
	ManagerApprovedName: string;
	TrackSegments: timeTrackSegment[];
	CurrentTrackSegment: timeTrackSegment;
	ResolvedIssueCount: number;
	IssueCount: number;
	Issues: timeIssueDb[];
	HistoryCount: number;
	IsMgrApproved: boolean;
	IsEmpApproved: boolean;
	DisplayTracks: boolean; // for UI use only
	hasUnapprovedTracks: boolean; // for UI use only
	hasBilledTrack: boolean; // for UI Use only
	hasBillableTrack: boolean; // for UI use only
	hasAutoGeneratedTrack: boolean; // for UI use only
	mayAdjustTime: boolean; // for UI use only
	mayDelete: boolean; // for UI use only
	mayChangeFunction: boolean; // for UI use only
	maySplitTime: boolean; // for UI use only
	mayScatterSeg: boolean; // for UI use only
	DisplayDuration: string; // for UI use only
	isSelected: boolean; // for UI use only
	isTagSelected: boolean; // for UI use only
	hasChanged: boolean; // for UI use only
	flagStart: boolean; // for UI use only
	flagStop: boolean; // for UI use only
	Display: boolean; // for UI use only
	FromDifferentDay: boolean; // for UI use only
	TrackSegmentsDuration: number; // for UI use only
	Disputed: boolean; // for UI use only
	validFunctions: SelectItem[]; // for UI use only
	validClients: timeClientFunctionShort[]; // for UI use only	
	validClientOptions: SelectItem[]; // for UI use only
}

export class timeTimeSegmentHistoryAll extends timeTimeSegmentDb {
	Type: string;
	FunctionName: string;
	WarehouseCode: string;
	EmployeeApprovedName: string;
	ManagerApprovedName: string;
	CreatedByName: string;
	UpdatedByName: string;
	// -- FOR UI USE ONLY --
	FuncChange?: boolean;
	StartChange?: boolean;
	StopChange?: boolean;
	EmpAppChange?: boolean;
	ManAppChange?: boolean;
	// ---------------------
}

export class timeTrackSegment extends timeTrackSegmentDb {
	TrackTypeName: string;
	ClientName: string;
	ApprovedByName: string;
	BillType: number;
	ResolvedIssueCount: number;
	IssueCount: number;
	Issues: timeIssueDb[];
	IsApproved: boolean;
	maySetApproved: boolean; // for UI use only
	mayEditClient: boolean; // for UI Use Only
	mayEditBillable: boolean; // for UI use only
	mayAdjustTime: boolean; // for UI use only
	isSelected: boolean; // for UI use only
	hasChanged: boolean; // for UI use only
	OrderURL: string; // For Navigating to P3PL from TrackSegment OrderID
}
export class timeEmployeeSnapshot {
	Employee: timeEmployee;
	ClockStatus: string;
	ActionStatus: string;
	ActionSuccess: boolean;
	Notes: string[];
	CurrentTimeSegment: timeTimeSegment;
	PreviousTimeSegment: timeTimeSegment;
	AssignedFunctions: timeAssignedFunction[];
	FrequentFunctions: timeAssignedFunction[];
	AssignedBreaks: timeAssignedFunction[];
	Activity: timeTimeSegment[];
	Assets: assetState[];
	WeekMinutes: number;
	DayMinutes: number;
}
export class timeFunctionSummary {
	Function: timeFunctionDb;
	Duration: number;
	TimeSegments: timeTimeSegment[];
	DisplaySegs: boolean; // for UI use only
}

export class teamMember {
	EmployeeID: number;
	ReportsToEmployeeID: number;
	EmployeeName: string;
	Department: string;
	TeamSize: number;
	Subs: teamMember[];
}

export class timeEmployeeAccess extends timeEmployeeAccessDb {
	AccessToName: string;
	ReportsToID: number;
	ReportsToName: string;
	AccessType: number;
	AccessToHomeWarehouseID: number;
}

/** *****************************************************************************
 * Actions Models
 * sent in to API to signal a requested action
 * ***************************************************************************** */

export class timeClockAction {
	EmployeeID: number;
	TimeStamp: string;
	Action: string;
	FunctionID?: number;
	ClientID?: number;
	TrackTypeID?: number;
	TrackID?: number;
	TrackReference?: string;
	TimeSegmentID?: number;
	TrackSegmentID?: number;
	IsBillable: boolean;
	IsAutoGenerated: boolean;
	StopType: number;
	Note?: string;
	Issues?: timeIssueDb[];
	Approval: timeTimeSegment[];
}

export class timeNewIssue {
	EmployeeID: number;
	TimeSegmentID?: number;
	TrackSegmentID?: number;
	/** public int? JobID { get; set; } */
	IssueType: number;
	IssueNote: string;
	NewStart?: Date; // this should be local (warehouse) date/time
	NewStop?: Date; // this should be local (warehouse) date/time
}
export class timeCredential {
	EmployeeNumber: string;
	EmployeePIN: string;
}

export class authReturn {
	SignedIn: boolean;
	Message: string;
}

export class timeEmployeeFunctionTotal {
	EmployeeID: number;
	EmployeeName: string;
	FunctionSummaries: timeFunctionSummaryValue[];
}

export class timeFunctionSummaryValue {
	FunctionID: number;
	FunctionCode: number;
	FunctionName: string;
	Duration: number;
}


/** *****************************************************************************
 * Dashboard Models
 * sent in to API to signal a requested dashboard model
 * ***************************************************************************** */

export class timeFunctionsInWarehouse {
	FunctionID: number;
	FunctionCode: number;
	FunctionName: string;
	NumberOfUsers: number;
}

export class timeEmployeeIssueSummary {
	EmployeeID: number;
	EmployeeName: string;
	TotalPoints: number;
	IssueData: timeIssueDataSummary[];
}

export class timeIssueDataSummary {
	IssueTypeName: string;
	IssueCount: number;
	SumPoints: number;
}

export class timeUnapprovedTimeSummary {
	EmployeeID: number;
	EmployeeName: string;
	NumberUnapprovedSegments: number;
}

/** ***************************************************************************
 * P3PL Models For Employee Queries
 * *************************************************************************** */
export class timePersonP3PL {
	PersonID: number;
	FirstName: string;
	LastName: string;
	Email: string;
	CreatedByUserID: number;
	CreatedOn: Date;
	UpdatedByUserID: number;
	UpdatedOn: Date;
	PersonName: string;
	BirthDate?: Date;
	Gender?: string;
	AlternateEmail?: string;
	WebSite?: string;
	ExternalCode?: string;
}


/** ***************************************************************************
 * Model for Adding GoTime Employees
 * *************************************************************************** */
export class timeGoTimeNewEmployee {
	// Normal EmployeeInformation
	FirstName: string;
	LastName: string;
	Phone: string;
	DepartmentID: number;
	ReportsToID: number;
	WorkStatus: string;
	WorkGroupID: number;
	PayrollID: string;
	PayrollType: number;
	EmployeeTypeID: number;
	HomeWarehouseID: number;
	ActiveWarehouseID: number;
	LanguageCode: string;
	RequireSchedule: number;
	HireDate: Date;
	// Misc
	NewArcEmployee: boolean;
	NewP3PLEmployee: boolean;
	ArcturusPersonID?: number;
	P3PLPersonID?: number;
}
