<div class="cardContainer" *ngIf="data">
    <div class="title">
        <h3>Assignment Completion Distribution By Percentage</h3>
    </div>
    <div class="header">
        <div class="dateOptions">
            <p-dropdown [options]="dateRangeOptions" [(ngModel)]="localStorage.dateOption" (onChange)="setDateRange()"></p-dropdown>
        </div>
        <div class="showFullTeam" *ngIf="displayType === displayTypes.Team">
            <p-checkbox [(ngModel)]="showFullTeam" inputId="showFullTeam" binary="true" (onChange)="getData()"></p-checkbox>
            <label for="showFullTeam">Full Team</label>
        </div>
    </div>
    <div class="body">
        <p-chart type="pie" [data]="formatedChartData" height="35vh"></p-chart>
    </div>
    <div class="footer">
        <p> Total Count: {{ data?.Total }} Users </p>
    </div>
</div>