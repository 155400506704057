import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PrinterUserService, PrinterService } from '../../_data/print-manager.service';
import { pmgRawPrinterUser, pmgPrinterDb, pmgPrinterUserDb } from '../../_models/PMG.model';
import { finalize } from 'rxjs/operators';
import { NotifierService } from '../../_sharedData/notifier.service';
import { AlertService } from '../../_services';
import { orgPerson } from '../../_models/ORG.model';
import { cfgWarehouse } from '../../_models/CFG.model';

@Component({
  selector: 'arc-printer-user-edit',
  templateUrl: './printer-user-edit.component.html',
  styleUrls: ['./printer-user-edit.component.scss']
})
export class PrinterUserEditComponent implements OnInit {


  @Output() close = new EventEmitter<any>();

  private _userID = new BehaviorSubject<number>(null);
  @Input()
  set userID(value) {
    this._userID.next(value);
  }
  get userID() {
    return this._userID.getValue();
  }

  private _appName = new BehaviorSubject<string>(null);
  @Input()
  set appName(value) {
    this._appName.next(value);
  }
  get appName() {
    return this._appName.getValue();
  }

  private _warehouseID = new BehaviorSubject<number>(null);
  @Input()
  set warehouseID(value) {
    this._warehouseID.next(value);
  }
  get warehouseID() {
    return this._warehouseID.getValue();
  }

  lastAppName = '';
  lastWarehouseID = -1;
  lastUserID = -1;

  items: pmgRawPrinterUser[] = [];
  person: orgPerson;
  warehouse: cfgWarehouse;
  printers: pmgPrinterDb[] = [];

  constructor(
    private itemsService: PrinterUserService,
    private notifier: NotifierService,
    private alert: AlertService,
    private printerService: PrinterService
  ) { }

  ngOnInit(): void {

    this._userID.subscribe(
      () => this.loadPrinters(),
      (error) => this.alert.error(error)
    );

    this._warehouseID.subscribe(() => {
      this.loadPrinters();
      if (this.warehouseID) {
        this.printerService.getByWarehouse(this.warehouseID)
          .subscribe(
            x => this.printers = x,
            (error) => this.alert.error(error));
      }
    },
      (error) => this.alert.error(error)
    );

    this._appName.subscribe(
      () => this.loadPrinters(),
      (error) => this.alert.error(error)
    );

  }

  loadPrinters() {
    console.log('userID:', this.userID);
    console.log('appName', this.appName);
    console.log('warehouseID', this.warehouseID);
    if (this.userID && this.appName && this.warehouseID) {
      if ((this.userID != this.lastUserID) || (this.appName != this.lastAppName) || (this.warehouseID != this.lastWarehouseID)) {
        this.lastUserID = this.userID;
        this.lastAppName = this.appName;
        this.lastWarehouseID = this.warehouseID;

        this.itemsService.getForUser(this.appName, this.userID, this.warehouseID)
          .pipe(finalize(() => this.notifier.loadingOff()))
          .subscribe(
            (data: pmgRawPrinterUser[]) => {
              this.items = data;
              console.log('got item', this.items);
            },
            (error) => this.alert.error(error)
          );
      }
    }
  }

  printersByType(typeID: number) {
    var x = this.printers.filter(x => x.PrinterTypeID === typeID).map(x => ({ label: x.PrinterName.substring(x.PrinterName.lastIndexOf('\\') + 1), value: x.PrinterID }));
    x.unshift({ label: '*none*', value: 0 });
    return x;
  }

  doSave() {
    let newList: pmgPrinterUserDb[];
    const selectedAppName1 = this.appName;
    newList = this.items
      .filter(a => a.PrinterID > 0)
      .map((a) => ({ PrinterUserID: -2, PrinterID: a.PrinterID, UserID: a.UserID, AppName: selectedAppName1 }))
    this.itemsService.saveList(this.userID, newList)
      .subscribe(
        (x) => {
          console.log('saved:', x);
          this.alert.success("saved!");
          this.toggleDialog();
        },
        (error) => this.alert.error(error)
      );

  }

  doCancel() {
    this.toggleDialog();
  }

  toggleDialog() {
    this.close.emit('close');
  }
}
