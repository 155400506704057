
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelForValuePipe } from '../_pipes/label-for-value.pipe';
import { LabelForEnumPipe } from '../_pipes/label-for-enum.pipe';
import { MinusToParensPipe } from '../_pipes/minus-to-parens.pipe';
import { TruncatePipe } from '../_pipes/truncate.pipe';
import { MinsToTimePipe } from '../_pipes/mins-to-time.pipe';
// import { FocusDirective } from '../_directives/focus.directive';
import { DecimalInputDirective } from '../_directives/decimal-input.directive';
import { NumericInputDirective } from '../_directives/numeric-input.directive';
import { ShowErrorsComponent } from '../_helpers/show-errors.component';
import { ShowStampComponent } from '../_helpers/show-stamp/show-stamp.component';
import { BlockableDivComponent } from '../_helpers/blockable-div.component';
import { DocumentsComponent } from '../_helpers/documents/documents.component';
import { DocumentViewComponent } from '../_helpers/document-view/document-view.component';
import { OrgDocumentsComponent } from '../_helpers/org-documents/org-documents.component';
import { OrgDocumentViewComponent } from '../_helpers/org-document-view/org-document-view.component';
import { AddressPhoneSummaryComponent } from '../_helpers/address-phone-summary/address-phone-summary.component';

import { ArcSharedServicesModule } from './arc-shared-services.module';

import { FormsModule } from '@angular/forms';
import { OrderFreightCalculatorComponent } from '../shipping/orders-freight/order-freight-edit/order-freight-calculator/order-freight-calculator.component';

import { QuickPlaceComponent } from '../organizations/places/quick-place/quick-place.component';
import { OrderNotesComponent } from '../shipping/orders/ord-notes/ord-notes.component';
import { OrdNoteSingleComponent } from '../shipping/orders/ord-note-single/ord-note-single.component';
import { DateTimeEntryComponent } from '../_helpers/date-time-entry/date-time-entry.component';
import { CustomConfirmDialogComponent } from '../_helpers/custom-confirm-dialog/custom-confirm-dialog.component';
import { ConfirmWarehouseDialogComponent } from '../_helpers/confirm-warehouse-dialog/confirm-warehouse-dialog.component';

import { UserSettingsComponent } from '../_helpers/user-settings/user-settings.component';
import { PersonSearchComponent } from '../organizations/persons/person-search/person-search.component';
import { DateRangeValidator } from '../_directives/date-range-validator.directive';
import { SpinnerComponent } from '../_helpers/spinner/spinner.component';
import { PrinterUserEditComponent } from '../admin/printer-user-edit/printer-user-edit.component';
import { TeamSelectorComponent } from 'app/_helpers/team-selector/team-selector.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ArcSharedServicesModule
  ],
  declarations: [
    // FocusDirective,
    AddressPhoneSummaryComponent,
    BlockableDivComponent,
    BlockableDivComponent,
    CustomConfirmDialogComponent,
    ConfirmWarehouseDialogComponent,
    DateRangeValidator,
    DateTimeEntryComponent,
    DecimalInputDirective,
    NumericInputDirective,
    DocumentsComponent,
    DocumentViewComponent,
    LabelForEnumPipe,
    LabelForValuePipe,
    MinusToParensPipe,
    MinsToTimePipe,
    TruncatePipe,
    OrderFreightCalculatorComponent,
    OrderNotesComponent,
    OrdNoteSingleComponent,
    OrgDocumentsComponent,
    OrgDocumentViewComponent,
    PersonSearchComponent,
    PrinterUserEditComponent,
    QuickPlaceComponent,
    ShowErrorsComponent,
    ShowStampComponent,
    SpinnerComponent,
    UserSettingsComponent,
    TeamSelectorComponent
  ],
  exports: [
    // FocusDirective,
    AddressPhoneSummaryComponent,
    BlockableDivComponent,
    BlockableDivComponent,
    CustomConfirmDialogComponent,
    ConfirmWarehouseDialogComponent,
    DateRangeValidator,
    DateTimeEntryComponent,
    DecimalInputDirective,
    NumericInputDirective,
    DocumentsComponent,
    DocumentViewComponent,
    LabelForEnumPipe,
    LabelForValuePipe,
    MinusToParensPipe,
    MinsToTimePipe,
    TruncatePipe,
    OrderFreightCalculatorComponent,
    OrderNotesComponent,
    OrdNoteSingleComponent,
    OrgDocumentsComponent,
    OrgDocumentViewComponent,
    PersonSearchComponent,
    PrinterUserEditComponent,
    QuickPlaceComponent,
    ShowErrorsComponent,
    ShowStampComponent,
    SpinnerComponent,
    UserSettingsComponent,
    TeamSelectorComponent
  ]
})
export class ArcSharedWidgetsModule { }
