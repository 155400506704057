<!-- <div style="background-color: #fbfbfb; text-align: center; margin-bottom: 10px;">
    <h1>Assignments</h1>
    <p>DocumentID: {{ documentID }}</p>
</div> -->

<p-table #dt [value]="items" sortMode="multiple" [multiSortMeta]="localFilter.MultiSortMeta"
    (onFilter)="doFilter($event)" (onSort)="onSort($event)" (onPage)="onPage($event)" [paginator]="true"
    [alwaysShowPaginator]="false">
    <ng-template pTemplate="caption">
        {{ itemName }}
    </ng-template>

    <!--Table Header Template-->
    <ng-template pTemplate="header">
        <!--Column Headers-->
        <tr>
            <th style="width:3em"></th>
            <th pSortableColumn="DocumentTitle" *ngIf="documentID <= 0"
                [ngStyle]="{ 'width': '20%', 'text-align': 'left' }">
                Document Title
                <span>
                    <p-sortIcon field="DocumentTitle"></p-sortIcon>
                </span>
            </th>
            <th pSortableColumn="PersonName" *ngIf="personID <= 0" [ngStyle]="{ 'width': '15%', 'text-align': 'left' }">
                Person
                <span>
                    <p-sortIcon field="PersonName"></p-sortIcon>
                </span>
            </th>
            <th pSortableColumn="Warehouse" *ngIf="personID <= 0" [ngStyle]="{ 'width': '10%', 'text-align': 'left' }">
                Warehouse
                <span>
                    <p-sortIcon field="Warehouse"></p-sortIcon>
                </span>
            </th>
            <!-- <th pSortableColumn="VersionID" [ngStyle]="{ 'width': '15%', 'text-align': 'left' }">
                Version
                <span>
                    <p-sortIcon field="VersionID"></p-sortIcon>
                </span>
            </th> -->
            <th pSortableColumn="AssignedDate" [ngStyle]="{ 'width': '10%', 'text-align': 'left' }">
                Assigned
                <span>
                    <p-sortIcon field="AssignedDate"></p-sortIcon>
                </span>
            </th>
            <th pSortableColumn="AssignedByName" [ngStyle]="{ 'width': '10%', 'text-align': 'left' }">
                By
                <span>
                    <p-sortIcon field="AssignedByName"></p-sortIcon>
                </span>
            </th>
            <th pSortableColumn="SoftDueDate" [ngStyle]="{ 'width': '15%', 'text-align': 'left' }">
                Soft Due
                <span>
                    <p-sortIcon field="SoftDueDate"></p-sortIcon>
                </span>
            </th>
            <th pSortableColumn="HardDueDate" [ngStyle]="{ 'width': '15%', 'text-align': 'left' }">
                Hard Due
                <span>
                    <p-sortIcon field="HardDueDate"></p-sortIcon>
                </span>
            </th>
            <th pSortableColumn="CompletedDate" [ngStyle]="{ 'width': '15%', 'text-align': 'left' }"
                *ngIf="includeCompleted">
                Completed
                <span>
                    <p-sortIcon field="CompletedDate"></p-sortIcon>
                </span>
            </th>
            <th pSortableColumn="CancelledDate" [ngStyle]="{ 'width': '15%', 'text-align': 'left' }"
                *ngIf="includeCompleted">
                Cancelled
                <span>
                    <p-sortIcon field="CancelledDate"></p-sortIcon>
                </span>
            </th>
            <th *ngIf="includeCompleted" style="width:3em"></th>
            <!-- <th pSortableColumn="CancelledDate" [ngStyle]="{ 'width': '15%', 'text-align': 'left' }">
                Cancelled
                <span>
                    <p-sortIcon field="CancelledDate"></p-sortIcon>
                </span>
            </th> -->
            <th style="width:3em"></th>
            <th style="width:3em"></th>
        </tr>

        <!--Column Filters-->
        <tr *ngIf="items?.length > 0">
            <th style="width:3em; text-align: center;" class="col-icon-delete">
                <div>
                    <i class="fa fa-ban" (click)="clearLocalFilter(dt)"></i>
                </div>
            </th>
            <th *ngIf="documentID <= 0">
                <input pInputText status="text" id="DocumentTitleFilter" [(ngModel)]="localFilter.DocumentTitle"
                    (input)="dt.filter(localFilter.DocumentTitle, 'DocumentTitle', 'contains')">
            </th>
            <th *ngIf="personID <= 0">
                <input pInputText status="text" id="PersonNameFilter" [(ngModel)]="localFilter.PersonName"
                    (input)="dt.filter(localFilter.PersonName, 'PersonName', 'contains')">
            </th>
            <th *ngIf="personID <= 0">
                <p-multiSelect [options]="warehouses" defaultLabel="All" [(ngModel)]="localFilter.Warehouse"
                    (onChange)="dt.filter($event.value, 'Warehouse', 'in')" appendTo="body" [style]="{'width':'100%'}">
                </p-multiSelect>
            </th>
            <th>
                <p-calendar [(ngModel)]='localFilter.AssignedDate'
                    (onSelect)="dt.filter(localFilter.AssignedDate, 'AssignedDate', 'dateRangeFilter')"
                    (onClearClick)="dt.filter('', 'AssignedDate','contains')" [showButtonBar]="true"
                    [readonlyInput]="true" [monthNavigator]="true" [yearNavigator]="true"
                    [inputStyle]="{'width': '100%'}" appendTo="body" yearRange="2000:2030" selectionMode="range">
                </p-calendar>
            </th>
            <th>
                <p-multiSelect [options]="assignees" defaultLabel="All" [(ngModel)]="localFilter.AssignedByName"
                    (onChange)="dt.filter($event.value, 'AssignedByName', 'in')" appendTo="body"
                    [style]="{'width':'100%'}">
                </p-multiSelect>
            </th>
            <th>
                <p-calendar [(ngModel)]='localFilter.SoftDueDate'
                    (onSelect)="dt.filter(localFilter.SoftDueDate, 'SoftDueDate', 'dateRangeFilter')"
                    (onClearClick)="dt.filter('', 'SoftDueDate','contains')" [showButtonBar]="true"
                    [readonlyInput]="true" [monthNavigator]="true" [yearNavigator]="true"
                    [inputStyle]="{'width': '100%'}" appendTo="body" yearRange="2000:2030" selectionMode="range">
                </p-calendar>
            </th>
            <th>
                <p-calendar [(ngModel)]='localFilter.HardDueDate'
                    (onSelect)="dt.filter(localFilter.HardDueDate, 'HardDueDate', 'dateRangeFilter')"
                    (onClearClick)="dt.filter('', 'HardDueDate','contains')" [showButtonBar]="true"
                    [readonlyInput]="true" [monthNavigator]="true" [yearNavigator]="true"
                    [inputStyle]="{'width': '100%'}" appendTo="body" yearRange="2000:2030" selectionMode="range">
                </p-calendar>
            </th>
            <th *ngIf="includeCompleted"></th>
            <th *ngIf="includeCompleted"></th>
            <th *ngIf="includeCompleted"></th>
            <th style="width:3em"></th>
            <th style="width:3em"></th>
        </tr>
    </ng-template>

    <!--Table Body Template-->
    <ng-template pTemplate="body" let-rowData let-item>
        <!--Column Rows-->
        <tr [pSelectableRow]="rowData">
            <td style="text-align:center">
                <p-button icon="fa fa-edit" (click)="editAssignment(rowData)"
                    [hidden]="item.CompletedDate || !mayEditAssignments()" pTooltip="Edit Assignment">
                </p-button>
            </td>
            <td *ngIf="documentID <= 0" style="color:blue; text-decoration:underline; cursor:pointer;"
                (click)="goToDocument(item.DocumentID)">
                {{ item.DocumentTitle }}
            </td>
            <td *ngIf="personID <= 0">
                {{ item.PersonName }}
            </td>
            <td *ngIf="personID <= 0">
                {{ item.Warehouse }}
            </td>
            <!-- <td>
                {{ item.VersionID }}
            </td> -->
            <td>
                {{ item.AssignedDate | date }}
            </td>
            <td>
                {{ item.AssignedByName }}
            </td>
            <td>
                {{ item.SoftDueDate | date }}
            </td>
            <td>
                {{ item.HardDueDate | date }}
            </td>
            <td *ngIf="includeCompleted">
                {{ item.CompletedDate | date }}
            </td>
            <td *ngIf="includeCompleted" [pTooltip]="item.CancelledBy">
                {{ item.CancelledDate | date }}
            </td>
            <td *ngIf="includeCompleted">
                <p-button icon="fa fa-repeat" (onClick)="reassign(rowData)" [disabled]="!item.CompletedDate"
                    pTooltip="Reassign" tooltipPosition="left"></p-button>
            </td>
            <!-- <td pTooltip="item.CancelledBy">
                {{ item.CancelledDate | date }}
            </td> -->
            <td style="text-align:center">
                <p-button icon="fa fa-question" (onClick)="showReason(rowData)" [pTooltip]="rowData.AssignmentReason"
                    tooltipPosition="top"></p-button>
            </td>
            <td style="text-align:center">
                <p-button icon="fa fa-trash-o" (onClick)="confirmDelete(rowData)" [hidden]="!mayEditAssignments()">
                </p-button>
            </td>
        </tr>
    </ng-template>

    <!--Table Footer Template-->
    <ng-template pTemplate="summary" style="height: 48px">
        <span style="float:left; width: 150px;">
            <p-button icon="fa fa-plus" [ngStyle]="{'float':'left'}" (click)="addAssignment()" label="New Assignment"
                [hidden]="!mayAddAssignments()"></p-button>
        </span>
        <span style="float:right; width: 250px;">
            <p-button icon="pi pi-history" [ngStyle]="{'float':'right'}" (click)="toggleIncludeCompleted()"
                [label]="includeCompleted ? 'Hide Completed' : 'Show Completed'"></p-button>
        </span>
        Count: {{ dt.totalRecords | number}}
    </ng-template>


</p-table>

<p-dialog header="Edit Assignment" *ngIf="showAssignDialog" [(visible)]="showAssignDialog" [modal]="true"
    appendTo="body" [closable]="false" [style]="{width: '1000px', height: '42vh'}" [minY]="100">
    <arc-assignment-edit [assignmentID]="editAssignmentID" [documentID]="editDocumentID" [personID]="editPersonID"
        [reassign]="reassignToggle" (editComplete)="editComplete()"></arc-assignment-edit>
</p-dialog>

<p-dialog header="Assignment Reason" *ngIf="showReasonDialog" [(visible)]="showReasonDialog" [modal]="true"
    [style]="{width: '800px', height: '20vh'}" [minY]="100">
    <div style="font-size: large;">
        <p>Assigned By: {{ selectedItem.AssignedByName }}</p>
        <p *ngIf="selectedItem.FromTriggerID">Reason: {{selectedItem.AssignmentReason}}</p>
        <p *ngIf="selectedItem.FromTriggerID">Trigger: {{ selectedItem.FromTriggerName }}</p>
    </div>
</p-dialog>