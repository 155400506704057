<div *ngIf="displayMe" [ngStyle]="{'float':'left', 'display':'block'}">

  <div>
    <span class="caption">Created:</span>
    <span>{{ item?.CreatedDate | date: 'MM/dd/yyyy hh:mm a' : offsetString }} : {{ creator?.PersonName }}</span>
  </div>
  <div>
    <span class="caption">Updated:</span>
    <span>{{ item?.UpdatedDate | date: 'MM/dd/yyyy hh:mm a' : offsetString }} : {{ updater?.PersonName }}</span>
  </div>
  <!-- <div>
    <span>
      <p-button label="PST" (onClick)="setOffset(420)"></p-button>
      <p-button label="MST" (onClick)="setOffset(360)"></p-button>
      <p-button label="CST" (onClick)="setOffset(300)"></p-button>
      <p-button label="EST" (onClick)="setOffset(240)"></p-button>
    </span>
  </div> -->
</div>