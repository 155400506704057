<div *ngIf="addingIssues && !ApprovalPayrollFlag">
	<h2 *ngIf="!selectedIssueType"  style="display: flex; justify-content: center;">Select an Issue Type</h2>
	<h2 style="display: flex; justify-content: center;"> {{selectedIssueType?.IssueTypeName}} </h2>
	<div class="issueContainer">		
		<!-- <div class="issueTypeBtns" *ngFor="let type of visableTypes"> -->
			<!-- <p (click)="setIssueType(type)"> {{type.IssueTypeName}} </p> -->
			<!-- <p-button label=" {{type.IssueTypeName}} " (click)="setIssueType(type)"></p-button> -->
		<!-- </div> -->

		<!-- Drop Down -->
		<p-dropdown [options]="visableTypes" [(ngModel)]="selectedIssueType" optionLabel="IssueTypeName" 
			placeholder="Select Issue Type" [showClear]="true" (onChange)="setIssueType(selectedIssueType)"></p-dropdown>

	</div>
	<div class="noteContainer">
		<textarea pInputTextarea [(ngModel)]="note" autoResize="autoResize" rows="15" cols="50" placeholder="Note:"></textarea>
	</div>
	<div class="issueConfirmBtn" style="text-align: center;">
		<p-button label="Set Issue" (onClick)="setIssue()"></p-button>
	</div>
</div>

<div *ngIf="ApprovalPayrollFlag && !viewingManagerNotes" style="text-align: center;">
	<h2>Time Segment has already been approved by manager.</h2>
</div>

<div class="issuesLog" *ngIf="viewingIssues">
	<div class="headerContainer" *ngIf="!ApprovalPayrollFlag" style="text-align: center;">
		<p-button label="Add Issue" (onClick)="addIssue()"></p-button>
	</div>
	<div class="issuesList" *ngIf="deliveredIssuePackage">
		<hr>
		<p-table [value]="deliveredIssuePackage">
			<ng-template pTemplate="header">
				<tr>
					<th><h2>Issue Type</h2></th>
					<th><h2>Issue Note</h2></th>
					<th><h2>Edit</h2></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-item let-index="rowIndex">
				<tr>

					<td style="text-align: center;">
						<h4>{{ getIssueName(item.IssueTypeID) }}</h4>
					</td>
					<td style="text-align: center;">
						<h4>{{ item.IssueNote }}</h4>
					</td>
					<td style="text-align: center;">
						<div (click)="edit(item)" style="display: flex; justify-content: center;" *ngIf="item.IsAutoGenerated === false && (!item.ResolvedDate && ApprovalPayrollFlag === false)">
							<i class="pi pi-pencil" style="width: 20%; justify-content: center; padding: 10px; border: 1px solid orange; border-radius: 10px; cursor: pointer;"></i>
						</div>
						<div (click)="viewManagerNotes(item)" *ngIf="item.ResolvedDate" style="display: flex; justify-content: center; ">
							<p style=" color: white; font-weight: 500; width: 120px; text-align: center; padding: 10px; border-radius: 10px; background-color: green; cursor: pointer;">Resolved</p>
						</div>
						<!-- <div style="display: flex; justify-content: center;" *ngIf="ApprovalPayrollFlag === true">
							<i style="width: 20%; justify-content: center; padding: 10px; border: 1px solid green; border-radius: 10px;">Sent to Payroll</i>
						</div> -->
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>

<!-- Viewing Manager Notes on Resoloved Issue -->
<div class="issuesLog" *ngIf="viewingManagerNotes">
	<div class="headerContainer" style="text-align: center;">
		<p-button label="Back" (onClick)="finishViewingManagerNotes()"></p-button>
	</div>
	<hr>
	<div class="issueList" style="padding: 20px;">
		<div class="row">
			<div class="left"><i><h2>Issue Note: </h2></i></div>
			<div class="right"><h3>{{ issueBeingViewed.IssueNote }}</h3></div>
		</div>
		<div class="row">
			<div class="left"><i><h2>Resoloved By: </h2></i></div>
			<div class="right"><h3>{{ issueBeingViewed.ResolvedByName }}</h3></div>
		</div>
		<div class="row">
			<div class="left"><i><h2>Resolved On: </h2></i></div>
			<div class="right"><h3>{{ issueBeingViewed.ResolvedDate | date:'medium' }}</h3></div>
		</div>
		<div class="row">
			<div class="left"><i><h2>Manager Note: </h2></i></div>
			<div class="right"><h3>{{ issueBeingViewed.ResolvedNote }}</h3></div>
		</div>
	</div>
</div>
<!-- ----------------------------------- -->
