import { Component, OnInit } from '@angular/core';
import { NotifierService } from '../../_sharedData/notifier.service';

@Component({
  selector: 'arc-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  loading = true;

  constructor(
    private notifier: NotifierService,
  ) { }

  ngOnInit() {
    this.notifier.SpinnerNotifier$
      .subscribe((val: boolean) => {
        // console.log('Spinner Notification', val);
        // 4/10/2020 RJS The following setTimeout is here to allow change detection to occur and eliminte the ExpressionChangedAfterIsHasBeenChecked error (Angular)
        setTimeout(() => {
          this.loading = val;
        });
      });
  }

}
