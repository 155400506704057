<div class="p-grid">
    <div class="p-col-10 p-offset-1">
        <p-table #dt [value]="items" sortMode="multiple" (onFilter)="doFilter($event)" (onSort)="onSort($event)"
            (onPage)="onPage($event)" [paginator]="true" [alwaysShowPaginator]="false" [rows]="30"
            [multiSortMeta]="localFilter.MultiSortMeta">
            <ng-template pTemplate="caption">
                <div style="display:flex; justify-content: space-between; margin: 0 25px 0 25px;">
                    <span style="padding: 5px;">{{ itemName }}</span>
                    <button pButton icon="pi pi-search" style="width: 12%;" label="{{docTextButtonText}}"
                        (click)="searchDocumentText()"></button>
                </div>
                <div *ngIf="searchingDocText" style="margin: 10px;">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-book"></i></span>
                        <input type="text" #docSearch [(ngModel)]="docText" pInputText placeholder="Document Text"
                            (keydown.enter)="queryDocumentText()">
                        <button type="button" pButton pRipple label="Search" (click)="queryDocumentText()"
                            icon="pi pi-search"></button>
                    </div>
                </div>
            </ng-template>

            <!--Table Header Template-->
            <ng-template pTemplate="header">
                <!--Column Headers-->
                <tr>
                    <th style="width:3em"></th>
                    <th pSortableColumn="ReferenceNumber" [ngStyle]="{'width':'5%', 'text-align':'center'}">
                        Ref #
                        <span>
                            <p-sortIcon field="ReferenceNumber"></p-sortIcon>
                        </span>
                    </th>
                    <th pSortableColumn="Title" [ngStyle]="{ 'width': '30%', 'text-align': 'left' }">
                        Title
                        <span>
                            <p-sortIcon field="Title"></p-sortIcon>
                        </span>
                    </th>
                    <th pSortableColumn="CategoryName" [ngStyle]="{ 'width': '13%', 'text-align': 'left' }">
                        Category
                        <span>
                            <p-sortIcon field="CategoryName"></p-sortIcon>
                        </span>
                    </th>
                    <th pSortableColumn="VersionCount" [ngStyle]="{ 'width': '10%', 'text-align': 'left' }">
                        # Versions
                        <span>
                            <p-sortIcon field="VersionCount"></p-sortIcon>
                        </span>
                    </th>
                    <th pSortableColumn="PublishedVersionCount" [ngStyle]="{ 'width': '10%', 'text-align': 'left' }">
                        # Pub. Versions
                        <span>
                            <p-sortIcon field="PublishedVersionCount"></p-sortIcon>
                        </span>
                    </th>
                    <th pSortableColumn="AssignmentCount" [ngStyle]="{ 'width': '10%', 'text-align': 'left' }">
                        # Assignments
                        <span>
                            <p-sortIcon field="AssignmentCount"></p-sortIcon>
                        </span>
                    </th>
                    <th pSortableColumn="TriggerCount" [ngStyle]="{ 'width': '7%', 'text-align': 'left' }">
                        # Triggers
                        <span>
                            <p-sortIcon field="TriggerCount"></p-sortIcon>
                        </span>
                    </th>
                    <th pSortableColumn="NotesCount" [ngStyle]="{ 'width': '7%', 'text-align': 'left' }">
                        # Notes
                        <span>
                            <p-sortIcon field="NotesCount"></p-sortIcon>
                        </span>
                    </th>
                    <th pSortableColumn="HasSearchText" [ngStyle]="{ 'width': '8%', 'text-align': 'left' }"
                        pTooltip="Has Searchable Text" tooltipPosition="top">
                        Has ST
                        <span>
                            <p-sortIcon field="HasSearchText"></p-sortIcon>
                        </span>
                    </th>

                    <!-- <th pSortableColumn="PublishedFileCount">
                        PDF File Count
                        <span>
                            <p-sortIcon field="PublishedFileCount"></p-sortIcon>
                        </span>
                    </th>
                    <th pSortableColumn="SourceFileCount">
                        SRC File Count
                        <span>
                            <p-sortIcon field="SourceFileCount"></p-sortIcon>
                        </span>
                    </th> -->
                    <th style="width:3em"></th>
                </tr>

                <!--Column Filters-->
                <tr>
                    <th style="width:3em; text-align: center;" class="col-icon-delete" (click)="clearLocalFilter(dt)">
                        <div>
                            <i class="fa fa-ban"></i>
                        </div>
                    </th>
                    <th>
                        <input pInputText status="text" id="ReferenceNumberFilter"
                            [(ngModel)]="localFilter.ReferenceNumber"
                            (input)="dt.filter(localFilter.ReferenceNumber, 'ReferenceNumber', 'contains')">
                    </th>
                    <th>
                        <input pInputText status="text" id="DocumentTitleFilter" [(ngModel)]="localFilter.DocumentTitle"
                            (input)="dt.filter(localFilter.DocumentTitle, 'Title', 'contains')">
                    </th>
                    <th>
                        <!-- <input pInputText status="text" id="CategoryNameFilter" [(ngModel)]="localFilter.CategoryName"
                            (input)="dt.filter(localFilter.CategoryName, 'CategoryName', 'startsWith')"> -->
                        <!-- <p-dropdown [options]="documentCategories" [(ngModel)]="localFilter.DocumentCategoryID"
                            showClear="true" (onClear)="resetFilter()" optionLabel="label"
                            (onChange)="dt.filter(localFilter.DocumentCategoryID, 'CategoryID', 'equals')"
                            optionValue="value" placeholder="Categories"></p-dropdown> -->
                        <p-multiSelect [options]="documentCategories" defaultLabel="All"
                            [(ngModel)]="localFilter.DocumentCategoryID"
                            (onChange)="dt.filter(localFilter.DocumentCategoryID, 'CategoryID', 'equals')"
                            appendTo="body" optionValue="value" placeholder="Categories" [style]="{'width':'100%'}">
                        </p-multiSelect>
                    </th>
                    <th style="width:3em">
                        <input pInputText status="text" id="NumberVersions" [(ngModel)]="localFilter.NumberVersions"
                            (input)="dt.filter(localFilter.NumberVersions, 'VersionCount', 'contains')">
                    </th>
                    <th style="width:3em">
                        <input pInputText status="text" id="NumberPublishedVersions"
                            [(ngModel)]="localFilter.NumberPublishedVersions"
                            (input)="dt.filter(localFilter.NumberPublishedVersions, 'PublishedVersionCount', 'contains')">
                    </th>
                    <th style="width:3em">
                        <input pInputText status="text" id="NumberAssignments"
                            [(ngModel)]="localFilter.NumberAssignments"
                            (input)="dt.filter(localFilter.NumberAssignments, 'AssignmentCount', 'contains')">
                    </th>
                    <th style="width:3em">
                        <input pInputText status="text" id="NumberTriggers" [(ngModel)]="localFilter.NumberTriggers"
                            (input)="dt.filter(localFilter.NumberTriggers, 'TriggerCount', 'contains')">
                    </th>
                    <th style="width:3em">
                        <input pInputText status="text" id="NumberNotes" [(ngModel)]="localFilter.NumberNotes"
                            (input)="dt.filter(localFilter.NumberNotes, 'NotesCount', 'contains')">
                    </th>
                    <th style="width:3em; text-align:center;">
                        <p-triStateCheckbox [(ngModel)]="localFilter.HasSearchText"
                            (onChange)="dt.filter($event.value, 'HasSearchText', 'contains')"></p-triStateCheckbox>
                    </th>
                    <!-- <th style="width:3em">
                        <input pInputText status="text" id="NumberPDF" [(ngModel)]="localFilter.NumberPDF"
                        (input)="dt.filter(localFilter.NumberPDF, 'PublishedFileCount', 'contains')">
                    </th>
                    <th style="width:3em">
                        <input pInputText status="text" id="NumberSRC" [(ngModel)]="localFilter.NumberSRC"
                        (input)="dt.filter(localFilter.NumberSRC, 'SourceFileCount', 'contains')">
                    </th> -->
                    <th style="width:3em"></th>
                </tr>
            </ng-template>

            <!--Table Body Template-->
            <ng-template pTemplate="body" let-rowData let-item>
                <!--Column Rows-->
                <tr [pSelectableRow]="rowData">
                    <td>
                        <p-button icon="fa fa-edit" (onClick)="editItem(rowData)"></p-button>
                    </td>
                    <td style="text-align:center">
                        {{ item.ReferenceNumber }}
                    </td>
                    <td>
                        {{ item.Title }}
                    </td>
                    <td>
                        {{ item.CategoryName }}
                    </td>
                    <!-- class="cellValid" [ngClass]="{'cellInvalid':!valid(item)}" This goes down below in VersionCount -->
                    <td style="text-align:center">
                        {{ item.VersionCount }}
                    </td>
                    <td style="text-align:center;">
                        {{ item.PublishedVersionCount }}
                    </td>
                    <td style="text-align:center;">
                        {{ item.AssignmentCount }} / {{ item.TotalAssignmentCount }}
                    </td>
                    <td style="text-align:center;">
                        {{ item.TriggerCount }}
                    </td>
                    <td style="text-align:center;">
                        {{ item.NotesCount }}
                    </td>
                    <td style="text-align:center;">
                        <p-checkbox [(ngModel)]="item.HasSearchText" [binary]="true" disabled="true"></p-checkbox>
                    </td>
                    <!-- <td class="cellValid" [ngClass]="{'cellInvalid':!validPDF(item)}" style="text-align:center">
                        {{ item.PublishedFileCount }}
                    </td>
                    <td class="cellValid" [ngClass]="{'cellInvalid':!validSRC(item)}" style="text-align:center">
                        {{ item.SourceFileCount }}
                    </td> -->
                    <td>
                        <p-button *ngIf="mayEdit() && !item.IsArchived" icon="fa fa-archive"
                            (onClick)="archiveDocument(rowData, true)" pTooltip="Archive Document"></p-button>
                        <p-button *ngIf="mayEdit() && item.IsArchived" icon="fa fa-folder-open"
                            (onClick)="archiveDocument(rowData, false)" pTooltip="UN-archive Document"></p-button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div style="display:flex; justify-content:space-between; align-items:center;">
                    <button pButton icon="pi pi-plus" label="New Document" (click)="addNew()"></button>
                    <span>Count: {{dt.totalRecords}} of {{items?.length}}</span>
                    <button pButton *ngIf="!showArchiveList" icon="pi pi-star" label="Show Archive"
                        (click)="showArchive()"></button>
                    <button pButton *ngIf="showArchiveList" icon="pi pi-star" label="Show Live"
                        (click)="showLive()"></button>

                </div>
            </ng-template>
        </p-table>
    </div>
</div>