<div class="container-fluid" id="loader-holder">
  <div class="row backBeige">
    <div class="col" id="loader-holder">
      <div *ngIf="userValidationState() === 1">
        <arc-header></arc-header>
      </div>

      <!-- <arc-alert></arc-alert> -->
      <p-toast [style]="{marginTop: '80px'}" position="top-left" key="tl"></p-toast>
      <p-toast [style]="{marginTop: '80px'}" position="top-center" key="tc"></p-toast>
      <p-toast [style]="{marginTop: '80px'}" position="top-right" key="tr"></p-toast>
      <p-toast [style]="{marginTop: '80px'}" position="center" key="c"></p-toast>

      <router-outlet></router-outlet>

        <!-- <img src="../assets/spinners/loading_spinner.gif" *ngIf="displayLoader" id="loader"> -->
        
    </div>

    <p-confirmDialog modal="true" appendTo="body"></p-confirmDialog>

  </div>
</div>