import { Injectable, Inject } from '@angular/core';

// // import { server } from '../_models/index';
import { cfgStatus } from '../_models/CFG.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class StatusesService {

  api = 'cfgStatus/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<cfgStatus[]>(this.apiURL + this.api);

  }
  create(Status: cfgStatus) {
    return this.http.post(this.apiURL + this.api, Status);
  }

  update(Status: cfgStatus) {
    return this.http
      .put(this.apiURL + this.api + Status.StatusStep, Status);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getForCategory(category: string) {
    return this.http.get<cfgStatus[]>(this.apiURL + this.api + 'category/' + category);
  }
}
