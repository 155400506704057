<div style="padding-left: 15px; padding-top: 15px;">
    <button pButton label="Go Up to Document List" icon="pi pi-arrow-up" (click)="toDocumentList()"></button>
</div>
<div>
    <form #formDocument="ngForm">
        <div style="display: flex; justify-content: center; margin-top: 10px;">
            <div
                style="background-color: #fbfbfb; text-align: center; margin-bottom: 10px; padding: 15px; width: 70%; border-radius: 10px;">
                <div *ngIf="documentID < 0"
                    style="width: 100%; text-align:center; border-radius: 5px; border: 1px solid red; margin-bottom: 10px; background-color: rgb(255, 79, 79);">
                    <span style="color: white; font-weight: 200;"> -- Never upload sensitive information into ProDocs --
                    </span>
                </div>
                <h1 pTooltip="{{document?.DocumentID }}">{{ document?.Title }}</h1>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-10 p-offset-1" *ngIf="document">
                <p-panel>
                    <ng-template pTemplate="header">
                        Document Details
                    </ng-template>
                    <div class="container" style="display: flex;">
                        <div style="width: 50%; margin: 10px;">
                            <div style="display:flex; align-items:center; padding:5px;">
                                <div style="width: 15%;">
                                    <span>Reference Number:</span>
                                </div>
                                <div style="width: 10%;">
                                    <span style="font-size: larger;">{{ document.ReferenceNumber }}</span>
                                    <!-- <input pInputText id="ReferenceNumber" name="ReferenceNumber" #ReferenceNumber="ngModel"
                                        [(ngModel)]="document.ReferenceNumber" [disabled]="!mayEdit() || !editing" requried
                                        [ngClass]="{'invalidInput': ReferenceNumber.touched && ReferenceNumber.invalid}"> -->
                                </div>
                                <div style="width: 50%; text-align: right;" *ngIf="document.IsArchived">
                                    <span style="font-size: larger;">** Archived **</span>
                                </div>
                            </div>
                            <div style="display: flex; align-items: center; padding: 5px;">
                                <div style="width: 15%;">
                                    <span>Title:</span>
                                </div>
                                <div style="width: 85%;">
                                    <input pInputText id="title" name="title" #title="ngModel"
                                        [(ngModel)]="document.Title" [disabled]="!mayEdit() || !editing" required
                                        [ngClass]="{'invalidInput': title.touched && title.invalid}">
                                </div>
                            </div>
                            <div style="display: flex; align-items: center; padding: 5px;">
                                <div style="width: 15%;">
                                    <span>Category:</span>
                                </div>
                                <div style="width: 85%;">
                                    <p-dropdown [options]="categories" [(ngModel)]="document.CategoryID"
                                        optionLabel="label" optionValue="value" [disabled]="!mayEdit() || !editing"
                                        optionLabel="CategoryName" optionValue="CategoryID" id="categoryID"
                                        name="categoryID" #categoryID="ngModel" required
                                        (onChange)="changeCategory($event)"
                                        [ngClass]="{'invalidInput': categoryID.touched && categoryID.invalid}">
                                    </p-dropdown>
                                </div>
                            </div>
                            <div style="display: flex; align-items: center; padding: 5px;">
                                <div style="width: 15%;">
                                    <span>In Library:</span>
                                </div>
                                <div style="width: 15%;">
                                    <p-checkbox name="inLibrary" [(ngModel)]="document.InLibrary" binary="true"
                                        inputId="inLibrary" [disabled]="!mayEdit() || !editing">
                                    </p-checkbox>
                                </div>
                            </div>
                        </div>
                        <div style="width: 50%; display: flex; align-items: center;">
                            <div style=" padding: 5px; width: 100%;">
                                <div style="padding-bottom: 5px;">
                                    <span>Document Description:</span>
                                </div>
                                <textarea rows="5" pInputTextarea style="max-width: 100%;" id="description"
                                    name="description" #description="ngModel" [(ngModel)]="document.Description"
                                    [readOnly]="!editing" [disabled]="!mayEdit() || !editing" required
                                    [ngClass]="{'invalidInput': description.touched && description.invalid}"></textarea>
                            </div>
                        </div>
                    </div>
                    <!-- HOT START VERSION NUMBER -->
                    <div class="container" *ngIf="editing && documentID < 0"
                        style="display:flex; Justify-content:center">
                        <button pButton label="Hot Start Version Number" *ngIf="editHotStart === false"
                            (click)="toggleEditHotStart()"></button>

                        <div *ngIf="editHotStart === true"
                            style="display:flex; width: 10%; justify-content: space-evenly;">
                            <div style="width: 35px;">
                                <input arcNumericInput [(ngModel)]="majorNum" [showButtons]="true"
                                    [ngModelOptions]="{standalone: true}" [min]="1" buttonLayout="vertical" />
                            </div>
                            .
                            <div style="width: 35px;">
                                <input arcNumericInput [(ngModel)]="minorNum" [showButtons]="true"
                                    [ngModelOptions]="{standalone: true}" [min]="0" buttonLayout="vertical" />
                            </div>
                            .
                            <div style="width: 35px;">
                                <input arcNumericInput [(ngModel)]="patch" [showButtons]="true"
                                    [ngModelOptions]="{standalone: true}" [min]="0" buttonLayout="vertical" />
                            </div>
                        </div>
                    </div>
                    <!-- FOOTER -->
                    <ng-template pTemplate="Footer" *ngIf="mayEdit()">
                        <!-- <div class="footer" *ngIf="addDocText">
                            <button pButton style="width:10%;" icon="pi pi-times" label="Cancel"
                                (click)="cancelEdit()"></button>
                            <button pButton label="Save Search Text" (click)="saveSearchText()"></button>
                        </div> -->

                        <div class="footer">
                            <div style=" width: 100%; display:flex; justify-content: space-between;" *ngIf="editing">
                                <button pButton style="width:10%;" icon="pi pi-times" label="Cancel"
                                    (click)="cancelEdit()"></button>
                                <button pButton style="width:10%;" icon="pi pi-save" label="Save"
                                    (click)="saveDocument()" [disabled]="formDocument.invalid"></button>
                            </div>
                            <!-- <div style="display:flex; justify-content:space-between; align-items:center;"> -->
                            <div style="width: 100%; display: flex; justify-content:space-between; align-items:center;"
                                *ngIf="mayEdit() && !editing">
                                <button pButton style="width:15%;" icon="pi pi-trash" label="Delete Document"
                                    (click)="confirmDelete()"></button>
                                <button pButton icon="pi pi-replay" label="Re-Assign"
                                    pTooltip="{{reassignErrorTooltip}}" 
                                    [ngClass]="canReassign() ? 'p-button-primary' : 'p-button-secondary'"
                                    (click)="reassignMenu.toggle($event)"></button>
                                <button pButton style="width:10%;" icon="pi pi-pencil" label="Edit"
                                    (click)="editDocumentDetails()"></button>
                            </div>
                        </div>
                    </ng-template>
                </p-panel>
            </div>
        </div>
    </form>
    <div style="width: 100%; display: flex; justify-content: center;" *ngIf="documentID > 0">
        <div style="width: 85%; background-color: #fbfbfb; border-radius: 10px; padding: 10px;">
            <p-tabView [activeIndex]="selectedTab" [ngStyle]="{'width':'100%'}" *ngIf="document">
                <p-tabPanel header="Versions ({{versionCount}}) ">
                    <arc-versions (count)="setVersionCount($event)" [documentID]="documentID"></arc-versions>
                </p-tabPanel>
                <p-tabPanel header="Triggers ({{triggerCount}}) ">
                    <arc-triggers (count)="setTriggerCount($event)" [documentInID]="documentID"></arc-triggers>
                </p-tabPanel>
                <p-tabPanel header="Assignments ({{assignmentCount}}) ">
                    <arc-assignments #assignmentComponent (count)="setAssignmentCount($event)" [documentID]="documentID">
                    </arc-assignments>
                </p-tabPanel>
                <p-tabPanel header="Notes ({{noteCount}}) ">
                    <arc-docNotes (loaded)="setNoteCount($event)" [documentID]="documentID"></arc-docNotes>
                </p-tabPanel>
                <p-tabPanel header="Function Links ({{functions.length}}) "
                    style="display: flex; justify-content:center">
                    <div style="width: 25vw; background-color: lightslategray; padding: 10px; border-radius: 10px;">
                        <div style="display:flex; justify-content:space-between; align-items: center; width: 100%;">
                            <div style="display:flex; align-items:center; justify-content: space-between; width: 100%;">
                                <h3 style="width: 30%; color: white;">Function Links</h3>
                                <div>
                                    <p-autoComplete [delay]="200" placeholder="Search Functions"
                                        [(ngModel)]="selectedFunctionLink" (onSelect)="selectFunction($event)"
                                        (onDropdownClick)="filterFunctions($event)" [suggestions]="filteredFunctions"
                                        (completeMethod)="filterFunctions($event)" field="DisplayName"
                                        [dropdown]="true">
                                        <ng-template let-item pTemplate="item" style="width: 200%;">
                                            <div>
                                                <div> {{item.FunctionCode}}: {{item.FunctionName}}</div>
                                            </div>
                                        </ng-template>
                                    </p-autoComplete>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p-table [value]="functions" *ngIf="functions.length > 0">
                                <ng-template pTemplate="body" let-item>
                                    <tr>
                                        <td>
                                            {{ item.FunctionCode }}: {{ item.FunctionName }}
                                        </td>
                                        <td style="text-align:center; width:5%;">
                                            <p-button icon="fa fa-trash-o" (onClick)="deleteLink(item)"></p-button>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            <div *ngIf="functions.length === 0"
                                style="text-align: center; padding: 5px; width: 100%; background-color:rgb(255, 79, 79); border-radius: 10px; color: white;">
                                <h4>No Functions Linked</h4>
                            </div>
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="History ({{historyCount}}) ">
                    <arc-document-version-history (count)="setHistoryCount($event)" [documentID]="documentID">
                    </arc-document-version-history>
                </p-tabPanel>
                <p-tabPanel header="Searchable Text (Length: {{searchTextString.length}} characters) ">
                    <div>
                        <div style="padding-bottom: 5px;">
                            <span>Searchable Document Text:</span>
                        </div>
                        <textarea rows="15" pInputTextarea style="max-width: 100%;" [(ngModel)]="searchTextString"
                            [readOnly]="!addDocText" [disabled]="!mayEdit()" id="SearchableText"></textarea>
                        <div class="footer">
                            <button pButton *ngIf="addDocText" icon="pi pi-times" label="Cancel"
                                (click)="cancelSearchText()" iconPos="left"></button>
                            <button pButton *ngIf="addDocText" icon="pi pi-check" label="Save Search Text"
                                (click)="saveSearchText()" iconPos="right"></button>
                            <div style="width: 100%; text-align:center;" *ngIf="!addDocText">
                                <button pButton icon="pi pi-pencil" label="Edit" (click)="editSearchText()"
                                    iconPos="right"></button>
                            </div>
                        </div>
                    </div>
            </p-tabPanel>
            </p-tabView>
        </div>
    </div>

</div>

<p-dialog header="{{'Selective Reassign: ' + document.Title}}" *ngIf="showReassignDialog" [(visible)]="showReassignDialog" [modal]="true"
    [style]="{width: '1500px', minWidth: '1200px'}" [minY]="100" [maximizable]="false">
    <arc-reassign-select [documentID]="reassignDocumentID" [documentTitle]="document.Title" (assignmentsMade)="doUpdateAssignments($event)"></arc-reassign-select>
</p-dialog>



<p-menu #reassignMenu [model]="reassignMenuItems" [popup]="true"></p-menu>