import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { AuthService, AlertService, BreadcrumbService } from '../_services/index';
import { User } from '../_models/user.model';

@Component({
  selector: 'arc-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  currentUser: any;
  // subscription: Subscription;

  validating = true;

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private router: Router
  ) {
    this.currentUser = this.authService.currentUser;
  }

  ngOnInit() {
    this.authService.userValidations$.subscribe(s => this.validating = false);

    if (this.authService.userValidationState === 0) {
      if (!this.authService.getAuthentication()) {
        this.router.navigate(['/']);
      }
    }
  }

  checkIsAuthenticated(): boolean {
    return this.authService.isAuthenticated;
  }
}
