
import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import { cfgServiceUnit } from '../_models/CFG.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ServiceUnitsService {

  api = 'cfgServiceUnit/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<cfgServiceUnit[]>(this.apiURL + this.api);

  }
  create(item: cfgServiceUnit) {
    return this.http.post(this.apiURL + this.api, item);
  }

  update(item: cfgServiceUnit) {
    return this.http
      .put(this.apiURL + this.api + item.ServiceUnitID, item);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

}

