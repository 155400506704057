import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { docDashboardService } from 'app/_data/pro-docs.service';
import { docDashboardActionsItem, } from 'app/_models/DOC.model';
import { AlertService, AuthService } from 'app/_services';
import { LookupsService } from 'app/_services/lookups.service';
import { PersistenceService } from 'app/_services/persistence.service';
import { NotifierService } from 'app/_sharedData/notifier.service';
import { SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';

// **********************
// ****** GLK 2022 ******
// **********************

@Component({
  selector: 'prodocs-dashboard-employee-actions',
  templateUrl: './prodocs-dashboard-employee-actions.component.html',
  styleUrls: ['./prodocs-dashboard-employee-actions.component.scss']
})
export class ProdocsDashboardEmployeeActionsComponent implements OnInit {

  // ** TOGGLES ** //
  headerString: string;
  buttonString: string;
  completeString: string;
  selectedDocType: number = null;
  isAdmin = false;

  // ** DATA ** //
  documentTypes: SelectItem[] = [];
  actions: docDashboardActionsItem[] = [];
  teamMembers: string[] = [];
  documents: string[] = [];
  versions: string[] = [];
  roles: string[] = [];

  // ** DATA TABLE FILTERING ** //
  @ViewChild('dt', { static: true }) mydt: Table;

  multiSortMetaDefault = [{ field: 'Title', order: 1 }];
  //#region FilterNames
  storedDTFilters = 'ProdocsActionsDashboardDTFilters';
  storedInputFilters = 'ProDocsActionsDashboardFilters';

  localFilter = {
    PersonName: null,
    Title: null,
    VersionNumber: null,
    RoleName: null,
    ActionDueDate: null,
    ActionCompletedDate: null,
    ShowCompleted: false,
    ShowAllActors: false,
    MultiSortMeta: this.multiSortMetaDefault,
    rowsPerPage: +this.auth.currentUser.opts.PaginatorRows,
    currentRow: 0,
  };


  constructor(
    private dashboardService: docDashboardService,
    private persist: PersistenceService,
    private notifier: NotifierService,
    private alert: AlertService,
    private auth: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.mydt.rows = +this.auth.currentUser.opts.PaginatorRows;
    this.mydt.rowsPerPageOptions = this.auth.currentUser.opts.PaginatorRowOptions;
    this.mydt.paginator = true;
    this.mydt.alwaysShowPaginator = false;

    this.isAdmin = this.auth.userHasRight('actProDocsAdmin');
  
    console.log("SDF:ISHD:OHAS:FHS:IOEDHG:WOEUHF:LSKJOI")
    console.log(this.localFilter)

    const tmp2 = this.persist.get(this.storedInputFilters);
    if (tmp2) {

      // Hard Due Date
      if (tmp2.ActionDueDate) {
        if (tmp2.ActionDueDate[0]) {
          tmp2.ActionDueDate[0] = new Date(tmp2.ActionDueDate[0]);
        }
        if (tmp2.ActionDueDate[1]) {
          tmp2.ActionDueDate[1] = new Date(tmp2.ActionDueDate[1]);
        }
      }
      if (tmp2.ActionCompletedDate) {
        if (tmp2.ActionCompletedDate[0]) {
          tmp2.ActionCompletedDate[0] = new Date(tmp2.ActionCompletedDate[0]);
        }
        if (tmp2.ActionCompletedDate[1]) {
          tmp2.ActionCompletedDate[1] = new Date(tmp2.ActionCompletedDate[1]);
        }
      }
      this.localFilter = tmp2;

      // if (this.localFilter.ShowCompleted) {
      //   this.includeComplete = this.localFilter.ShowCompleted;
      //   this.completeString = 'Hide'
      // } else {
      //   this.includeComplete = false;
      // }
    }
    const tmp = this.persist.get(this.storedDTFilters);
    if (tmp) {
      this.mydt.filters = tmp;
    }

    this.setStrings();

    this.getData();
  }

  getData() {
    this.notifier.loadingOn();
    const forUser = this.localFilter.ShowAllActors ? -1 : this.auth.currentUser.UserID;
    this.dashboardService.getActions(forUser, this.localFilter.ShowCompleted).subscribe({
      next: (data: docDashboardActionsItem[]) => {
        this.actions = data.sort((a, b) => a.Title > b.Title ? 1 : -1);

        this.actions.forEach(a => {
          a.ActionCompletedDate = a.ActionCompletedDate ? new Date(a.ActionCompletedDate) : null;
          a.ActionDueDate = a.ActionDueDate ? new Date(a.ActionDueDate) : null;
        });

        this.getRoles(data);
        this.getDocumentTitles(data);
        this.getVersionNumbers(data);
        this.getTeamMembers(data);
        console.log(this.actions);

        this.notifier.loadingOff();
      }, error: (err) => {
        console.log(err);
        this.alert.error('Error Gettings Actions');
      }
    })
  }

  getTeamMembers(actions: docDashboardActionsItem[]) {
    this.teamMembers = [... new Set(actions.map(member => member.PersonName))].sort((a, b) => a > b ? 1 : -1);
  }

  getRoles(actions: docDashboardActionsItem[]) {
    this.roles = [... new Set(actions.map(action => action.RoleName))].sort((a, b) => a > b ? 1 : -1);
  }

  getDocumentTitles(actions: docDashboardActionsItem[]) {
    this.documents = [... new Set(actions.map(action => action.Title))].sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1);
  }

  getVersionNumbers(actions: docDashboardActionsItem[]) {
    this.versions = [... new Set(actions.map(action => action.VersionNumber))].sort((a, b) => a > b ? 1 : -1);
  }

  viewAction(action: docDashboardActionsItem) {
    this.router.navigate(['/prodocs/version', action.VersionID]);
  }

  toggleTeam() {
    this.localFilter.ShowAllActors = !this.localFilter.ShowAllActors;
    this.setStrings();
    this.getData();
    }

  includeCompleted() {
    this.localFilter.ShowCompleted = !this.localFilter.ShowCompleted;
    this.persist.set(this.storedInputFilters, this.localFilter);
    this.setStrings();
    this.getData();
  }

  setStrings() {
    this.buttonString = this.localFilter.ShowAllActors ? "View MY Actions" : "View ALL Actions";
    this.headerString = this.localFilter.ShowAllActors ? "ALL Actions" : "MY Actions";
    this.completeString = this.localFilter.ShowCompleted ? 'Hide Completed' : 'Include Completed';
  }

  // ***************************
  // TABLE SORTING AND FILTERING
  // ***************************
  doFilter(event) {
    this.persist.set(this.storedDTFilters, event.filters);
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  onSort(event) {
    this.localFilter.MultiSortMeta = event.multisortmeta;
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  onPage(event) {
    this.localFilter.rowsPerPage = event.rows;
    this.localFilter.currentRow = event.first;
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  clearLocalFilter(dt: Table) {

    this.localFilter.ShowCompleted = false;
    this.localFilter.ShowAllActors = false;
    this.localFilter.PersonName = null;
    this.localFilter.Title = null;
    this.localFilter.VersionNumber = null;
    this.localFilter.RoleName = null;
    this.localFilter.ActionCompletedDate = null;
    this.localFilter.ActionDueDate = null;

    this.localFilter.MultiSortMeta = this.multiSortMetaDefault;
    this.localFilter.rowsPerPage = +this.auth.currentUser.opts.PaginatorRows;
    this.localFilter.currentRow = 0;

    dt.clear();
    dt.filters = {};

    this.persist.set(this.storedDTFilters, null);
    this.persist.set(this.storedInputFilters, this.localFilter);

    this.getData();
  }
}
