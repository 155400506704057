<div style="width: 100%;">
	<p-panel header="Document Version Status">
		<p-table #dt [value]="versionsData" sortMode="multiple" [showJumpToPageDropdown]="true" [multiSortMeta]="localFilter.MultiSortMeta" [paginator]="true" [showPageLinks]="true" [multiSortMeta]="multiSortMetaDefault"
			(onFilter)="doFilter($event)" (onSort)="onSort($event)" (onPage)="onPage($event)">
			<!-- Table Header Template -->
			<ng-template pTemplate="header">
				<!-- Header Row -->
				<tr>
					<th style="width:3em;"></th>
					<th pSortableColumn="DocumentTitle" [ngStyle]="{ 'width': '17%', 'text-align': 'left' }">
						Document
						<span>
							<p-sortIcon field="DocumentTitle"></p-sortIcon>
						</span>
					</th>
					<th pSortableColumn="VersionNumber" [ngStyle]="{ 'width': '5%', 'text-align': 'left' }">
						Version
						<span>
							<p-sortIcon field="VersionNumber"></p-sortIcon>
						</span>
					</th>
					<th pSortableColumn="LanguageCode" [ngStyle]="{ 'width': '7%', 'text-align': 'left' }">
						Language
						<span>
							<p-sortIcon field="LanguageCode"></p-sortIcon>
						</span>
					</th>
					<th pSortableColumn="CategoryName" [ngStyle]="{ 'width': '10%', 'text-align': 'left' }">
						Category Name
						<span>
							<p-sortIcon field="CategoryName"></p-sortIcon>
						</span>
					</th>
					<th pSortableColumn="CreatedDate" [ngStyle]="{ 'width': '12%', 'text-align': 'left' }">
						Created Date
						<span>
							<p-sortIcon field="CreatedDate"></p-sortIcon>
						</span>
					</th>
					<th pSortableColumn="PublicationDate" [ngStyle]="{ 'width': '12%', 'text-align': 'left' }">
						Publication Date
						<span>
							<p-sortIcon field="PublicationDate"></p-sortIcon>
						</span>
					</th>
					<th pSortableColumn="ExpirationDate" [ngStyle]="{'width' : '12%', 'text-align':'center'}">
						Review Due Date
							<span>
								<p-sortIcon field="ExpirationDate"></p-sortIcon>
							</span>
					</th>
					<th pSortableColumn="StatusName" [ngStyle]="{'text-align': 'left', 'width':'12%' }">
						Status
						<span>
							<p-sortIcon field="StatusName"></p-sortIcon>
						</span>
					</th>
					<th pSortableColumn="OwnerName" [ngStyle]="{'width':'12%'}">
						Owners
						<span>
							<p-sortIcon field="OwnerName"></p-sortIcon>
						</span>
					</th>
				</tr>
				<!-- Filters Row -->
				<tr>
					<th class="col-icon-delete">
						<div class="col-icon-delete">
							<i class="fa fa-ban" (click)="clearLocalFilter(dt)"></i>
						</div>
					</th>
					<th>
						<input pInputText type="text" id="DocumentTitle" [(ngModel)]="localFilter.DocumentTitle"
							(input)="dt.filter(localFilter.DocumentTitle, 'DocumentTitle', 'contains')">
					</th>
					<th>
						<input pInputText type="text" id="VersionNumber" [(ngModel)]="localFilter.VersionNumber"
							(input)="dt.filter(localFilter.VersionNumber, 'VersionNumber', 'contains')">
					</th>
					<th>
						<p-multiSelect [options]="languages" defaultLabel="All" [(ngModel)]="localFilter.LanguageName"
                        	(onChange)="dt.filter($event.value, 'LanguageName', 'in')" appendTo="body"
							[style]="{'width':'100%'}">
                    	</p-multiSelect>
					</th>
					<th>
						<p-multiSelect [options]="categories" defaultLabel="All" [(ngModel)]="localFilter.CategoryName"
                        	(onChange)="dt.filter($event.value, 'CategoryName', 'in')" appendTo="body"
							[style]="{'width':'100%'}">
                    	</p-multiSelect>
					</th>
					<th>
						<p-calendar [(ngModel)]='localFilter.CreatedDate'
							(onSelect)="dt.filter(localFilter.CreatedDate, 'CreatedDate', 'dateRangeFilter')"
							(onClearClick)="dt.filter('', 'CreatedDate','contains')" [showButtonBar]="true"
							[readonlyInput]="true" [monthNavigator]="true" [yearNavigator]="true"
							[inputStyle]="{'width': '100%'}" appendTo="body" yearRange="2000:2030" selectionMode="range">
						</p-calendar>
					</th>
					<th>
						<p-calendar [(ngModel)]='localFilter.PublicationDate'
							(onSelect)="dt.filter(localFilter.PublicationDate, 'PublicationDate', 'dateRangeFilter')"
							(onClearClick)="dt.filter('', 'PublicationDate','contains')" [showButtonBar]="true"
							[readonlyInput]="true" [monthNavigator]="true" [yearNavigator]="true"
							[inputStyle]="{'width': '100%'}" appendTo="body" yearRange="2000:2030" selectionMode="range">
						</p-calendar>
					</th>
					<th>
						<p-calendar [(ngModel)]='localFilter.ExpirationDate'
							(onSelect)="dt.filter(localFilter.ExpirationDate, 'ExpirationDate', 'dateRangeFilter')"
							(onClearClick)="dt.filter('', 'ExpirationDate','contains')" [showButtonBar]="true"
							[readonlyInput]="true" [monthNavigator]="true" [yearNavigator]="true"
							[inputStyle]="{'width': '100%'}" appendTo="body" yearRange="2000:2030" selectionMode="range">
						</p-calendar>
					</th>
					<th>
						<p-multiSelect [options]="statuses" defaultLabel="All" [(ngModel)]="localFilter.StatusID"
                        	(onChange)="dt.filter($event.value, 'StatusID', 'in')" appendTo="body"
							[style]="{'width':'100%'}">
                    	</p-multiSelect>
					</th>
					<th>
						<p-multiSelect [options]="owners" defaultLabel="All" [(ngModel)]="localFilter.OwnerName"
                        	(onChange)="dt.filter($event.value, 'OwnerName', 'in')" appendTo="body"
							[style]="{'width':'100%'}">
                    	</p-multiSelect>
					</th>
				</tr>
			</ng-template>
			<!-- Table Body Template -->
			<ng-template pTemplate="body" let-rowData let-version>
				<!-- Data Rows -->
				<tr [pSelectableRow]="rowData">
					<td style="text-align: center;">
						<p-button icon="pi pi-book" (onClick)="viewVersion(version.VersionID)" pTooltip="View Version"></p-button>
					</td>
					<td>
						<p>{{ version.DocumentTitle }}</p>
					</td>
					<td>
						{{ version.VersionNumber }}
					</td>
					<td>
						{{ version.LanguageName }}
					</td>
					<td>
						{{ version.CategoryName }}
					</td>
					<td>
						{{ version.CreatedDate | date: 'mediumDate' }}
					</td>
					<td>
						{{ version.PublicationDate | date: 'mediumDate' }}
					</td>
					<td [style.color]="getTextColor(version.DaysTilExpiration)">
						{{ version.ExpirationDate | date: 'mediumDate' }}
					</td>
					<td>
						<p>{{ version.StatusName }}</p>
					</td>
					<td>
						{{ version.OwnerName }}
					</td>
				</tr>
			</ng-template>
			<!-- Table Footer Template -->
			<ng-template pTemplate="summary">
				<div style="display:flex; justify-content:space-evenly">
					<p-button label="Refresh" icon="pi pi-refresh" (click)="getVersionDate()"></p-button>
					<div>
						Viewing {{dt.totalRecords}} of {{versionsData.length}} Versions in Production
					</div>
					<button pButton label="{{archivedString}} Archived" (click)="toggleArchived()"></button>
				</div>
			</ng-template>
		</p-table>

	</p-panel>
</div>