<div class="captureContainer" *ngIf="employee?.ClockStatus === 'OUT'" style="text-align: center;">
	<div class="message" >
		<h1>You cannot begin break because you are clocked out</h1>
	</div>
</div>
<div class="captureContainer" *ngIf="employee?.ClockStatus === 'BRK' && punchRecorded === false" style="text-align: center;">
	<div class="message" >
		<h1>You cannot begin break because you are already on break</h1>
	</div>
</div>

<div class="captureContainer" *ngIf="employee?.ClockStatus === 'ADM'" style="text-align: center;">
	<div class="message">
		<h1>You have time that requires approval.</h1>
		<h2>Please view and approve your time before clocking in.</h2>
		<h1>Last Punch: {{employee.PreviousTimeSegment?.Start | date:'medium'}} </h1>
	</div>
</div>

<arc-select-break-type *ngIf="!deliveredPackage && employee?.ClockStatus === 'IN'" (setJob)="collectPackage($event)"></arc-select-break-type>

<div class="container" *ngIf="deliveredPackage && employee.ClockStatus === 'IN' && !punchRecorded">
	<div style="padding: 5px;"></div>
	<div class="backBtn">
		<button pButton style="width: 75%;" label="Back" (click)="goBack()"></button>
	</div>
	<div class="exitBtnContainer">
		<h1>Now Clocking Into: {{deliveredPackage.FunctionName}}</h1>
	
		<div class="exitBtn" (click)="finish()" style="background-color: rgb(26, 124, 209);">
			<p>CONFIRM</p>
		</div>
		<br>
		<div class="logIssueBtn" (click)="createIssue()" *ngIf="addedIssue === false" style="background-color: rgb(243, 168, 59);">
			<p>Log Issue</p>
		</div>
		<h1 *ngIf="addedIssue === true" style="color: green">Issue Logged</h1>
	</div>
	<p-dialog header="Log Issue" [(visible)]="creatingIssue" [style]="{width: '50vw', height: '500px'}" styleclass="issue-dialog" *ngIf="creatingIssue" appendTo="body">
		<arc-issue-popup [deliveredIssuePackage]=sentIssueArray [ApprovalPayrollFlag]=ApprovalPayrollFlag  (hasChanged)="hasChanged($event)"></arc-issue-popup>
	</p-dialog>
</div>
