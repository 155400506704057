import { Injectable, Inject } from '@angular/core';
import { chgCarrierAreaEx } from '../_models/TableCustom.model';
import { chgCarrierArea } from '../_models/CHG.model';

import { HttpClient } from '@angular/common/http';

@Injectable()
export class CarrierAreasService {


  api = 'chgCarrierArea/';
  apiEx = 'chgCarrierAreasEx/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<chgCarrierArea[]>(this.apiURL + this.api);

  }
  create(carrierArea: chgCarrierArea) {
    return this.http.post(this.apiURL + this.api, carrierArea);
  }

  update(carrierArea: chgCarrierArea) {
    return this.http
      .put(this.apiURL + this.api + carrierArea.CarrierAreaID, carrierArea);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getAllEx() {
    return this.http.get<chgCarrierAreaEx[]>(this.apiURL + this.apiEx);
  }
}
