import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import { cfgInternationalType } from '../_models/CFG.model';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class InternationalTypesService {


  api = 'cfgInternationalType/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<cfgInternationalType[]>(this.apiURL + this.api);

  }
  create(associationType: cfgInternationalType) {
    return this.http.post(this.apiURL + this.api, associationType);
  }

  update(associationType: cfgInternationalType) {
    return this.http
      .put(this.apiURL + this.api + associationType.InternationalTypeID, associationType);
  }

  delete (id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

}

