import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { timeActionService, timeEmployeeService } from 'app/_data/time.service';
import { cfgTrackTypes } from 'app/_models/enums.model';
import { timeAssignedFunction, timeClientFunction, timeClockAction, timeEmployeeSnapshot, timeIssueDb } from 'app/_models/TIME.model';
import { AlertService, AuthService } from 'app/_services';
import { NotifierService } from 'app/_sharedData/notifier.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { bundle } from '../select-function/select-function.component';

@Component({
  selector: 'arc-change-function',
  templateUrl: './change-function.component.html',
  styleUrls: ['./change-function.component.scss']
})
export class ChangeFunctionComponent implements OnInit, OnDestroy {

  @Input() currentFunction?: timeAssignedFunction = null;

  deliveredFunction: timeAssignedFunction = null;
  deliveredClient: timeClientFunction = null;
  deliveredClientReferenceNote: string = null;
  isBilling: boolean = false;
  counter: number;
  employee: timeEmployeeSnapshot;
  finished: boolean = false;
  punchRecorded: boolean = false;
  justClocked: boolean = false;
  now: Date;
  creatingIssue: boolean = false;
  addedIssue: boolean = false;
  sentIssueArray: timeIssueDb[];
  timeTrack: boolean = false;

  isBusy: boolean = false;

  private _subscription: Subscription;

  @Output() out = new EventEmitter<timeEmployeeSnapshot>();
  @Output() goToTimeTrack = new EventEmitter<timeEmployeeSnapshot>();

  userID: number;

  constructor(
    private authService: AuthService,
    private employeeService: timeEmployeeService,
    private actionService: timeActionService,
    private alert: AlertService,
    private notifier: NotifierService
  ) { }

  ngOnInit(): void {
    this.userID = this.authService.currentUser.UserID
    this._subscription = this.employeeService.getSnapshot(this.userID).subscribe(
      (value) => {
        if (value) { this.employee = value; } else { this.employee = null; }
      }, err => { this.alert.error(err); });

  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
    this.finished = true;
  }

  collectPackage(pack: bundle) {
    this.deliveredFunction = pack.func;
    this.deliveredClient = pack.clnt;
    this.deliveredClientReferenceNote = pack.clientJobReferenceNote;
    this.isBilling = pack.billClient;
    console.log('****** Bundle Package ******', pack);
  }

  beginTimeTrack(pack: bundle) {
    this.timeTrack = true;
    this.deliveredFunction = pack.func;
    this.deliveredClient = pack.clnt;
    this.isBilling = pack.billClient;

    // this.goToTimeTrack.emit(pack);
  }

  confirmTimeTrack() {

    if (this.isBusy)
      return;
    this.isBusy = true;
    this.notifier.loadingOn();
    let action: timeClockAction = {
      EmployeeID: this.employee.Employee.EmployeeID,
      TimeStamp: new Date().toLocaleTimeString(),
      Action: 'FUNC',
      FunctionID: this.deliveredFunction.FunctionID,
      ClientID: this.deliveredClient.ClientID,
      IsBillable: this.isBilling,
      Approval: null,
      Note: null,
      IsAutoGenerated: false,
      StopType: 0,
      // Two is for orderID
      TrackTypeID: cfgTrackTypes.Order,
      TrackID: null,
      Issues: this.sentIssueArray
    }
    console.log('SENDING ACTION to TimeTrack', action);

    this.actionService.postAction(action)
      .pipe(finalize(() => {
        this.notifier.loadingOff();
        this.isBusy = false;
      }))
      .subscribe((data: timeEmployeeSnapshot) => {
        if (data.ActionSuccess === true) {
          this.goToTimeTrack.emit(data);
        } else { this.alert.error(data.ActionStatus); }
      }, err => { this.alert.error(err); });

  }

  hasChanged(result: boolean) {
    this.creatingIssue = false;
    if (result === true) { this.addedIssue = true; }
  }

  createIssue() {
    this.creatingIssue = true;
    this.sentIssueArray = [];
  }

  finish() {
    if (this.isBusy)
      return;
    this.isBusy = true;
    this.notifier.loadingOn();
    let client;
    if (this.deliveredClient === null) {
      client = null;
    } else {
      client = this.deliveredClient.ClientID;
    }
    // let tType: number;
    let tReference: string;
    if (this.deliveredClientReferenceNote) {
      // tType = cfgTrackTypes.ClientReference;
      tReference = this.deliveredClientReferenceNote;
    }
    let action: timeClockAction = {
      EmployeeID: this.employee.Employee.EmployeeID,
      TimeStamp: new Date().toLocaleTimeString(),
      Action: 'FUNC',
      FunctionID: this.deliveredFunction.FunctionID,
      ClientID: client,
      IsBillable: this.isBilling,
      Approval: null,
		  TrackTypeID: this.deliveredClient?.TrackTypeID,
      TrackReference: tReference,
      Issues: this.sentIssueArray,
      IsAutoGenerated: false,
      StopType: 0
    }
    console.log('SENDING ACTION', action);

    this.actionService.postAction(action)
      .pipe(finalize(() => {
        this.notifier.loadingOff();
        this.isBusy = false;
      }))
      .subscribe((data: timeEmployeeSnapshot) => {
        if (data.ActionSuccess === true) {
          this.punchRecorded = true;
          console.log('Returned Action', data);
          this.employeeService.updateEmployeeSnapshot(data);
          this.employeeService.getGoTimeInfo(this.employee.Employee.EmployeeID);
          this.out.emit(data);
        } else { this.alert.error(data.ActionStatus); }
      }, err => { this.alert.error(err); });
  }

  goBack() {
    this.deliveredFunction = null;
    this.deliveredClient = null;
    this.isBilling = false;
    this.timeTrack = false;
    this.sentIssueArray = [];
    this.addedIssue = false;
  }

  getDate(): Date {
    this.now = new Date();
    return this.now;
  }
}
