import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ordFreightCalculator, ordFreightCalculatorEx } from '../_models/ORD.model';

@Injectable({
  providedIn: 'root'
})
export class OrderCalculatorService {

  api = 'ordFreightCalculator/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }


  create(item: ordFreightCalculator) {
    return this.http.post(this.apiURL + this.api, item);
  }

  update(item: ordFreightCalculator) {
    return this.http.put(this.apiURL + this.api, item);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  get(id: number, dataVersion: string) {
    return this.http.get(this.apiURL + this.api + id + '/' + dataVersion);
  }

  copy(id: number) {
    return this.http.get(this.apiURL + this.api + id + '/copy');
  }

  getForOrder(id: number, dataVersion: string) {
    return this.http.get<ordFreightCalculatorEx[]>(this.apiURL + this.api + 'order/' + id + '/' + dataVersion);
  }

}
