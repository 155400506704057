import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { docHistoryService } from 'app/_data/pro-docs.service';
import { docHistoryDb } from 'app/_models/DOC.model';
import { AlertService, AuthService } from 'app/_services';
import { PersistenceService } from 'app/_services/persistence.service';
import { Table } from 'primeng/table';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'arc-document-version-history',
  templateUrl: './document-version-history.component.html',
  styleUrls: ['./document-version-history.component.scss']
})
export class DocumentVersionHistoryComponent implements OnInit {

  @ViewChild('dt', { static: true }) mydt: Table;

  private _versionID = new BehaviorSubject<number>(null);
  private _documentID = new BehaviorSubject<number>(null);
  @Input()
  set versionID(value: number) {
    this._versionID.next(value);
  }
  get versionID() {
    return this._versionID.getValue();
  }
  @Input()
  set documentID(value: number) {
    this._documentID.next(value);
  }
  get documentID() {
    return this._documentID.getValue();
  }

  // @Input() documentID?: number = null;
  // @Input() versionID?: number = null;
  @Output() count = new EventEmitter<number>();

  documentHistory: docHistoryDb[] = [];

  multiSortMetaDefault = [{ field: 'ActionDate', order: 1 }]; // , {field: 'PlaceHolder', order: 1}, {field: 'PlaceHolder', order: 1}];

  //#region FilterNames
  storedDTFilters = 'DocHistoryDTFilters';
  storedInputFilters = 'DocHistoryInputFilters';
  //#endregion

  localFilter = {
    VersionNumber: null,
    PersonName: null,
    Action: null,
    ActionDate: null,
    Note: null,
    MultiSortMeta: this.multiSortMetaDefault,
    rowsPerPage: +this.authService.currentUser.opts.PaginatorRows,
    currentRow: 0
  };

  constructor(
    private historyService: docHistoryService,
    private persist: PersistenceService,
    private authService: AuthService,
    private alert: AlertService,
  ) { }

  ngOnInit(): void {

    this.mydt.rows = +this.authService.currentUser.opts.PaginatorRows;
    this.mydt.rowsPerPageOptions = this.authService.currentUser.opts.PaginatorRowOptions;
    this.mydt.paginator = true;
    this.mydt.alwaysShowPaginator = false;

    const tmp2 = this.persist.get(this.storedInputFilters);
    if (tmp2) {
      this.localFilter = tmp2;
    }

    const tmp = this.persist.get(this.storedDTFilters);
    if (tmp) {
      this.mydt.filters = tmp;
    }

    this._documentID.subscribe({
      next: () => {
        if (this.documentID) {
          this.getDocumentHistory();
        }
      }
    });
    this._versionID.subscribe({
      next: () => {
        if (this.versionID) {
          this.getVersionHistory();
        }
      }
    });
  }

  getDocumentHistory() {
    this.historyService.getDocumentHistory(this.documentID).subscribe({
      next: (data: docHistoryDb[]) => {
        this.documentHistory = data.sort((a, b) => a.ActionDate > b.ActionDate ? -1 : 1);
        this.count.emit(this.documentHistory.length);
      }, error: (err) => {
        this.alert.error(err);
      }
    });
  }

  getVersionHistory() {
    this.historyService.getVersionHistory(this.versionID).subscribe({
      next: (data: docHistoryDb[]) => {
        this.documentHistory = data.sort((a, b) => a.ActionDate > b.ActionDate ? -1 : 1);
        // console.log('%c DATA IN HERE', 'color: coral; font-size: 30px;')
        console.log(data);
        this.count.emit(this.documentHistory.length);
      }, error: (err) => {
        console.log(err);
        this.alert.error(err);
      }
    });
  }

  onSort(event) {
    this.localFilter.MultiSortMeta = event.multisortmeta;
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  onPage(event) {
    this.localFilter.rowsPerPage = event.rows;
    this.localFilter.currentRow = event.first;
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  doFilter(event) {
    // and store the filters for later recall
    this.persist.set(this.storedDTFilters, event.filters);
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  clearLocalFilter(dt: Table) {
    this.localFilter.VersionNumber = null;
    this.localFilter.PersonName = null;
    this.localFilter.Action = null;
    this.localFilter.ActionDate = null;
    this.localFilter.Note = null;
    this.localFilter.MultiSortMeta = this.multiSortMetaDefault;
    this.localFilter.rowsPerPage = +this.authService.currentUser.opts.PaginatorRows;
    this.localFilter.currentRow = 0;

    dt.clear();
    dt.filters = {};

    this.persist.set(this.storedDTFilters, null);
    this.persist.set(this.storedInputFilters, this.localFilter);

    if (this.documentID) {
      this.getDocumentHistory();
    } else if (this.versionID) {
      this.getVersionHistory();
    }

  }

}
