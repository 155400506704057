import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { timeActionService, timeEmployeeService } from 'app/_data/time.service';
import { cfgTrackTypes } from 'app/_models/enums.model';
import { timeAssignedFunction, timeClientFunction, timeClockAction, timeEmployeeSnapshot, timeIssueDb } from 'app/_models/TIME.model';
import { AlertService, AuthService } from 'app/_services';
import { NotifierService } from 'app/_sharedData/notifier.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { bundle } from '../select-function/select-function.component';

@Component({
  selector: 'arc-clock-in',
  templateUrl: './clock-in.component.html',
  styleUrls: ['./clock-in.component.scss']
})
export class ClockInComponent implements OnInit, OnDestroy {

  deliveredFunction: timeAssignedFunction = null;
  deliveredClient: timeClientFunction = null;
  deliveredClientJobReferenceNote: string = null;
  isBilling: boolean = false;
  counter: number;
  employee: timeEmployeeSnapshot;
  finished: boolean = false;
  punchRecorded: boolean = false;
  now: Date;
  creatingIssue: boolean = false;
  addedIssue: boolean = false;
  sentIssueArray: timeIssueDb[];
  private _subscription: Subscription;
  userID: number;
  confirm: boolean = false;

  isBusy: boolean = false;
  timeTrack: boolean = false;

  viewingTime: boolean = false;

  @Output() out = new EventEmitter<timeEmployeeSnapshot>();
  @Output() goToTimeTrack = new EventEmitter<timeEmployeeSnapshot>();

  constructor(
    private authService: AuthService,
    private employeeService: timeEmployeeService,
    private actionService: timeActionService,
    private alert: AlertService,
    private notifier: NotifierService
  ) { }

  ngOnInit(): void {
    this.userID = this.authService.currentUser.UserID;

    this._subscription = this.employeeService.signedInEmployee$.subscribe(
      (value) => {
        if (value) { this.employee = value; } else { this.employee = null; }
      },
      (error) => this.alert.error(error)
    )
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
    this.finished = true;
  }

  // COLLECTING SELECTED JOB
  collectPackage(pack: bundle) {
    this.deliveredFunction = pack.func;
    this.deliveredClient = pack.clnt;
    this.deliveredClientJobReferenceNote = pack.clientJobReferenceNote;
    this.isBilling = pack.billClient;
    console.log(pack);
  }

  hasChanged(result: boolean) {
    this.creatingIssue = false;
    if (result === true) { this.addedIssue = true; }
    console.log('Sent Issues Array RETURN', this.sentIssueArray);
  }

  createIssue() {
    this.creatingIssue = true;
    this.sentIssueArray = [];
  }

  confirmBtn() {
    this.confirm = true;
  }

  finish() {

    if (this.isBusy)
      return;
    this.isBusy = true;

    this.notifier.loadingOn();
    let client;
    if (this.deliveredClient === null) {
      client = null;
    } else {
      client = this.deliveredClient.ClientID;
    }
    // let tType: number;
    let tReference: string;
    if (this.deliveredClientJobReferenceNote) {
      // tType = cfgTrackTypes.ClientReference;
      tReference = this.deliveredClientJobReferenceNote;
    }
    let action: timeClockAction = {
      EmployeeID: this.employee.Employee.EmployeeID,
      TimeStamp: new Date().toLocaleTimeString(),
      Action: 'IN',
      FunctionID: this.deliveredFunction.FunctionID,
      ClientID: client,
      IsBillable: this.isBilling,
      Approval: null,
      TrackTypeID: this.deliveredClient?.TrackTypeID,
      TrackReference: tReference,
      Issues: this.sentIssueArray,
      IsAutoGenerated: false,
      StopType: 0
    }
    this.actionService.postAction(action)
      .pipe(finalize(() => {
        this.notifier.loadingOff();
        this.isBusy = false;
      }))
      .subscribe(
        (data: timeEmployeeSnapshot) => {
          if (data.ActionSuccess === true) {
            // this.authService.employeeSignOut();
            this.punchRecorded = true;
            this.employeeService.updateEmployeeSnapshot(data);
            this.out.emit(data);
          } else { this.alert.error(data.ActionStatus); }
        },
        err => { this.alert.error(err); console.log(err); });
  }

  goBack() {
    this.deliveredFunction = null;
    this.deliveredClient = null;
    this.isBilling = false;
    this.timeTrack = false;
    this.sentIssueArray = [];
    this.addedIssue = false;
  }

  toggleViewTime(data: timeEmployeeSnapshot) {
    this.viewingTime = false;
  }

  beginTimeTrack(pack: bundle) {
    this.timeTrack = true;
    this.deliveredFunction = pack.func;
    this.deliveredClient = pack.clnt;
    this.isBilling = pack.billClient;
  }

  confirmTimeTrack() {

    if (this.isBusy)
      return;
    this.isBusy = true;
    this.notifier.loadingOn();
    let action: timeClockAction = {
      EmployeeID: this.employee.Employee.EmployeeID,
      TimeStamp: new Date().toLocaleTimeString(),
      Action: 'IN',
      FunctionID: this.deliveredFunction.FunctionID,
      ClientID: this.deliveredClient.ClientID,
      IsBillable: this.isBilling,
      Approval: null,
      Note: null,
      IsAutoGenerated: false,
      StopType: 0,
      // Two is for orderID
      TrackTypeID: cfgTrackTypes.Order,
      TrackID: null,
      Issues: this.sentIssueArray
    }
    console.log('SENDING ACTION to TimeTrack', action);

    this.actionService.postAction(action)
      .pipe(finalize(() => {
        this.notifier.loadingOff();
        this.isBusy = false;
      }))
      .subscribe((data: timeEmployeeSnapshot) => {
        if (data.ActionSuccess === true) {
          this.goToTimeTrack.emit(data);
        } else { this.alert.error(data.ActionStatus); }
      }, err => { this.alert.error(err); });

  }

}
