<div *ngIf="isOwnerOrAdmin()" style="padding-left: 15px; padding-top: 15px;">
    <button pButton label="Go Up to Document" icon="pi pi-arrow-up" (click)="goToDocument()"></button>
</div>
<div style="display: flex; justify-content: center; margin-top: 10px;">
    <div
        style="background-color: #fbfbfb; text-align: center; margin-bottom: 10px; padding: 15px; width: 70%; border-radius: 10px;">
        <h1 pTooltip="DocumentID: {{document?.DocumentID}}">{{ document?.ReferenceNumber }}: {{ document?.Title }}</h1>
        <h2 style="font-weight: 400" pTooltip="VersionID: {{version?.VersionID}}">{{ version?.Description}}</h2>
        <p-selectButton dataKey="VersionID" [options]="versions" [(ngModel)]="version"
            (onOptionClick)="this.changeVersion()" [ngStyle]="{'padding':'10px'}">
            <ng-template let-item>
                <span> V:{{item.VersionNumber}} - {{item.LanguageCode}} </span>
            </ng-template>
        </p-selectButton>
        <!-- <div style="width:50%; display: block; margin: 0 auto;">
            <p-dropdown [options]="versions" [(ngModel)]="version" (onChange)="this.changeVersion()">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="version">
                        <div>V:{{version.VersionNumber}}  {{version.LanguageCode | labelForValue: languages}}</div>
                    </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                    <div>
                        <div>V:{{item.VersionNumber}}  {{item.LanguageCode | labelForValue: languages}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div> -->
        <!-- <h3 style="padding: 0; font-weight: 300;">Document Reference Number: {{ document?.ReferenceNumber }} </h3> -->
        <div class="actions">
            <button pButton icon="pi pi-list" label="New Language" pTooltip="{{newLanguageErrorTooltip}}"
                [ngClass]="canAddNewLanguage() ? 'p-button-primary' : 'p-button-secondary'"
                (click)="langMenu.toggle($event)"></button>
            <button pButton icon="fa fa-wrench" label="Revise" pTooltip="{{reviseErrorTooltip}}"
                [ngClass]="canRevise() ? 'p-button-primary' : 'p-button-secondary'"
                (click)="newVersionMenu.toggle($event)"></button>
            <button pButton icon="pi pi-thumbs-up" label="Publish" pTooltip="{{publishErrorTooltip}}"
                [ngClass]="canPublish() ? 'p-button-primary' : 'p-button-secondary'"
                (click)="confirmPublish(version)"></button>
        </div>
    </div>
</div>
<div class="p-grid">
    <div class="p-col-5 p-offset-1" *ngIf="version">
        <p-panel>
            <p-header>
                Version Details
            </p-header>
            <div class="p-grid p-field">
                <div class="p-col-3" style="text-align:right; display:flex; align-items: center;">
                    <span>Number:</span>
                </div>
                <div class="p-col-3" style="display: flex; align-items: center;">
                    <span>
                        {{ version.VersionNumber }}
                    </span>
                </div>
                <!-- </div>
            <div class="p-grid p-field"> -->
                <div class="p-col-3" style="text-align:right; display:flex; align-items: center;">
                    <span>Owner:</span>
                </div>
                <div class="p-col-3" style="display: flex; align-items: center;">
                    <span *ngIf="!mayEdit">
                        {{ version.OwnerName }}
                    </span>
                    <span *ngIf="mayEdit && isOwnerOrAdmin()" style="width: 100%;">
                        <p-dropdown [options]="actors" [ngStyle]="{'width':'100%'}" [(ngModel)]="ownerID"
                            placeholder="Select Owner" [showClear]="false" optionLabel="label" optionValue="value"
                            autoDisplayFirst="false">
                        </p-dropdown>
                    </span>
                    <ng-template #NoOwnerEdit>
                        <span>
                            {{ version.OwnerName }}
                        </span>
                    </ng-template>
                </div>
            </div>
            <div class="p-grid p-field">
                <div class="p-col-3" style="text-align:right; display:flex; align-items: center;">
                    <span>Status:</span>
                </div>
                <div class="p-col-3" style="display: flex; align-items: center;">
                    <span>{{ version.StatusName }}</span>
                </div>
                <div class="p-col-3" style="text-align:right; display:flex; align-items: center;">
                    <span>Language:</span>
                </div>
                <div class="p-col-3" style="display: flex; align-items: center;">
                    <span *ngIf="mayEdit && !isPublished()" style="width: 100%;">
                        <p-dropdown [options]="languages" [(ngModel)]="version.LanguageCode" optionLabel="label"
                            optionValue="value">
                        </p-dropdown>
                    </span>
                    <span *ngIf="!mayEdit || isPublished()">
                        {{ version?.LanguageName }}
                    </span>
                </div>
            </div>
            <div class="p-grid p-field">
                <div class="p-col-3" style="text-align:right; display:flex; align-items: center;">
                    <span>Last Published:</span>
                </div>
                <div class="p-col-3" style="display: flex; align-items: center;">
                    <span>{{ version.PublicationDate | date: 'mediumDate' }}</span>
                </div>
                <!-- </div>
            <div class="p-grid p-field"> -->
                <div class="p-col-3" style="text-align:right; display:flex; align-items: center;">
                    <span>Review Due:</span>
                </div>
                <div class="p-col-3" style="display: flex; align-items: center;">
                    <span *ngIf="!mayEdit">  
                        {{ version.ExpirationDate | date: 'mediumDate' }}
                    </span>
                    <span *ngIf="mayEdit" style="width: 100%;">
                        <p-calendar [(ngModel)]="version.ExpirationDate" [readonlyInput]="false" [monthNavigator]="true"
                            [yearNavigator]="true" [inputStyle]="{'width': '100%'}" appendTo="body"
                            yearRange="2000:2030" showButtonBar="true"></p-calendar>
                    </span>
                </div>
            </div>
            <div class="p-grid p-field">
                <div class="p-col-3" style="text-align:right; display:flex; align-items: center;">
                    <span>Description:</span>
                </div>
                <div class="p-col-9">
                    <textarea rows="2" cols="40" pInputTextarea [(ngModel)]="version.Description"
                        [readOnly]="!mayEdit"></textarea>
                </div>
            </div>
            <div class="p-grid p-field">
                <div class="p-col-3" style="text-align:right; display:flex; align-items: center;">
                    <span>Change Notes:</span>
                </div>
                <div class="p-col-9">
                    <textarea rows="4" cols="40" pInputTextarea [(ngModel)]="version.ChangeNotes" [readOnly]="!mayEdit"
                        required></textarea>
                    <!-- <arc-show-errors ></arc-show-errors> -->
                </div>
            </div>
            <ng-template pTemplate="footer">
                <div style="display:flex; justify-content: space-between;">
                    <button pButton style="width:15%;" icon="pi pi-times" label="Cancel" *ngIf="mayEdit"
                        (click)="cancelDetailEdit()"></button>
                    <button pButton style="width:15%;" icon="pi pi-save" label="Save" *ngIf="mayEdit"
                        (click)="doSave()"></button>
                </div>
                <div style="width: 100%; display: flex; justify-content: right;">
                    <button pButton style="width:15%;" icon="pi pi-pencil" label="Edit"
                        *ngIf="!mayEdit && isOwnerOrAdmin() && version.StatusID !== statusTypes.Archived"
                        (click)="editVersionDetails()"></button>
                </div>
                <div style="text-align:center" *ngIf="version.StatusID === statusTypes.Archived">
                    <p>Version Archived</p>
                </div>
            </ng-template>

        </p-panel>
    </div>
    <div class="p-col-5">
        <p-panel class="container flex align-items-stretch flex-wrap">
            <p-header>
                Files
            </p-header>
            <div
                style="width: 100%; text-align:center; border-radius: 5px; border: 1px solid red; margin-bottom: 10px; background-color: rgb(255, 79, 79);">
                <span style="color: white; font-weight: 200;"> -- Never upload sensitive information into ProDocs --
                </span>
            </div>
            <div class="pdf" style="display: flex; padding: 5px;">
                Published file (PDF):
                <a (click)="showFile('PDF')" pTooltip="View" tooltipPosition="top">{{ version?.PublishedFile }}</a>
                <div style="display: flex; width: 10%; justify-content: space-between;">
                    <p-button [disabled]="!allowDownload('PDF') || !isOwnerOrAdmin()" icon="pi pi-download"
                        (onClick)="downloadFile('PDF')" pTooltip="Download" tooltipPosition="left"></p-button>
                    <p-fileUpload *ngIf="isOwnerOrAdmin() && version?.StatusID < statusTypes.Published" #PDFUpload
                        mode="basic" name="PDFFile" [customUpload]="true" (uploadHandler)="uploadFile('PDF', $event)"
                        accept=".pdf" [auto]="true" [pTooltip]="getPDFTooltip()" tooltipPosition="right"></p-fileUpload>
                </div>
            </div>
            <br>
            <div class="sourceFiles" style="display: flex; padding: 5px;">
                Source file (anything):
                <span> {{ version?.SourceFile }}</span>
                <div style="display: flex; justify-content: space-between; width: 10%;">
                    <p-button [disabled]="!allowDownload('SOURCE') || !isOwnerOrAdmin()" icon="pi pi-download"
                        (onClick)="downloadFile('SOURCE')" pTooltip="Download" tooltipPosition="left">
                    </p-button>
                    <p-fileUpload *ngIf="isOwnerOrAdmin() && version?.StatusID < statusTypes.Published" #SourceUpload
                        mode="basic" name="SourceFile" [customUpload]="true" (uploadHandler)="uploadFile('SRC', $event)"
                        [auto]="true" [pTooltip]="getSourceTooltip()" tooltipPosition="right">
                    </p-fileUpload>
                </div>
            </div>
        </p-panel>

        <br />
        <p-panel>
            <p-header>
                <div style="display:flex; justify-content:space-between; align-items: center;">
                    <div style="display:flex; align-items:center;">
                        <p style="margin-right:200px;">Document Links</p>
                        <p-autoComplete [delay]="200" [ngStyle]="{'width':'200%'}" placeholder="Search Documents"
                            *ngIf="version?.StatusID !== statusTypes.Archived" [(ngModel)]="selectedDocumentLink"
                            (onSelect)="selectDocument($event)" (onDropdownClick)="filterDocument($event)"
                            [suggestions]="filteredDocuments" (completeMethod)="filterDocument($event)"
                            field="DisplayName" [dropdown]="true">
                            <ng-template let-item pTemplate="item">
                                <div>
                                    <div> {{item.CategoryName}}: {{item.DisplayName}}</div>
                                </div>
                            </ng-template>
                        </p-autoComplete>
                    </div>
                </div>
            </p-header>
            <div>
                <p-table [value]="links" *ngIf="links.length > 0">
                    <ng-template pTemplate="body" let-item>
                        <tr>
                            <td>
                                {{ item.DocumentTitle }}
                            </td>
                            <td style="text-align:center; width:5%;">
                                <p-button icon="fa fa-trash-o" (onClick)="deleteLink(item)"></p-button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </p-panel>

    </div>


    <div class="p-col-5 p-offset-1">
        <p-panel>
            <p-header>
                Actors
            </p-header>
            <arc-actors [versionID]="versionID" [versionStatus]="version?.StatusID" [ownerID]="version?.OwnerID"
                (reload)="loadVersion()" (sendActors)="findAuthor($event)"></arc-actors>
        </p-panel>
        <p-panel [ngStyle]="{'padding':'10px'}">
            <p-header>
                Quiz Actions
            </p-header>
            <div class="actions" style="margin-bottom: 15px;" *ngIf="version?.QuizID !== 0">
                <div style="width:33%; text-align: center"><span
                        style="font-weight:bold;">{{version?.QuizQuestionCount}} Questions</span></div>
                <div style="width:33%; text-align: center"><span style="font-weight:bold;">Last Edited:</span>
                    {{version?.QuizLastEditDate | date: 'medium'}} </div>
                <div style="width:33%; text-align: center"><span style="font-weight:bold;">By:
                    </span>{{version?.QuizLastEditByName}} </div>
            </div>
            <div class="actions">
                <button pButton icon="pi pi-pencil" label="Edit Quiz" *ngIf="version?.QuizID !== 0"
                    pTooltip="{{editQuizErrorTooltip}}"
                    [ngClass]="canEditQuiz() ? 'p-button-primary' : 'p-button-secondary'" (click)=" goQuiz()"></button>
                <button pButton icon="pi pi-desktop" label="View Quiz" *ngIf="version?.QuizID !== 0"
                    (click)="viewQuiz()"></button>
                <button pButton label="Deactivate Quiz" *ngIf="version?.QuizID !== 0 && version?.IsActive"
                    pTooltip="{{editQuizErrorTooltip}}"
                    [ngClass]="canEditQuiz() ? 'p-button-primary' : 'p-button-secondary'"
                    (click)="toggleQuiz()"></button>
                <button pButton label="Activate Quiz" *ngIf="version?.QuizID !== 0 && !version?.IsActive"
                    pTooltip="{{editQuizErrorTooltip}}"
                    [ngClass]="canEditQuiz() ? 'p-button-primary' : 'p-button-secondary'"
                    (click)="toggleQuiz()"></button>
                <button pButton label="Remove Quiz"
                    *ngIf="version?.QuizID !== 0 && version?.QuizQuestionCount === 0 && version?.StatusID < statusTypes.Published"
                    pTooltip="{{editQuizErrorTooltip}}"
                    [ngClass]="canEditQuiz() ? 'p-button-primary' : 'p-button-secondary'"
                    (click)="deleteQuiz()"></button>

                <button pButton label="Create Quiz" *ngIf="version?.QuizID === 0" (click)="createQuiz()"></button>


            </div>
        </p-panel>
    </div>

    <div class="p-col-5">
        <p-panel>
            <p-header>
                Communication
            </p-header>
            <p-tabView>
                <p-tabPanel header="Notes ({{ noteCount }})">
                    <div class="actions" style="width: 100%;">
                        <arc-docNotes *ngIf="version" [ngStyle]="{'width':'100%'}" [documentID]="version.DocumentID"
                            [versionID]="versionID" (loaded)="setNoteCount($event)"></arc-docNotes>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="History ({{historyCount}})">
                    <arc-document-version-history (count)="setHistoryCount($event)" [versionID]="versionID">
                    </arc-document-version-history>
                </p-tabPanel>
            </p-tabView>
        </p-panel>
    </div>

</div>

<p-dialog [header]="confirmMessage" [(visible)]="showConfirmLanguageDialog" [modal]="true"
    [style]="{width: '600px', minWidth: '400px'}" [minY]="400" [maximizable]="false">
    <arc-confirm-new-language-dialog *ngIf="showConfirmLanguageDialog" [message]="" [inputLabel]="confirmLabel"
        (accept)="goNewLanguage(versionID, $event)" (reject)="showConfirmLanguageDialog = false">
    </arc-confirm-new-language-dialog>
</p-dialog>

<p-menu #langMenu [model]="langMenuItems" [popup]="true"></p-menu>

<p-menu #newVersionMenu [model]="versionMenuItems" [popup]="true"></p-menu>

