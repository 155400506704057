import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../_guards/auth.guard';
import { ActorEditComponent } from './actors/actor-edit/actor-edit.component';
import { AssignmentsComponent } from './assignments/assignments.component';
import { ProdocsActorsHomeDashboardComponent } from './dashboards/Actors/prodocs-actors-home-dashboard/prodocs-actors-home-dashboard.component';
import { ProdocsAdminHomeDashboardComponent } from './dashboards/Admin/prodocs-admin-home-dashboard/prodocs-admin-home-dashboard.component';
import { ProdocsTeamHomeDashboardComponent } from './dashboards/Team/prodocs-team-home-dashboard/prodocs-team-home-dashboard.component';
import { DocumentLibraryComponent } from './document-library/document-library.component';
import { DocumentEditComponent } from './documents/document-edit/document-edit.component';
import { DocumentsComponent } from './documents/documents.component';
import { QuizComponent } from './quiz/quiz.component';
import { TriggerEditComponent } from './triggers/trigger-edit/trigger-edit.component';
import { TriggersComponent } from './triggers/triggers.component';
import { VersionComponent } from './version/version.component';

const ProDocsRoutes: Routes = [
  { path: 'assignments', component: AssignmentsComponent, data: { needRight: 'rtmProDocsAssignments' }, canActivate: [AuthGuard] },
  { path: 'admin-dashboard', component: ProdocsAdminHomeDashboardComponent, data: { needRight: 'actProDocsAdmin', canActivate: [AuthGuard] } },
  { path: 'team-dashboard', component: ProdocsTeamHomeDashboardComponent, data: { needRight: 'rtmProDocsDashboardTeam', canActivate: [AuthGuard] } },
  { path: 'actor-dashboard', component: ProdocsActorsHomeDashboardComponent, data: { needRight: 'rtmProDocsDashboardActor', canActivate: [AuthGuard] } },
  { path: 'documents', component: DocumentsComponent, data: { needRight: 'rtmProDocsDocuments' }, canActivate: [AuthGuard] },
  { path: 'document/:documentID', component: DocumentEditComponent, data: { needRight: 'rtmProDocsDocuments' }, canActivate: [AuthGuard] },
  { path: 'version/:versionID', component: VersionComponent, data: { needRight: 'actProDocsVersionEdit' }, canActivate: [AuthGuard] },
  { path: 'actor/:actorID', component: ActorEditComponent, data: { needRight: 'rtmProDocsDocuments' }, canActivate: [AuthGuard] },
  { path: 'quiz/:versionID/:mode', component: QuizComponent, data: { needRight: 'rtmProDocsDocuments' }, canActivate: [AuthGuard] },
  { path: 'triggers/:documentID', component: TriggersComponent, data: { needRight: 'rtmProDocsTriggers' }, canActivate: [AuthGuard] },
  { path: 'trigger-edit/:documentID/:triggerID', component: TriggerEditComponent, data: { needRight: 'actProDocsTriggerEdit' }, canActivate: [AuthGuard] },
  { path: 'triggers', component: TriggersComponent, data: { needRight: 'rtmProDocsTriggers' }, canActivate: [AuthGuard] },
  { path: 'library', component: DocumentLibraryComponent, data: { needRight: 'rtmProDocsLibrary' }, canActivate: [AuthGuard] }

];

@NgModule({
  imports: [
    RouterModule.forChild(ProDocsRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class ProDocsRoutingModule { }
