import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import { ordWarehouseAlertEx } from '../_models/ORD.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class WarehouseAlertsService {


  api = 'ordWarehouseAlert/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<ordWarehouseAlertEx[]>(this.apiURL + this.api);

  }

  getByID(id: number) {
    return this.http.get<ordWarehouseAlertEx>(this.apiURL + this.api + id);
  }

  create(warehouse: ordWarehouseAlertEx) {
    return this.http.post(this.apiURL + this.api, warehouse);
  }

  update(warehouse: ordWarehouseAlertEx) {
    return this.http
      .put(this.apiURL + this.api + warehouse.WarehouseID, warehouse);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getForOrder(id: number) {
      return this.http.get<ordWarehouseAlertEx[]>(this.apiURL + this.api + 'order/' + id);
  }

  generateForOrder(id: number) {
      return this.http.post<ordWarehouseAlertEx[]>(this.apiURL + this.api + 'order/' + id, null);
  }
}
