<!-- ----------------- -->
<!-- NAVBAR -->
<div class="navbar">
	<div class="btn" *ngIf="authService.userHasRight('actGoTime_ClockIn')">
		<button pButton label="Clock In" (click)="changeTitle('Clock In')"></button>
	</div>
	<div class="btn" *ngIf="authService.userHasRight('actGoTime_Break')">
		<button pButton label="Begin Break" (click)="changeTitle('Begin Break')"></button>
	</div>
	<div class="btn" *ngIf="authService.userHasRight('actGoTime_Break')">
		<button pButton label="End Break" (click)="changeTitle('End Break')"></button>
	</div>
	<div class="btn" *ngIf="authService.userHasRight('actGoTime_ChangeFunction')">
		<button pButton label="Change Function" (click)="changeTitle('Change Function')"></button>
	</div>
	<div class="btn" *ngIf="authService.userHasRight('actGoTime_ChangeFunction')">
		<button pButton label="Change Client" (click)="changeTitle('Change Client')"></button>
	</div>
	<div class="btn" *ngIf="authService.userHasRight('actGoTime_ClockOut')">
		<button pButton label="Clock Out" (click)="changeTitle('Clock Out')"></button>
	</div>
</div>
<div class="header">
	<h1>{{ pageTitle }}</h1>
</div>
<!-- NAVBAR END -->
<!-- ----------------- -->

<!-- ----------------- -->
<!-- APPLICATION BODY -->
<div class="contentContainer">
	<div *ngIf="pageTitle === 'Clock In'">
		<arc-clock-in (out)="reloadStatusBar($event)" (goToTimeTrack)="goToTimeTrack($event)"></arc-clock-in>
	</div>
	<div *ngIf="pageTitle === 'Begin Break'">
		<arc-begin-break (out)="reloadStatusBar($event)"></arc-begin-break>
	</div>
	<div *ngIf="pageTitle === 'End Break'">
		<arc-end-break (out)="reloadStatusBar($event)" (goToTimeTrack)="goToTimeTrack($event)"></arc-end-break>
	</div>
	<div *ngIf="pageTitle === 'Change Function'">
		<arc-change-function (out)="reloadStatusBar($event)" (goToTimeTrack)="goToTimeTrack($event)" ></arc-change-function>
	</div>
	<div *ngIf="pageTitle === 'Change Client'">
		<arc-change-function [currentFunction]=currentFunction (out)="reloadStatusBar($event)" (goToTimeTrack)="goToTimeTrack($event)"></arc-change-function>
	</div>
	<div *ngIf="pageTitle === 'Clock Out'">
		<arc-clock-out [pageTitle]=pageTitle (out)="reloadStatusBar($event)"></arc-clock-out>
	</div>
	<div *ngIf="pageTitle === 'Dashboard'">
		<arc-dashboard [pageTitle]=pageTitle></arc-dashboard>
	</div>
</div>
<!-- APPLICATION BODY END -->
<!-- ----------------- -->

<div class="statusBar" style="display: flex; flex-direction: column;">
	<button pButton (click)="changeTitle('Dashboard')" style="width: 200px; bottom: 5px; position: fixed;" label="Dashboard"></button>
</div>