import { Directive } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors, Validator, UntypedFormControl } from '@angular/forms';

@Directive({
  selector: '[arcValidDateRange]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: DateRangeValidator, multi: true }
  ]
})
export class DateRangeValidator implements Validator {

  constructor() { }

  validate(c: UntypedFormControl): ValidationErrors | null {

    console.log('validate ', c);

    if (c.value && (!c.value[0] || !c.value[1])) {
      return { dateRange: 'Must be a valid date range. (e.g., 1/1/2020 - 4/15/2020)' };
    }

    return null;
  }
}
