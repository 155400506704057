import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { docDashboardService } from 'app/_data/pro-docs.service';
import { docAssignmentEx } from 'app/_models/DOC.model';
import { AssignmentCompletionDistributionTypes } from 'app/_models/enums.model';
import { AuthService } from 'app/_services';

@Component({
  selector: 'prodocs-team-home-dashboard',
  templateUrl: './prodocs-team-home-dashboard.component.html',
  styleUrls: ['./prodocs-team-home-dashboard.component.scss']
})
export class ProdocsTeamHomeDashboardComponent implements OnInit {

  // ** TOGGLES ** \\
  showStats: boolean = false;
  showDetail: boolean = false;

  // ** DATA ** \\ 
  rawData: docAssignmentEx[] = [];

  // ** MISC ** \\
  displayTypes = AssignmentCompletionDistributionTypes

  constructor(
    private dashboardService: docDashboardService,
    private auth: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void { this.getData() }

  hasRight(right: string): boolean {
    return this.auth.userHasRight(right);
  }

  handleTabChange(e) {
    console.log(e.index)
    if (e.index === 1) {
      this.showDetail = true;
      this.showStats = false;
    }
    if (e.index === 2) {
      this.showStats = true;
      this.showDetail = false
    } else {
      this.showDetail = false;
      this.showStats = false;
    }
  }

  getData() {
    this.dashboardService.getAssignmentsForTeam(this.auth.currentUser.UserID, false).subscribe({
      next: (data: docAssignmentEx[]) => {
        console.log('%cTEAM ASSIGNMENT LOG', 'color: orange; font-size: 15px');
        this.rawData = data;      // Store this later for flipping without having to go back to server.

        this.rawData.forEach(a => {
          a.AssignedDate = a.AssignedDate ? new Date(a.AssignedDate) : null;
          a.SoftDueDate = a.SoftDueDate ? new Date(a.SoftDueDate) : null;
          a.HardDueDate = a.HardDueDate ? new Date(a.HardDueDate) : null;
        })

        this.rawData.forEach(a => {
          a.DaysTillSoft = this.daysTill(a.SoftDueDate);
          a.DaysTillHard = this.daysTill(a.HardDueDate);
        })
        console.log(this.rawData);
      }
    });
  }

  daysTill(dueDate: Date): number {
    let ret = Math.round((new Date(dueDate.toString()).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
    if (ret < 0)
      ret = 0;
    return ret;
  }

}
