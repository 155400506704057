import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { timeActionService, timeEmployeeService } from 'app/_data/time.service';
import { secGoTime } from 'app/_models/SEC.model';
import { timeClockAction, timeEmployeeSnapshot } from 'app/_models/TIME.model';
import { AlertService, AuthService } from 'app/_services';
import { NotifierService } from 'app/_sharedData/notifier.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'arc-time-track',
  templateUrl: './time-track.component.html',
  styleUrls: ['./time-track.component.scss']
})
export class TimeTrackComponent implements OnInit, OnDestroy {

  employee: timeEmployeeSnapshot;
  userID: number;

  newOrderID: number = null;
  currentOrderID: number = null;
  workingOrder: boolean = false;
  showOrderIDBtn: boolean = false;
  statusData: secGoTime = null;

  functionDisplayName: string = '';
  clientDisplayName: string = '';

  isBusy: boolean = false;

  private _subscription: Subscription;

  @Input() pack: timeEmployeeSnapshot;

  constructor(
    private authService: AuthService,
    private employeeService: timeEmployeeService,
    private actionService: timeActionService,
    private alert: AlertService,
    private notifier: NotifierService
  ) { }

  ngOnInit(): void {
    this.userID = this.authService.currentUser.UserID;
    this._subscription = this.employeeService.getSnapshot(this.userID).subscribe(
      (value) => {
        if (value) {
          this.employee = value;
          // this.employeeService.updateEmployeeSnapshot(this.employee);
          this.functionDisplayName = value.CurrentTimeSegment.Function.FunctionName;
          this.clientDisplayName = value.CurrentTimeSegment?.CurrentTrackSegment.ClientName;

          if (value.CurrentTimeSegment.CurrentTrackSegment.TrackID) {
            this.showOrderIDBtn = true;
            this.currentOrderID = value.CurrentTimeSegment.CurrentTrackSegment.TrackID;
            this.workingOrder = true;
            // document.getElementById('orderIDTextInput').focus();
          } else {
            this.showOrderIDBtn = false;
            this.workingOrder = false;
            // document.getElementById('noOrderIDTextInput').focus();
          }

        } else { this.employee = null; }
      },
      (error) => this.alert.error(error)
    );

  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  getUserStatus() {
    this.authService.gatherGoTimeInfo(this.authService.currentUser.UserID).subscribe(
      (val) => {
        this.statusData = val;
        this.functionDisplayName = val.FunctionName;
        this.clientDisplayName = val.ClientShortName;

        if (val.TrackID) {
          this.workingOrder = true;
          this.showOrderIDBtn = true;
          this.currentOrderID = val.TrackID;
        } else {
          this.showOrderIDBtn = false;
        }
        console.log(val);
      },
      (error) => this.alert.error(error)
    );
  }

  // **********************
  // Here is where we begin a track segment 
  // Posting an action with the OrderID 
  // **********************
  beginTrack() {
    if (this.newOrderID) {
      if (this.isBusy)
        return;
      this.isBusy = true;
      this.notifier.loadingOn();
      let action: timeClockAction = {
        EmployeeID: this.employee.Employee.EmployeeID,
        TimeStamp: new Date().toLocaleTimeString(),
        Action: 'FUNC',
        FunctionID: this.employee.CurrentTimeSegment.FunctionID,
        ClientID: this.employee.CurrentTimeSegment.CurrentTrackSegment.ClientID,
        IsBillable: this.employee.CurrentTimeSegment.CurrentTrackSegment.IsBillable,
        Approval: null,
        Note: null,
        IsAutoGenerated: false,
        StopType: 0,
        // Two is for orderID
        TrackTypeID: 2,
        TrackID: this.newOrderID
      }
      console.log(action);
      this.actionService.postAction(action)
        .pipe(finalize(() => {
          this.notifier.loadingOff();
          this.isBusy = false;
        }))
        .subscribe((data: timeEmployeeSnapshot) => {
          if (data.ActionSuccess === true) {
            this.employee = data;
            this.workingOrder = true;
            this.functionDisplayName = data.CurrentTimeSegment.Function.FunctionName;
            this.clientDisplayName = data.CurrentTimeSegment.CurrentTrackSegment.ClientName;
            this.currentOrderID = data.CurrentTimeSegment.CurrentTrackSegment.TrackID;
            this.newOrderID = null;
            // if (data.CurrentTimeSegment.CurrentTrackSegment.TrackID) {
            //   this.showOrderIDBtn = true;
            // } else { this.showOrderIDBtn = false; }

            console.log('Return Snapshot', data);
            this.alert.success('Working on OrderID: ' + data.CurrentTimeSegment.CurrentTrackSegment.TrackID);
            this.employeeService.updateEmployeeSnapshot(data);
          } else { 
            this.alert.error(data.ActionStatus); 
          }
        }, err => {
          this.alert.error(err);
          // this.currentOrderID = null;
          // this.workingOrder = false;
        });
    } else {
      this.alert.warn('Please Enter an OrderID');
      this.currentOrderID = null;
      return;
    }
  }

  // **********************
  // Here is where we end a track segment 
  // Posting an action with the OrderID 
  // **********************
  endTrack() {
    if (this.isBusy)
      return;
    this.isBusy = true;
    this.notifier.loadingOn();
    this.workingOrder = false;
    let action: timeClockAction = {
      EmployeeID: this.employee.Employee.EmployeeID,
      TimeStamp: new Date().toLocaleTimeString(),
      Action: 'FUNC',
      FunctionID: this.employee.CurrentTimeSegment.FunctionID,
      ClientID: this.employee.CurrentTimeSegment.CurrentTrackSegment.ClientID,
      IsBillable: this.employee.CurrentTimeSegment.CurrentTrackSegment.IsBillable,
      Approval: null,
      Note: null,
      IsAutoGenerated: false,
      StopType: 0,
      // One is for orderID
      TrackTypeID: 2,
      TrackID: null
    }

    this.actionService.postAction(action)
      .pipe(finalize(() => {
        this.notifier.loadingOff();
        this.isBusy = false;
      }))
      .subscribe((data: timeEmployeeSnapshot) => {
        if (data.ActionSuccess === true) {
          this.employee = data;
          this.newOrderID = null;
          this.currentOrderID = null;
          this.functionDisplayName = data.CurrentTimeSegment.Function.FunctionName;
          this.clientDisplayName = data.CurrentTimeSegment.CurrentTrackSegment.ClientName;

          this.alert.success('Billing ' + data.CurrentTimeSegment.CurrentTrackSegment.ClientName + ' for ' + data.CurrentTimeSegment.Function.FunctionName + ' with no Order');
          this.employeeService.updateEmployeeSnapshot(data);
        } else { this.alert.error(data.ActionStatus); }
      }, err => {
        this.alert.error(err);
        this.currentOrderID = null;
        this.workingOrder = false;
      });
  }

  goHome() {
    this.workingOrder = false;
  }
}
