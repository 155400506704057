<div class="header">
	<h1>Time Track</h1>
	<div class="currentData">
		<h2> {{ functionDisplayName }} </h2>
		<div style="width: 25px;"></div>
		<h2> {{ clientDisplayName }} </h2>
	</div>
	<div *ngIf="workingOrder === true">
		<h2 style="margin: 25px 0 0 0;"> Current Order </h2>
		<h2> {{ currentOrderID }} </h2>
	</div>
</div>


<div class="body">
	<div style="display: flex; flex-direction: column; justify-content: center; text-align: center;">
		<input autofocus type="number" pInputText placeholder="Order ID" id="orderIDTextInput" [(ngModel)]="newOrderID"
			style="padding: 10px; width: 500px;" [disabled]="isBusy" (keyup.tab)="beginTrack()" (keyup.enter)="beginTrack()">
		<div style="padding: 5px;"></div>

		<button *ngIf="workingOrder === false" type="button" pButton pRipple icon="pi pi-arrow-right"
			styleClass="p-button-warn" [disabled]="isBusy" (click)="beginTrack()" label="Begin Order" style="padding: 10px;"></button>
		<button *ngIf="workingOrder === true" type="button" pButton pRipple icon="pi pi-arrow-right" iconPos="right"
			label="Next Order" [disabled]="isBusy" (click)="beginTrack()" style="padding: 10px;"></button>
		<div style="padding: 10px;"></div>
		<button *ngIf="workingOrder === true" type="button" pButton pRipple icon="pi pi-arrow-right" iconPos="right"
			label="Continue with No Order" [disabled]="isBusy" (click)="endTrack()" style="padding: 10px;"></button>
	</div>
</div>