import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { secUserValidations } from 'app/_models/SEC.model';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';

import { AlertService, AuthService } from '../_services/index';

@Component({
    //    moduleId: module.id,
    templateUrl: 'login.component.html'
})

export class LoginComponent implements OnInit, OnDestroy {
    model: any = {};
    loading = false;
    returnUrl: string;
    validated = false;

    subValidations: Subscription;

    env = environment;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private alert: AlertService
    ) { }

    ngOnInit() {
        // reset login status
        this.authService.logout();


        // get return url from route parameters or default to '/'
        // 1/28/22 RJS THIS IS NEEDED!
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.subValidations = this.authService.userValidations$.subscribe(
            (d: secUserValidations[]) => {
                if (d) {
                    this.validated = (d.length === 0);
                    if (this.validated) {
                        this.router.navigate([this.returnUrl]);
                    } else {
                        this.router.navigate(['validations']);
                    }
                }
            },
            (error) => this.alert.error(error)
        );
    }

    ngOnDestroy(): void {
        this.subValidations.unsubscribe();
    }

    login() {
        this.loading = true;
        console.log('trying to login RJS');
        this.authService.login(this.model.username, this.model.password)
            .subscribe(
                (data) => {
                    this.authService.checkValidations();
                },
                error => {
                    this.alert.error(error);
                    this.loading = false;
                }
            );
    }
}
