
import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import { cfgServiceLevel } from '../_models/CFG.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ServiceLevelsService {

  api = 'cfgServiceLevel/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<cfgServiceLevel[]>(this.apiURL + this.api);

  }
  create(ServiceLevel: cfgServiceLevel) {
    return this.http.post(this.apiURL + this.api, ServiceLevel);
  }

  update(ServiceLevel: cfgServiceLevel) {
    return this.http
      .put(this.apiURL + this.api + ServiceLevel.ServiceLevelID, ServiceLevel);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }
}

