import { IDType } from './enums.model';
import { utlByNames } from './utlByNames.model';

/** A class which represents the org.Affiliates table. */
export class orgAffiliate extends utlByNames {
	EntityID: number;
	IsActive: boolean;
	IsShipCon: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the org.AffiliatesVw view. */
export class orgAffiliatesVw extends utlByNames {
	EntityID: number;
	EntityName: string;
	ShortName: string;
	OnPropackHold: boolean;
	OnCreditHold: boolean;
	IsShipCon: boolean;
	IsActive: boolean;
	TerminalCount: number;
	ActiveCount: number;
	EntityType: string;
	AssociatedEntityID: number;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
export class orgAffiliateEdit extends utlByNames {
	EntityID: number;
	EntityName: string;
	ShortName: string;
	IsActive: boolean;
	IsShipCon: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
	OnPropackHold: boolean;
	OnCreditHold: boolean;
	TerminalCount: number;
	AssociatedEntityID: number;
	EntityType: string;
	AssociationIsActive: boolean;

}
/** A class which represents the org.Associations table. */
export class orgAssociation {
	EntityID: number;
	AssociatedEntityID: number;
	AssociationTypeCode: string;
	Note: string;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the org.BrokerCountries table. */
export class orgBrokerCountry {
	BrokerCountryID: number;
	EntityID: number;
	BrokerCountryCode: string;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the org.CarrierFuelSurcharges table. */
export class orgCarrierFuelSurcharge {
	CarrierFuelSurchargeID: number;
	EntityID: number;
	StartDate: Date;
	EndDate: Date;
	FuelSurcharge: number;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the org.ClientsFreight table. */
export class orgClientsFreight {
	EntityID: number;
	ContractExpirationDate: Date;
	CrossReferenceID: number;
	IsActive: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}

export class orgClientsGoTime {
	EntityID: number;
	EntityName: string;
	ShortName: string;
	IsActive: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}

export class ClientsGoTimeSearch
{
	EntityID: number;
	P3PLClientID: number;
	ShortName: string;
}

/** A class which represents the org.ClientsFreightVw view. */
export class orgClientsFreightVw {
	EntityID: number;
	EntityName: string;
	ShortName: string;
	OnPropackHold: boolean;
	OnCreditHold: boolean;
	PaymentTermsID: number;
	ShipConCount: number;
	BrokerCount: number;
	ContactCount: number;
	NmfcCount: number;
	IsActive: boolean;
	ContractExpirationDate: Date;
	CrossReferenceID: number;
	EntityType: string;
	AssociatedEntityID: number;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the org.ClientsFulfillment table. */
export class orgClientsFulfillment {
	EntityID: number;
	ClientName: string;
	ContractExpirationDate: Date;
	DBAForEntityID: number;
	CommonInventory: boolean;
	CommonServices: boolean;
	CommonCharges: boolean;
	IsActive: boolean;
	IsLive: boolean;
	UsesWebRobot: boolean;
	AcceptEmailRobotOrders: number;
	DefaultWarehouseID: number;
	DefaultServiceLevelCode: string;
	DefaultShippingServiceCode: string;
	SortPickTicketBy: number;
	SortPackingListBy: number;
	DefaultStorageUnitID: number;
	ShipPartialOrders: boolean;
	IncludePickingTicket: boolean;
	IncludeInvoice: boolean;
	BarCodesIncluded: boolean;
	LocationScanning: boolean;
	LotNumberScanning: boolean;
	SerialNumberScanning: boolean;
	RequireProductWeights: boolean;
	PickGroups: boolean;
	OnProPackHold: boolean;
	OnCreditHold: boolean;
	RestrictedCtryHold: boolean;
	AutoHold: boolean;
	AdditionalReferenceRequired: boolean;
	UsesSizeTypes: boolean;
	EmailOrderFailToClient: boolean;
	Donate: boolean;
	ShippingNotes: string;
	ReceivingNotes: string;
	PickTicketFooterComment: string;
	ConsigneeEmailMessage: string;
	ReturnCriteria: string;
	ReturnToInventory: boolean;
	ReturnToClient: boolean;
	SendShippingSummary: boolean;
	RequireLotNumbers: boolean;
	TrackConsignees: boolean;
	PickParcel: boolean;
	NonStock: boolean;
	ConfirmOrders: boolean;
	DeliveryConfirmation: boolean;
	ValueAddedShipments: boolean;
	VASShowCost: boolean;
	ReplenishmentEnabled: boolean;
	LowInventoryCountEnabled: boolean;
	CycleCountEnabled: boolean;
	SendConsigneeEmail: boolean;
	TimeClockJobCode: number;
	BackorderQtyCheck: boolean;
	BackorderQtyPercent: number;
	BackorderValueCheck: boolean;
	BackorderValuePercent: number;
	BackorderLineItemsCheck: boolean;
	BackorderLineItemsPercent: number;
	BackorderLineItemsComplete: boolean;
	BackorderTimesCheck: boolean;
	BackorderTimesAllowed: number;
	BackorderCloneOrder: boolean;
	TasksCanView: boolean;
	TasksCanSubmit: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the org.ClientWarehouses table. */
export class orgClientWarehouse {
	ClientWarehouseID: number;
	ClientID: number;
	WarehouseID: any;
	IsActive: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the org.Emails table. */
export class orgEmail {
	EmailID: number;
	EmailTypeID: any;
	EmailAddress: string;
	EntityID: number;
	PlaceID: number;
	PersonID: number;
	Comment: string;
	IsActive: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the org.EmailVw view. */
export class orgEmailVw {
	EmailID: number;
	EmailAddress: string;
	EmailTypeID: any;
	EntityID: number;
	PlaceID: number;
	PersonID: number;
	IsDefault: boolean;
	Comment: string;
	IsActive: boolean;
	EmailType: string;
	EntityName: string;
	PlaceName: string;
	PersonName: string;
	Address1: string;
	Address2: string;
	Address: string;
	City: string;
	StateProvinceCode: string;
	PostalCode: string;
	CountryCode: string;
	CSC: string;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the org.Entities table. */
export class orgEntity {
	EntityID: number;
	EntityName: string;
	ShortName: string;
	OnPropackHold: boolean;
	OnCreditHold: boolean;
	PaymentTermsID: number;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
	FBContactMasterID: number;
	P3PLClientID: number;
	BillingPlaceID: number;
}

export class orgEntityVw extends orgEntity {
	IsPayableCrossDock: boolean;
	PreferredCurrencyCode: string;
}

export class orgEntityHeader {
	EntityID: number;
	EntityName: string;
	ShortName: string;
	OnPropackHold: boolean;
	OnCreditHold: boolean;
	IsActive: boolean;
	EntityType: string;
	AssociatedEntityID: number;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
export class orgEntitySearchResult {
	EntityID: number;
	EntityName: string;
	PaymentTermsID: number;
	OnCreditHold: boolean;
	PreferredCurrencyCode: string;
}

export class orgAssociate {
	EntityID: number;
	EntityName: string;
	ShortName: string;
	OnPropackHold: boolean;
	OnCreditHold: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
	FBContactMasterID: number;
	P3PLClientID: number;
	IsActive: boolean;
}

/** A class which represents the org.EntityPersons table. */
export class orgEntityPerson extends utlByNames {
	EntityPersonID: number;
	EntityID: number;
	PersonID: number;
	Title: string;
	IsPrimaryEntity: boolean;
	IsPrimaryPerson: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
	Places: number[];

	constructor() {
		super();
		this.EntityID = IDType.NoRec;
		this.PersonID = IDType.NoRec;
		this.Title = '';
	}
}

/** A class which represents the org.Activity table. */
export class orgActivity {
	ActivityID: number;
	ActivityTypeID: number;
	EntityID: number;
	ActivityDescription: string;
	CreatedBy: number;
	CreatedDate: Date;
	OldValue: string;
	NewValue: string;
	EntityName: string;
	ActivityType: string;
}
/** A class which represents the org.Media table. */
export class orgMedium {
	MediaID: number;
	MediaTypeID: any;
	MediaURL: string;
	EntityID: number;
	PlaceID: number;
	PersonID: number;

	Comment: string;
	IsActive: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the org.MediaVw view. */
export class orgMediaVw {
	MediaID: number;
	MediaURL: string;
	MediaTypeID: any;
	EntityID: number;
	PlaceID: number;
	PersonID: number;
	Comment: string;
	IsActive: boolean;
	MediaType: string;
	EntityName: string;
	PlaceName: string;
	PersonName: string;
	Address1: string;
	Address2: string;
	Address: string;
	City: string;
	StateProvinceCode: string;
	PostalCode: string;
	CountryCode: string;
	CSC: string;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the org.Notes table. */
export class orgNote {
	NoteID: number;
	NoteTypeID: any;
	EntityID: number;
	PlaceID: number;
	PersonID: number;
	EmailID: number;
	MediaID: number;
	PhoneID: number;
	Note: string;
	ClientVisible: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
	PersonName: string;
}

/** A class which represents the org.OrganizationsListVw view. */
export class orgOrganizationsListVw {
	EntityID: number;
	EntityName: string;
	IsBillTo: boolean;
	IsCarrier: boolean;
	IsBroker: boolean;
	IsInsurance: boolean;
	IsSalesRep: boolean;
	IsCrossDock: boolean;
	IsOther: boolean;
	IsApproved: boolean;
	IsShipCon: boolean;
}
/** A class which represents the org.Persons table. */
export class orgPerson {
	PersonID: number;
	FirstName: string;
	LastName: string;
	PersonName: string;
	BirthDate: Date;
	IsActive: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
	P3PLPersonID: number;
	FBSubContact2ID: number;
	UserID: number;
}
/** A class which represents the org.PersonSimpleVw view. */
export class orgPersonSimpleVw {
	EntityID: number;
	Title: string;
	PlaceID: number;
	PersonID: number;
	FirstName: string;
	LastName: string;
	PersonName: string;
	IsActive: boolean;
}
/** A class which represents the org.PersonVw view. */
export class orgPersonVw {
	EntityPersonID: number;
	EntityID: number;
	Title: string;
	IsPrimary: boolean;
	EntityName: string;
	PersonID: number;
	FirstName: string;
	LastName: string;
	PersonName: string;
	BirthDate: Date;
	IsActive: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
	P3PLPersonID: number;
	PhoneNumber: string;
	PhoneType: string;
	EmailAddress: string;
	EmailType: string;
}

/** class which represents the results of searching for a person */
export class orgPersonSearchResult {
	PersonID: number;
	FirstName: string;
	LastName: string;
	PersonName: string;
	IsActive: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
	PhoneNumber: string;
	EmailAddress: string;
	MediaURL: string;
	EntityPersonID: number;
	EntityID: number;
	Title: string;
	EntityName: string;
	IsUser: boolean;
}

/** A class which represents the org.Phones table. */
export class orgPhone {
	PhoneID: number;
	PhoneTypeID: any;
	PhoneNumber: string;
	EntityID: number;
	PlaceID: number;
	PersonID: number;

	Comment: string;
	IsActive: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the org.PhoneVw view. */
export class orgPhoneVw {
	PhoneID: number;
	PhoneNumber: string;
	PhoneTypeID: any;
	IsDefault: boolean;
	EntityID: number;
	PlaceID: number;
	PersonID: number;
	Comment: string;
	IsActive: boolean;
	PhoneType: string;
	EntityName: string;
	PlaceName: string;
	PersonName: string;
	Address1: string;
	Address2: string;
	Address: string;
	City: string;
	StateProvinceCode: string;
	PostalCode: string;
	CountryCode: string;
	CSC: string;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the org.PlacePersons table. */
export class orgPlacePerson {
	PlacePersonID: number;
	PlaceID: number;
	PersonID: number;
	Title: string;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}
/** A class which represents the org.PlacePersonVw view. */
export class orgPlacePersonVw {
	PlacePersonID: number;
	PlaceID: number;
	PlaceName: string;
	PersonID: number;
	FirstName: string;
	LastName: string;
	BirthDate: Date;
	IsActive: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
	P3PLPersonID: number;
	PhoneNumber: string;
	PhoneType: string;
	EmailAddress: string;
	EmailType: string;
}
/** A class which represents the org.Places table. */
export class orgPlace {
	PlaceID: number;
	EntityID: number;
	PlaceTypeID: any;
	PlaceName: string;
	Address1: string;
	Address2: string;
	City: string;
	StateProvinceCode: string;
	PostalCode: string;
	CountryCode: string;
	AirportCode: string;
	OpenHours: string;
	IsBillingAddress: boolean;
	IsCrossDock: boolean;
	IsTerminal: boolean;
	IsActive: boolean;
	Comment: string;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
	FBContactsSub2ID: number;
	CSC: string;
	WarehouseID: number;
}
/** A class which represents the org.PlaceVw view. */
export class orgPlaceVw {
	PlaceID: number;
	EntityID: number;
	EntityName: string;
	IsCarrier: boolean;
	PlaceType: string;
	PlaceName: string;
	Address1: string;
	Address2: string;
	Address: string;
	City: string;
	StateProvinceCode: string;
	PostalCode: string;
	CountryCode: string;
	CSC: string;
	AirportCode: string;
	OpenHours: string;
	IsCrossDock: boolean;
	IsTerminal: boolean;
	IsActive: boolean;
	Comment: string;
}
/** A class which represents the org.VendorCrossDockVw view. */
export class orgVendorCrossDockVw {
	EntityID: number;
	EntityName: string;
	IsActive: boolean;
	TerminalCount: number;
	DefaultSalesCommission: number;
}
/** A class which represents the org.Vendors table. */
export class orgVendor {
	EntityID: number;
	IsActive: boolean;
	IsCarrier: boolean;
	IsBroker: boolean;
	IsInsurance: boolean;
	IsSalesRep: boolean;
	IsCrossDock: boolean;
	IsOther: boolean;
	IsApproved: boolean;
	DefaultSalesCommission: number;
	CashOnly: boolean;
	AutoBilling: boolean;
	PreferredCurrencyCode: string;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}

export class orgVendorEdit {
	EntityID: number;
	EntityName: string;
	ShortName: string;
	IsActive: boolean;
	IsCarrier: boolean;
	IsBroker: boolean;
	IsInsurance: boolean;
	IsSalesRep: boolean;
	IsCrossDock: boolean;
	IsOther: boolean;
	IsApproved: boolean;
	DefaultSalesCommission: number;
	CashOnly: boolean;
	AutoBilling: boolean;
	PreferredCurrencyCode: string;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
	OnPropackHold: boolean;
	OnCreditHold: boolean;
	TerminalCount: number;
	AssociatedEntityID: number;
	EntityType: string;
	AssociationIsActive: boolean;
}

// the following structure is only used by the entity-quick-edit component
// to quickly add an entity
export class orgAddress {
	EntityName: string;
	ContactName: string;
	PlaceName: string;
	Address1: string;
	Address2: string;
	City: string;
	PostalCode: string;
	StateProvinceCode: string;
	CountryCode: string;
	AirportCode: string;
	TollFree: string;
	Phone: string;
	Fax: string;
	Mobile: string;
	EMail: string;
	Website: string;
	OpenHours: string;
	Comments: string;
}

export class orgDocument {
	DocumentID: number;
	OrgItemTypeID: number;
	EntityID: number;
	PlaceID: number;
	PersonID: number;
	EmailID: number;
	PhoneID: number;
	MediaID: number;
	DocumentName: string;
	DocumentPath: string;
	ContentType: string;
	DocumentDescription: string;
	ClientVisible: boolean;
	CreatedBy: number;
	CreatedDate: Date;
	UpdatedBy: number;
	UpdatedDate: Date;
}

export class orgDocumentEx extends orgDocument {
	PersonName: string;
	UserInitials: string;
	LinkType: string;
	LinkName: string;
}
