import { Injectable } from '@angular/core';
import { SelectItem } from 'primeng/api/selectitem';
import { Observable } from 'rxjs';

import { AlertService } from './index';
import { cfgStatus } from '../_models/CFG.model';
import { utlAirportCode, utlStateProvinceCode } from '../_models/UTL.model';

import { AirportCodesService } from '../_data/airport-codes.service';
import { AudCarrierService } from '../_data/auditing.service';
import { CountryCodesService } from '../_data/country-codes.service';
import { CurrencyExchangeService } from '../_data/currency-exchange.service';
import { EmailTypesService } from '../_data/email-types.service';
import { HandlingChargesService } from '../_data/handling-charges.service';
import { InternationalTypesService } from '../_data/international-types.service';
import { MediaTypesService } from '../_data/media-types.service';
import { NmfcNumbersService } from '../_data/nmfc-numbers.service';
import { PaymentTermsService } from '../_data/payment-terms.service';
import { PaymentTypesService } from '../_data/payment-types.service';
import { PhoneTypesService } from '../_data/phone-types.service';
import { PlaceTypesService } from '../_data/place-types.service';
import { ReportParameterTypeService } from '../_data/reports.service';
import { RightTypesService } from '../_data/right-types.service';
import { ServiceLevelsService } from '../_data/service-levels.service';
import { StateProvinceCodesService } from '../_data/state-province-codes.service';
import { StatusesService } from '../_data/statuses.service';
import { TrackingCategoryService } from '../_data/tracking-category.service';
import { UsersService } from '../_data/users.service';
import { VendorFeesService } from '../_data/vendor-fees.service';
import { VendorsService } from '../_data/vendors.service';
import { WarehousesService } from '../_data/warehouses.service';

import { LookupListType } from '../_models/enums.model';
import { audCarrierDb } from '../_models/AUD.model';
import {
  cfgBillTypesService,
  cfgClientRulesService,
  cfgDepartmentsService,
  cfgEmployeeTypesService,
  cfgIssueTypesService,
  cfgPayrollTypesService,
  cfgScheduleTypesService,
  cfgTerminationReasonsService,
  cfgTrackTypesService,
  cfgWorkGroupsService,
  cfgWorkStatusesService
} from 'app/_data/time-cfg.service';
// import { ɵangular_packages_platform_browser_platform_browser_k } from '@angular/platform-browser';
import { astActionService, cfgAssetStatusService, cfgAssetTypeService } from 'app/_data/asset.service';
import { docCategoryService, docLanguageService, docStatusService } from 'app/_data/pro-docs.service';
import { cfgPaymentTypesService } from 'app/_data/sendit-cfg.service';


@Injectable({
  providedIn: 'root'
})
export class LookupsService {

  activityTypeList: SelectItem[] = [];
  airportCodeList: utlAirportCode[] = [];
  assetActionList: SelectItem[] = [];
  assetStatusList: SelectItem[] = [];
  assetTypeList: SelectItem[] = [];
  associationTypeList: SelectItem[] = [];
  //billingTermList: SelectItem[] = [];
  //billingTypeList: SelectItem[] = [];
  billTypeList: SelectItem[] = [];
  carrierTrackingLinkList: SelectItem[] = [];
  clientRuleList: SelectItem[] = [];
  countryCodeList: SelectItem[] = [];
  country2CodeList: SelectItem[] = [];
  currencyCodeList: SelectItem[] = [];
  departmentList: SelectItem[] = [];
  emailTypeList: SelectItem[] = [];
  employeeTypeList: SelectItem[] = [];
  employeeTypeGroupList: SelectItem[] = [];
  feeTypeList: SelectItem[] = [];
  forwarderList: SelectItem[] = [];
  functionGroupList: SelectItem[] = [];
  handlingChargesList: SelectItem[] = [];
  insurerList: SelectItem[] = [];
  internationalTypeList: SelectItem[] = [];
  issueTypeList: SelectItem[] = [];
  languageList: SelectItem[] = [];
  mediaTypeList: SelectItem[] = [];
  nmfcNumberList: SelectItem[] = [];
  noteTypeList: SelectItem[] = [];
  orderHoldsList: SelectItem[] = [];
  otherPayableList: SelectItem[] = [];
  paymentTermsList: SelectItem[] = [];
  paymentTypeList: SelectItem[] = [];
  payrollTypeList: SelectItem[] = [];
  payrollTypeGroupList: SelectItem[] = [];
  phoneTypeList: SelectItem[] = [];
  pieceTypeList: SelectItem[] = [];
  placeTypeList: SelectItem[] = [];
  proDocActorList: SelectItem[] = [];
  reportParameterTypeList: SelectItem[] = [];
  rightTypeList: SelectItem[] = [];
  salesRepList: SelectItem[] = [];
  scheduleTypeList: SelectItem[] = [];
  serviceLevelsList: SelectItem[] = [];
  serviceProcessesList: SelectItem[] = [];
  serviceUnitsList: SelectItem[] = [];
  shipmentTypeList: SelectItem[] = [];
  stateProvinceCodeList: utlStateProvinceCode[] = [];
  statusesList: cfgStatus[] = [];
  terminationReasonList: SelectItem[] = [];
  trackingCategoriesList: SelectItem[] = [];
  trackTypeList: SelectItem[] = [];
  userNameList: SelectItem[] = [];
  warehouseList: SelectItem[] = [];
  warehouserList: SelectItem[] = [];
  workGroupList: SelectItem[] = [];
  workStatusList: SelectItem[] = [];
  documentCategoryList: SelectItem[] = [];
  documentStatusList: SelectItem[] = []
  senditPaymentTypesList: SelectItem[] = []
  carriersList: SelectItem[] = [];

  exchangeRateList: { fromCur: string, toCur: string, onDate: string, rate: number }[] = [];


  // fromCurrency = '';
  // toCurrency = '';
  // exRate = 1.0;
  // onDate = '';

  constructor(
    private airportsService: AirportCodesService,
    private assetActionService: astActionService,
    private assetStatusService: cfgAssetStatusService,
    private assetTypeService: cfgAssetTypeService,
    private alert: AlertService,
    private billTypeService: cfgBillTypesService,
    private carrierService: AudCarrierService,
    private categoryService: TrackingCategoryService,
    private clientRuleService: cfgClientRulesService,
    private countryCodesService: CountryCodesService,
    private currencyExchangeService: CurrencyExchangeService,
    private departmentService: cfgDepartmentsService,
    private emailTypesService: EmailTypesService,
    private employeeTypeService: cfgEmployeeTypesService,
    private feeTypesService: VendorFeesService,
    private handlingChargesService: HandlingChargesService,
    private internationalTypesService: InternationalTypesService,
    private issueTypeService: cfgIssueTypesService,
    private languageService: docLanguageService,
    private mediaTypesService: MediaTypesService,
    private nmfcNumberService: NmfcNumbersService,
    private paymentTermsService: PaymentTermsService,
    private paymentTypesService: PaymentTypesService,
    private senditPaymentTypesService: cfgPaymentTypesService,
    private payrollTypesService: cfgPayrollTypesService,
    private phoneTypesService: PhoneTypesService,
    private placeTypesService: PlaceTypesService,
    private reportParameterTypeService: ReportParameterTypeService,
    private rightTypesService: RightTypesService,
    private scheduleTypeService: cfgScheduleTypesService,
    private serviceLevelsService: ServiceLevelsService,
    private stateProvinceCodeService: StateProvinceCodesService,
    private statusesService: StatusesService,
    private terminationReasonService: cfgTerminationReasonsService,
    private trackTypeService: cfgTrackTypesService,
    private usersService: UsersService,
    private vendorsService: VendorsService,
    private warehousesService: WarehousesService,
    private workStatusService: cfgWorkStatusesService,
    private workGroupService: cfgWorkGroupsService,
    private documentStatusService: docStatusService,
    private documentCategoryService: docCategoryService

  ) { }

  public assetStatuses = new Observable((assetStatuses) => {
    if (this.assetStatusList.length === 0) {
      this.assetStatusService.getAll().subscribe(data => {
        this.assetStatusList = data
          .map(x => ({ label: x.AssetStatusName, value: +x.AssetStatusID }))
          .sort((a, b) => this.sortFunction(a, b));
        assetStatuses.next(this.assetStatusList);
        assetStatuses.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      assetStatuses.next(this.assetStatusList);
      assetStatuses.complete();
    }
  });

  public assetActions = new Observable((assetActions) => {
    if (this.assetActionList.length === 0) {
      this.assetActionService.getAll().subscribe(data => {
        this.assetActionList = data
          .map(x => ({ label: x.ActionName, value: +x.ActionID }))
          .sort((a, b) => this.sortFunction(a, b));
        assetActions.next(this.assetActionList);
        assetActions.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      assetActions.next(this.assetActionList);
      assetActions.complete();
    }
  });


  public assetTypes = new Observable((assetTypes) => {
    if (this.assetTypeList.length === 0) {
      this.assetTypeService.getAll().subscribe(data => {
        this.assetTypeList = data
          .map(x => ({ label: x.AssetTypeName, value: +x.AssetTypeID }))
          .sort((a, b) => this.sortFunction(a, b));
        assetTypes.next(this.assetTypeList);
        assetTypes.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      assetTypes.next(this.assetTypeList);
      assetTypes.complete();
    }
  });

  public warehouses = new Observable((warehouses) => {
    if (this.warehouseList.length === 0) {
      this.warehousesService.getAll().subscribe(data => {
        // console.log('got warehouses', data);
        this.warehouseList = data
          .filter(w => w.IsActive === true)
          .map(x => ({ label: x.WarehouseName, value: +x.WarehouseID, disabled: !x.IsActive }))
          .sort((a, b) => this.sortFunction(a, b));
        warehouses.next(this.warehouseList);
        warehouses.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      warehouses.next(this.warehouseList);
      warehouses.complete();
    }
  });

  public warehousers = new Observable((warehousers) => {
    if (this.warehouserList.length === 0) {
      this.usersService.getAllWarehousers().subscribe(data => {
        this.warehouserList = data
          .map(x => ({ label: x.PersonName, value: x.UserID }))
          .sort((a, b) => this.sortFunction(a, b));
        warehousers.next(this.warehouserList);
        warehousers.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      warehousers.next(this.warehouserList);
      warehousers.complete();
    }
  });

  public proDocActors = new Observable((proDocActors) => {
    if (this.proDocActorList.length === 0) {
      this.usersService.getProDocActors().subscribe(data => {
        this.proDocActorList = data
          .map(x => ({ label: x.PersonName, value: x.UserID }))
          .sort((a, b) => this.sortFunction(a, b));
        proDocActors.next(this.proDocActorList);
        proDocActors.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      proDocActors.next(this.proDocActorList);
      proDocActors.complete();
    }
  });


  public nmfcNumbers = new Observable((nmfcNumbers) => {
    if (this.nmfcNumberList.length === 0) {
      this.nmfcNumberService.getAll().subscribe(data => {
        this.nmfcNumberList = data
          .map(x => ({ label: x.NmfcNumber + ' - ' + x.Item, value: x.NmfcNumberID, disabled: !x.IsActive }))
          .sort((a, b) => this.sortFunction(a, b));
        nmfcNumbers.next(this.nmfcNumberList);
        nmfcNumbers.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      nmfcNumbers.next(this.nmfcNumberList);
      nmfcNumbers.complete();
    }
  });

  public nmfcNumbersForced = new Observable((nmfcNumbers) => {
    this.nmfcNumberService.getAll().subscribe(data => {
      this.nmfcNumberList = data
        .map(x => ({ label: x.NmfcNumber + ' - ' + x.Item, value: x.NmfcNumberID, disabled: !x.IsActive }))
        .sort((a, b) => this.sortFunction(a, b));
      nmfcNumbers.next(this.nmfcNumberList);
      nmfcNumbers.complete();
    },
      (error) => this.alert.error(error)
    );
  });

  public paymentTerms = new Observable((paymentTerms) => {
    if (this.paymentTermsList.length === 0) {
      this.paymentTermsService.getAll().subscribe(data => {
        this.paymentTermsList = data
          .map(x => ({ label: x.PaymentTerms, value: x.PaymentTermsID, disabled: !x.IsActive }))
          .sort((a, b) => this.sortFunction(a, b));
        paymentTerms.next(this.paymentTermsList);
        paymentTerms.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      paymentTerms.next(this.paymentTermsList);
      paymentTerms.complete();
    }
  });

  public paymentTypes = new Observable((paymentTypes) => {
    if (this.paymentTypeList.length === 0) {
      this.paymentTypesService.getAll().subscribe(data => {
        this.paymentTypeList = data
          .map(x => ({ label: x.PaymentType, value: x.PaymentTypeID, disabled: !x.IsActive }))
          .sort((a, b) => this.sortFunction(a, b));
        paymentTypes.next(this.paymentTypeList);
        paymentTypes.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      paymentTypes.next(this.paymentTypeList);
      paymentTypes.complete();
    }
  });

  public payrollTypes = new Observable((payrollTypes) => {
    if (this.payrollTypeList.length === 0) {
      this.payrollTypesService.getAll().subscribe(data => {
        this.payrollTypeList = data
          .map(x => ({ label: x.PayrollTypeName, value: x.PayrollTypeID, disabled: !x.IsActive }))
          .sort((a, b) => this.sortFunction(a, b));
        payrollTypes.next(this.payrollTypeList);
        payrollTypes.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      payrollTypes.next(this.payrollTypeList);
      payrollTypes.complete();
    }
  });

  public payrollTypeGroups = new Observable((payrollTypeGroups) => {
    if (this.payrollTypeGroupList.length === 0) {
      this.payrollTypesService.getAll().subscribe(data => {
        this.payrollTypeGroupList = data
          .filter((item, i, ar) => ar.findIndex(x => x.PayrollTypeGroup === item.PayrollTypeGroup) === i) // remove duplicates
          .map(x => ({ label: x.PayrollTypeGroup, value: x.PayrollTypeGroup, disabled: !x.IsActive }))
          .sort((a, b) => this.sortFunction(a, b));
        payrollTypeGroups.next(this.payrollTypeGroupList);
        payrollTypeGroups.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      payrollTypeGroups.next(this.payrollTypeGroupList);
      payrollTypeGroups.complete();
    }
  });

  public billTypes = new Observable((billTypes) => {
    if (this.billTypeList.length === 0) {
      this.billTypeService.getAll().subscribe(data => {
        this.billTypeList = data
          .map(x => ({ label: x.BillTypeName, value: x.BillTypeID, disabled: !x.IsActive }))
          .sort((a, b) => this.sortFunction(a, b));
        billTypes.next(this.billTypeList);
        billTypes.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      billTypes.next(this.billTypeList);
      billTypes.complete();
    }
  });

  public issueTypes = new Observable((issueTypes) => {
    if (this.issueTypeList.length === 0) {
      this.issueTypeService.getAll().subscribe(data => {
        this.issueTypeList = data
          .map(x => ({ label: x.IssueTypeName, value: x.IssueTypeID, disabled: !x.IsActive }))
          .sort((a, b) => this.sortFunction(a, b));
        issueTypes.next(this.issueTypeList);
        issueTypes.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      issueTypes.next(this.issueTypeList);
      issueTypes.complete();
    }
  });

  public senditPaymentTypes = new Observable((senditPaymentTypes) => {
    if (this.senditPaymentTypesList.length === 0) {
      this.senditPaymentTypesService.getAll().subscribe(data => {
        this.senditPaymentTypesList = data
          .map(x => ({ label: x.PaymentDescription, value: x.PaymentTypeID, disabled: !x.IsActive }))
          .sort((a, b) => this.sortFunction(a, b));
        senditPaymentTypes.next(this.senditPaymentTypesList);
        senditPaymentTypes.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      senditPaymentTypes.next(this.senditPaymentTypesList);
      senditPaymentTypes.complete();
    }
  });

  public languages = new Observable((languages) => {
    if (this.languageList.length === 0) {
      this.languageService.getAll().subscribe(data => {
        this.languageList = data
          .map(x => ({ label: x.LanguageName, value: x.LanguageCode, disabled: false }))
          .sort((a, b) => this.sortFunction(a, b));
        languages.next(this.languageList);
        languages.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      languages.next(this.languageList);
      languages.complete();
    }
  });

  public clientRules = new Observable((clientRules) => {
    if (this.clientRuleList.length === 0) {
      this.clientRuleService.getAll().subscribe(data => {
        this.clientRuleList = data
          .map(x => ({ label: x.ClientRuleName, value: x.ClientRuleID, disabled: false }))
          .sort((a, b) => this.sortFunction(a, b));
        clientRules.next(this.clientRuleList);
        clientRules.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      clientRules.next(this.clientRuleList);
      clientRules.complete();
    }
  });


  public departments = new Observable((departments) => {
    if (this.departmentList.length === 0) {
      this.departmentService.getAll().subscribe(data => {
        this.departmentList = data
          .map(x => ({ label: x.DepartmentName, value: x.DepartmentID, disabled: !x.IsActive }))
          .sort((a, b) => this.sortFunction(a, b));
        departments.next(this.departmentList);
        departments.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      departments.next(this.departmentList);
      departments.complete();
    }
  });


  public scheduleTypes = new Observable((scheduleTypes) => {
    if (this.scheduleTypeList.length === 0) {
      this.scheduleTypeService.getAll().subscribe(data => {
        this.scheduleTypeList = data
          .map(x => ({ label: x.ScheduleTypeName, value: x.ScheduleTypeID, disabled: !x.IsActive }))
          .sort((a, b) => this.sortFunction(a, b));
        scheduleTypes.next(this.scheduleTypeList);
        scheduleTypes.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      scheduleTypes.next(this.scheduleTypeList);
      scheduleTypes.complete();
    }
  });


  public terminationReasons = new Observable((terminationReasons) => {
    if (this.terminationReasonList.length === 0) {
      this.terminationReasonService.getAll().subscribe(data => {
        this.terminationReasonList = data
          .map(x => ({ label: x.TerminationReasonName, value: x.TerminationReasonID, disabled: !x.IsActive }))
          .sort((a, b) => this.sortFunction(a, b));
        terminationReasons.next(this.terminationReasonList);
        terminationReasons.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      terminationReasons.next(this.terminationReasonList);
      terminationReasons.complete();
    }
  });


  public trackTypes = new Observable((trackTypes) => {
    if (this.trackTypeList.length === 0) {
      this.trackTypeService.getAll().subscribe(data => {
        this.trackTypeList = data
          .map(x => ({ label: x.TrackTypeName, value: x.TrackTypeID, disabled: !x.IsActive }))
          .sort((a, b) => this.sortFunction(a, b));
        trackTypes.next(this.trackTypeList);
        trackTypes.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      trackTypes.next(this.trackTypeList);
      trackTypes.complete();
    }
  });

  public documentCategories = new Observable((documentCategories) => {
    if (this.documentCategoryList.length === 0) {
      this.documentCategoryService.getAll().subscribe(data => {
        this.documentCategoryList = data
          .map(x => ({ label: x.CategoryName, value: x.CategoryID, disabled: false }))
          .sort((a, b) => this.sortFunction(a, b));
        documentCategories.next(this.documentCategoryList);
        documentCategories.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      documentCategories.next(this.documentCategoryList);
      documentCategories.complete();
    }
  });

  public documentStatuses = new Observable((documentStatuses) => {
    if (this.documentStatusList.length === 0) {
      this.documentStatusService.getAll().subscribe(data => {
        this.documentStatusList = data
          .map(x => ({ label: x.StatusName, value: x.StatusID, disabled: false }))
          .sort((a, b) => this.sortFunction(a, b));
        documentStatuses.next(this.documentStatusList);
        documentStatuses.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      documentStatuses.next(this.documentStatusList);
      documentStatuses.complete();
    }
  });


  public workStatuses = new Observable((workStatuses) => {
    if (this.workStatusList.length === 0) {
      this.workStatusService.getAll().subscribe(data => {
        this.workStatusList = data
          .map(x => ({ label: x.WorkStatus, value: x.WorkStatusID, disabled: false }))
          .sort((a, b) => this.sortFunction(a, b));
        workStatuses.next(this.workStatusList);
        workStatuses.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      workStatuses.next(this.workStatusList);
      workStatuses.complete();
    }
  });

  public workGroups = new Observable((workGroups) => {
    if (this.workGroupList.length === 0) {
      this.workGroupService.getAll().subscribe(data => {
        this.workGroupList = data
          .map(x => ({ label: x.WorkGroupName, value: x.WorkGroupID, disabled: false }))
          .sort((a, b) => this.sortFunction(a, b));
        workGroups.next(this.workGroupList);
        workGroups.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      workGroups.next(this.workGroupList);
      workGroups.complete();
    }
  });

  public forwarders = new Observable((forwarders) => {
    if (this.forwarderList.length === 0) {
      this.usersService.getAllForwarders().subscribe(data => {
        this.forwarderList = data
          .map(x => ({ label: x.PersonName, value: x.UserID }))
          .sort((a, b) => this.sortFunction(a, b));
        forwarders.next(this.forwarderList);
        forwarders.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      forwarders.next(this.forwarderList);
      forwarders.complete();
    }
  });

  public userNames = new Observable((userNames) => {
    if (this.userNameList.length === 0) {
      this.usersService.getUserNamesAndInitials().subscribe(data => {
        this.userNameList = data
          .map(x => ({ label: x.PersonName, value: x.UserID, disabled: !x.IsActive }))
          .sort((a, b) => this.sortFunction(a, b));
        userNames.next(this.userNameList);
        userNames.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      userNames.next(this.userNameList);
      userNames.complete();
    }
  });

  public phoneTypes = new Observable((phoneTypes) => {
    if (this.phoneTypeList.length === 0) {
      this.phoneTypesService.getAll().subscribe(data => {
        this.phoneTypeList = data
          .map(x => ({ label: x.PhoneTypeName, value: x.PhoneTypeID, disabled: !x.IsActive }))
          .sort((a, b) => this.sortFunction(a, b));
        phoneTypes.next(this.phoneTypeList);
        phoneTypes.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      phoneTypes.next(this.phoneTypeList);
      phoneTypes.complete();
    }
  });

  public emailTypes = new Observable((emailTypes) => {
    if (this.emailTypeList.length === 0) {
      this.emailTypesService.getAll().subscribe(data => {
        this.emailTypeList = data
          .map(x => ({ label: x.EmailTypeName, value: x.EmailTypeID, disabled: !x.IsActive }))
          .sort((a, b) => this.sortFunction(a, b));
        emailTypes.next(this.emailTypeList);
        emailTypes.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      emailTypes.next(this.emailTypeList);
      emailTypes.complete();
    }
  });

  public mediaTypes = new Observable((mediaTypes) => {
    if (this.mediaTypeList.length === 0) {
      this.mediaTypesService.getAll().subscribe(data => {
        this.mediaTypeList = data
          .map(x => ({ label: x.MediaTypeName, value: x.MediaTypeID, disabled: !x.IsActive }))
          .sort((a, b) => this.sortFunction(a, b));
        mediaTypes.next(this.mediaTypeList);
        mediaTypes.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      mediaTypes.next(this.mediaTypeList);
      mediaTypes.complete();
    }
  });

  public trackingCategories = new Observable((trackingCategories) => {
    if (this.trackingCategoriesList.length === 0) {
      this.categoryService.getAll().subscribe(data => {
        this.trackingCategoriesList = data
          .map(x => ({ label: x.CategoryName, value: x.TrackingCategoryID, disabled: !x.IsActive }))
          .sort((a, b) => this.sortFunction(a, b));
        trackingCategories.next(this.trackingCategoriesList);
        trackingCategories.complete;
      },
        () => this.alert.error
      );
    } else {
      trackingCategories.next(this.trackingCategoriesList);
      trackingCategories.complete();
    }
  });

  public stateProvinceCodes = new Observable((stateProvinceCodes) => {
    if (this.stateProvinceCodeList.length === 0) {
      this.stateProvinceCodeService.getAll().subscribe(data => {
        this.stateProvinceCodeList = data
          .sort((a, b) => a.StateProvinceName > b.StateProvinceName ? 1 : -1);
        stateProvinceCodes.next(this.stateProvinceCodeList);
        stateProvinceCodes.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      stateProvinceCodes.next(this.stateProvinceCodeList);
      stateProvinceCodes.complete();
    }
  });

  public airportCodes = new Observable((airportCodes) => {
    if (this.airportCodeList.length === 0) {
      this.airportsService.getAll().subscribe(data => {
        this.airportCodeList = data
          .sort((a, b) => a.AirportCode < b.AirportCode ? 1 : -1);
        airportCodes.next(this.airportCodeList);
        airportCodes.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      airportCodes.next(this.airportCodeList);
      airportCodes.complete();
    }
  });

  public countryCodes = new Observable((countryCodes) => {
    if (this.countryCodeList.length === 0) {
      this.countryCodesService.getAll().subscribe(data => {
        this.countryCodeList = data
          .map(x => ({ label: x.CountryName, value: x.CountryCodeA3, disabled: !x.IsActive }))
          .sort((a, b) => this.sortFunction(a, b));
        countryCodes.next(this.countryCodeList);
        countryCodes.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      countryCodes.next(this.countryCodeList);
      countryCodes.complete();
    }
  });


  // the following provides the TWO character country codes (used by SendIt)
  public country2Codes = new Observable((country2Codes) => {
    if (this.country2CodeList.length === 0) {
      this.countryCodesService.getAll().subscribe(data => {
        this.country2CodeList = data
          .map(x => ({ label: x.CountryName, value: x.CountryCodeA2, disabled: !x.IsActive }))
          .sort((a, b) => this.sortFunction(a, b));
        country2Codes.next(this.country2CodeList);
        country2Codes.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      country2Codes.next(this.country2CodeList);
      country2Codes.complete();
    }
  });

  public placeTypes = new Observable((placeTypes) => {
    if (this.placeTypeList.length === 0) {
      this.placeTypesService.getAll().subscribe(data => {
        this.placeTypeList = data
          .map(x => ({ label: x.PlaceType, value: x.PlaceTypeID, disabled: !x.IsActive }))
          .sort((a, b) => this.sortFunction(a, b));
        placeTypes.next(this.placeTypeList);
        placeTypes.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      placeTypes.next(this.placeTypeList);
      placeTypes.complete();
    }
  });

  public internationalTypes = new Observable((internationalTypes) => {
    if (this.internationalTypeList.length === 0) {
      this.internationalTypesService.getAll().subscribe(data => {
        this.internationalTypeList = data
          .map(x => ({ label: x.InternationalType, value: x.InternationalTypeID, disabled: !x.IsActive }))
          .sort((a, b) => this.sortFunction(a, b));
        internationalTypes.next(this.internationalTypeList);
        internationalTypes.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      internationalTypes.next(this.internationalTypeList);
      internationalTypes.complete();
    }
  });

  public serviceLevels = new Observable((serviceLevels) => {
    if (this.serviceLevelsList.length === 0) {
      this.serviceLevelsService.getAll().subscribe(data => {
        this.serviceLevelsList = data
          .map(x => ({ label: x.ServiceLevelDescription, value: x.ServiceLevelCode, disabled: !x.IsActive }))
          .sort((a, b) => this.sortFunction(a, b));
        serviceLevels.next(this.serviceLevelsList);
        serviceLevels.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      serviceLevels.next(this.serviceLevelsList);
      serviceLevels.complete();
    }
  });

  public insurers = new Observable((insurers) => {
    if (this.insurerList.length === 0) {
      this.vendorsService.getAll('IN').subscribe(data => {
        this.insurerList = data
          .map(x => ({ label: x.EntityName, value: x.EntityID, disabled: !x.IsActive }))
          .sort((a, b) => this.sortFunction(a, b));
        insurers.next(this.insurerList);
        insurers.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      insurers.next(this.insurerList);
      insurers.complete();
    }
  });

  public otherPayables = new Observable((otherPayables) => {
    if (this.otherPayableList.length === 0) {
      this.vendorsService.getAll('OT').subscribe(data => {
        this.otherPayableList = data
          .map(x => ({ label: x.EntityName, value: x.EntityID, disabled: !x.IsActive }))
          .sort((a, b) => this.sortFunction(a, b));
        otherPayables.next(this.otherPayableList);
        otherPayables.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      otherPayables.next(this.otherPayableList);
      otherPayables.complete();
    }
  });

  public salesReps = new Observable((salesReps) => {
    if (this.salesRepList.length === 0) {
      this.vendorsService.getAll('SR').subscribe(data => {
        this.salesRepList = data
          .map(x => ({ label: x.EntityName, value: x.EntityID, disabled: !x.IsActive }))
          .sort((a, b) => this.sortFunction(a, b));
        salesReps.next(this.salesRepList);
        salesReps.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      salesReps.next(this.salesRepList);
      salesReps.complete();
    }
  });

  public handlingCharges = new Observable((handlingCharges) => {
    if (this.handlingChargesList.length === 0) {
      this.handlingChargesService.getAll().subscribe(data => {
        this.handlingChargesList = data
          .map(x => ({ label: x.ServiceName, value: x.ServiceCode, disabled: !x.IsActive }))
          .sort((a, b) => this.sortFunction(a, b));
        handlingCharges.next(this.handlingChargesList);
        handlingCharges.complete();
      },
        (error) => {
          console.log(error);
          this.alert.error(error);
        }
      );
    } else {
      handlingCharges.next(this.handlingChargesList);
      handlingCharges.complete();
    }
  });

  public handlingChargesForced = new Observable((handlingChargesForced) => {
    this.handlingChargesService.getAll().subscribe(data => {
      this.handlingChargesList = data
        .map(x => ({ label: x.ServiceName, value: x.ServiceCode, disabled: !x.IsActive }))
        .sort((a, b) => this.sortFunction(a, b));
      handlingChargesForced.next(this.handlingChargesList);
      handlingChargesForced.complete();
    },
      (error) => {
        console.log(error);
        this.alert.error(error);
      }
    );
  });

  // RJS 10/9/2018
  // apparently we have no currency Code list in config?
  // so, I'm hardcoding these here at the moment
  public currencyCodes = new Observable((currencyCodes) => {
    if (this.currencyCodeList.length === 0) {
      this.currencyCodeList = [
        { value: 'USD', label: 'USD' },
        { value: 'CAD', label: 'CAD' }
      ];
      currencyCodes.next(this.currencyCodeList);
      currencyCodes.complete();
    } else {
      currencyCodes.next(this.currencyCodeList);
      currencyCodes.complete();
    }
  });

  public carrierFeeTypes = new Observable((carrierFeeTypes) => {
    if (this.feeTypeList.length === 0) {
      const carrierPayableTypeID = 1;
      this.feeTypesService.getForPayableType(carrierPayableTypeID).subscribe(data => {
        this.feeTypeList = data
          .map(x => ({ label: x.FeeTypeName, value: x.FeeTypeID, disabled: !x.IsActive }))
          .sort((a, b) => this.sortFunction(a, b));
        carrierFeeTypes.next(this.feeTypeList);
        carrierFeeTypes.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      carrierFeeTypes.next(this.feeTypeList);
      carrierFeeTypes.complete();
    }
  });

  public carrierTrackingLinks = new Observable((carrierTrackingLinks) => {
    if (this.carrierTrackingLinkList.length === 0) {
      this.carrierService.getAll().subscribe((data: audCarrierDb[]) => {
        this.carrierTrackingLinkList = data
          .map(x => ({ label: x.TrackingLink, value: x.CarrierID }));
        carrierTrackingLinks.next(this.carrierTrackingLinkList);
        carrierTrackingLinks.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      carrierTrackingLinks.next(this.carrierTrackingLinkList);
      carrierTrackingLinks.complete();
    }
  });

  public carriers = new Observable((carriers) => {
    if (this.carriersList.length === 0) {
      this.carrierService.getAll().subscribe((data: audCarrierDb[]) => {
        // console.log(data)
        this.carriersList = data
          .filter(x => x.IsActive === true)
          .map(x => ({ label: x.CarrierName, value: x.CarrierID }));
        carriers.next(this.carriersList);
        carriers.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      carriers.next(this.carriersList);
      carriers.complete();
    }
  })

  public rightTypes = new Observable((rightTypes) => {
    if (this.rightTypeList.length === 0) {
      this.rightTypesService.getAll().subscribe(data => {
        this.rightTypeList = data
          .map(x => ({ label: x.RightTypeDescription, value: x.RightTypeID }))
          .sort((a, b) => this.sortFunction(a, b));
        rightTypes.next(this.rightTypeList);
        rightTypes.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      rightTypes.next(this.rightTypeList);
      rightTypes.complete();
    }
  });

  public statuses = new Observable((statuses) => {
    if (this.statusesList.length === 0) {
      this.statusesService.getAll().subscribe(data => {
        this.statusesList = data;
        statuses.next(this.statusesList);
        statuses.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      statuses.next(this.statusesList);
      statuses.complete();
    }
  });

  public reportParameterTypes = new Observable((reportParameterTypes) => {
    if (this.reportParameterTypeList.length === 0) {
      this.reportParameterTypeService.getAll().subscribe(data => {
        this.reportParameterTypeList = data
          .sort((a, b) => a.ParameterTypeID < b.ParameterTypeID ? 1 : -1)
          .map(x => ({ label: x.Name, value: x.ParameterTypeID, disabled: false }))
        reportParameterTypes.next(this.reportParameterTypeList);
        reportParameterTypes.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      reportParameterTypes.next(this.reportParameterTypeList);
      reportParameterTypes.complete();
    }
  });


  public WorkGroups = new Observable((WorkGroups) => {
    if (this.workGroupList.length === 0) {
      this.workGroupService.getAll().subscribe(data => {
        this.workGroupList = data
          .map(x => ({ label: x.WorkGroupName, value: x.WorkGroupID, disabled: false }))
          .sort((a, b) => this.sortFunction(a, b));
        WorkGroups.next(this.workGroupList);
        WorkGroups.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      WorkGroups.next(this.workGroupList);
      WorkGroups.complete();
    }
  });

  public employeeTypes = new Observable((employeeTypes) => {
    if (this.employeeTypeList.length === 0) {
      this.employeeTypeService.getAll().subscribe(data => {
        this.employeeTypeList = data
          .map(x => ({ label: x.EmployeeTypeName, value: x.EmployeeTypeID, disabled: false }))
          .sort((a, b) => this.sortFunction(a, b));
        employeeTypes.next(this.employeeTypeList);
        employeeTypes.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      employeeTypes.next(this.employeeTypeList);
      employeeTypes.complete();
    }
  });

  public employeeTypeGroups = new Observable((employeeTypeGroups) => {
    if (this.employeeTypeGroupList.length === 0) {
      this.employeeTypeService.getAll().subscribe(data => {
        console.log('got employee types', data);
        this.employeeTypeGroupList = data
          .filter((item, i, ar) => ar.findIndex(x => x.EmployeeTypeGroup === item.EmployeeTypeGroup) === i) // remove duplicates
          .map(x => ({ label: x.EmployeeTypeGroup, value: x.EmployeeTypeGroup, disabled: false }))
          .sort((a, b) => this.sortFunction(a, b));
        employeeTypeGroups.next(this.employeeTypeGroupList);
        employeeTypeGroups.complete();
      },
        (error) => this.alert.error(error)
      );
    } else {
      employeeTypeGroups.next(this.employeeTypeGroupList);
      employeeTypeGroups.complete();
    }
  });


  // RJS 5/21/2019
  // a helper service to asynchronously return either known values, or looked-up values from DB
  public exchangeRateLookup(fromCur: string, toCur: string, onDate: string): Observable<number> {
    return new Observable((out) => {
      if (fromCur === toCur) {
        // from and to are the same; return 1.0;
        out.next(1);
      } else {
        const idx = this.exchangeRateList.findIndex(r => r.fromCur === fromCur && r.toCur === toCur && r.onDate === onDate);
        if (idx >= 0) {
          // found already in array
          out.next(this.exchangeRateList[idx].rate);
        } else {
          // not already in array, go fetch it
          this.currencyExchangeService.getCurrencyForFromToOnDate(fromCur, toCur, onDate)
            .subscribe(data => {
              const newRate = { fromCur: fromCur, toCur: toCur, onDate: onDate, rate: data.ExchangeRate };
              // console.log('onDate, data', onDate, data);
              // if (data.ExchangeDate !== new Date(onDate)) {
              //   this.alert.warn('Currency Exchange rate out of date. Using rate ' + data.ExchangeRate + ' from ' + data.ExchangeDate + '.');
              // }
              this.exchangeRateList.push(newRate);
              out.next(data.ExchangeRate); // and return it
            },
              () => {
                out.error('Exchange Rate for ' + fromCur + ' to ' + toCur + ' on ' + onDate + ' not found. Defaulting to 1.0');
              });
        }
        out.complete();
      }
    });
  }

  formatDate(inDate: Date): string {
    return inDate.getFullYear() + '-' + (inDate.getMonth() + 1) + '-' + inDate.getDate();
  }

  countrySort(a, b) {
    if (a.SortOrder < b.SortOrder) { return -1; }
    if (a.SortOrder > b.SortOrder) { return 1; }
    // if they're equal...
    if (a.CountryName < b.CountryName) { return -1; }
    if (a.CountryName > b.CountryName) { return 1; }
    return 0;
  }

  clearList(listType: LookupListType) {
    switch (listType) {
      case (LookupListType.ActivityTypeList):
        this.activityTypeList = [];
        break;
      case (LookupListType.AirportCodeList):
        this.airportCodeList = [];
        break;
      case (LookupListType.SendItPaymentTypeList):
        this.senditPaymentTypesList = [];
        break;
      case (LookupListType.AssociationType):
        this.associationTypeList = [];
        break;
      // case (LookupListType.BillingTermList):
      //   this.billingTermList = [];
      //   break;
      // case (LookupListType.BillingTypeList):
      //   this.billingTypeList = [];
      //   break;
      case (LookupListType.BillTypeList):
        this.billTypeList = [];
        break;
      case (LookupListType.CarrierTrackingList):
        this.carrierTrackingLinkList = [];
        break;
      case (LookupListType.ClientRuleList):
        this.clientRuleList = [];
        break;
      case (LookupListType.CurrencyCodeList):
        this.countryCodeList = [];
        break;
      case (LookupListType.CurrencyCodeList):
        this.currencyCodeList = [];
        break;
      case (LookupListType.DepartmentList):
        this.departmentList = [];
        break;
      case (LookupListType.EmailTypeList):
        this.emailTypeList = [];
        break;
      case (LookupListType.EmployeeTypeList):
        this.employeeTypeList = [];
        break;
      case (LookupListType.EmployeeTypeGroupList):
        this.employeeTypeGroupList = [];
        break;
      case (LookupListType.ExchangeRateList):
        this.exchangeRateList = [];
        break;
      case (LookupListType.FeeTypeList):
        this.feeTypeList = [];
        break;
      case (LookupListType.ForwarderList):
        this.forwarderList = [];
        break;
      case (LookupListType.FunctionGroupList):
        this.functionGroupList = [];
        break;
      case (LookupListType.HandlingChargesList):
        this.handlingChargesList = [];
        break;
      case (LookupListType.InsurerList):
        this.insurerList = [];
        break;
      case (LookupListType.InternationalTypeList):
        this.internationalTypeList = [];
        break;
      case (LookupListType.MediaTypeList):
        this.mediaTypeList = [];
        break;
      case (LookupListType.NmfcNumberList):
        this.nmfcNumberList = [];
        break;
      case (LookupListType.NoteTypeList):
        this.noteTypeList = [];
        break;
      case (LookupListType.OrderHoldsList):
        this.orderHoldsList = [];
        break;
      case (LookupListType.OtherPayableList):
        this.otherPayableList = [];
        break;
      case (LookupListType.PaymentTermsList):
        this.paymentTermsList = [];
        break;
      case (LookupListType.PaymentTypeList):
        this.paymentTypeList = [];
        break;
      case (LookupListType.PayrollTypeList):
        this.payrollTypeList = [];
        break;
      case (LookupListType.PayrollTypeGroupList):
        this.payrollTypeGroupList = [];
        break;
      case (LookupListType.PhoneTypeList):
        this.phoneTypeList = [];
        break;
      case (LookupListType.PieceTypeList):
        this.pieceTypeList = [];
        break;
      case (LookupListType.PlaceTypeList):
        this.placeTypeList = [];
        break;
      case (LookupListType.ProDocActorList):
        this.proDocActorList = [];
        break;
      case (LookupListType.LanguageList):
        this.languageList = [];
        break;
      case (LookupListType.ReportParameterTypeList):
        this.reportParameterTypeList = [];
        break;
      case (LookupListType.RightTypeList):
        this.rightTypeList = [];
        break;
      case (LookupListType.SalesRepList):
        this.salesRepList = [];
        break;
      case (LookupListType.ScheduleTypeList):
        this.scheduleTypeList = [];
        break;
      case (LookupListType.ServiceLevelsList):
        this.serviceLevelsList = [];
        break;
      case (LookupListType.ServiceProcessesList):
        this.serviceProcessesList = [];
        break;
      case (LookupListType.ServiceUnitsList):
        this.serviceUnitsList = [];
        break;
      case (LookupListType.ShipmentTypeList):
        this.shipmentTypeList = [];
        break;
      case (LookupListType.StateProvinceCodeList):
        this.stateProvinceCodeList = [];
        break;
      case (LookupListType.StatusesList):
        this.statusesList = [];
        break;
      case (LookupListType.TerminationReasonsList):
        this.terminationReasonList = [];
        break;
      case (LookupListType.TrackingCategoryList):
        this.trackingCategoriesList = [];
        break;
      case (LookupListType.UserNameList):
        this.userNameList = [];
        break;
      case (LookupListType.WarehouseList):
        this.warehouseList = [];
        break;
      case (LookupListType.WarehouserList):
        this.warehouserList = [];
        break;
      case (LookupListType.WorkStatusList):
        this.workStatusList = [];
        break;
      case (LookupListType.WorkGroupList):
        this.workGroupList = [];
        break;
    }

  }
  clearAll() {
    this.activityTypeList = [];
    this.airportCodeList = [];
    this.associationTypeList = [];
    //this.billingTermList = [];
    //this.billingTypeList = [];
    this.billTypeList = [];
    this.carrierTrackingLinkList = [];
    this.clientRuleList = [];
    this.countryCodeList = [];
    this.country2CodeList = [];
    this.currencyCodeList = [];
    this.departmentList = [];
    this.emailTypeList = [];
    this.employeeTypeList = [];
    this.employeeTypeGroupList = [];
    this.exchangeRateList = [];
    this.feeTypeList = [];
    this.forwarderList = [];
    this.functionGroupList = [];
    this.handlingChargesList = [];
    this.insurerList = [];
    this.internationalTypeList = [];
    this.mediaTypeList = [];
    this.nmfcNumberList = [];
    this.noteTypeList = [];
    this.orderHoldsList = [];
    this.otherPayableList = [];
    this.paymentTermsList = [];
    this.paymentTypeList = [];
    this.payrollTypeList = [];
    this.payrollTypeGroupList = [];
    this.phoneTypeList = [];
    this.pieceTypeList = [];
    this.placeTypeList = [];
    this.proDocActorList = [];
    this.reportParameterTypeList = [];
    this.rightTypeList = [];
    this.salesRepList = [];
    this.scheduleTypeList = [];
    this.serviceLevelsList = [];
    this.serviceProcessesList = [];
    this.serviceUnitsList = [];
    this.shipmentTypeList = [];
    this.stateProvinceCodeList = [];
    this.statusesList = [];
    this.terminationReasonList = [];
    this.trackingCategoriesList = [];
    this.userNameList = [];
    this.warehouseList = [];
    this.warehouserList = [];
    this.workStatusList = [];
    this.workGroupList = [];
    this.senditPaymentTypesList = []
  }

  sortFunction(a, b) {
    if (a.disabled === b.disabled) {
      return (a.label < b.label) ? -1 : 1;
    } else {
      return (a.disabled < b.disabled) ? -1 : 1;
    }
  }
}

