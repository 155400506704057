<p-table [value]="actors">
    <ng-template pTemplate="header">
        <tr>
            <th style="width:3em"></th>
            <th pSortableColumn="PersonName" [ngStyle]="{ 'width': '25%', 'text-align': 'left' }">
                Name
                <span>
                    <p-sortIcon field="PersonName"></p-sortIcon>
                </span>
            </th>
            <th pSortableColumn="RoleName" [ngStyle]="{ 'width': '25%', 'text-align': 'left' }">
                Role
                <span>
                    <p-sortIcon field="RoleName"></p-sortIcon>
                </span>
            </th>
            <th pSortableColumn="ActionDueDate" [ngStyle]="{ 'width': '25%', 'text-align': 'left' }">
                Due
                <span>
                    <p-sortIcon field="ActionDueDate"></p-sortIcon>
                </span>
            </th>
            <th pSortableColumn="ActionCompletedDate" [ngStyle]="{ 'width': '25%', 'text-align': 'left' }">
                Completed
                <span>
                    <p-sortIcon field="ActionCompletedDate"></p-sortIcon>
                </span>
            </th>
            <th style="width:3em; text-align:center;">
                Complete
            </th>
            <th style="width:3em"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr>
            <td style="text-align:center;">
                <button pButton icon="fa fa-edit" *ngIf="isOwnerOrAdmin()" (click)="editItem(item)"  pTooltip="{{canUseTooltip}}" [ngClass]="isArchivedOrPublished() ? 'p-button-secondary' : 'p-button-primary'"></button>
            </td>
            <td>
                {{ item.PersonName }}
            </td>
            <td>
                {{ item.RoleName }}
            </td>
            <td>
                {{ item.ActionDueDate | date: 'mediumDate' }}
            </td>
            <td>
                {{ item.ActionCompletedDate | date: 'mediumDate' }}
            </td>
            <td style="text-align:center;">
                <button pButton icon="fa fa-check-circle" *ngIf="!item.ActionCompletedDate"  pTooltip="{{canUseTooltip}}" [ngClass]="isArchivedOrPublished() ? 'p-button-secondary' : 'p-button-primary'"
                    [disabled]="!item.CanComplete" (click)="toggleComplete(item)"></button>
                <button pButton class="p-button-success" icon="fa fa-check-circle" *ngIf="item.ActionCompletedDate"  pTooltip="{{canUseTooltip}}" [ngClass]="isArchivedOrPublished() ? 'p-button-secondary' : 'p-button-primary'"
                    [disabled]="!item.CanComplete" (click)="toggleComplete(item)"></button>
            </td>
            <td style="text-align:center;">
                <button pButton icon="fa fa-trash-o" *ngIf="isOwnerOrAdmin()"  pTooltip="{{canUseTooltip}}" [ngClass]="isArchivedOrPublished() ? 'p-button-secondary' : 'p-button-primary'" (click)="confirmDelete(item)"></button>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="summary" *ngIf="isOwnerOrAdmin()">
        <div class="summary">
            <button pButton icon="pi pi-plus" label="Add New" (click)="addNew()" pTooltip="{{canUseTooltip}}" [ngClass]="versionStatus === versionStatusTypes.Archived ? 'p-button-secondary' : 'p-button-primary'"></button>
        </div>
    </ng-template>
</p-table>
<p-dialog header="Actor" [(visible)]="showEditDialog" *ngIf="showEditDialog" [modal]="true" [responsive]="true"
    [style]="{width: '600px', minWidth: '600px'}" [minY]="100" [maximizable]="false" [closable]="false"
    [closeOnEscape]="false">
    <arc-actor-edit [actorID]="selectedActorID" [versionID]="versionID" [versionStatus]="versionStatus" (editComplete)="editComplete($event)">
    </arc-actor-edit>
</p-dialog>