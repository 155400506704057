
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

 import { orgEntityPerson } from '../_models/ORG.model';
import { OrgItemType } from '../_models/enums.model';

@Injectable()
export class EntityPersonService {

//     api = 'orgEntityPerson/';

//     constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

//     getAllForItem(itemType: OrgItemType, itemID: number) {
//         return this.http.get<orgEntityPerson[]>(this.apiURL + this.api + itemType + '/' + itemID);
//     }

//     getByID(id: number) {
//         return this.http.get<orgEntityPerson>(this.apiURL + this.api + id);
//     }

//     getAll() {
//         return this.http.get<orgEntityPerson[]>(this.apiURL + this.api);

//     }
//     create(person: orgEntityPerson) {
//         return this.http.post(this.apiURL + this.api, person);
//     }

//     update(person: orgEntityPerson) {
//         return this.http
//             .put(this.apiURL + this.api + person.PersonID, person);
//     }

//     delete(id: number) {
//         return this.http.delete(this.apiURL + this.api + id);
//     }

}

