
import { Injectable, Inject } from '@angular/core';
import { utlExchangeRate } from '../_models/UTL.model';

// // import { server } from '../_models/index';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CurrencyExchangeService {


  api = 'utlExchangeRate/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<utlExchangeRate[]>(this.apiURL + this.api);

  }

  getCurrencies() {
    return this.http.get<any[]>(this.apiURL + this.api + 'Currencies/');
  }

  create(ExchangeRate: utlExchangeRate) {
    return this.http.post(this.apiURL + this.api, ExchangeRate);
  }

  update(ExchangeRate: utlExchangeRate) {
    return this.http
      .put(this.apiURL + this.api + ExchangeRate.ExchangeRateID, ExchangeRate);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getCurrencyForFromToOnDate(FromCode: string, ToCode: string, OnDate: string) {
    return this.http.get<utlExchangeRate>(this.apiURL + this.api + FromCode + '/' + ToCode + '/' + OnDate);
  }

  getCurrencyExchangeForDate(ToCode: string, OnDate: string) {
    // should return a list of all currency exchange values known to DB for the ToCode on the OnDate
    return this.http.get<utlExchangeRate[]>(this.apiURL + this.api + 'All/' + ToCode + '/' + OnDate);
  }
}
