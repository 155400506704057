import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { timeEmployeeService } from 'app/_data/time.service';
import { UsersService } from 'app/_data/users.service';
import { UpsertRet } from 'app/_models/API.model';
import { secPasswordUpdate, secUser, secUserValidations } from 'app/_models/SEC.model';
import { timeEmployee, timeEmployeePINUpdate } from 'app/_models/TIME.model';
import { AlertService, AuthService } from 'app/_services';

@Component({
  selector: 'arc-change-pass-pin',
  templateUrl: './change-pass-pin.component.html',
  styleUrls: ['./change-pass-pin.component.scss']
})
export class ChangePassPinComponent implements OnInit {

  @Input() changePIN: boolean;
  @Input() changePass: boolean;
  @Input() selectedValidation: secUserValidations;
  @Output() passSuccess = new EventEmitter();
  @Output() pinSuccess = new EventEmitter();

  oldPIN: string = '';
  newPIN: string = '';
  confirmNewPIN: string = '';
  currentPINErr: boolean = false;

  oldPass: string = '';
  newPass: string = '';
  confirmNewPass: string = '';
  currentPassErr: boolean = false;

  userID: number = null;

  constructor(
    private employeeService: timeEmployeeService,
    private usersService: UsersService,
    private authService: AuthService,
    private alert: AlertService
  ) { }

  ngOnInit(): void {
    this.userID = this.authService.currentUser.UserID;
  }

  changePassword() {
    let passPayload = new secPasswordUpdate();
    passPayload.OldPassword = this.oldPass;
    passPayload.NewPassword = this.newPass;

    this.usersService.changePassword(this.userID, passPayload).subscribe((data: UpsertRet) => {
      console.log(data);
      if (data.returnItem !== null) {
        this.passSuccess.emit();
      }
    }, err => {
      this.alert.error('Error Changing Password: ' + err);
      console.log(err);
    })
  }

  checkPassword() {
    let passPayload = new secPasswordUpdate();
    passPayload.OldPassword = this.oldPass;

    this.usersService.checkPassword(this.userID, passPayload).subscribe((data: UpsertRet) => {
      if (data.returnItem === false)
        this.currentPassErr = true;
      else
        this.currentPassErr = false;
    }, err => {
      this.alert.error('Error Checking Password');
      console.log(err);
    });
  }

  changeUserPIN() { 
    let pinPayload = new timeEmployeePINUpdate();
    pinPayload.OldPIN = this.oldPIN;
    pinPayload.NewPIN = this.newPIN;

    this.employeeService.changePIN(this.userID, pinPayload).subscribe((data: UpsertRet) => {
      if (data.returnItem !== null) {
        this.pinSuccess.emit();
      }
    }, err => {
      this.alert.error('Error Changing PIN: ' + err);
      console.log(err);
    })
  }

  checkPIN() {
    let pinPayload = new timeEmployeePINUpdate();
    pinPayload.OldPIN = this.oldPIN;

    this.employeeService.checkPIN(this.userID, pinPayload).subscribe((data: UpsertRet) => {
      if (data.returnItem === false)
        this.currentPINErr = true;
      else 
        this.currentPINErr = false;
    }, err => {
      this.alert.error('Error Checking PIN');
      console.log(err);
    })
    
  }
}
