import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import { chgServiceCode } from '../_models/CHG.model';

import { HttpClient } from '@angular/common/http';

@Injectable()
export class HandlingChargesService {

  api = 'chgServiceCode/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<chgServiceCode[]>(this.apiURL + this.api);

  }
  create(ServiceCode: chgServiceCode) {
    return this.http.post(this.apiURL + this.api, ServiceCode);
  }

  update(ServiceCode: chgServiceCode) {
    return this.http
      .put(this.apiURL + this.api + ServiceCode.ServiceCodeID, ServiceCode);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }
}


