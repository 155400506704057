<div style="display: flex; justify-content: center; margin-top: 10px;">
    <div
        style="background-color: #fbfbfb; text-align: center; margin-bottom: 10px; padding: 15px; width: 70%; border-radius: 10px;">
        <h1>Trigger</h1>
        <!-- <p style="color: rgb(49, 49, 49); padding-bottom: 10px;">Each trigger is designed to be written as a story</p> -->
        <!-- <p style="color: rgb(49, 49, 49); padding-bottom: 10px;">{{ document?.Title }}</p> -->
        <h3>{{ document?.Title }}</h3>
    </div>
</div>
<form #formTrigger="ngForm">
    <div style="display: flex; justify-content: center;">
        <!-- <div class="alert" *ngIf="errors.length > 0">
            <div *ngFor="let item of errors">
                {{ item }}
            </div>
        </div> -->
        <div class="trigger-edit-body">
            <div class="p-grid p-fluid" style=" display: flex; align-items: center; padding: 25px;">
                <div *ngIf="trigger" style="display: flex; width: 100%;">
                    <div style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
                        <div style="text-align: center; width: 25%;">
                            <span
                                style="margin-right: 25px; font-size: 20px; background-color: rgb(179, 179, 179); border-radius: 10px; padding: 5px;">Trigger Name:</span>
                        </div>
                        <div style="width: 60%;">
                            <input type="text" pInputText [(ngModel)]="trigger.TriggerName" [ngClass]="{'invalidInput': triggerName.touched && triggerName.invalid}"
                                placeholder="Enter name for trigger" name="triggerName" id="triggerName"
                                #triggerName="ngModel" [style]="{'width':'80%', 'padding':'10px'}" required>
                        </div>
                        <div style="width:15%">
                            <p-checkbox name="isActive" label="Active" [(ngModel)]="trigger.IsActive" inputId="isActive" [binary]="true"></p-checkbox>
                        </div>
                    </div>
                    <div class="p-inputgroup" style="padding: 5px;">
                        <span
                            style="margin-right: 25px; display: flex; align-items: center; font-size: 20px; background-color: rgb(179, 179, 179); border-radius: 10px; padding: 5px;">Trigger
                            Type: </span>
                        <p-selectButton name="triggerTypes" [options]="triggerTypes" optionLabel="label"
                            optionValue="value" [ngStyle]="{'width': '500px', 'align-self':'center'}"
                            [(ngModel)]="trigger.TriggerTypeID" [disabled]="trigger.TriggerID > 0"
                            (onChange)="selectTriggerType()">
                        </p-selectButton>
                    </div>
                </div>
            </div>
            <div class="p-grid p-fluid" *ngIf="trigger" style=" display: flex; align-items: center;">
                <!---------------------------------------------->
                <div class="p-col-4" style="text-align:right; font-weight: bolder;">
                    <span>Assign this document to employees...</span>
                </div>
                <div class="p-col-9 p-offset-1" style="text-align:right">
                </div>
                <!--------- Person ------------------------------------->
                <div class="p-col-4" style="text-align:right" *ngIf="trigger?.TriggerTypeID === TriggerType.Person">
                    <span>in this list: </span>
                </div>
                <div class="p-col-8" *ngIf="trigger?.TriggerTypeID === TriggerType.Person">
                    <div style="width: 90%" [ngClass]="{ 'autocompleteInvalid': personSelect.touched && personSelect.invalid }">

                        <p-autoComplete [(ngModel)]="personsSelected" [suggestions]="personSuggs" field="LastName"
                            (completeMethod)="searchPerson($event)" [style]="{'width':'100%'}" [multiple]="true"
                            name="personSelect" #personSelect="ngModel" required>
                        </p-autoComplete>

                    </div>
                </div>
                <!----- Departments ----------------------------------------->
                <div class="p-col-4" style="text-align:right" *ngIf="trigger?.TriggerTypeID === TriggerType.Department">
                    <span>in any of these departments: </span>
                </div>
                <div class="p-col-8" *ngIf="trigger?.TriggerTypeID === TriggerType.Department">
                    <div style="width: 90%" [ngClass]="{ 'autocompleteInvalid':departmentSelect.touched && departmentSelect.invalid }">

                        <p-autoComplete [(ngModel)]="departmentsSelected" [suggestions]="departmentSuggs" field="DepartmentName" 
                            (completeMethod)="searchDepartment($event)" [style]="{'width':'100%'}" [multiple]="true" 
                            name="departmentSelect" #departmentSelect="ngModel" required>
                        </p-autoComplete>

                    </div>
                </div>
                <!-------- Functions -------------------------------------->
                <div class="p-col-4" style="text-align:right" *ngIf="trigger?.TriggerTypeID === TriggerType.Function">
                    <span>who have used any of these functions </span>
                </div>
                <div class="p-col-8" *ngIf="trigger?.TriggerTypeID === TriggerType.Function">
                    <div style="width: 90%;" [ngClass]="{ 'autocompleteInvalid':functionSelect.touched && functionSelect.invalid }">

                        <p-autoComplete [(ngModel)]="functionsSelected" [suggestions]="functionSuggs" field="FunctionName"
                            (completeMethod)="searchFunction($event)" [style]="{'width':'100%'}" [multiple]="true"
                            name="functionSelect" #functionSelect="ngModel" required>
                        </p-autoComplete>

                    </div>
                </div>
                <!---------------------------------------------->
                <div class="p-col-4" style="text-align:right" *ngIf="trigger?.TriggerTypeID === TriggerType.Function">
                    <span>for at least </span>
                </div>
                <div class="p-col-8" *ngIf="trigger?.TriggerTypeID === TriggerType.Function">
                    <input type="pInputNumber" arcNumericInput [(ngModel)]="trigger.UsageMinutes" style="width: 10%; padding: 5px;"
                        name="UsageMinutes" #UsageMinutes="ngModel" required [ngClass]="{'invalidInput': UsageMinutes.touched && UsageMinutes.invalid}">
                    <span> minutes</span>
                </div>
                <!---------------------------------------------->
                <div class="p-col-4" style="text-align:right" *ngIf="trigger?.TriggerTypeID === TriggerType.Function">
                    <span>in the past </span>
                </div>
                <div class="p-col-8" *ngIf="trigger?.TriggerTypeID === TriggerType.Function">
                    <input type="pInputNumber" arcNumericInput [(ngModel)]="trigger.UsageWindow" style="width: 10%; padding: 5px;"
                        name="usageWindow" #usageWindow="ngModel" required [ngClass]="{'invalidInput': usageWindow.touched && usageWindow.invalid}">
                    <span> work days</span>
                </div>
                <!--------- Warehouses --------------------------------->
                <div class="p-col-4" style="text-align:right" *ngIf="trigger?.TriggerTypeID === TriggerType.Function || trigger?.TriggerTypeID === TriggerType.Warehouse">
                    who work at
                    <p-radioButton name="WhOpts" value="WhAny" label="any" [(ngModel)]="WhValue"></p-radioButton>
                    <p-radioButton name="WhOpts" value="WhList" label="these" [(ngModel)]="WhValue"></p-radioButton>
                    warehouses
                </div>
                <div class="p-col-8" *ngIf="trigger?.TriggerTypeID === TriggerType.Function || trigger?.TriggerTypeID === TriggerType.Warehouse">
                    <div *ngIf="WhValue !== 'WhAny'" style="width: 90%;" [ngClass]="{'autocompleteInvalid': warehouseSelect.touched && warehouseSelect.invalid}" >
                            <p-autoComplete name="warehouseSelect" [(ngModel)]="warehousesSelected"
                                [suggestions]="warehouseSuggs" field="WarehouseCode" (completeMethod)="searchWarehouse($event)"
                                [style]="{'width':'100%'}" [multiple]="true" [hidden]="WhValue === 'WhAny'"
                                #warehouseSelect="ngModel" required="WhValue !== 'WhAny'">
                            </p-autoComplete>
                            
                    </div>
                </div>
                <!---------- Clients -------------------------------->
                <div class="p-col-4" style="text-align:right" *ngIf="trigger?.TriggerTypeID === TriggerType.Function">
                    for
                    <p-radioButton name="ClOpts" value="ClAny" label="any" [(ngModel)]="ClValue">
                    </p-radioButton>
                    <p-radioButton name="ClOpts" value="ClList" label="these" [(ngModel)]="ClValue">
                    </p-radioButton>
                    clients
                </div>
                <div class="p-col-8" *ngIf="trigger?.TriggerTypeID === TriggerType.Function">
                    <div *ngIf="ClValue !== 'ClAny'" style="width: 90%" [ngClass]="{'autocompleteInvalid': clientSelect.touched && clientSelect.invalid}">
                        <p-autoComplete name="clientSelect" [(ngModel)]="clientsSelected" [suggestions]="clientSuggs"
                        field="ShortName" (completeMethod)="searchClient($event)" [style]="{'width':'100%'}"
                        [multiple]="true" [hidden]="ClValue === 'ClAny'" #clientSelect="ngModel"
                        required="ClValue !== 'ClAny'">
                        </p-autoComplete>
                    </div>
                    <!-- <p-multiSelect [options]="clientList" [(ngModel)]="trigger.Clients" optionLabel="ShortName"
                    optionValue="EntityID" [style]="{'width':'80%'}" [panelStyle]="{maxWidth:'80%'}"></p-multiSelect> -->
                </div>
                <!---------------------------------------------->
                <div class="p-col-4" style="text-align:right">
                    <span>who have not completed the assignment in the past </span>
                </div>
                <div class="p-col-8">
                    <input type="pInputNumber" arcNumericInput [(ngModel)]="trigger.AssignFrequencyDays"
                        style="width: 10%; padding: 5px;" name="frequencyDays" #frequencyDays="ngModel" required
                        [ngClass]="{'invalidInput': frequencyDays.touched && frequencyDays.invalid}">
                    <span> calendar days</span>
                </div>
                <!---------------------------------------------->
                <div class="p-col-4" style="text-align:right">
                    <span>with a 'Soft Due Date' in </span>
                </div>
                <div class="p-col-8">
                    <input type="pInputNumber" arcNumericInput [(ngModel)]="trigger.SoftDueInDays" style="width: 10%; padding: 5px;"
                        name="softDue" #softDue="ngModel" required  [ngClass]="{'invalidInput': softDue.touched && softDue.invalid}">
                    <span> work days</span>
                </div>
                <!---------------------------------------------->
                <div class="p-col-4" style="text-align:right">
                    <span>and a 'Hard Due Date' in </span>
                </div>
                <div class="p-col-8">
                    <input type="pInputNumber" arcNumericInput [(ngModel)]="trigger.HardDueInDays" style="width: 10%; padding: 5px;"
                        name="hardDue" #hardDue="ngModel" required  [ngClass]="{'invalidInput': hardDue.touched && hardDue.invalid}">
                    <span> work days.</span>
                </div>
                <!---------------------------------------------->
            </div>
        </div>
    </div>
    <br>
    <div class="footer">
        <div class="footerBody">
            <button pButton icon="pi pi-ban" label="Cancel Changes" (click)="cancelEdit()"></button>
            <button pButton icon="pi pi-save" label="Save Trigger" (click)="saveTrigger()"
                [disabled]="formTrigger.invalid">
            </button>
        </div>
    </div>
</form>