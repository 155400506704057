import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ReportOptionsService, ReportsService, ReportViewHistoryService } from '../../_data/reports.service';
import { AuthService, AlertService } from '../../_services';
import { rptReportEx, rptReportParameterEx, rptReportViewHistoryDb } from '../../_models/RPT.model';
import { ReportEnvironmentType, ReportParameterType, IDType } from '../../_models/enums.model';
import { environment } from '../../../environments/environment';
import { SelectItem } from 'primeng/api';
import { formatDate } from '@angular/common';
import { UtilityService } from '../../_services/utility.service';
import { reportParam } from '../../_models/UTL.model';
import { ReportingService } from '../../_data/reporting.service';
import { NotifierService } from '../../_sharedData/notifier.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'arc-report-display',
  templateUrl: './report-display.component.html',
  styleUrls: ['./report-display.component.scss']
})
export class ReportDisplayComponent implements OnInit {

  @Output() displayComplete = new EventEmitter<number>();

  @Input() reportID: number;

  categoryList: SelectItem[] = [];
  selectedCategoryID: number;
  selectedCategoryName = '';
  reportList: rptReportEx[] = [];
  selectedReport: rptReportEx;
  selectedReportID: number;

  dateRangeError = false;
  outputOptions: any[] = [];
  selectedOutput: any;

  env = environment;

  searchText = '';
  show = 'list';

  envType: ReportEnvironmentType;
  parmType: ReportParameterType;
  parmDisplay: string = '';

  ReportParameterType = ReportParameterType;

  constructor(
    private options: ReportOptionsService,
    private alert: AlertService,
    private reports: ReportsService,
    private reportHistory: ReportViewHistoryService,
    private auth: AuthService,
    private reporting: ReportingService,
    private notifier: NotifierService,
    private utility: UtilityService
  ) { }

  ngOnInit() {

    this.envType = ReportEnvironmentType.Internal;
    this.doSelectReportID(this.reportID);

    this.outputOptions = [
      { name: 'PDF', logo: 'icons8-pdf-50.png' },
      { name: 'Excel', logo: 'icons8-microsoft-excel-50.png' },
      { name: 'CSV', logo: 'icons8-csv-50.png' },
      { name: 'XML', logo: 'icons8-xml-file-50.png' },
      { name: 'Word', logo: 'icons8-microsoft-word-50.png' }
    ];

    this.selectedOutput = this.outputOptions[0];
  }

  doSelectReportID(reportID: number) {
    this.reports.getExpanded(reportID)
      .subscribe(
        (data: rptReportEx) => {
          this.selectedReport = data;

          // 7/14/23 RJS: if a client is running this report... (WrikeID 1157346470)
          if (this.auth.currentUser.curEntity.EntityID != 0) {
            // filter out those that are client omit, keeping only those that are
            this.selectedReport.Parameters = this.selectedReport.Parameters.filter(p => !p.ClientOmit);
            // and put IsClientID parameter (if any) first in list (if a client is running the report)
            var ICI = this.selectedReport.Parameters.find(p => p.IsClientID);
            if (ICI) {
              ICI.DisplayOrder = -1;
              // now re-sort by DisplayOrder  
              this.selectedReport.Parameters.sort((a, b) => (a.DisplayOrder > b.DisplayOrder) ? 1 : -1);
            }
          }
          // --------------------------------------
          this.selectedReport.Parameters.forEach(p => {
            if (p.AllowNull === false) {
              switch (p.ParameterTypeID) {
                case ReportParameterType.DropDownList:
                case ReportParameterType.RadioButtonList:
                  if (p.Options.SelectOptions && (p.Options.SelectOptions.length > 0)) {
                    p.ParameterValue = p.Options.SelectOptions[0].value || null;
                  }
                  break;
                case ReportParameterType.TextBox:
                  p.ParameterValue = '';
                  break;
              }
            }
            // if this is a client report, and the parameter is a client ID, set the value to the current client
            // Do this AFTER the adjustments for AllowNull above, so that the default value is not overwritten
          
            if ((p.IsClientID) && (this.auth.currentUser.curEntity.EntityID != 0)) {
              console.log('checking values ', p.ParameterValue, this.auth.currentUser.curEntity.EntityID, p.Options.SelectOptions);
              p.ParameterValue = +this.auth.currentUser.curEntity.EntityID;
              console.log('now look for ', p.ParameterValue);
              p.ParameterDisplayValue = p.Options.SelectOptions.find(o => +o.value === p.ParameterValue)?.label || 'Unknown Client';
            }
            if ((p.ClientOmit) && (this.auth.currentUser.curEntity.EntityID != 0)) {
              p.DoNotPass = true;
            }
            switch (p.ParameterTypeID) {
              case ReportParameterType.CheckBox:
                p.ParameterValue = false;
                break;
              case ReportParameterType.TriCheckBox:
                p.ParameterValue = null;
                break;
              //default: // 7/11/2022 RJS:  DON'T SET THIS or the values above will ALSO get set BACK to NULL
              //  p.ParameterValue = null;
            }

            // furthermore, get a handle to any dependent parameters
            if (p.DependentOnParameterIDs) {
              p.Dependents = [];
              const deps = p.DependentOnParameterIDs.split('|');
              deps.forEach(dp => {
                const parmHandle = this.selectedReport.Parameters.find(q => q.ParameterID === +dp);
                if (parmHandle) {
                  p.Dependents.push(parmHandle);
                }
              });
            }

          });

          this.selectedReport.Parameters.forEach(p => {
            // now, for each paramter, check Cascade to see if any defaults are handled
            this.doCheckCascade(p.ParameterID, p.DisplayOrder);
          });
        },
        error => this.alert.error(error)
      );
  }

  doCheckCascade(parmID: number, displayOrder: number) {
    // see if this is a dependency of any other subsequent parameter
    const laterParms = this.selectedReport.Parameters.filter(r => r.DisplayOrder > displayOrder);
    laterParms.forEach(laterParm => {
      if (laterParm.Dependents && laterParm.Dependents.length > 0 && (laterParm.Dependents.findIndex(d => d.ParameterID === parmID) >= 0)) {
        // find out if there are any dependencies of this one that still have a null value

        // ! LOOK HERE

        const emptyDependent = laterParm.Dependents.findIndex(cp => (cp.ParameterValue === null));
        if (emptyDependent < 0) { // none found, so all parameters are supplied
          const useParms: string[] = []; // get a list
          laterParm.Dependents.forEach(d => {
            // add each value to the list
            useParms.push(d.ParameterValue.toString());
          });
          // now, call the method to get the new parms
          this.options.getWithParms(laterParm.OptionsID, useParms, laterParm.AllowNull)
            .subscribe(
              (retData: SelectItem[]) => {
                // and assign them to that parameter's SelectOptions
                laterParm.Options.SelectOptions = retData;
              },
              (error) => this.alert.error(error)
            );
        }
      }
    });
  }

  checkClearText(prm: rptReportParameterEx) {
    if (prm.ParameterValue == '' && prm.AllowNull) {
      prm.ParameterValue = null;
    }
  }

  // doValidateInput(): boolean {
  //   let retVal = true;
  //   this.selectedReport.Parameters.forEach( p => {
  //     if (p.ParameterTypeID === ReportParameterType.DateRangePicker) {
  //       if (!this.doCheckDateRange(p.ParameterValue, 'validation')) {
  //         retVal = false;
  //       }
  //     }
  //   });
  //   return retVal;
  // }

  doCreateReport() {

    // if (!this.doValidateInput()) { return }

    const parms = []; // String = '';
    this.selectedReport.Parameters.forEach(p => {
      console.log('parameter', p);
      // if not flagged as DoNotPass, or if it is flagged as ClientOmit and the current entity is not Propack (EntityID = 0)
      if ((!p.DoNotPass) || (p.ClientOmit && this.auth.currentUser.curEntity.EntityID != 0)) {
        if (!(p.AllowNull && (p.ParameterValue === null))) {
          switch (p.ParameterTypeID) {
            case this.ReportParameterType.CheckBox:
            case this.ReportParameterType.TextBox:
            case this.ReportParameterType.DatePicker:
            case this.ReportParameterType.DropDownList:
            case this.ReportParameterType.RadioButtonList:
              parms.push({ name: p.SSRSName, value: p.ParameterValue }); //  += ('&' + p.SSRSName + '=' + p.ParameterValue);
              break;
            case this.ReportParameterType.DateTimePicker: // fix the time
              parms.push({ name: p.SSRSName, value: formatDate(p.ParameterValue, 'MM/dd/yyyy hh:mm a', 'en') });
              break;
            case this.ReportParameterType.TriCheckBox:
              if (p.ParameterValue !== null) {
                parms.push({ name: p.SSRSName, value: p.ParameterValue }); //  += ('&' + p.SSRSName + '=' + p.ParameterValue);
              }
              break;
            case this.ReportParameterType.DateRangePicker:
              const fldList = p.SSRSName.split('|');
              if (p.ParameterValue[0] !== null) {
                parms.push({ name: fldList[0], value: formatDate(p.ParameterValue[0], 'MM/dd/yyyy', 'en-US') }); //  += ('&' + p.SSRSName + '=' + p.ParameterValue);
              }
              if (p.ParameterValue[1] !== null) {
                parms.push({ name: fldList[1], value: formatDate(p.ParameterValue[1], 'MM/dd/yyyy', 'en-US') }); //  += ('&' + p.SSRSName + '=' + p.ParameterValue);
              }
              break;
          }
        }
      }
    });

    this.doPopupReport(this.selectedReport.ReportID, this.selectedReport.SSRSName, parms, this.selectedOutput.name);

  }

  doPopupReport(reportID: number, reportName: string, parms: reportParam[], output: string = this.env.SSRSOutput) {
    /// doPopupReport(reportName: string, parms: reportParam[], output: string = this.env.SSRSOutput) {

    //     const SSRSDest = '&rs:Format=' + (output.toUpperCase());

    //     console.log('in doPopupReport, got', reportName, parms, output);
    // //    let toString = this.env.SSRSServer + '{0}{1}' + this.env.SSRSCmds + this.env.SSRSOutput;
    //     const parmString = parms.map(p => '&' + p.name + '=' + p.value).join('');
    //     const toString = this.env.SSRSServer + reportName + parmString + this.env.SSRSCmds + SSRSDest;
    //     console.log('you get toString', toString);
    //     window.open(toString); // , '', locString);

    this.notifier.loadingOn();

    this.reporting.getReportToFormat(reportName, parms, output)
      .pipe(finalize(() => this.notifier.loadingOff()))
      .subscribe(
        (data: Blob) => {
          const fileName = reportName + '.' + this.utility.extensionForFormat(output);
          const docType = this.utility.contentTypeForFormat(output);
          const theFile = new Blob([data], { type: docType });
          const url = window.URL.createObjectURL(theFile);
          // if ((output === 'CSV') || (output === 'PDF') || (output === 'XML') || (output === 'CSVASCII') || (output === 'EXCEL')) {
          const link = document.createElement('a');
          if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
          // } else {
          //   window.open(url); // , 'NewWindow'); // , 'resizable,scrollbar,toolbar');
          // }
          this.alert.info('Report Generated');
        },
        error => {
          this.alert.error(error);
        });

    this.doLogReportDisplay(reportID, reportName, parms, output);

  }


  doLogReportDisplay(reportID: number, reportName: string, parms: reportParam[], outputFormat: string) {

    const rvh = new rptReportViewHistoryDb();
    rvh.ViewHistoryID = IDType.NewRec;
    console.log(reportID, reportName, parms, outputFormat);
    rvh.ViewHistoryID = IDType.NewRec;
    rvh.Parameters = JSON.stringify(parms);
    rvh.DateViewed = new Date();
    rvh.ReportID = reportID;
    rvh.ReportName = reportName;
    rvh.OutputFormat = outputFormat;
    rvh.UserID = this.auth.currentUser.UserID;

    console.log('rvh=', rvh);

    this.reportHistory.create(rvh)
      .subscribe(() => {
      },
        (error) => this.alert.error(error)
      );
  }

  doCancelDisplay() {
    this.displayComplete.emit(0);
  }
}
