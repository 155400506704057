import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { orgEntity, orgAssociate } from '../_models/ORG.model';

@Injectable()
export class AssociationService {

    api = 'orgAssociation/';
    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    getByID(associationType: string, associateID: number) {
        return this.http.get<orgAssociate>(this.apiURL + this.api + '/' + associateID + '/' + associationType);
    }

    getForEntity(associationType: string, entityID: number) {
        return this.http.get<orgAssociate[]>(this.apiURL + this.api + '/' + associationType + '/' + entityID);
    }

    createAssociation(forEntityID: number, AssociatedID: number, AssociationType: string) {
        return this.http.post(this.apiURL + this.api + 'createAssociation/' + forEntityID + '/' + AssociatedID + '/' + AssociationType, null);
    }
}
