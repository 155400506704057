import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { IDType } from '../_models/enums.model';

// // import { server } from '../_models/index';
import { ordFreightPayableEx } from '../_models/ORD.model';

@Injectable()
export class OrdersFreightPayableService {
  api = 'FreightPayable/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) {}

  getNew(type: number, DataVersion: string) {
    return this.http.get<ordFreightPayableEx>(this.apiURL + this.api + 'new/' + type + '/' + DataVersion);
  }

  getPayable(payableID: number) {
    return this.http.get<ordFreightPayableEx>(this.apiURL + this.api + payableID);
  }

  getPayableForOrderByType(orderID: number, payableType: number, dataVersion: string) {
    return this.http.get<ordFreightPayableEx>(this.apiURL + this.api + 'order/' + orderID + '/' + payableType + '/' + dataVersion);
  }

  save(item: ordFreightPayableEx) {
    return this.http.put<ordFreightPayableEx>(this.apiURL + this.api, item);
  }

  delete(payableID: number) {
    return this.http.delete(this.apiURL + this.api + payableID);
  }

  markPayablesEntered(payableIDList: number[], billRef: string) {
    console.log('markPayablesEntered', payableIDList);
    const config = { headers: { 'Content-Type': 'application/json' } };
    const payload = { 'payableIDList': payableIDList.join(','), 'referenceNumber': billRef };
    return this.http.put(this.apiURL + this.api + 'Entered', payload, config);

  }

  markPayablesApproved(payableIDList: number[]) {
    console.log('markPayablesApproved', payableIDList);
    const config = { headers: { 'Content-Type': 'application/json' } };
    const payload = { 'payableIDList': payableIDList.join(',') };
    return this.http.put(this.apiURL + this.api + 'Approve', payload, config);

  }

}




