import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { menuData } from './menuData';
import { Observable } from 'rxjs';
import { arcMenuItem } from '../_models/SEC.model';

@Injectable()
export class MenuService {
  api = 'secMenu/';

  constructor(private http: HttpClient,
    // private auth: AuthService,
    @Inject('apiURL') private apiURL) { }


  get(rights: string[]): Observable<arcMenuItem[]> {
    return new Observable(observer => {
      const mnu = this.filterMenu(menuData.items, rights);
      observer.next(mnu);
      observer.complete();
    });
  }

  filterMenu(dta: any, rights: string[]): arcMenuItem[] {
    dta.forEach(itm => {
      itm.visible = ((!itm.needsRight) || (rights.includes(itm.needsRight)));
      if ((itm.visible) && (itm.items)) {
        itm.items = this.filterMenu(itm.items, rights);
      }
    });
    return dta;
  }

}
