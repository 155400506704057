import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LookupsService } from 'app/_services/lookups.service';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'arc-confirm-new-language-dialog',
  templateUrl: './confirm-new-language-dialog.component.html',
  styleUrls: ['./confirm-new-language-dialog.component.scss']
})
export class ConfirmNewLanguageDialogComponent implements OnInit {

  constructor(
    private lookups: LookupsService
  ) { }

  languages: SelectItem[] = [];

  @Input() inputLabel = 'label';
  @Input() message = '';

  @Input() retValueNumber = 0;
  @Input() retValueText = '';

  @Output() accept = new EventEmitter<string>();
  @Output() reject = new EventEmitter<null>();

  ngOnInit() {
    console.log('in oninit');
    this.lookups.languages.subscribe((data: SelectItem[]) => {
      this.languages = data;
      console.log('got languages', this.languages);
    });
  }

  doAccept() {
    this.accept.emit(this.retValueText);
    this.retValueText = '';
    this.retValueNumber = 0;
  }

  doReject() {
    this.retValueText = '';
    this.retValueNumber = 0;
    this.reject.emit();
  }

}
