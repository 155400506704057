import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

// import { server } from '../_models';
import { orgPlaceVw, orgPlace } from '../_models/ORG.model';
import { OrgItemType } from '../_models/enums.model';
import { orgPlaceDest, orgPlaceEx } from '../_models/TableCustom.model';
import { CSC } from '../_services/utility.service';
import { utlPlace } from '../_models/UTL.model';

@Injectable()
export class PlacesService {
  api = 'orgPlace/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<orgPlace[]>(this.apiURL + this.api);
  }

  getByID(itemID: number) {
    return this.http.get<orgPlace>(this.apiURL + this.api + itemID);
  }

  getAllForItem(itemType: OrgItemType, itemID: number) {
    return this.http.get<orgPlace[]>(this.apiURL + this.api + itemType + '/' + itemID);
  }

  getAllForItemAndPlace(itemType: OrgItemType, itemID: number, itemPlace: string) {
    return this.http.get<orgPlace[]>(this.apiURL + this.api + itemType + '/' + itemID + '/' + itemPlace);
  }

  update(item: orgPlace) {
    return this.http.put(this.apiURL + this.api + item.PlaceID, item);
  }

  create(item: orgPlace) {
    return this.http.post(this.apiURL + this.api, item);
  }

  delete(itemID: number) {
    return this.http.delete(this.apiURL + this.api + itemID);
  }

  search(frag: string) {
    return this.http.get<string[]>(this.apiURL + this.api + 'search', { params: { frag: frag }});
  }

  getTerminalsInCSC(inCSC: string) {
    return this.http.get<orgPlace[]>(this.apiURL + this.api + 'terminals/' + inCSC);
  }

  getLegDestinationInCSC(WOConsigneeID: number, CarrierID: number, inCSC: string) {
    return this.http.get<orgPlaceDest[]>(this.apiURL + this.api + 'legDest/' + WOConsigneeID + '/' + CarrierID + '/' + inCSC);
  }

}

@Injectable()
export class PlaceVwService {
  api = 'orgPlaceVw/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<orgPlaceVw[]>(this.apiURL + this.api);
  }

  getByID(itemID: number) {
    return this.http.get<orgPlaceVw>(this.apiURL + this.api + itemID);
  }

}

@Injectable()
export class PlaceExService {
  api = 'orgPlaceEx/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getByID(itemID: number) {
    console.log('in PlaceExService.getbyID', itemID);
    return this.http.get<orgPlaceEx>(this.apiURL + this.api + itemID);
  }

}

@Injectable()
export class utlPlaceService {
  api = 'utlPlaces/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getByID(id: number) {
    return this.http.get<utlPlace>(this.apiURL + this.api + id);
  }

  getAll() {
    return this.http.get<utlPlace[]>(this.apiURL + this.api);
  }

  update(item: utlPlace) {
    return this.http.put<utlPlace>(this.apiURL + this.api + item.id, item);
  }
  insert(item: utlPlace) {
    return this.http.post<utlPlace>(this.apiURL + this.api, item);
  }

  delete(id: number) {
    return this.http.delete<utlPlace>(this.apiURL + this.api + id);
  }
}
