import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { orgPhoneEx } from '../_models/TableCustom.model';
import { orgPhone, orgPhoneVw } from '../_models/ORG.model';
import { OrgItemType } from '../_models/enums.model';
import { PEMPhone } from '../_models/PEMLink.model';

@Injectable()
export class PhonesService {
  api = 'orgPhone/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  get(itemID: number) {
    console.log('in orgPhone service, get', itemID);
    return this.http.get<orgPhone>(this.apiURL + this.api + itemID);
  }

  update(item: orgPhone) {
    return this.http.put(this.apiURL + this.api + item.PhoneID, item);
  }

  create(item: orgPhone) {
    return this.http.post(this.apiURL + this.api, item);
  }

  delete(itemID: number) {
    return this.http.delete(this.apiURL + this.api + itemID);
  }
}

@Injectable()
export class PEMPhoneService {
  api = 'PEMPhone/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAllForItem(itemType: OrgItemType, itemID: number) {
    return this.http.get<PEMPhone[]>(this.apiURL + this.api + itemType + '/' + itemID);
  }

  get(itemID: number) {
    return this.http.get<PEMPhone>(this.apiURL + this.api + itemID);
  }

  save(item: PEMPhone) {
    return this.http.post<PEMPhone>(this.apiURL + this.api, item);
  }

  delete(itemID: number) {
    return this.http.delete(this.apiURL + this.api + itemID);
  }

  link(PhoneID: number, itemID: number, itemType: OrgItemType) {
    let itm;
    switch (itemType) {
      case OrgItemType.Entity:
        itm = { 'PhoneID': PhoneID, 'EntityID': itemID };
        break;
      case OrgItemType.Place:
        itm = { 'PhoneID': PhoneID, 'PlaceID': itemID };
        break;
      case OrgItemType.Person:
        itm = { 'PhoneID': PhoneID, 'PersonID': itemID };
        break;
    }
    console.log('link phone', itm);
    return this.http.put(this.apiURL + this.api, itm);
  }
}

@Injectable()
export class PhoneVwService {
  api = 'orgPhoneVw/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    console.log('in orgPhoneVw service, getAll');
    return this.http.get<orgPhoneVw[]>(this.apiURL + this.api);
  }

  get(itemID: number) {
    return this.http.get<orgPhoneVw>(this.apiURL + this.api + itemID);
  }

  // delete(itemID: number) {
  //   return this.http.delete(this.apiURL + 'orgPhone/' + itemID);
  // }
}
