import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

// import { server } from '../_models';
import { orgMediaVw, orgMedium } from '../_models/ORG.model';

import { OrgItemType } from '../_models/enums.model';
import { PEMMedia } from '../_models/PEMLink.model';

@Injectable()
export class MediaService {
  api = 'orgMedia/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  get(itemID: number) {
    return this.http.get<orgMedium>(this.apiURL + this.api + itemID);
  }

  update(item: orgMedium) {
    return this.http.put(this.apiURL + this.api + item.MediaID, item);
  }

  create(item: orgMedium) {
    return this.http.post(this.apiURL + this.api, item);
  }

  delete(itemID: number) {
    return this.http.delete(this.apiURL + this.api + itemID);
  }
}

@Injectable()
export class MediaExService {
  api = 'orgMediaEx/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll(itemType: OrgItemType, itemID: number, andLinked: boolean) {
    const plusLinked =  { params: new HttpParams().set('linked', andLinked ? 'true' : 'false') };
    return this.http.get<orgMediaVw[]>(this.apiURL + this.api + itemType + '/' + itemID, plusLinked);
  }

  get(itemID: number) {
    return this.http.get<orgMediaVw>(this.apiURL + this.api + itemID);
  }

  update(item: orgMediaVw) {
    return this.http.put(this.apiURL + this.api + item.MediaID, item );
  }

  create(item: orgMediaVw) {
    return this.http.post(this.apiURL + this.api, item);
  }

  delete(itemID: number) {
    return this.http.delete(this.apiURL + this.api + itemID);
  }
}

@Injectable()
export class PEMMediaService {
  api = 'PEMMedia/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAllForItem(itemType: OrgItemType, itemID: number) {
    return this.http.get<PEMMedia[]>(this.apiURL + this.api + itemType + '/' + itemID);
  }

  get(itemID: number) {
  return this.http.get<PEMMedia>(this.apiURL + this.api + itemID);
  }

  save(item: PEMMedia) {
    return this.http.post<PEMMedia>(this.apiURL + this.api, item);
  }

  delete(itemID: number) {
    return this.http.delete(this.apiURL + this.api + itemID);
  }

  link(MediaID: number, itemID: number, itemType: OrgItemType) {
    let itm;
    switch (itemType) {
      case OrgItemType.Entity:
        itm = { 'MediaID': MediaID, 'EntityID': itemID };
        break;
      case OrgItemType.Place:
        itm = { 'MediaID': MediaID, 'PlaceID': itemID };
        break;
      case OrgItemType.Person:
        itm = { 'MediaID': MediaID, 'PersonID': itemID };
        break;
    }
    console.log('link Media', itm);
    return this.http.put(this.apiURL + this.api, itm);
  }
}

@Injectable()
export class MediaVwService {
  api = 'orgMediaVw/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<orgMediaVw[]>(this.apiURL + this.api);
  }

  get(itemID: number) {
    return this.http.get<orgMediaVw>(this.apiURL + this.api + itemID);
  }

  delete(itemID: number) {
    return this.http.delete(this.apiURL + 'orgMedia/' + itemID);
  }
}
