import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ordTask, taskFilter } from '../_models/ORD.model';
import { OrdLinkType, IDType } from '../_models/enums.model';
import { UpsertRetStatus, UpsertRet } from '../_models/API.model';

@Injectable({
  providedIn: 'root'
})
export class OrderTaskService {

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  api = 'ordTask/';


  getAll() {
    return this.http.get<ordTask[]>(this.apiURL + this.api);

  }

  get(id: number) {
    return this.http.get<ordTask>(this.apiURL + this.api + id);
  }

  // generally, do not use this. Instead, use the methods in OrdItemService, where things get updated more intelligently
  save(task: ordTask) {
    if (task.TaskID === IDType.NewRec) {
      return this.http.post<UpsertRet>(this.apiURL + this.api, task);
    } else {
      return this.http.put<UpsertRet>(this.apiURL + this.api + task.TaskID, task);
    }
  }

  getFiltered(taskStatuses: string[]) {

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    let params = new HttpParams();
    // params = params.append('filter', JSON.stringify(filter));
    params = params.append('taskStatuses', taskStatuses.join(','));

    return this.http.get<ordTask[]>(this.apiURL + this.api + 'filtered', { headers: headers, params: params });
  }

  // create(task: ordTask) {
  //   return this.http.post<UpsertRetStatus>(this.apiURL + this.api, task);
  // }

  // update(task: ordTask) {
  //   return this.http.put<UpsertRetStatus>(this.apiURL + this.api + task.TaskID, task);
  // }

  delete(id: number) {
    return this.http.delete<UpsertRetStatus>(this.apiURL + this.api + id);
  }

  getForOrder(orderID: number) {
    return this.http.get<ordTask[]>(this.apiURL + this.api + 'forOrder/' + orderID);
  }

  getForItem(linkType: OrdLinkType, itemID: number) {
    return this.http.get<ordTask[]>(this.apiURL + this.api + 'forItem/' + itemID + '/' + linkType);
  }

  // getForWarehouseAlert(warehouseAlertID: number) {
  //   return this.http.get<ordTask[]>(this.apiURL + this.api + 'forAlert/' + warehouseAlertID);
  // }

  // getForFreightLeg(freightLegID: number) {
  //   return this.http.get<ordTask[]>(this.apiURL + this.api + 'forLeg/' + freightLegID);
  // }

  // getForFreightPayable(freightPayableID: number) {
  //   return this.http.get<ordTask[]>(this.apiURL + this.api + 'forPayable/' + freightPayableID);
  // }

  getDefaultsForLinkType(linkType: OrdLinkType) {
    return this.http.get<ordTask[]>(this.apiURL + this.api + 'defaultsForLinkType/' + linkType);
  }
}
