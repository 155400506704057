import { enableProdMode } from '@angular/core';
import '@angular/compiler';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment as env } from './environments/environment';
import { appRoutes } from './app/app-routing.module';
import { authHttpInterceptorFn, provideAuth0 } from '@auth0/auth0-angular';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideRouter } from '@angular/router';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from 'app/app.component';


if (env.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule, {
  providers: [
    // provideHttpClient(withInterceptors([authHttpInterceptorFn])),
    provideAuth0({
      ...env.auth,
      httpInterceptor: {
        ...env.httpInterceptor,
      }
    })
  ],
})
  .catch(err => console.error(err));
;


//   .catch(err => console.log(err));
// bootstrapApplication(AppComponent, {
//   providers: [
//     provideHttpClient(withInterceptors([authHttpInterceptorFn])),
//     provideRouter(appRoutes),
//     provideAuth0({
//       ...env.auth,
//       httpInterceptor: {
//         ...env.httpInterceptor,
//       },
//     }),
//   ],
// });