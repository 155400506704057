import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { tempTable } from 'app/time/dashboards/employees-issues-count/employees-issues-count.component';
import { UpsertRet } from 'app/_models/API.model';
import { cfgWarehouse } from 'app/_models/CFG.model';
import { orgPerson } from 'app/_models/ORG.model';
import { secGoTime } from 'app/_models/SEC.model';
import {
  teamMember,
  timeAssignedFunction,
  timeClientFunction,
  timeClientFunctionDb,
  timeClientJob,
  timeClientWarehouseDb,
  timeClockAction,
  timeEmployee,
  timeEmployeeDb,
  timeEmployeeFunctionDb,
  timeEmployeeSnapshot,
  timeFunctionDb,
  timeFunctionGroupDb,
  timeFunctionSummary,
  timeIssue,
  timeIssueDb,
  timeClientJobDb,
  timeSchedule,
  timeScheduleDb,
  timeScheduleTemplateDb,
  timeTimeSegment,
  timeTimeSegmentDb,
  timeTrackSegment,
  timeTrackSegmentDb,
  timeClientFunctionAssignment,
  timeClientWarehouseAssignment,
  timeSetting,
  timeIssueSummary,
  timeFunctionGroup,
  timeFunctionGroupFunctionDb,
  timeFunction,
  timeEmployeeFunctionTotal,
  timeNewTimeSegment,
  timeFunctionsInWarehouse,
  timeEmployeeIssueSummary,
  timeDaySnapshot,
  timePersonP3PL,
  timeGoTimeNewEmployee,
  timeEmployeeAccess,
  timeUnapprovedTimeSummary,
  timeEmployeePINUpdate,
  timeEmployeeTermination,
  timeTimeSegmentHistoryAll,
  timePayrollBatch,
  timePayrollBatchDetail,
  timePayrollParm,
  timeUnapprovedSummary,
  timeTeamWeekTotals,
  timeClientFunctionShort,
  timeWarehousePayrollSettings
} from 'app/_models/TIME.model';

// import {
//   cfgDepartmentDb,
//   cfgIssueTypeDb,
//   cfgScheduleTypeDb,
//   cfgTrackTypeDb,

// } from 'app/_models/CFG.model';

import { SelectItem } from 'primeng/api/selectitem';
import { BehaviorSubject } from 'rxjs';



@Injectable()
export class timeActionService {
  api = 'timeAction/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getTimeForEmployeeAndDate(employeeID: number, forDate: Date) {
    let params: HttpParams = new HttpParams();
    params = params.append('employeeID', employeeID.toString());
    params = params.append('forDate', forDate.toLocaleDateString());
    return this.http.get(this.apiURL + this.api + 'activityList', { params: params });
  }

  getActivityForClockOut(employeeID: number) {
    return this.http.get(this.apiURL + this.api + 'activityForClockOut/' + employeeID);
  }

  saveTimeSegments(segs: timeTimeSegment[]) {
    return this.http.put<timeTimeSegment[]>(this.apiURL + this.api + 'activityList', segs);
  }

  saveTrackSegments(segs: timeTrackSegment[]) {
    return this.http.put<timeTrackSegment[]>(this.apiURL + this.api + 'trackList', segs);
  }

  // Perform Specific Actions on GoTime Structures
  postAction(action: timeClockAction) {
    return this.http.post(this.apiURL + this.api + 'action', action);
  }

  // Return a Snapshot Record for a Particular Employee (Date optional)
  getSnapshot(id: number, date?: Date) {
    let params = new HttpParams();
    params = params.append('employeeID', id.toString());
    if (date) {
      params = params.append('forDate', new Date(date).toLocaleString());
    }
    return this.http.get<timeEmployeeSnapshot>(this.apiURL + this.api + 'snapshot', { params: params });
  }

  // Return a list of timeSegments for a Particular Employee/Date ordered by Time (Date Required)
  // Return a Snapshot Record for a Particular Employee (Date Required)
  getSnapshotOfDay(id: number, date: Date) {
    let params = new HttpParams();
    params = params.append('employeeID', id.toString());
    params = params.append('forDate', new Date(date).toLocaleString());
    return this.http.get<timeFunctionSummary[]>(this.apiURL + this.api + 'activitySummary', { params: params });
  }

  // Return a Snapshot Record for a Particular Employee ordered by Time (Date Required)
  getSnapshotofDayInOrder(id: number, date: Date) {
    let params = new HttpParams();
    params = params.append('employeeID', id.toString());
    params = params.append('forDate', new Date(date).toLocaleString());
    return this.http.get<timeDaySnapshot>(this.apiURL + this.api + 'daySnapshot/', { params: params });
  }

  // Send new employee data to server
  createGoTimeEmployee(employee: timeGoTimeNewEmployee) {
    return this.http.post<UpsertRet>(this.apiURL + this.api + 'createEmployee', employee);
  }
}

@Injectable()
export class timeClientFunctionService {
  api = 'timeClientFunction/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<timeClientFunctionDb[]>(this.apiURL + this.api);

  }
  get(id: number) {
    return this.http.get<timeClientFunctionDb>(this.apiURL + this.api + id);
  }

  create(ClientFunctionDb: timeClientFunctionDb) {
    return this.http.post(this.apiURL + this.api, ClientFunctionDb);
  }

  update(ClientFunctionDb: timeClientFunctionDb) {
    return this.http
      .put(this.apiURL + this.api + ClientFunctionDb.ClientFunctionID, ClientFunctionDb);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getForClient(id: number) {
    return this.http.get<timeClientFunctionDb>(this.apiURL + this.api + 'functions/' + id);
  }

  getAssignmentsForClient(id: number) {
    return this.http.get<timeClientFunctionAssignment[]>(this.apiURL + this.api + 'functionAssignments/' + id);
  }

  getAssignmentsForFunction(id: number) {
    return this.http.get<timeClientFunctionAssignment[]>(this.apiURL + this.api + 'clientAssignments/' + id);
  }

  getClientRefNumClients() {
    console.log('in clientrefnumclients');
    return this.http.get<SelectItem[]>(this.apiURL + this.api + 'refNumClients');
  }

  getClientRefFuncsForClient(clientID: number) {
    return this.http.get<SelectItem<number>[]>(this.apiURL + this.api + 'refNumFuncsForClient/' + clientID);
  }

  getClientsForFunctionAndEmployeeAndWarehouse(FunctionID: number, EmployeeID: number, WarehouseID: number) {
    return this.http.get<timeClientFunctionShort[]>(this.apiURL + this.api + 'functionEmployee/' + FunctionID + '/' + EmployeeID + '/' + WarehouseID);
  }

  getFunctionsForEmployeeAndClients(EmployeeID: number, clients: number[]) {
    let params = new HttpParams();
    params = params.append('employeeID', EmployeeID.toString());
    params = params.append('clients', clients.join('|'));
    console.log('passing clients', clients.toString());

    return this.http.get<timeFunctionDb[]>(this.apiURL + this.api + 'employeeClients', { params: params });
  }

  getClientFunctionsReverse(functionID: number) {
    return this.http.get<timeClientFunctionAssignment[]>(this.apiURL + this.api + 'clientFunctionReverse/' + functionID);
  }
}

@Injectable()
export class timeClientJobService {
  api = 'timeClientJob/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getJobsForClientFunction(clientFunctionID: number, userID: number) {
    return this.http.get<timeClientJob[]>(this.apiURL + this.api + 'jobsForClientFunc/' + clientFunctionID + '/' + userID);
  }

}

@Injectable()
export class timeClientWarehouseService {
  api = 'timeClientWarehouse/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<timeClientWarehouseDb[]>(this.apiURL + this.api);

  }
  get(id: number) {
    return this.http.get<timeClientWarehouseDb>(this.apiURL + this.api + id);
  }

  create(ClientWarehouseDb: timeClientWarehouseDb) {
    return this.http.post(this.apiURL + this.api, ClientWarehouseDb);
  }

  update(ClientWarehouseDb: timeClientWarehouseDb) {
    return this.http
      .put(this.apiURL + this.api + ClientWarehouseDb.ClientWarehouseID, ClientWarehouseDb);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getForClient(id: number) {
    return this.http.get<cfgWarehouse[]>(this.apiURL + this.api + 'client/' + id + '/assigned');
  }

  getAssignedForClient(id: number) {
    return this.http.get<timeClientWarehouseAssignment[]>(this.apiURL + this.api + 'client/' + id + '/assignments');
  }

}

@Injectable()
export class timeEmployeeFunctionService {
  api = 'timeEmployeeFunction/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<timeEmployeeFunctionDb[]>(this.apiURL + this.api);

  }
  get(id: number) {
    return this.http.get<timeEmployeeFunctionDb>(this.apiURL + this.api + id);
  }

  create(EmployeeFunctionDb: timeEmployeeFunctionDb) {
    return this.http.post(this.apiURL + this.api, EmployeeFunctionDb);
  }

  update(EmployeeFunctionDb: timeEmployeeFunctionDb) {
    return this.http
      .put(this.apiURL + this.api + EmployeeFunctionDb.EmployeeFunctionID, EmployeeFunctionDb);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  assignMultiple(assigns: timeEmployeeFunctionDb[]) {
    return this.http.post(this.apiURL + this.api + 'assign', assigns);

  }

  getEmployeeFunctionAssignments(functionID: number, warehouseID: number) {
    let params = new HttpParams();
    params = params.append('functionID', functionID.toString());
    params = params.append('warehouseID', warehouseID.toString());
    return this.http.get(this.apiURL + this.api + 'employeeFunctionAssignments', { params: params });
  }

}


@Injectable()
export class timeEmployeeService {
  api = 'timeEmployee/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  // * ***************
  // * User Observable
  private _currentEmployeeSource = new BehaviorSubject<any>(null);

  signedInEmployee$ = this._currentEmployeeSource.asObservable();

  signedInEmployee: timeEmployeeSnapshot = null;

  updateEmployeeSnapshot(data: timeEmployeeSnapshot) {
    this.signedInEmployee = data;
    this._currentEmployeeSource.next(this.signedInEmployee);
  }

  // * ***************

  getAll() {
    return this.http.get<timeEmployeeDb[]>(this.apiURL + this.api);

  }
  get(id: number) {
    return this.http.get<timeEmployeeDb>(this.apiURL + this.api + id);
  }

  create(Employee: timeEmployee) {
    return this.http.post(this.apiURL + this.api, Employee);
  }

  update(Employee: timeEmployeeDb) {
    return this.http.put(this.apiURL + this.api + Employee.EmployeeID, Employee);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getSnapshot(id: number) {
    let params = new HttpParams();
    params = params.append('employeeID', id.toString());
    return this.http.get<timeEmployeeSnapshot>(this.apiURL + 'timeAction/snapshot', { params: params });
  }

  getEx(id: number) {
    return this.http.get<timeEmployee>(this.apiURL + this.api + 'ex/' + id);
  }

  getAllEx(activeOnly: boolean = false) {
    let params = new HttpParams();
    params = params.append('activeOnly', activeOnly ? 'true' : 'false');
    return this.http.get<timeEmployee[]>(this.apiURL + this.api + 'ex', { params: params });
  }

  getTeamEx(id: number, showFullTeam: boolean) {
    let params = new HttpParams();
    params = params.append('showFullTeam', showFullTeam.toString());
    return this.http.get<timeEmployee[]>(this.apiURL + this.api + 'teamEx/' + id, { params: params });
  }

  getTeam(id: number, showFullTeam: boolean) {
    if (showFullTeam) {
      return this.http.get<teamMember[]>(this.apiURL + this.api + 'teamFull/' + id);
    } else {
      return this.http.get<teamMember[]>(this.apiURL + this.api + 'team/' + id);
    }
  }

  getColleagues(id: number) {
    return this.http.get<teamMember[]>(this.apiURL + this.api + 'colleagues/' + id);
  }

  getGoTimeInfo(id: number) {
    return this.http.get<secGoTime>(this.apiURL + this.api + 'goTimeInfo/' + id);
    // return this.http.get<secGoTime>(this.api + this.apiURL + 'goTimeInfo/' + id);
  }

  getGoTimeInfoForTeam(id: number, fullTeam: boolean = false) {
    let params = new HttpParams();
    params = params.append('fullTeam', fullTeam.toString());
    return this.http.get<secGoTime[]>(this.apiURL + this.api + 'goTimeInfoForTeam/' + id, { params: params });
  }

  getTeamActivity(managerID: number, showFullTeam: boolean, forDays?: number) {
    let params = new HttpParams();
    params = params.append('forDays', forDays?.toString());
    params = params.append('showFullTeam', showFullTeam.toString());
    return this.http.get<timeEmployeeFunctionTotal[]>(this.apiURL + this.api + 'teamActivity/' + managerID, { params: params });
  }

  getP3PLPersons(name: string) {
    return this.http.get<timePersonP3PL[]>(this.apiURL + this.api + 'p3plName/' + name);
  }

  getArcturusPersonsByP3PLID(id: number) {
    return this.http.get<orgPerson[]>(this.apiURL + this.api + 'arcturusByP3PLID/' + id);
  }

  getP3PLPersonsByID(id: number) {
    return this.http.get<timePersonP3PL[]>(this.apiURL + this.api + 'p3plPersonsByID/' + id);
  }

  getArcturusPersons(name: string) {
    return this.http.get<orgPerson[]>(this.apiURL + this.api + 'arcturusName/' + name);
  }

  checkPIN(id: number, pin: timeEmployeePINUpdate) {
    return this.http.post(this.apiURL + this.api + 'PIN/' + id, pin);
  }

  changePIN(id: number, pin: timeEmployeePINUpdate) {
    return this.http.put(this.apiURL + this.api + 'PIN/' + id, pin);
  }

  forcePINChange(id: number) {
    return this.http.put(this.apiURL + this.api + 'forceChangePIN/', id);
  }

  forcePasswordChange(id: number) {
    return this.http.put(this.apiURL + this.api + 'forceChangePassword/', id);
  }

  deactivateEmployee(terminationObject: timeEmployeeTermination) {
    return this.http.put<UpsertRet>(this.apiURL + this.api + 'deactivate', terminationObject);
  }

  activateEmployee(id: number) {
    return this.http.put<UpsertRet>(this.apiURL + this.api + 'activate/' + id, null);
  }

  getUnapprovedTimeForTeam(id: number, fullTeam: boolean = false) {
    let params = new HttpParams();
    params = params.append('fullTeam', fullTeam.toString());
    return this.http.get<timeUnapprovedSummary[]>(this.apiURL + this.api + 'teamUnapproved/' + id, { params: params });
  }

  getUnapprovedTimeForEmployee(id: number) {
    return this.http.get<timeUnapprovedSummary[]>(this.apiURL + this.api + 'employeeUnapproved/' + id);
  }

  getSelectForPayroll(parm: timePayrollParm) {
    return this.http.post<timeEmployee[]>(this.apiURL + this.api + 'payrollSelect', parm);
  }
}


@Injectable()
export class timeFunctionService {
  api = 'timeFunction/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<timeFunction[]>(this.apiURL + this.api);
  }

  getAllActive() {
    return this.http.get<timeFunctionDb[]>(this.apiURL + this.api + 'active');
  }

  getAllClientAssignable() {
    return this.http.get<timeFunctionDb[]>(this.apiURL + this.api + 'clientAssignable');
  }

  get(id: number) {
    return this.http.get<timeFunctionDb>(this.apiURL + this.api + id);
  }

  create(FunctionDb: timeFunctionDb) {
    return this.http.post(this.apiURL + this.api, FunctionDb);
  }

  update(FunctionDb: timeFunctionDb) {
    return this.http
      .put(this.apiURL + this.api + FunctionDb.FunctionID, FunctionDb);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getAvailableForEmployee(id: number) {
    return this.http.get<timeAssignedFunction[]>(this.apiURL + this.api + 'employee/' + id + '/available');
  }

  getAssignedForEmployee(id: number) {
    return this.http.get<timeAssignedFunction[]>(this.apiURL + this.api + 'employee/' + id + '/assigned');
  }

  getGroupFunctions(id: number) {
    return this.http.get<timeAssignedFunction[]>(this.apiURL + this.api + 'group/' + id);
  }

  putAssignedForEmployee(id: number, functions: timeFunctionDb[]) {
    return this.http.put(this.apiURL + this.api + 'employee/' + id, functions);
  }

  getAssignedForClient(id: number) {
    return this.http.get<timeFunctionDb[]>(this.apiURL + this.api + 'client/' + id + '/assigned');
  }

  getBySearchText(frag: string) {
    return this.http.get<timeFunctionDb[]>(this.apiURL + this.api + 'searchText', { params: { frag: frag } })
  }

}

@Injectable()
export class timeFunctionGroupService {
  api = 'timeFunctionGroup/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<timeFunctionGroupDb[]>(this.apiURL + this.api);

  }
  get(id: number) {
    return this.http.get<timeFunctionGroupDb>(this.apiURL + this.api + id);
  }

  create(FunctionGroupDb: timeFunctionGroupDb) {
    return this.http.post(this.apiURL + this.api, FunctionGroupDb);
  }

  update(FunctionGroupDb: timeFunctionGroupDb) {
    return this.http
      .put(this.apiURL + this.api + FunctionGroupDb.FunctionGroupID, FunctionGroupDb);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getAllEx() {
    return this.http.get<timeFunctionGroup[]>(this.apiURL + this.api + 'ex');
  }

  getAllOfType(type: string) {
    console.log('get all of type ', type);
    return this.http.get<timeFunctionGroup[]>(this.apiURL + this.api + 'type/' + type);
  }

}

@Injectable()
export class timeFunctionGroupFunctionService {
  api = 'timeFunctionGroupFunction/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<timeFunctionGroupFunctionDb[]>(this.apiURL + this.api);

  }
  get(id: number) {
    return this.http.get<timeFunctionGroupFunctionDb>(this.apiURL + this.api + id);
  }

  create(FunctionGroupFunctionDb: timeFunctionGroupFunctionDb) {
    return this.http.post(this.apiURL + this.api, FunctionGroupFunctionDb);
  }

  update(FunctionGroupFunctionDb: timeFunctionGroupFunctionDb) {
    return this.http
      .put(this.apiURL + this.api + FunctionGroupFunctionDb.FunctionGroupFunctionID, FunctionGroupFunctionDb);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getSelectedForGroup(id: number, type: string) {
    console.log('getSelectedForGroup', id);
    return this.http.get<timeFunctionGroupFunctionDb[]>(this.apiURL + this.api + 'forGroup/' + id + '/' + type);
  }

  toggleSelectedForGroup(func: timeFunctionGroupFunctionDb) {
    return this.http.put<timeFunctionGroupFunctionDb[]>(this.apiURL + this.api + 'forGroup', func);
  }
  getSelectedForFunction(id: number, types: string) {
    console.log('getSelectedForFunction', id);
    return this.http.get<timeFunctionGroupFunctionDb[]>(this.apiURL + this.api + 'forFunction/' + id + '/' + types);
  }

  toggleSelectedForFunction(func: timeFunctionGroupFunctionDb) {
    return this.http.put<timeFunctionGroupFunctionDb[]>(this.apiURL + this.api + 'forFunction', func);
  }
}

@Injectable()
export class timeIssueService {
  api = 'timeIssue/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<timeIssueDb[]>(this.apiURL + this.api);

  }
  get(id: number) {
    return this.http.get<timeIssueDb>(this.apiURL + this.api + id);
  }

  getEx(id: number) {
    return this.http.get<timeIssue>(this.apiURL + this.api + 'ex/' + id);
  }

  create(IssueDb: timeIssueDb) {
    return this.http.post(this.apiURL + this.api, IssueDb);
  }

  update(IssueDb: timeIssueDb) {
    return this.http
      .put(this.apiURL + this.api + IssueDb.IssueID, IssueDb);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getSummaryByID(IssueID: number) {
    return this.http.get<timeIssueSummary>(this.apiURL + this.api + 'summary/' + IssueID);
  }

  getSummaryForEmployee(employeeID: number) {
    return this.http.get<timeIssueSummary[]>(this.apiURL + this.api + 'employeeSummary/' + employeeID);
  }

  getSummaryForTeam(managerID: number, fullTeam: boolean = false, resolved: boolean = false) {
    let params: HttpParams = new HttpParams();
    params = params.append('fullTeam', fullTeam.toString());
    params = params.append('resolved', resolved.toString());
    return this.http.get<timeIssueSummary[]>(this.apiURL + this.api + 'teamSummary/' + managerID, { params: params });
  }

}


@Injectable()
export class timeSettingService {
  api = 'timeSetting/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<timeSetting[]>(this.apiURL + this.api);

  }
  get(id: number) {
    return this.http.get<timeSetting>(this.apiURL + this.api + id);
  }

  create(Setting: timeSetting) {
    return this.http.post(this.apiURL + this.api, Setting);
  }

  update(Setting: timeSetting) {
    return this.http
      .put(this.apiURL + this.api + Setting.SettingID, Setting);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }
}

@Injectable()
export class timeJobService {
  api = 'timeClientJob/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<timeClientJobDb[]>(this.apiURL + this.api);
  }

  get(id: number) {
    return this.http.get<timeClientJobDb>(this.apiURL + this.api + id);
  }

  create(ClientJobDb: timeClientJobDb) {
    return this.http.post(this.apiURL + this.api, ClientJobDb);
  }

  update(ClientJobDb: timeClientJobDb) {
    return this.http
      .put(this.apiURL + this.api + ClientJobDb.ClientJobID, ClientJobDb);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getEx(id: number) {
    return this.http.get<timeClientJob>(this.apiURL + this.api + 'ex/' + id);
  }

  getAllEx() {
    return this.http.get<timeClientJob[]>(this.apiURL + this.api + 'ex');
  }

}

@Injectable()
export class timePayrollBatchService {
  api = 'timePayrollBatch/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<timePayrollBatch[]>(this.apiURL + this.api);
  }

  get(id: number) {
    return this.http.get<timePayrollBatch>(this.apiURL + this.api + id);
  }

  create(PayrollBatch: timePayrollBatch) {
    return this.http.post(this.apiURL + this.api, PayrollBatch);
  }

  update(PayrollBatch: timePayrollBatch) {
    return this.http
      .put(this.apiURL + this.api + PayrollBatch.PayrollBatchID, PayrollBatch);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  process(parms: timePayrollParm) {
    return this.http.post(this.apiURL + this.api + 'process', parms);
  }

  getUnprocessed() {
    return this.http.get(this.apiURL + this.api + 'unprocessed');
  }

  getBatchContents(id: number) {
    return this.http.get(this.apiURL + this.api + 'batchContents/' + id);
  }
}

@Injectable()
export class timePayrollBatchDetailService {
  api = 'timePayrollBatchDetail/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<timePayrollBatchDetail[]>(this.apiURL + this.api);
  }

  get(id: number) {
    return this.http.get<timePayrollBatchDetail>(this.apiURL + this.api + id);
  }

  getByBatchID(id: number) {
    return this.http.get<timePayrollBatchDetail[]>(this.apiURL + this.api + 'batch/' + id);
  }

  getBatchByEmployee(id: number) {
    return this.http.get<timePayrollBatchDetail[]>(this.apiURL + this.api + 'batchByEmployee/' + id);
  }

  create(PayrollBatchDetail: timePayrollBatchDetail) {
    return this.http.post(this.apiURL + this.api, PayrollBatchDetail);
  }

  update(PayrollBatchDetail: timePayrollBatchDetail) {
    return this.http
      .put(this.apiURL + this.api + PayrollBatchDetail.PayrollBatchDetailID, PayrollBatchDetail);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

}

@Injectable()
export class timeScheduleService {
  api = 'timeSchedule/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<timeScheduleDb[]>(this.apiURL + this.api);

  }
  get(id: number) {
    return this.http.get<timeScheduleDb>(this.apiURL + this.api + id);
  }

  create(ScheduleDb: timeScheduleDb) {
    return this.http.post(this.apiURL + this.api, ScheduleDb);
  }

  update(ScheduleDb: timeScheduleDb) {
    return this.http
      .put(this.apiURL + this.api + ScheduleDb.ScheduleID, ScheduleDb);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getForEmployeeAndDateRange(id: number, fromDate: Date, numDays: number) {
    let params = new HttpParams();
    params = params.append('fromDate', new Date(fromDate).toLocaleDateString());
    params = params.append('numDays', numDays.toString());

    return this.http.get<timeSchedule[]>(this.apiURL + this.api + 'employee/' + id, { params: params });
  }

  getForTeamAndDateRange(id: number, fromDate: Date, showFullTeam: boolean, numDays: number) {
    let params = new HttpParams();
    params = params.append('fromDate', new Date(fromDate).toLocaleDateString());
    params = params.append('numDays', numDays.toString());

    if (showFullTeam) {
      return this.http.get<timeSchedule[]>(this.apiURL + this.api + 'teamFull/' + id, { params: params });
    } else {
      return this.http.get<timeSchedule[]>(this.apiURL + this.api + 'team/' + id, { params: params });

    }
  }

  upsertBatch(scheds: timeScheduleDb[]) {
    return this.http.post(this.apiURL + this.api + 'batch', scheds);
  }

  deleteBatch(scheds: timeScheduleDb[]) {
    return this.http.put(this.apiURL + this.api + 'batchDelete', scheds);
  }
}


@Injectable()
export class timeScheduleTemplateService {
  api = 'timeScheduleTemplate/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<timeScheduleTemplateDb[]>(this.apiURL + this.api);
  }

  getAllForUser(id: number) {
    return this.http.get<timeScheduleTemplateDb[]>(this.apiURL + this.api + 'forUser/' + id);
  }

  get(id: number) {
    return this.http.get<timeScheduleTemplateDb>(this.apiURL + this.api + id);
  }

  create(ScheduleTemplateDb: timeScheduleTemplateDb) {
    return this.http.post(this.apiURL + this.api, ScheduleTemplateDb);
  }

  update(ScheduleTemplateDb: timeScheduleTemplateDb) {
    return this.http
      .put(this.apiURL + this.api + ScheduleTemplateDb.ScheduleTemplateID, ScheduleTemplateDb);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }
}


@Injectable()
export class timeTimeSegmentService {
  api = 'timeTimeSegment/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<timeTimeSegmentDb[]>(this.apiURL + this.api);

  }
  get(id: number) {
    return this.http.get<timeTimeSegmentDb>(this.apiURL + this.api + id);
  }

  getEx(id: number) {
    return this.http.get<timeTimeSegment>(this.apiURL + this.api + 'ex/' + id);
  }

  create(TimeSegmentDb: timeTimeSegmentDb) {
    return this.http.post(this.apiURL + this.api, TimeSegmentDb);
  }

  update(TimeSegmentDb: timeTimeSegmentDb) {
    return this.http
      .put(this.apiURL + this.api + TimeSegmentDb.TimeSegmentID, TimeSegmentDb);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }
  getCluster(segID: number, margin: number) {

    let params: HttpParams = new HttpParams();
    params = params.append('clusterMargin', margin.toString());
    return this.http.get<timeTimeSegmentDb[]>(this.apiURL + this.api + segID + '/cluster', { params: params });
  }

  createNew(seg: timeNewTimeSegment) {
    console.log('create new ', seg);
    return this.http.post(this.apiURL + this.api + 'new', seg);
  }

  getHistory(id: number) {
    return this.http.get<timeTimeSegmentHistoryAll[]>(this.apiURL + this.api + 'history/' + id);
  }

  scatterSplit(id: number) {
    return this.http.put<number>(this.apiURL + this.api + 'scatterSplit/' + id, null);
  }
}

@Injectable()
export class timeTrackSegmentService {
  api = 'timeTrackSegment/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<timeTrackSegmentDb[]>(this.apiURL + this.api);

  }
  get(id: number) {
    return this.http.get<timeTrackSegmentDb>(this.apiURL + this.api + id);
  }

  create(TrackSegmentDb: timeTrackSegmentDb) {
    return this.http.post(this.apiURL + this.api, TrackSegmentDb);
  }

  update(TrackSegmentDb: timeTrackSegmentDb) {
    return this.http
      .put(this.apiURL + this.api + TrackSegmentDb.TrackSegmentID, TrackSegmentDb);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getCluster(segID: number, margin: number) {
    let params: HttpParams = new HttpParams();
    params = params.append('clusterMargin', margin.toString());
    return this.http.get<timeTrackSegmentDb[]>(this.apiURL + this.api + segID + '/cluster', { params: params });
  }

  getForTimeSegment(timeSegID: number) {
    return this.http.get<timeTrackSegmentDb[]>(this.apiURL + this.api + 'forTimeSegment/' + timeSegID);
  }
}

@Injectable()
export class timeDashboardService {
  api = 'timeDashboard/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getFunctionsInWarehouse(warehouseID: number) {
    return this.http.get<timeFunctionsInWarehouse[]>(this.apiURL + this.api + 'functionsInWarehouse/' + warehouseID);
  }

  getEmployeesInFunction(warehouseID: number, functionID: number) {
    return this.http.get<secGoTime[]>(this.apiURL + this.api + 'employeesInFunction/' + warehouseID + '/' + functionID);
  }

  getTeamEmployeeIssueSummaries(employeeID: number, showFullTeam: boolean) {
    let params = new HttpParams();
    params = params.append('showFullTeam', showFullTeam.toString());
    return this.http.get<tempTable[]>(this.apiURL + this.api + 'teamEmployeeIssueSummaries/' + employeeID, { params: params });
  }

  getEmployeeIssueSummary(employeeID: number) {
    return this.http.get<tempTable[]>(this.apiURL + this.api + 'employeeIssueSummary/' + employeeID);
  }

  getUnresolvedTimeForTeam(employeeID: number, showFullTeam: boolean) {
    return this.http.get<timeUnapprovedTimeSummary[]>(this.apiURL + this.api + 'unapprovedTime/' + employeeID + '/' + showFullTeam);
  }

  getWeekTotalsForTeam(supervisorID: number, forDate: Date, showFullTeam: boolean) {
    let params = new HttpParams();
    params = params.append('forDate', new Date(forDate).toLocaleDateString());
    params = params.append('showFullTeam', showFullTeam.toString());
    return this.http.get<timeTeamWeekTotals[]>(this.apiURL + this.api + 'weekTotalsForTeam/' + supervisorID, { params: params });
  }

  getCountEmployeesInWarehouse(warehouseID: number) {
    return this.http.get<number>(this.apiURL + this.api + 'employeeCount/' + warehouseID);
  }
}

@Injectable()
export class timeEmployeeAccessService {
  api = 'timeEmployeeAccess/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  updateAccess(accessArray: timeEmployeeAccess[], employeeID: number) {
    return this.http.post<UpsertRet>(this.apiURL + this.api + 'current/' + employeeID, accessArray);
  }

  getCurrentAccess(employeeID: number) {
    return this.http.get<timeEmployeeAccess[]>(this.apiURL + this.api + 'current/' + employeeID);
  }

  getNoAccess(employeeID: number) {
    return this.http.get<timeEmployeeAccess[]>(this.apiURL + this.api + 'noAccess/' + employeeID);
  }
}

@Injectable()
export class timeWarehousePayrollSettingsService {
  api = 'timeWarehousePayrollSettings/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<timeWarehousePayrollSettings[]>(this.apiURL + this.api);

  }
  get(id: number) {
    return this.http.get<timeWarehousePayrollSettings>(this.apiURL + this.api + id);
  }

  create(warehousePayrollSettings: timeWarehousePayrollSettings) {
    return this.http.post(this.apiURL + this.api, warehousePayrollSettings);
  }

  update(warehousePayrollSettings: timeWarehousePayrollSettings) {
    return this.http
      .put(this.apiURL + this.api + warehousePayrollSettings.WarehouseID, warehousePayrollSettings);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }
}

