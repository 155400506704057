import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import { secRight } from '../_models/SEC.model';

import { HttpClient } from '@angular/common/http';

@Injectable()
export class RightsService {
  api = 'secRight/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<secRight[]>(this.apiURL + this.api);

  }
  getAllActive() {
    return this.http.get<secRight[]>(this.apiURL + this.api + 'active');
  }
  create(item: secRight) {
    return this.http.post(this.apiURL + this.api, item);
  }

  update(item: secRight) {
    return this.http
      .put(this.apiURL + this.api + item.RightID, item);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getAssignedForEntityUser(id: number) {
    return this.http.get<secRight[]>(this.apiURL + this.api + 'entityUser/' + id + '/assigned');
  }

  getAvailableForEntityUser(id: number) {
    return this.http.get<secRight[]>(this.apiURL + this.api + 'entityUser/' + id + '/available');
  }

  getAdditionsForEntityUser(id: number) {
    return this.http.get<secRight[]>(this.apiURL + this.api + 'entityUser/' + id + '/additions');
  }

  getExceptionsForEntityUser(id: number) {
    return this.http.get<secRight[]>(this.apiURL + this.api + 'entityUser/' + id + '/exceptions');
  }

  putAdditionsForEntityUser(id: number, rights: secRight[]) {
    return this.http.put(this.apiURL + this.api + 'entityUser/' + id + '/additions', rights);
  }

  putExceptionsForEntityUser(id: number, rights: secRight[]) {
    return this.http.put(this.apiURL + this.api + 'entityUser/' + id + '/exceptions', rights);
  }

  getAvailableForGroup(id: number) {
    return this.http.get<secRight[]>(this.apiURL + this.api + 'group/' + id + '/available');
  }

  getAssignedForGroup(id: number) {
    return this.http.get<secRight[]>(this.apiURL + this.api + 'group/' + id + '/assigned');
  }

  putAssignedForGroup(id: number, rights: secRight[]) {
    return this.http.put(this.apiURL + this.api + 'group/' + id, rights);
  }

  getFiltered(filter: string) {
    return this.http.get(this.apiURL + this.api + 'filter/' + filter);
  }

}
