import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

// // import { server } from '../_models/index';
import { ordWarehouseFreightVw } from '../_models/DBTables.model';
import { filter } from 'minimatch';

@Injectable()
export class WarehouseFreightSelectService {
  api = 'ordWarehouseFreightVw/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAllFiltered(sourceDate: Date, WarehouseCode: string, filterStatus: string) {

    let myParams = new HttpParams();
    // if (OrderIDList) {
    //   myParams = myParams.set('idList', OrderIDList);
    // }

    if (sourceDate) {
      const tmp = new Date(sourceDate).toLocaleDateString();
      myParams = myParams.set('sourceDate', tmp);
    }
    if (filterStatus) {
      myParams = myParams.set('status', filterStatus);
    }
    if (WarehouseCode) {
      myParams = myParams.set('warehouse', WarehouseCode);
    }
    return this.http.get<ordWarehouseFreightVw[]>(this.apiURL + this.api, { params: myParams });
  }

  getAll(currentStatuses: string[], triggerStatus: string) {
    if (currentStatuses.indexOf(triggerStatus) >= 0) { // it's found
      return this.http.get<ordWarehouseFreightVw[]>(this.apiURL + this.api); // so get all
    } else {  // else, not found,
      return this.http.get<ordWarehouseFreightVw[]>(this.apiURL + this.api + '/except/' + triggerStatus); // so get all EXCEPT the trigger status
    }
  }

  getWOList(statusList: string[], sourceDate: Date, warehouseCode: string) {

    let myParams = new HttpParams();

    if (sourceDate) {
      const tmp = new Date(sourceDate).toLocaleDateString();
      myParams = myParams.set('sourceDate', tmp);
    }
    if (statusList) {
      myParams = myParams.set('status', statusList.join(','));
    }
    if (warehouseCode) {
      myParams = myParams.set('warehouse', warehouseCode);
    }
    return this.http.get<ordWarehouseFreightVw[]>(this.apiURL + this.api, { params: myParams });
  }


}
