import { IDType } from "./enums.model";

export class User {
    username: string;
    password: string;
    FirstName: string;
    LastName: string;
}

export class UserSettings {
    UserID: number;
    PersonID: number;
    LastName: string;
    FirstName: string;
    Login: string;
    DefaultWarehouseID: number;
    HomeWarehouseID: number;
    Initials: string;
    Tag: string;
    IsActive: boolean;
    OldPassword: string;
    Password: string;
    ConfirmPassword: string;
    NewPassword: boolean;
    ByUser: boolean;
    CreatedDate: Date;
    CreatedBy: number;
    UpdatedDate: Date;
    UpdatedBy: number;
    PersonName: string;
    Entities: UserEntity[];
    EntityCount: number; // for UI use only
    EntityList: string; // for UI use only

    /**
     *
     */
    constructor() {
        this.UserID = IDType.NewRec;
        this.Entities = [];
        this.HomeWarehouseID = 3;   // hardcode defaults to 3 = BLNE
        this.DefaultWarehouseID = 3;
        this.Initials = '';
        this.Tag = '';
        
    }
}

export class UserEntity {
     EntityUserID: number;
     EntityID: number;
     EntityName: string;
     Title: string;
     IsDefaultForUser: boolean;
     IsActive: boolean;
}