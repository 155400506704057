import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { AlertService, AuthService } from '../../_services/index';
import { PersistenceService } from '../../_services/persistence.service';
import { NotifierService } from '../../_sharedData/notifier.service';
import { LookupsService } from 'app/_services/lookups.service';
import { IDType } from 'app/_models/enums.model';
import { docDocumentDb, docDocumentEx, docDocumentItem, docVersion } from 'app/_models/DOC.model';
import { docDocumentService } from 'app/_data/pro-docs.service';
import { Table } from 'primeng/table';
import { ta } from 'date-fns/locale';

@Component({
  selector: 'arc-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  // ** TOGGLES ** \\
  searchingDocText: boolean = false;
  showArchiveList: boolean = false;

  // ** DATA ** \\
  selectedDocType: number = null;
  selectedItem: docDocumentItem = null;
  docText: string = null;
  // -- 
  documentCategories: SelectItem[] = [];
  documentStatuses: SelectItem[] = [];
  documentActions: SelectItem[] = [];
  filteredDocuments: docDocumentEx[];
  items: docDocumentEx[];

  // ** MISC ** \\
  titleFilter: string = '';
  iconName: string = 'pi pi-file';
  itemName = 'Documents';
  docTextButtonText: string = 'Search Document Text'
  @ViewChild('docSearch', { static: false })
  set docSearch(element: ElementRef<HTMLInputElement>) {
    if (element) {
      element.nativeElement.focus();
    }
  }

  // ** DATA TABLE ** \\
  // for table mode
  @ViewChild('dt', { static: true }) mydt: Table;

  // sorting
  multiSortMetaDefault = [{ field: 'ReferenceNumber', order: 1 }]; // , {field: 'PlaceHolder', order: 1}, {field: 'PlaceHolder', order: 1}];

  //#region FilterNames
  storedDTFilters = 'DocumentsDTFilters';
  storedInputFilters = 'DocumentsInputFilters';
  //#endregion

  localFilter = {
    DocumentCategoryID: null,
    DocumentTitle: null,
    NumberVersions: null,
    NumberAssignments: null,
    NumberTriggers: null,
    NumberNotes: null,
    NumberPublishedVersions: null,
    NumberPDF: null,
    NumberSRC: null,
    ReferenceNumber: null,
    SearchText: null,
    HasSearchText: null,
    ShowArchived: null,
    mode: 'table',
    MultiSortMeta: this.multiSortMetaDefault,
    rowsPerPage: +this.authService.currentUser.opts.PaginatorRows,
    currentRow: 0
  };

  constructor(
    private itemsService: docDocumentService,
    private authService: AuthService,
    private alert: AlertService,
    private lookups: LookupsService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private route: ActivatedRoute,
    private persist: PersistenceService,
    private notifier: NotifierService,
  ) { }

  ngOnInit() {

    this.lookups.documentCategories
      .subscribe(
        (data: SelectItem[]) => this.documentCategories = data.sort((a, b) => a.label > b.label ? 1 : -1),
        (error) => this.alert.error(error)
      );

    console.log(this.documentCategories)
    // this.loadAll();
    this.lookups.documentStatuses
      .subscribe(
        (data: SelectItem[]) => {
          this.documentStatuses = data
            .sort((a, b) => a.label > b.label ? 1 : -1);
        },
        (error) => this.alert.error(error)
      );

    this.mydt.rowsPerPageOptions = this.authService.currentUser.opts.PaginatorRowOptions;
    this.mydt.paginator = true;
    this.mydt.alwaysShowPaginator = true;

    const tmp2 = this.persist.get(this.storedInputFilters);
    if (tmp2) {
      this.localFilter = tmp2;
      this.mydt.first = this.localFilter.currentRow;

      if (this.localFilter.MultiSortMeta) {
        this.mydt.multiSortMeta = this.localFilter.MultiSortMeta;
      } else {
        this.mydt.multiSortMeta = this.multiSortMetaDefault;
      }

      if (this.localFilter.ShowArchived) {
        this.showArchiveList = this.localFilter.ShowArchived;
      } else {
        this.showArchiveList = false;
      }

      if (this.localFilter.SearchText) {
        this.docText = this.localFilter.SearchText;
        this.searchingDocText = true;
      }
    }

    const tmp = this.persist.get(this.storedDTFilters);
    if (tmp) {
      this.mydt.filters = tmp;
    }

    if (this.localFilter.SearchText) {
      this.queryDocumentText(this.localFilter.SearchText);
      this.searchingDocText = true;
      this.docTextButtonText = 'Cancel Document Search'
    } else {
      this.loadAll();
    }

  }

  loadAll() {
    this.titleFilter = '';
    this.notifier.loadingOn();
    this.itemsService.getList(this.showArchiveList)
      .pipe(finalize(() => this.notifier.loadingOff()))
      .subscribe(
        (data: docDocumentEx[]) => {
          this.items = data.sort((a, b) => a.Title > b.Title ? 1 : -1);
          this.filteredDocuments = this.items;
          console.log('got items', this.items);

          setTimeout(() => this.setTableParms(), 200);
        },
        error => {
          this.alert.error(error);
        });
  }

  setTableParms() {
    this.mydt.rows = this.localFilter.rowsPerPage; //  +this.authService.currentUser.opts.PaginatorRows;
    this.mydt.first = this.localFilter.currentRow;
  }

  checkItem(item: any) {
    item.isSelected = !item.isSelected;
  }

  addNew() {
    console.log('navigating to new rec');
    this.router.navigate(['/prodocs/document', IDType.NewRec]);
  }

  editItem(item: docDocumentDb) {
    console.log('routing ', item.DocumentID);
    this.router.navigate(['/prodocs/document', item.DocumentID]);
  }

  //#endregion

  archiveDocument(doc: docDocumentItem, doArchive: boolean) {
    // set up messages for doArchive = true/false
    const headerMsg = doArchive ? 'Confirm archive' : 'Confirm un-archive';
    const msg = doArchive ? `Are you sure you want to archive ${doc.Title}?` : `Are you sure you want to UN-archive ${doc.Title}?`;
    const successMsg = doArchive ? `${doc.Title} Archived` : `${doc.Title} UN-archived`;
    const errMsg = doArchive ? `Error archiving ${doc.Title}` : `Error UN-archiving ${doc.Title}`;
    const rejectMsg = doArchive ? 'Archive Cancelled' : 'UN-archive Cancelled';

    // note that toggleArchive just toggles the IsArchived flag on the record
    this.confirmationService.confirm({
      header: headerMsg,
      message: msg,
      accept: () => {
        this.itemsService.toggleArchive(doc.DocumentID).subscribe({
          next: (data) => {
            this.alert.success(successMsg);
            this.loadAll();
          }, error: (err) => {
            console.log(err);
            this.alert.error(errMsg);
          }
        });
      },
      reject: (type) => {
        console.log(type)
        this.alert.info(rejectMsg);
      }
    })
  }

  searchDocumentText() {
    this.searchingDocText = !this.searchingDocText;
    if (this.searchingDocText === false) {
      this.docTextButtonText = 'Search Document Text';
      this.clearLocalFilter(this.mydt)
    } else {
      this.docTextButtonText = 'Cancel Document Search';
    }
  }

  queryDocumentText(text?) {
    console.log('IM IN HERE')
    this.localFilter.SearchText = text ? text.trim() : this.docText.trim();
    this.docText = this.docText.trim();
    this.persist.set(this.storedInputFilters, this.localFilter);

    this.itemsService.searchAllText(text ? text : this.docText).subscribe({
      next: (data: docDocumentEx[]) => {
        this.items = data.sort((a, b) => a.Title > b.Title ? 1 : -1);
      }
    })
  }

  // more only for table mode --------------------------
  onSort(event) {
    this.localFilter.MultiSortMeta = event.multisortmeta;
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  onPage(event) {
    this.localFilter.rowsPerPage = event.rows;
    this.localFilter.currentRow = event.first;
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  doFilter(event) {
    // and store the filters for later recall
    this.persist.set(this.storedDTFilters, event.filters);
    this.persist.set(this.storedInputFilters, this.localFilter);
  }

  clearLocalFilter(dt: Table) {
    this.localFilter.DocumentCategoryID = null;
    this.localFilter.DocumentTitle = null;
    this.localFilter.NumberVersions = null;
    this.localFilter.NumberAssignments = null;
    this.localFilter.NumberTriggers = null;
    this.localFilter.NumberNotes = null;
    this.localFilter.NumberPublishedVersions = null;
    this.localFilter.NumberPDF = null;
    this.localFilter.NumberSRC = null;
    this.localFilter.SearchText = null;
    this.localFilter.HasSearchText = null;
    this.localFilter.ShowArchived = null;
    this.localFilter.ReferenceNumber = null;
    this.localFilter.MultiSortMeta = this.multiSortMetaDefault;
    this.localFilter.rowsPerPage = +this.authService.currentUser.opts.PaginatorRows;
    this.localFilter.mode = 'table';
    this.localFilter.currentRow = 0;

    dt.clear();
    dt.filters = {};

    this.persist.set(this.storedDTFilters, null);
    this.persist.set(this.storedInputFilters, this.localFilter);
    this.mydt.multiSortMeta = this.localFilter.MultiSortMeta;

    this.docTextButtonText = 'Search Document Text'
    this.docText = null;
    this.searchingDocText = false;
    this.loadAll();

  }

  toggleMode() {
    this.localFilter.mode = (this.localFilter.mode === 'tiles') ? 'table' : 'tiles';
    this.persist.set(this.storedInputFilters, this.localFilter);
  }
  // ------------ end of addin for table mode

  mayEdit(): boolean {
    return this.authService.userHasRight('actProDocsAdmin');
  }

  validSRC(doc: docDocumentEx): boolean {
    if (doc.VersionCount > doc.SourceFileCount)
      return false;
    else
      return true;
  }

  validPDF(doc: docDocumentEx): boolean {
    if (doc.VersionCount > doc.PublishedFileCount)
      return false;
    else
      return true;
  }

  valid(doc: docDocumentEx): boolean {
    if (doc.VersionCount === doc.PublishedFileCount && doc.VersionCount === doc.SourceFileCount)
      return true;
    else
      return false
  }

  showArchive() {
    this.showArchiveList = true;
    this.localFilter.ShowArchived = this.showArchiveList;
    this.persist.set(this.storedInputFilters, this.localFilter);
    this.loadAll();
  }
  showLive() {
    this.showArchiveList = false;
    this.localFilter.ShowArchived = this.showArchiveList;
    this.persist.set(this.storedInputFilters, this.localFilter);
    this.loadAll();
  }
}

