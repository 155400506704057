import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
    audCarrierDb,
    audImportBatchDb,
    audImportBatchEx,
    audImportItemSummary,
    P3PLShipmentPackage,
    audPackageDb,
    WLShipment,
    WLShipmentEx,
    WLPackage,
    audCompare,
    audFedExCodeDb,
    audServiceMapDb,
    audSurchargeCarrierDb,
    audSurchargeInternalDb,
    audSurchargeTypeDb,
    audChargeDb,
    audWeightsDims,
    audResidentialSummary,
    audResidentialStats,
    audSurchargeCarrierEx,
    audInvoiceSummary,
    audPackageSummary,
    audVASSummary,
    audBackBillSummary,
    audPackageBase,
    audChargeAction,
    audPendingSummary,
    audVASItem,
    audShipOrderSummary,
    audServiceCodeLookup,
    audCarrierCharge,
    audMarginSummary,
    audDimWeightSummary,
    audPendingSummaryItem,
    audNewPackage,
    audRatedCharges,
    audChargesCompare,
    audOrderPackage,
    audActionableDashboard,
    reRateRequestedServices,
    BackBillActionItem,
    audActionableFinacialDashboard,
    TrialPackage,
    audNoteDb,
    P3PLCLientShort,
    NotePack,
    reRateShippingServiceCodes,
    audPackageHistory,
    audDisputePackage,
    audCarrierInvoiceTypes
} from '../_models/AUD.model';
import { formatDate } from '@angular/common';
import { UpsertRet } from 'app/_models/API.model';
import { pack } from 'app/auditing/shipment-compare/compare-detail/compare-detail.component';


@Injectable()
export class AudCarrierService {

    api = 'audCarrier/';
    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    getByID(carrierID: number) {
        return this.http.get<audCarrierDb>(this.apiURL + this.api + '/' + carrierID);
    }

    getAll() {
        return this.http.get<audCarrierDb[]>(this.apiURL + this.api);

    }
    create(item: audCarrierDb) {
        return this.http.post(this.apiURL + this.api, item);
    }

    update(item: audCarrierDb) {
        return this.http
            .put(this.apiURL + this.api + item.CarrierID, item);
    }

    delete(id: number) {
        return this.http.delete(this.apiURL + this.api + id);
    }

    getCarrierInvoiceTypes() {
        return this.http.get<audCarrierInvoiceTypes[]>(this.apiURL + this.api + 'CarrierInvoiceTypes');
    }

}


@Injectable()
export class AudFedExCodeService {

    api = 'audFedExCode/';
    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    getByID(FedExCode: string) {
        return this.http.get<audFedExCodeDb>(this.apiURL + this.api + '/' + FedExCode);
    }

    getAll() {
        return this.http.get<audFedExCodeDb[]>(this.apiURL + this.api);

    }
    create(item: audFedExCodeDb) {
        return this.http.post(this.apiURL + this.api, item);
    }

    update(item: audFedExCodeDb) {
        return this.http
            .put(this.apiURL + this.api + item.FedExCode, item);
    }

    delete(id: string) {
        return this.http.delete(this.apiURL + this.api + id);
    }
}


@Injectable()
export class AudServiceMapService {

    api = 'audServiceMap/';
    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    getByID(serviceMapID: number) {
        return this.http.get<audServiceMapDb>(this.apiURL + this.api + '/' + serviceMapID);
    }

    getAll() {
        return this.http.get<audServiceMapDb[]>(this.apiURL + this.api);

    }
    create(item: audServiceMapDb) {
        return this.http.post(this.apiURL + this.api, item);
    }

    update(item: audServiceMapDb) {
        return this.http
            .put(this.apiURL + this.api + item.ServiceMapID, item);
    }

    delete(id: number) {
        return this.http.delete(this.apiURL + this.api + id);
    }
}

// audSurchargeCarrier

@Injectable()
export class AudSurchargeCarrierService {

    api = 'audSurchargeCarrier/';
    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    getByID(surchargeCarrierID: number) {
        return this.http.get<audSurchargeCarrierDb>(this.apiURL + this.api + surchargeCarrierID);
    }

    getAll() {
        console.log('going to get all surcharge carrier');
        return this.http.get<audSurchargeCarrierDb[]>(this.apiURL + this.api);
    }

    getExByID(surchargeCarrierID: number) {
        return this.http.get<audSurchargeCarrierDb>(this.apiURL + this.api + 'ex/' + surchargeCarrierID);
    }

    getAllExShort() {
        return this.http.get<audSurchargeCarrierEx[]>(this.apiURL + this.api + 'exShort');
    }

    getAllEx() {
        return this.http.get<audSurchargeCarrierEx[]>(this.apiURL + this.api + 'ex');
    }

    create(item: audSurchargeCarrierDb) {
        return this.http.post(this.apiURL + this.api, item);
    }

    update(item: audSurchargeCarrierDb) {
        return this.http
            .put(this.apiURL + this.api + item.SurchargeCarrierID, item);
    }

    delete(id: number) {
        return this.http.delete(this.apiURL + this.api + id);
    }
}


// audSurchargeInternal

@Injectable()
export class AudSurchargeInternalService {

    api = 'audSurchargeInternal/';
    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    getByID(SurchargeIntenalID: number) {
        return this.http.get<audSurchargeInternalDb>(this.apiURL + this.api + '/' + SurchargeIntenalID);
    }

    getAll() {
        return this.http.get<audSurchargeInternalDb[]>(this.apiURL + this.api);

    }
    create(item: audSurchargeInternalDb) {
        return this.http.post(this.apiURL + this.api, item);
    }

    update(item: audSurchargeInternalDb) {
        return this.http
            .put(this.apiURL + this.api + item.SurchargeIntenalID, item);
    }

    delete(id: number) {
        return this.http.delete(this.apiURL + this.api + id);
    }
}

// audSurchargeType

@Injectable()
export class AudSurchargeTypeService {

    api = 'audSurchargeType/';
    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    getByID(surchargeTypeID: number) {
        return this.http.get<audSurchargeTypeDb>(this.apiURL + this.api + '/' + surchargeTypeID);
    }

    getAll() {
        return this.http.get<audSurchargeTypeDb[]>(this.apiURL + this.api);

    }
    create(item: audSurchargeTypeDb) {
        return this.http.post(this.apiURL + this.api, item);
    }

    update(item: audSurchargeTypeDb) {
        return this.http
            .put(this.apiURL + this.api + item.SurchargeTypeID, item);
    }

    delete(id: number) {
        return this.http.delete(this.apiURL + this.api + id);
    }
}

@Injectable()
export class AudImportBatchService {
    api = 'audImportBatch/';
    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    getByCarrier(carrierID: number) {
        return this.http.get<audImportBatchEx[]>(this.apiURL + this.api + 'carrier/' + carrierID);
    }

    getByID(batchID: number) {
        return this.http.get<audImportBatchEx>(this.apiURL + this.api + 'ex/' + batchID);
    }

    getAll() {
        return this.http.get<audImportBatchEx[]>(this.apiURL + this.api);

    }
    create(item: audImportBatchDb) {
        return this.http.post(this.apiURL + this.api, item);
    }

    update(item: audImportBatchDb) {
        return this.http
            .put(this.apiURL + this.api + item.ImportBatchID, item);
    }

    delete(id: number) {
        return this.http.delete(this.apiURL + this.api + id);
    }

    getBatchItemSummaries(batchID: number) {
        return this.http.get<audImportItemSummary[]>(this.apiURL + this.api + 'batchItems/' + batchID);
    }
}

@Injectable()
export class AudBackBillService {
    api = 'audBackBill/';
    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    getAll(fromDate: Date, toDate: Date, displayOption: string) {
        const tmpFrom = new Date(fromDate).toLocaleDateString();
        const tmpTo = new Date(toDate).toLocaleDateString();

        let params = new HttpParams();
        params = params.append('fromDate', tmpFrom);
        params = params.append('toDate', tmpTo);
        params = params.append('displayOption', displayOption);
        console.log('in get all', params);

        return this.http.get<audBackBillSummary[]>(this.apiURL + this.api, { params: params });
    }

}

// this service gets an audPackage structure from the aud.Packages table
@Injectable()
export class AudPackageService {
    api = 'audPackage/';
    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    getByID(PackageID: number) {
        console.log('getting AuditPackage by ID:', PackageID);
        return this.http.get<audPackageDb>(this.apiURL + this.api + PackageID);
    }

    getByTrackingNumber(TrackingNumber: string) {
        console.log('getting AuditPackage by tracking Number', TrackingNumber);
        return this.http.get<audPackageDb[]>(this.apiURL + this.api + 'trackingNumber/' + TrackingNumber);
    }

    getByOrderID(OrderID: string) {
        console.log('getting AuditPackage[] by OrderID', OrderID);
        return this.http.get<audPackageDb[]>(this.apiURL + this.api + 'orderID/' + OrderID);
    }

    savePackage(Package: audPackageDb) {
        console.log('Saving Package to audPackageDb, Package:', Package);
        return this.http.put<UpsertRet>(this.apiURL + this.api + Package.PackageID, Package);
    }

    getP3PLClients() {
        return this.http.get<P3PLCLientShort[]>(this.apiURL + this.api + 'clients');
    }

    updateClientWarehouse(pack: pack) {
        // console.log(pack)
        return this.http.post<boolean>(this.apiURL + this.api + 'updatePackages', pack);
    }

    updateFromOrder(packageID: number, orderID: number) {
        console.log(packageID, orderID);
        return this.http.post<boolean>(this.apiURL + this.api + 'updatePackageFromOrder/' + packageID, orderID);
    }

    reImport(packageID: number) {
        return this.http.post<boolean>(this.apiURL + this.api + 'reImportPackage/' + packageID, null);
    }

    getPackageHistory(packageID: number) {
        return this.http.get<audPackageHistory[]>(this.apiURL + this.api + 'packageHistory/' + packageID);
    }

    getPackagesInDispute() {
        return this.http.get<audDisputePackage[]>(this.apiURL + this.api + 'disputePackages');
    }
}

// this service gets Charges from the aud.CHarges table
@Injectable()
export class AudChargeService {
    api = 'audCharge/';
    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    getByID(ChargeID: number) {
        return this.http.get<audChargeDb>(this.apiURL + this.api + ChargeID);
    }

    getForPackage(PackageID: number) {
        return this.http.get<audChargeDb[]>(this.apiURL + this.api + 'package/' + PackageID);
    }
}

// this service gets a 'dynamic' structure from a requested carrier specific table
@Injectable()
export class AudImportPackageService {
    api = 'audImportPackage/';
    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    getByID(carrierID: number, importPackageID: number) {
        return this.http.get<audImportBatchEx>(this.apiURL + this.api + carrierID + '/' + importPackageID);
    }

}

@Injectable()
export class P3PLShipmentPackageService {
    api = 'P3PLShipmentPackage/';
    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    getByID(shipmentPackageID: number) {
        return this.http.get<audImportBatchEx>(this.apiURL + this.api + shipmentPackageID);
    }

    getShipmentSummary(shipmentPackageID: number) {
        return this.http.get<audImportItemSummary[]>(this.apiURL + this.api + 'summary/' + shipmentPackageID);
    }

    update(item: P3PLShipmentPackage) {
        return this.http.put(this.apiURL + this.api + item.PackageID, item);
    }
    getOrderID(shipmentPackageID: number) {
        return this.http.get<number>(this.apiURL + this.api + 'OrderID/' + shipmentPackageID);
    }
}

@Injectable()
export class WLShipmentService {
    api = 'WLShipment/';
    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    getByID(shipmentID: number) {
        console.log('getting worldLink shipment', shipmentID);
        return this.http.get<WLShipment>(this.apiURL + this.api + shipmentID);
    }

    getExByID(shipmentID: number) {
        console.log('getting worldLink shipmentEx', shipmentID);
        return this.http.get<WLShipmentEx>(this.apiURL + this.api + 'ex/' + shipmentID);
    }

    getShipmentPackages(shipmentID: number) {
        console.log('getting worldLink shipment packages', shipmentID);
        return this.http.get<WLPackage[]>(this.apiURL + this.api + 'packages/' + shipmentID);
    }
}

@Injectable()
export class WLPackageService {
    api = 'WLPackage/';

    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    getByID(packageID: number) {
        console.log('getting worldLink package ', packageID);
        return this.http.get<WLPackage>(this.apiURL + this.api + packageID);
    }

    // getExByID(packageID: number) {
    //     return this.http.get<WLShipmentEx>(this.apiURL + this.api + "ex/" + packageID);
    // }

    // getShipmentPackages(shipmentID: number) {
    //     return this.http.get<WLPackage[]>(this.apiURL + this.api + "packages/" + shipmentID);
    // }

}

@Injectable()
export class AuditActionService {
    api = 'audAction/';

    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    chargeAction(action: audChargeAction) {
        return this.http.post(this.apiURL + this.api + 'charge', action);
    }

    chargeActionMultiple(actions: audChargeAction[]) {
        return this.http.post(this.apiURL + this.api + 'chargeMultiple', actions);
    }

    flagPackage(packageID: number) {
        return this.http.put(this.apiURL + this.api + 'flagPackage/' + packageID, null);
    }
    flagPackageMultiple(actions: audChargeAction[]) {
        return this.http.put(this.apiURL + this.api + 'flagPackageMultiple', actions);
    }

    flagInvoice(invoiceNumber: string) {
        return this.http.put(this.apiURL + this.api + 'flagInvoice/' + invoiceNumber, null);
    }

    chargeVASMultiple(actions: audVASItem[]) {
        return this.http.post(this.apiURL + this.api + 'chargeMultiple', actions);
    }

}

@Injectable()
export class AuditCompareService {
    api = 'audCompare/';

    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    getForPackage(packageID: number) {
        return this.http.get<audCompare>(this.apiURL + this.api + packageID);
    }

    getChargeDetailForpackage(packageID: number) {
        return this.http.get<audChargesCompare[]>(this.apiURL + this.api + 'package/' + packageID);
    }
}

@Injectable()
export class AuditWeightsDimsService {
    api = 'audWeightsDims/';

    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    getAll(fromDate: Date, toDate: Date) {
        const tmpFrom = new Date(fromDate).toLocaleDateString();
        const tmpTo = new Date(toDate).toLocaleDateString();

        let params = new HttpParams();
        params = params.append('fromDate', tmpFrom);
        params = params.append('toDate', tmpTo);

        return this.http.get<audWeightsDims[]>(this.apiURL + this.api, { params: params });
    }

    getForPackage(packageID: number) {
        return this.http.get<audWeightsDims>(this.apiURL + this.api + packageID);
    }

    getForShipDates(fromDate: Date, toDate: Date) {
        const tmpFrom = new Date(fromDate).toLocaleDateString();
        const tmpTo = new Date(toDate).toLocaleDateString();

        let params = new HttpParams();
        params = params.append('fromDate', tmpFrom);
        params = params.append('toDate', tmpTo);

        return this.http.get<audWeightsDims[]>(this.apiURL + this.api + 'shipDates', { params: params });
    }

    getForInvoiceDates(fromDate: Date, toDate: Date) {
        const tmpFrom = new Date(fromDate).toLocaleDateString();
        const tmpTo = new Date(toDate).toLocaleDateString();

        let params = new HttpParams();
        params = params.append('fromDate', tmpFrom);
        params = params.append('toDate', tmpTo);

        return this.http.get<audWeightsDims[]>(this.apiURL + this.api + 'invoiceDates', { params: params });
    }
}

@Injectable()
export class AuditResidentialService {
    api = 'audResidential/';

    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    getSummary(groupBy: string, fromDate: Date, toDate: Date, warehouseID: string) {

        // let headers = new HttpHeaders();
        // headers = headers.append('Content-Type', 'application/json');

        const tmpFrom = new Date(fromDate).toLocaleDateString();
        const tmpTo = new Date(toDate).toLocaleDateString();

        let params = new HttpParams();
        params = params.append('fromDate', tmpFrom);
        params = params.append('toDate', tmpTo);
        params = params.append('warehouseID', warehouseID);

        return this.http.get<audResidentialSummary[]>(this.apiURL + this.api + 'summary/' + groupBy, { params: params });
    }

    getStats(fromDate: Date, toDate: Date, warehouseID: string) {
        const tmpFrom = new Date(fromDate).toLocaleDateString();
        const tmpTo = new Date(toDate).toLocaleDateString();

        let params = new HttpParams();
        params = params.append('fromDate', tmpFrom);
        params = params.append('toDate', tmpTo);
        params = params.append('warehouseID', warehouseID);

        return this.http.get<audResidentialStats>(this.apiURL + this.api + 'stats', { params: params });
    }
}

@Injectable()
export class AudDashboardService {
    api = 'audDashboard/';

    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    getMarginSummary(groupBy: string, fromDate: Date, toDate: Date) {
        const tmpFrom = new Date(fromDate).toLocaleDateString();
        const tmpTo = new Date(toDate).toLocaleDateString();

        let params = new HttpParams();
        params = params.append('fromDate', tmpFrom);
        params = params.append('toDate', tmpTo);

        return this.http.get<audMarginSummary[]>(this.apiURL + this.api + 'margin/' + groupBy, { params: params });
    }

    getDimWeightSummary(groupBy: string, fromDate: Date, toDate: Date) {
        const tmpFrom = new Date(fromDate).toLocaleDateString();
        const tmpTo = new Date(toDate).toLocaleDateString();

        let params = new HttpParams();
        params = params.append('fromDate', tmpFrom);
        params = params.append('toDate', tmpTo);

        return this.http.get<audDimWeightSummary[]>(this.apiURL + this.api + 'dimWeight/' + groupBy, { params: params });
    }

    getResidentialSummary(groupBy: string, fromDate: Date, toDate: Date) {
        const tmpFrom = new Date(fromDate).toLocaleDateString();
        const tmpTo = new Date(toDate).toLocaleDateString();

        let params = new HttpParams();
        params = params.append('fromDate', tmpFrom);
        params = params.append('toDate', tmpTo);

        return this.http.get<audResidentialSummary[]>(this.apiURL + this.api + 'residential/' + groupBy, { params: params });
    }

    getPendingSummary(fromDate: Date, toDate: Date) {
        const tmpFrom = new Date(fromDate).toLocaleDateString();
        const tmpTo = new Date(toDate).toLocaleDateString();

        let params = new HttpParams();
        params = params.append('fromDate', tmpFrom);
        params = params.append('toDate', tmpTo);

        return this.http.get<audPendingSummaryItem[]>(this.apiURL + this.api + 'pending', { params: params });
    }
}

@Injectable()
export class AudInvoiceSummaryService {
    api = 'audInvoiceDashboard/';

    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    getAll(fromDate: Date, toDate: Date) {
        const tmpFrom = new Date(fromDate).toLocaleDateString();
        const tmpTo = new Date(toDate).toLocaleDateString();

        let params = new HttpParams();
        params = params.append('fromDate', tmpFrom);
        params = params.append('toDate', tmpTo);

        return this.http.get<audInvoiceSummary[]>(this.apiURL + this.api, { params: params });
    }

    getByNumber(invoiceNumber: string) {
        return this.http.get<audInvoiceSummary>(this.apiURL + this.api + 'invoiceNumber/' + invoiceNumber);
    }

}

@Injectable()
export class AudPackageSummaryService {
    api = 'audPackageSummary/';

    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    getPackage(PackageID: number) {
        return this.http.get<audPackageBase>(this.apiURL + this.api + PackageID);
    }

    getRelatedForShipPackageID(carrierID: number, shipmentPackageID: number) {
        let params = new HttpParams();
        params = params.append('CarrierID', carrierID.toString());
        params = params.append('ShipPackageID', shipmentPackageID.toString());

        return this.http.get<audPackageBase[]>(this.apiURL + this.api + 'related', { params: params });
    }

    getRelatedProAuditForShipPackageID(PackageID: number) {
        let params = new HttpParams();
        params = params.append('PackageID', PackageID.toString());

        return this.http.get<audOrderPackage[]>(this.apiURL + this.api + 'proAuditRelated', { params: params });
    }

    getAllForInvoice(invoiceNumber: string, invoiceDate: string) {
        console.log('get AllForInvoice', invoiceNumber, invoiceDate);
        let params = new HttpParams();
        params = params.append('InvoiceNumber', invoiceNumber);
        params = params.append('InvoiceDate', invoiceDate);
        return this.http.get<audPackageSummary[]>(this.apiURL + this.api + 'invoice', { params: params });
    }

}

@Injectable()
export class AudShipSearchService {
    api = 'audShipSearch/';

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    getByTrackingNumber(trackingNumber: string) {
        return this.http.get<audShipOrderSummary[]>(
            this.apiURL + this.api + 'TrackingNumber/' + trackingNumber
        );
    }

    getByReferenceNumber(referenceNumber: string) {
        return this.http.get<audShipOrderSummary[]>(
            this.apiURL + this.api + 'ReferenceNumber/' + referenceNumber
        );
    }

    getByOrderID(orderID: number) {
        return this.http.get<audShipOrderSummary[]>(
            this.apiURL + this.api + 'OrderID/' + orderID
        );
    }
}

@Injectable()
export class AudVASService {
    api = 'audVAS/';

    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    getAll(fromDate: Date, toDate: Date, displayOption: string) {
        const tmpFrom = new Date(fromDate).toLocaleDateString();
        const tmpTo = new Date(toDate).toLocaleDateString();

        let params = new HttpParams();
        params = params.append('fromDate', tmpFrom);
        params = params.append('toDate', tmpTo);
        params = params.append('displayOption', displayOption);

        return this.http.get<audVASSummary[]>(this.apiURL + this.api, { params: params });
    }

    getCharges(packageID: number) {
        return this.http.get<audCarrierCharge[]>(this.apiURL + this.api + 'charges/' + packageID);
    }

    fetchServiceCodeMarkups(items: audServiceCodeLookup) {
        return this.http.put<audServiceCodeLookup>(this.apiURL + this.api + 'markups', items);
    }
}


@Injectable()
export class AudPendingService {
    api = 'audPending/';

    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    getSummary(type: string, fromDate: Date, toDate: Date) {

        console.log('in getSummary', type, fromDate, toDate);
        switch (type.toLowerCase()) {
            case 'carrier':
                return this.getCarrierSummary(fromDate, toDate);
            case 'service':
                return this.getServiceSummary(fromDate, toDate);
            case 'order':
                return this.getOrderSummary(fromDate, toDate);
            case 'package':
                return this.getPackageSummary(fromDate, toDate);
        }
    }

    getCarrierSummary(fromDate: Date, toDate: Date) {
        let params = new HttpParams();
        params = params.append('FromDate', new Date(fromDate).toLocaleDateString());
        params = params.append('ToDate', new Date(toDate).toLocaleDateString());

        return this.http.get<audPendingSummary[]>(this.apiURL + this.api + 'Carrier', { params: params });
    }

    getServiceSummary(fromDate: Date, toDate: Date) {
        let params = new HttpParams();
        params = params.append('FromDate', new Date(fromDate).toLocaleDateString());
        params = params.append('ToDate', new Date(toDate).toLocaleDateString());

        return this.http.get<audPendingSummary[]>(this.apiURL + this.api + 'Service', { params: params });
    }

    getOrderSummary(fromDate: Date, toDate: Date) {
        let params = new HttpParams();
        params = params.append('FromDate', new Date(fromDate).toLocaleDateString());
        params = params.append('ToDate', new Date(toDate).toLocaleDateString());

        return this.http.get<audPendingSummary[]>(this.apiURL + this.api + 'Order', { params: params });
    }

    getPackageSummary(fromDate: Date, toDate: Date) {
        let params = new HttpParams();
        params = params.append('FromDate', new Date(fromDate).toLocaleDateString());
        params = params.append('ToDate', new Date(toDate).toLocaleDateString());

        return this.http.get<audPendingSummary[]>(this.apiURL + this.api + 'Package', { params: params });
    }
}

@Injectable()
export class AudPending2Service {
    api = 'audPending_v2/';

    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    getSummary(type: string, fromDate: Date, toDate: Date) {

        console.log('in getSummary', type, fromDate, toDate);
        switch (type.toLowerCase()) {
            case 'carrier':
                return this.getCarrierSummary(fromDate, toDate);
            case 'service':
                return this.getServiceSummary(fromDate, toDate);
            case 'order':
                return this.getOrderSummary(fromDate, toDate);
            case 'package':
                return this.getPackageSummary(fromDate, toDate);
        }
    }

    getCarrierSummary(fromDate: Date, toDate: Date) {
        let params = new HttpParams();
        params = params.append('FromDate', new Date(fromDate).toLocaleDateString());
        params = params.append('ToDate', new Date(toDate).toLocaleDateString());

        return this.http.get<audPendingSummary[]>(this.apiURL + this.api + 'Carrier', { params: params });
    }

    getServiceSummary(fromDate: Date, toDate: Date) {
        let params = new HttpParams();
        params = params.append('FromDate', new Date(fromDate).toLocaleDateString());
        params = params.append('ToDate', new Date(toDate).toLocaleDateString());

        return this.http.get<audPendingSummary[]>(this.apiURL + this.api + 'Service', { params: params });
    }

    getOrderSummary(fromDate: Date, toDate: Date) {
        let params = new HttpParams();
        params = params.append('FromDate', new Date(fromDate).toLocaleDateString());
        params = params.append('ToDate', new Date(toDate).toLocaleDateString());

        return this.http.get<audPendingSummary[]>(this.apiURL + this.api + 'Order', { params: params });
    }

    getPackageSummary(fromDate: Date, toDate: Date) {
        let params = new HttpParams();
        params = params.append('FromDate', new Date(fromDate).toLocaleDateString());
        params = params.append('ToDate', new Date(toDate).toLocaleDateString());

        return this.http.get<audPendingSummary[]>(this.apiURL + this.api + 'Package', { params: params });
    }
}

@Injectable()
export class AudCompareService {
    api = 'audGetComparedRecords/';

    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    getDashboardData() {
        return this.http.get<audActionableDashboard[]>(this.apiURL + this.api + 'GetDashboardData');
    }

    getDashboardFinancialData() {
        return this.http.get<audActionableFinacialDashboard[]>(this.apiURL + this.api + 'GetDashboardFinancialData');
    }

    getComparedRecords(invoice?: string) {
        return this.http.get<audNewPackage[]>(this.apiURL + this.api + 'GetComparedRecords/' + (invoice ? invoice : null));
    }

    getComparedRecordsByCharge(charge: string, invoice: string) {
        return this.http.get<audNewPackage[]>(this.apiURL + this.api + 'GetComparedRecordsByCharge/' + charge + '/' + (invoice ? invoice : null));
    }

    getSingleComparedRecord(packageID: number) {
        return this.http.get<audNewPackage>(this.apiURL + this.api + 'GetComparedRecordByID/' + packageID);
    }

    getServiceSummary() {
        return this.http.get(this.apiURL + this.api);
    }
}


@Injectable()
export class AudActionService {
    api = 'audAction/';

    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    bulkBackBill(actionItem: BackBillActionItem) {
        return this.http.post<any>(this.apiURL + this.api + 'bulkbackbill', actionItem);
    }

    bulkIgnore(packageIDs: number[]) {
        return this.http.post<audNewPackage[]>(this.apiURL + this.api + 'bulkignore', packageIDs);
    }

    unBackBill(packageID: number) {
        return this.http.post<boolean>(this.apiURL + this.api + 'unbackbillPackage/' + packageID, null);
    }

    unIgnorePackage(packageID: number) {
        return this.http.post<boolean>(this.apiURL + this.api + 'unignorePackage/' + packageID, null);
    }

    backBillCharge(chargeID: number, amount: number) {
        return this.http.post<boolean>(this.apiURL + this.api + 'backBillCharge/' + chargeID, amount);
    }

    unBackBillCharge(chargeID: number) {
        return this.http.post<boolean>(this.apiURL + this.api + 'unbackBillCharge/' + chargeID, null);
    }

    ignoreCharge(chargeID: number) {
        return this.http.post<boolean>(this.apiURL + this.api + 'ignoreCharge/' + chargeID, null);
    }

    unIgnoreCharge(chargeID: number) {
        return this.http.post<boolean>(this.apiURL + this.api + 'unignoreCharge/' + chargeID, null);
    }

    revertCharge(PackageID: number, includeReRate: boolean) {
        return this.http.post<boolean>(this.apiURL + this.api + 'revertCharges/' + includeReRate, PackageID);
    }

    disputeBulk(packageIDs: number[]) {
        return this.http.post<audNewPackage[]>(this.apiURL + this.api + 'disputeBulk', packageIDs);
    }

    acceptBulk(packageIDs: number[]) {
        return this.http.post<audNewPackage[]>(this.apiURL + this.api + 'acceptBulk', packageIDs);
    }

    chargeAction(Action: 'DisputeCharge' | 'DisputePackage' | 'AcceptCharge' | 'AcceptPackage' | 'UnDisputeCharge' | 'UnDisputePackage' | 'UnAcceptCharge' | 'UnAcceptPackage', ID: number) {
        return this.http.post<boolean>(this.apiURL + this.api + 'chargeAction/' + Action + '/' + ID, null);
    }

    forceClosePackage(packages: number[]) {
        return this.http.post<boolean>(this.apiURL + this.api + 'forceClosePackage', packages);
    }
}

@Injectable()
export class AudRerateService {
    api = 'audRerate/';

    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    bulkRerate(packageIDs: number[], isActionablePage: boolean) {
        return this.http.post<any>(this.apiURL + this.api + 'ReRate/' + isActionablePage, packageIDs);
    }

    trialReRate(pkg: TrialPackage) {
        return this.http.post<audChargesCompare[]>(this.apiURL + this.api + 'TrialReRate', pkg);
    }
}

@Injectable()
export class AuedExpectedChargesService {
    api = 'audExpectedCharge/'

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    getExpectedChargesForPackageID(packageID: number) {
        return this.http.get<audRatedCharges[]>(this.apiURL + this.api + 'package/' + packageID);
    }

}

@Injectable()
export class ReRateRequestedServicesService {
    api = 'RequestedServices/'

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    getForPackageID(packageID: number) {
        return this.http.get<reRateRequestedServices[]>(this.apiURL + this.api + 'servicesFor/' + packageID);
    }

    getShippingServiceCodes(packageID: number) {
        return this.http.get<reRateShippingServiceCodes[]>(this.apiURL + this.api + 'ShippingServiceCodes/' + packageID);
    }
}

@Injectable({
    providedIn: 'root'
})
export class AudNotesService {

    api = 'audNote/';

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    getAll() {
        return this.http.get<audNoteDb[]>(this.apiURL + this.api);
    }

    create(item: audNoteDb) {
        return this.http.post(this.apiURL + this.api, item);
    }

    update(item: audNoteDb) {
        return this.http.put(this.apiURL + this.api + item.NoteID, item);
    }

    delete(id: number) {
        console.log('deleting note with id:', id);
        return this.http.delete(this.apiURL + this.api + id);
    }

    getNotes(packageID: number) {
        return this.http.get<audNoteDb[]>(this.apiURL + this.api + 'package/' + packageID);
    }

    getNoteCount(packageID: number) {
        console.log('getting note count for packageID:', packageID);
        return this.http.get<number>(this.apiURL + this.api + 'package/count/' + packageID);
    }

    setBulkNotes(notePack: NotePack) {
        console.log(notePack)
        return this.http.post<boolean>(this.apiURL + this.api + 'bulk', notePack);
    }

}
