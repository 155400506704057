<div class="'p-grid">
    <div class="p-col-8 p-offset-2">
        <p-panel [ngStyle]="{'width':'80%'}">
            <p-header>
                Quiz Info
            </p-header>
            <div class="p-grid">
                <div class="p-col-2" style="text-align:right">
                    Description:
                </div>
                <div class="p-col-6" *ngIf="quiz">
                    <input pInputText [(ngModel)]="quiz.Description">
                </div>
                <div class="p-col-2" style="text-align:right">
                    Required Score:
                </div>
                <div class="p-col-2">
                    <input type="text" arcDecimalInput [(ngModel)]="requiredScore" id="RequiredScore"
                    name="RequiredScore" (change)="RequiredScoreChanged()" #RequiredScore="ngModel"
                    style="width:25%; text-align:right;" onfocus="this.select();"> %
                </div>
            </div>
        </p-panel>
        <hr>
        <p-treeTable #tt [value]="quizData" *ngIf="showEditor" selectionMode="single" [(selection)]="selectedQuestion"
            dataKey="keyid" (onEditComplete)="onEditComplete($event)">
            <ng-template pTemplate="caption">
                <div style="width:100%; text-align: center;font-size:larger">
                    Edit Quiz
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width:11%"></th>
                    <th style="width:5%" ttSortableColumn="sequence">
                        Seq
                        <p-treeTableSortIcon [field]="'sequence'"></p-treeTableSortIcon>
                    </th>
                    <th style="width:35%">Text</th>
                    <!-- <th style="width:31%">Notes</th> -->
                    <th style="width:8%"></th>
                    <th style="width:8%">
                        <p-button icon="pi pi-plus" label="Question" (click)="addQuestion($event)"></p-button>
                    </th>
                    <th style="width:3%"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                <tr [ttRow]="rowNode" [ttSelectableRow]="rowNode">
                    <td [ngClass]="p-toggler-column">
                        <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                        {{ rowData.toggle }}
                    </td>
                    <!-- SEQUENCE -->
                    <td [ttEditableColumn]="rowData" [ttEditableColumnField]="'sequence'">
                        <p-treeTableCellEditor>
                            <ng-template pTemplate="input">
                                <p-inputNumber [(ngModel)]="rowData.sequence" [showButtons]="true" [step]="1">
                                </p-inputNumber>
                            </ng-template>
                            <ng-template pTemplate="output">{{rowData.sequence}}</ng-template>
                        </p-treeTableCellEditor>
                    </td>
                    <!-- RESPONSE ROW -->
                    <td [ttEditableColumn]="rowData" [ttEditableColumnField]="'text'"
                        [ngClass]="{'responseRow': rowData.response, 'questionRow': !rowData.response}">
                        <p-treeTableCellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" placeholder="Type here" [(ngModel)]="rowData.text">
                            </ng-template>
                            <ng-template pTemplate="output">{{rowData.text || 'New'}}</ng-template>
                        </p-treeTableCellEditor>
                    </td>
                    <!-- NOTES -->
                    <!-- <td [ttEditableColumn]="rowData" [ttEditableColumnField]="'notes'"
                        [ngClass]="{'responseRow': rowData.response, 'questionRow': !rowData.response}">
                        <p-treeTableCellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="rowData.notes">
                            </ng-template>
                            <ng-template pTemplate="output">{{rowData.notes}}</ng-template>
                        </p-treeTableCellEditor>
                    </td> -->
                    <!-- IS CORRECT -->
                    <td>
                        <div style="text-align:right">
                            <p-toggleButton *ngIf="rowData.response" [(ngModel)]="rowData.isCorrect" onLabel="Correct"
                                offLabel="Incorrect" offIcon="pi pi-times" [onIcon]="'pi pi-check'"
                                (onChange)="onChangeToggle(rowData)"></p-toggleButton>
                        </div>
                    </td>
                    <!-- ADD A RESPONSE -->
                    <td>
                        <p-button *ngIf="!rowData.response" icon="pi pi-plus" label="Response"
                            (onClick)="addResponse(rowData, $event)"></p-button>
                    </td>
                    <td style="text-align:center">
                        <p-button icon="pi pi-trash" (click)="onConfirmDelete(rowData)"></p-button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptyMessage">
                <tr>
                    <td colspan="6">
                        No quiz questions found.
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div style="display:flex; justify-content:space-evenly;">
                    <button pButton style="width: 15%;" icon="pi pi-save" label="Save" (click)="saveQuiz()"></button>
                </div>
            </ng-template>
        </p-treeTable>
    </div>
</div>