import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import { cfgWarehouse } from '../_models/CFG.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class WarehousesService {


  api = 'cfgWarehouse/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<cfgWarehouse[]>(this.apiURL + this.api);

  }
  getByID(id: number) {
    return this.http.get<cfgWarehouse>(this.apiURL + this.api + id);
  }
  create(warehouse: cfgWarehouse) {
    return this.http.post(this.apiURL + this.api, warehouse);
  }

  update(warehouse: cfgWarehouse) {
    return this.http
      .put(this.apiURL + this.api + warehouse.WarehouseID, warehouse);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

}

