<div *ngIf='!validating; else waiting'>
    <h1 style="margin: 0; text-align: center;"> ProPack User Validations </h1>
    <p-toolbar [ngStyle]="{'padding':'5px'}">
        <div class="p-toolbar-group-left" style="padding: 5px; ">

            <button pButton pRipple label="Change Password" icon="pi pi-lock" [ngStyle]="{'padding-left':'5px'}"
                *ngIf="changePass_tab"></button>
            <i class="pi pi-chevron-right" style="padding: 5px; font-weight: bold;" *ngIf="changePass_tab"></i>

            <button pButton pRipple label="Clock In" icon="pi pi-clock" [ngStyle]="{'padding-left':'5px'}"
                style="padding-left: 5px;" *ngIf="clockIn_tab" (click)="clockIn = true"></button>
            <i class="pi pi-chevron-right" style="padding: 5px; font-weight: bold;" *ngIf="clockIn_tab"></i>

            <button pButton pRipple label="Change PIN" icon="pi pi-tablet" [ngStyle]="{'padding-left':'5px'}"
                *ngIf="changePIN_tab"></button>
            <i class="pi pi-chevron-right" style="padding: 5px; font-weight: bold;" *ngIf="changePIN_tab"></i>

            <button pButton pRipple label="Read SOPs" icon="pi pi-file-o" [ngStyle]="{'padding-left':'5px'}"
                *ngIf="readSOPs_tab"></button>
            <i class="pi pi-chevron-right" style="padding: 5px; font-weight: bold;" *ngIf="readSOPs_tab"></i>

            <button pButton pRipple label="Employee Stop" icon="pi pi-ban" [ngStyle]="{'padding-left':'5px'}"
                *ngIf="employeeStop_tab"></button>
            <i class="pi pi-chevron-right" style="padding: 5px; font-weight: bold;" *ngIf="employeeStop_tab"></i>

            <button pButton pRipple label="Notes" icon="pi pi-folder-open" [ngStyle]="{'padding-left':'5px'}"
                *ngIf="employeeNotes_tab"></button>
                <i class="pi pi-chevron-right" style="padding: 5px; font-weight: bold;" *ngIf="employeeNotes_tab"></i>

        </div>

        <div class="p-toolbar-group-right">
            <button pButton pRipple label="Check Validations" icon="pi pi-refresh" iconPos="left"
                (click)="runValidations()"></button>
            <i class="pi pi-ellipsis-v" style="padding: 5px; font-weight: bold;"></i>
            <button pButton pRipple label="Logout" icon="pi pi-sign-out" iconPos="right" (click)="logout()"></button>
        </div>
    </p-toolbar>

    <br>
    <!-- CLOCK IN -->
    <p-dialog header="GoTime: {{ selectedValidation.ValidationMessages }} " [(visible)]="clockIn" *ngIf="clockIn"
        [modal]="true" showEffect="fade" [style]="{'width':'1500px'} " (onHide)="closeGoTime()">
        <arc-time-home [pageTitle]="GoTime" [isValidated]="false" (out)="kioskFinished()"></arc-time-home>
        <!-- (out)="clockInSuccess()" -->
        <p-footer>
            {{ currentUser?.PersonName}}
        </p-footer>
    </p-dialog>

    <!-- CLOCK IN -->
    <p-dialog header="ProDocs: {{ selectedValidation.ValidationMessages }} " [(visible)]="readSOPs" *ngIf="readSOPs"
        [modal]="true" showEffect="fade" [style]="{'width':'1500px'} " (onHide)="closeGoTime()">
        <arc-prodoc-reader-home></arc-prodoc-reader-home>
        <!-- (out)="clockInSuccess()" -->
        <p-footer>
            {{ currentUser?.PersonName}}
        </p-footer>
    </p-dialog>

    <arc-change-pass-pin [changePass]="changePass" [selectedValidation]="selectedValidation"
        (passSuccess)="passSuccess()" *ngIf="changePass === true"></arc-change-pass-pin>
    <arc-change-pass-pin [changePIN]="changePIN" [selectedValidation]="selectedValidation" (pinSuccess)="pinSuccess()"
        *ngIf="changePIN === true"></arc-change-pass-pin>

    <!-- <arc-sop [selectedValidation]="selectedValidation" *ngIf="readSOPs"> Read SOPs here</arc-sop> -->

    <arc-employee-stop [selectedValidation]="selectedValidation" *ngIf="employeeStop === true" (acknowledgeNotes)="acknowledge()" ></arc-employee-stop>
</div>
<ng-template #waiting>
    <h1 style="text-align: center;">Validating...</h1>
</ng-template>
