import { Component, OnInit } from '@angular/core';
import { timeEmployeeService } from 'app/_data/time.service';
import { timeEmployeeSnapshot } from 'app/_models/TIME.model';
import { AuthService } from 'app/_services';

@Component({
  selector: 'arc-prodoc-reader-home',
  templateUrl: './prodoc-reader-home.component.html',
  styleUrls: ['./prodoc-reader-home.component.scss']
})
export class ProdocReaderHomeComponent implements OnInit {

  _subscription;
  pageTitle: string = 'DOCUMENT';
  employee: timeEmployeeSnapshot = null;

  constructor(
    private authService: AuthService,
    private employeeService: timeEmployeeService
  ) { }

  ngOnInit(): void {
    this.getEmployeeStatus();
  }

  getEmployeeStatus() {
    this.employeeService.getSnapshot(this.authService.currentUser.UserID).subscribe({
      next: (data: timeEmployeeSnapshot) => {
        this.employee = data;
      }
    });
  }

}
