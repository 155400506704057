import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import { cfgTaskType } from '../_models/CFG.model';
import { HttpClient } from '@angular/common/http';
import { OrdLinkType } from '../_models/enums.model';

@Injectable()
export class TaskTypesService {
  api = 'cfgTaskType/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<cfgTaskType[]>(this.apiURL + this.api);

  }
  create(TaskType: cfgTaskType) {
    return this.http.post(this.apiURL + this.api, TaskType);
  }

  update(TaskType: cfgTaskType) {
    return this.http
      .put(this.apiURL + this.api + TaskType.TaskTypeID, TaskType);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getAllForLinkType(area: OrdLinkType) {
    return this.http.get<cfgTaskType[]>(this.apiURL + this.api + 'linkType/' + area);
  }
}

