
import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import { secRightType } from '../_models/SEC.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RightTypesService {

  api = 'secRightType/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<secRightType[]>(this.apiURL + this.api);

  }
  create(item: secRightType) {
    return this.http.post(this.apiURL + this.api, item);
  }

  update(item: secRightType) {
    return this.http
      .put(this.apiURL + this.api + item.RightTypeID, item);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

}

