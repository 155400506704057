<div class="container">
    <!-- * ----------------------------- SEARCH -------------------------------- * -->
    <div class="searchContainer" *ngIf="documents.length === 0  && !viewingDoc">
        <div class="title">
            <div class="head">
                <h1>Document Library</h1>
            </div>
        </div>
        <!-- INPUT -->
        <div class="p-inputgroup" id="search">
            <span class="p-inputgroup-addon" style="border-radius: 5px;"><i class="pi pi-search"></i></span>
            <span style="width: 5px;"></span>
            <input pInputText autofocus placeholder="Search:" id="searchText" #docSearch (keyup.enter)="searchForDocuments()" [(ngModel)]="searchText" />
            <span style="width: 5px;"></span>
            <button type="button" pButton pRipple label="Search" (click)="searchForDocuments()" icon="pi pi-search"></button>  
        </div>
        
        <div class="noDataFound" *ngIf="notFound">
            <div class="error">
                <p>No Documents Found Matching Search</p>
            </div>
        </div>
        
    </div>
    
    <!-- * -------------------------  DOCUMENT LIST ---------------------------- * -->
    <div class="docListContainer" *ngIf="documents.length > 0 && !notFound && !viewingDoc">
        <div class="listHeader">
            <div class="back"> <button type="button" pButton pRipple label="New Search" (click)="goBack()" icon="pi pi-arrow-left"></button></div>
            <!-- CATEGORIES -->
            <!-- <div class="categories">
                <p-selectButton [options]="cats" dataKey="CategoryID" [ngStyle]="{'border-radius':'50px'}" [(ngModel)]="selectedCategories" multiple="multiple" (onOptionClick)="filterSearch()">
                    <ng-template let-item>
                        <span [ngStyle]="{'width':'150px', 'padding':'10px', 'font-weight':'bold'}"> {{item.CategoryName}} </span>
                    </ng-template>
                </p-selectButton>
            </div> -->
        </div>
        <div class="listBody">
            <!-- <div class="header">
                <h1>Documents List</h1>
            </div>
            <div class="lineItem" *ngFor="let item of documents">
                <div class="lineLeft">
                    <p class="title"> {{ item.Title }} </p>
                    <p class="description"> {{ item.Description }} </p>
                </div>
                <div class="lineRight">
                    <div class="primaryBtn" (click)="viewDocument(item)">
                        <h1>View</h1>
                    </div>
                </div>
            </div> -->

            <p-table #dt [value]="filteredDocs" sortMode="single" (onFilter)="doFilter($event)" (onSort)="onSort($event)"
                (onPage)="onPage($event)" [paginator]="true" [alwaysShowPaginator]="false" [rows]="20">
                <ng-template pTemplate="caption">
                    
                        <h3 style="margin: 0;">Document List</h3>
                    
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="Title" [ngStyle]="{ 'width': '25%', 'text-align': 'left' }">
                            Title
                            <span>
                                <p-sortIcon field="Title"></p-sortIcon>
                            </span>
                        </th>
                        <th pSortableColumn="Description" [ngStyle]="{ 'width': '65%', 'text-align': 'left' }">
                            Description
                            <span>
                                <p-sortIcon field="Description"></p-sortIcon>
                            </span>
                        </th>
                        <th pSortableColumn="Category Name" [ngStyle]="{ 'width' : '10%', 'text-align': 'left' }">
                            Category Name
                            <span>
                                <p-sortIcon field="Category Name"></p-sortIcon>
                            </span>
                        </th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-item>
                    <tr style="margin: 5px;">
                        <td style="width: 25%">
                            <p class="title">{{ item.Title }}</p>
                        </td>
                        <td style="width: 65%">
                            <p class="description">{{ item.Description }}</p>
                        </td>
                        <td style="width: 10%">
                            <p class="categoryName">{{ item.CategoryName }}</p>
                        </td>
                        <td style="text-align:center">
                            <button pButton type="button" pRipple label="View" (click)="viewDocument(item)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    Count: {{dt.totalRecords}}
                </ng-template>
            </p-table>
        </div>
    </div>

    <!-- * ------------------------ VIEWING DOCUMENT --------------------------- * -->
    <div class="documentBody" *ngIf="gotURL">
        <div class="back"><button type="button" pButton pRipple label="Back To List" (click)="backToList()" icon="pi pi-arrow-left"></button></div>

        <embed [src]="cleanURL" width="100%" height="100%" style="overflow-y: auto;" type="application/pdf">

    </div>
</div>