import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

// // import { server } from '../_models/index';

@Injectable()
export class FreightPayableService {

    api = 'FreightPayable/';
    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    getPayableSummary({ payableStatuses, forwardingStatuses }: { payableStatuses: string[]; forwardingStatuses: number[]; }) {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        let myParams = new HttpParams();
        myParams = myParams.append('payableStatuses', payableStatuses.join(','));
        myParams = myParams.append('forwardingStatuses', forwardingStatuses.join(','));
        return this.http.get(this.apiURL + this.api + 'Summary', { headers: headers, params: myParams} );
    }

}

