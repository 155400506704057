import { Pipe, PipeTransform } from '@angular/core';
import { SelectItem } from 'primeng/api/selectitem';

@Pipe({
  name: 'labelForValue'
})
export class LabelForValuePipe implements PipeTransform {

  // transform(value: number, items: SelectItem[]): string {
  //   if (!value) { return ''; }
  //   if (!items) { return value.toString(); }
  //   const ret = items.find(x => x.value === value);

  //   return ret ? ret.label : '';
  // }


  transform(value: any, items: SelectItem[]): string {
    // if (!value) { return ''; }
    if (!items) { return ''; }
    const ret = items.find(x => x.value === value);

    // return ret ? startCase(ret.label) : '';
    return ret ? ret.label : '';
  }
}
