<div class="head">
    <h1>Team Dashboard</h1>
</div>

<p-tabView (onChange)="handleTabChange($event)" [ngStyle]="{'margin':'10px'}">
    <p-tabPanel header="Team Summary">
        <prodocs-dashboard-team-summary [rawData]="rawData"></prodocs-dashboard-team-summary>
    </p-tabPanel>
    <p-tabPanel header="Team Detail">
        <prodocs-dashboard-team-table [rawData]="rawData"></prodocs-dashboard-team-table>
    </p-tabPanel>
    <p-tabPanel header="Stats">
        <div *ngIf="showStats" class="ProDocsDashboardContainer">
            <div class="Card">
                <assignment-completion-distribution [displayType]="displayTypes.Team"></assignment-completion-distribution>
            </div>
            <div class="Card">
                <document-assignment-completion [displayType]="displayTypes.Team"></document-assignment-completion>
            </div>
        </div>
    </p-tabPanel>
</p-tabView>