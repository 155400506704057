<p-table #dt [value]="items" sortMode="multiple" [multiSortMeta]="localFilter.MultiSortMeta"
    (onFilter)="doFilter($event)" (onSort)="onSort($event)" (onPage)="onPage($event)" [paginator]="true"
    [alwaysShowPaginator]="true">
    <ng-template pTemplate="caption">
        {{ itemName }}
    </ng-template>

    <!--Table Header Template-->
    <ng-template pTemplate="header">
        <!--Column Headers-->
        <tr>
            <th style="width:3em">
            </th>
            <th *ngIf="documentID < 0" pSortableColumn="DocumentTitle"
                [ngStyle]="{ 'width': '35%', 'text-align': 'left' }">
                Document
                <span>
                    <p-sortIcon field="DocumentTitle"></p-sortIcon>
                </span>
            </th>
            <th pSortableColumn="TriggerName" [ngStyle]="{ 'width': '25%', 'text-align': 'left' }">
                Name
                <span>
                    <p-sortIcon field="TriggerName"></p-sortIcon>
                </span>
            </th>
            <th pSortableColumn="TriggerTypeID" [ngStyle]="{ 'text-align': 'left' }">
                Type
                <span>
                    <p-sortIcon field="TriggerTypeID"></p-sortIcon>
                </span>
            </th>
            <th pSortableColumn="CreatedBy" [ngStyle]="{ 'width': '15%', 'text-align': 'left' }">
                Created By
                <span>
                    <p-sortIcon field="CreatedBy"></p-sortIcon>
                </span>
            </th>
            <th pSortableColumn="CreatedDate" [ngStyle]="{ 'width': '15%', 'text-align': 'left' }">
                Created Date
                <span>
                    <p-sortIcon field="CreatedDate"></p-sortIcon>
                </span>
            </th>
            <th pSortableColumn="IsActive" [ngStyle]="{ 'width': '10%', 'text-align': 'center' }">
                Active
                <span>
                    <p-sortIcon field="IsActive"></p-sortIcon>
                </span>
            </th>
            <th style="width:3em"></th>
        </tr>
        <!-- Filters -->
        <tr *ngIf="items?.length > 0">
            <th style="width:3em; text-align: center;" class="col-icon-delete" (click)="clearLocalFilter(dt)">
                <div>
                    <i class="fa fa-ban"></i>
                </div>
            </th>
            <th *ngIf="documentID < 0">
                <input pInputText status="text" id="DocumentTitle" [(ngModel)]="localFilter.DocumentTitle"
                    (input)="dt.filter(localFilter.DocumentTitle, 'DocumentTitle', 'contains')">
            </th>
            <th>
                <input pInputText status="text" id="TriggerName" [(ngModel)]="localFilter.TriggerName"
                    (input)="dt.filter(localFilter.TriggerName, 'TriggerName', 'contains')">
            </th>
            <th>
                <p-dropdown [options]="triggerTypes" [(ngModel)]="localFilter.TriggerTypeID" showClear="true"
                    (onClear)="resetFilter()" optionLabel="label"
                    (onChange)="dt.filter(localFilter.TriggerTypeID, 'TriggerTypeID', 'equals')" optionValue="value"
                    placeholder="Trigger Types"></p-dropdown>
            </th>
            <th>
                <p-dropdown [options]="creators" [(ngModel)]="localFilter.CreatedByName" showClear="true"
                    (onChange)="dt.filter(localFilter.CreatedByName, 'CreatedByName', 'equals')"
                    placeholder="All"></p-dropdown>
            </th>
            <th>
                <p-calendar [(ngModel)]='localFilter.CreatedDate'
                    (onSelect)="dt.filter(localFilter.CreatedDate, 'CreatedDate', 'dateRangeFilter')"
                    (onClearClick)="dt.filter('', 'CreatedDate','contains')" [showButtonBar]="true"
                    [readonlyInput]="true" [monthNavigator]="true" [yearNavigator]="true"
                    [inputStyle]="{'width': '100%'}" appendTo="body" yearRange="2000:2030" selectionMode="range">
                </p-calendar>
            </th>
            <th style="text-align: center"
                [pTooltip]="localFilter.IsActive ? 'Active' : localFilter.IsActive === false ? 'Not Active' : 'All'">
                <p-triStateCheckbox [(ngModel)]="localFilter.IsActive"
                    (onChange)="dt.filter(localFilter.IsActive, 'IsActive', 'contains')"></p-triStateCheckbox>
            </th>
            <th *ngIf="documentID > 0"></th>
            <th *ngIf="documentID < 0"></th>
        </tr>
    </ng-template>

    <!--Table Body Template-->
    <ng-template pTemplate="body" let-rowData let-item>
        <!--Column Rows-->
        <tr [pSelectableRow]="rowData">
            <td>
                <p-button icon="fa fa-edit" (onClick)="editItem(rowData)" [hidden]="!mayEditTriggers()"></p-button>
            </td>
            <td *ngIf="documentID < 0" style="color:blue; text-decoration:underline; cursor:pointer;"
                (click)="goToDocument(item.DocumentID)">
                {{ item.DocumentTitle }}
            </td>
            <td>
                {{ item.TriggerName }}
            </td>
            <td>
                {{ item.TriggerTypeID | labelForValue: triggerTypes }}
            </td>
            <td>
                {{ item.CreatedByName }}
            </td>
            <td>
                {{ item.CreatedDate | date: 'mediumDate' }}
            </td>
            <!-- <td>
                {{ item.CreatedBy }}
            </td> -->
            <td style="text-align:center;">
                <i *ngIf="item.IsActive" class="fa fa-check-square-o"></i>
                <i *ngIf="!item.IsActive" class="fa fa-square-o"></i>
            </td>
            <td>
                <p-button icon="fa fa-trash-o" (onClick)="confirmDelete(rowData)" [hidden]="!mayEditTriggers()">
                </p-button>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="summary" style="height: 48px">
        <span style="float:left; width: 150px;">
            <p-button icon="pi pi-plus" label="New Trigger" (click)="addNew()" [hidden]="!mayEditTriggers()"></p-button>
        </span>
        Count: {{dt.totalRecords}}
    </ng-template>
</p-table>