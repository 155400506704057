import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { timeout, catchError } from 'rxjs/operators';

import { AuthService, AlertService } from '../_services/index';
// import { errorMonitor } from 'node:events';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  defaultTimeout = 120000; // default to wait for 15 seconds?

  constructor(
    public auth: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const waitTimeout = Number(request.headers.get('timeout')) || this.defaultTimeout;
    if (!request.url.endsWith('token') && !request.url.includes('localhost:3257')) {
      //if (!request.url.endsWith('token')) {
      // console.log('making request to: ', request);
      const authToken = this.auth.getAuth();
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${authToken}`, 'Content-Encoding': 'gzip' }
      });
    }

    return next.handle(request)
      .pipe(
        timeout(waitTimeout),
        // retry(1),
        catchError((error: any) => {
          let errorMessage = '';
          console.log('error', error);
          if (error.error instanceof ErrorEvent) {
            // client side error
            errorMessage = `Error: ${error.error.message}`;
          } else if (error instanceof HttpErrorResponse) {
            if (error.status === 0) {
              errorMessage = 'Unable to connect to server at: ' + error.url;
            } else if (error.status === 401) { // unauthorized
              errorMessage = 'Unauthorized';
              errorMessage = error.error?.error_description;
            } else if (error.status === 404) {
              errorMessage = error.statusText ? error.statusText : 'Not Found'
            } else if (error.status === 410) {
              errorMessage = 'No longer available';
            } else if (error.status === 500) { // internal server
              errorMessage = (error.error.Message ?? '') + ' ' + (error.error.ExceptionMessage ?? '');
            } else if (error.error) {
              if (error.error.Message) {
                errorMessage += error.error.Message;
              }
              if (error.error.ExceptionMessage) {
                errorMessage += 'Exception: ' + error.error.ExceptionMessage;
              }
            } else {
              errorMessage = error.message;
            }
          }
          return throwError(errorMessage);
        })
      );
  }
}
