import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UpsertRet } from 'app/_models/API.model';
import { assetAction, AssetActionCount, assetState, AssetStatusCount, AssetUsage, AssetUsageWeekday, astActionDb, astActionScopeDb, astActivityDb, astAsset, astAssetDb, cfgAssetStatusDb, cfgAssetTypeDb } from 'app/_models/asset.model';


@Injectable()
export class astAssetActionService {
    api = 'AssetAction/';

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    doAction(action: assetAction) {
        return this.http.post<UpsertRet>(this.apiURL + this.api, action);
    }
}

@Injectable()
export class astAssetStateService {
    api = 'AssetState/';

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    getAll() {
        return this.http.get<assetState[]>(this.apiURL + this.api);
    }

    get(id: number) {
        return this.http.get<assetState>(this.apiURL + this.api + id);
    }

    getScanned(scannedID: string) {
        return this.http.get<assetState>(this.apiURL + this.api + 'scanned/' + scannedID);
    }

    getForEmployee(employeeID: number) {
        return this.http.get<assetState[]>(this.apiURL + this.api + 'employee/' + employeeID);
    }

    getForWarehouse(warehouseID: number) {
        return this.http.get<assetState[]>(this.apiURL + this.api + 'warehouse/' + warehouseID);
    }
}

@Injectable()
export class astActionService {
    api = 'astAction/';

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    getAll() {
        return this.http.get<astActionDb[]>(this.apiURL + this.api);
    }

    get(id: number) {
        return this.http.get<astActionDb>(this.apiURL + this.api + id);
    }

    create(Action: astActionDb) {
        return this.http.post(this.apiURL + this.api, Action);
    }

    update(Action: astActionDb) {
        return this.http
            .put(this.apiURL + this.api + Action.ActionID, Action);
    }

    delete(id: number) {
        return this.http.delete(this.apiURL + this.api + id);
    }

}

@Injectable()
export class astActionScopeService {
    api = 'astActionScope/';

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    getAll() {
        return this.http.get<astActionScopeDb[]>(this.apiURL + this.api);
    }

    get(id: number) {
        return this.http.get<astActionScopeDb>(this.apiURL + this.api + id);
    }

    create(ActionScope: astActionScopeDb) {
        return this.http.post(this.apiURL + this.api, ActionScope);
    }

    update(ActionScope: astActionScopeDb) {
        return this.http
            .put(this.apiURL + this.api + ActionScope.ActionScopeID, ActionScope);
    }

    delete(id: number) {
        return this.http.delete(this.apiURL + this.api + id);
    }

    getForStatus(status: number) {
        return this.http.get(this.apiURL + this.api + 'status/' + status);
    }

    updateForAction(actionID: number, values: cfgAssetStatusDb[]) {
        return this.http.put(this.apiURL + this.api + 'action/' + actionID, values);
    }
}

@Injectable()
export class astActivityService {
    api = 'astActivity/';

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    getAll() {
        return this.http.get<astActivityDb[]>(this.apiURL + this.api);
    }

    get(id: number) {
        return this.http.get<astActivityDb>(this.apiURL + this.api + id);
    }

    create(Activity: astActivityDb) {
        return this.http.post(this.apiURL + this.api, Activity);
    }

    update(Activity: astActivityDb) {
        return this.http
            .put(this.apiURL + this.api + Activity.ActivityID, Activity);
    }

    delete(id: number) {
        return this.http.delete(this.apiURL + this.api + id);
    }

    getHistory(id: number, days: number) {
        return this.http.get(this.apiURL + this.api + 'history/' + id + '/' + days);
    }

    getEmployeeAssetHistory(id: number, days: number) {
        return this.http.get(this.apiURL + this.api + 'employee/' + id + '/' + days);
    }

    getAssetActivityCount(assetID: number) {
        return this.http.get<AssetActionCount[]>(this.apiURL + this.api + 'activityCount/' + assetID);
    }
}

@Injectable()
export class astAssetService {
    api = 'astAsset/';

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    getAll() {
        return this.http.get<astAsset[]>(this.apiURL + this.api);
    }

    get(id: number) {
        return this.http.get<astAsset>(this.apiURL + this.api + id);
    }

    create(Asset: astAssetDb) {
        return this.http.post(this.apiURL + this.api, Asset);
    }

    update(Asset: astAssetDb) {
        return this.http
            .put(this.apiURL + this.api + Asset.AssetID, Asset);
    }

    delete(id: number) {
        return this.http.delete(this.apiURL + this.api + id);
    }

}

@Injectable()
export class cfgAssetStatusService {
    api = 'cfgAssetStatus/';

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    getAll() {
        return this.http.get<cfgAssetStatusDb[]>(this.apiURL + this.api);
    }

    get(id: number) {
        return this.http.get<cfgAssetStatusDb>(this.apiURL + this.api + id);
    }

    create(AssetStatus: cfgAssetStatusDb) {
        return this.http.post(this.apiURL + this.api, AssetStatus);
    }

    update(AssetStatus: cfgAssetStatusDb) {
        return this.http
            .put(this.apiURL + this.api + AssetStatus.AssetStatusID, AssetStatus);
    }

    delete(id: number) {
        return this.http.delete(this.apiURL + this.api + id);
    }

    getScopeForAction(actionID: number) {
        return this.http.get(this.apiURL + this.api + 'scope/' + actionID);
    }

}

@Injectable()
export class cfgAssetTypeService {
    api = 'cfgAssetType/';

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    getAll() {
        return this.http.get<cfgAssetTypeDb[]>(this.apiURL + this.api);
    }

    get(id: number) {
        return this.http.get<cfgAssetTypeDb>(this.apiURL + this.api + id);
    }

    create(AssetType: cfgAssetTypeDb) {
        return this.http.post(this.apiURL + this.api, AssetType);
    }

    update(AssetType: cfgAssetTypeDb) {
        return this.http
            .put(this.apiURL + this.api + AssetType.AssetTypeID, AssetType);
    }

    delete(id: number) {
        return this.http.delete(this.apiURL + this.api + id);
    }

}

@Injectable()
export class AssetDashboardService {
    api = 'assetDashboard/';

    constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

    getStatusCountForWarehouse(warehouseID: number) {
        return this.http.get<AssetStatusCount[]>(this.apiURL + this.api + 'statusCount/' + warehouseID);
    }

    getUsageCountForDay(warehouseID: number, startDate: string, intervalSize: number) {
        let params = new HttpParams();
        params = params.append('warehouseID', warehouseID ? warehouseID.toString() : null);
        params = params.append('startDate', startDate);
        params = params.append('intSize', intervalSize.toString());
        return this.http.get<AssetUsage[]>(this.apiURL + this.api + 'usageForDay', { params: params });
    }

    getUsageByWeekday(warehouseID: number, toDate: string, intervalSize: number, dayCount: number) {
        let params = new HttpParams();
        params = params.append('warehouseID', warehouseID ? warehouseID.toString(): null);
        params = params.append('toDate', toDate);
        params = params.append('intSize', intervalSize.toString());
        params = params.append('dayCount', dayCount.toString());
        return this.http.get<AssetUsageWeekday[]>(this.apiURL + this.api + 'usageByWeekday', { params: params });
    }
}
