import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BilledStatus, DimFactors } from '../_models/enums.model';
import { ordFreightFeeEx, ordOrderFreightEx, ordTask, ordPieceEx } from '../_models/ORD.model';
import { UpsertRet, UpsertRetStatus, UpsertRetBilling } from '../_models/API.model';
import { DimFactorUpdate, QuoteForwardingStatus } from '../_models/UTL.model';

@Injectable()
export class ActionsService {

    api = 'Actions/';
    constructor(
        private http: HttpClient, @Inject('apiURL') private apiURL) {
    }

    setOKtoInvoice(orderID: number, set: boolean) {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const payload = { 'isTrue': set };

        return this.http.put<UpsertRetBilling>(this.apiURL + this.api + 'OKtoInvoice/' + orderID, payload, config);
    }

    // setNewBilledStatus(orderID: number, newStatus: BilledStatus) {
    //     return this.http.put(this.apiURL + this.api + 'BilledStatus/' + orderID, newStatus);
    // }

    BilledFeeUpdate(freightFee: ordFreightFeeEx) {
        return this.http.put<UpsertRetBilling>(this.apiURL + this.api + 'billingFee', freightFee);
    }

    quoteBuildFromSubmit(item: ordOrderFreightEx) {
        return this.http.put<UpsertRet>(this.apiURL + this.api + 'quoteBuildFromSubmit', item);
    }

    quoteSubmit(item: ordOrderFreightEx) {
        return this.http.put<UpsertRet>(this.apiURL + this.api + 'quoteSubmit', item);
    }

    quoteUnsubmit(item: ordOrderFreightEx) {
        return this.http.put<UpsertRet>(this.apiURL + this.api + 'quoteUnsubmit', item);
    }

    quoteApprove(item: ordOrderFreightEx) {
        return this.http.put<UpsertRet>(this.apiURL + this.api + 'quoteApprove', item);
    }

    quoteUnapprove(item: ordOrderFreightEx) {
        return this.http.put<UpsertRet>(this.apiURL + this.api + 'quoteUnapprove', item);
    }

    quoteToClient(item: ordOrderFreightEx) {
        return this.http.put<UpsertRet>(this.apiURL + this.api + 'quoteToClient', item);
    }

    convertToWorkOrder(item: ordOrderFreightEx) {
        return this.http.put<UpsertRet>(this.apiURL + this.api + 'convertToWorkOrder', item);
    }

    archiveQuote(item: ordOrderFreightEx, doArchive: boolean) {
        if (doArchive) {
            return this.http.put<ordOrderFreightEx>(this.apiURL + this.api + 'archiveQuote', item);
        } else {
            return this.http.put<ordOrderFreightEx>(this.apiURL + this.api + 'unarchiveQuote', item);
        }
    }

    deleteQuote(orderID: number) {
        return this.http.delete<number>(this.apiURL + this.api + 'Quote/' + orderID);
    }

    deleteOrder(orderID: number) {
        return this.http.delete<number>(this.apiURL + this.api + 'WorkOrder/' + orderID);
    }

    releaseOrder(orderID: number) {
        return this.http.put<UpsertRet>(this.apiURL + this.api + 'ReleaseOrder/' + orderID, null);
    }

    unReleaseOrder(orderID: number) {
        return this.http.put<UpsertRet>(this.apiURL + this.api + 'UnReleaseOrder/' + orderID, null);
    }

    generateInvoice(orderID: number, invoiceType: string, invoiceComment: string) {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const payload = { 'invoiceType': invoiceType, 'invoiceComment': invoiceComment };
        return this.http.post(this.apiURL + this.api + 'GenerateInvoice/' + orderID, payload, config);
    }

    bypassInvoice(orderID: number) {
        return this.http.put(this.apiURL + this.api + 'BypassInvoice/' + orderID, null);
    }

    recallInvoice(invoiceID: number) {
        return this.http.post(this.apiURL + this.api + 'RecallInvoice/' + invoiceID, null);
    }

    pickupLeg(freightLegID: number, actionDate: Date) {
        return this.http.put(this.apiURL + this.api + 'PickupLeg/' + freightLegID, actionDate);
    }

    deliverLeg(freightLegID: number, actionDate: Date) {
        return this.http.put(this.apiURL + this.api + 'DeliverLeg/' + freightLegID, actionDate);
    }

    completeAlert(alertID: number, actionDate: Date) {
        return this.http.put(this.apiURL + this.api + 'CompleteAlert/' + alertID, actionDate);
    }

    confirmPiece(pieceID: number, isConfirmed: boolean) {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const payload = { 'isTrue': isConfirmed };
        return this.http.put<UpsertRetStatus>(this.apiURL + this.api + 'ConfirmPiece/' + pieceID, payload, config);
    }

    setDimFactor(orderID: number, pieces: ordPieceEx[], dimFactor: DimFactors) {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const payload = new DimFactorUpdate();
        payload.DimFactor = +dimFactor;
        payload.pieces = pieces.map(p => ({ 'PieceID': p.PieceID, 'DimWeight': p.DimWeight }));
        return this.http.put<number>(this.apiURL + this.api + 'DimFactor/' + orderID, payload, config);
    }

    setPaymentTerms(orderID: number, paymentTermsID: number) {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const payload = { 'newValue': paymentTermsID };
        return this.http.put<number>(this.apiURL + this.api + 'PaymentTerms/' + orderID, payload, config);
    }

    savePiece(piece: ordPieceEx) {
        return this.http.put<UpsertRetStatus>(this.apiURL + this.api + 'SavePiece/', piece);
    }

    deletePiece(piece: ordPieceEx) {
        return this.http.put<UpsertRetStatus>(this.apiURL + this.api + 'DeletePiece/', piece);
    }

    saveTask(task: ordTask) {
        return this.http.put<UpsertRetStatus>(this.apiURL + this.api + 'SaveTask/', task);
    }

    completeTask(taskID: number, actionDate: Date) {
        return this.http.put<UpsertRetStatus>(this.apiURL + this.api + 'CompleteTask/' + taskID, actionDate);
    }

    deleteTask(taskID: number) {
        return this.http.put<UpsertRetStatus>(this.apiURL + this.api + 'DeleteTask/' + taskID, null);
    }

    updateCarrierReferenceNumber(freightLegID: number, newReferenceNumber: string) {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const payload = { 'newReferenceNumber': newReferenceNumber };
        return this.http.put(this.apiURL + this.api + 'CarrierReferenceNumber/' + freightLegID, payload, config);
    }

    updatePOD(freightLegID: number, PODName: string, PODDate: Date) {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const payload = { 'PODName': PODName, 'PODDate': PODDate };
        return this.http.put(this.apiURL + this.api + 'UpdatePOD/' + freightLegID, payload, config);
    }

    validateStatus(orderID: number) {
        return this.http.get(this.apiURL + 'ordOrderFreightEx/status/' + orderID);
    }

    refreshStatus(orderID: number) {
        return this.http.get<QuoteForwardingStatus>(this.apiURL + this.api + 'getStatus/' + orderID);
    }

}
