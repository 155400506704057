import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { timeEmployeeService } from 'app/_data/time.service';
import { timeEmployeeSnapshot } from 'app/_models/TIME.model';
import { AlertService, AuthService } from 'app/_services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'arc-clock-out',
  templateUrl: './clock-out.component.html',
  styleUrls: ['./clock-out.component.scss']
})
export class ClockOutComponent implements OnInit {

  employee: timeEmployeeSnapshot;
  _subscription: Subscription;
  userID: number;
  forDate: Date;
  displayDate: string;

  freezeClockOut: boolean = false;
  @Output() out = new EventEmitter<timeEmployeeSnapshot>();

  @Input() pageTitle: string;


  constructor(
    private authService: AuthService,
    private employeeService: timeEmployeeService,
    private alert: AlertService
  ) { }

  ngOnInit(): void {
    this.userID = this.authService.currentUser.UserID;

    this._subscription = this.employeeService.signedInEmployee$.subscribe(
      (value) => {
        this.employee = value;
        if (this.employee.Assets.length > 0) {
          this.freezeClockOut = true;
        }
      },
      (error) => this.alert.error(error)
    );
    this.forDate = new Date();
  }

  selectDate(newDate: Date) {
    this.forDate = newDate;
    this.displayDate = this.forDate.toLocaleDateString();
  }

  reload(employee: timeEmployeeSnapshot): void {
    this.out.emit(employee);
  }


}
