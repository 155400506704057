<div>
  <div class="p-grid" *ngIf="env.display !== ''" style="margin-top: 0px;">
    <div class="p-col-12" style="color:white; text-align: center;" [style.background-color]="env.headerColor">
      {{ env.display }}
    </div>
  </div>
  <arc-spinner></arc-spinner>
  <div>
    <p-menubar id="headerMenu" autoDisplay="true" [model]="menuItems">
      <a routerLink="/">
        <img id="logo" src="./assets/images/ProPackLogoHalfSize.png" alt="" (click)="resetAll()" [pTooltip]="VERSION"
          style="vertical-align: middle" />
      </a>
      <p-menu #userMenu [popup]="true" [model]="userMenuItems"></p-menu>
      <p-button label="{{ loggedInUser() }}" styleClass="p-button-primary" (onClick)="userMenu.toggle($event)">
      </p-button>
    </p-menubar>
  </div>
  <p-toolbar>
    <div class="p-toolbar-group-left">
      <i class="p-toolbar-separator pi pi-ellipsis-v mr-2" style="vertical-align: middle; color: grey;"></i>
      <p-button label="Back" icon="pi pi-arrow-circle-left" (onClick)="navBack()" [disabled]="!canGoBack()"
        iconPos="left"></p-button>
      <i class="p-toolbar-separator pi pi-ellipsis-v mr-2" style="vertical-align: middle; color: grey;"></i>


      <p-button *ngIf="userMay('rtmShpQuoQuotes')" label="Quotes" icon="fa fa-quote-right"
        [routerLink]="['../shipping/quotes']" [ngStyle]="{'padding-left':'2.5px'}"
        [styleClass]="needyQuotes ? 'p-button-success' : 'p-button-primary'"></p-button>
      <p-button *ngIf="userMay('rtmShpFwdWorkOrders')" label="Work Orders" icon="fa fa-file-o"
        [routerLink]="['../shipping/orders-freight']" [ngStyle]="{'padding-left':'2.5px'}"></p-button>
      <p-button *ngIf="userMay('rtmWarWarehouseDashboard')" label="Warehouse" icon="fa fa-truck"
        [routerLink]="['../warehouse-services/warehouse-dashboard']" [ngStyle]="{'padding-left':'2.5px'}"></p-button>
      <p-button *ngIf="userMay('rtmShpTrkTrackedWOs')" label="Tracking" icon="fa fa-random"
        [routerLink]="['../shipping/tracking']" [ngStyle]="{'padding-left':'2.5px'}"></p-button>
      <p-button *ngIf="userMay('rtmBilFreightBilling')" label="Invoicing" icon="fa fa-usd"
        [routerLink]="['../billing/freight-billing']" [ngStyle]="{'padding-left':'2.5px'}"></p-button>
      <p-button *ngIf="userMay('rtmBilFreightPayables')" label="Payables" icon="fa fa-check"
        [routerLink]="['../billing/freight-payables']" [ngStyle]="{'padding-left':'2.5px'}"></p-button>

      <p-button *ngIf="userMay('rtmAuditDashboard')" label="Audit Dashboard" icon="fa fa-tachometer"
        [routerLink]="['../auditing/dashboard']" [ngStyle]="{'padding-left':'2.5px'}"></p-button>
      <p-button *ngIf="userMay('rtmAuditImport')" label="Audit Import" icon="fa fa-cloud-download"
        [routerLink]="['../auditing/import']" [ngStyle]="{'padding-left':'2.5px'}"></p-button>
      <p-button *ngIf="userMay('rtmAuditBilling')" label="Back-Billing" icon="fa fa-money"
        [routerLink]="['../auditing/billing-back-bill']" [ngStyle]="{'padding-left':'2.5px'}"></p-button>
      <p-button *ngIf="userMay('rtmAuditBilling')" label="Exceptions" icon="fa fa-exclamation"
        [routerLink]="['../auditing/billing-VAS']" [ngStyle]="{'padding-left':'2.5px'}"></p-button>


      <p-button *ngIf="userMay('rtmAssetsDashboard')" label="Asset Dashboard" icon="pi pi-tablet"
        [routerLink]="['../assets/dashboard']" [ngStyle]="{'padding-left':'2.5px'}"></p-button>
      <p-button *ngIf="userMay('rtmTimeDashboard')" label="Time Dashboard" icon="pi pi-clock"
        [routerLink]="['../time/dashboard']" [ngStyle]="{'padding-left':'2.5px'}"></p-button>
      <p-button *ngIf="userMay('rtmTimeEmployees')" label="Employees" icon="fa fa-users"
        [routerLink]="['../time/employees']" [ngStyle]="{'padding-left':'2.5px'}"></p-button>
      <p-button *ngIf="userMay('rtmTimeSchedules')" label="Schedules" icon="fa fa-calendar-plus-o"
        [routerLink]="['../time/schedules']" [ngStyle]="{'padding-left':'2.5px'}"></p-button>
      <p-button *ngIf="userMay('rtmTimeReview')" label="Review" icon="fa fa-clock-o"
        [routerLink]="['../time/review-time']" [ngStyle]="{'padding-left':'2.5px'}"></p-button>
      <p-button *ngIf="userMay('rtmTimeIssues')" label="Issues" icon="fa fa-frown-o" [routerLink]="['../time/issues']"
        [ngStyle]="{'padding-left':'2.5px'}"></p-button>

      <p-button *ngIf="userMay('rtmPortalQuotes')" label="Quotes" icon="fa fa-quote-right"
        [routerLink]="['../portal/cpquotes']" [ngStyle]="{'padding-left':'2.5px'}"></p-button>
      <p-button *ngIf="userMay('rtmPortalWorkOrders')" label="Work Orders" icon="fa fa-file-o"
        [routerLink]="['../portal/cpworkOrders']" [ngStyle]="{'padding-left':'2.5px'}"></p-button>
      <p-button *ngIf="userMay('rtmPortalTrackings')" label="Tracking" icon="fa fa-random"
        [routerLink]="['../portal/cptrackings']" [ngStyle]="{'padding-left':'2.5px'}"></p-button>
      <p-button *ngIf="userMay('rtmPortalBillings')" label="Billing" icon="fa fa-usd"
        [routerLink]="['../portal/cpbillings']" [ngStyle]="{'padding-left':'2.5px'}"></p-button>

    </div>
    <div class="p-toolbar-group-right" *ngIf="userMay('rtmShpForwarding')">
      <p-button label="My Tasks" icon="fa fa-list" [routerLink]="['../shipping/user-tasks']"></p-button>
    </div>

    <!-- <div class="p-toolbar-group-right">
        <p-button styleClass='p-button-danger' label="error" (onClick)="showMsg('error')"></p-button>
        <p-button styleClass='p-button-warning' label="warn" (onClick)="showMsg('warn')"></p-button>
        <p-button styleClass='p-button-success' label="success" (onClick)="showMsg('success')"></p-button>
        <p-button styleClass='p-button-info' label="info" (onClick)="showMsg('info')"></p-button>
      </div> -->
  </p-toolbar>

  <!-- Arcturus Settings -->
  <p-dialog header="User Settings" [(visible)]="displayUserSettings" [modal]="true" showEffect="fade" [closable]="false"
    [style]="{'width':'800px'}">
    <p-tabView>
      <p-tabPanel header="Name and Password">
        <arc-user-settings *ngIf="displayUserSettings" [userID]="currentUser.UserID" [ByUser]="true" [InSec]="false"
          (editComplete)="onEditUserComplete()">
        </arc-user-settings>
      </p-tabPanel>
      <p-tabPanel header="Printer Setup" *ngIf="statusData">
        <arc-printer-user-edit *ngIf="displayUserSettings" [appName]="'ARC'" [userID]="currentUser.UserID"
          [warehouseID]="currentUser.DefaultWarehouse" (close)="onEditUserComplete()">
        </arc-printer-user-edit>
      </p-tabPanel>
    </p-tabView>
    <!-- <div>
      {{ statusData | json }}
      <hr>
      {{ currentUser | json }}
    </div> -->
  </p-dialog>

  <!-- Go Time Home -->
  <p-dialog class="goTime" header="GoTime - {{ currentUser.FirstName }} {{ currentUser.LastName }} "
    [(visible)]="loadGoTimeToggle" *ngIf="loadGoTimeToggle" [modal]="true" showEffect="fade" [style]="{'width':'95vw'} "
    (onHide)="close()">
    <arc-time-home [pageTitle]="goTimeHome" (out)="getUserStatus($event)"
      (timeTrack)="loadTimeTrack($event)"></arc-time-home>
    <p-footer>
      <div class="statusBar" *ngIf="statusData"
        style="display: flex; flex-direction: column; text-align: center; height: 65px;">
        <h3 *ngIf="statusData.ClockStatus === 'IN'">
          <span style="font-weight: bold; padding-right: 5px;">Status:</span>
          <span style="color: rgb(30, 155, 30); font-weight: bold;">{{ statusData.ClockStatus }}</span> - <span> {{
            statusData.FunctionCode }}: {{ statusData.FunctionName }} - {{ statusData.ClientShortName }}</span>
        </h3>
        <h3 *ngIf="statusData.ClockStatus === 'OUT'">
          <span style="font-weight: bold; padding-right: 5px;">Status:</span>
          <span style="color: red;">{{ statusData.ClockStatus }}</span>
        </h3>
        <h3 *ngIf="statusData.ClockStatus === 'BRK'">
          <span style="font-weight: bold; padding-right: 5px;">Status:</span>
          <span style="color: orange">{{ statusData.ClockStatus }}</span> - <span>{{ statusData.FunctionName }}</span>
        </h3>
        <h3 *ngIf="statusData.ClockStatus === 'ADM'">
          <span style="font-weight: bold; padding-right: 5px;">Status:</span>
          <span style="color: red; font-weight: bold;">Time Requires Attention</span>
        </h3>
        <!-- <p-button (onClick)="changeTitle('Dashboard')">Dashboard</p-button> -->
      </div>
    </p-footer>
  </p-dialog>

  <!-- TimeTrack -->
  <p-dialog header="TimeTrack - {{ currentUser.FirstName }} {{ currentUser.LastName }}  "
    [(visible)]="loadTimeTrackToggle" *ngIf="loadTimeTrackToggle" [modal]="true" showEffect="fade"
    [style]="{'width':'1200px'} " (onHide)="closeTimeTrack()">
    <!-- <arc-time-home [pageTitle]="goTimeHome" (out)="getUserStatus($event)"></arc-time-home> -->
    <arc-time-track [pack]="timeTrackBundle"></arc-time-track>
    <p-footer>
      <div class="statusBar" *ngIf="statusData"
        style="display: flex; flex-direction: column; text-align: center; height: 65px;">
        <h3 *ngIf="statusData.ClockStatus === 'IN'">
          <span style="font-weight: bold; padding-right: 5px;">Status:</span>
          <span style="color: rgb(30, 155, 30); font-weight: bold;">{{ statusData.ClockStatus }}</span> - <span> {{
            statusData.FunctionCode }}: {{ statusData.FunctionName }} - {{ statusData.ClientShortName }}</span>
        </h3>
        <h3 *ngIf="statusData.ClockStatus === 'OUT'">
          <span style="font-weight: bold; padding-right: 5px;">Status:</span>
          <span style="color: red;">{{ statusData.ClockStatus }}</span>
        </h3>
        <h3 *ngIf="statusData.ClockStatus === 'BRK'">
          <span style="font-weight: bold; padding-right: 5px;">Status:</span>
          <span style="color: orange">{{ statusData.ClockStatus }}</span> - <span>{{ statusData.FunctionName }}</span>
        </h3>
        <h3 *ngIf="statusData.ClockStatus === 'ADM'">
          <span style="font-weight: bold; padding-right: 5px;">Status:</span>
          <span style="color: red; font-weight: bold;">Time Requires Attention</span>
        </h3>
        <!-- <p-button (onClick)="changeTitle('Dashboard')">Dashboard</p-button> -->
      </div>
    </p-footer>
  </p-dialog>

  <!-- ProDocs -->
  <p-dialog header="ProDocs" [(visible)]="loadProDocsToggle" *ngIf="loadProDocsToggle" [modal]="true" showEffect="fade"
    [style]="{'width':'95vw', 'height':'90vh'}" (onHide)="closeProDocs()">
    <arc-prodoc-reader-home></arc-prodoc-reader-home>
    <!-- <p-footer>
      <div class="statusBar" *ngIf="statusData" style="display: flex; flex-direction: column; align-items: center; text-align: center; height: 20px;">
        <h3 *ngIf="statusData.ClockStatus === 'IN'">
          <span style="font-weight: bold; padding-right: 5px;">Status:</span> 
          <span style="color: rgb(30, 155, 30); font-weight: bold;">{{ statusData.ClockStatus }}</span> - <span> {{ statusData.FunctionCode }}: {{ statusData.FunctionName }} - {{ statusData.ClientShortName }}</span>
        </h3> 
        <h3 *ngIf="statusData.ClockStatus === 'OUT'">
          <span style="font-weight: bold; padding-right: 5px;">Status:</span> 
          <span style="color: red;">{{ statusData.ClockStatus }}</span>
        </h3> 
        <h3 *ngIf="statusData.ClockStatus === 'BRK'">
          <span style="font-weight: bold; padding-right: 5px;">Status:</span> 
          <span style="color: orange">{{ statusData.ClockStatus }}</span> - <span>{{ statusData.FunctionName }}</span>
        </h3> 
        <h3 *ngIf="statusData.ClockStatus === 'ADM'">
          <span style="font-weight: bold; padding-right: 5px;">Status:</span> 
          <span style="color: red; font-weight: bold;">Time Requires Attention</span>
        </h3> 
        <p-button (onClick)="changeTitle('Dashboard')">Dashboard</p-button>
      </div>
    </p-footer> -->
  </p-dialog>

  <!--   
  <pre>{{ menuItems | json }}</pre> 
-->