export enum EntityType {
    AllEntities = 0,
    BillTo = 1,
    Carrier = 2,
    Broker = 3,
    Insurer = 4,
    SalesRep = 5,
    CrossDock = 6,
    ShipCon = 7,
    Other = 8
}

export enum OrgItemType {
    Entity = 1,
    Place = 2,
    Person = 3,
    Email = 4,
    Media = 5,
    Phone = 6
}

export enum OrdLinkType {
    Order = 1,
    Quote = 2,
    WONumber = 3,
    FreightLeg = 4,
    FreightPayable = 5,
    Invoice = 6,
    WarehouseAlert = 7,
    FreightHandling = 8,
    FreightFee = 9,
    Task = 10,
    Tracking = 11,
    Portal = 12
}

export enum IDType {
    NoRec = -1,
    NewRec = -2
}

export enum PayableType {
    Carrier = 1,
    Insurance = 2,
    Brokerage = 3,
    Commission = 4,
    CrossDock = 5,
    Other = 6
}

export enum FeeType {
    Freight = 1,
    Insurance = 2,
    Brokerage = 3,
    Commission = 4,
    CrossDock = 5,
    Accessorial = 6  // and higher
}

export enum NoteType {
    All = 0,
    ClientComments = 1,
    PropackComments = 2,
    QuoteNotes = 3,
    WarehouseNotes = 4,
    // SupportForwarderNotes = 5,
    // SupportNotes = 6,
    // TrackingForwarderNotes = 7,
    TrackingNotes = 8,
    CustomerConfirmationNotes = 9,
    WaybillNotes = 10,
    BillingNotes = 11,
    InvoiceComments = 12,
    // BillToQuoteNotes = 13,
    // BillToNotes = 14,
    CarrierSpecialInfo = 15,
    BillToSpecialInfo = 16,
    ShipperSpecialInfo = 17,
    ConsigneeSpecialInfo = 18,
    BrokerSpecialInfo = 19,
    ShipmentSpecialInstructions = 20,
    // FreightLegNotes = 21,
    FreightFeeNotes = 22,
    FreightHandlingNotes = 23,
    InvoiceNotes = 24,
    TaskNotes = 25,
    // TaskNotesWarehouse = 26,
    // TaskNotesSupport = 27,
    AffiliateNote = 28,
    AssociationNote = 29,
    // ClientsFreightNote = 30,
    // ClientsFulfillmentNote = 31,
    EmailNote = 32,
    EntityNote = 33,
    EntityPersonNote = 34,
    MediaNote = 35,
    PersonNote = 36,
    PhoneNote = 37,
    PlaceNote = 38,
    VendorNote = 39,
    // TrackerNote = 40,
    WorkOrderNotes = 41,
    QuoteNoteClient = 42,
    CrossDockSpecialInfo = 43,
    CrossDockSpecialInstructions = 44,
    InsuranceNotes = 45,
    CommissionNotes = 46,
    OtherPayableNotes = 47,
    ClientPortalNote = 48
}

export enum PhoneTypes {
    Home = 1,
    Work = 2,
    Mobile = 3,
    Fax = 4,
    TollFree = 5,
    Other = 6
}

export enum EmailTypes {
    Primary = 1,
    Personal = 2,
    Secondary = 3
}

export enum MediaTypes {
    Facebook = 1,
    Twitter = 2,
    LinkedIn = 3,
    Website = 4,
    Other = 5
}

export enum QuoteStatus {
    New = 201,
    Creating = 203,
    Submitted = 205,
    Building = 210,
    Quoted = 220,
    Approved = 225,
    Forwarded = 230,
    Archived = 240
}

export enum ForwardingStatus {
    Building = 310,
    Released = 320,
    InTransit = 330,
    Delivered = 340,
    Complete = 350,
    Archived = 360
}

export enum WarehouseAlertStatus {
    Pending = 410,
    Inbound = 420,
    OnSite = 430,
    Outbound = 440,
    Complete = 450
}

export enum BilledStatus {
    PendingEntry = 510,
    EntryComplete = 520,
    Cleared = 530
}

export enum TrackingStatus {
    Pending = 610,
    Warehouse = 615,
    LegOrigin = 620,
    InTransit = 630,
    Delivered = 640,
    Complete = 650,
}

export enum InvoiceStatus {
    PendingApproval = 710,
    Approved = 720,
    PendingInvoice = 730,
    Invoiced = 740
}

export enum RevenueStatus {
    Pending = 810,
    Invoiced = 820,
    Cleared = 830
}

export enum TrackingType {
    Manual = 1,
    AutoArrive = 2,
    AutoDepart = 3
}

export enum DocumentType {
    Proof_of_Delivery = 1,
    Waybill = 2,
    Invoice = 3,
    Customs = 4,
    Other = 9
}

export enum PaymentType {
    Prepaid = 1,
    Collect = 2,
    ThirdParty = 3
}

export enum PaymentTerms {
    Net15 = 1,
    Net30 = 2,
    OnReceipt = 3,
    CashOnly = 4
}

export enum InvoiceType {
    Original = 'O',
    Revised = 'R',
    Additional = 'A'
}

export enum InternationalType {
    Domestic = 1,
    Export = 2,
    Import = 3,
    International = 4
}

export enum LookupListType {
    ActivityTypeList,
    AirportCodeList,
    AssociationType,
    BillingTermList,
    BillingTypeList,
    BillTypeList,
    CarrierTrackingList,
    ClientRuleList,
    CountryCodeList,
    CurrencyCodeList,
    DepartmentList,
    EmailTypeList,
    EmployeeTypeList,
    EmployeeTypeGroupList,
    ExchangeRateList,
    FeeTypeList,
    ForwarderList,
    FunctionGroupList,
    HandlingChargesList,
    InsurerList,
    InternationalTypeList,
    IssueTypeList,
    MediaTypeList,
    NmfcNumberList,
    NoteTypeList,
    OrderHoldsList,
    OtherPayableList,
    PaymentTermsList,
    PaymentTypeList,
    PayrollTypeList,
    PayrollTypeGroupList,
    PhoneTypeList,
    PieceTypeList,
    PlaceTypeList,
    ReportParameterTypeList,
    RightTypeList,
    SalesRepList,
    ScheduleTypeList,
    ServiceLevelsList,
    ServiceProcessesList,
    ServiceUnitsList,
    ShipmentTypeList,
    StateProvinceCodeList,
    StatusesList,
    TerminationReasonsList,
    TrackingCategoryList,
    TrackTypeList,
    UserNameList,
    WarehouserList,
    WarehouseList,
    WorkStatusList,
    WorkGroupList,
    ProDocActorList,
    LanguageList,
    SendItPaymentTypeList
}

export enum ReportParameterType {
    TextBox = 1,
    DatePicker = 2,
    DropDownList = 3,
    CheckBox = 4,
    RadioButtonList = 5,
    DateRangePicker = 6,
    TriCheckBox = 7,
    DateTimePicker = 8
}

export enum ReportEnvironmentType {
    Internal = 1,
    Client = 2
}

export enum DimFactors {
    Dim139 = 139,
    Dim160 = 160,
    Dim166 = 166,
    Dim173 = 173,
    Dim194 = 194,
    Dim250 = 250,
    Dim1728 = 1728,
    CubicFeet = -1,
    LbsPerCF = -2
}
export enum UserRightType {
    Menus = 1,
    Views = 2,
    Actions = 3,
    Reports = 4
}

export enum DateRange {
    Custom,
    WeekToDate,
    LastWeek,
    MonthToDate,
    LastMonth,
    YearToDate,
    LastYear,
    Last7Days,
    Last30Days,
    Last60Days,
    Last90Days,
    Last120Days,
    Last180Days,
    Last12Months,
    Last24Months,
    All
}

export enum AuditBillType {
    Unknown = 0,
    BackBill = 1,
    VAS = 2
}

export enum WorkStatus {
    None = 0,
    FullTime = 1,
    PartTime = 2,
    OnLeave = 3
}

export enum EmployeeType {
    Salaried = 1,
    Hourly = 2,
    Contract = 3,
    Seasonal = 4
}

export enum ScheduleType {
    Work = 1,
    Sick = 2,
    Vacation = 3,
    Holiday = 4
}

export enum cfgTrackTypes {
    Unknown = 1,
    Order = 2,
    Receipt = 3,
    iPod = 4,
    ClientReference = 5
}

export enum ClientRule {
    NoClient = 0,
    AllowClient = 1,
    RequireClient = 2
}

export enum BillType {
    NoBill = 0,
    MayBill = 1,
    MustBill = 2
}

export enum GoTimeEmployeeTabs {
    General = 0,
    Functions = 1,
    Scheduling = 2,
    ReviewTime = 3,
    Issues = 4,
    Access = 5,
    AssetActivity = 6,
    Assignments = 7,
}

export enum EmployeeAccessType {
    None = 0,
    Primary = 1,
    Secondary = 2,
    Explicit = 3
}

export enum EmployeeTerminationType {
    Voluntary = 1,
    Involuntary = 2,
    Leave = 3
}

export enum TimeDashboardTabType {
    Main = 0,
    Charts = 1
}

export enum AssetStatusType {
    OnHold = 1,
    Available = 2,
    InUse = 3,
    ReportedLost = 4,
    ReportedDamaged = 5,
    OutForRepair = 6,
    InHouseRepair = 7,
    Decommissioned = 8,
    Disposed = 9,
    InTransit = 13
}

export enum AssetActionType {
    OnBoard = 1,
    Activate = 2,
    CheckOut = 3,
    CheckIn = 4,
    ReportLost = 5,
    ReportDamaged = 6,
    RepairInHouse = 7,
    SendForRepair = 8,
    ReturnFixed = 9,
    ReportFound = 10,
    Decommission = 11,
    Dispose = 12,
    ProxyCheckIn = 13,
    ChangeWarehouse = 16,
    FailToCheckin = 17
}

export enum RefLinkType {
    Asset = 1,
    Employee = 2
}

export enum DocItemType {
    Document = 1,
    Version = 2
}

export enum TriggerType {
    Function = 1,
    Person = 2,
    Department = 3,
    Warehouse = 4
}

export enum LanguageTypes {
    German = 'DE',
    English = 'EN',
    Spanish = 'ES',
    French = 'FR',
    Italian = 'IT',
    Japanese = 'JA',
    Chinese = 'ZH',
}

export enum DocVersionStatusTypes {
    Authoring = 10,
    Reviewing = 20,
    Reviewed = 25,
    Approved = 30,
    Published = 40,
    Archived = 50
}

export enum DocActorRoleTypes {
    Author = 1,
    Reviewer = 2,
    Approver = 3,
    QA_Reviewer = 4
}

export enum DocumentTabs {
    Versions = 0,
    Triggers = 1,
    Assignmetns = 2,
    Notes = 3,
    History = 4
}

export enum AssignmentCompletionDistributionTypes {
    Team = 'team',
    Admin = 'admin',
    Warehouse = 'warehouse',
}

// NOT REALLY AN ENUM BUT DON'T HAVE ANYWHERE BETTER TO PUT THIS
export enum AssetStatusColors {
    OnHold = '#8E6E53',
    Available = '#41863E',
    InUse = '#3590F3',
    ReportedLost = '#D33E43',
    ReportedDamaged = '#DD7373',
    OutForRepair = '#F95738',
    InHouseRepair = '#EDD83D',
    Decommissioned = '#5F1A37',
    Disposed = 'black',
    InTransit = '#666666'
}

export enum SendItShipmentSearchType {
    Order = 1,
    Tracking = 2,
    Reference = 3
}

export enum AddressCheckType {
    Unknown = 0,
    Commercial = 1,
    Residential = 2
}