import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { pmgPrinterTypeDb, pmgPrinterDb, pmgPrintQueueDb, pmgPrintQueueViewDb, pmgReportDb, pmgPrinterUserDb, pmgPrinterUserFilter } from '../_models/PMG.model';

@Injectable({
  providedIn: 'root'
})
export class PrinterTypeService {

  api = 'pmgPrinterType/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<pmgPrinterTypeDb[]>(this.apiURL + this.api);

  }
  create(printerType: pmgPrinterTypeDb) {
    return this.http.post(this.apiURL + this.api, printerType);
  }

  update(printerType: pmgPrinterTypeDb) {
    return this.http
      .put(this.apiURL + this.api + printerType.PrinterTypeID, printerType);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getByWarehouse(warehouseID: number) {
    return this.http.get<pmgPrinterTypeDb[]>(this.apiURL + this.api + "warehouse/" + warehouseID);
  }

}

@Injectable({
  providedIn: 'root'
})
export class PrinterService {

  api = 'pmgPrinter/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<pmgPrinterDb[]>(this.apiURL + this.api);

  }
  create(printer: pmgPrinterDb) {
    return this.http.post(this.apiURL + this.api, printer);
  }

  update(printer: pmgPrinterDb) {
    return this.http
      .put(this.apiURL + this.api + printer.PrinterID, printer);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  updateAllPrinters() {
    return this.http.put(this.apiURL + this.api + 'update', '');
  }

  getByWarehouse(warehouseID: number) {
    return this.http.get<pmgPrinterDb[]>(this.apiURL + this.api + 'warehouse/' + warehouseID);
  }
}

@Injectable({
  providedIn: 'root'
})
export class PrinterUserService {

  api = 'pmgPrinterUser/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<pmgPrinterUserDb[]>(this.apiURL + this.api);

  }
  
  getAllWithFilters(AppName: string, ClientID: number, WarehouseID: number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let myParams = new HttpParams();
    myParams = myParams.append('AppName', AppName);
    myParams = myParams.append('ClientID', ClientID.toString());
    myParams = myParams.append('WarehouseID',  WarehouseID.toString());
    return this.http.get(this.apiURL + this.api + 'filter', { headers: headers, params: myParams} );
  }

  getForUser(AppName: string, UserID: number, WarehouseID: number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let myParams = new HttpParams();
    myParams = myParams.append('AppName', AppName);
    myParams = myParams.append('UserID', UserID.toString());
    myParams = myParams.append('WarehouseID', WarehouseID.toString());
    return this.http.get(this.apiURL + this.api + 'forUser', { headers: headers, params: myParams} );

  }

  create(printerUser: pmgPrinterUserDb) {
    return this.http.post(this.apiURL + this.api, printerUser);
  }

  saveList(userID: number, puList: pmgPrinterUserDb[]) {
    return this.http.put(this.apiURL + this.api + 'forUser/' + userID, puList);
  }

  update(printerUser: pmgPrinterUserDb) {
    return this.http
      .put(this.apiURL + this.api + printerUser.PrinterUserID, printerUser);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }


}
@Injectable({
  providedIn: 'root'
})
export class PrintQueueService {

  api = 'pmgPrintQueue/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<pmgPrintQueueDb[]>(this.apiURL + this.api);

  }
  create(queue: pmgPrintQueueDb) {
    return this.http.post(this.apiURL + this.api, queue);
  }

  update(queue: pmgPrintQueueDb) {
    return this.http
      .put(this.apiURL + this.api + queue.PrintQueueID, queue);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  reprint(id: number) {
    return this.http.put(this.apiURL + this.api + 'reprint/' + id, '');
  }
}

@Injectable({
  providedIn: 'root'
})
export class PrintQueueViewService {
  api = 'pmgPrintQueueView/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<pmgPrintQueueViewDb[]>(this.apiURL + this.api);

  } 
}

@Injectable({
  providedIn: 'root'
})
export class PrintManagerReportService {

  api = 'pmgReport/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<pmgReportDb[]>(this.apiURL + this.api);

  }
  create(report: pmgReportDb) {
    return this.http.post(this.apiURL + this.api, report);
  }

  update(report: pmgReportDb) {
    return this.http
      .put(this.apiURL + this.api + report.ReportID, report);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

}