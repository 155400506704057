import { Injectable, Inject } from '@angular/core';
// // import { server } from '../_models/index';
import { cfgActivityType } from '../_models/CFG.model';

import { HttpClient } from '@angular/common/http';

@Injectable()
export class ActivityTypesService {


  api = 'cfgActivityType/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<cfgActivityType[]>(this.apiURL + this.api);

  }
  create(associationType: cfgActivityType) {
    return this.http.post(this.apiURL + this.api, associationType);
  }

  update(associationType: cfgActivityType) {
    return this.http
      .put(this.apiURL + this.api + associationType.ActivityTypeID, associationType);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }


}
