import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api/menuitem';
import { VERSION } from '../../environments/version';
import { AlertService, AuthService } from '../_services/index';

import { Location } from '@angular/common';
// import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { cfgTrackTypes, QuoteStatus } from 'app/_models/enums.model';
import { timeEmployeeSnapshot } from 'app/_models/TIME.model';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { arcMenuItem, secGoTime } from '../_models/SEC.model';
import { BreadcrumbService } from '../_services/breadcrumb.service';
import { LookupsService } from '../_services/lookups.service';
import { QuoteStatusService } from 'app/_data/quotes.service';
import { ordQuoteStatusItem } from 'app/_models/ORD.model';

@Component({
  selector: 'arc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  currentUser: any;
  subscription: Subscription;
  querysub: Subscription;

  displayUserSettings = false;

  displayColor = '';

  env = environment;
  VERSION = VERSION;

  goTimeHome: "GoTime";
  loadGoTimeToggle: boolean = false;
  loadTimeTrackToggle: boolean = false;
  trackTime: boolean = false;
  timeTrackBundle: timeEmployeeSnapshot;
  statusData: secGoTime = null;
  timer: any; //  NodeJS.Timeout;

  menuItems: arcMenuItem[];
  userMenuItems: arcMenuItem[] = [];

  needyQuotes: boolean = false;

  cfgTrackTypes = cfgTrackTypes;

  // * MAKE SURE TO FLIP THIS TOGGLE BACK TO FALSE
  loadProDocsToggle: boolean = false;

  constructor(
    private authService: AuthService,
    private lookupService: LookupsService,
    private route: Router,
    private breadcrumbService: BreadcrumbService,
    private location: Location,
    private quoteStatusService: QuoteStatusService,
    private auth: AuthService,
    private alert: AlertService) {
  }

  ngOnInit() {
    console.log('in ngOnInit');
    this.subscription = this.authService.currentUserItem$.subscribe(
      user => {
        this.currentUser = user;
        console.log('got current user?', this.currentUser);
        if (this.currentUser) {
          this.menuItems = [...this.currentUser.MenuItems];
          this.getUserStatus();
          this.loadCurrentEntityUser();
        }
      },
      error => {
        this.alert.error(error);
      });


    // this.authService.getAuthentication();

    if (this.userMay('rtmShpQuoQuotes')) {
      const statuses: ordQuoteStatusItem[] = [];
      statuses.push(new ordQuoteStatusItem(QuoteStatus.Submitted));
      statuses.push(new ordQuoteStatusItem(QuoteStatus.Approved));
      // start a polling process to look for Submitted or Approved quotes
      this.timer = setInterval(() => {
        if (this.auth.getAuth()) {
          this.quoteStatusService.fetchQuoteListByStatus(0, statuses)
            .subscribe((d: ordQuoteStatusItem[]) => {
              this.needyQuotes = (d[0].QuoteList.length + d[1].QuoteList.length) > 0;
            })
        }
      }, 20 * 1000, statuses);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.timer) {
      this.timer.unref;
    }

  }

  loggedInUser() {
    if (this.currentUser && this.statusData) {
      if (this.statusData.ClockStatus === 'IN' || this.statusData.ClockStatus === 'BRK') {
        if (this.statusData.ClientID) {
          return this.currentUser.FirstName + ' ' + this.currentUser.LastName + ' - (' + this.statusData.FunctionCode + ') ' + this.statusData.FunctionName + ' ' + this.statusData.ClientShortName + ' ' + ' - ' + this.currentUser.curEntity.EntityName;
        } else {
          return this.currentUser.FirstName + ' ' + this.currentUser.LastName + ' - (' + this.statusData.FunctionCode + ') ' + this.statusData.FunctionName + ' ' + ' - ' + this.currentUser.curEntity.EntityName;
        }
      } else if (this.statusData.ClockStatus === 'OUT') {
        return this.currentUser.FirstName + ' ' + this.currentUser.LastName + ' - OUT - ' + this.currentUser.curEntity.EntityName;
      } else if (this.statusData.ClockStatus === 'ADM') {
        return this.currentUser.FirstName + ' ' + this.currentUser.LastName + ' - ADM - ' + this.currentUser.curEntity.EntityName;
      }
    } else if (this.currentUser) { // but NO status data -- they're logged in as a client user, not a ProPack user
      return this.currentUser.FirstName + ' ' + this.currentUser.LastName + ' - ' + this.currentUser.curEntity.EntityName;
    }
    else { return 'Not Logged In'; }
    // if (this.currentUser && this.statusData) {
    //   if (this.statusData.ClockStatus === 'OUT') {

    //   }
    // } else { return 'Not logged in'; }
  }

  logout() {
    this.authService.logout();
    this.route.navigate(['/login']);
  }

  loadCurrentEntityUser() {
    console.log('in loadCurrentENtityUser');
    // 01/26/2023 RJS Converted this to dynamically build user menu by checking user rights.
    this.userMenuItems = [];
    let userItems: arcMenuItem[] = [];
    if (this.userMay('rtmGoTime')) {
      userItems.push(
        {
          label: 'Go Time',
          icon: 'pi pi-clock',
          command: () => { this.loadGoTime(); }
        }
      )
    };
    if (this.userMay('rtmAssetUser')) {
      userItems.push(
        {
          label: 'Asset Tracker',
          icon: 'pi pi-tablet',
          command: () => { window.open(this.env.AssetTrackerURL, '_blank'); }
        }
      )
    };
    if (this.userMay('rtmProDocs')) {
      userItems.push(
        {
          label: 'ProDocs',
          icon: 'pi pi-book',
          command: () => { this.loadProDocs(); }
        }
      )
    };
    if (this.userMay('rtmTimeTrack')) {
      userItems.push(
        {
          label: 'Time Track',
          icon: 'pi pi-eye',
          command: () => { this.openTimeTrackFromMenu(); },
          visible: this.statusData?.TrackTypeID === cfgTrackTypes.Order

        }
      );
    }
    /// everybody gets the following
    userItems.push(
      {
        label: 'Settings',
        icon: 'pi pi-plus',
        command: () => { this.editUserSettings(); }
      }
    )
    userItems.push(
      {
        label: 'Logout',
        icon: 'pi pi-power-off',
        command: () => { this.logout(); }
      }
    )

    this.userMenuItems.push({ label: 'User', items: userItems });

    if (this.currentUser.ents.length > 0) {
      // this.userMenuItems.push( { separator: true });
      let entityItems: MenuItem[] = [];
      this.currentUser.ents.forEach(e => {
        if (e.isActive) {
          entityItems.push(
            {
              label: e.EntityName,
              command: () => { this.changeEntityUser(e.EntityUserID); }
            }
          );
        }
      });
      this.userMenuItems.push(
        {
          label: 'Company',
          items: entityItems
        }
      );
      //   // this.userMenuItems.push( { });
    }
  }


  editUserSettings() {
    this.displayUserSettings = true;
  }

  loadGoTime() {
    this.goTimeHome = "GoTime";
    this.loadGoTimeToggle = true;
  }

  loadProDocs() {
    this.loadProDocsToggle = true;
  }

  openTimeTrackFromMenu() {
    if (this.statusData.TrackTypeID === cfgTrackTypes.Order) {
      this.loadTimeTrackToggle = true;
    } else {
      this.alert.warn('Current Function and Client Do Not Apply for Time Track');
    }
  }

  loadTimeTrack(pack: timeEmployeeSnapshot) {
    this.close();
    this.getUserStatus();
    this.loadTimeTrackToggle = true;
  }

  getUserStatus() {
    // this gets GoTime info for user -- will fall through to error for Clients
    this.authService.gatherGoTimeInfo(this.currentUser.UserID).subscribe(
      (val) => {
        this.statusData = val;
        this.loadCurrentEntityUser();
      },
      (error) => {
        // this.alert.error(error);
        this.statusData = null;
      }
    );
  }

  onEditUserComplete() {
    this.displayUserSettings = false;
  }

  resetAll() {
    this.lookupService.clearAll();
    this.changeEntityUser(this.authService.currentUser.curEntity.EntityUserID); // reset to same, to force reload of menus, etc.
  }

  changeEntityUser(entityUserID: number) {
    this.authService.setEntityUser(entityUserID);
    this.route.navigate(['/']);
  }

  canGoBack(): boolean {
    return this.location.path().length > 0;
  }

  navBack() {
    // const path = this.location.path();
    // if (path !== '') {
    if (this.canGoBack()) {
      this.location.back();
    }
  }
  onNavClick(event) {
    this.breadcrumbService.navTo(event.target.parentElement.href);
  }

  userMay(right: string): boolean {
    return this.authService.userHasRight(right);
  }

  close() {
    this.loadGoTimeToggle = false;
    this.goTimeHome = "GoTime";
  }

  closeTimeTrack() {
    // this.loadTimeTrackToggle = false;
  }

  closeProDocs() {
    this.loadProDocsToggle = false;
  }
}
