import { Inject, Injectable } from '@angular/core';
// // import { server } from '../_models/index';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UpsertRet } from 'app/_models/API.model';
import { docActorDb, docAssignmentCompletionDistribution, docAssignmentDb, docAssignmentEx, docAssignmentStatus, docCategoryDb, docDashboardActionsItem, docDocLinkDb, docDocumentDb, docDocumentEx, docDocumentItem, docDocumentSearch, docHistoryDb, docLanguageDb, docNoteDb, docQuizDb, docQuizEx, docQuizInstanceDb, docQuizInstanceEx, docQuizQuestionDb, docQuizResponseDb, docReassignCandidate, docReassignGroup, docRoleDb, docSearchText, docStatusDb, docTriggerDb, docTriggerEx, docUploadedDoc, docVersion, docVersionDb, docVersionDbEx } from 'app/_models/DOC.model';
import { docDocumentFunction } from 'app/_models/TIME.model';

@Injectable()
export class docActorService {

  api = 'docActor/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<docActorDb[]>(this.apiURL + this.api);

  }
  getByID(id: number) {
    return this.http.get<docActorDb>(this.apiURL + this.api + id);
  }

  create(actor: docActorDb) {
    return this.http.post(this.apiURL + this.api, actor);
  }

  update(actor: docActorDb) {
    return this.http
      .put(this.apiURL + this.api + actor.ActorID, actor);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getFiltered(versionid: number, personid: number, includeCompleted: boolean) {
    let params = new HttpParams();
    params = params.append('includeCompleted', includeCompleted.toString());
    return this.http.get<docActorDb[]>(this.apiURL + this.api + 'filtered/' + versionid + '/' + personid, { params: params });
  }

  markComplete(actorID: number, completed: boolean) {
    let params = new HttpParams();
    params = params.append('completed', completed.toString());
    return this.http.put<UpsertRet>(this.apiURL + this.api + 'mark/' + actorID, null, { params: params });
  }
}

@Injectable()
export class docAssignmentService {

  api = 'docAssignment/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll(includeCompleted: boolean = true) {
    let params = new HttpParams();
    params = params.append('includeCompleted', includeCompleted.toString());
    return this.http.get<docAssignmentDb[]>(this.apiURL + this.api, { params: params });

  }
  getByID(id: number) {
    return this.http.get<docAssignmentDb>(this.apiURL + this.api + id);
  }
  create(assignment: docAssignmentDb) {
    return this.http.post(this.apiURL + this.api, assignment);
  }

  update(assignment: docAssignmentDb) {
    return this.http
      .put(this.apiURL + this.api + assignment.AssignmentID, assignment);
  }

  reassign(assignment: docAssignmentDb) {
    return this.http
      .put(this.apiURL + this.api  + 'reassign/' + assignment.AssignmentID, assignment);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getForDocument(id: number, includeCompleted: boolean) {
    let params = new HttpParams();
    params = params.append('includeCompleted', includeCompleted.toString());
    return this.http.get<docAssignmentDb[]>(this.apiURL + this.api + 'doc/' + id, { params: params });
  }

  getForAssignee(id: number, includeCompleted: boolean) {
    let params = new HttpParams();
    params = params.append('includeCompleted', includeCompleted.toString());
    return this.http.get<docAssignmentDb[]>(this.apiURL + this.api + 'assignee/' + id, { params: params });
  }

  markRead(assignmentID: number) {
    return this.http.put<UpsertRet>(this.apiURL + this.api + 'markRead/' + assignmentID, null)
  }

  flagAssignmentOverride(documentID: number) {
    return this.http.put<boolean>(this.apiURL + this.api + 'flagOverride/' + documentID, null);
  }

  getReassignList(documentID: number) {
    return this.http.get<docReassignCandidate[]>(this.apiURL + this.api + 'reassign/' + documentID);
  }

  reassignGroup(reassignGroup: docReassignGroup) {
    return this.http.put<number>(this.apiURL + this.api + 'reassignGroup', reassignGroup);
  }
}

@Injectable()
export class docCategoryService {

  api = 'docCategory/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<docCategoryDb[]>(this.apiURL + this.api);

  }
  getByID(id: number) {
    return this.http.get<docCategoryDb>(this.apiURL + this.api + id);
  }
  create(category: docCategoryDb) {
    return this.http.post(this.apiURL + this.api, category);
  }

  update(category: docCategoryDb) {
    return this.http
      .put(this.apiURL + this.api + category.CategoryID, category);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

}

@Injectable()
export class docLanguageService {

  api = 'docLanguage/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<docLanguageDb[]>(this.apiURL + this.api);

  }
  getByCode(code: string) {
    return this.http.get<docLanguageDb>(this.apiURL + this.api + code);
  }
  create(language: docLanguageDb) {
    return this.http.post(this.apiURL + this.api, language);
  }

  update(language: docLanguageDb) {
    return this.http
      .put(this.apiURL + this.api + language.LanguageCode, language);
  }

  delete(code: string) {
    return this.http.delete(this.apiURL + this.api + code);
  }

}


@Injectable()
export class docDocumentService {

  api = 'docDocument/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<docDocumentDb[]>(this.apiURL + this.api);
  }

  getAllPublished() {
    return this.http.get<docDocumentDb[]>(this.apiURL + this.api + 'published');
  }

  getByID(id: number) {
    return this.http.get<docDocumentDb>(this.apiURL + this.api + id);
  }
  create(document: docDocumentDb) {
    return this.http.post(this.apiURL + this.api, document);
  }

  update(document: docDocumentDb) {
    return this.http
      .put(this.apiURL + this.api + document.DocumentID, document);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getList(showArchive: boolean) {
    return this.http.get<docDocumentEx[]>(this.apiURL + this.api + 'ex', { params: { showArchive: showArchive } });
  }

  getForAssignment(assignmentID: number) {
    return this.http.get(this.apiURL + this.api + 'assignment/' + assignmentID, { responseType: 'blob' });
  }

  getForVersion(versionID: number, type: string) {
    return this.http.get(this.apiURL + this.api + 'version/' + versionID + '/' + type, { responseType: 'blob' });
  }

  getForDocument(documentID: number) {
    return this.http.get(this.apiURL + this.api + 'content/' + documentID, { responseType: 'blob' })
  }

  getNextReferenceNumber() {
    return this.http.get<number>(this.apiURL + this.api + 'nextDocumentReferenceNumber');
  }

  upload(file: File, doc: docUploadedDoc) {

    const fData: FormData = new FormData();

    fData.append(file.name, file, file.name);
    fData.append('docUploadedDoc', JSON.stringify(doc));
    return this.http.post(this.apiURL + this.api + 'upload', fData);
  }

  searchLibrary(frag: string) {
    return this.http.get<docDocumentEx[]>(this.apiURL + this.api + 'searchLibrary', { params: { frag: frag } });
  }

  search(frag: string) {
    return this.http.get<docDocumentSearch[]>(this.apiURL + this.api + 'search', { params: { frag: frag } });
  }

  searchAllText(frag: string) {
    return this.http.get<docDocumentEx[]>(this.apiURL + this.api + 'searchAllText', { params: { frag: frag } });
  }

  toggleArchive(id: number) {
    console.log('in toggle archive', id);
    return this.http.put<docDocumentDb>(this.apiURL + this.api + 'toggleArchive/' + id, null);
  }

}

@Injectable()
export class docVersionService {

  api = 'docVersion/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<docVersionDb[]>(this.apiURL + this.api);

  }
  getByID(id: number) {
    return this.http.get<docVersion>(this.apiURL + this.api + id);
  }
  create(version: docVersionDb) {
    return this.http.post(this.apiURL + this.api, version);
  }

  update(version: docVersionDb) {
    return this.http
      .put(this.apiURL + this.api + version.VersionID, version);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getForDocument(id: number) {
    return this.http.get<docVersionDb[]>(this.apiURL + this.api + 'doc/' + id);
  }

  revise(id: number, level: number) {
    let params = new HttpParams();
    params = params.append('level', level.toString());
    return this.http.post(this.apiURL + this.api + 'revise/' + id, null, { params: params });
  }

  newLanguage(id: number, languageCode: string) {
    let params = new HttpParams();
    params = params.append('languageCode', languageCode);
    return this.http.post(this.apiURL + this.api + 'newlanguage/' + id, null, { params: params });
  }

  publish(id: number) {
    console.log('publishing ', id);
    return this.http.put(this.apiURL + this.api + 'publish/' + id, null);
  }
}

@Injectable()
export class docQuizInstanceService {

  api = 'docQuizInstance/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<docQuizInstanceDb[]>(this.apiURL + this.api);

  }
  getByID(id: number) {
    return this.http.get<docQuizInstanceDb>(this.apiURL + this.api + id);
  }
  create(quizInstance: docQuizInstanceDb) {
    return this.http.post(this.apiURL + this.api, quizInstance);
  }

  update(quizInstance: docQuizInstanceDb) {
    return this.http
      .put(this.apiURL + this.api + quizInstance.InstanceID, quizInstance);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getQuizForAssignment(assignID: number) {
    return this.http.get<docQuizInstanceEx>(this.apiURL + this.api + assignID + '/new');
  }

  postQuiz(quiz: docQuizInstanceEx) {
    return this.http.post<UpsertRet>(this.apiURL + this.api + 'submit', quiz);
  }
}

@Injectable()
export class docQuizQuestionService {

  api = 'docQuizQuestion/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<docQuizQuestionDb[]>(this.apiURL + this.api);

  }
  getByID(id: number) {
    return this.http.get<docQuizQuestionDb>(this.apiURL + this.api + id);
  }
  create(quizQuestion: docQuizQuestionDb) {
    return this.http.post(this.apiURL + this.api, quizQuestion);
  }

  update(quizQuestion: docQuizQuestionDb) {
    return this.http
      .put(this.apiURL + this.api + quizQuestion.QuestionID, quizQuestion);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

}

@Injectable()
export class docQuizResponseService {

  api = 'docQuizResponse/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<docQuizResponseDb[]>(this.apiURL + this.api);

  }
  getByID(id: number) {
    return this.http.get<docQuizResponseDb>(this.apiURL + this.api + id);
  }
  create(quizResponse: docQuizResponseDb) {
    return this.http.post(this.apiURL + this.api, quizResponse);
  }

  update(quizResponse: docQuizResponseDb) {
    return this.http
      .put(this.apiURL + this.api + quizResponse.ResponseID, quizResponse);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

}

@Injectable()
export class docQuizService {

  api = 'docQuiz/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<docQuizDb[]>(this.apiURL + this.api);

  }
  getByID(id: number) {
    return this.http.get<docQuizDb>(this.apiURL + this.api + id);
  }
  create(quiz: docQuizDb) {
    return this.http.post(this.apiURL + this.api, quiz);
  }

  update(quiz: docQuizDb) {
    return this.http
      .put(this.apiURL + this.api + quiz.QuizID, quiz);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  toggleActiveQuiz(quizID: number, activate: boolean) {
    let params = new HttpParams();
    params = params.append('IsActive', activate.toString());
    console.log(quizID + '====' + activate)
    return this.http.put(this.apiURL + this.api + 'activate/' + quizID, null, { params: params });
  }
}

@Injectable()
export class docQuizExService {

  api = 'docQuizEx/'

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getByID(id: number) {
    return this.http.get<docQuizEx>(this.apiURL + this.api + id);
  }

  getByVersionID(id: number) {
    return this.http.get<docQuizEx>(this.apiURL + this.api + 'version/' + id);
  }

  create(quiz: docQuizEx) {
    return this.http.post(this.apiURL + this.api, quiz);
  }

  update(quiz: docQuizEx) {
    return this.http.put(this.apiURL + this.api + quiz.QuizID, quiz);
  }
}

@Injectable()
export class docRoleService {

  api = 'docRole/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<docRoleDb[]>(this.apiURL + this.api);

  }
  getByID(id: number) {
    return this.http.get<docRoleDb>(this.apiURL + this.api + id);
  }
  create(role: docRoleDb) {
    return this.http.post(this.apiURL + this.api, role);
  }

  update(role: docRoleDb) {
    return this.http
      .put(this.apiURL + this.api + role.RoleID, role);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

}

@Injectable()
export class docStatusService {

  api = 'docStatus/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<docStatusDb[]>(this.apiURL + this.api);

  }
  getByID(id: number) {
    return this.http.get<docStatusDb>(this.apiURL + this.api + id);
  }
  create(status: docStatusDb) {
    return this.http.post(this.apiURL + this.api, status);
  }

  update(status: docStatusDb) {
    return this.http
      .put(this.apiURL + this.api + status.StatusID, status);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

}

@Injectable()
export class docTriggerService {

  api = 'docTrigger/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll(id: number) {
    return this.http.get<docTriggerDb[]>(this.apiURL + this.api + 'document/' + id);
  }

  getByID(id: number) {
    return this.http.get<docTriggerDb>(this.apiURL + this.api + id);
  }
  getByIDEx(id: number) {
    return this.http.get<docTriggerEx>(this.apiURL + this.api + 'ex/' + id);
  }
  create(trigger: docTriggerDb) {
    return this.http.post(this.apiURL + this.api, trigger);
  }

  update(trigger: docTriggerDb) {
    return this.http
      .put(this.apiURL + this.api + trigger.TriggerID, trigger);
  }

  createEx(trigger: docTriggerEx) {
    return this.http.post(this.apiURL + this.api + 'ex', trigger);
  }

  updateEx(trigger: docTriggerEx) {
    console.log('saving ', trigger);
    return this.http
      .put(this.apiURL + this.api + 'ex/' + trigger.TriggerID, trigger);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

}

@Injectable()
export class docDashboardService {
  api = 'docDashboard/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getActions(employeeID, includeComplete) {
    // if employeeID = -1, show all actors' actions
    let params = new HttpParams();
    params = params.append('employeeID', employeeID.toString());
    params = params.append('includeComplete', includeComplete.toString());
    return this.http.get<docDashboardActionsItem[]>(this.apiURL + this.api + 'actions', { params: params });
  }

  // getActionsForTeam(managerID, showFullTeam, includeComplete) {
  //   let params = new HttpParams();
  //   params = params.append('showFullTeam', showFullTeam.toString());
  //   params = params.append('includeComplete', includeComplete.toString());
  //   return this.http.get<docDashboardActionsItem[]>(this.apiURL + this.api + 'teamActions/' + managerID, { params: params });
  // }

  getAssignmentsForTeam(managerID: number, showFullTeam: boolean) {
    let params = new HttpParams();
    params = params.append('showFullTeam', showFullTeam.toString());
    return this.http.get<docAssignmentEx[]>(this.apiURL + this.api + 'teamAssignments/' + managerID, { params: params });
  }

  // api/docDashboard/versions?includeArchived=[true|false]
  getVersionsList(includeArchived: boolean) {
    let params = new HttpParams();
    params = params.append('includeArchived', includeArchived.toString());
    return this.http.get<docVersionDbEx[]>(this.apiURL + this.api + 'versions', { params: params });
  }

  // api/docDashboard/compDistTeam/{managerID: int}?showFullTeam=[true|false]?FromDate=[date]
  getCompletionDistributionForTeam(managerID: number, showFullTeam: boolean, fromDate: Date) {
    let params = new HttpParams();
    params = params.append('showFullTeam', showFullTeam.toString());
    params = params.append('fromDate', fromDate.toLocaleDateString());
    console.log(params)
    return this.http.get<docAssignmentCompletionDistribution>(this.apiURL + this.api + 'compDistTeam/' + managerID, { params: params });
  }

  // api/docDashboard/compDist/?FromDate=[date]
  getCompletionDistribution(fromDate: Date) {
    let params = new HttpParams();
    params = params.append('fromDate', fromDate.toLocaleDateString());
    console.log(params)
    return this.http.get<docAssignmentCompletionDistribution>(this.apiURL + this.api + 'compDist/', { params: params });
  }

  // api/docDashboard/numberOfStatus/{managerID: int}?showFullTeam=[true|false]?warehouseID=[int]
  getDocumentAssignmentStatus(managerID: number, showFullTeam?: boolean, warehouseID?: number) {
    let params = new HttpParams();
    if (showFullTeam) { params = params.append('showFullTeam', showFullTeam.toString()); }
    if (warehouseID) { params = params.append('warehouseID', warehouseID.toString()); }
    return this.http.get<docAssignmentStatus[]>(this.apiURL + this.api + 'numberOfStatus/' + managerID, { params: params });
  }
}

@Injectable()
export class docHistoryService {
  api = 'docHistory/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getDocumentHistory(docID: number) {
    return this.http.get<docHistoryDb[]>(this.apiURL + this.api + 'document/' + docID);
  }

  getVersionHistory(versionID: number) {
    return this.http.get<docHistoryDb[]>(this.apiURL + this.api + 'version/' + versionID);
  }
}



@Injectable({
  providedIn: 'root'
})
export class DocNotesService {

  api = 'docNote/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<docNoteDb[]>(this.apiURL + this.api);
  }

  create(item: docNoteDb) {
    return this.http.post(this.apiURL + this.api, item);
  }

  update(item: docNoteDb) {
    return this.http
      .put(this.apiURL + this.api + item.NoteID, item);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getNotes(documentID: number, versionID: number = null) {
    if (versionID) {
      return this.http.get<docNoteDb[]>(this.apiURL + this.api + 'version/' + versionID);
    } else {
      return this.http.get<docNoteDb[]>(this.apiURL + this.api + 'document/' + documentID);
    }
  }

}

@Injectable({
  providedIn: 'root'
})
export class docDocLinkService {
  api = 'docLink/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<docDocLinkDb[]>(this.apiURL + this.api);

  }
  getByID(id: number) {
    return this.http.get<docDocLinkDb>(this.apiURL + this.api + id);
  }

  create(link: docDocLinkDb) {
    return this.http.post(this.apiURL + this.api, link);
  }

  update(link: docDocLinkDb) {
    return this.http
      .put(this.apiURL + this.api + link.DocLinkID, link);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }

  getForVersion(versionID: number) {
    return this.http.get<docDocLinkDb[]>(this.apiURL + this.api + 'version/' + versionID);
  }

  getLinkedDoc(documentID: number, assignmentID: number) {
    let params = new HttpParams();
    params = params.append('assignmentID', assignmentID.toString());
    return this.http.get(this.apiURL + this.api + 'document/' + documentID, { params: params, responseType: 'blob' })
  }
}

@Injectable({
  providedIn: 'root'
})
export class docSearchTextService {
  api = 'docSearchText/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<docSearchText[]>(this.apiURL + this.api);

  }
  getByID(id: number) {
    return this.http.get<docSearchText>(this.apiURL + this.api + id);
  }

  create(entry: docSearchText) {
    return this.http.post(this.apiURL + this.api, entry);
  }

  update(entry: docSearchText) {
    return this.http
      .put(this.apiURL + this.api + entry.DocumentID, entry);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }
}

@Injectable({
  providedIn: 'root'
})
export class docDocumentFunctionService {
  api = 'docDocumentFunctions/';

  constructor(private http: HttpClient, @Inject('apiURL') private apiURL) { }

  getAll() {
    return this.http.get<docDocumentFunction[]>(this.apiURL + this.api);
  }

  getForDocument(documentID: number) {
    return this.http.get<docDocumentFunction[]>(this.apiURL + this.api + 'document/' + documentID);
  }

  getForFunction(functionID: number) {
    return this.http.get<docDocumentFunction[]>(this.apiURL + this.api + 'function/' + functionID);
  }

  getByID(id: number) {
    return this.http.get<docDocumentFunction>(this.apiURL + this.api + id);
  }

  create(entry: docDocumentFunction) {
    return this.http.post(this.apiURL + this.api, entry);
  }

  delete(id: number) {
    return this.http.delete(this.apiURL + this.api + id);
  }
}
